<template>
  <div class="profile-balance">
    <div class="balance-element">
      <div class="element-title">TOTAL BALANCE</div>
      <div class="element-amount">
        <div class="amount-inner">
          <img src="@/assets/img/icons/coin.svg" alt="icon"/>
          <div class="inner-value">
            <span>{{ profileFormatValue(authUser.user.balance + (authUser.user.bonusBalance || 0)).split('.')[0] }}</span>
            .{{ profileFormatValue(authUser.user.balance + (authUser.user.bonusBalance || 0)).split('.')[1] }}
          </div>
        </div>
      </div>
    </div>
    <div class="balance-details">
      <div class="balance-item">
        <div class="item-title">MAIN BALANCE</div>
        <div class="item-amount">
          <img src="@/assets/img/icons/coin.svg" alt="icon"/>
          <div class="amount-value">
            <span>{{ profileFormatValue(authUser.user.balance).split('.')[0] }}</span>
            .{{ profileFormatValue(authUser.user.balance).split('.')[1] }}
          </div>
        </div>
      </div>
      <div class="balance-item">
        <div class="item-title">BONUS BALANCE</div>
        <div class="item-amount">
          <img src="@/assets/img/icons/coin.svg" alt="icon"/>
          <div class="amount-value">
            <span>{{ profileFormatValue(authUser.user.bonusBalance || 0).split('.')[0] }}</span>
            .{{ profileFormatValue(authUser.user.bonusBalance || 0).split('.')[1] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileBalance',
  methods: {
    profileFormatValue(value) {
      return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  },
  computed: {
    ...mapGetters([
      'authUser'
    ])
  }
};
</script>

<style scoped>
.profile-balance {
  width: 100%;
  margin-top: 35px;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.04);
}

.profile-balance .balance-element {
  width: 100%;
  margin-bottom: 20px;
}

.profile-balance .element-title {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  background: var(--highlight-blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.profile-balance .element-amount {
  width: 100%;
  height: 70px;
  position: relative;
  margin-top: 12px;
  padding: 1px;
}

.profile-balance .amount-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-secondary);
  border-radius: 0.5rem;
  z-index: 1;
}

.profile-balance .amount-inner img {
  user-select: none;
  width: 2rem;
}

.profile-balance .inner-value {
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}

.profile-balance .inner-value span {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}

.profile-balance .balance-details {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.profile-balance .balance-item {
  flex: 1;
  background: var(--background-secondary);
  border-radius: 0.5rem;
  padding: 15px;
}

.profile-balance .item-title {
  font-size: 12px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 10px;
}

.profile-balance .item-amount {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-balance .item-amount img {
  width: 1.5rem;
  user-select: none;
}

.profile-balance .amount-value {
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}

.profile-balance .amount-value span {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}

@media only screen and (max-width: 650px) {
  .profile-balance .balance-details {
    flex-direction: column;
    gap: 15px;
  }
}
</style> 