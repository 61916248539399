<template>
  <div class="iframe-container">
    <iframe 
      src="https://pump-staging.bazedbet.com" 
      frameborder="0" 
      class="iframe-content"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'IframePage'
};
</script>

<style scoped>
.iframe-container {
  width: 100%;
  height: 100vh; /* Full height of the viewport */
  overflow: hidden;
}

.iframe-content {
  width: 100%;
  height: 100%;
}
</style>