<template>
    <div class="card">
        <div class="card-header">
            <p class="card-title">Bazed Supply</p>
            <div class="info-logo">
                <img src="@/assets/icons/info.svg" alt="info" />
            </div>
        </div>
        <div class="supply-container">
            <img src="@/assets/icons/fire.svg" />
            <img src="@/assets/icons/red-right-arrow.svg" />
            <div class="supply-bar">
                <div class="supply-burnt" :style="{ width: burntPercentage + '%' }"></div>
                <div class="supply-circulating" :style="{ width: circulatingPercentage + '%' }"></div>
            </div>
        </div>
        <div class="supply-stats">
            <span class="red-line"></span>
            <span class="supply-title">Burnt BAZED</span>
            <span class="burnt-percentage">{{ burntPercentage }}%</span>
        </div>
        <span class="bazed-amount amount-m">{{ formattedBurnedTokens }} BAZED</span>

        <div class="supply-stats">
            <span class="green-line"></span>
            <span class="supply-title">Circulating BAZED</span>
            <span class="circulating-percentage">{{ circulatingPercentage }}%</span>
        </div>
        <span class="bazed-amount">{{ formattedCirculatingTokens }} BAZED</span>
        <div class="bazed-supply">
            <span class="original-supply">BAZED Supply:</span>
            <span class="original-amount">{{ formattedTotalSupply }} BAZED</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

const TOTAL_SUPPLY = 77000000;

export default {
    name: 'BuybackBazedSupplyCard',
    data() {
        return {
            burntPercentage: 0,
            circulatingPercentage: 100,
            burnedTokens: 0
        };
    },
    async created() {
        try {
            const response = await axios.get('/api/etherscan/burned-tokens');
            this.burnedTokens = parseFloat(response.data.amount);
            this.burntPercentage = ((this.burnedTokens / TOTAL_SUPPLY) * 100).toFixed(2);
            this.circulatingPercentage = (100 - this.burntPercentage).toFixed(2);
        } catch (error) {
            console.error('Error fetching burned tokens:', error);
        }
    },
    computed: {
        formattedBurnedTokens() {
            return this.burnedTokens.toLocaleString();
        },
        formattedCirculatingTokens() {
            return (TOTAL_SUPPLY - this.burnedTokens).toLocaleString();
        },
        formattedTotalSupply() {
            return TOTAL_SUPPLY.toLocaleString();
        }
    }
};

</script>

<style scoped>
.amount-m {
    margin-bottom: 31px;
}

.bazed-amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bazed-supply {
    display: flex;
    width: 100%;
    height: 47px;
    padding: 11px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #081122;
    margin-top: 33px;
}

.card {
    width: 100%;
    height: 402px;
    padding: 30px 30px 20px 30px;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(46, 99, 212, 0.00) 0%, rgba(46, 99, 212, 0.15) 100%), #10182B;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px -1px 0px 0px rgba(0, 0, 0, 0.73) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset;
    min-width: 330px;
}

.card-header {
    display: flex;
    justify-content: space-between;
}

.card-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #92969D;
    font-size: 16px;
}

.info-logo {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    cursor: pointer;
}

.supply-container {
    display: flex;
    width: 100%;
    height: 36px;
    padding: 9px 18px;
    align-items: center;
    gap: 18px;
    border-radius: 10px;
    background: #081122;
    margin-bottom: 31px;
    margin-top: 24px;
}

.supply-bar img {
    width: 10px;
    height: 14px;
}

.supply-bar {
    display: flex;
    width: 100%;
    height: 3px;
}

.supply-title {
    color: #92969D;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.supply-burnt {
    border-radius: 1px 0px 0px 1px;
    background: var(--secondary-color, #FF571B);
}

.supply-circulating {
    border-radius: 1px 0px 0px 1px;
    background: var(--primary-color, #38f27f);
}

.supply-stats {
    display: inline-flex;
    align-items: center;
    gap: 9px;
    margin-bottom: 8px;
}

.red-line,
.green-line {
    width: 23px;
    height: 3px;
    border-radius: 1px;
}

.red-line {
    background: var(--secondary-color, #FF571B);
}

.green-line {
    background: var(--primary-color, #38f27f);
}

.burnt-percentage,
.circulating-percentage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.burnt-percentage {
    color: var(--secondary-color, #FF571B);
}

.circulating-percentage {
    color: var(--primary-color, #38f27f);
}

.original-supply,
.original-amount {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.original-supply {
    color: #92969D;
}

.original-amount {
    color: #FFF;
}
</style>