var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"duels-user-element",class:{
    'element-rolling': _vm.duelsIsRolling === true,
    'element-winner': (_vm.duelsGame.state === 'completed' || _vm.duelsGame.winner !== undefined) && _vm.bet._id === _vm.duelsGame.winner._id
}},[(_vm.bet === undefined)?_c('div',{staticClass:"element-placeholder"},[(_vm.duelsGame.bets.length === _vm.index - 1)?_c('button',{attrs:{"disabled":_vm.socketSendLoading !== null},on:{"click":function($event){return _vm.duelsActionButton()}}},[_c('div',{staticClass:"button-inner"},[_vm._v(_vm._s(_vm.authUser.user !== null && _vm.authUser.user._id === _vm.duelsGame.bets[0].user._id ? 'CALL BOT' : 'JOIN'))])]):_vm._e()]):_c('div',{staticClass:"element-content"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"top-user",class:[
                'user-' + (_vm.bet.bot === true ? 'bot' : _vm.duelsGetRank(_vm.bet.user)),
                'user-' + (_vm.bet.bot === true ? '' : _vm.duelsGetLevelColor(_vm.bet.user))
            ]},[_c('div',{staticClass:"user-avatar"},[_c('AvatarImage',{attrs:{"image":_vm.bet.bot ? null : _vm.bet.user.avatar}})],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.bet.bot === true ? _vm.duelsGetBotName : _vm.bet.user.username)}})])]),_c('div',{staticClass:"content-bottom"},[(_vm.duelsIsRolling === true)?_c('span',[_vm._v("FLIPPING...")]):_c('div',{staticClass:"bottom-chance"},[_vm._v(" CHANCE: "),_c('span',[_vm._v(_vm._s(parseFloat(100 / _vm.duelsGame.playerCount).toFixed(2))+"%")])])])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }