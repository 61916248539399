<template>
  <div class="roll">
    <!--<RollBets />-->

    <div class="roll-container">
      <div class="top-wrapper">
        <RollGame />
        <RollHistory />
      </div>
      <RollControls />
      <!--<RollUserBets />-->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RollBets from "@/components/roll/RollBets";
import RollGame from "@/components/roll/RollGame";
import RollHistory from "@/components/roll/RollHistory";
import RollControls from "@/components/roll/RollControls";
import RollUserBets from "@/components/roll/RollUserBets";

export default {
  name: "Roll",
  metaInfo: {
    title: "Spin - Bazed Casino",
  },
  components: {
    RollBets,
    RollGame,
    RollHistory,
    RollControls,
    RollUserBets,
  },
  methods: {
    ...mapActions(["socketConnectRoll", "socketDisconnectRoll"]),
  },
  created() {
    this.socketConnectRoll();
  },
  beforeRouteLeave(to, from, next) {
    this.socketDisconnectRoll();
    next();
  },
};
</script>

<style scoped>
.roll {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 45px 24px;
  background: linear-gradient(180deg, #250d14 0%, #350064 100%), #08171f;
}

.top-wrapper {
width: 90%;
  border-radius: 1.25rem 1.25rem 0 0;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.28) 100%
    ),
    #544e89;
  box-shadow: inset 0 5px 0 rgba(255, 255, 255, 0.25);
  padding: 28px;
}

.roll .roll-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 25px;
}

@media only screen and (max-width: 1250px) {
  .roll .roll-bets {
    display: none;
  }

  .roll .roll-container {
    width: 100%;
    margin-left: 0;
    padding: 0 10px;
  }
}
</style>
