<template>
  <div class="sports-slider">
    <div class="sports-container">
      <div
        v-for="sport in sports"
        :key="sport.name"
        class="sport-card"
        @click="navigateToSport(sport)"
      >
        <div class="sport-icon">
          <i :class="sport.icon"></i>
        </div>
        <div class="sport-name">{{ sport.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SportsSlider',
  data() {
    return {
      sports: [
        { name: 'Football', path: '/football', icon: 'fas fa-futbol' },
        { name: 'Basketball', path: '/basketball', icon: 'fas fa-basketball-ball' },
        { name: 'Cricket', path: '/cricket', icon: 'fas fa-baseball-ball' },
        { name: 'Tennis', path: '/tennis', icon: 'fas fa-table-tennis' },
        { name: 'E-Sports', path: '/esports', icon: 'fas fa-gamepad' },
        { name: 'Volleyball', path: '/volleyball', icon: 'fas fa-volleyball-ball' },
        { name: 'Table Tennis', path: '/tableTennis', icon: 'fas fa-table-tennis' },
        { name: 'Ice Hockey', path: '/iceHockey', icon: 'fas fa-hockey-puck' },
        { name: 'Baseball', path: '/baseball', icon: 'fas fa-baseball-ball' },
        { name: 'American Football', path: '/americanFootball', icon: 'fas fa-football-ball' },
        { name: 'Boxing', path: '/boxing', icon: 'fas fa-fist-raised' },
        { name: 'UFC', path: '/ufc', icon: 'fas fa-fist-raised' },
        { name: 'MMA', path: '/mma', icon: 'fas fa-user-ninja' },
        { name: 'Handball', path: '/handball', icon: 'fas fa-hand-paper' },
        { name: 'Futsal', path: '/futsal', icon: 'fas fa-futbol' },
        { name: 'Racing', path: '/racing', icon: 'fas fa-flag-checkered' },
        { name: 'Kabaddi', path: '/kabaddi', icon: 'fas fa-running' },
        { name: 'Darts', path: '/darts', icon: 'fas fa-bullseye' },
        { name: 'Snooker', path: '/snooker', icon: 'fas fa-circle' },
        { name: 'Politics', path: '/politics', icon: 'fas fa-landmark' },
        { name: 'Rugby', path: '/rugby', icon: 'fas fa-football-ball' },
        { name: 'Biathlon', path: '/biathlon', icon: 'fas fa-skiing' },
        { name: 'Water Polo', path: '/waterPolo', icon: 'fas fa-water' },
        { name: 'Entertainment', path: '/entertainment', icon: 'fas fa-star' }
      ]
    }
  },
  methods: {
    navigateToSport(sport) {
      this.$router.push('/sports');
      setTimeout(() => {
        window.postMessage({
          type: 'navigation',
          path: sport.path,
          query: { tab: 'all' }
        }, '*');
      }, 100);
    }
  }
}
</script>

<style scoped>
.sports-slider {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.sports-slider::-webkit-scrollbar {
  display: none;
}

.sports-container {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  width: max-content;
}

.sport-card {
  cursor: pointer;
  border-radius: 0.625rem;
  background: linear-gradient(
      0deg,
      rgba(225, 239, 255, 0) 0%,
      rgba(225, 239, 255, 0.06) 100%
    ),
    #1e2639;
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.15),
    0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset;
  padding: 16px;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  transition: transform 0.2s ease;
}

.sport-card:hover {
  transform: translateY(-2px);
}

.sport-icon {
  color: #349DFF;
  font-size: 2rem;
  transition: transform 0.2s ease;
}

.sport-card:hover .sport-icon {
  transform: scale(1.1);
}

.sport-name {
  color: white;
  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

@media (max-width: 768px) {
  .sport-card {
    width: 100px;
    height: 100px;
    padding: 12px;
  }

  .sport-icon {
    font-size: 1.75rem;
  }

  .sport-name {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .sport-card {
    width: 90px;
    height: 90px;
    padding: 10px;
  }

  .sport-icon {
    font-size: 1.5rem;
  }

  .sport-name {
    font-size: 0.7rem;
  }
}
</style> 