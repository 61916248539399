<template>
  <div class="home-banner-user-large">
    <div class="user-inner">
      <div class="slider-container">
        <div class="image-container" v-for="(slide, index) in slides" :key="index"
          :class="{ active: currentSlide === index }" :style="{ '--slide-opacity': slide.opacity || 1 }">
          <img :src="slide.background" alt="Background" draggable="false" />
        </div>

        <div class="slider-nav">
          <button v-for="(slide, index) in slides" :key="index" :class="{ active: currentSlide === index }"
            @click="setSlide(index)" class="slider-dot"></button>
        </div>
      </div>

      <img :src="slides[currentSlide].character" class="character" :style="{
        bottom: slides[currentSlide].position?.bottom || '-175px',
        right: slides[currentSlide].position?.right || '50px',
        height: slides[currentSlide].size || '150%',
        transform: `rotate(${slides[currentSlide].rotation || 0}deg)`
      }" alt="Character" />

      <div v-if="authUser.user === null" class="inner-guest">
        <div class="headers">
          <span class="title">{{ slides[currentSlide].title }}</span>
          <span class="subtitle">
            <span v-html="highlightSubtitle"></span>
          </span>
        </div>
        <div class="divider" />
        <div class="actions">
          <LoginButton />
          <AuthButton />
        </div>
      </div>
      <div v-else class="inner-auth" :class="['auth-' + homeGetLevelColor]">
        <span class="title">WELCOME BACK, {{ authUser.user.username }}</span>
        <!--<div class="auth-info">
          <div class="info-avatar">
            <AvatarImage :image="authUser.user.avatar"/>
          </div>
          <span v-html="authUser.user.username"></span>
        </div>-->
        <div class="auth-level">
          <div class="level-box">
            <div class="box-inner">
              {{ homeGetLevel >= 99 ? 99 : homeGetLevel }}
            </div>
          </div>
          <div class="level-progress">
            <div class="progress-bar" :style="{
              width: (100 / homeGetLevelBet) * homeGetLevelProgress + '%',
            }"></div>
            <div class="progress-text">
              <span>{{
                homeGetLevelProgress >= 1000000
                  ? (homeGetLevelProgress / 1000000).toFixed(2) + "m"
                  : homeGetLevelProgress >= 1000
                    ? (homeGetLevelProgress / 1000).toFixed(2) + "k"
                    : homeGetLevelProgress.toFixed(2)
              }}
                /
              </span>
              <span class="text-target">
                {{
                  homeGetLevelBet >= 1000000
                    ? (homeGetLevelBet / 1000000).toFixed(2) + "m"
                    : homeGetLevelBet >= 1000
                      ? (homeGetLevelBet / 1000).toFixed(2) + "k"
                      : homeGetLevelBet.toFixed(2)
                }}
              </span>
            </div>
          </div>
          <div class="level-box">
            <div class="box-inner">
              {{ homeGetLevel >= 99 ? 100 : homeGetLevel + 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AuthButton from "@/components/AuthButton";
import LoginButton from "@/components/LoginButton";
import AvatarImage from "@/components/AvatarImage";

export default {
  name: "HomeBannerUserLarge",
  components: {
    LoginButton,
    AuthButton,
    AvatarImage,
  },
  props: {
    backgroundImage: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      default: require("@/assets/img/logo.png"),
    },
    character: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["authUser"]),
    homeGetLevel() {
      let level = Math.floor(
        Math.pow(this.authUser.user.xp / 1000 / 100, 1 / 3)
      );
      return level >= 100 ? 100 : level;
    },
    homeGetLevelColor() {
      let color = "";
      if (this.homeGetLevel >= 2 && this.homeGetLevel < 26) {
        color = "blue";
      } else if (this.homeGetLevel >= 26 && this.homeGetLevel < 51) {
        color = "green";
      } else if (this.homeGetLevel >= 51 && this.homeGetLevel < 76) {
        color = "orange";
      } else if (this.homeGetLevel >= 76 && this.homeGetLevel < 100) {
        color = "red";
      } else if (this.homeGetLevel >= 100) {
        color = "purple";
      }
      return color;
    },
    homeGetLevelBet() {
      return this.homeGetLevel >= 100
        ? 2970100
        : (Math.pow(this.homeGetLevel + 1, 3) -
          Math.pow(this.homeGetLevel, 3)) *
        100;
    },
    homeGetLevelProgress() {
      return (
        Math.floor(
          (this.homeGetLevel >= 100
            ? 2970100 * 1000
            : this.authUser.user.xp -
            Math.pow(this.homeGetLevel, 3) * 100 * 1000) / 10
        ) / 100
      );
    },
    highlightSubtitle() {
      const slide = this.slides[this.currentSlide];
      const { subtitle, highlight } = slide;
      if (!highlight || !subtitle) return subtitle;

      const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(`(${escapedHighlight})`, 'gi');
      return subtitle.replace(regex, `<span class="highlight-blue" style="color: rgba(52, 157, 255, 1) !important;">$1</span>`);
    },
  },
  data() {
    return {
      currentSlide: 0,
      slides: [
        {
          background: require("@/assets/img/banner/banner-background-1.png"),
          character: require("@/assets/img/banner/banner-character-1.png"),
          position: {
            bottom: '-175px',
            right: '0px', //class character position (left 30%)
          },
          size: '145%',
          rotation: 10,
          opacity: 1,
          title: "Experience the most BAZED crypto casino",
          highlight: "$BAZED",
          subtitle: "Deposit and gamble with $BAZED token for up to 20% rakeback!",
        },
        {
          background: require("@/assets/img/banner/banner-background-2.png"),
          character: require("@/assets/img/banner/banner-character-2.png"),
          position: {
            bottom: '-150px',
            right: '0px'
          },
          size: '140%',
          rotation: 0,
          opacity: 0.7,
          title: "Experience the most BAZED crypto casino",
          highlight: "20% Rakeback!",
          subtitle: "20% Rakeback! Using $BAZED token.",
        },
      ],
    };
  },
  mounted() {
    this.startSlideshow();
  },
  beforeUnmount() {
    this.stopSlideshow();
  },
  methods: {
    ...mapActions(["modalsSetShow"]),
    openDepositModal() {
      this.modalsSetShow("Cashier");
    },
    startSlideshow() {
      this.slideshowInterval = setInterval(this.nextSlide, 5000);
    },
    stopSlideshow() {
      clearInterval(this.slideshowInterval);
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    setSlide(index) {
      this.currentSlide = index;
      this.stopSlideshow();
      this.startSlideshow();
    },
  },
};
</script>

<style scoped>
.home-banner-user-large {
  width: 100%;
  height: min(60vw, 23rem);
  position: relative;
  z-index: 1;
  background: linear-gradient(180deg,
      rgba(10, 20, 42, 0) 0%,
      rgba(12, 52, 126, 0.89) 100%),
    radial-gradient(76.02% 76.02% at 61.34% 50%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.57) 100%);
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.divider {
  width: 30%;
  height: 2px;
  background-color: #1f4072;
  opacity: 0.5;
  max-width: 400px !important;
}

.headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 30%;
}

.user-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5%;
  overflow: hidden;
  z-index: 1;
}

.slider-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.image-container.active {
  opacity: var(--slide-opacity, 1);
}

.slider-nav {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  height: 47px;
  position: absolute;
  padding-right: 40px;
  bottom: 0px;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 5;
}

.slider-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  padding: 0;
  transition: background-color 0.3s ease;
}

.slider-dot.active {
  background: linear-gradient(180deg, #00FEFB 0%, #0067FE 100%);
  box-shadow: 0 0 4px 4px rgba(0, 254, 251, 0.2),
    0 0 4px 4px rgba(0, 103, 254, 0.3);
}

.title {
  max-width: 400px !important;
  color: #e8eaed;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  font-family: "Poster Gothic ExCond ATF";
  font-size: min(8vw, 2.4rem);
}

.subtitle {
  max-width: 300px;
  color: #e8eaed;
  font-size: 24px;
  font-family: "Poster Gothic ExCond ATF";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: right;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.subtitle .highlight-blue {
  background: var(--highlight-blue-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle img {
  width: 2rem;
}

.inner-guest,
.inner-auth {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 3;
  position: relative;
  gap: 1vw;
}

.inner-guest ul {
  display: flex;
  flex-direction: column;
}

.inner-guest ul li {
  color: white;
  font-weight: 500;
  list-style-type: none;
}

.inner-guest button {
  margin-top: 0.5rem;
}

.auth-info {
  display: flex;
  align-items: center;
}

.auth-info span {
  font-size: 1.5vw;
  font-weight: 600;
  color: #ffffff;
}

.info-avatar {
  width: 3vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #9e9e9e;
  overflow: hidden;
}

.auth-level {
  bottom: 0;
  min-width: 250px;
  width: 40%;
  display: flex;
  align-items: center;
  z-index: 1;
  color: white;
  margin-top: 1rem;
}

.level-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background: var(--highlight-blue, #333);
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
  color: var(--highlight-white);
}

.level-progress {
  flex: 1;
  height: 1rem;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0.5rem;
  margin: 0 1rem;
  position: relative;
}

.progress-bar {
  height: 100%;
  background: var(--highlight-blue);
  border-radius: 0.5rem;
}

.progress-text {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.7rem;
  font-weight: bold;
  color: var(--highlight-white);
}

.text-target {
  color: var(--highlight-yellow);
}

.character {
  position: absolute;
  object-fit: contain;
  z-index: 2;
  user-select: none;
  transition: all 0.5s ease-in-out;
  /* Smooth transition between slides */
  left: 31%;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  /* Centers the image */
}

@media only screen and (max-width: 1176px) {
  .auth-info span {
    font-size: 2.25vw;
  }

  .info-avatar {
    width: 4vw;
    height: 4vw;
  }

  .auth-level {
    width: 50%;
  }

  .title {
    max-width: 65%;
  }

  .title,
  .subtitle {
    text-align: left;
  }

  .character {
    right: -50px;
  }

  .headers {
    flex-direction: column;
    gap: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .auth-info span {
    font-size: 3vw;
  }

  .info-avatar {
    width: 6vw;
    height: 6vw;
  }

  .auth-level {
    width: 100%;
  }

  .title {
    max-width: 90%;
  }

  .character {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .inner-auth {
    align-items: center;
  }

  .auth-info span {
    font-size: 4vw;
  }

  .info-avatar {
    width: 8vw;
    height: 8vw;
  }

  .image-container img {
    filter: brightness(0.5);
  }

  .inner-guest ul {
    align-items: center;
  }

  .title {
    max-width: none;
    font-size: 30px;
  }

  .subtitle {
    max-width: 250px;
    font-size: 15px;
    text-align: left;
  }

  .divider {
    display: none;
  }

  .slider-nav {
    background-color: rgba(0, 0, 0, 0);
    height: 27px;
  }

  .subtitle img {
    display: none;
  }

  .character {
    display: none;
  }

  .slider-nav {
    bottom: 10px;
  }

  .slider-dot {
    width: 8px;
    height: 8px;
  }
}
</style>
