const state = {
    bets: null,
    loading: false,
    error: null
};

const getters = {
    slotsBets: state => state
};

const actions = {
    async slotsGetBetsDataSocket({ commit, rootGetters }, data) {
        try {
            commit('SET_LOADING', true);
            rootGetters.socketSlots.emit('slots:getBets', data, (response) => {
                if (response.success) {
                    commit('SET_BETS', response.bets);
                } else {
                    commit('SET_ERROR', response.error);
                }
            });
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_LOADING', false);
        }
    }
};

const mutations = {
    SET_BETS(state, bets) {
        // Transform slots bets to match the general bet format
        state.bets = bets.map(bet => ({
            _id: bet.txId,
            user: bet.playerId,
            method: 'slots',
            amount: bet.type === 'bet' ? bet.amount : 0,
            payout: bet.type === 'win' ? bet.amount : 0,
            multiplier: bet.type === 'win' ? (bet.amount / bet.balanceChanges.main) * 100 : 0,
            updatedAt: bet.timestamp,
            gameId: bet.gameId,
            provider: bet.provider,
            roundId: bet.roundId,
            type: bet.type,
            reason: bet.reason,
            currency: bet.currency,
            balanceChanges: bet.balanceChanges,
            freeSpinData: bet.freeSpinData
        }));
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}; 