export default {
    state: {
        rakebackData: {
            totalWagered: 0,
            availableRakeback: 0,
            currentTier: null,
            nextTier: null,
            lastClaims: {
                weekly: null,
                reload: null,
                birthday: null
            }
        }
    },
    mutations: {
        rakeback_set_data(state, data) {
            state.rakebackData = {
                ...state.rakebackData,
                ...data
            };
        }
    },
    actions: {
        async rakebackGetDataSocket({ commit, rootGetters, rootState }) {
            try {
                // Initialize with user data we already have
                const currentUser = rootState.auth?.user;
                if (currentUser) {
                    commit('rakeback_set_data', {
                        totalWagered: currentUser.stats?.bet || 0,
                        availableRakeback: currentUser.rakeback?.available || 0,
                        currentTier: currentUser.tier || null,
                        lastClaims: {
                            weekly: currentUser.rakeback?.lastWeeklyClaim || null,
                            reload: currentUser.rakeback?.lastReloadClaim || null,
                            birthday: currentUser.rakeback?.lastBirthdayClaim || null
                        }
                    });
                }

                // Ensure socket is connected
                if (!rootGetters.socketGeneral?.connected) {
                    await this.dispatch('socketConnectGeneral');
                }

                return new Promise((resolve, reject) => {
                    const timeout = setTimeout(() => {
                        reject(new Error('Socket request timeout'));
                    }, 10000);

                    const socket = rootGetters.socketGeneral;
                    if (!socket) {
                        clearTimeout(timeout);
                        reject(new Error('Socket not available'));
                        return;
                    }

                    socket.emit('getRakebackData', {}, (response) => {
                        clearTimeout(timeout);
                        
                        if (response.success) {
                            const userData = response.user || {};
                            commit('rakeback_set_data', {
                                totalWagered: userData.stats?.bet || 0,
                                availableRakeback: userData.rakeback?.available || 0,
                                currentTier: userData.tier || null,
                                nextTier: response.nextTier || null,
                                lastClaims: {
                                    weekly: userData.rakeback?.lastWeeklyClaim || null,
                                    reload: userData.rakeback?.lastReloadClaim || null,
                                    birthday: userData.rakeback?.lastBirthdayClaim || null
                                }
                            });
                            resolve(response);
                        } else {
                            reject(response.error || { message: 'Failed to fetch rakeback data' });
                        }
                    });
                });
            } catch (error) {
                console.error('Error fetching rakeback data:', error);
                throw error;
            }
        },

        async rakebackSendClaimSocket({ rootGetters }, { type }) {
            try {
                if (!rootGetters.socketGeneral?.connected) {
                    await this.dispatch('socketConnectGeneral');
                }

                return new Promise((resolve, reject) => {
                    const timeout = setTimeout(() => {
                        reject(new Error('Socket request timeout'));
                    }, 10000);

                    const socket = rootGetters.socketGeneral;
                    if (!socket) {
                        clearTimeout(timeout);
                        reject(new Error('Socket not available'));
                        return;
                    }

                    socket.emit('claimRakeback', { type }, (response) => {
                        clearTimeout(timeout);
                        
                        if (response.success) {
                            resolve(response);
                        } else {
                            reject(response.error || { message: `Failed to claim ${type} rakeback` });
                        }
                    });
                });
            } catch (error) {
                console.error('Error claiming rakeback:', error);
                throw error;
            }
        }
    },
    getters: {
        getRakebackData: state => state.rakebackData
    }
};
