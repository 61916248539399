<template>
  <div class="modal-cashier">
    <div class="cashier-header">
      <!-- Navigation Buttons -->
      <div class="nav-buttons">
        <button 
          v-for="tab in tabs" 
          :key="tab.id"
          @click="modalSetTab(tab.id)"
          class="nav-button"
          :class="{ 'active': modalTab === tab.id }"
        >
          <div class="button-inner">
            <span>{{ tab.label }}</span>
          </div>
        </button>
      </div>

      <!-- Balance and Close Button Container -->
      <div class="header-right">
        <!-- Balance Display -->
        <div class="cashier-balance">
          <div class="balance-inner">
            <img src="@/assets/img/icons/coin.svg" alt="icon" />
            <AmountNumber :amount="authUser.user.balance" />
          </div>
        </div>
      </div>
    </div>

    <!-- Content Area -->
    <div class="cashier-content">
      <transition name="fade" mode="out-in">
        <CashierDeposit 
          v-if="modalTab === 'deposit'"
          :currencies="availableCurrencies"
        />
        <CashierCreditCard
          v-else-if="modalTab === 'credit-card'"
        />
        <CashierWithdraw 
          v-else-if="modalTab === 'withdraw'"
          :currencies="availableCurrencies"
        />
        <TransactionHistory v-else-if="modalTab === 'history'"/>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import CashierDeposit from '@/components/cashier/CashierDeposit';
import CashierWithdraw from '@/components/cashier/CashierWithdraw';
import TransactionHistory from '@/components/cashier/TransactionHistory/TransactionHistory';
import CashierCreditCard from '@/components/cashier/CashierCreditCard';
import AmountNumber from "@/components/AmountNumber";
import nowPaymentsService from '@/services/nowPayments';

export default {
  name: 'ModalCashier',
  
  components: {
    CashierDeposit,
    CashierWithdraw,
    TransactionHistory,
    CashierCreditCard,
    AmountNumber
  },

  data() {
    return {
      modalTab: 'deposit',
      availableCurrencies: [],
      tabs: [
        {
          id: 'deposit',
          label: 'DEPOSIT',
          icon: 'fas fa-arrow-down'
        },
        {
          id: 'credit-card',
          label: 'CREDIT CARD',
          icon: 'fas fa-credit-card'
        },
        {
          id: 'withdraw',
          label: 'WITHDRAW',
          icon: 'fas fa-arrow-up'
        },
        {
          id: 'history',
          label: 'HISTORY',
          icon: 'fas fa-history'
        },
        {
          id: 'code',
          label: 'CODE',
          icon: 'fas fa-gift'
        }
      ]
    };
  },

  computed: {
    ...mapGetters([
      'modalsData',
      'authUser'
    ]),
    userBalance() {
      return parseFloat(Math.floor(this.authUser.user.balance / 10) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },

  methods: {
    ...mapActions(['notificationShow']),
    
    modalSetTab(tab) {
      if (tab === 'code') {
        this.$emit('close');
        this.$store.dispatch('modalsSetShow', 'Claim');
        return;
      }
      this.modalTab = tab;
    },

    async loadCurrencies() {
      try {
        const response = await nowPaymentsService.getAvailableCurrencies();
        this.availableCurrencies = response.currencies.filter(c => 
          c.enable && c.merchant_enabled
        );
      } catch (error) {
        console.error('Error loading currencies:', error);
        this.notificationShow({
          type: 'error',
          message: 'Failed to load currencies'
        });
      }
    }
  },

  created() {
    if (this.modalsData?.typeCashier) {
      this.modalTab = this.modalsData.typeCashier;
    }
    this.loadCurrencies();
  }
};
</script>

<style scoped>
.modal-cashier {
  width: 820px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
  overflow: hidden;
}

.cashier-header {
  padding: 20px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.header-right {
  display: flex;
  align-items: center;
  padding-right: 56px;
}

.cashier-balance {
  display: flex;
  align-items: center;
}

.balance-inner {
  height: 44px;
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 0 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.balance-inner img {
  width: 24px;
  height: 24px;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
}

.nav-buttons {
  display: flex;
  gap: 8px;
}

.nav-button {
  padding: 10px 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.2s ease;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
}

.nav-button .button-inner {
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav-button i {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.nav-button span {
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.2px;
}

.nav-button.active {
  background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
  border-color: rgba(44, 94, 255, 0.3);
  box-shadow: 0px 4px 12px rgba(44, 94, 255, 0.25);
}

.nav-button.active i,
.nav-button.active span {
  color: rgba(255, 255, 255, 0.9);
}

.nav-button:not(.active):hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(31, 42, 68, 0.4) 100%);
  transform: translateY(-1px);
}

.cashier-content {
  padding: 20px;
  min-height: 400px;
  max-height: 80vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
}

.cashier-content::-webkit-scrollbar {
  width: 6px;
}

.cashier-content::-webkit-scrollbar-track {
  background: transparent;
}

.cashier-content::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  border-radius: 3px;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Responsive Design */
@media only screen and (max-width: 840px) {
  .modal-cashier {
    width: calc(100vw - 32px);
    max-height: calc(100vh - 32px);
  }

  .cashier-header {
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    padding-top: 64px;
  }

  .header-right {
    width: 100%;
    justify-content: center;
    padding-right: 0;
  }

  .nav-buttons {
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
  }

  .nav-button {
    flex: 1;
    min-width: calc(33.33% - 8px);
    max-width: calc(33.33% - 8px);
    padding: 8px;
  }

  .nav-button .button-inner {
    justify-content: center;
  }

  .cashier-balance {
    width: auto;
  }

  .balance-inner {
    width: auto;
    min-width: 160px;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .nav-button {
    min-width: calc(50% - 4px);
    max-width: calc(50% - 4px);
  }
}

@media only screen and (max-width: 480px) {
  .modal-cashier {
    width: calc(100vw - 16px);
    max-height: calc(100vh - 16px);
  }

  .cashier-header {
    padding: 12px;
    padding-top: 56px;
  }

  .cashier-content {
    padding: 12px;
  }

  .nav-button span {
    font-size: 12px;
  }

  .balance-inner {
    height: 40px;
    padding: 0 12px;
    min-width: 140px;
  }

  .balance-inner img {
    width: 20px;
    height: 20px;
  }

  .header-right {
    padding-right: 0;
  }
}

@media only screen and (max-width: 360px) {
  .nav-button {
    min-width: 100%;
    max-width: 100%;
  }

  .cashier-header {
    padding-top: 52px;
  }

  .balance-inner {
    min-width: 120px;
  }
}
</style>

