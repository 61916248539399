const state = {
  balance: 0,
  lastBet: null,
  lastSettlement: null
}

const mutations = {
  SET_BALANCE(state, balance) {
    state.balance = balance
  },
  SET_LAST_BET(state, bet) {
    state.lastBet = bet
  },
  SET_LAST_SETTLEMENT(state, settlement) {
    state.lastSettlement = settlement
  }
}

const actions = {
  updateBalance({ commit }, balance) {
    commit('SET_BALANCE', balance)
  },
  recordBet({ commit }, bet) {
    commit('SET_LAST_BET', bet)
  },
  recordSettlement({ commit }, settlement) {
    commit('SET_LAST_SETTLEMENT', settlement)
  },
  updateUserBalance({ commit }, balance) {
    commit('SET_BALANCE', balance)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}