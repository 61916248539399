<template>
    <div class="affiliates">
        <AffiliatesBanner />
        <div class="tabs-container">
            <div class="tabs">
                <div 
                    v-for="(tab, index) in tabs" 
                    :key="index"
                    :class="['tab', { active: currentTab === tab.name }]"
                    @click="currentTab = tab.name"
                >
                    <font-awesome-icon :icon="tab.icon" />
                    <span>{{ tab.label }}</span>
                </div>
            </div>
            
            <div class="tab-content">
                <transition name="fade" mode="out-in">
                    <component :is="currentComponent" />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { 
        faChartLine,
        faCode,
        faChartBar,
        faUsers,
        faQuestionCircle
    } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import AffiliatesBanner from '@/components/affiliates/AffiliatesBanner';
    import AffiliatesOverview from '@/components/affiliates/AffiliatesOverview';
    import AffiliatesCode from '@/components/affiliates/AffiliatesCode';
    import AffiliatesStats from '@/components/affiliates/AffiliatesStats';
    import AffiliatesReferred from '@/components/affiliates/AffiliatesReferred';
    import AffiliatesFAQ from '@/components/affiliates/AffiliatesFAQ';
    import socketService from '@/services/socket';

    // Add icons to library
    library.add(
        faChartLine,
        faCode,
        faChartBar,
        faUsers,
        faQuestionCircle
    );

    export default {
        name: 'Affiliates',
        metaInfo: {
            title: 'Affiliates - Bazedbet'
        },
        components: {
            FontAwesomeIcon,
            AffiliatesBanner,
            AffiliatesOverview,
            AffiliatesCode,
            AffiliatesStats,
            AffiliatesReferred,
            AffiliatesFAQ
        },
        data() {
            return {
                isLoading: true,
                currentTab: 'overview',
                tabs: [
                    { name: 'overview', label: 'Overview', icon: ['fas', 'chart-line'] },
                    { name: 'code', label: 'Code', icon: ['fas', 'code'] },
                    { name: 'stats', label: 'Claim', icon: ['fas', 'coins'] },
                    { name: 'referred', label: 'Referred', icon: ['fas', 'users'] }
                ]
            };
        },
        methods: {
            ...mapActions(['affiliatesGetDataSocket']),
            
            initializeSocket() {
                try {
                    socketService.on('affiliates:update', (data) => {
                        this.affiliatesGetDataSocket(data);
                    });
                } catch (error) {
                    console.error('Error initializing affiliates socket:', error);
                }
            }
        },
        computed: {
            ...mapGetters(['affiliatesData']),
            currentComponent() {
                const components = {
                    'overview': 'AffiliatesOverview',
                    'code': 'AffiliatesCode',
                    'stats': 'AffiliatesStats',
                    'referred': 'AffiliatesReferred'
                };
                return components[this.currentTab];
            }
        },
        created() {
            this.initializeSocket();
            this.affiliatesGetDataSocket({});
            this.isLoading = false;
        },
        mounted() {
            this.affiliatesGetDataSocket({});
        },
        beforeDestroy() {
            socketService.off('affiliates:update');
        }
    }
</script>

<style scoped>
    .affiliates {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 65px 24px 45px 24px;
        min-height: 100vh;
        background: var(--background-primary);
    }

    .tabs-container {
        width: 100%;
        max-width: 1200px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .tabs {
        display: flex;
        gap: 2px;
        background: rgba(13, 23, 39, 0.3);
        padding: 4px;
        border-radius: 12px;
        margin: 0 auto;
        width: fit-content;
        box-shadow: 
            0px 4px 4px 0px rgba(0, 0, 0, 0.25),
            0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
            0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
    }

    .tab {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 12px 24px;
        cursor: pointer;
        color: #8bacc8;
        font-weight: 600;
        border-radius: 8px;
        transition: all 0.3s ease;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        white-space: nowrap;
    }

    .tab:hover {
        background: rgba(44, 94, 255, 0.1);
        color: #ffffff;
    }

    .tab.active {
        background: linear-gradient(180deg, rgba(52, 157, 255, 0.1) 0%, rgba(0, 94, 233, 0.1) 100%);
        border: 1px solid rgba(52, 157, 255, 0.3);
        color: #349DFF;
        box-shadow: 0px 0px 4px rgba(52, 157, 255, 0.5);
    }

    .tab.active .svg-inline--fa {
        filter: drop-shadow(0px 0px 4px rgba(52, 157, 255, 0.5));
    }

    .tab-content {
        width: 100%;
    }

    /* Transitions */
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.3s ease;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    /* Mobile Responsive Styles */
    @media only screen and (max-width: 1150px) {
        .affiliates {
            padding: 25px 16px 45px 16px;
        }

        .tabs {
            width: 100%;
            overflow-x: auto;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
            scrollbar-width: none;
            -ms-overflow-style: none;
            padding: 8px;
        }

        .tabs::-webkit-scrollbar {
            display: none;
        }

        .tab {
            padding: 10px 16px;
            font-size: 13px;
        }
    }

    @media only screen and (max-width: 768px) {
        .affiliates {
            padding: 25px 12px 45px 12px;
        }

        .tabs-container {
            margin-top: 10px;
            gap: 16px;
        }

        .tab {
            padding: 8px 12px;
        }

        .tab span {
            display: none;
        }

        .tab .svg-inline--fa {
            margin: 0;
            font-size: 16px;
        }
    }

    /* Icon Styles */
    .svg-inline--fa {
        width: 16px;
        height: 16px;
        color: currentColor;
    }
</style>
