var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blackjack-game"},[_c('div',{staticClass:"game-inner"},[(_vm.table.game.state === 'created')?_c('div',{staticClass:"inner-created"},[_vm._v(" WAITING FOR PLAYERS TO JOIN... ")]):_vm._e(),(
        _vm.authUser.user !== null &&
        _vm.table.players.some(
          (element) => element.user._id === _vm.authUser.user._id
        ) === true &&
        _vm.table.game.state === 'countdown'
      )?_c('BlackjackControlsBet',{attrs:{"table":_vm.table,"blackjackChip":_vm.blackjackChip}}):(
        _vm.authUser.user !== null &&
        _vm.table.game.state === 'running' &&
        _vm.table.playersPos !== null &&
        (_vm.table.playersPos === 'all' ||
          _vm.authUser.user._id ===
            _vm.blackjackGetPlayer(_vm.table.playersPos).user._id) &&
        new Date().getTime() + _vm.generalTimeDiff <=
          new Date(_vm.table.game.updatedAt).getTime() + 1000 * 10
      )?_c('BlackjackControlsAction',{attrs:{"table":_vm.table}}):_vm._e()],1),(['running', 'completed'].includes(_vm.table.game.state) === true)?_c('div',{staticClass:"game-dealer"},[_c('transition-group',{staticClass:"dealer-cards",attrs:{"name":"dealer","tag":"div"}},_vm._l((_vm.table.game.dealerCards),function(card,index){return _c('BlackjackCard',{key:index + card.suit + card.rank,attrs:{"card":card}})}),1),_c('BlackjackValue',{class:{
        'value-blackjack':
          _vm.table.game.dealerCards.length === 2 &&
          _vm.blackjackGetCardValue(_vm.table.game.dealerCards) === 21,
      },style:({
        top: 84 + (_vm.table.game.dealerCards.length - 1) * 15 + 'px',
        left: 60 + (_vm.table.game.dealerCards.length - 1) * 20 + 'px',
      }),attrs:{"value":_vm.blackjackGetCardValue(_vm.table.game.dealerCards),"state":""}})],1):_vm._e(),_c('div',{staticClass:"game-seats"},_vm._l((5),function(index){return _c('BlackjackSeatElement',{key:index,attrs:{"table":_vm.table,"seat":index - 1,"player":_vm.blackjackGetPlayer(index - 1)}})}),1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }