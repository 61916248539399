<template>
  <div class="games-list">
    <!-- Games Grid -->
    <div 
      class="games-grid"
      ref="gamesGrid"
      @scroll="handleScroll"
    >
      <!-- No Games Found State -->
      <div v-if="!isLoading('all') && displayedGames.length === 0" class="no-games-state">
        <font-awesome-icon icon="dice-d20" class="no-games-icon" />
        <h3 class="no-games-title">No Games Found</h3>
        <p class="no-games-text">Try adjusting your filters or start fresh</p>
        <button @click="resetToAllGames" class="reload-button">
          <font-awesome-icon icon="sync" />
          Show All Games
        </button>
      </div>

      <div 
        v-else
        v-for="game in displayedGames" 
        :key="game.gameId"
        class="game-card"
        @click="handleGameSelect(game)"
      >
        <img 
          :src="game.imageUrl" 
          :alt="game.translationKey"
          class="game-image"
          loading="lazy"
        >
        <div class="game-info">
          <h3 class="game-title">{{ game.translationKey }}</h3>
          <p class="game-provider">{{ game.gameProvider }}</p>
        </div>
      </div>

      <!-- Loading Indicator -->
      <div v-if="isLoading('all')" class="loading-indicator">
        <div class="spinner"></div>
        <p>Loading more games...</p>
      </div>
    </div>

    <!-- Error State -->
    <div v-if="hasError('all') && errorMessage('all')" class="error-state">
      <i class="fas fa-exclamation-circle"></i>
      <p>{{ errorMessage('all') }}</p>
      <button @click="retryLoading" class="retry-button">
        <i class="fas fa-redo"></i>
        Retry
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'EVAGamesList',

  data() {
    return {
      isLoadingMore: false,
      scrollThreshold: 200 // pixels from bottom to trigger load more
    }
  },

  computed: {
    ...mapState('eva', ['totalItems', 'totalServerPages']),
    ...mapGetters('eva', [
      'displayedGames',
      'hasNextPage',
      'hasPreviousPage',
      'totalDisplayPages',
      'currentDisplayPage',
      'isLoading',
      'hasError',
      'errorMessage'
    ])
  },

  methods: {
    ...mapActions('eva', [
      'fetchGames',
      'setDisplayPage',
      'setFilters'
    ]),

    handleGameSelect(game) {
      this.$emit('game-selected', game)
    },

    async handleScroll(event) {
      const element = event.target
      const bottomSpace = element.scrollHeight - element.scrollTop - element.clientHeight

      if (bottomSpace < this.scrollThreshold && !this.isLoadingMore && this.hasNextPage && !this.isLoading('all')) {
        this.isLoadingMore = true
        try {
          const nextPage = this.currentDisplayPage + 1
          await this.setDisplayPage(nextPage)
          console.log('[EVA GamesList] Loaded more games:', {
            page: nextPage,
            gamesCount: this.displayedGames.length
          })
        } catch (error) {
          console.error('[EVA GamesList] Error loading more games:', error)
        } finally {
          this.isLoadingMore = false
        }
      }
    },

    async retryLoading() {
      console.log('[EVA GamesList] Retrying games fetch')
      try {
        await this.fetchGames()
      } catch (error) {
        console.error('[EVA GamesList] Retry failed:', error)
      }
    },

    async resetToAllGames() {
      // Reset all filters and load all games
      await this.$store.dispatch('eva/setFilters', {
        filters: {
          size: 500,
          channel: 'desktop',
          isActive: true
        },
        target: 'all',
        forceRefresh: true
      });

      // Clear URL parameters
      const url = new URL(window.location);
      url.searchParams.clear();
      window.history.pushState({}, '', url);

      // Emit event to reset filters in parent component
      this.$root.$emit('category-selected', '');
    }
  },

  async created() {
    console.log('[EVA GamesList] Component created, fetching initial games')
    await this.fetchGames()
  }
}
</script>

<style scoped>
.games-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(170px, 48vw), 1fr));
  grid-auto-flow: dense;
  gap: 1rem;
  padding: 0.25rem;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
}

.games-grid::-webkit-scrollbar {
  width: 6px;
}

.games-grid::-webkit-scrollbar-track {
  background: transparent;
}

.games-grid::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

/* Loading Indicator */
.loading-indicator {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  color: rgba(255, 255, 255, 0.6);
}

.game-card {
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.75rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  aspect-ratio: 16/9;
}

.game-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.game-image {
  width: 100%;
  aspect-ratio: 16/9;
  transition: transform 0.3s ease;
}

.game-card:hover .game-image {
  transform: scale(1.05);
}

.game-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7) 50%, transparent);
  transform: translateY(100%);
  transition: transform 0.2s ease;
}

.game-card:hover .game-info {
  transform: translateY(0);
}

.game-title {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.game-provider {
  margin: 0.25rem 0 0;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

/* Loading State */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 3rem;
  color: rgba(255, 255, 255, 0.6);
}

.spinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.25rem solid rgba(52, 157, 255, 0.1);
  border-left-color: #349DFF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Error State */
.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 3rem;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.error-state i {
  font-size: 2rem;
  color: #FF4D4D;
}

.retry-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.retry-button:hover {
  background: rgba(255, 255, 255, 0.15);
}

/* Pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

.page-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.page-button:not(:disabled):hover {
  background: rgba(255, 255, 255, 0.15);
}

.page-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-info {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.875rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.no-games-state {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 1rem;
  margin: 1rem;
}

.no-games-icon {
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.2);
  margin-bottom: 1rem;
}

.no-games-title {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.75rem;
  letter-spacing: -0.01em;
}

.no-games-text {
  font-size: 0.9375rem;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 1.5rem;
  line-height: 1.5;
  max-width: 280px;
}

.reload-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem;
  background: linear-gradient(90deg, #2c5eff 0%, #1d43c0 100%);
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  letter-spacing: 0.01em;
}

.reload-button:hover {
  filter: brightness(1.1);
  transform: translateY(-1px);
}

.reload-button .svg-inline--fa {
  width: 1rem;
  height: 1rem;
}

@media (max-width: 425px) {
  .games-grid {
    grid-template-columns: repeat(auto-fill, minmax(min(170px, 42vw), 1fr));
  }

  .game-card {
    width: 140px;
    height: 176px;
  }
}

@media (max-width: 350px) {
  .games-grid {
    grid-template-columns: repeat(auto-fill, minmax(min(170px, 35vw), 1fr));
  }

  .game-card {
    width: 120px;
    height: 150px;
  }
}
</style> 