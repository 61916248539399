<template>
    <div class="steps-container">
        <div class="steps-grid">
            <div class="step-item">
                <div class="step-number">1</div>
                <div class="step-content">
                    <h3>Get Your Code</h3>
                    <p>Generate your unique referral code and share it with friends</p>
                    <div class="step-icon">
                        <font-awesome-icon :icon="['fas', 'link']" />
                    </div>
                </div>
            </div>
            <div class="step-item">
                <div class="step-number">2</div>
                <div class="step-content">
                    <h3>Friends Play</h3>
                    <p>When your friends play using your code, you earn commission</p>
                    <div class="step-icon">
                        <font-awesome-icon :icon="['fas', 'gamepad']" />
                    </div>
                </div>
            </div>
            <div class="step-item">
                <div class="step-number">3</div>
                <div class="step-content">
                    <h3>Earn Rewards</h3>
                    <p>Claim your earnings instantly!</p>
                    <div class="step-icon">
                        <font-awesome-icon :icon="['fas', 'coins']" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AffiliatesSteps'
}
</script>

<style scoped>
.steps-container {
    width: 100%;
    max-width: 1200px;
    margin: 24px auto;
}

.steps-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}

.step-item {
    position: relative;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(44, 94, 255, 0.05) 100%);
    border-radius: 16px;
    padding: 24px;
    border: 1px solid rgba(44, 94, 255, 0.1);
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
    transition: all 0.3s ease;
}

.step-item:hover {
    transform: translateY(-2px);
    border-color: rgba(44, 94, 255, 0.3);
    box-shadow: 
        0px 8px 16px rgba(0, 0, 0, 0.3),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset,
        0px 0px 20px rgba(44, 94, 255, 0.1);
}

.step-number {
    position: absolute;
    top: -16px;
    left: 24px;
    width: 32px;
    height: 32px;
    background: linear-gradient(135deg, #2C5EFF 0%, #2C5EFF 100%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(44, 94, 255, 0.3);
}

.step-content {
    text-align: center;
    padding-top: 24px;
}

.step-content h3 {
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    margin: 0 0 12px 0;
}

.step-content p {
    font-family: Inter, sans-serif;
    font-size: 14px;
    color: #8BACC8;
    margin: 0;
    line-height: 1.5;
}

.step-icon {
    margin-top: 20px;
    font-size: 32px;
    color: #2C5EFF;
    opacity: 0.8;
}

@media only screen and (max-width: 1024px) {
    .steps-grid {
        gap: 16px;
    }
    
    .step-item {
        padding: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .steps-grid {
        grid-template-columns: 1fr;
        max-width: 400px;
        margin: 0 auto;
    }
}
</style> 