var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-fair"},[_vm._m(0),_c('div',{staticClass:"fair-info"},[_vm._v(" Yo degens! We're all about that transparent gambling life here! 🎲 Before you ape in with your bets, we've already cooked up the results and locked them in with some serious crypto magic. We give you the hashed outcome upfront (think of it as a sealed envelope), then after the game we reveal all the juicy details - server seed, public seed, everything! You can verify it yourself that we're keeping it 100% legit. No cap, just pure provably fair gaming for the true degens! 🚀 ")]),_c('div',{staticClass:"fair-nav"},[_c('button',{class:{ 'button-active': _vm.modalGame === 'Blackjack' },on:{"click":function($event){return _vm.modalSetGame('Blackjack')}}},[_vm._m(1)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Roll' },on:{"click":function($event){return _vm.modalSetGame('Roll')}}},[_vm._m(2)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Crash' },on:{"click":function($event){return _vm.modalSetGame('Crash')}}},[_vm._m(3)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Duels' },on:{"click":function($event){return _vm.modalSetGame('Duels')}}},[_vm._m(4)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Mines' },on:{"click":function($event){return _vm.modalSetGame('Mines')}}},[_vm._m(5)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Towers' },on:{"click":function($event){return _vm.modalSetGame('Towers')}}},[_vm._m(6)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Battles' },on:{"click":function($event){return _vm.modalSetGame('Battles')}}},[_vm._m(7)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Unbox' },on:{"click":function($event){return _vm.modalSetGame('Unbox')}}},[_vm._m(8)])]),_c('div',{staticClass:"fair-content"},[_c('Fair' + _vm.modalGame,{tag:"component"})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fair-header"},[_c('span',{staticClass:"gradient-blue"},[_vm._v("PROVABLY FAIR")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("BLACKJACK")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("ROLL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("CRASH")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("DICE DUELS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("MINES")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("TOWERS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("BATTLES")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("UNBOX")])])
}]
render._withStripped = true
export { render, staticRenderFns }