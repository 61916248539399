<template>
  <div class="roll-history">
    <transition-group class="history-list" name="list" tag="div">
      <RollHistoryElement
        v-for="game of rollHistory"
        v-bind:key="game._id"
        v-bind:game="game"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RollHistoryElement from "@/components/roll/RollHistoryElement";

export default {
  name: "RollHistory",
  components: {
    RollHistoryElement,
  },
  computed: {
    ...mapGetters(["rollHistory"]),
  },
};
</script>

<style scoped>
.roll-history {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 18px;
  border-radius: 1.25rem;
  border: 3px solid #ebebeb;
  background: #101717;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.roll-history .history-title {
  margin-right: 15px;
  font-size: 12px;
  font-weight: 600;
  color: var(--highlight-blue);
}

.roll-history .history-list {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.roll-history .list-enter-active {
  transition: all 0.5s;
}

.roll-history .list-enter {
  opacity: 0;
  transform: translateX(-15px);
}

@media only screen and (max-width: 750px) {
  .roll-history {
    flex-direction: column;
  }
}
</style>
