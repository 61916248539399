import { render, staticRenderFns } from "./UnboxControls.vue?vue&type=template&id=44c87f2b&scoped=true"
import script from "./UnboxControls.vue?vue&type=script&lang=js"
export * from "./UnboxControls.vue?vue&type=script&lang=js"
import style0 from "./UnboxControls.vue?vue&type=style&index=0&id=44c87f2b&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c87f2b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('44c87f2b')) {
      api.createRecord('44c87f2b', component.options)
    } else {
      api.reload('44c87f2b', component.options)
    }
    module.hot.accept("./UnboxControls.vue?vue&type=template&id=44c87f2b&scoped=true", function () {
      api.rerender('44c87f2b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/unbox/UnboxControls.vue"
export default component.exports