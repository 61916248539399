var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roll-card",class:{
    'card-high': _vm.multiplier >= _vm.rollGetMinBetMultiplier && _vm.multiplier >= 20,
    'card-mid': _vm.multiplier >= _vm.rollGetMinBetMultiplier && _vm.multiplier < 20 && _vm.multiplier >= 5,
    'card-low': _vm.multiplier >= _vm.rollGetMinBetMultiplier && _vm.multiplier < 5 && _vm.multiplier >= 2
}},[_c('div',{staticClass:"card-inner"},[_c('div',{staticClass:"inner-amount"},[_c('img',{attrs:{"src":require("@/assets/img/icons/coin.svg"),"alt":"icon"}}),_c('div',{staticClass:"amount-value"},[_c('span',[_vm._v(_vm._s(_vm.rollFormatValue(_vm.rollGetPotential).split('.')[0]))]),_vm._v("."+_vm._s(_vm.rollFormatValue(_vm.rollGetPotential).split('.')[1])+" ")])]),_c('div',{staticClass:"inner-multiplier"},[_vm._v(_vm._s(parseFloat(_vm.multiplier).toFixed(2))+"x")]),(_vm.multiplier >= _vm.rollGetMinBetMultiplier)?_c('div',{staticClass:"inner-win"},[_c('div',{staticClass:"win-image",style:({ 
                'background-image': 'url(' + _vm.rollGetItem.image + ')',
                'background-repeat': 'no-repeat',
                'background-position': 'center',
                'background-size': '80px auto'
            })}),_vm._v(" "+_vm._s(_vm.rollGetItem.name)+" ")]):_c('div',{staticClass:"inner-lose"},[_c('svg',{attrs:{"width":"104","height":"104","viewBox":"0 0 104 104","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M52 0C23.3285 0 0 23.3285 0 52C0 80.6715 23.3285 104 52 104C80.6715 104 104 80.6715 104 52C104 23.3285 80.6715 0 52 0ZM26 39C26 35.4185 28.9185 32.5 32.5 32.5C36.0815 32.5 39 35.4185 39 39C39 42.5815 36.0815 45.5 32.5 45.5C28.9185 45.5 26 42.5815 26 39ZM74.9775 79.118C74.3405 79.755 73.5085 80.067 72.6765 80.067C71.8445 80.067 71.0125 79.7485 70.3755 79.118C60.5475 69.29 43.4265 69.29 33.605 79.118C32.3375 80.3855 30.277 80.3855 29.0095 79.118C27.742 77.8505 27.742 75.79 29.0095 74.5225C35.1585 68.3865 43.316 65 52 65C60.684 65 68.8415 68.3865 74.9775 74.5225C76.245 75.79 76.245 77.8505 74.9775 79.118ZM71.5 45.5C67.9185 45.5 65 42.5815 65 39C65 35.4185 67.9185 32.5 71.5 32.5C75.0815 32.5 78 35.4185 78 39C78 42.5815 75.0815 45.5 71.5 45.5Z"}})])])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }