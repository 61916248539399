var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"crash"},[_c('div',{staticClass:"black-gradient"}),_vm._m(0),_c('div',{staticClass:"planets"},_vm._l((_vm.planets),function(planet,index){return _c('img',{key:index,staticClass:"planet-animation",style:({
        top: planet.top,
        left: planet.left,
        width: planet.size,
        height: planet.size,
      }),attrs:{"src":planet.src,"alt":"Planet"}})}),0),_c('div',{staticClass:"crash-container"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.socketCrash.connected === false)?_c('div',{key:"loading",staticClass:"container-loading"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","width":"24","height":"24","stroke-width":"2"}},[_c('path',{attrs:{"d":"M12 3a9 9 0 1 0 9 9"}})])]):_c('div',{key:"data",staticClass:"container-data"},[_c('CrashControls'),_c('div',{staticClass:"data-left"},[_c('div',{staticClass:"game-wrapper"},[_c('CrashHistory'),_c('CrashGame')],1)])],1)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clouds"},[_c('img',{staticClass:"cloud-animation first-cloud",attrs:{"src":require("../assets/img/crash/cloud.png"),"alt":"Cloud"}}),_c('img',{staticClass:"cloud-animation second-cloud",attrs:{"src":require("../assets/img/crash/cloud.png"),"alt":"Cloud"}})])
}]
render._withStripped = true
export { render, staticRenderFns }