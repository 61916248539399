<template>
  <div class="futures-container">
    <iframe
      :src="futuresUrl"
      frameborder="0"
      class="futures-iframe"
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Futures",
  computed: {
    ...mapGetters(["authUser"]),
    futuresUrl() {
      return process.env.VUE_APP_FUTURES_URL;
    }
  },
  mounted() {
    this.bazedFuturesJwt();
  },
  methods: {
    setCookie(name, value, days = 7, domain = null) {
      const d = new Date();
      d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = `expires=${d.toUTCString()}`;
      const domainStr = domain ? `; domain=${domain}` : '';
      document.cookie = `${name}=${value}; ${expires}; path=/; secure; SameSite=Strict${domainStr}`;
    },
    async bazedFuturesJwt() {
      const jwt = await axios.get(
        `${process.env.VUE_APP_BACKEND_URL}/api/bazedfutures/jwt/${localStorage.getItem('token')}`
      );
      this.setCookie('bazed-futures-token', jwt.data.generatedToken, 7, process.env.VUE_APP_COOKIE_DOMAIN);
    },
  },
};
</script>

<style scoped>
.futures-container {
  width: 100%;
  height: calc(100vh - 125px);
  position: relative;
}

.futures-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 768px) {
  .futures-container {
    height: calc(100vh - 100px);
  }
}
</style>
