<template>
    <Card :title="'24H Crypto Futures Revenue'" :label="cryptoLabel" :percentage="cryptoPercentage">
        <LineChart class="chart" :chart-options="ChartOptions" :chart-data="CryptoChartData" />
    </Card>
</template>

<script>
import Card from "@/components/buyback/BuybackCardElement.vue";
import LineChart from '@/components/LineChart';
import {
    cryptoLabel,
    cryptoPercentage,
    cryptoRevenue,
    cryptoBurnt,
} from '../../views/buyback/DataConfig';

export default {
    name: 'BuybackCryptoCard',
    components: {
        Card,
        LineChart,
    },
    data() {
        return {
            cryptoLabel,
            cryptoPercentage,
            ChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                layout: {
                    padding: {
                        left: 5,
                    },
                },
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        grid: { display: false },
                        position: 'right',
                        title: {
                            display: true,
                        },
                        ticks: {
                            callback: function (value) {
                                return `$${value / 1000}k`;
                            },
                        },
                    },
                    x: {
                        display: true,
                        grid: { display: false },
                        title: {
                            display: true,
                        },
                    },
                },

            },
            CryptoChartData: {
                labels: ['12:00', '18:00', '20:00'],
                datasets: [
                    {
                        label: 'Revenue',
                        data: cryptoRevenue,
                        borderColor: 'rgba(1, 150, 254, 1)',
                        backgroundColor: 'rgba(1, 150, 254, 0.2)',
                        fill: false,
                        tension: 0.4,
                    },
                    {
                        label: 'Burnt',
                        data: cryptoBurnt,
                        borderColor: 'rgba(255, 143, 49, 1)',
                        backgroundColor: 'rgba(255, 143, 49, 0.3)',
                        fill: false,
                        tension: 0.4,
                    },
                ],
            },
        };
    },

}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-top: 10px;
}
</style>
