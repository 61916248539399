<template>
  <div id="app">
    <!-- Add password protection layer -->
    <!--<div v-if="!isAuthenticated" class="password-gate">
      <div class="password-container">
        <img src="@/assets/img/logo.png" />
        <h2>Coming Soon</h2>
        <span>We're working on something exciting. Stay tuned!</span>
        <input
          type="password"
          v-model="password"
          @keyup.enter="checkPassword"
          placeholder="Enter password"
        />
        <button @click="checkPassword">Unlock</button>
        <button class="support-btn" @click="showIntercom">Need Help?</button>
        <p v-if="passwordError" class="error">Incorrect password</p>
      </div>
    </div>-->

    <!-- Existing content wrapped in v-else -->
    <transition name="fade" mode="out-in">
      <AppLoader
        v-if="
          generalSettings === null ||
          (authToken !== null && authUser.user === null)
        "
        key="loading"
      />
      <div
        v-else-if="
          generalSettings.general.maintenance.enabled === false ||
          (authUser.user !== null && authUser.user.rank === 'admin')
        "
        class="app-page"
        key="page"
      >
        <Chat />
        <Navbar />

        <div
          class="content-container"
          :class="{
            'sidebar-collapsed': isSidebarCollapsed || isSmallScreen,
            'chat-open': generalSidebarMobile === 'Chat',
          }"
        >
          <div
            class="side-menu"
            :class="{ collapsed: isSidebarCollapsed || isSmallScreen }"
          >
            <Menu @sidebar-toggle="handleSidebarToggle" />
          </div>
          <main
            ref="mainContainer"
            :class="{ expanded: isSidebarCollapsed || isSmallScreen }"
          >
            <transition name="slide-fade" mode="out-in">
              <router-view />
            </transition>
            <Footer v-if="showFooter" />
          </main>
        </div>

        <Modals />
        <Notifications />

        <MobileMenu v-if="isSmallScreen" />
      </div>
      <AppMaintenance v-else key="maintenance" />
    </transition>
    <CookieConsent />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import AppLoader from "@/components/AppLoader";
import AppMaintenance from "@/components/AppMaintenance";
import Navbar from "@/components/navbar/Navbar";
import Chat from "@/components/chat/Chat";
import Footer from "@/components/footer/Footer";
import Modals from "@/components/modals/Modals";
import Notifications from "@/components/notifications/Notifications";
import Menu from "@/components/menu/Menu";
import MobileMenu from "@/components/menu/MobileMenu";
import CookieConsent from "@/components/common/CookieConsent";

window.intercomSettings = {
  app_id: process.env.VUE_APP_INTERCOM_APP_ID,
};
(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src =
        "https://widget.intercom.io/widget/process.env.VUE_APP_INTERCOM_APP_ID";
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
})();

export default {
  components: {
    AppLoader,
    AppMaintenance,
    Navbar,
    Chat,
    Footer,
    Modals,
    Notifications,
    Menu,
    MobileMenu,
    CookieConsent,
  },
  data() {
    return {
      isSmallScreen: false,
      password: "",
      isAuthenticated: false,
      passwordError: false,
      sitePassword: "1BINCOMING",
    };
  },
  computed: {
    ...mapGetters([
      "socketGeneral",
      "generalSettings",
      "generalSidebarMobile",
      "authToken",
      "authUser",
    ]),
    ...mapState("menu", ["isCollapsed"]),
    isSidebarCollapsed: {
      get() {
        return this.isCollapsed;
      },
      set(value) {
        this.$store.dispatch("menu/updateCollapseState", value);
      },
    },
    appGetRouteName() {
      return this.$route.name;
    },
    appHasBackground() {
      let background = true;

      if (
        [
          "ProfileTransactions",
          "ProfileGames",
          "ProfileSettings",
          "UnboxOverview",
          "UnboxBox",
          "BattlesOverview",
          "BattlesCreate",
          "BattlesGame",
          "CashierLimiteds",
        ].includes(this.appGetRouteName) === true
      ) {
        background = false;
      }

      return background;
    },
    showFooter() {
      // Add routes where footer should be hidden
      const routesWithFooter = [
        "/crash",
        "/sports",
        // Add more routes as needed
      ];
      return !routesWithFooter.includes(this.$route.path);
    },
  },
  methods: {
    ...mapActions(["socketConnectGeneral", "generalSetSidebarMobile"]),
    handleSidebarToggle(isCollapsed) {
      this.isSidebarCollapsed = isCollapsed;
    },
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth <= 1175;
    },
    checkPassword() {
      if (this.password === this.sitePassword) {
        this.isAuthenticated = true;
        this.passwordError = false;
        // Optionally save to localStorage to persist across refreshes
        localStorage.setItem("siteAuthenticated", "true");
      } else {
        this.passwordError = true;
      }
    },
    showIntercom() {
      window.Intercom("show");
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        if (this.$refs.mainContainer !== undefined) {
          this.$nextTick(() => {
            const mainContainer = this.$refs.mainContainer;
            mainContainer.scrollTo({ top: 0, behavior: "smooth" });
          });
        }
      },
    },
  },
  created() {
    // Check if already authenticated
    if (localStorage.getItem("siteAuthenticated") === "true") {
      this.isAuthenticated = true;
    }
    this.socketConnectGeneral();
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);

    // Set initial chat state to null (collapsed)
    this.generalSetSidebarMobile(null);

    // Initialize sidebar state from Vuex
    this.isSidebarCollapsed = this.$store.state.menu.isCollapsed;

    // Hide Intercom messenger by default
    if (window.Intercom) {
      window.Intercom("update", { hide_default_launcher: true });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style>
.dark-background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: transparent;
  transition: background-color ease-in-out 0.3s;
  pointer-events: none;
  z-index: 70;
}

.dark-background.enabled {
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

body .app-loader.fade-leave-active {
  transition: opacity 0.5s ease;
}

body .app-loader.fade-leave-active {
  opacity: 0;
}

body .content-container {
  position: fixed;
  top: 100px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 100px);
  transition: all 0.3s ease;
  padding-right: 0;
}

body .content-container.sidebar-collapsed {
  padding-left: 60px; /* Width of collapsed sidebar */
}

body .content-container:not(.sidebar-collapsed) {
  padding-left: 300px; /* Width of expanded sidebar */
}

body .content-container.chat-open {
  padding-right: 300px;
}

body .side-menu {
  position: fixed;
  left: 0;
  top: 100px;
  height: calc(100% - 100px);
  width: 300px;
  transition: all 0.3s ease;
  z-index: 10;
}

body .side-menu.collapsed {
  width: 60px;
}

body .app-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

body .app-page.fade-enter-active,
body .app-maintenance.fade-enter-active {
  transition: opacity 0.5s ease;
}

body .app-page.fade-enter-from,
body .app-maintenance.fade-enter-from {
  opacity: 0;
}

body .app-page main {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: var(--background-primary);
  transition: all 0.3s ease;
}

/*
body .app-page main.main-background {
    background-image: url('~@/assets/img/background.png');
    background-repeat: no-repeat;
    background-position: center 35px;
}
*/

body .app-page main::-webkit-scrollbar-track {
  background-color: transparent;
}

body .app-page main::-webkit-scrollbar-thumb {
  background-color: transparent;
}

body .app-page main::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body .app-page main .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

body .app-page main .slide-fade-enter {
  opacity: 0;
}

@media (max-width: 1030px) {
  aside#chat {
    position: fixed;
    transform: translate(100%, 0);
    z-index: 100;
  }
}

@media (max-width: 1175px) {
  .chat-toggle {
    display: none;
  }

  .side-menu {
    display: none;
  }

  body .app-page main,
  body .app-page main.expanded {
    margin-left: 0;
    width: 100%;
  }

  body .content-container {
    top: 100px;
    height: calc(100% - 110px);
  }

  body .app-page main {
    padding-bottom: 65px;
  }
}

.chat-toggle.hidden {
  display: none;
}

.password-gate {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
}

.password-container img {
  width: 150px;
  margin-bottom: 1rem;
}

.password-container input {
  width: 100%;
  margin: 1rem 0.5rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.1);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: white;
}

.password-container h2 {
  color: white;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.password-container span {
  color: white;
  font-size: 1rem;
  font-weight: 300;
}

.password-container button {
  width: 100%;
  background: var(--highlight-blue);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.password-container .error {
  color: rgb(226, 72, 72);
  margin-top: 0.75rem;
  font-size: 1rem;
  font-weight: 500;
}

.password-container .support-btn {
  margin-top: 1rem;
  background: transparent;
  border: 1px solid var(--highlight-blue);
  color: var(--highlight-blue);
}

.password-container .support-btn:hover {
  background: var(--highlight-blue);
  color: white;
}

@media (max-width: 500px) {
  body .content-container.chat-open {
    padding-right: 0;
  }
}

@media (max-width: 1175px) {
  body .content-container,
  body .content-container.sidebar-collapsed,
  body .content-container:not(.sidebar-collapsed) {
    padding-left: 0;
  }

  body .side-menu {
    display: none;
  }

  body .app-page main,
  body .app-page main.expanded {
    margin-left: 0;
    width: 100%;
  }
}
</style>
