<template>
    <div class="profile-settings-element" :class="{ 'element-success': info === 'success' }">
        <div class="element-name">
            <svg v-if="info === 'success'" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
            </svg>
            <svg v-else-if="info === 'warning'" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
            </svg>
            {{name}}
        </div>
        <div class="element-action">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProfileSettingsElement',
        props: ['name', 'info']
    }
</script>

<style scoped>
    .profile-settings-element {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        border-radius: 10px;
        margin-bottom: 8px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 
            0px 4px 4px 0px rgba(0, 0, 0, 0.25),
            0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
            0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
        transition: all 0.3s ease;
    }

    .profile-settings-element:hover {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, var(--background-primary) 100%);
        transform: translateY(-1px);
    }

    .profile-settings-element .element-name {
        display: flex;
        align-items: center;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.8);
        transition: color 0.3s ease;
    }

    .profile-settings-element:hover .element-name {
        color: white;
    }

    .profile-settings-element .element-name svg {
        width: 18px;
        height: 18px;
        margin-right: 12px;
        fill: #db7d48;
        transition: fill 0.3s ease;
    }

    .profile-settings-element.element-success {
        background: linear-gradient(180deg, rgba(0, 255, 194, 0.08) 0%, var(--background-primary) 100%);
    }

    .profile-settings-element.element-success:hover {
        background: linear-gradient(180deg, rgba(0, 255, 194, 0.12) 0%, var(--background-primary) 100%);
    }

    .profile-settings-element.element-success .element-name svg {
        fill: #00ffc2;
    }

    .profile-settings-element .element-action {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    /* Add subtle animation for interactive elements inside action area */
    .profile-settings-element .element-action :deep(button),
    .profile-settings-element .element-action :deep(input) {
        transition: all 0.3s ease;
    }

    .profile-settings-element .element-action :deep(button:hover) {
        transform: translateY(-1px);
        filter: brightness(1.1);
    }

    @media only screen and (max-width: 600px) {
        .profile-settings-element {
            height: auto;
            padding: 15px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }

        .profile-settings-element .element-name {
            font-size: 13px;
        }

        .profile-settings-element .element-action {
            width: 100%;
            justify-content: flex-end;
        }
    }
</style>