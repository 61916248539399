<template>
    <div class="referred-element">
        <div class="element-content">
            <!-- User Info -->
            <div class="user-info" :class="[
                !referred.user ? 'anonymous' : 
                `rank-${affiliatesGetRank(referred.user)} level-${affiliatesGetLevelColor(referred.user)}`
            ]">
                <div class="avatar">
                    <AvatarImage :image="referred.user?.avatar" />
                </div>
                <span class="username">{{ referred.user?.username || 'Anonymous' }}</span>
            </div>

            <!-- Stats -->
            <div class="stats-info">
                <div class="stat earned">
                    <span class="stat-label">EARNED</span>
                    <div class="stat-value">
                        <img src="@/assets/img/icons/coin.svg" alt="coin" />
                        <span>{{affiliatesFormatValue(totalEarned)}}</span>
                    </div>
                </div>

                <div class="stat deposits">
                    <span class="stat-label">DEPOSITS</span>
                    <div class="stat-value">
                        <img src="@/assets/img/icons/coin.svg" alt="coin" />
                        <span>{{affiliatesFormatValue(getUserDeposits)}}</span>
                    </div>
                </div>

                <div class="stat wagered">
                    <span class="stat-label">WAGERED</span>
                    <div class="stat-value">
                        <img src="@/assets/img/icons/coin.svg" alt="coin" />
                        <span>{{affiliatesFormatValue(totalWagered)}}</span>
                    </div>
                </div>
            </div>

            <!-- Affiliate Account Overview -->
            <div class="affiliate-overview" v-if="referred.affiliates?.codes?.length">
                <div class="overview-section">
                    <div class="overview-item">
                        <span class="overview-label">Slots Commission</span>
                        <span class="overview-value">{{(referred.affiliates.codes[0].rates?.slots * 100).toFixed(1)}}%</span>
                    </div>
                    <div class="overview-item">
                        <span class="overview-label">Max Bonus</span>
                        <span class="overview-value">${{(referred.affiliates.codes[0].maxBonus / 1000).toFixed(2)}}</span>
                    </div>
                    <div class="overview-item">
                        <span class="overview-label">Bonus Percentage</span>
                        <span class="overview-value">{{referred.affiliates.codes[0].bonusPercentage}}%</span>
                    </div>
                    <div class="overview-item">
                        <span class="overview-label">Wagering Requirement</span>
                        <span class="overview-value">{{referred.affiliates.codes[0].wageringRequirement}}x</span>
                    </div>
                    <div class="overview-item">
                        <span class="overview-label">Allowed Games</span>
                        <span class="overview-value">{{referred.affiliates.codes[0].allowedGames.join(', ')}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'AffiliatesReferredElement',
        components: {
            AvatarImage
        },
        props: [
            'referred'
        ],
        computed: {
            totalEarned() {
                // Get stats for all games
                const statsExtra = this.referred.statsExtra || {};
                
                // Get affiliate rates from user's tier or use defaults
                const rates = this.referred.affiliates?.rates || {
                    slots: 0.05,
                    sports: 0.05,
                    crash: 0.05,
                    roll: 0.05,
                    blackjack: 0.05,
                    duels: 0.05,
                    mines: 0.05,
                    towers: 0.05,
                    unbox: 0.05,
                    battles: 0.05,
                    upgrader: 0.05,
                    original: 0.05
                };

                // Constants from backend
                const HOUSE_EDGE = 0.30;  // 30%
                const OPERATIONAL_COSTS = 0.20;  // 20%
                const FIXED_COSTS = 50.00;  // $50 total
                const MIN_NGR = 10.00;  // $10 minimum

                // Calculate raw NGR for all games
                let totalBet = 0;
                let totalWon = 0;
                let totalRawNGR = 0;

                // First pass: calculate raw NGR
                Object.keys(statsExtra).forEach(game => {
                    const gameStats = statsExtra[game] || {};
                    const bet = gameStats.bet || 0;
                    const won = gameStats.won || 0;
                    
                    totalBet += bet;
                    totalWon += won;
                    totalRawNGR += bet - won;
                });

                // For individual users, we show the actual NGR (can be negative)
                // Only apply house edge and operational costs
                let adjustedNGR = totalRawNGR * (1 - HOUSE_EDGE - OPERATIONAL_COSTS);
                adjustedNGR = adjustedNGR - FIXED_COSTS;
                
                // Calculate commission - can be negative for individual users
                const commission = adjustedNGR * rates.original;

                // Never show negative values in the frontend
                return Math.max(0, commission);
            },
            getUserDeposits() {
                console.log('AffiliatesReferredElement - Getting user deposits');
                console.log('Referred user data:', this.referred);
                console.log('Stats:', this.referred.stats);
                
                const deposit = this.referred.stats?.deposit || 0;
                console.log(`User ${this.referred.user?.username || 'Unknown'} deposits:`, {
                    rawDeposit: deposit,
                    formattedDeposit: this.affiliatesFormatValue(deposit)
                });
                return deposit;
            },
            totalWagered() {
                const statsExtra = this.referred.statsExtra || {};
                let totalWagered = 0;

                // Sum up wagers from all games
                Object.values(statsExtra).forEach(game => {
                    totalWagered += game.bet || 0;
                });
                
                return totalWagered;
            }
        },
        methods: {
            affiliatesFormatValue(value) {
                // Convert from credits to dollars (1000 credits = $1)
                const dollars = value / 1000;
                return dollars.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            affiliatesGetRank(user) {
                let rank = user.rakeback;

                if(user.rank !== 'user') { rank = user.rank; }

                return rank;
            },
            affiliatesGetLevelColor(user) {
                let color = '';

                if(user.level >= 2 && user.level < 26) { color = 'blue'; }
                else if(user.level >= 26 && user.level < 51) { color = 'green'; }
                else if(user.level >= 51 && user.level < 76) { color = 'orange'; }
                else if(user.level >= 76 && user.level < 100) { color = 'red'; }
                else if(user.level >= 100) { color = 'purple'; }

                return color;
            }
        }
    }
</script>

<style scoped>
.referred-element {
    background: rgba(13, 23, 39, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.3s ease;
}

.referred-element:hover {
    background: rgba(44, 94, 255, 0.1);
    border-color: rgba(44, 94, 255, 0.2);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.element-content {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 24px;
}

/* User Info Styles */
.user-info {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 16px;
}

.avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.username {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Stats Styles */
.stats-info {
    flex: 1;
    display: flex;
    gap: 16px;
}

.stat {
    width: 33.33%;
    position: relative;
}

.stat-label {
    font-size: 12px;
    color: #8bacc8;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 6px;
}

.stat-value {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #ffffff;
    font-weight: 600;
}

.stat-value img {
    width: 20px;
    height: 20px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

/* Rank Colors with Glow Effect */
.user-info.rank-admin .avatar {
    border-color: #00ffc2;
    box-shadow: 0 0 20px rgba(0, 255, 194, 0.2);
}

.user-info.rank-mod .avatar {
    border-color: #ffb703;
    box-shadow: 0 0 20px rgba(255, 183, 3, 0.2);
}

.user-info.rank-partner .avatar {
    border-color: #eca822;
    box-shadow: 0 0 20px rgba(236, 168, 34, 0.2);
}

/* Level Colors with Enhanced Glow */
.user-info.level-blue .avatar {
    border-color: #559ee4;
    box-shadow: 0 0 20px rgba(85, 158, 228, 0.2);
}

.user-info.level-green .avatar {
    border-color: #b8e92d;
    box-shadow: 0 0 20px rgba(184, 233, 45, 0.2);
}

.user-info.level-orange .avatar {
    border-color: #fca311;
    box-shadow: 0 0 20px rgba(252, 163, 17, 0.2);
}

.user-info.level-red .avatar {
    border-color: #ff4e4e;
    box-shadow: 0 0 20px rgba(255, 78, 78, 0.2);
}

.user-info.level-purple .avatar {
    border-color: #6953f1;
    box-shadow: 0 0 20px rgba(105, 83, 241, 0.2);
}

/* Anonymous User Style */
.user-info.anonymous .avatar {
    border-color: rgba(255, 255, 255, 0.1);
    opacity: 0.5;
}

.user-info.anonymous .username {
    opacity: 0.7;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
    .element-content {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        padding: 12px;
    }

    .user-info {
        width: 100%;
    }

    .stats-info {
        width: 100%;
        margin-top: 8px;
    }

    .stat-label {
        display: block;
        margin-bottom: 4px;
        font-size: 11px;
    }

    .avatar {
        width: 40px;
        height: 40px;
    }

    .username {
        font-size: 14px;
    }

    .stat-value {
        font-size: 14px;
    }

    .stat-value img {
        width: 16px;
        height: 16px;
    }
}

@media only screen and (max-width: 480px) {
    .element-content {
        padding: 10px;
        gap: 12px;
    }

    .user-info {
        gap: 12px;
    }

    .avatar {
        width: 36px;
        height: 36px;
    }

    .username {
        font-size: 13px;
    }

    .stats-info {
        gap: 16px;
    }

    .stat-label {
        font-size: 10px;
    }

    .stat-value {
        font-size: 13px;
    }

    .stat-value img {
        width: 14px;
        height: 14px;
    }
}

.affiliate-overview {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.overview-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 12px;
}

.overview-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.overview-label {
    font-size: 12px;
    color: #8bacc8;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.overview-value {
    font-size: 14px;
    color: #ffffff;
    font-weight: 700;
}

@media only screen and (max-width: 768px) {
    .overview-section {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 480px) {
    .overview-section {
        grid-template-columns: 1fr;
    }
}
</style>
