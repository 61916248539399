<template>
    <div class="fair-towers">
        <div class="towers-title">TOWERS</div>
        <div class="towers-text">
            <p>Each Towers game is generated using three crucial elements:</p>
            <p><span class="highlight">Server Seed:</span> Our secret key that determines safe and dangerous tiles.</p>
            <p><span class="highlight">Nonce:</span> A unique number that changes with each new game.</p>
            <p><span class="highlight">Client Seed:</span> Your customizable seed that adds extra randomness.</p>
            <p>Together, these create a verifiable path to the top.</p>
        </div>
        <div class="towers-code">
            <pre>
                <code>
const crypto = require('crypto');
const ChanceJs = require('chance');

const towersRisk = 'easy';
const serverSeed = '4c60603de55e3b85fe67450aa124ec34509cb73b0f1fde15';
const nonce = 1;
const clientSeed = 'customClientSeed';

const fairGenerateTowersDeck = () => {
    let deck = [];

    for(let rowIndex = 0; rowIndex &lt; 8; rowIndex++) {
        const tilesPerRow = towersRisk === 'medium' ? 2 : 3;
        const losePerRow = towersRisk === 'hard' ? 2 : 1;

        deck[rowIndex] = [];
        for(let tileIndex = 0; tileIndex &lt; tilesPerRow; tileIndex++) {
            if(tileIndex &lt; losePerRow) {
                deck[rowIndex].push('lose');
            } else {
                deck[rowIndex].push('coin');
            }
        }
    }

    return deck;
}

const fairGetTowersOutcome = () => {
    let deck = fairGenerateTowersDeck();
    const combined = `${serverSeed}-${nonce}-${clientSeed}`;

    for(let rowIndex = 0; rowIndex &lt; 8; rowIndex++) {
        const hash = crypto.createHash('sha256').update(`${combined}-${rowIndex}`).digest('hex');

        const chance = new ChanceJs(hash);
        deck[rowIndex] = chance.shuffle(deck[rowIndex]);
    }

    console.log(deck);
}

fairGetTowersOutcome();
                </code>
            </pre>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FairTowers'
    }
</script>

<style scoped>
    .fair-towers {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
        border-radius: 10px;
    }

    .towers-title {
        font-size: 24px;
        font-weight: 900;
        font-family: Montserrat, sans-serif;
        background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .towers-text {
        margin-top: 12px;
        width: 100%;
    }

    .towers-text p {
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
        margin-bottom: 8px;
        font-family: Montserrat, sans-serif;
    }

    .towers-text p .highlight {
        color: #2C5EFF;
        font-weight: 700;
    }

    .towers-code {
        width: 100%;
        margin-top: 25px;
        padding: 16px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
    }

    .towers-code pre {
        width: 100%;
        height: 225px;
        overflow: auto;
    }

    .towers-code pre code {
        font-family: 'Courier New', monospace;
        font-size: 13px;
        font-weight: 600;
        color: #bbbfd0;
    }

    /* Custom scrollbar styling */
    .towers-code pre::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    .towers-code pre::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
    }

    .towers-code pre::-webkit-scrollbar-thumb {
        background: #2C5EFF;
        border-radius: 2px;
    }

    @media only screen and (max-width: 768px) {
        .fair-towers {
            padding: 15px;
        }
        
        .towers-title {
            font-size: 20px;
        }
        
        .towers-code {
            padding: 12px;
        }
    }
</style>