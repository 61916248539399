<template>
  <button
    v-on:click="crashInfoButton"
    class="crash-history-element"
    v-bind:class="{
      'element-high': crashGetOutcome >= 10,
      'element-mid': crashGetOutcome < 10 && crashGetOutcome >= 2,
    }"
  >
    <div class="element-inner">
      <span>{{ crashGetOutcome }}x</span>
    </div>
  </button>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CrashHistoryElement",
  props: ["game"],
  methods: {
    ...mapActions(["modalsSetData", "modalsSetShow"]),
    crashInfoButton() {
      this.modalsSetData({ game: this.game });
      this.modalsSetShow("FairGame");
    },
  },
  computed: {
    crashGetOutcome() {
      return parseFloat(this.game.outcome / 100).toFixed(2);
    },
  },
};
</script>

<style scoped>
button.crash-history-element {
  width: 64px;
  height: 35px;
  position: relative;
  flex-shrink: 0;
  margin-right: 4px;
  border-radius: 8px;
  border-radius: 7px;
  border: 2px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0px 14.954px 19.938px 0px rgba(0, 0, 0, 0.06);
}

button.crash-history-element:last-of-type {
  margin-right: 0;
}

button.crash-history-element .element-inner {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1px;
  left: 1px;
  z-index: 1;
}

button.crash-history-element .element-inner span {
  font-size: 14px;
  font-weight: normal;
  color: var(--highlight-white);
}

button.crash-history-element.element-high {
  background-color: #8127FF;
}

button.crash-history-element.element-mid {
  background-color: var(--highlight-blue);
}

button.crash-history-element:not(.element-high):not(.element-mid) {
  background-color: #242937;
}

@media only screen and (max-width: 950px) {
  button.crash-history-element {
    width: min(64px, 7.5vh);
    height: min(35px, 4vh);
    border-radius: min(7px, 0.8vh);
    border: min(2px, 0.25vh) solid rgba(255, 255, 255, 0.08);
    margin-right: min(4px, 0.5vh);
  }

  button.crash-history-element .element-inner span {
    font-size: min(14px, 1.6vh);
  }
}

@media only screen and (max-width: 550px) {
  button.crash-history-element {
    width: min(55px, 6.5vh);
    height: min(28px, 3.5vh);
  }

  button.crash-history-element .element-inner span {
    font-size: min(12px, 1.4vh);
  }
}
</style>
