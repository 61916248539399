<template>
  <div class="modal-login">
    <div class="login-sidebar">
      <img src="@/assets/img/logo.png"/>
      <div class="sidebar-text">
        <span>WELCOME TO THE BEST CASINO</span>
      </div>
      <img v-if="['login', 'register'].includes(modalTab) === true"
           src="@/assets/img/banner-character.png"/>
    </div>
    <div class="login-content">
      <div class="content-title">
        <span>{{ modalTab === 'forgot' ? 'RESET PASSWORD' : modalTab.toUpperCase() }}</span>
      </div>
      <div v-if="['login', 'register'].includes(modalTab) === true"
           class="content-auth">
        <LoginCredentials :tab="modalTab"/>
      </div>
      <LoginForgot v-else/>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import LoginCredentials from '@/components/login/LoginCredentials';
import LoginForgot from '@/components/login/LoginForgot';

export default {
  name: 'ModalLogin',
  components: {
    LoginCredentials,
    LoginForgot
  },
  data() {
    return {
      modalTab: 'login'
    };
  },
  computed: {
    currentRoute() {
      return this.$route;
    }
  },
  methods: {
    ...mapActions([
      'modalsSetShow',
      'promotionsClaim'
    ]),
    modalSetTab(tab) {
      this.modalTab = tab;
    },
    modalTermsButton() {
      this.modalsSetShow(null);
      setTimeout(() => {
        this.modalsSetShow('Terms');
      }, 300);
    },
    async handleLoginSuccess() {
      // Check if we're on a promotion details page
      if (this.currentRoute.name === 'promotionDetails') {
        const promotionId = this.currentRoute.params.id;
        if (promotionId) {
          // Close the modal
          this.modalsSetShow(null);
          // Attempt to claim the promotion
          await this.promotionsClaim(promotionId);
          return;
        }
      }

      // Check if there was a pending game selection
      const pendingGame = this.$store.state.eva.selectedGame;
      if (pendingGame) {
        // Clear the pending game
        this.$store.commit('eva/setSelectedGame', null);
        // Close the modal
        this.modalsSetShow(null);
        // Launch the game
        this.$router.push({
          name: 'slots',
          params: { gameId: pendingGame.gameId }
        });
      } else {
        // Just close the modal for other cases
        this.modalsSetShow(null);
      }
    }
  },
  created() {
    // If we're on a promotion details page, default to register tab
    if (this.currentRoute.name === 'promotionDetails') {
      this.modalTab = 'register';
    }
  }
};
</script>

<style scoped>
.modal-login {
  width: 1010px;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.15),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.modal-login .login-sidebar {
  width: 237px;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  border-radius: 15px 0 0 15px;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.15),
    0px -1px 0px 0px rgba(0, 0, 0, 0.15) inset;
}

.modal-login .login-sidebar img:nth-of-type(1) {
  width: 143px;
  margin-top: 45px;
}

.modal-login .login-sidebar img:nth-of-type(2) {
  width: 300px;
  transform: translateY(-2.3rem);
}

.modal-login .sidebar-text {
  width: 100%;
  position: relative;
  margin-top: 42px;
  padding: 15px 30px;
  text-align: center;
  background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
  box-shadow: 0 0 15px rgba(44, 94, 255, 0.3);
  color: white;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
}

.modal-login .login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 110px 48px 347px;
}

.modal-login .content-title {
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.8);
}

.modal-login .content-auth {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.modal-login .auth-seperator {
  width: 530px;
  position: relative;
  margin-top: 35px;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}

.modal-login .auth-seperator:before,
.modal-login .auth-seperator:after {
  content: '';
  width: calc(50% - 110px);
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background: rgba(255, 255, 255, 0.08);
}

.modal-login .auth-seperator:before {
  left: 0;
}

.modal-login .auth-seperator:after {
  right: 0;
}

.modal-login button.button-credentials,
.modal-login button.button-bazed {
  width: 100%;
  height: 54px;
  margin-top: 35px;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.15),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset;
  transition: all 0.3s ease;
}

.modal-login button.button-credentials:hover,
.modal-login button.button-bazed:hover {
  background: linear-gradient(180deg, rgba(44, 94, 255, 0.1) 0%, rgba(44, 94, 255, 0.2) 100%);
  border: 1px solid rgba(44, 94, 255, 0.3);
  transform: translateY(-2px);
  box-shadow: 
    0px 8px 15px rgba(0, 0, 0, 0.2),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset;
}

.modal-login button.button-credentials .button-inner,
.modal-login button.button-bazed .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
}

.modal-login button:hover .button-inner {
  color: #FFFFFF;
}

.modal-login button.button-bazed .button-inner svg {
  margin-right: 14px;
  fill: rgba(255, 255, 255, 0.5);
  transition: fill 0.3s ease;
}

.modal-login button.button-bazed:hover .button-inner svg {
  fill: #FFFFFF;
}

@media only screen and (max-width: 1030px) {
  .modal-login {
    width: calc(100vw - 20px);
  }
}

@media only screen and (max-width: 950px) {
  .modal-login .login-sidebar {
    display: none;
  }

  .modal-login .login-content {
    padding: 85px 15px 48px 15px;
  }
}

@media only screen and (max-width: 560px) {
  .modal-login .auth-seperator {
    width: 100%;
  }
}
</style>
