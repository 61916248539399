<template>
    <div class="faq-container">
        <div class="faq-card">
            <div class="faq-header">
                <span>FREQUENTLY ASKED QUESTIONS</span>
                <p>
                    Learn more about our affiliate program and how you can maximize your earnings.
                    Our instant payout system and generous commission rates make it easy to start earning today.
                    Need more help? Contact our support team through live chat.
                </p>
            </div>
            <div class="faq-grid">
                <div class="faq-item" v-for="(item, index) in faqItems" :key="index">
                    <div class="faq-question" @click="toggleFaq(index)" :class="{ active: openIndex === index }">
                        <span>{{ item.question }}</span>
                        <svg class="arrow-icon" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1.5L6 6.5L11 1.5" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div class="faq-answer" :class="{ active: openIndex === index }">
                        {{ item.answer }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AffiliatesFAQ',
    data() {
        return {
            openIndex: null,
            faqItems: [
                {
                    question: "How much can I earn as an affiliate?",
                    answer: "You earn a generous 5% commission on all wagers placed by your referred users. With our instant payout system, there's no limit to how much you can earn. The more active players you bring, the more you earn!"
                },
                {
                    question: "When can I withdraw my affiliate earnings?",
                    answer: "You can withdraw your earnings anytime they reach 10 coins. Our instant payout system ensures you get your commissions immediately after claiming, with no delays or waiting periods."
                },
                {
                    question: "How do I get started with the affiliate program?",
                    answer: "Getting started is simple! Just create your account, generate your unique affiliate code, and start sharing it with your audience. Each new user who signs up using your code becomes your referral automatically."
                },
                {
                    question: "Do my referrals expire?",
                    answer: "No! Once a user signs up using your affiliate code, they're permanently linked to your account. You'll continue earning commissions from their activity indefinitely."
                },
                {
                    question: "What marketing materials are available?",
                    answer: "We provide you with a unique referral link that you can share anywhere. Plus, you can track your performance in real-time through our comprehensive dashboard showing referrals, earnings, and wagering statistics."
                },
                {
                    question: "Is there a limit to how many users I can refer?",
                    answer: "Absolutely not! You can refer as many users as you want. The more users you bring, the more potential earnings you can generate. There's no cap on referrals or earnings!"
                }
            ]
        }
    },
    methods: {
        toggleFaq(index) {
            this.openIndex = this.openIndex === index ? null : index;
        }
    }
}
</script>

<style scoped>
.faq-container {
    width: 100%;
    max-width: 955px;
    margin: 24px auto;
}

.faq-card {
    background: linear-gradient(135deg, rgba(44, 94, 255, 0.15) 0%, rgba(44, 94, 255, 0.05) 100%);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2),
                0px -1px 0px rgba(0, 0, 0, 0.3) inset,
                0px 1px 0px rgba(255, 255, 255, 0.1) inset;
    border: 1px solid rgba(44, 94, 255, 0.1);
    padding: 32px;
}

.faq-header {
    width: 100%;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 32px;
}

.faq-header span {
    display: block;
    font-size: 36px;
    font-weight: 900;
    color: white;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 16px;
    background: linear-gradient(135deg, #FFFFFF 0%, #B8C7FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.faq-header p {
    font-size: 16px;
    font-weight: 500;
    color: #8bacc8;
    line-height: 1.6;
    max-width: 800px;
}

.faq-grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.faq-item {
    border-radius: 12px;
    overflow: hidden;
    background: rgba(13, 23, 39, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
}

.faq-item:hover {
    background: rgba(44, 94, 255, 0.1);
    border-color: rgba(44, 94, 255, 0.2);
    transform: translateY(-1px);
}

.faq-question {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease;
    user-select: none;
}

.faq-question:hover {
    background: rgba(44, 94, 255, 0.15);
}

.faq-question.active {
    background: rgba(44, 94, 255, 0.2);
}

.arrow-icon {
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
    opacity: 0.7;
}

.faq-question:hover .arrow-icon {
    opacity: 1;
}

.faq-question.active .arrow-icon {
    transform: rotate(180deg);
    opacity: 1;
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    padding: 0 20px;
    color: #8bacc8;
    line-height: 1.8;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease;
    opacity: 0;
}

.faq-answer.active {
    max-height: 500px;
    padding: 0 20px 20px 20px;
    opacity: 1;
}

/* Media Queries */
@media only screen and (max-width: 1024px) {
    .faq-card {
        padding: 24px;
    }

    .faq-header span {
        font-size: 32px;
    }

    .faq-header p {
        font-size: 15px;
    }

    .faq-question {
        font-size: 15px;
        padding: 18px;
    }

    .faq-answer {
        font-size: 14px;
    }
}

@media only screen and (max-width: 768px) {
    .faq-card {
        padding: 20px;
    }

    .faq-header {
        padding-bottom: 24px;
        margin-bottom: 24px;
    }

    .faq-header span {
        font-size: 28px;
        margin-bottom: 12px;
    }

    .faq-header p {
        font-size: 14px;
    }

    .faq-grid {
        gap: 12px;
    }

    .faq-question {
        padding: 16px;
        font-size: 14px;
    }

    .arrow-icon {
        width: 14px;
        height: 14px;
    }

    .faq-answer.active {
        padding: 0 16px 16px 16px;
    }
}

@media only screen and (max-width: 480px) {
    .faq-card {
        padding: 16px;
    }

    .faq-header {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .faq-header span {
        font-size: 24px;
    }

    .faq-header p {
        font-size: 13px;
    }

    .faq-grid {
        gap: 8px;
    }

    .faq-question {
        padding: 14px;
        font-size: 13px;
    }

    .arrow-icon {
        width: 12px;
        height: 12px;
    }

    .faq-answer {
        font-size: 13px;
        line-height: 1.6;
    }

    .faq-answer.active {
        padding: 0 14px 14px 14px;
    }
}
</style> 