<template>
  <div class="search-filters">
    <!-- Categories Section -->
    <div class="quick-filters-wrapper">
      <div class="quick-filters">
        <button 
          v-for="category in categories" 
          :key="category.type"
          @click="selectCategory(category.type)"
          :class="[
            'category-button',
            selectedCategory === category.type ? 'active' : ''
          ]"
          :style="{
            '--category-gradient': selectedCategory === category.type ? category.gradient : 'linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%)'
          }"
        >
          <div class="category-icon-wrapper">
            <font-awesome-icon :icon="category.icon" />
          </div>
          <span class="category-name">{{ category.name }}</span>
        </button>
      </div>
    </div>

    <!-- Search and Provider Row -->
    <div class="search-row">
      <!-- Search Input -->
      <div class="search-wrapper">
        <font-awesome-icon icon="search" class="search-icon" />
        <input 
          v-model="searchQuery" 
          type="text" 
          class="search-input"
          placeholder="Search games..."
          @input="handleFilters"
        >
        <button 
          v-if="searchQuery"
          @click="clearSearch"
          class="clear-search"
        >
          <font-awesome-icon icon="times" />
        </button>
      </div>

      <!-- Provider Selection -->
      <div class="provider-select-wrapper">
        <div class="custom-select" :class="{ active: isProviderDropdownOpen }">
          <div class="select-header" @click="toggleProviderDropdown">
            <span class="selected-value">{{ formatProviderName(selectedProvider) || 'All Providers' }}</span>
            <div class="select-controls">
              <button 
                v-if="selectedProvider"
                class="reset-provider"
                @click.stop="selectProvider('')"
              >
                <font-awesome-icon icon="times" />
              </button>
              <span class="select-icon">
                <font-awesome-icon :icon="['fas', 'chevron-down']" />
              </span>
            </div>
          </div>
          <div class="select-dropdown" v-if="isProviderDropdownOpen">
            <div 
              class="select-option" 
              :class="{ active: selectedProvider === '' }"
              @click="selectProvider('')"
            >
              All Providers
            </div>
            <div 
              v-for="provider in availableProviders" 
              :key="provider"
              class="select-option"
              :class="{ active: selectedProvider === provider }"
              @click="selectProvider(provider)"
            >
              {{ formatProviderName(provider) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import { PRODUCT_TYPES } from '@/constants/eva'

export default {
  name: 'EVASearchFilters',

  data() {
    return {
      searchQuery: '',
      selectedCategory: '',
      selectedProvider: '',
      isProviderDropdownOpen: false,
      debounceTimer: null,
      originalGames: [
        { 
          gameId: 'mines',
          translationKey: 'Mines',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Mines.png'),
          route: '/mines'
        },
        { 
          gameId: 'crash',
          translationKey: 'Crash',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Crash.png'),
          route: '/crash'
        },
        { 
          gameId: 'blackjack',
          translationKey: 'Blackjack',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Blackjack.png'),
          route: '/blackjack'
        },
        { 
          gameId: 'towers',
          translationKey: 'Towers',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Tower.png'),
          route: '/tower'
        },
        { 
          gameId: 'duels',
          translationKey: 'Duels',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Dice_Duels.png'),
          route: '/diceduels'
        },
        { 
          gameId: 'coinflip',
          translationKey: 'Coinflip',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Coin_Flip.png'),
          route: '/coinflip'
        }
      ],
      categories: [
        { 
          type: '', 
          name: 'All Games', 
          icon: ['fas', 'border-all'],
          gradient: 'linear-gradient(90deg, #2c5eff 0%, #1d43c0 100%)',
          productType: ''
        },
        { 
          type: 'originals', 
          name: 'Originals', 
          icon: ['fas', 'star'],
          gradient: 'linear-gradient(90deg, #FFD700 0%, #FFA500 100%)',
          productType: '',
          isOriginal: true
        },
        { 
          type: 'slots', 
          name: 'Slots', 
          icon: ['fas', 'dice-five'],
          gradient: 'linear-gradient(90deg, #FF6B6B 0%, #EE5253 100%)',
          productType: 'slots'
        },
        { 
          type: 'live-casino', 
          name: 'Live Casino', 
          icon: ['fas', 'crown'],
          gradient: 'linear-gradient(90deg, #20BF6B 0%, #0FB855 100%)',
          productType: 'live-casino'
        },
        { 
          type: 'virtual-sports', 
          name: 'Virtual Sports', 
          icon: ['fas', 'trophy'],
          gradient: 'linear-gradient(90deg, #4B7BEC 0%, #3867D6 100%)',
          productType: 'virtual-sports'
        },
        { 
          type: 'tv-games', 
          name: 'TV Games', 
          icon: ['fas', 'tv'],
          gradient: 'linear-gradient(90deg, #A55EEA 0%, #8854D0 100%)',
          productType: 'tv-games'
        },
        { 
          type: 'instant-games', 
          name: 'Instant Games', 
          icon: ['fas', 'bolt'],
          gradient: 'linear-gradient(90deg, #FA8231 0%, #E67E22 100%)',
          productType: 'instant-games'
        },
        { 
          type: 'bingo', 
          name: 'Bingo', 
          icon: ['fas', 'bullseye'],
          gradient: 'linear-gradient(90deg, #26DE81 0%, #20C997 100%)',
          productType: 'bingo'
        }
      ]
    }
  },

  computed: {
    ...mapState('eva', ['filters', 'allGames']),

    availableProviders() {
      // Get all providers from store
      const allProviders = this.$store.state.eva.providers
        .filter(Boolean);

      // If no category selected or "All Games", show all providers
      if (!this.selectedCategory || this.selectedCategory === '') {
        return allProviders.sort((a, b) => this.formatProviderName(a).localeCompare(this.formatProviderName(b)));
      }

      // Get the current category's product type
      const category = this.categories.find(c => c.type === this.selectedCategory);
      if (!category) return allProviders;

      // Filter providers based on the games in the current category
      const categoryGames = this.allGames.filter(game => {
        if (category.type === 'originals') {
          return game.isOriginal;
        }
        return game.productType === category.productType;
      });

      // Get unique providers from the filtered games
      const categoryProviders = [...new Set(categoryGames.map(game => game.gameProvider))];

      // Return sorted providers that exist in both the category and the store's provider list
      return allProviders
        .filter(provider => categoryProviders.includes(provider))
        .sort((a, b) => this.formatProviderName(a).localeCompare(this.formatProviderName(b)));
    }
  },

  methods: {
    ...mapActions('eva', ['setFilters', 'fetchGames']),

    async handleFilters() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      this.debounceTimer = setTimeout(async () => {
        console.log('[EVA Filters] Applying filters:', {
          search: this.searchQuery,
          category: this.selectedCategory,
          provider: this.selectedProvider
        });

        // Reset games when filters change
        this.$store.commit('eva/RESET_GAMES');
        
        // Build filters based on API parameters guide
        const filters = {
          size: 500,
          channel: 'desktop',
          isActive: true,
          translationKey: '' // Explicitly set empty to clear previous search
        };

        // Only add search filter if there is a search query with 3 or more characters
        if (this.searchQuery && this.searchQuery.length >= 3) {
          filters.translationKey = this.searchQuery;
          filters.size = 100; // Reduce size when searching
        }

        // Add provider filter if selected
        if (this.selectedProvider) {
          filters.gameProvider = this.selectedProvider;
        }

        // Add category filter if selected
        const category = this.categories.find(c => c.type === this.selectedCategory);
        if (category?.productType) {
          filters.productType = category.productType;
        }

        await this.setFilters({
          filters,
          target: 'all',
          forceRefresh: !this.searchQuery // Force refresh when clearing search
        });

        // Update URL with search parameter
        const url = new URL(window.location);
        if (this.searchQuery) {
          url.searchParams.set('search', this.searchQuery);
        } else {
          url.searchParams.delete('search');
        }
        window.history.pushState({}, '', url);
      }, 300);
    },

    async selectCategory(type) {
      if (this.selectedCategory === type) {
        console.log('[EVA Filters] Category already selected:', type);
        return;
      }

      console.log('[EVA Filters] Selecting category:', type);
      
      // Reset all filters and state
      this.selectedCategory = type;
      this.selectedProvider = '';
      this.searchQuery = '';
      this.isProviderDropdownOpen = false;
      
      // Reset games in store
      this.$store.commit('eva/RESET_GAMES');
      
      const category = this.categories.find(c => c.type === type);
      
      if (category?.type === 'originals') {
        console.log('[EVA Filters] Setting originals category');
        await this.setFilters({
          filters: {
            gameProvider: 'BazedBet',
            isActive: true
          },
          target: 'originals',
          forceRefresh: true
        });
        
        // Set original games in store
        this.$store.commit('eva/SET_ALL_GAMES', this.originalGames);
        this.$store.commit('eva/SET_FILTERED_GAMES', this.originalGames);
        this.$store.commit('eva/SET_TOTAL_ITEMS', this.originalGames.length);
        this.$store.commit('eva/SET_TOTAL_SERVER_PAGES', 1);
      } else if (!type || type === '') {
        // Handle "All Games" selection
        console.log('[EVA Filters] Setting all games (fresh request)');
        await this.setFilters({
          filters: {
            size: 500,
            channel: 'desktop',
            isActive: true
          },
          target: 'all',
          forceRefresh: true
        });
      } else {
        console.log('[EVA Filters] Setting category:', category?.productType);
        const filters = {
          size: 500,
          channel: 'desktop',
          isActive: true
        };

        if (category?.productType) {
          filters.productType = category.productType;
        }

        await this.setFilters({
          filters,
          target: 'all',
          forceRefresh: true // Force refresh when changing categories
        });
      }

      // Update URL - remove all parameters except category
      const url = new URL(window.location);
      url.searchParams.clear();
      if (type) {
        url.searchParams.set('category', type);
      }
      window.history.pushState({}, '', url);
    },

    async selectProvider(provider) {
      console.log('[EVA Filters] Selecting provider:', provider);
      
      if (this.selectedProvider === provider) {
        console.log('[EVA Filters] Provider already selected:', provider);
        this.isProviderDropdownOpen = false;
        return;
      }

      // Update provider state
      this.selectedProvider = provider;
      this.isProviderDropdownOpen = false;
      this.searchQuery = ''; // Reset search when changing provider

      // Reset games
      this.$store.commit('eva/RESET_GAMES');
      
      const category = this.categories.find(c => c.type === this.selectedCategory);
      const filters = {
        size: 500,
        channel: 'desktop',
        isActive: true
      };

      // Add provider filter if selected
      if (provider) {
        filters.gameProvider = provider;
      }

      // Add category filter if selected
      if (category?.productType) {
        filters.productType = category.productType;
      }

      // If selecting "All Providers", force a refresh within the current category
      await this.setFilters({
        filters,
        target: 'all',
        forceRefresh: !provider // Force refresh when selecting "All Providers"
      });

      // Update URL - preserve category if exists, update provider
      const url = new URL(window.location);
      if (provider) {
        url.searchParams.set('provider', provider);
      } else {
        url.searchParams.delete('provider');
      }
      window.history.pushState({}, '', url);
    },

    async clearSearch() {
      if (!this.searchQuery) {
        return;
      }

      console.log('[EVA Filters] Clearing search');
      this.searchQuery = '';
      
      // Use the same logic as handleFilters
      await this.handleFilters();
    },

    formatProviderName(provider) {
      if (!provider) return ''
      
      // Handle special cases
      const specialCases = {
        // Original special cases
        'pragmatic': 'Pragmatic Play',
        'evolution': 'Evolution Gaming',
        'netent': 'NetEnt',
        'playtech': 'Playtech',
        'microgaming': 'Microgaming',
        'playngo': "Play'n GO",
        'quickspin': 'Quickspin',
        'yggdrasil': 'Yggdrasil',
        'redtiger': 'Red Tiger',
        'pushgaming': 'Push Gaming',
        'nolimitcity': 'Nolimit City',
        'hacksaw': 'Hacksaw Gaming',
        'relax': 'Relax Gaming',
        
        // New provider mappings
        'ctgaming': 'CT Interactive',
        'digitain-galaxsys': 'Digital Galaxys',
        'eva-lc-pragmatic': 'Pragmatic Play Live',
        'evenbet': 'Evo',
        'f1x2': 'Fix 2',
        
        // Remove "Infigame" prefix cases
        'infingame-7777gaming': '7777 Gaming',
        'infingame-bet2tech': 'Bet2Tech',
        'infingame-eagaming': 'EA Gaming',
        'infingame-enjoygaming': 'Enjoy Gaming',
        'infingame-matrixgames': 'Matrix Games',
        'infingame-playbro': 'Playbro',
        
        // Mr Slotty cases
        'mrslotty-kagaming': 'Mrslotty',
        'mrslotty-mrslotty': 'Mrslotty',
        
        // Specific renames
        'scorum-aviatrix-ig': 'Aviatrix',
        'uranus-vivogames-lc': 'Vivo Gaming Live',
        'wdc-ig': 'Skywind Group',
        
        // Softgamings cases with Live
        'softgamings-asiagaming-live-casino': 'Asia Gaming Live',
        'softgamings-authenticgaming-live-casino': 'Authentic Gaming Live',
        'softgamings-luckystreak-live-casino': 'Lucky Streak Live',
        'softgamings-xprogaming-live-casino': 'Xpro Gaming Live',
        'softgamings-yggdrasil': 'Yggdrasil',
        
        // Tom Horn cases
        'tomhorn': 'Tom Horn',
        'tomhorn-alg-lc': 'Tom Horn Live',
        
        // Additional Live cases
        'beterlive-lc': 'Beterlive',
        'atmosfera-lc': 'Atmosfera Live',
        'live-solutions-lc': 'Live Solutions Live'
      }

      // First check if we have an exact match in special cases
      const lowercaseProvider = provider.toLowerCase()
      if (specialCases[lowercaseProvider]) {
        return specialCases[lowercaseProvider]
      }

      // For providers not in special cases, remove common prefixes and format
      let formattedProvider = provider
        .replace(/^1gamehub-/, '')  // Remove 1gamehub prefix
        .split(/[-_\s]/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
        .replace(/\s+Lc$/i, ' Live')  // Replace LC suffix with Live
        .replace(/[-\s]Live[-\s]Casino$/i, ' Live')  // Replace Live Casino suffix with Live
        .trim()

      // Add Live suffix if it contains LC or live but doesn't end with Live
      if ((provider.toLowerCase().includes('lc') || provider.toLowerCase().includes('live')) && 
          !formattedProvider.endsWith('Live')) {
        formattedProvider += ' Live'
      }

      return formattedProvider
    },

    toggleProviderDropdown() {
      this.isProviderDropdownOpen = !this.isProviderDropdownOpen;
    },

    // Add click outside handler
    handleClickOutside(event) {
      const dropdown = this.$el.querySelector('.custom-select');
      if (dropdown && !dropdown.contains(event.target)) {
        this.isProviderDropdownOpen = false;
      }
    }
  },

  watch: {
    // Watch for external category changes
    'filters.productType'(newType) {
      const category = this.categories.find(c => c.productType === newType)
      if (category && this.selectedCategory !== category.type) {
        this.selectedCategory = category.type
      }
    }
  },

  mounted() {
    // Initialize filters from URL or default state
    const urlParams = new URLSearchParams(window.location.search);
    const categoryType = urlParams.get('category') || '';
    const provider = urlParams.get('provider') || '';
    const search = urlParams.get('search') || '';

    console.log('[EVA Filters] Initializing with:', {
      category: categoryType,
      provider,
      search
    });

    this.selectedCategory = categoryType;
    this.selectedProvider = provider;
    this.searchQuery = search;

    // Apply initial filters
    this.setFilters({
      filters: {
        searchQuery: search,
        productType: this.categories.find(c => c.type === categoryType)?.productType || '',
        gameProvider: provider,
        channel: 'desktop',
        isActive: true,
        page: 0
      },
      target: categoryType || 'all'
    });

    // Listen for category selection from menu
    this.$root.$on('category-selected', async (category) => {
      if (this.selectedCategory !== category) {
        await this.selectCategory(category);
      }
    });

    // Add click outside listener
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    // Clean up event listeners
    this.$root.$off('category-selected');
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>

<style scoped>
.search-filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(8px);
  position: relative;
  z-index: 1001;
}

.quick-filters-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 0.25rem;
}

.quick-filters-wrapper::-webkit-scrollbar {
  display: none;
}

.quick-filters {
  display: flex;
  gap: 0.75rem;
  padding: 0.25rem;
  min-width: min-content;
}

.category-button {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  min-width: fit-content;
  padding: 0.625rem 0.875rem;
  border-radius: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.category-button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.category-button.active {
  background: var(--category-gradient);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.category-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.category-button.active .category-icon-wrapper {
  background: rgba(255, 255, 255, 0.15);
}

.category-icon-wrapper .svg-inline--fa {
  width: 1rem;
  height: 1rem;
  color: #ffffff;
  opacity: 0.75;
  transition: all 0.2s ease;
}

.category-button.active .category-icon-wrapper .svg-inline--fa {
  opacity: 1;
  transform: scale(1.1);
}

.category-name {
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  text-align: left;
  opacity: 0.75;
  transition: all 0.2s ease;
  padding: 0 0.25rem;
}

.category-button.active .category-name {
  opacity: 1;
  font-weight: 600;
}

.search-row {
  display: flex;
  gap: 0.75rem;
  align-items: stretch;
  position: relative;
}

.search-wrapper {
  position: relative;
  flex: 1;
  min-width: 0;
}

.search-input-wrapper {
  position: relative;
  flex: 1;
  min-width: 200px;
}

.search-input {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border-radius: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  color: #fff;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.search-icon {
  position: absolute;
  left: 0.875rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
  width: 1rem;
  height: 1rem;
  pointer-events: none;
}

.clear-search {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.875rem;
  transition: all 0.2s ease;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
}

.clear-search:hover {
  color: #fff;
}

.provider-select-wrapper {
  position: relative;
  z-index: 1002;
  min-width: 180px;
  max-width: 240px;
  flex-shrink: 0;
}

.custom-select {
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
}

.select-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  gap: 8px;
}

.selected-value {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
}

.select-icon {
  flex-shrink: 0;
  transition: transform 0.3s ease;
  width: 12px;
  opacity: 0.7;
}

.custom-select.active .select-icon {
  transform: rotate(180deg);
}

.select-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: linear-gradient(180deg, 
    rgba(20, 22, 36, 0.95) 0%, 
    rgba(16, 18, 31, 0.98) 100%
  );
  border-radius: 10px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.4),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.05) inset;
  max-height: min(400px, calc(100vh - 200px));
  overflow-y: auto;
  z-index: 1003;
  width: max-content;
  min-width: 100%;
  max-width: 300px;
}

.select-option {
  padding: 12px 16px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  transition: all 0.3s ease;
  background: transparent;
  font-size: 0.875rem;
  line-height: 1.4;
  white-space: nowrap;
  display: flex;
  align-items: center;
  min-height: 44px;
}

.select-option:hover {
  background: rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 1);
}

.select-option.active {
  background: linear-gradient(90deg, rgba(44, 94, 255, 0.2) 0%, rgba(29, 67, 192, 0.2) 100%);
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  position: relative;
}

.select-option.active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background: linear-gradient(90deg, #2c5eff 0%, #1d43c0 100%);
  box-shadow: 0px 0px 8px rgba(44, 94, 255, 0.5);
}

@media (max-width: 768px) {
  .provider-select-wrapper {
    min-width: 140px;
    max-width: 180px;
  }

  .select-header {
    padding: 10px 12px;
  }

  .selected-value {
    font-size: 0.8125rem;
  }

  .select-option {
    padding: 10px 12px;
    font-size: 0.8125rem;
    min-height: 40px;
  }

  .select-dropdown {
    right: 0;
    width: max-content;
    min-width: 160px;
    max-width: min(300px, calc(100vw - 32px));
  }
}

@media (max-width: 480px) {
  .provider-select-wrapper {
    min-width: 120px;
    max-width: 160px;
  }

  .select-dropdown {
    min-width: 140px;
    max-width: min(280px, calc(100vw - 24px));
  }

  .select-option {
    padding: 10px;
  }
}

.select-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.reset-provider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
}

.reset-provider:hover {
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.9);
}

.reset-provider .svg-inline--fa {
  width: 0.75rem;
  height: 0.75rem;
}
</style> 