<template>
  <div class="similar-games">
    <h2 class="section-title">You Might Also Like</h2>
    <div class="games-grid">
      <div 
        v-for="game in similarGames" 
        :key="game.gameId"
        class="game-card"
        @click="handleGameSelect(game)"
      >
        <img 
          :src="game.imageUrl" 
          :alt="game.translationKey"
          class="game-image"
          loading="lazy"
        >
        <div class="game-info">
          <h3 class="game-title">{{ game.translationKey }}</h3>
          <p class="game-provider">{{ game.gameProvider }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EVASimilarGames',

  props: {
    currentGame: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('eva', ['allGames']),

    similarGames() {
      if (!this.currentGame || !this.allGames.length) return []

      // Find games with same provider or game type
      return this.allGames
        .filter(game => 
          game.gameId !== this.currentGame.gameId && // Exclude current game
          (
            game.gameProvider === this.currentGame.gameProvider || // Same provider
            game.productType === this.currentGame.productType // Same game type
          )
        )
        .sort(() => Math.random() - 0.5) // Randomize order
        .slice(0, 18) // Show 24 games
    }
  },

  methods: {
    handleGameSelect(game) {
      this.$emit('game-selected', game);
    }
  }
}
</script>

<style scoped>
.similar-games {
  margin-top: 2rem;
  padding: 1.5rem;
  border-radius: 0.625rem;
  background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.section-title {
  font-family: Montserrat, sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #E8EAED;
  margin-bottom: 1.5rem;
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 0.75rem;
  padding: 0.25rem;
}

.game-card {
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.75rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.game-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.game-image {
  width: 100%;
  aspect-ratio: 16/9;
  transition: transform 0.3s ease;
}

.game-card:hover .game-image {
  transform: scale(1.05);
}

.game-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7) 50%, transparent);
  transform: translateY(100%);
  transition: transform 0.2s ease;
}

.game-card:hover .game-info {
  transform: translateY(0);
}

.game-title {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.game-provider {
  margin: 0.25rem 0 0;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .similar-games {
    margin: 1rem -1rem 0;
    border-radius: 0;
    padding: 1rem;
  }

  .games-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 0.5rem;
    padding: 0.25rem;
  }

  .section-title {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  .game-info {
    padding: 0.75rem;
  }

  .game-title {
    font-size: 0.875rem;
  }

  .game-provider {
    font-size: 0.75rem;
  }
}
</style> 