<template>
  <div class="modal-terms">
    <div class="terms-header">
      <span>KYC Policy</span>
      <p>Last updated: 3.7.2024</p>
    </div>
    <div class="terms-list">
      <div class="list-element">
        <div class="element-title">1. Introduction</div>
        <div class="element-text">
          <p>The Company adheres to and complies with "Know your customer" principles, which aim to prevent financial crime and money laundering through client identification and due diligence.</p>
          <p>The Company reserves the right, at any time, to ask for any KYC documentation it deems necessary to determine the identity and location of a user on bazedbet.com ("Website"). We reserve the right to restrict the service, payment, or withdrawal until identity is sufficiently determined, or for any other reason in our sole discretion based on the legal framework.</p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">2. Due Diligence Levels</div>
        <div class="element-text">
          <p>We take a risk-based approach and perform strict due diligence checks and ongoing monitoring of all clients, customers, and transactions. As per the money laundering regulations, we utilize three stages of due diligence checks, depending on the risk, transaction, and customer type:</p>
          <p>• SDD — simplified due diligence is used in instances of extremely low-risk transactions that do not meet the required thresholds</p>
          <p>• CDD — customer due diligence is the standard for due diligence checks, used in most cases for verification and identification</p>
          <p>• EDD — Enhanced due diligence is used for high-risk customers, large transactions or special cases.</p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">3. KYC Requirements</div>
        <div class="element-text">
          <p>When a user makes an aggregate lifetime total of deposits exceeding EUR 10,000 or requests a withdrawal of any amount on Website, or attempts to or completes a transaction that is deemed suspicious, then it is compulsory for them to complete the full KYC process.</p>
          <p>During this process, the user will have to input some basic details about themselves and then upload:</p>
          <p>1. Copy of Government Issued Photo ID (in some cases front and back depending on the ID)</p>
          <p>2. Photo of themselves holding the ID</p>
          <p>3. Bank statement/utility bill</p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">4. Document Requirements</div>
        <div class="element-text">
          <p>4.1 Proof of ID Requirements:</p>
          <p>• Signed document</p>
          <p>• Issuing country is not from the restricted countries list</p>
          <p>• Full name matches client's name</p>
          <p>• Document is valid for a minimum of 3 months</p>
          <p>• Owner is over 18 years of age</p>
          
          <p>4.2 Proof of Residence Requirements:</p>
          <p>• Bank statement or utility bill</p>
          <p>• Country of residence is not from the restricted countries list</p>
          <p>• Full Name matches client's name and is identical to client's ID</p>
          <p>• Issued less than 3 months ago</p>
          
          <p>4.3 Selfie with ID Requirements:</p>
          <p>• Holder is identical to the ID provided</p>
          <p>• ID is identical to the Proof of ID provided</p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">5. Restricted Countries</div>
        <div class="element-text">
          <p>The following countries are restricted from our services:</p>
          <p>• United States of America and its territories</p>
          <p>• Austria</p>
          <p>• France and its territories</p>
          <p>• Netherlands and its territories</p>
          <p>• Australia and its territories</p>
          <p>• United Kingdom</p>
          <p>• Spain</p>
          <p>• Cyprus</p>
          <p>• And other countries as listed in our full terms, including FATF Blacklisted countries and jurisdictions prohibited by Anjouan Offshore Financial Authority</p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">6. Process Notes</div>
        <div class="element-text">
          <p>6.1 If the KYC process is unsuccessful, the reason is documented and a support ticket is created in the system. The ticket number along with an explanation is communicated back to the user.</p>
          <p>6.2 Once all proper documents are in our possession, the account gets approved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalKyc'
}
</script>

<style scoped>
.modal-terms {
  width: 1000px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 0 30px;
  border-radius: 15px;
  background: var(--background-secondary);
}

.terms-header {
  width: 100%;
  padding-bottom: 30px;
}

.terms-header span {
  font-size: 32px;
  font-weight: 900;
  color: white;
  font-family: Montserrat, sans-serif;
}

.terms-header p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-family: Montserrat, sans-serif;
}

.terms-list {
  width: 100%;
  height: 600px;
  padding: 30px 20px 30px 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.terms-list::-webkit-scrollbar {
  width: 5px;
  height: 0;
}

.terms-list::-webkit-scrollbar-track {
  margin: 30px 0;
  background: var(--background-primary);
}

.terms-list::-webkit-scrollbar-thumb {
  background: var(--highlight-blue);
}

.list-element {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.list-element:first-child {
  margin-top: 0;
}

.element-title {
  font-size: 24px;
  font-weight: 800;
  color: #ffffff;
  font-family: Montserrat, sans-serif;
}

.element-text {
  width: 100%;
  margin-top: 15px;
  padding-left: 30px;
}

.element-text p {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-family: Montserrat, sans-serif;
  line-height: 1.6;
}

.element-text p:first-child {
  margin-top: 0;
}

@media only screen and (max-width: 1190px) {
  .modal-terms {
    width: calc(100vw - 20px);
  }
}

@media only screen and (max-width: 700px) {
  .modal-terms {
    padding: 80px 15px 0 15px;
  }
  
  .terms-header span {
    font-size: 24px;
  }
  
  .element-title {
    font-size: 20px;
  }
  
  .element-text {
    padding-left: 15px;
  }
}
</style> 