export const getCurrencyIcon = (code) => {
  return require(`@/assets/icons/coins/${code.toLowerCase()}_logo.svg`);
};

export const getAvailableCurrencies = () => {
  const context = require.context('@/assets/icons/coins', false, /.*_logo\.svg$/);
  return context.keys().map(key => {
    const code = key.replace('./', '').replace('_logo.svg', '').toUpperCase();
    return {
      code,
      name: code,
      logo_url: getCurrencyIcon(code),
      enable: true,
      merchant_enabled: true
    };
  });
};
 