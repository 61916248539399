<template>
  <div class="promotions">
    <div class="promotions-container">
      <!-- Header Section -->
      <div class="promotions-header">
        <h1>Promotions & Events</h1>
        <p>Discover exclusive bonuses, competitions and special events</p>
      </div>

      <div class="promotions-content">
        <!-- Promotions Grid Section -->
        <div class="promotions-grid">
          <transition name="fade" mode="out-in">
            <!-- Loading State -->
            <div v-if="loading" class="content-loading" key="loading">
              <div class="loading-spinner"></div>
              <p>Loading promotions...</p>
            </div>

            <!-- Error State -->
            <div v-else-if="error" class="content-error" key="error">
              <div class="error-content">
                <span class="error-icon">⚠️</span>
                <p class="error-message">{{ error }}</p>
                <button @click="fetchPromotions" class="retry-btn">
                  Try Again
                </button>
              </div>
            </div>

            <!-- Content State -->
            <div v-else class="content-list" key="content">
              <!-- Highlighted Promotions -->
              <div v-if="highlightedPromotions.length > 0" class="highlighted-promotions">
                <div 
                  v-for="promo in highlightedPromotions" 
                  :key="promo._id"
                  class="promotion-card highlighted"
                  @click="openPromotion(promo)"
                >
                  <div class="card-image">
                    <img :src="promo.image" :alt="promo.title">
                    <div class="card-overlay">
                      <div class="promo-badges">
                        <span class="promo-type" :class="promo.type.toLowerCase()">
                          {{ formatType(promo.type) }}
                        </span>
                        <span class="promo-status" :class="{ active: isActive(promo) }">
                          {{ isActive(promo) ? 'Active' : 'Ended' }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="card-content">
                    <h3>{{ promo.title }}</h3>
                    <p>{{ promo.description }}</p>
                    <div class="card-footer">
                      <span class="date">
                        <span class="date-icon">🕒</span>
                        Valid until {{ formatDate(promo.validUntil) }}
                      </span>
                      <div class="footer-actions">
                        <button 
                          v-if="isActive(promo)"
                          class="claim-btn" 
                          @click.stop="claimPromotion(promo._id)"
                        >
                          Claim
                        </button>
                        <button class="view-btn" @click.stop="openPromotion(promo)">
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Regular Promotions -->
              <div class="regular-promotions">
                <div 
                  v-for="promo in paginatedPromotions" 
                  :key="promo._id"
                  class="promotion-card"
                  @click="openPromotion(promo)"
                >
                  <div class="card-image">
                    <img :src="promo.image" :alt="promo.title">
                    <div class="card-overlay">
                      <div class="promo-badges">
                        <span class="promo-type" :class="promo.type.toLowerCase()">
                          {{ formatType(promo.type) }}
                        </span>
                        <span class="promo-status" :class="{ active: isActive(promo) }">
                          {{ isActive(promo) ? 'Active' : 'Ended' }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="card-content">
                    <h3>{{ promo.title }}</h3>
                    <p>{{ promo.description }}</p>
                    <div class="card-footer">
                      <span class="date">
                        <span class="date-icon">🕒</span>
                        Valid until {{ formatDate(promo.validUntil) }}
                      </span>
                      <div class="footer-actions">
                        <button 
                          v-if="isActive(promo)"
                          class="claim-btn" 
                          @click.stop="claimPromotion(promo._id)"
                        >
                          Claim
                        </button>
                        <button class="view-btn" @click.stop="openPromotion(promo)">
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Pagination -->
              <div v-if="totalPages > 1" class="pagination">
                <button 
                  :disabled="page === 1"
                  @click="changePage(page - 1)"
                  class="page-btn"
                >
                  Previous
                </button>
                <span class="page-info">{{ page }} / {{ totalPages }}</span>
                <button 
                  :disabled="page === totalPages"
                  @click="changePage(page + 1)"
                  class="page-btn"
                >
                  Next
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Promotions',
  data() {
    return {
      loading: true,
      error: null,
      page: 1,
      perPage: 12
    }
  },
  computed: {
    ...mapGetters([
      'promotionsList',
      'socketGeneral',
      'authToken',
      'isAuthenticated'
    ]),
    promotions() {
      return this.promotionsList || [];
    },
    totalPages() {
      return Math.ceil(this.filteredPromotions.length / this.perPage);
    },
    highlightedPromotions() {
      return this.promotions.filter(p => p.order === 0);
    },
    filteredPromotions() {
      return this.promotions.filter(p => p.order !== 0);
    },
    paginatedPromotions() {
      const start = (this.page - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredPromotions.slice(start, end);
    }
  },
  methods: {
    ...mapActions([
      'notificationShow',
      'promotionsGetList',
      'socketConnectGeneral',
      'modalsSetShow',
      'modalsSetData'
    ]),
    async fetchPromotions() {
      try {
        this.loading = true;
        this.error = null;
        await this.promotionsGetList();
      } catch (error) {
        this.error = error.message || 'Failed to load promotions';
        this.notificationShow({
          type: 'error',
          message: this.error
        });
      } finally {
        this.loading = false;
      }
    },
    changePage(newPage) {
      if (newPage >= 1 && newPage <= this.totalPages) {
        this.page = newPage;
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    },
    formatType(type) {
      return type.replace(/_/g, ' ').toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    isActive(promotion) {
      const now = new Date();
      return promotion.active && new Date(promotion.validUntil) > now;
    },
    openPromotion(promotion) {
      this.$router.push(`/promotions/${promotion._id}`);
    },
    async claimPromotion(promotionId) {
      if (!this.isAuthenticated) {
        // Open login modal with register tab
        this.modalsSetData({ modalTab: 'register' });
        this.modalsSetShow('Login');
        return;
      }

      // Ensure socket is connected
      if (!this.socketGeneral?.connected) {
        await this.socketConnectGeneral();
      }

      // Emit socket event to claim promotion
      this.socketGeneral.emit('promotions:claim', { promotionId }, (response) => {
        if (!response.success) {
          this.notificationShow({
            type: 'error',
            message: response.error || 'Failed to claim promotion'
          });
        }
      });
    },
    setupSocketListeners() {
      if (this.socketGeneral) {
        this.socketGeneral.on('promotion_claimed', (data) => {
          this.notificationShow({
            type: 'success',
            message: 'Promotion claimed successfully!'
          });
        });

        this.socketGeneral.on('promotion_progress', (data) => {
          this.notificationShow({
            type: 'info',
            message: `Wagering progress: ${Math.floor((data.wageringProgress / data.wageringRequirement) * 100)}%`
          });
        });

        this.socketGeneral.on('promotion_completed', (data) => {
          this.notificationShow({
            type: 'success',
            message: `Promotion completed! ${data.convertedAmount} credits added to your balance.`
          });
        });
      }
    }
  },
  async created() {
    // Connect socket if needed
    if (!this.socketGeneral?.connected) {
      await this.socketConnectGeneral();
    }
    
    this.setupSocketListeners();
    await this.fetchPromotions();
  },
  beforeDestroy() {
    // Clean up socket event listeners
    if (this.socketGeneral) {
      this.socketGeneral.off('promotion_claimed');
      this.socketGeneral.off('promotion_progress');
      this.socketGeneral.off('promotion_completed');
    }
  }
}
</script>

<style scoped>
.promotions {
  width: 100%;
  padding: 45px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promotions-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.promotions-header {
  text-align: center;
  margin-bottom: 32px;
}

.promotions-header h1 {
  font-size: 36px;
  font-weight: 800;
  margin: 0;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.promotions-header p {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 8px;
}

.promotions-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Promotions Grid */
.promotions-grid {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.3) inset;
  border-radius: 12px;
  padding: 20px;
}

.content-loading,
.content-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
  gap: 16px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-top-color: rgba(44, 94, 255, 0.8);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.error-icon {
  font-size: 32px;
}

.error-message {
  color: #E94848;
  margin: 0;
}

.retry-btn {
  padding: 10px 20px;
  border-radius: 8px;
  background: rgba(44, 94, 255, 0.15);
  border: 1px solid rgba(44, 94, 255, 0.3);
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.retry-btn:hover {
  background: rgba(44, 94, 255, 0.25);
}

/* Promotion Cards */
.highlighted-promotions,
.regular-promotions {
  display: grid;
  gap: 20px;
  margin-bottom: 24px;
}

.highlighted-promotions {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.regular-promotions {
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.promotion-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
}

.promotion-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  border-color: rgba(44, 94, 255, 0.2);
  background: rgba(255, 255, 255, 0.08);
}

.card-image {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.card-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-overlay {
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
}

.promo-badges {
  display: flex;
  gap: 8px;
}

.promo-type,
.promo-status {
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.promo-type {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.promo-status {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.promo-status.active {
  background: rgba(0, 170, 109, 0.2);
  color: #00AA6D;
}

.card-content {
  padding: 20px;
}

.card-content h3 {
  margin: 0 0 12px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-content p {
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 1.5;
}

.card-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.date {
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

.view-btn {
  padding: 8px 16px;
  border-radius: 6px;
  background: rgba(44, 94, 255, 0.15);
  border: 1px solid rgba(44, 94, 255, 0.3);
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-btn:hover {
  background: rgba(44, 94, 255, 0.25);
  transform: translateY(-1px);
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
}

.page-btn {
  padding: 8px 16px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.page-btn:hover:not(:disabled) {
  background: rgba(44, 94, 255, 0.15);
  border-color: rgba(44, 94, 255, 0.3);
  transform: translateY(-1px);
}

.page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-info {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

/* Animations */
@keyframes spin {
  to { transform: rotate(360deg); }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .promotions {
    padding: 35px 10px;
  }

  .promotions-header h1 {
    font-size: 28px;
  }

  .promotions-grid {
    padding: 16px;
  }

  .highlighted-promotions,
  .regular-promotions {
    gap: 16px;
  }
}

@media (max-width: 480px) {
  .regular-promotions {
    grid-template-columns: 1fr;
  }

  .card-content h3 {
    font-size: 16px;
  }

  .promotions-grid {
    padding: 12px;
  }

  .highlighted-promotions,
  .regular-promotions {
    gap: 12px;
  }
}

.footer-actions {
  display: flex;
  gap: 8px;
}

.claim-btn {
  padding: 8px 16px;
  border-radius: 6px;
  background: linear-gradient(180deg, #2C5EFF 0%, #1F4BCC 100%);
  border: none;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.claim-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(44, 94, 255, 0.3);
}
</style> 