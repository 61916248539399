<template>
    <div class="fair-blackjack">
        <div class="blackjack-title">BLACKJACK</div>
        <div class="blackjack-text">
            <p>Every Blackjack hand is determined by combining these key elements:</p>
            <p><span class="highlight">Game ID:</span> A unique identifier created when you start the game - like your game's fingerprint.</p>
            <p><span class="highlight">Server Seed:</span> Our secure random string that's kept secret until the game ends.</p>
            <p><span class="highlight">Public Seed:</span> A blockchain block ID that's revealed after all bets are placed.</p>
            <p>Together, these create a provably fair shuffle of our 8-deck shoe.</p>
        </div>
        <div class="blackjack-code">
            <pre>
                <code>
const crypto = require('crypto');
const ChanceJs = require('chance');

const cardRanks = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
const cardSuits = ['heart', 'spade', 'diamond', 'club'];

const deckCount = 8;
const gameId = '6443aa99f4290f71b555f4bd';
const serverSeed = '4c60603de55e3b85fe67450aa124ec34509cb73b0f1fde15';
const publicSeed = '128b71e19b3c4bd9ec01eba9d6840bdda2a8d0972ac918c9d937c7f861879727';

const fairGenerateBlackjackDeck = () => {
    let deck = [];

    for(let d =  0; d &lt; deckCount; d++) {
        for(let s = 0; s &lt; cardSuits.length; s++) {
            for(let r = 0; r &lt; cardRanks.length; r++) {
                deck.push({ rank: cardRanks[r], suit: cardSuits[s] });
            }
        }
    }

    return deck;
}

const fairShuffleBlackjackDeck = (deck) => {
    const combined = `${gameId}-${serverSeed}-${publicSeed}`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');

    const chance = new ChanceJs(hash);
    deck = chance.shuffle(deck);

    console.log(deck);
}

let deck = fairGenerateBlackjackDeck();
fairShuffleBlackjackDeck(deck);
                </code>
            </pre>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FairBlackjack'
    }
</script>

<style scoped>
    .fair-blackjack {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
        border-radius: 10px;
    }

    .blackjack-title {
        font-size: 24px;
        font-weight: 900;
        font-family: Montserrat, sans-serif;
        background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .blackjack-text {
        margin-top: 12px;
        width: 100%;
    }

    .blackjack-text p {
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
        margin-bottom: 8px;
        font-family: Montserrat, sans-serif;
    }

    .blackjack-text p .highlight {
        color: #2C5EFF;
        font-weight: 700;
    }

    .blackjack-code {
        width: 100%;
        margin-top: 25px;
        padding: 16px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
    }

    .blackjack-code pre {
        width: 100%;
        height: 225px;
        overflow: auto;
    }

    .blackjack-code pre code {
        font-family: 'Courier New', monospace;
        font-size: 13px;
        font-weight: 600;
        color: #bbbfd0;
    }

    /* Custom scrollbar styling */
    .blackjack-code pre::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    .blackjack-code pre::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
    }

    .blackjack-code pre::-webkit-scrollbar-thumb {
        background: #2C5EFF;
        border-radius: 2px;
    }

    @media only screen and (max-width: 768px) {
        .fair-blackjack {
            padding: 15px;
        }
        
        .blackjack-title {
            font-size: 20px;
        }
        
        .blackjack-code {
            padding: 12px;
        }
    }
</style>