<template>
  <div class="bets-element">
    <div class="element-game">
      <div class="game-icon" :class="{
        'icon-yellow': bet.method === 'crash',
        'icon-green': bet.method === 'roll',
        'icon-blue-dark': bet.method === 'blackjack',
        'icon-red': bet.method === 'duels',
        'icon-purple': bet.method === 'mines',
        'icon-blue': bet.method === 'towers',
        'icon-pink': bet.method === 'unbox',
        'icon-orange': bet.method === 'battles',
        'icon-green': bet.method === 'sports',
        'icon-purple': bet.method === 'slots'
      }">
        <font-awesome-icon v-if="bet.method === 'crash'" icon="chart-line" />
        <font-awesome-icon v-else-if="bet.method === 'roll'" icon="dice" />
        <font-awesome-icon v-else-if="bet.method === 'blackjack'" icon="dice-five" />
        <font-awesome-icon v-else-if="bet.method === 'duels'" icon="gun" />
        <font-awesome-icon v-else-if="bet.method === 'mines'" icon="bomb" />
        <font-awesome-icon v-else-if="bet.method === 'towers'" icon="chess-rook" />
        <font-awesome-icon v-else-if="bet.method === 'unbox'" icon="box-open" />
        <font-awesome-icon v-else-if="bet.method === 'battles'" icon="shield" />
        <font-awesome-icon v-else-if="bet.method === 'sports'" icon="futbol" />
        <font-awesome-icon v-else-if="bet.method === 'slots'" icon="dice-d6" />
      </div>
      <span :class="[
        'game-name',
        {
          'gradient-yellow': bet.method === 'crash',
          'gradient-green': bet.method === 'roll',
          'gradient-blue-dark': bet.method === 'blackjack',
          'gradient-red': bet.method === 'duels',
          'gradient-purple': bet.method === 'mines',
          'gradient-blue': bet.method === 'towers',
          'gradient-pink': bet.method === 'unbox',
          'gradient-orange': bet.method === 'battles',
          'gradient-green': bet.method === 'sports',
          'gradient-purple': bet.method === 'slots'
        }
      ]">{{betsGetMethod}}</span>
    </div>

    <div class="element-user" :class="userClasses">
      <div class="user-avatar">
        <AvatarImage :image="bet.user === null ? null : bet.user.avatar" />
      </div>
      <div v-if="bet.user !== null" v-html="bet.user.username" class="user-username"></div>
      <div v-else class="anonymous">Anonymous</div>
    </div>

    <div class="element-time">
      {{ timeAgo }}
    </div>

    <div class="element-wager">
      <img src="@/assets/img/icons/coin.svg" alt="coin" />
      <div class="value-container">
        <span class="main-value">{{betsFormatValue(bet.amount).split('.')[0]}}</span>
        <span class="decimal-value">.{{betsFormatValue(bet.amount).split('.')[1]}}</span>
      </div>
    </div>

    <div class="element-multiplier">
      <span :class="{ 'positive': betsGetMultiplier > 0 }">
        {{betsGetMultiplier.toFixed(2)}}x
      </span>
    </div>

    <div class="element-payout" :class="{ 'payout-positive': bet.payout > 0 }">
      <img src="@/assets/img/icons/coin.svg" alt="coin" />
      <div class="value-container">
        <span class="main-value">{{betsFormatValue(bet.payout || 0).split('.')[0]}}</span>
        <span class="decimal-value">.{{betsFormatValue(bet.payout || 0).split('.')[1]}}</span>
      </div>
    </div>
  </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'BetsElement',
        components: {
            FontAwesomeIcon,
            AvatarImage
        },
        props: [
            'bet'
        ],
        data() {
            return {
                debugInfo: true // Set this to false in production
            }
        },
        methods: {
            betsFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            betsGetRank(user) {
                let rank = user.rakeback;

                if(user.rank !== 'user') { rank = user.rank; }

                return rank;
            },
            betsGetLevelColor(user) {
                let color = '';

                if(user.level >= 2 && user.level < 26) { color = 'blue'; }
                else if(user.level >= 26 && user.level < 51) { color = 'green'; }
                else if(user.level >= 51 && user.level < 76) { color = 'orange'; }
                else if(user.level >= 76 && user.level < 100) { color = 'red'; }
                else if(user.level >= 100) { color = 'purple'; }

                return color;
            }
        },
        computed: {
            betsGetMethod() {
                let method = this.bet.method.charAt(0).toUpperCase() + this.bet.method.slice(1);

                if(this.bet.method === 'duels') {
                    method = 'Dice Duels';
                } else if(this.bet.method === 'sports') {
                    method = 'Sports';
                } else if(this.bet.method === 'slots') {
                    method = 'Slots';
                }

                return method;
            },
            betsGetDate() {
                let date = new Date(this.bet.updatedAt).toLocaleString('en-US', { 
                    hour12: true, 
                    hour: '2-digit', 
                    minute: '2-digit'
                });

                return [date];
            },
            betsGetAmount() {
                let amount = this.bet.amount;

                if(this.bet.method === 'blackjack') {
                    amount = Math.floor(this.bet.amount.main + this.bet.amount.sideLeft + this.bet.amount.sideRight);
                    if(this.bet.actions.includes('split') === true) { amount = Math.floor(amount + this.bet.amount.main); }
                } else if(this.bet.method === 'sports') {
                    amount = parseInt(this.bet.amount);
                } else if(this.bet.method === 'slots') {
                    amount = this.bet.amount;
                }

                return amount;
            },
            betsGetMultiplier() {
                if (!this.bet.payout || this.bet.payout <= 0) {
                    return 0;
                }
                return this.bet.payout / this.bet.amount;
            },
            userClasses() {
                if (!this.bet.user) return ['user-hidden'];
                return [
                    'user-' + this.betsGetRank(this.bet.user),
                    'user-' + this.betsGetLevelColor(this.bet.user)
                ];
            },
            getProfit() {
                return this.bet.payout - this.betsGetAmount;
            },
            timeAgo() {
                const now = new Date();
                const betTime = new Date(this.bet.updatedAt);
                const diffInSeconds = Math.floor((now - betTime) / 1000);

                if (diffInSeconds < 60) return 'just now';
                if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
                if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
                return `${Math.floor(diffInSeconds / 86400)}d ago`;
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
.bets-element {
  display: flex;
  align-items: center;
  padding: 0 35px;
  height: 52px;
  background: var(--background-secondary);
  transition: all 0.3s ease;
}

.bets-element:nth-child(even) {
  background: rgba(20, 30, 48, 0.5);
}

/* Game section */
.element-game {
  width: 20%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.game-icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-icon svg {
  width: 22px;
  height: 22px;
  transition: all 0.3s ease;
}

.icon-yellow svg { color: #ffd700; }
.icon-green svg { color: #2ebd85; }
.icon-blue-dark svg { color: #2c5eff; }
.icon-red svg { color: #ff4e4e; }
.icon-purple svg { color: #6953f1; }
.icon-blue svg { color: #3498db; }
.icon-pink svg { color: #e91e63; }
.icon-orange svg { color: #f39c12; }

.game-name {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--highlight-white);
  text-transform: capitalize;
}

/* User section */
.element-user {
  width: 20%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.user-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid var(--highlight-blue);
}

.user-username {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--highlight-white);
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Time section */
.element-time {
  width: 15%;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--highlight-white);
  opacity: 0.8;
  padding-left: 10px;
}

/* Wager section */
.element-wager {
  width: 15%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 10px;
}

/* Multiplier section */
.element-multiplier {
  width: 15%;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #2ebd85;
  text-align: center;
  padding-left: 10px;
}

/* Payout section */
.element-payout {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.value-container {
  display: flex;
  align-items: baseline;
}

.main-value {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: var(--highlight-white);
}

.decimal-value {
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}

img {
  width: 18px;
  height: 18px;
}

/* User rank colors */
.element-user.user-blue .user-avatar { border-color: var(--highlight-blue); }
.element-user.user-green .user-avatar { border-color: #b8e92d; }
.element-user.user-orange .user-avatar { border-color: #fca311; }
.element-user.user-red .user-avatar { border-color: #ff4e4e; }
.element-user.user-purple .user-avatar { border-color: #6953f1; }
.element-user.user-partner .user-avatar { border-color: #eca822; }
.element-user.user-mod .user-avatar { border-color: #ffb703; }
.element-user.user-admin .user-avatar { border-color: #00ffc2; }

@media only screen and (max-width: 950px) {
  .bets-element {
    height: auto;
    display: grid;
    grid-template-rows: 42px 54px;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
    background: none;
  }

  .element-game,
  .element-user,
  .element-time {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: var(--background-primary);
    padding: 8px;
  }

  .element-wager,
  .element-multiplier,
  .element-payout {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: var(--background-secondary);
    padding: 8px;
  }

  .element-multiplier {
    position: relative;
  }

  .element-multiplier::before,
  .element-multiplier::after {
    content: '';
    width: 1px;
    height: 24px;
    position: absolute;
    top: 50%;
    background: var(--highlight-blue);
    transform: translateY(-50%);
  }

  .element-multiplier::before { left: 0; }
  .element-multiplier::after { right: 0; }

  .game-name,
  .user-username,
  .element-time,
  .main-value,
  .element-multiplier {
    font-size: 14px;
  }

  .decimal-value {
    font-size: 11px;
  }

  .user-username {
    max-width: 90px;
  }
}

@media only screen and (max-width: 480px) {
  .game-name,
  .user-username,
  .element-time,
  .main-value,
  .element-multiplier {
    font-size: 13px;
  }

  .decimal-value {
    font-size: 10px;
  }

  .user-username {
    max-width: 70px;
  }

  img {
    width: 16px;
    height: 16px;
  }
}
</style>