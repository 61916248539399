<template>
    <div class="cashier-crypto-withdraw">
        <div class="withdraw-amount">
            <div class="amount-header">
                <img 
                    :src="selectedCurrency.logo_url" 
                    :alt="selectedCurrency.code"
                    @error="handleImageError"
                />
                {{cashierGetName}}
            </div>
            <p>Enter the amount you would like to withdraw. The network fees will be deducted from your withdraw amount. Average network fees are <span>${{ modalFormatValue(cashierGetFee) }}</span>.</p>
            <div class="amount-inputs">
                <div class="inputs-element">
                    <transition name="fade" mode="out-in">
                        <div v-if="cashierCryptoData.loading === true" class="element-loading" key="loading"></div>
                        <div v-else class="element-content" key="data">
                            <img src="@/assets/img/icons/coin.svg" alt="icon" />
                            <input v-model="cashierCoinAmount" v-on:input="cashierCoinInput" type="text" />
                        </div>
                    </transition>
                </div>
                <span>=</span>
                <div class="inputs-element element-crypto">
                    <transition name="fade" mode="out-in">
                        <div v-if="cashierCryptoData.loading === true" class="element-loading" key="loading"></div>
                        <div v-else class="element-content" key="data">
                            <img 
                                :src="selectedCurrency.logo_url" 
                                :alt="selectedCurrency.code"
                                @error="handleImageError"
                            />
                            <input v-model="cashierCryptoAmount" v-on:input="cashierCryptoInput" type="text" />
                        </div>
                    </transition>
                </div>
                <span>=</span>
                <div class="inputs-element">
                    <transition name="fade" mode="out-in">
                        <div v-if="cashierCryptoData.loading === true" class="element-loading" key="loading"></div>
                        <div v-else class="element-content" key="data">
                            <span class="text-green-gradient">$</span>
                            <input v-model="cashierFiatAmount" v-on:input="cashierFiatInput" type="text" />
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <div class="withdraw-address">
            <div class="address-inner">
                <div class="inner-title">{{cashierGetName}} Withdraw Address</div>
                <p>This action is irreversible. Please ensure the provided information is correct.</p>
                <input v-model="cashierAddress" type="text" placeholder="Enter your address...">
            </div>
        </div>
        <button v-on:click="cashierWithdrawButton()" class="button-withdraw" v-bind:disabled="socketSendLoading !== null">
            <div class="button-inner">
                <transition name="fade" mode="out-in">
                    <ButtonLoading v-if="socketSendLoading === 'CryptoWithdraw'" />
                    <div v-else class="inner-content">CONFIRM CRYPTO WITHDRAW</div>
                </transition>
            </div>
        </button>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ButtonLoading from '@/components/ButtonLoading';
    import coinData from '@/assets/icons/coins/coin_data.json';

    export default {
        name: 'CashierCryptoWithdraw',
        components: {
            ButtonLoading
        },
        data() {
            return {
                cashierCoinAmount: 0,
                cashierCryptoAmount: 0,
                cashierFiatAmount: 0,
                cashierAddress: null,
                selectedCurrency: null
            }
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'cashierSendCryptoWithdrawDepositSocket'
            ]),
            modalFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            cashierCoinInput() {
                this.cashierFiatAmount = parseFloat((this.cashierCoinAmount / 1000) * 2.5).toFixed(2);
                this.cashierCryptoAmount = parseFloat(1 / (this.cashierCryptoData.prices[this.modalsData.currency].price / 1000) * this.cashierFiatAmount).toFixed(8);
            },
            cashierCryptoInput() {
                this.cashierFiatAmount = parseFloat(this.cashierCryptoAmount * (this.cashierCryptoData.prices[this.modalsData.currency].price / 1000)).toFixed(2);
                this.cashierCoinAmount = parseFloat((this.cashierFiatAmount / 2.5) * 1000).toFixed(2);
            },
            cashierFiatInput() {
                this.cashierCoinAmount = parseFloat((this.cashierFiatAmount / 2.5) * 1000).toFixed(2);;
                this.cashierCryptoAmount = parseFloat(1 / (this.cashierCryptoData.prices[this.modalsData.currency].price / 1000) * this.cashierFiatAmount).toFixed(8)
            },
            cashierWithdrawButton() {
                const amount = Math.floor(this.cashierCoinAmount * 1000);

                if(this.cashierAddress === null || this.cashierAddress.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'You need to enter a valid ' + this.modalsData.currency + ' withdraw address.' });
                    return;
                }

                const data = { currency: this.modalsData.currency, amount: amount, address: this.cashierAddress };
                this.cashierSendCryptoWithdrawDepositSocket(data);
            },
            handleImageError(event) {
                const target = event.target;
                const code = this.modalsData.currency.toLowerCase();
                target.src = `https://nowpayments.io/images/coins/${code}.svg`;
            },
            loadCurrencyData() {
                const currency = this.modalsData.currency.toLowerCase();
                const currencyData = coinData.currencies.find(c => c.code.toLowerCase() === currency);
                if (currencyData) {
                    try {
                        this.selectedCurrency = {
                            ...currencyData,
                            logo_url: require(`@/assets/icons/coins/${currency}_logo.svg`)
                        };
                    } catch (error) {
                        console.warn(`Missing icon for ${currency}`);
                        this.selectedCurrency = {
                            ...currencyData,
                            logo_url: require('@/assets/icons/coins/btc_logo.svg') // Fallback to BTC icon
                        };
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'modalsData',
                'cashierCryptoData'
            ]),
            cashierGetName() {
                if (!this.selectedCurrency) return this.modalsData.currency.toUpperCase();
                return this.selectedCurrency.name || this.modalsData.currency.toUpperCase();
            },
            cashierGetFee() {
                let fee = 1000;

                if(this.modalsData.currency === 'eth') {
                    fee = 2000;
                }  else if(this.modalsData.currency === 'ltc') {
                    fee = 250;
                }

                return fee;
            }
        },
        watch: {
            'cashierCryptoData.prices': {
                handler() {
                    this.cashierCoinAmount = parseFloat((100 / 2.5) * 1000).toFixed(2);
                    this.cashierCryptoAmount = parseFloat(1 / (this.cashierCryptoData.prices[this.modalsData.currency].price / 1000) * 100).toFixed(8);
                    this.cashierFiatAmount = parseFloat(100).toFixed(2);
                },
                deep: true
            },
            'modalsData.currency': {
                immediate: true,
                handler() {
                    this.loadCurrencyData();
                }
            }
        },
        created() {
            this.loadCurrencyData();
        }
    }
</script>

<style scoped>
    .cashier-crypto-withdraw {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .withdraw-amount {
        width: 100%;
        padding: 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
                    0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                    0px 1px 0px rgba(255, 255, 255, 0.1) inset;
        border: 1px solid rgba(255, 255, 255, 0.05);
    }

    .amount-header {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 800;
        color: rgba(255, 255, 255, 0.9);
        letter-spacing: 0.2px;
        margin-bottom: 12px;
    }

    .amount-header img {
        width: 30px;
        height: 30px;
        margin-right: 13px;
        filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
    }

    .withdraw-amount p {
        font-size: 12px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.7);
        letter-spacing: 0.2px;
        margin-bottom: 16px;
    }

    .withdraw-amount p span {
        color: rgba(44, 94, 255, 0.9);
    }

    .amount-inputs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
    }

    .inputs-element {
        width: 200px;
        height: 50px;
        position: relative;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(31, 42, 68, 0.2) 100%);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
                    0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                    0px 1px 0px rgba(255, 255, 255, 0.1) inset;
    }

    .element-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .element-content input {
        width: 100%;
        height: 100%;
        padding: 0 12px 0 44px;
        background: transparent;
        border: none;
        color: rgba(255, 255, 255, 0.9);
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.2px;
    }

    .element-content img,
    .element-content span {
        position: absolute;
        left: 12px;
        width: 22px;
        height: 22px;
        filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
    }

    .element-content span {
        font-weight: 800;
        font-size: 17px;
        background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .withdraw-address {
        width: 100%;
        margin-top: 20px;
        padding: 20px 0;
        border-top: 1px solid rgba(44, 94, 255, 0.15);
        border-bottom: 1px solid rgba(44, 94, 255, 0.15);
    }

    .address-inner {
        width: 100%;
        padding: 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
                    0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                    0px 1px 0px rgba(255, 255, 255, 0.1) inset;
        border: 1px solid rgba(255, 255, 255, 0.05);
    }

    .inner-title {
        font-size: 14px;
        font-weight: 800;
        color: rgba(255, 255, 255, 0.7);
        letter-spacing: 0.2px;
        margin-bottom: 8px;
    }

    .address-inner p {
        font-size: 12px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.5);
        letter-spacing: 0.2px;
        margin-bottom: 16px;
    }

    .address-inner input {
        width: 100%;
        height: 50px;
        padding: 0 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(31, 42, 68, 0.2) 100%);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 8px;
        border: 1px solid rgba(44, 94, 255, 0.2);
        color: rgba(255, 255, 255, 0.9);
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.2px;
        transition: all 0.2s ease;
    }

    .address-inner input:focus {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
        border-color: rgba(44, 94, 255, 0.4);
        outline: none;
    }

    .address-inner input::placeholder {
        color: rgba(255, 255, 255, 0.3);
    }

    .button-withdraw {
        width: 300px;
        height: 54px;
        margin-top: 35px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 8px;
        border: none;
        overflow: hidden;
        transition: all 0.2s ease;
    }

    .button-withdraw .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(90deg, #00FFC2 0%, #00AA6D 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
                    0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                    0px 1px 0px rgba(255, 255, 255, 0.1) inset;
    }

    .button-withdraw:hover {
        transform: translateY(-1px);
    }

    .button-withdraw:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .button-withdraw .inner-content {
        font-size: 16px;
        font-weight: 800;
        color: rgba(255, 255, 255, 0.9);
        letter-spacing: 0.2px;
    }

    @media only screen and (max-width: 750px) {
        .amount-inputs {
            flex-direction: column;
            width: 100%;
        }

        .inputs-element {
            width: 100%;
        }

        .button-withdraw {
            width: 100%;
        }
    }
</style>