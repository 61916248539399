<template>
  <div class="crash-game">
    <div v-if="showManualBets" class="manual-bets-popup">
      <div class="popup-header">
        <h3>{{ crashBets.length }} PLAYERS</h3>
        <h3>
          <img src="@/assets/img/icons/coin.svg" alt="icon" />
          <span>{{ crashFormatValue(crashGetBetsAmount) }}</span>
        </h3>
        <button class="close-button" @click="showManualBets = false">×</button>
      </div>
      <div class="popup-content">
        <CrashBetElement
          v-for="bet of crashBets"
          v-bind:key="bet._id"
          v-bind:bet="bet"
        />
      </div>
    </div>
    <div class="game-graph" ref="crashGraph">
      <canvas id="canvas-graph"></canvas>
    </div>
    <!--<div class="game-info">
      <div class="info-network">
        <div class="network-point"></div>
        <span>NETWORK STATUS</span>
      </div>
      <div class="info-profit">
        <transition name="fade">
          <div v-if="crashProfitInfo === true" class="profit-amount">
            <img src="@/assets/img/icons/coin.svg" alt="icon"/>
            250,000
          </div>
        </transition>
        <button v-on:click="crashSetProfitInfo(!crashProfitInfo)">
          <IconInfo/>
          MAX PROFIT
        </button>
      </div>
    </div>-->
    <button class="show-manual-bets" @click="showManualBets = !showManualBets">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="16"
        viewBox="0 0 22 16"
        fill="none"
      >
        <path
          d="M0 16V13.2C0 12.6333 0.145833 12.1125 0.4375 11.6375C0.729167 11.1625 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64583 4.75 9.3875C5.81667 9.12917 6.9 9 8 9C9.1 9 10.1833 9.12917 11.25 9.3875C12.3167 9.64583 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2708 11.1625 15.5625 11.6375C15.8542 12.1125 16 12.6333 16 13.2V16H0ZM18 16V13C18 12.2667 17.7958 11.5625 17.3875 10.8875C16.9792 10.2125 16.4 9.63333 15.65 9.15C16.5 9.25 17.3 9.42083 18.05 9.6625C18.8 9.90417 19.5 10.2 20.15 10.55C20.75 10.8833 21.2083 11.2542 21.525 11.6625C21.8417 12.0708 22 12.5167 22 13V16H18ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM18 4C18 5.1 17.6083 6.04167 16.825 6.825C16.0417 7.60833 15.1 8 14 8C13.8167 8 13.5833 7.97917 13.3 7.9375C13.0167 7.89583 12.7833 7.85 12.6 7.8C13.05 7.26667 13.3958 6.675 13.6375 6.025C13.8792 5.375 14 4.7 14 4C14 3.3 13.8792 2.625 13.6375 1.975C13.3958 1.325 13.05 0.733333 12.6 0.2C12.8333 0.116667 13.0667 0.0625 13.3 0.0375C13.5333 0.0125 13.7667 0 14 0C15.1 0 16.0417 0.391667 16.825 1.175C17.6083 1.95833 18 2.9 18 4Z"
          fill="white"
          fill-opacity="0.5"
        />
      </svg>
    </button>
    <div v-if="crashGame !== null" class="game-inner">
      <div v-if="crashGame.state === 'completed'" class="inner-completed">
        <div class="completed-multiplier">
          x{{ parseFloat(crashGame.outcome / 100).toFixed(2) }}
        </div>
        <div class="completed-over">ROUND OVER</div>
      </div>
      <div
        v-else-if="
          crashGame.state === 'rolling' || crashGame.state === 'completed'
        "
        class="inner-rolling"
      >
        <span class="rolling-multiplier">
          x{{ parseFloat(Math.floor(crashMultiplier / 1000) / 100).toFixed(2) }}
        </span>
        <div class="rolling-payout">CURRENT PAYOUT</div>
        <transition name="fade">
          <div
            v-if="
              authUser.user !== null &&
              crashBets.some(
                (element) =>
                  element.user._id === authUser.user._id &&
                  element.multiplier === undefined
              ) === true
            "
            class="rolling-amount"
          >
            <img src="@/assets/img/icons/coin.svg" alt="icon" />
            <div class="amount-value">
              <span
                >+{{
                  crashFormatValue(crashGetPayoutAmount).split(".")[0]
                }}</span
              >
              .{{ crashFormatValue(crashGetPayoutAmount).split(".")[1] }}
            </div>
          </div>
        </transition>
      </div>
      <div v-else class="inner-waiting">
        <img
          class="waiting-rocket"
          src="@/assets/img/crash/bazed-rocket.png"
          alt="Crash Rocket"
        />
        <div class="waiting-status">PREPARING ROUND</div>
        <div class="waiting-timer">{{ crashText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CrashGraph from "@/components/crash/CrashGraph";
import IconInfo from "@/components/icons/IconInfo";
import CrashBetElement from "@/components/crash/CrashBetElement";

export default {
  name: "CrashGame",
  components: {
    IconInfo,
    CrashBetElement,
  },
  data() {
    return {
      crashTimerRepeater: null,
      crashRunRepeater: null,
      crashGraphInstance: null,
      crashWidth: 0,
      crashProfitInfo: false,
      crashText: "STARTING IN 20.00s",
      crashMultiplier: 1.0001,
      showManualBets: false,
    };
  },
  methods: {
    crashFormatValue(value) {
      return parseFloat(Math.floor(value / 10) / 100)
        .toFixed(2)
        .toString();
    },
    crashSetProfitInfo(value) {
      this.crashProfitInfo = value;
    },
    crashStartTimer() {
      const timeEnding =
        new Date(this.crashGame.createdAt).getTime() + 1000 * 6;
      const timeLeft =
        (timeEnding - (new Date().getTime() + this.generalTimeDiff)) / 1000;

      this.crashText = "STARTING IN " + timeLeft.toFixed(2) + "s";

      if (timeLeft <= 0) {
        this.crashText = "PENDING...";
      } else {
        this.crashTimerRepeater = requestAnimationFrame(this.crashStartTimer);
      }
    },
    crashStartMutiplier() {
      const elapsed =
        new Date().getTime() +
        this.generalTimeDiff -
        new Date(this.crashGame.updatedAt).getTime();
      const multiplier = Math.floor(
        100000 * Math.pow(Math.E, 0.00006 * elapsed)
      );

      this.crashMultiplier = multiplier === 100000 ? 100001 : multiplier;

      CrashGraph.Engine.multi = this.crashMultiplier / 100000;

      this.crashRunRepeater = requestAnimationFrame(this.crashStartMutiplier);
    },
  },
  computed: {
    ...mapGetters(["authUser", "generalTimeDiff", "crashGame", "crashBets"]),
    crashGetPayoutAmount() {
      return Math.floor(
        this.crashBets[
          this.crashBets.findIndex(
            (element) => element.user._id === this.authUser.user._id
          )
        ].amount *
          (this.crashMultiplier / 100000)
      );
    },
    crashGetBetsAmount() {
      let amount = 0;
      for (const bet of this.crashBets) {
        amount = Math.floor(amount + bet.amount);
      }
      return amount;
    },
  },
  watch: {
    crashGame: {
      handler(data, oldData) {
        if (data.state === "created") {
          CrashGraph.Engine.multi = 1.0001;
          CrashGraph.Engine.gameState = "STARTING";

          this.crashStartTimer();
        } else if (data.state === "rolling") {
          CrashGraph.Engine.multi = 1.0001;
          CrashGraph.Engine.gameState = "IN_PROGRESS";

          this.crashMultiplier = 1.0001;
          this.crashStartMutiplier();
        } else if (data.state === "completed") {
          CrashGraph.Engine.multi = 1.0001;
          CrashGraph.Engine.gameState = "ENDED";

          cancelAnimationFrame(this.crashRunRepeater);
        }
      },
      deep: true,
    },
  },
  mounted() {
    let canvas = document.querySelector("#canvas-graph");
    this.crashWidth = this.$refs.crashGraph.offsetWidth;

    const isMobile = window.innerWidth <= 550;
    const height = isMobile ? 225 : 435;

    this.crashGraphInstance = new CrashGraph.Graph();
    this.crashGraphInstance.startRendering(canvas, {
      width: this.crashWidth,
      height: height,
    });

    CrashGraph.Engine.multi = 1;

    if (this.crashGame.state === "created") {
      CrashGraph.Engine.gameState = "STARTING";
      this.crashStartTimer();
    } else if (this.crashGame.state === "rolling") {
      CrashGraph.Engine.gameState = "IN_PROGRESS";
      this.crashStartMutiplier();
    } else {
      this.crashText = "PENDING...";
    }
  },
  destroyed() {
    cancelAnimationFrame(this.crashTimerRepeater);
    cancelAnimationFrame(this.crashRunRepeater);
    this.crashGraphInstance.stopRendering();
  },
};
</script>

<style scoped>
.crash-game {
  width: 100%;
  height: 90%;
  position: relative;
  border-radius: 15px;
}

.crash-game .game-graph {
  position: absolute;
  top: 20px;
  bottom: 5px;
  left: 5px;
  right: 5px;
}

.graph-crash {
  height: 460px;
}

.show-manual-bets {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(29, 33, 52, 0.5);
  border-radius: 15px;
  border: 3px solid rgba(255, 255, 255, 0.05);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(2px);
  padding: 10px;
}

.crash-game .game-info {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 15px;
  left: 0;
  padding: 0 20px;
}

.crash-game .info-network {
  display: flex;
  align-items: center;
}

.crash-game .info-network span {
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}

.crash-game .network-point {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
  background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%), #d9d9d9;
  box-shadow: 0px 0px 8px #01dea0;
}

.crash-game .info-profit {
  display: flex;
  align-items: center;
}

.crash-game .info-profit button {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}

.crash-game .info-profit button svg {
  margin-right: 8px;
  fill: #ffffff;
}

.crash-game .profit-amount {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 800;
  color: #ffffff;
}

.crash-game .profit-amount.fade-enter-active,
.crash-game .profit-amount.fade-leave-active {
  transition: opacity 0.5s;
}

.crash-game .profit-amount.fade-enter-from,
.crash-game .profit-amount.fade-leave-to {
  opacity: 0;
}

.crash-game .profit-amount img {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.crash-game .game-inner {
  width: 100%;
  min-height: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
  z-index: -1;
}

.crash-game .inner-completed,
.crash-game .inner-rolling,
.crash-game .inner-waiting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80%;
  height: 40%;
  padding: min(20px, 2.5vh) min(15px, 2vh);
  border-radius: min(1.875rem, 2.5vh);
  border: min(4px, 0.5vh) solid rgba(255, 255, 255, 0.05);
  background: rgba(29, 33, 52, 0.25);
  backdrop-filter: blur(6.75px);
}

.crash-game .inner-completed::before,
.crash-game .inner-rolling::before,
.crash-game .inner-waiting::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  background: var(--highlight-blue);
  opacity: 0.05;
  z-index: -1;
}

.crash-game .waiting-rocket {
  width: 130px;
  position: absolute;
  top: -160px;
  left: 50%;
  transform: translateX(-50%);
}

.crash-game .completed-multiplier,
.crash-game .rolling-multiplier,
.crash-game .waiting-status {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: min(16vw, 2.5rem);
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  word-break: break-word;
}

.crash-game .rolling-multiplier {
  width: min(100%, 300px);
  font-size: min(16vw, 4rem);
  font-weight: 900;
  color: #e3e7ff;
  text-shadow:
    0 3px #0068ff,
    0 0 150px #0057bd,
    0 0 150px #0057bd,
    0 0 50px #004dbd,
    0 0 14px #0f1761;
  line-height: normal;
}

.crash-game .waiting-status {
  font-size: min(8vw, 2rem);
}

.crash-game .completed-over,
.crash-game .rolling-payout,
.crash-game .waiting-timer {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 5px;
}

.crash-game .rolling-amount {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: min(10px, 1.2vh);
  padding-top: min(13px, 1.5vh);
  border-top: min(1px, 0.15vh) solid rgba(255, 255, 255, 0.1);
  position: relative;
  transform: none;
  left: 0;
  bottom: 0;
}

.crash-game .rolling-amount img {
  width: min(20px, 2.5vh);
  height: min(20px, 2.5vh);
  margin-right: min(8px, 1vh);
}

.crash-game .amount-value {
  font-size: min(14px, 1.6vh);
  font-weight: 600;
  color: #bbbfd0;
}

.crash-game .amount-value span {
  font-size: min(19px, 2.2vh);
  font-weight: 800;
  color: #ffffff;
}

.crash-game .inner-waiting img {
  margin-top: 25px;
}

.crash-game .waiting-timer {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
}

.crash-game .inner-rolling {
  position: absolute;
  top: min(20px, 3%);
  left: min(50px, 5%);
  width: auto;
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.manual-bets-popup {
  position: absolute;
  top: 0;
  right: 50%;
  transform: translateX(50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  border: 3px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  z-index: 100;
  font-size: 14px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.popup-header h3 {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.50);
}

.popup-header h3 img {
  width: 18px;
  height: 18px;
}

.popup-header h3 span {
  color: #ffffff;
}

.close-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 24px;
  cursor: pointer;
  padding: 0 5px;
}

.close-button:hover {
  color: #ffffff;
}

.popup-content {
  padding: 20px;
}

@media only screen and (min-width: 950px) {
  .show-manual-bets {
    display: none;
  }

  .manual-bets-popup {
    display: none;
  }
}

@media only screen and (max-width: 950px) {
  .crash-game {
    height: min(300px, 30vh);
  }

  .crash-game .game-inner {
    padding-bottom: 50px;
  }

  .waiting-rocket {
    display: none;
  }

  .crash-game .inner-rolling {
    top: min(20px, 2.5vh);
    left: min(50px, 6vh);
    padding: min(20px, 2.5vh);
  }

  .crash-game .completed-over,
  .crash-game .rolling-payout,
  .crash-game .waiting-timer {
    font-size: min(16px, 1.8vh);
    margin-top: min(5px, 0.6vh);
  }

  .crash-game .rolling-multiplier {
    font-size: min(16vw, 3.5rem);
    text-shadow:
      0 min(3px, 0.4vh) #0068ff,
      0 0 min(150px, 18vh) #0057bd,
      0 0 min(150px, 18vh) #0057bd,
      0 0 min(50px, 6vh) #004dbd,
      0 0 min(14px, 1.7vh) #0f1761;
  }
}

@media only screen and (max-width: 550px) {
  .crash-game {
    height: min(250px, 28vh);
  }

  .crash-game .inner-completed,
  .crash-game .inner-waiting {
    width: 85%;
    height: 35%;
    padding: min(15px, 1.8vh) min(12px, 1.5vh);
  }

  .crash-game .inner-rolling {
    top: min(12px, 1.5vh);
    left: min(25px, 3vh);
    padding: min(12px, 1.5vh);
  }

  .crash-game .completed-multiplier,
  .crash-game .rolling-multiplier {
    font-size: min(12vw, 1.8rem);
  }

  .crash-game .waiting-status {
    font-size: min(6vw, 1.3rem);
  }

  .crash-game .completed-over,
  .crash-game .rolling-payout,
  .crash-game .waiting-timer {
    font-size: min(12px, 1.4vh);
    margin-top: min(4px, 0.5vh);
  }

  .crash-game .rolling-amount {
    margin-top: min(6px, 0.8vh);
    padding-top: min(8px, 1vh);
  }

  .crash-game .rolling-amount img {
    width: min(16px, 2vh);
    height: min(16px, 2vh);
    margin-right: min(5px, 0.6vh);
  }

  .crash-game .amount-value {
    font-size: min(11px, 1.3vh);
  }

  .crash-game .amount-value span {
    font-size: min(14px, 1.6vh);
  }

  .crash-game .rolling-multiplier {
    text-shadow:
      0 min(2px, 0.3vh) #0068ff,
      0 0 min(120px, 15vh) #0057bd,
      0 0 min(120px, 15vh) #0057bd,
      0 0 min(40px, 5vh) #004dbd,
      0 0 min(12px, 1.5vh) #0f1761;
  }

  .manual-bets-popup {
    border: min(2px, 0.3vh) solid rgba(255, 255, 255, 0.05);
  }

  .popup-header {
    padding: min(10px, 1.2vh) min(12px, 1.5vh);
  }

  .popup-header h3 {
    font-size: min(11px, 1.3vh);
    gap: min(5px, 0.6vh);
  }

  .popup-header h3 img {
    width: min(14px, 1.8vh);
    height: min(14px, 1.8vh);
  }

  .close-button {
    font-size: min(18px, 2.2vh);
    padding: 0 min(3px, 0.4vh);
  }

  .popup-content {
    padding: min(12px, 1.5vh);
  }

  .show-manual-bets {
    top: min(10px, 1.2vh);
    right: min(10px, 1.2vh);
    padding: min(12px, 1.2vh);
    border-radius: min(10px, 2vh);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .show-manual-bets svg {
    width: min(24px, 2.2vh);
    height: min(24px, 2.2vh);
  }
}

@media only screen and (max-width: 350px) {
  .crash-game {
    height: min(220px, 25vh);
  }

  .crash-game .inner-completed,
  .crash-game .inner-waiting {
    width: 90%;
    height: 32%;
    padding: min(12px, 1.5vh) min(10px, 1.2vh);
  }

  .crash-game .completed-multiplier,
  .crash-game .rolling-multiplier {
    font-size: min(11vw, 1.6rem);
  }

  .crash-game .waiting-status {
    font-size: min(5vw, 1.1rem);
  }
}
</style>
