var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"battles-game-boxes",class:{
    'boxes-rolling': _vm.game !== null && _vm.game.state === 'rolling',
  }},[_vm._m(0),_c('div',{staticClass:"boxes-selector"},[_c('div',{staticClass:"selector-inner"},[(_vm.game !== null && _vm.game.state === 'completed')?_c('IconCheckGradient'):_vm._e()],1)]),_c('transition-group',{staticClass:"boxes-list",attrs:{"name":"list","tag":"div"}},_vm._l((_vm.battlesGetBoxes),function(box,index){return _c('button',{key:box.pos,staticClass:"button-box",class:{
        'button-active':
          (index === 0 &&
            ['created', 'countdown', 'pending'].includes(_vm.game.state) ===
              true) ||
          box.pos === _vm.battlesGetRound - 1,
      },on:{"click":function($event){return _vm.battlesBoxButton(box.box)}}},[_c('img',{attrs:{"src":_vm.unboxImagePath + '/img/' + box.box.slug + '.png'}})])}),0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boxes-background"},[_c('div',{staticClass:"background-inner"})])
}]
render._withStripped = true
export { render, staticRenderFns }