var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-banner-user-large"},[_c('div',{staticClass:"user-inner"},[_c('div',{staticClass:"slider-container"},[_vm._l((_vm.slides),function(slide,index){return _c('div',{key:index,staticClass:"image-container",class:{ active: _vm.currentSlide === index },style:({ '--slide-opacity': slide.opacity || 1 })},[_c('img',{attrs:{"src":slide.background,"alt":"Background","draggable":"false"}})])}),_c('div',{staticClass:"slider-nav"},_vm._l((_vm.slides),function(slide,index){return _c('button',{key:index,staticClass:"slider-dot",class:{ active: _vm.currentSlide === index },on:{"click":function($event){return _vm.setSlide(index)}}})}),0)],2),_c('img',{staticClass:"character",style:({
      bottom: _vm.slides[_vm.currentSlide].position?.bottom || '-175px',
      right: _vm.slides[_vm.currentSlide].position?.right || '50px',
      height: _vm.slides[_vm.currentSlide].size || '150%',
      transform: `rotate(${_vm.slides[_vm.currentSlide].rotation || 0}deg)`
    }),attrs:{"src":_vm.slides[_vm.currentSlide].character,"alt":"Character"}}),(_vm.authUser.user === null)?_c('div',{staticClass:"inner-guest"},[_c('div',{staticClass:"headers"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.slides[_vm.currentSlide].title))]),_c('span',{staticClass:"subtitle"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightSubtitle)}})])]),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"actions"},[_c('LoginButton'),_c('AuthButton')],1)]):_c('div',{staticClass:"inner-auth",class:['auth-' + _vm.homeGetLevelColor]},[_c('span',{staticClass:"title"},[_vm._v("WELCOME BACK, "+_vm._s(_vm.authUser.user.username))]),_c('div',{staticClass:"auth-level"},[_c('div',{staticClass:"level-box"},[_c('div',{staticClass:"box-inner"},[_vm._v(" "+_vm._s(_vm.homeGetLevel >= 99 ? 99 : _vm.homeGetLevel)+" ")])]),_c('div',{staticClass:"level-progress"},[_c('div',{staticClass:"progress-bar",style:({
            width: (100 / _vm.homeGetLevelBet) * _vm.homeGetLevelProgress + '%',
          })}),_c('div',{staticClass:"progress-text"},[_c('span',[_vm._v(_vm._s(_vm.homeGetLevelProgress >= 1000000 ? (_vm.homeGetLevelProgress / 1000000).toFixed(2) + "m" : _vm.homeGetLevelProgress >= 1000 ? (_vm.homeGetLevelProgress / 1000).toFixed(2) + "k" : _vm.homeGetLevelProgress.toFixed(2))+" / ")]),_c('span',{staticClass:"text-target"},[_vm._v(" "+_vm._s(_vm.homeGetLevelBet >= 1000000 ? (_vm.homeGetLevelBet / 1000000).toFixed(2) + "m" : _vm.homeGetLevelBet >= 1000 ? (_vm.homeGetLevelBet / 1000).toFixed(2) + "k" : _vm.homeGetLevelBet.toFixed(2))+" ")])])]),_c('div',{staticClass:"level-box"},[_c('div',{staticClass:"box-inner"},[_vm._v(" "+_vm._s(_vm.homeGetLevel >= 99 ? 100 : _vm.homeGetLevel + 1)+" ")])])])])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }