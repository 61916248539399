<template>
  <div class="withdraw-status">
    <!-- Header -->
    <div class="status-header">
      <button class="back-button" @click="$emit('go-back')">
        <i class="fas fa-arrow-left"></i>
        Back
      </button>
      <div class="selected-currency">
        <div class="currency-icon">
          <img 
            :src="selectedCurrency.logo_url" 
            :alt="selectedCurrency.code"
            @error="handleImageError"
          />
        </div>
        <div class="currency-info">
          <span class="currency-name">{{ selectedCurrency.name }} ({{ selectedCurrency.code.toUpperCase() }})</span>
          <span class="network-name">{{ selectedCurrency.network.toUpperCase() }}</span>
        </div>
      </div>
    </div>

    <div class="details-container">
      <!-- Status Information -->
      <div class="status-info">
        <!-- Status Card -->
        <div class="status-card" :class="withdrawalData.status">
          <div class="status-icon">
            <i class="fas" :class="statusIcon"></i>
          </div>
          <div class="status-content">
            <div class="status-text">{{ statusMessage }}</div>
            <div class="progress-bar" v-if="withdrawalData.status === 'processing'">
              <div class="progress" style="width: 25%"></div>
            </div>
          </div>
        </div>

        <!-- Withdrawal Amount -->
        <div class="info-card">
          <div class="info-header">Amount:</div>
          <div class="amount-display">
            <img 
              :src="selectedCurrency.logo_url" 
              :alt="selectedCurrency.code"
              @error="handleImageError"
            />
            <span class="amount">{{ withdrawalData.amount }} {{ selectedCurrency.code.toUpperCase() }}</span>
          </div>
        </div>

        <!-- Withdrawal Address -->
        <div class="info-card">
          <div class="info-header">Withdrawal Address:</div>
          <div class="copy-field">
            <input type="text" readonly :value="withdrawalData.address" />
            <button @click="copyToClipboard(withdrawalData.address)" class="copy-button">
              <i class="fas fa-copy"></i>
            </button>
          </div>
        </div>

        <!-- Transaction ID -->
        <div class="info-card" v-if="withdrawalData.txid">
          <div class="info-header">Transaction ID:</div>
          <div class="copy-field">
            <input type="text" readonly :value="withdrawalData.txid" />
            <button @click="copyToClipboard(withdrawalData.txid)" class="copy-button">
              <i class="fas fa-copy"></i>
            </button>
          </div>
        </div>

        <!-- Warning Message -->
        <div class="warning-card" v-if="withdrawalData.status === 'processing'">
          <i class="fas fa-exclamation-triangle"></i>
          <span>Your withdrawal is being processed. This may take a few minutes.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { mapActions } from 'vuex';

export default {
  name: 'WithdrawStatus',

  props: {
    withdrawalData: {
      type: Object,
      required: true
    },
    selectedCurrency: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const statusCheckInterval = ref(null);

    const statusIcon = computed(() => {
      const icons = {
        'creating': 'fa-clock',
        'processing': 'fa-spinner fa-spin',
        'sending': 'fa-paper-plane',
        'finished': 'fa-check-circle',
        'failed': 'fa-times-circle',
        'rejected': 'fa-ban'
      };
      return icons[props.withdrawalData.status] || 'fa-clock';
    });

    const statusMessage = computed(() => {
      const messages = {
        'creating': 'Creating withdrawal request...',
        'waiting': 'Waiting for processing...',
        'processing': 'Processing your withdrawal...',
        'sending': 'Sending funds...',
        'finished': 'Withdrawal completed!',
        'failed': 'Withdrawal failed',
        'rejected': 'Withdrawal rejected',
        'cancelled': 'Withdrawal cancelled'
      };
      return messages[props.withdrawalData.status] || 'Processing request...';
    });

    const paymentProgress = computed(() => {
      const progress = {
        'creating': 20,
        'waiting': 40,
        'processing': 60,
        'sending': 80,
        'finished': 100,
        'failed': 100,
        'rejected': 100,
        'cancelled': 100
      };
      return progress[props.withdrawalData.status] || 0;
    });

    return {
      statusIcon,
      statusMessage,
      paymentProgress
    };
  },

  methods: {
    ...mapActions(['notificationShow']),

    handleImageError(event) {
      const target = event.target;
      const code = this.selectedCurrency.code.toLowerCase();
      target.src = `https://nowpayments.io/images/coins/${code}.svg`;
    },

    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.notificationShow({
          type: 'success',
          message: 'Copied to clipboard!'
        });
      } catch (error) {
        console.error('Failed to copy:', error);
      }
    },

    async checkPayoutStatus() {
      try {
        const withdrawalId = this.withdrawalData.withdrawal_id;
        if (!withdrawalId) return;

        const response = await nowPaymentsService.getPaymentStatus(withdrawalId);

        if (response) {
          // Update local status
          this.withdrawalData.status = response.withdrawals?.[0]?.status?.toLowerCase() || 'processing';
          
          // Update transaction hash if available
          if (response.withdrawals?.[0]?.hash) {
            this.withdrawalData.txid = response.withdrawals[0].hash;
          }
          
          // Stop polling if we reach a final status
          if (['finished', 'failed', 'rejected', 'cancelled'].includes(this.withdrawalData.status)) {
            this.stopStatusCheck();
          }
        }
      } catch (error) {
        console.error('Failed to check withdrawal status:', error);
      }
    },

    startStatusCheck() {
      this.statusCheckInterval = setInterval(this.checkPayoutStatus, 30000); // Check every 30 seconds
    },

    stopStatusCheck() {
      if (this.statusCheckInterval) {
        clearInterval(this.statusCheckInterval);
        this.statusCheckInterval = null;
      }
    }
  },

  mounted() {
    this.startStatusCheck();
  },

  beforeDestroy() {
    this.stopStatusCheck();
  }
}
</script>

<style scoped>
.withdraw-status {
  padding: 16px;
  max-width: 960px;
  margin: 0 auto;
}

.status-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(31, 42, 68, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.2s ease;
}

.back-button:hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(31, 42, 68, 0.5) 100%);
  transform: translateY(-1px);
}

.details-container {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.status-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 0;
}

.status-card {
  padding: 16px;
  background: linear-gradient(180deg, rgba(44, 94, 255, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(44, 94, 255, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  display: flex;
  align-items: center;
  gap: 16px;
}

.status-card.finished {
  background: linear-gradient(180deg, rgba(76, 175, 80, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  border: 1px solid rgba(76, 175, 80, 0.2);
}

.status-card.failed,
.status-card.rejected,
.status-card.cancelled {
  background: linear-gradient(180deg, rgba(233, 72, 72, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  border: 1px solid rgba(233, 72, 72, 0.2);
}

.status-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #2C5EFF;
  flex-shrink: 0;
}

.status-card.finished .status-icon {
  color: #4CAF50;
}

.status-card.failed .status-icon,
.status-card.rejected .status-icon,
.status-card.cancelled .status-icon {
  color: #E94848;
}

.status-content {
  flex: 1;
  min-width: 0;
}

.status-text {
  color: rgba(255, 255, 255, 0.9);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
}

.progress-bar {
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
  box-shadow: 0px 0px 8px rgba(44, 94, 255, 0.5);
  transition: width 0.3s ease;
}

.info-card {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.info-header {
  color: rgba(255, 255, 255, 0.7);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  letter-spacing: 0.2px;
}

.amount-display {
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 0.9);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.amount-display img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
}

.copy-field {
  display: flex;
  gap: 8px;
  width: 100%;
}

.copy-field input {
  flex: 1;
  padding: 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(31, 42, 68, 0.2) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  letter-spacing: 0.2px;
  min-width: 0;
  width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
  height: auto;
  min-height: 44px;
  resize: none;
  overflow-wrap: break-word;
  line-height: 1.4;
}

.copy-button {
  padding: 0 16px;
  height: 44px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(31, 42, 68, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.2s ease;
  white-space: nowrap;
  align-self: flex-start;
}

.copy-button:hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(31, 42, 68, 0.5) 100%);
  transform: translateY(-1px);
}

.warning-card {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: linear-gradient(180deg, rgba(255, 193, 7, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 193, 7, 0.2);
  color: #FFC107;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.selected-currency {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  background: linear-gradient(180deg, rgba(44, 94, 255, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  border: 1px solid rgba(44, 94, 255, 0.2);
}

.currency-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.currency-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
}

.currency-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.currency-name {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.2px;
}

.network-name {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .withdraw-status {
    padding: 12px;
  }

  .status-header {
    padding: 12px;
  }

  .details-container {
    padding: 16px;
  }

  .status-card {
    padding: 12px;
  }

  .status-icon {
    width: 32px;
    height: 32px;
    font-size: 20px;
  }

  .status-text {
    font-size: 13px;
  }

  .info-card {
    padding: 12px;
  }

  .info-header {
    font-size: 13px;
  }

  .amount-display {
    font-size: 18px;
  }

  .copy-field {
    flex-direction: column;
  }

  .copy-field input {
    font-size: 13px;
    padding: 10px;
    min-height: 40px;
  }

  .copy-button {
    width: 100%;
    height: 40px;
    align-self: stretch;
  }

  .warning-card {
    font-size: 12px;
    padding: 12px;
  }

  .currency-name {
    font-size: 13px;
  }

  .network-name {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .withdraw-status {
    padding: 8px;
  }

  .status-header {
    padding: 10px;
  }

  .details-container {
    padding: 12px;
  }

  .status-card {
    padding: 10px;
    gap: 12px;
  }

  .status-icon {
    width: 28px;
    height: 28px;
    font-size: 18px;
  }

  .status-text {
    font-size: 12px;
  }

  .info-card {
    padding: 10px;
  }

  .info-header {
    font-size: 12px;
  }

  .amount-display {
    font-size: 16px;
  }

  .copy-field input {
    font-size: 12px;
    padding: 8px;
    min-height: 36px;
  }

  .copy-button {
    height: 36px;
  }

  .warning-card {
    font-size: 11px;
    padding: 10px;
  }

  .currency-icon {
    width: 28px;
    height: 28px;
  }

  .currency-name {
    font-size: 12px;
  }

  .network-name {
    font-size: 10px;
  }
}
</style>
