<template>
  <div class="avatar-image">
    <img :src="getImagePath" @error="avatarImageError()" alt="avatar"/>
  </div>
</template>

<script>
export default {
  name: 'AvatarImage',
  props: ['image'],
  data() {
    return {
      avatarImage: null,
      serverUrl: process.env.VUE_APP_BACKEND_URL
    };
  },
  computed: {
    getImagePath() {
      // If it's a full URL (from server), use it directly
      if (this.avatarImage && this.avatarImage.startsWith('http')) {
        return this.avatarImage;
      }
      // Remove /public/ prefix if it exists
      if (this.avatarImage && this.avatarImage.startsWith('/public/')) {
        return this.avatarImage.replace('/public', '');
      }
      // If it's a local avatar, use the path as is
      return this.avatarImage;
    }
  },
  methods: {
    avatarImageError() {
      // If the image prop exists but failed to load, try without the /public/ prefix
      if (this.image) {
        this.avatarImage = this.image.replace('/public', '');
      } else {
        // Only if no image was provided, use avatar 1 as default
        this.avatarImage = "/img/avatar/1.webp";
      }
    }
  },
  created() {
    this.avatarImage = this.image || "/img/avatar/1.webp";
  }
};
</script>

<style scoped>
.avatar-image {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.avatar-image img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 100%;
}
</style>
