<template>
    <div></div>
</template>

<script>
export default {
    name: 'CardStyles'
}
</script>

<style>
.code-card {
    background: linear-gradient(135deg, rgba(44, 94, 255, 0.15) 0%, rgba(44, 94, 255, 0.05) 100%);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2),
                0px -1px 0px rgba(0, 0, 0, 0.3) inset,
                0px 1px 0px rgba(255, 255, 255, 0.1) inset;
    border: 1px solid rgba(44, 94, 255, 0.1);
}

.card-header {
    padding: 20px;
    background: rgba(44, 94, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.card-header span {
    font-size: 14px;
    font-weight: 700;
    color: #8bacc8;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.card-content {
    padding: 24px;
}

@media only screen and (max-width: 480px) {
    .card-content {
        padding: 16px;
    }
}
</style> 