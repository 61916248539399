<template>
    <div>
        <div v-if="codes.length > 0" class="codes-list">
            <h3 class="section-title">YOUR AFFILIATE CODES</h3>
            <div class="codes-container">
                <div v-for="code in codes" :key="code.code" class="code-item">
                    <div class="code-main">
                        <div class="code-info">
                            <span class="code-text">{{ code.code }}</span>
                            <div class="code-stats">
                                <span>{{ code.referredCount || 0 }} referrals</span>
                                <div class="rates-summary">
                                    <span>Commission rates:</span>
                                    <span>Slots: {{ ((code.rates?.slots || 0.05) * 100).toFixed(1) }}%</span>
                                    <span>Sports: {{ ((code.rates?.sports || 0.05) * 100).toFixed(1) }}%</span>
                                    <span>Other games: {{ ((code.rates?.original || 0.05) * 100).toFixed(1) }}%</span>
                                </div>
                                <span>${{ code.maxBonus || 1000 }} max • {{ code.wageringRequirement || 30 }}x wagering</span>
                            </div>
                        </div>
                        <button 
                            @click="copyCode(code.code)" 
                            class="copy-button"
                            title="Copy affiliate link"
                        >
                            <IconCopy />
                        </button>
                    </div>
                    <div class="code-link">
                        {{ brandUrl }}/?a={{ code.code }}
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="no-codes-message">
            <p>You haven't created any affiliate codes yet. Create one above to start earning!</p>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import IconCopy from '@/components/icons/IconCopy';

export default {
    name: 'AffiliateCodesList',
    components: {
        IconCopy
    },
    computed: {
        ...mapGetters(['affiliatesData']),
        codes() {
            return this.affiliatesData?.data?.codes || [];
        },
        brandUrl() {
            return getComputedStyle(document.documentElement)
                .getPropertyValue('--brand-url')
                .trim()
                .replace(/['"]/g, '') || 'https://bazedbet.com';
        }
    },
    methods: {
        ...mapActions(['notificationShow']),
        copyCode(code) {
            const el = document.createElement('textarea');
            el.value = `${this.brandUrl}/?a=${code}`;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            this.notificationShow({ type: 'success', message: 'Copied to your clipboard.' });
        }
    }
}
</script>

<style scoped>
.codes-list {
    margin-top: 24px;
}

.section-title {
    font-size: 14px;
    font-weight: 700;
    color: #8bacc8;
    margin-bottom: 16px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.codes-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.code-item {
    background: rgba(13, 23, 39, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    transition: all 0.3s ease;
}

.code-item:hover {
    background: rgba(44, 94, 255, 0.1);
    border-color: rgba(44, 94, 255, 0.2);
    transform: translateY(-1px);
}

.code-main {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.code-info {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.code-text {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0.02em;
}

.code-stats {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #8bacc8;
    font-size: 13px;
}

.code-link {
    padding: 12px 16px;
    background: rgba(13, 23, 39, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    color: #8bacc8;
    font-size: 13px;
    font-family: monospace;
}

.copy-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    transition: all 0.3s ease;
}

.copy-button:hover {
    background: rgba(44, 94, 255, 0.2);
    transform: translateY(-1px);
}

.copy-button svg {
    width: 16px;
    height: 16px;
    fill: #8bacc8;
    transition: fill 0.3s ease;
}

.copy-button:hover svg {
    fill: #ffffff;
}

.no-codes-message {
    text-align: center;
    color: #8bacc8;
    padding: 32px;
    background: rgba(13, 23, 39, 0.3);
    border-radius: 12px;
    font-size: 14px;
    line-height: 1.6;
}

.rates-summary {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin: 4px 0;
}

.rates-summary span {
    color: #8bacc8;
    font-size: 13px;
}

.rates-summary span:first-child {
    color: #ffffff;
    font-weight: 500;
}

@media only screen and (max-width: 768px) {
    .code-main {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .copy-button {
        width: 100%;
        margin-top: 8px;
    }
}

@media only screen and (max-width: 480px) {
    .code-text {
        font-size: 14px;
    }

    .code-stats {
        font-size: 12px;
    }

    .code-link {
        font-size: 12px;
        padding: 10px 16px;
        word-break: break-all;
    }
}
</style> 