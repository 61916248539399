<template>
  <div class="withdraw">
    <div class="withdraw-header">
      <div class="withdraw-title">
        <div class="title-icon">
          <img src="@/assets/img/bets.png" alt="Withdraw" />
        </div>
        <h2>Withdraw</h2>
      </div>
    </div>

    <!-- Step-based content with transitions -->
    <transition name="fade" mode="out-in">
      <div class="withdraw-content">
        <CurrencySelector 
          v-if="currentStep === 'select'"
          :currencies="localCurrencies"
          :selectedNetwork="selectedNetwork"
          @select-currency="handleCurrencySelect"
        />

        <WithdrawForm
          v-if="currentStep === 'form'"
          :selectedCurrency="selectedCurrency"
          :minAmount="minAmount"
          :userBalance="parseFloat(userBalance.replace(/,/g, ''))"
          @submit-withdrawal="handleWithdrawal"
          @go-back="currentStep = 'select'"
        />

        <WithdrawStatus
          v-if="currentStep === 'status'"
          :withdrawalData="withdrawalData"
          :selectedCurrency="selectedCurrency"
          @go-back="currentStep = 'form'"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import nowPaymentsService from '@/services/nowPayments';
import CurrencySelector from './deposit/CurrencySelector.vue';
import WithdrawForm from './withdraw/WithdrawForm.vue';
import WithdrawStatus from './withdraw/WithdrawStatus.vue';
import coinData from '@/assets/icons/coins/coin_data.json';

export default {
  name: 'CashierWithdraw',
  
  components: {
    CurrencySelector,
    WithdrawForm,
    WithdrawStatus
  },

  props: {
    currencies: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      currentStep: 'select',
      localCurrencies: [],
      selectedCurrency: null,
      selectedNetwork: 'all',
      minAmount: null,
      withdrawalData: null,
      isLocalDataLoaded: false
    };
  },

  watch: {
    currencies: {
      immediate: true,
      handler(newCurrencies) {
        if (!this.isLocalDataLoaded && newCurrencies?.length) {
          this.loadLocalCurrencies();
        }
      }
    }
  },

  computed: {
    ...mapState({
      userBalance: state => {
        const balance = state.auth.user?.balance || 0;
        return parseFloat(Math.floor(balance / 10) / 100)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      rawBalance: state => state.auth.user?.balance || 0
    }),
    ...mapGetters([
      'authUser'
    ]),
    userBalance() {
      return parseFloat(Math.floor(this.authUser.user.balance / 10) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    rawBalance() {
      return this.authUser.user.balance || 0;
    }
  },

  methods: {
    ...mapActions(['notificationShow']),

    transformCoinData(coinData) {
      return coinData.currencies.map(coin => {
        try {
          return {
            ...coin,
            logo_url: require(`@/assets/icons/coins/${coin.code.toLowerCase()}_logo.svg`)
          };
        } catch (error) {
          console.warn(`Missing icon for ${coin.code}`);
          return {
            ...coin,
            logo_url: require('@/assets/icons/coins/btc_logo.svg') // Using BTC as fallback
          };
        }
      });
    },

    async loadLocalCurrencies() {
      try {
        // Define featured withdrawal currencies
        const featuredWithdrawalCurrencies = [
          'btc',
          'eth',
          'usdterc20',
          'trx',
          'bnb',
          'ltc'
        ];

        // Transform and sort currencies
        this.localCurrencies = this.transformCoinData(coinData)
          .sort((a, b) => {
            const indexA = featuredWithdrawalCurrencies.indexOf(a.code.toLowerCase());
            const indexB = featuredWithdrawalCurrencies.indexOf(b.code.toLowerCase());
            
            // If both are featured, sort by their order in featuredWithdrawalCurrencies
            if (indexA !== -1 && indexB !== -1) {
              return indexA - indexB;
            }
            // If only a is featured, it comes first
            if (indexA !== -1) return -1;
            // If only b is featured, it comes first
            if (indexB !== -1) return 1;
            // If neither is featured, maintain original order
            return 0;
          });

        this.isLocalDataLoaded = true;
        console.log('Loaded local withdrawal currencies:', this.localCurrencies);
      } catch (error) {
        console.error('Error loading currencies:', error);
        this.notificationShow({
          type: 'error',
          message: 'Failed to load currencies'
        });
      }
    },

    async handleCurrencySelect(currency) {
      if (!currency || !currency.code) {
        console.error('Invalid currency selected:', currency);
        this.notificationShow({
          type: 'error',
          message: 'Invalid currency selected'
        });
        return;
      }

      this.selectedCurrency = currency;
      
      try {
        console.log('Selected currency:', currency);
        const minAmountResponse = await nowPaymentsService.getMinimumWithdrawalAmount(
          currency.code
        );
        
        console.log('Minimum withdrawal amount response:', minAmountResponse);
        this.minAmount = minAmountResponse.min_amount;
        
        this.currentStep = 'form';
      } catch (error) {
        console.error('Error getting minimum amount:', error);
        this.notificationShow({
          type: 'error',
          message: error.response?.data?.details || 'Failed to get minimum amount'
        });
      }
    },

    async handleWithdrawal(data) {
      try {
        const response = await nowPaymentsService.createPayout(data);
        
        this.withdrawalData = {
          address: data.withdrawals[0].address,
          amount: data.withdrawals[0].amount,
          currency: data.withdrawals[0].currency,
          withdrawal_id: response.withdrawal_id,
          status: 'waiting',
          fiat_amount: data.fiat_amount,
          fiat_currency: data.fiat_currency
        };

        this.currentStep = 'status';
      } catch (error) {
        console.error('Withdrawal creation failed:', error);
        this.notificationShow({
          type: 'error',
          message: error.response?.data?.message || 'Failed to create withdrawal'
        });
      }
    }
  },

  async created() {
    await this.loadLocalCurrencies();
  }
};
</script>

<style scoped>
.withdraw {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  padding: 20px;
  font-family: Montserrat, sans-serif;
}

.withdraw-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.withdraw-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.title-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.withdraw-title h2 {
  font-size: 18px;
  font-weight: 700;
  color: var(--highlight-white);
  margin: 0;
}

.withdraw-content {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.3) inset;
  border-radius: 10px;
  overflow: hidden;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Responsive Design */
@media only screen and (max-width: 1100px) {
  .withdraw {
    padding: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .withdraw {
    padding: 10px;
  }

  .withdraw-title h2 {
    font-size: 16px;
  }
}
</style>
