// Frontend Rakeback Constants
export const RAKEBACK_TIERS = {
  IRON: {
    name: 'iron',
    icon: '⚔️',
    slotsRate: 0.01,
    sportsRate: 0.003,
    minWager: 100,
    benefits: {
      reloadBonus: 0.03,
      withdrawalLimit: 5000,
      eventAccess: false,
      giveawayEntries: 1,
      birthdayBonus: 25,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  IRON_I: {
    name: 'iron I',
    icon: '⚔️',
    slotsRate: 0.011,
    sportsRate: 0.004,
    minWager: 250,
    benefits: {
      reloadBonus: 0.035,
      withdrawalLimit: 6000,
      eventAccess: false,
      giveawayEntries: 1,
      birthdayBonus: 30,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  IRON_II: {
    name: 'iron II',
    icon: '⚔️',
    slotsRate: 0.012,
    sportsRate: 0.005,
    minWager: 500,
    benefits: {
      reloadBonus: 0.04,
      withdrawalLimit: 7000,
      eventAccess: false,
      giveawayEntries: 1,
      birthdayBonus: 35,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  IRON_III: {
    name: 'iron III',
    icon: '⚔️',
    slotsRate: 0.014,
    sportsRate: 0.006,
    minWager: 750,
    benefits: {
      reloadBonus: 0.045,
      withdrawalLimit: 8000,
      eventAccess: false,
      giveawayEntries: 1,
      birthdayBonus: 40,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  IRON_IV: {
    name: 'iron IV',
    icon: '⚔️',
    slotsRate: 0.016,
    sportsRate: 0.007,
    minWager: 1000,
    benefits: {
      reloadBonus: 0.05,
      withdrawalLimit: 9000,
      eventAccess: false,
      giveawayEntries: 1,
      birthdayBonus: 45,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  IRON_V: {
    name: 'iron V',
    icon: '⚔️',
    slotsRate: 0.018,
    sportsRate: 0.008,
    minWager: 1500,
    benefits: {
      reloadBonus: 0.055,
      withdrawalLimit: 10000,
      eventAccess: false,
      giveawayEntries: 1,
      birthdayBonus: 50,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  BRONZE: {
    name: 'bronze',
    icon: '🥉',
    slotsRate: 0.02,
    sportsRate: 0.009,
    minWager: 2000,
    benefits: {
      reloadBonus: 0.06,
      withdrawalLimit: 15000,
      eventAccess: false,
      giveawayEntries: 2,
      birthdayBonus: 75,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  BRONZE_I: {
    name: 'bronze I',
    icon: '🥉',
    slotsRate: 0.021,
    sportsRate: 0.0095,
    minWager: 2500,
    benefits: {
      reloadBonus: 0.065,
      withdrawalLimit: 16000,
      eventAccess: false,
      giveawayEntries: 2,
      birthdayBonus: 80,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  BRONZE_II: {
    name: 'bronze II',
    icon: '🥉',
    slotsRate: 0.022,
    sportsRate: 0.010,
    minWager: 3000,
    benefits: {
      reloadBonus: 0.07,
      withdrawalLimit: 17000,
      eventAccess: false,
      giveawayEntries: 2,
      birthdayBonus: 85,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  BRONZE_III: {
    name: 'bronze III',
    icon: '🥉',
    slotsRate: 0.024,
    sportsRate: 0.0105,
    minWager: 3500,
    benefits: {
      reloadBonus: 0.075,
      withdrawalLimit: 18000,
      eventAccess: false,
      giveawayEntries: 2,
      birthdayBonus: 90,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  BRONZE_IV: {
    name: 'bronze IV',
    icon: '🥉',
    slotsRate: 0.026,
    sportsRate: 0.011,
    minWager: 4000,
    benefits: {
      reloadBonus: 0.08,
      withdrawalLimit: 19000,
      eventAccess: false,
      giveawayEntries: 2,
      birthdayBonus: 95,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  BRONZE_V: {
    name: 'bronze V',
    icon: '🥉',
    slotsRate: 0.028,
    sportsRate: 0.0115,
    minWager: 4500,
    benefits: {
      reloadBonus: 0.085,
      withdrawalLimit: 20000,
      eventAccess: false,
      giveawayEntries: 2,
      birthdayBonus: 100,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  SILVER: {
    name: 'silver',
    icon: '🥈',
    slotsRate: 0.03,
    sportsRate: 0.012,
    minWager: 5000,
    benefits: {
      reloadBonus: 0.09,
      withdrawalLimit: 25000,
      eventAccess: true,
      giveawayEntries: 2,
      birthdayBonus: 150,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  SILVER_I: {
    name: 'silver I',
    icon: '🥈',
    slotsRate: 0.031,
    sportsRate: 0.0125,
    minWager: 6000,
    benefits: {
      reloadBonus: 0.095,
      withdrawalLimit: 26000,
      eventAccess: false,
      giveawayEntries: 2,
      birthdayBonus: 160,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  SILVER_II: {
    name: 'silver II',
    icon: '🥈',
    slotsRate: 0.032,
    sportsRate: 0.013,
    minWager: 7000,
    benefits: {
      reloadBonus: 0.1,
      withdrawalLimit: 27000,
      eventAccess: false,
      giveawayEntries: 2,
      birthdayBonus: 170,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  SILVER_III: {
    name: 'silver III',
    icon: '🥈',
    slotsRate: 0.034,
    sportsRate: 0.0135,
    minWager: 8000,
    benefits: {
      reloadBonus: 0.105,
      withdrawalLimit: 28000,
      eventAccess: true,
      giveawayEntries: 2,
      birthdayBonus: 180,
      reloadBonusEnabled: true,
      exclusiveEvents: true,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  SILVER_IV: {
    name: 'silver IV',
    icon: '🥈',
    slotsRate: 0.036,
    sportsRate: 0.014,
    minWager: 9000,
    benefits: {
      reloadBonus: 0.11,
      withdrawalLimit: 29000,
      eventAccess: false,
      giveawayEntries: 2,
      birthdayBonus: 190,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  SILVER_V: {
    name: 'silver V',
    icon: '🥈',
    slotsRate: 0.038,
    sportsRate: 0.0145,
    minWager: 10000,
    benefits: {
      reloadBonus: 0.115,
      withdrawalLimit: 30000,
      eventAccess: false,
      giveawayEntries: 2,
      birthdayBonus: 200,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  GOLD: {
    name: 'gold',
    icon: '🥇',
    slotsRate: 0.04,
    sportsRate: 0.015,
    minWager: 10000,
    benefits: {
      reloadBonus: 0.12,
      withdrawalLimit: 35000,
      eventAccess: true,
      giveawayEntries: 3,
      birthdayBonus: 250,
      reloadBonusEnabled: true,
      exclusiveEvents: true,
      privateGames: true,
      customLimits: false,
      luxuryGifts: false
    }
  },
  GOLD_I: {
    name: 'gold I',
    icon: '🥇',
    slotsRate: 0.041,
    sportsRate: 0.0155,
    minWager: 11000,
    benefits: {
      reloadBonus: 0.125,
      withdrawalLimit: 36000,
      eventAccess: false,
      giveawayEntries: 3,
      birthdayBonus: 260,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  GOLD_II: {
    name: 'gold II',
    icon: '🥇',
    slotsRate: 0.042,
    sportsRate: 0.016,
    minWager: 12000,
    benefits: {
      reloadBonus: 0.13,
      withdrawalLimit: 37000,
      eventAccess: false,
      giveawayEntries: 3,
      birthdayBonus: 270,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  GOLD_III: {
    name: 'gold III',
    icon: '🥇',
    slotsRate: 0.044,
    sportsRate: 0.0165,
    minWager: 13000,
    benefits: {
      reloadBonus: 0.135,
      withdrawalLimit: 38000,
      eventAccess: true,
      giveawayEntries: 3,
      birthdayBonus: 280,
      reloadBonusEnabled: true,
      exclusiveEvents: true,
      privateGames: true,
      customLimits: true,
      luxuryGifts: false
    }
  },
  GOLD_IV: {
    name: 'gold IV',
    icon: '🥇',
    slotsRate: 0.046,
    sportsRate: 0.017,
    minWager: 14000,
    benefits: {
      reloadBonus: 0.14,
      withdrawalLimit: 39000,
      eventAccess: false,
      giveawayEntries: 3,
      birthdayBonus: 290,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  GOLD_V: {
    name: 'gold V',
    icon: '🥇',
    slotsRate: 0.048,
    sportsRate: 0.0175,
    minWager: 15000,
    benefits: {
      reloadBonus: 0.145,
      withdrawalLimit: 40000,
      eventAccess: false,
      giveawayEntries: 3,
      birthdayBonus: 300,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  PLATINUM: {
    name: 'platinum',
    icon: '💎',
    slotsRate: 0.05,
    sportsRate: 0.018,
    minWager: 15000,
    benefits: {
      reloadBonus: 0.15,
      withdrawalLimit: 45000,
      eventAccess: true,
      giveawayEntries: 4,
      birthdayBonus: 350,
      reloadBonusEnabled: true,
      exclusiveEvents: true,
      privateGames: true,
      customLimits: true,
      luxuryGifts: true
    }
  },
  PLATINUM_I: {
    name: 'platinum I',
    icon: '💎',
    slotsRate: 0.051,
    sportsRate: 0.0185,
    minWager: 16000,
    benefits: {
      reloadBonus: 0.155,
      withdrawalLimit: 46000,
      eventAccess: false,
      giveawayEntries: 4,
      birthdayBonus: 360,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  PLATINUM_II: {
    name: 'platinum II',
    icon: '💎',
    slotsRate: 0.052,
    sportsRate: 0.019,
    minWager: 17000,
    benefits: {
      reloadBonus: 0.16,
      withdrawalLimit: 47000,
      eventAccess: false,
      giveawayEntries: 4,
      birthdayBonus: 370,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  PLATINUM_III: {
    name: 'platinum III',
    icon: '💎',
    slotsRate: 0.054,
    sportsRate: 0.0195,
    minWager: 18000,
    benefits: {
      reloadBonus: 0.165,
      withdrawalLimit: 48000,
      eventAccess: false,
      giveawayEntries: 4,
      birthdayBonus: 380,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  PLATINUM_IV: {
    name: 'platinum IV',
    icon: '💎',
    slotsRate: 0.056,
    sportsRate: 0.02,
    minWager: 19000,
    benefits: {
      reloadBonus: 0.17,
      withdrawalLimit: 49000,
      eventAccess: false,
      giveawayEntries: 4,
      birthdayBonus: 390,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  PLATINUM_V: {
    name: 'platinum V',
    icon: '💎',
    slotsRate: 0.058,
    sportsRate: 0.0205,
    minWager: 20000,
    benefits: {
      reloadBonus: 0.175,
      withdrawalLimit: 50000,
      eventAccess: false,
      giveawayEntries: 4,
      birthdayBonus: 400,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  DIAMOND: {
    name: 'diamond',
    icon: '💎',
    slotsRate: 0.06,
    sportsRate: 0.021,
    minWager: 20000,
    benefits: {
      reloadBonus: 0.18,
      withdrawalLimit: 55000,
      eventAccess: true,
      giveawayEntries: 5,
      birthdayBonus: 450,
      reloadBonusEnabled: true,
      exclusiveEvents: true,
      privateGames: true,
      customLimits: true,
      luxuryGifts: true
    }
  },
  DIAMOND_I: {
    name: 'diamond I',
    icon: '💎',
    slotsRate: 0.061,
    sportsRate: 0.0215,
    minWager: 21000,
    benefits: {
      reloadBonus: 0.185,
      withdrawalLimit: 56000,
      eventAccess: false,
      giveawayEntries: 5,
      birthdayBonus: 460,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  DIAMOND_II: {
    name: 'diamond II',
    icon: '💎',
    slotsRate: 0.062,
    sportsRate: 0.022,
    minWager: 22000,
    benefits: {
      reloadBonus: 0.19,
      withdrawalLimit: 57000,
      eventAccess: false,
      giveawayEntries: 5,
      birthdayBonus: 470,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  DIAMOND_III: {
    name: 'diamond III',
    icon: '💎',
    slotsRate: 0.064,
    sportsRate: 0.0225,
    minWager: 23000,
    benefits: {
      reloadBonus: 0.195,
      withdrawalLimit: 58000,
      eventAccess: false,
      giveawayEntries: 5,
      birthdayBonus: 480,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  DIAMOND_IV: {
    name: 'diamond IV',
    icon: '💎',
    slotsRate: 0.066,
    sportsRate: 0.023,
    minWager: 24000,
    benefits: {
      reloadBonus: 0.2,
      withdrawalLimit: 59000,
      eventAccess: false,
      giveawayEntries: 5,
      birthdayBonus: 490,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  DIAMOND_V: {
    name: 'diamond V',
    icon: '💎',
    slotsRate: 0.068,
    sportsRate: 0.0235,
    minWager: 25000,
    benefits: {
      reloadBonus: 0.205,
      withdrawalLimit: 60000,
      eventAccess: false,
      giveawayEntries: 5,
      birthdayBonus: 500,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  MASTER: {
    name: 'master',
    icon: '👑',
    slotsRate: 0.07,
    sportsRate: 0.024,
    minWager: 25000,
    benefits: {
      reloadBonus: 0.21,
      withdrawalLimit: 65000,
      eventAccess: true,
      giveawayEntries: 6,
      birthdayBonus: 550,
      reloadBonusEnabled: true,
      exclusiveEvents: true,
      privateGames: true,
      customLimits: true,
      luxuryGifts: true
    }
  },
  MASTER_I: {
    name: 'master I',
    icon: '👑',
    slotsRate: 0.071,
    sportsRate: 0.0245,
    minWager: 26000,
    benefits: {
      reloadBonus: 0.215,
      withdrawalLimit: 66000,
      eventAccess: false,
      giveawayEntries: 6,
      birthdayBonus: 560,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  MASTER_II: {
    name: 'master II',
    icon: '👑',
    slotsRate: 0.072,
    sportsRate: 0.025,
    minWager: 27000,
    benefits: {
      reloadBonus: 0.22,
      withdrawalLimit: 67000,
      eventAccess: false,
      giveawayEntries: 6,
      birthdayBonus: 570,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  MASTER_III: {
    name: 'master III',
    icon: '👑',
    slotsRate: 0.074,
    sportsRate: 0.0255,
    minWager: 28000,
    benefits: {
      reloadBonus: 0.225,
      withdrawalLimit: 68000,
      eventAccess: false,
      giveawayEntries: 6,
      birthdayBonus: 580,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  MASTER_IV: {
    name: 'master IV',
    icon: '👑',
    slotsRate: 0.076,
    sportsRate: 0.026,
    minWager: 29000,
    benefits: {
      reloadBonus: 0.23,
      withdrawalLimit: 69000,
      eventAccess: false,
      giveawayEntries: 6,
      birthdayBonus: 590,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  MASTER_V: {
    name: 'master V',
    icon: '👑',
    slotsRate: 0.078,
    sportsRate: 0.0265,
    minWager: 30000,
    benefits: {
      reloadBonus: 0.235,
      withdrawalLimit: 70000,
      eventAccess: false,
      giveawayEntries: 6,
      birthdayBonus: 600,
      reloadBonusEnabled: true,
      exclusiveEvents: false,
      privateGames: false,
      customLimits: false,
      luxuryGifts: false
    }
  },
  LEGEND: {
    name: 'legend',
    icon: '👑',
    slotsRate: 0.08,
    sportsRate: 0.04,
    minWager: 100000,
    benefits: {
      reloadBonus: 0.25,
      withdrawalLimit: -1,
      eventAccess: true,
      giveawayEntries: 25,
      birthdayBonus: 5000,
      reloadBonusEnabled: true,
      exclusiveEvents: true,
      privateGames: true,
      customLimits: true,
      luxuryGifts: true
    }
  }
};

// Helper function to convert number to Roman numeral
export const toRomanNumeral = (num) => {
  const romanNumerals = ['I', 'II', 'III', 'IV', 'V'];
  return romanNumerals[num - 1] || '';
};

// Helper functions
export const getTierByWager = (wagerAmount) => {
  const tiers = Object.values(RAKEBACK_TIERS);
  return tiers.reduce((prevTier, currentTier) => {
    return wagerAmount >= currentTier.minWager ? currentTier : prevTier;
  }, tiers[0]);
};

export const getNextTier = (currentTierName) => {
  const tiers = Object.values(RAKEBACK_TIERS).sort((a, b) => a.minWager - b.minWager);
  const currentIndex = tiers.findIndex(tier => tier.name === currentTierName);
  return currentIndex < tiers.length - 1 ? tiers[currentIndex + 1] : null;
};

// Claim periods in milliseconds
export const CLAIM_PERIODS = {
  WEEKLY: 7 * 24 * 60 * 60 * 1000,  // 7 days
  RELOAD: 24 * 60 * 60 * 1000,      // 24 hours
  BIRTHDAY: 365 * 24 * 60 * 60 * 1000 // 365 days
};

// Minimum amounts for claims
export const MINIMUM_AMOUNTS = {
  WEEKLY_CLAIM: 50,  // Lowered to be more accessible
  RELOAD_DEPOSIT: 20 // Lowered to be more accessible
}; 