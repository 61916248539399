import axios from 'axios';

// Create axios instance with default config
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
});

// Add request interceptor to include auth token
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token'); // Or however you store your auth token
  if (token) {
    config.headers['x-auth-token'] = token;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// State
const state = {
  games: [],
  loading: false,
  error: null,
  pagination: {
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
    limit: 50
  },
  filters: {
    provider: null,
    search: null
  }
};

// Getters
const getters = {
  allGames: state => state.games,
  isLoading: state => state.loading,
  hasError: state => !!state.error,
  errorMessage: state => state.error,
  pagination: state => state.pagination,
  filters: state => state.filters
};

// Actions
const actions = {
  async fetchGames({ commit, state }) {
    try {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);

      const { currentPage, limit } = state.pagination;
      const { provider, search } = state.filters;

      const response = await api.get('/api/eva-slots/games', {
        params: {
          page: currentPage,
          size: limit,
          gameProvider: provider,
          search
        }
      });

      commit('SET_GAMES', response.data.items);
      commit('SET_PAGINATION', {
        currentPage: response.data.currentPage,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages
      });
    } catch (error) {
      commit('SET_ERROR', error.response?.data?.message || 'Failed to fetch games');
    } finally {
      commit('SET_LOADING', false);
    }
  },

  setFilters({ commit, dispatch }, filters) {
    commit('SET_FILTERS', filters);
    commit('RESET_PAGINATION');
    dispatch('fetchGames');
  },

  setPage({ commit, dispatch }, page) {
    commit('SET_PAGE', page);
    dispatch('fetchGames');
  }
};

// Mutations
const mutations = {
  SET_GAMES(state, games) {
    state.games = games;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },

  SET_PAGINATION(state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },

  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  },

  SET_PAGE(state, page) {
    state.pagination.currentPage = page;
  },

  RESET_PAGINATION(state) {
    state.pagination.currentPage = 0;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}; 