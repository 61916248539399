<template>
  <div class="deposit">
    <div class="deposit-header">
      <div class="deposit-title">
        <div class="title-icon">
          <img src="@/assets/img/bets.png" alt="Deposit" />
        </div>
        <h2>Deposit</h2>
      </div>
    </div>

    <!-- Step-based content with transitions -->
    <transition name="fade" mode="out-in">
      <div class="deposit-content">
        <CurrencySelector 
          v-if="currentStep === 'select'"
          :currencies="localCurrencies"
          :selectedNetwork="selectedNetwork"
          @select-currency="handleCurrencySelect"
        />

        <AmountInput 
          v-if="currentStep === 'amount'"
          :selectedCurrency="selectedCurrency"
          :minAmount="minAmount"
          @generate-address="handleGenerateAddress"
          @go-back="currentStep = 'select'"
        />

        <PaymentDetails 
          v-if="currentStep === 'payment'"
          :paymentData="paymentData"
          :selectedCurrency="selectedCurrency"
          @go-back="currentStep = 'amount'"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapActions, mapState } from 'vuex';
import nowPaymentsService from '@/services/nowPayments';
import CurrencySelector from './deposit/CurrencySelector.vue';
import AmountInput from './deposit/AmountInput.vue';
import PaymentDetails from './deposit/PaymentDetails.vue';
import coinData from '@/assets/icons/coins/coin_data.json';

export default {
  name: 'CashierDeposit',
  
  components: {
    CurrencySelector,
    AmountInput,
    PaymentDetails
  },

  props: {
    currencies: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      currentStep: 'select',
      localCurrencies: [],
      selectedCurrency: null,
      selectedNetwork: 'all',
      minAmount: null,
      paymentData: null,
      isLocalDataLoaded: false
    }
  },

  computed: {
    ...mapState({
      socket: state => state.socket.socketCashier,
      user: state => state.user
    })
  },

  watch: {
    currencies: {
      immediate: true,
      handler(newCurrencies) {
        if (!this.isLocalDataLoaded && newCurrencies?.length) {
          this.localCurrencies = JSON.parse(JSON.stringify(newCurrencies));
        }
      }
    }
  },

  methods: {
    ...mapActions(['notificationShow', 'updateUserBalance', 'socketConnectCashier', 'socketDisconnectCashier']),

    handlePaymentCompleted() {
      // Reset the deposit form
      this.currentStep = 'select';
      this.selectedCurrency = null;
      this.paymentData = null;
    },

    handleAuthError() {
      this.notificationShow({
        type: 'error',
        message: 'Session expired. Please log in again.'
      });
      // Handle auth error appropriately (e.g., redirect to login)
      this.$router.push('/login');
    },

    async handleCurrencySelect(currency) {
      if (!currency || !currency.code) {
        console.error('Invalid currency selected:', currency);
        this.notificationShow({
          type: 'error',
          message: 'Invalid currency selected'
        });
        return;
      }

      this.selectedCurrency = currency;
      
      try {
        console.log('Selected currency:', currency);
        // Get minimum amount for selected currency
        const minAmountResponse = await nowPaymentsService.getMinimumPaymentAmount(
          currency.code.toLowerCase()  // Ensure lowercase
        );
        
        console.log('Minimum amount response:', minAmountResponse);
        this.minAmount = minAmountResponse.min_amount;
        
        this.currentStep = 'amount';
      } catch (error) {
        console.error('Error getting minimum amount:', error);
        this.notificationShow({
          type: 'error',
          message: error.response?.data?.details || 'Failed to get minimum amount'
        });
      }
    },

    async handleGenerateAddress(paymentData) {
      try {
        console.log('Creating payment with data:', paymentData);
        
        if (!paymentData.price_amount || isNaN(paymentData.price_amount)) {
          throw new Error('Invalid amount');
        }

        const payment = await nowPaymentsService.createInvoice(paymentData);
        
        this.paymentData = payment;
        this.currentStep = 'payment';

        // Listen for socket events for this payment
        if (this.socket?.connected) {
          console.log('Socket connected, listening for payment_completed event');
          this.socket.on('payment_completed', (data) => {
            console.log('Payment completed event received:', data);
            if (data.payment_id === payment.payment_id) {
              // Update user balance from socket data
              this.updateUserBalance(data.user);
              // Reset deposit form
              this.handlePaymentCompleted();
            }
          });
        } else {
          console.warn('Socket not connected, payment updates will not be real-time');
        }
      } catch (error) {
        console.error('Error generating payment:', error);
        this.notificationShow({
          type: 'error',
          message: error.response?.data?.details || 'Failed to generate payment address'
        });
      }
    },

    transformCoinData(coinData) {
      return coinData.currencies.map(coin => {
        try {
          return {
            ...coin,
            logo_url: require(`@/assets/icons/coins/${coin.code.toLowerCase()}_logo.svg`)
          };
        } catch (error) {
          console.warn(`Missing icon for ${coin.code}`);
          return {
            ...coin,
            logo_url: require('@/assets/icons/coins/btc_logo.svg') // Using BTC as fallback
          };
        }
      });
    },

    async loadLocalCurrencies() {
      try {
        this.localCurrencies = this.transformCoinData(coinData);
        this.isLocalDataLoaded = true;
      } catch (error) {
        console.error('Error loading currencies:', error);
        this.notificationShow({
          type: 'error',
          message: 'Failed to load currencies'
        });
      }
    }
  },

  created() {
    // Connect to cashier socket when component is created
    this.$store.dispatch('socketConnectCashier');
    this.loadLocalCurrencies();
  },

  beforeDestroy() {
    // Disconnect from cashier socket when component is destroyed
    this.$store.dispatch('socketDisconnectCashier');
  }
}
</script>

<style scoped>
.deposit {
  width: fit-content;
  min-width: min(100%, 1050px);
  margin: 0 auto;
  padding: 16px;
  font-family: Montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.deposit-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.deposit-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.title-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.deposit-title h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.2px;
}

.deposit-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .deposit {
    min-width: min(100%, 480px);
    padding: 12px;
    gap: 12px;
  }

  .deposit-header {
    padding: 12px;
  }

  .title-icon {
    width: 28px;
    height: 28px;
  }

  .deposit-title h2 {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .deposit {
    min-width: 100%;
    padding: 8px;
    gap: 8px;
  }

  .deposit-header {
    padding: 10px;
  }

  .title-icon {
    width: 24px;
    height: 24px;
  }

  .deposit-title h2 {
    font-size: 14px;
  }
}
</style>

