<template>
  <div class="rain-tip-dropdown" v-bind:class="{ 'dropdown-open': rainDropdownTip === true }">
    <button v-on:click="rainSetDropdownTip(!rainDropdownTip)" class="button-toggle">
      <div class="button-inner">
        <!--<IconRain/>-->
        <div class="inner-amount">
          <img src="@/assets/img/icons/coin.svg" alt="icon"/>
          <div class="amount-value">
            <span>{{
                rainFormatValue(generalRain.site.amount).split('.')[0]
              }}
            </span>
            <span>
              .{{ rainFormatValue(generalRain.site.amount).split('.')[1] }}
            </span>
          </div>
        </div>
        <IconDropdown/>
      </div>
    </button>
    <div class="dropdown-window">
      <div class="window-inner">
        <div class="inner-timer">
          <IconTimer/>
          <div class="timer-value">
            <span>{{ rainTimer.split(':')[0] }}</span>
            m
            <span>{{ rainTimer.split(':')[1] }}</span>
            s
          </div>
        </div>
        <div class="inner-info">
          Half of the rain pot is split evenly amongst players while the other half is split amongst levels.
        </div>
        <div class="inner-input">
          <input v-model="rainAmount" v-on:input="rainValidateInput" type="text" placeholder="TIP AMOUNT"/>
          <button v-on:click="rainTipButton" class="button-tip">
            <div class="button-inner">TIP</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import IconRain from '@/components/icons/IconRain';
import IconDropdown from '@/components/icons/IconDropdown';
import IconTimer from '@/components/icons/IconTimer';

export default {
  name: 'RainTipDropdown',
  components: {
    IconRain,
    IconDropdown,
    IconTimer
  },
  data() {
    return {
      rainTimerInterval: null,
      rainTimer: '00:00',
      rainAmount: ''
    };
  },
  methods: {
    ...mapActions(['notificationShow', 'rainSetDropdownTip', 'generalSendRainTipSocket']),
    rainFormatValue(value) {
      return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    rainValidateInput() {
      this.rainAmount = this.rainAmount.replace(/[^\d.]/g, '');
    },
    rainStartTimer() {
      const endingTime = new Date(this.generalRain.site.updatedAt).getTime() + (1000 * 60 * (this.generalRain.site.state === 'running' ? 2 : 30));
      let timeLeft = Math.floor((endingTime - (new Date().getTime() + this.generalTimeDiff)) / 1000);
      timeLeft = this.generalRain.site.state === 'pending' ? 60 * 2 : timeLeft <= 0 ? 0 : timeLeft;

      let timeMinutes = Math.floor((timeLeft % (60 * 60)) / 60);
      timeMinutes = timeMinutes < 10 ? '0' + timeMinutes : timeMinutes;
      let timeSeconds = Math.floor(timeLeft % 60);
      timeSeconds = timeSeconds < 10 ? '0' + timeSeconds : timeSeconds;

      this.rainTimer = timeMinutes.toString() + ':' + timeSeconds.toString();
    },
    rainTipButton() {
      if (this.socketSendLoading !== null) {
        return;
      }

      const amount = Math.floor(this.rainAmount * 1000);

      if (amount === undefined || isNaN(amount) === true || amount <= 0) {
        this.notificationShow({type: 'error', message: 'Your entered rain tip amount is invalid.'});
        return;
      }

      const data = {amount: amount};
      this.generalSendRainTipSocket(data);

      this.rainAmount = null;
      this.rainSetDropdownTip(false);
    }
  },
  computed: {
    ...mapGetters(['socketSendLoading', 'authUser', 'generalTimeDiff', 'rainDropdownTip', 'generalRain'])
  },
  created() {
    let self = this;
    document.addEventListener('click', function (event) {
      if (!self.$el.contains(event.target) && event.path !== undefined && event.path.some((element) => element._prevClass === 'button-tip') === false && self.rainDropdownTip === true) {
        self.rainSetDropdownTip(false);
      }
    });

    this.rainTimerInterval = setInterval(() => {
      this.rainStartTimer();
    }, 500);
  }
};
</script>

<style scoped>
.rain-tip-dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.rain-tip-dropdown button.button-toggle {
  position: relative;
  padding: 1px;
  z-index: 2;
}

.rain-tip-dropdown button.button-toggle .button-inner {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.3rem 0.5rem;
  font-size: 14px;
  font-weight: 800;
  background-color: rgba(var(--highlight-blue-rgb), 0.1);
  border-radius: 0.5rem;
  border: 2px solid rgba(0, 117, 255, 0.65);
  transition: all .3s;
}


/* .rain-tip-dropdown button.button-toggle .button-inner:active {
    transition-duration: .2s;
    scale: .9;
} */

.rain-tip-dropdown button.button-toggle .button-inner svg {
  margin-left: 0.3rem;
  height: 0.3rem;
  fill: white;
  transition: all 0.3s ease;
}

.rain-tip-dropdown button.button-toggle .button-inner svg:last-of-type {
  transition: all 0.3s ease;
}

.rain-tip-dropdown.dropdown-open button.button-toggle .button-inner svg:last-of-type {
  transform: rotate(180deg);
}

.rain-tip-dropdown button.button-toggle .inner-amount {
  display: flex;
  align-items: center;
  margin-left: 0.1rem;
}

.rain-tip-dropdown button.button-toggle .inner-amount img {
  width: 1rem;
}

.rain-tip-dropdown button.button-toggle .amount-value {
  display: flex;
}

.rain-tip-dropdown button.button-toggle .amount-value span {
  font-family: Montserrat, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
}

.rain-tip-dropdown button.button-toggle .amount-value span:nth-child(2) {
  color: rgba(255, 255, 255, 0.5);
}

.rain-tip-dropdown .dropdown-window {
  height: 0;
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  transition: height 0.2s ease;
  overflow: hidden;
  z-index: 1;
  

}

/* Add media query for mobile screens */
@media screen and (max-width: 768px) {
  .rain-tip-dropdown .dropdown-window {
    position: fixed; /* Change to fixed positioning on mobile */
    left: 50%;
    top: 130px; /* Keep the same top distance as desktop */
    transform: translateX(-50%); /* Only center horizontally */
    padding: 0;
    z-index: 100;
  }

  .rain-tip-dropdown .window-inner {
    width: 90vw;
    max-width: 280px;
    
  }
}

.rain-tip-dropdown.dropdown-open .dropdown-window {
  height: 172px;
  /* padding: 0 15px 15px 15px; */
}

.rain-tip-dropdown .window-inner {
  min-width: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 7px 15px 7px;
  border-radius: 15px;
  background: var(--background-secondary);
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
  border: 1px solid #79797930;
}

.rain-tip-dropdown .inner-timer {
  display: flex;
  align-items: center;
}

.rain-tip-dropdown .inner-timer svg {
  margin-right: 8px;
  fill: #ffffff;
}

.rain-tip-dropdown .timer-value {
  font-size: 10px;
  font-weight: 600;
  color: #d0cdbb;
}

.rain-tip-dropdown .timer-value span {
  font-size: 12px;
  font-weight: 800;
  color: #ffffff;
}

.rain-tip-dropdown .inner-info {
  width: 80%;
  margin-top: 6px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  color: #d0cdbb;
}

.rain-tip-dropdown .inner-input {
  width: 200px;
  height: 40px;
  position: relative;
  margin-top: 15px;
  padding: 1px;
}

.rain-tip-dropdown .inner-input input {
  width: 100%;
  height: 100%;
  padding: 0 80px 0 15px;
  font-size: 0.75rem;
  font-weight: 600;
  color: #ffffff;
  border-radius: 0.5rem;
  background: var(--background-primary);
}

.rain-tip-dropdown .inner-input input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.rain-tip-dropdown button.button-tip {
  width: 50px;
  height: 22px;
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translate(0, -50%);
  transition: all .2s;
}

.rain-tip-dropdown button.button-tip:hover {
  transition-duration: .2s;
  opacity: .8;
}

.rain-tip-dropdown button.button-tip:active {
  transition-duration: .2s;
  scale: .9;
}

.rain-tip-dropdown button.button-tip .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 800;
  color: #ffffff;
  border-radius: 0.5rem;
  background: var(--highlight-blue);
}
</style>
