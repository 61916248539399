<template>
    <div class="modal-reset">
        <div class="reset-title">
            <span>RESET PASSWORD</span>
        </div>
        <div class="reset-element">
            <div class="element-title">NEW PASSWORD</div>
            <div class="element-content">
                <input v-model="modalPassword" type="password" placeholder="ENTER PASSWORD..." />
            </div>
        </div>
        <div class="reset-element">
            <div class="element-title">REPEAT PASSWORD</div>
            <div class="element-content">
                <input v-model="modalPasswordConfirm" type="password" placeholder="ENTER PASSWORD..." />
            </div>
        </div>
        <button v-on:click="modalResetButton()" class="button-reset">
            <div class="button-inner">SET NEW PASSWORD</div>
        </button>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'ModalReset',
        data() {
            return {
                modalPassword: null,
                modalPasswordConfirm: null
            }
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'modalsSetShow',
                'modalsSetData'
            ]),
            modalResetButton() {
                if(this.modalPassword === null || this.modalPassword.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered password is invalid.' });
                    return;
                }

                if(this.modalPassword !== this.modalPasswordConfirm) {
                    this.notificationShow({ type: 'error', message: 'Your entered passwords does not match.' });
                    return;
                }

                this.modalsSetData({ typeCaptcha: 'credentialsReset', data: { ...this.modalsData, password: this.modalPassword } });
                this.modalsSetShow(null);

                setTimeout(() => { this.modalsSetShow('Captcha'); }, 200);
            }
        },
        computed: {
            ...mapGetters([ 
                'modalsData'
            ])

        }
    }
</script>

<style scoped>
    .modal-reset {
        width: 773px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 35px 80px 48px 80px;
        border-radius: 15px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 
            0px 4px 4px 0px rgba(0, 0, 0, 0.25),
            0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
            0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
    }

    .modal-reset .reset-title {
        text-align: center;
        font-family: Montserrat, sans-serif;
        font-size: 32px;
        font-weight: 800;
        color: rgba(255, 255, 255, 0.8);
    }

    .modal-reset .reset-element {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 35px;
    }

    .modal-reset .element-title {
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.5);
    }

    .modal-reset .element-content {
        width: 100%;
        height: 56px;
        position: relative;
        margin-top: 12px;
    }

    .modal-reset .element-content input {
        width: 100%;
        height: 100%;
        padding: 0 20px;
        border-radius: 10px;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
        background: rgba(255, 255, 255, 0.08);
        border: 1px solid transparent;
        transition: all 0.3s ease;
    }

    .modal-reset .element-content input::placeholder {
        color: rgba(255, 255, 255, 0.3);
    }

    .modal-reset .element-content input:focus {
        background: rgba(44, 94, 255, 0.1);
        border: 1px solid rgba(44, 94, 255, 0.3);
        color: #FFFFFF;
    }

    .modal-reset button.button-reset {
        width: 300px;
        height: 54px;
        margin-top: 35px;
        border-radius: 10px;
        background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
        box-shadow: 0 0 15px rgba(44, 94, 255, 0.3);
        transition: all 0.3s ease;
    }

    .modal-reset button.button-reset:hover {
        transform: translateY(-1px);
        filter: brightness(1.1);
    }

    .modal-reset button.button-reset .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.8);
        transition: color 0.3s ease;
    }

    .modal-reset button.button-reset:hover .button-inner {
        color: #FFFFFF;
    }

    @media only screen and (max-width: 793px) {
        .modal-reset {
            width: calc(100vw - 20px);
            padding: 35px 20px 48px 20px;
        }
    }

    @media only screen and (max-width: 500px) {
        .modal-reset {
            padding: 55px 15px 48px 15px;
        }

        .modal-reset button.button-reset {
            width: 100%;
        }

        .modal-reset .reset-title {
            font-size: 28px;
        }
    }
</style>