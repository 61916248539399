<template>
  <div class="game-slider originals-slider">
    <div class="games-container">
      <div class="game-row">
        <!-- Original Games -->
        <div
          v-for="game in originalGames"
          :key="game.id"
          class="game-card"
          @click="navigateToGame(game)"
        >
          <img :src="game.imageUrl" :alt="game.name" class="game-image" loading="lazy" />
          <div class="game-overlay"></div>
          <div class="game-info">
            <h3 class="game-title">{{ game.name }}</h3>
            <p class="game-provider">BazedBet</p>
          </div>
        </div>

        <!-- Provider Games (Predefined, Preferred, and Others) -->
        <div
          v-for="game in providerGames"
          :key="'provider-' + game.gameId"
          class="game-card"
          @click="handleGameSelect(game)"
        >
          <div class="game">
            <img
              :src="game.imageUrl"
              :alt="game.translationKey || game.name"
              class="game-image"
              loading="lazy"
            />
            <div class="game-overlay"></div>
            <div class="game-info">
              <div class="game-title">{{ game.translationKey || game.name }}</div>
              <div class="game-provider">{{ game.gameProvider }}</div>
            </div>
          </div>
        </div>

        <!-- See More Card -->
        <div 
          class="game-card see-more-card"
          @click="navigateToFullView"
        >
          <div class="see-more-content">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="34" viewBox="0 0 28 34" fill="none">
              <g filter="url(#filter0_d_1625_1363)">
                <path d="M17.4606 4.26739L4.25642 17.7754C3.70804 18.3361 4.09743 19.2915 4.87419 19.2935L10.7662 19.31C11.355 19.3113 11.7747 19.8932 11.5989 20.4648L9.03351 28.8401C8.76202 29.7256 9.83721 30.3904 10.4846 29.7372L23.7394 16.3752C24.2904 15.8194 23.9105 14.8626 23.1344 14.853L17.0638 14.7749C16.475 14.7673 16.0614 14.182 16.2419 13.6118L18.9138 5.16797C19.1954 4.27767 18.1087 3.60395 17.4606 4.26739Z" fill="url(#paint0_linear_1625_1363)"/>
              </g>
              <defs>
                <filter id="filter0_d_1625_1363" x="0" y="0" width="28" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.321667 0 0 0 0 0.804167 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1625_1363"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1625_1363" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_1625_1363" x1="14.0004" y1="4.0001" x2="14.0004" y2="30" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#4DA9F9"/>
                  <stop offset="1" stop-color="#2863F6"/>
                </linearGradient>
              </defs>
            </svg>
            <span class="see-more-text">See More</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading State -->
    <div v-if="loading" class="loading-state">
      <div class="spinner"></div>
      <p>Loading games...</p>
    </div>

    <!-- Error State -->
    <div v-if="error" class="error-state">
      <font-awesome-icon icon="exclamation-circle" class="error-icon" />
      <p class="error-message">{{ error }}</p>
      <button @click="fetchGames" class="retry-button">
        <font-awesome-icon icon="redo" />
        Retry
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { PREDEFINED_SLOTS_GAMES, PREFERRED_SLOTS_PROVIDERS } from '@/constants/eva'
import EVASlotsGameService from '@/services/EVASlotsGameService'

export default {
  name: 'OriginalGamesSlider',
  data() {
    return {
      error: null,
      predefinedGames: [],
      preferredGames: [],
      originalGames: [
        { id: 1, name: 'Mines', link: '/mines', imageUrl: require('@/assets/img/home/originalgames/Mines.png') },
        { id: 2, name: 'Crash', link: '/crash', imageUrl: require('@/assets/img/home/originalgames/Crash.png') },
        { id: 4, name: 'Blackjack', link: '/blackjack', imageUrl: require('@/assets/img/home/originalgames/Blackjack.png') },
        { id: 6, name: 'Towers', link: '/towers', imageUrl: require('@/assets/img/home/originalgames/Tower.png') },
        { id: 9, name: 'Duels', link: '/duels', imageUrl: require('@/assets/img/home/originalgames/Dice_Duels.png') },
        { id: 10, name: 'Coinflip', link: '/coinflip', imageUrl: require('@/assets/img/home/originalgames/Coin_Flip.png') },
      ]
    }
  },

  computed: {
    ...mapState({
      authUser: state => state.auth.authUser,
      authToken: state => state.auth.authToken,
      filters: state => state.eva.filters.slots,
      games: state => state.eva.allGames
    }),
    
    ...mapGetters('eva', ['isLoading', 'hasError', 'errorMessage']),

    loading() {
      return this.isLoading('slots')
    },

    error() {
      return this.errorMessage('slots')
    },

    isAuthenticated() {
      return !!(this.authUser?.user?._id && this.authToken)
    },

    providerGames() {
      // Start with predefined games
      const games = [...this.predefinedGames];
      
      // Add preferred provider games
      const remainingSlots = 69 - this.originalGames.length - games.length;
      if (remainingSlots > 0) {
        const preferredGamesFiltered = this.preferredGames
          .filter(game => !games.some(g => g.gameId === game.gameId))
          .filter(game => game.imageUrl && game.imageUrl !== '')
          .slice(0, remainingSlots);
        
        games.push(...preferredGamesFiltered);
      }

      // If we still have slots, add other games
      const lastRemainingSlots = 69 - this.originalGames.length - games.length;
      if (lastRemainingSlots > 0) {
        const otherGames = this.games
          .filter(game => {
            // Filter out games that are already included
            return !games.some(g => g.gameId === game.gameId);
          })
          .filter(game => game.imageUrl && game.imageUrl !== '')
          .slice(0, lastRemainingSlots);

        games.push(...otherGames);
      }

      return games;
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },

    lobbyUrl() {
      return `${window.location.origin}/games`
    }
  },

  async mounted() {
    try {
      // First load predefined games
      this.predefinedGames = await EVASlotsGameService.getPredefinedGames(PREDEFINED_SLOTS_GAMES);
      console.log('[OriginalGamesSlider] Loaded predefined games:', this.predefinedGames.length);

      // Then load games from preferred providers
      const preferredGamesPromises = PREFERRED_SLOTS_PROVIDERS.map(provider => 
        EVASlotsGameService.getGames({
          gameProvider: provider,
          productType: 'slots',
          isActive: true,
          channel: 'desktop',
          size: Math.ceil((69 - this.originalGames.length - this.predefinedGames.length) / PREFERRED_SLOTS_PROVIDERS.length),
          page: 0
        })
      );

      const preferredResults = await Promise.all(
        preferredGamesPromises.map(p => p.catch(error => {
          console.error('[OriginalGamesSlider] Error fetching preferred provider games:', error);
          return { items: [] };
        }))
      );

      this.preferredGames = preferredResults.flatMap(result => result.items || []);
      console.log('[OriginalGamesSlider] Loaded preferred provider games:', this.preferredGames.length);

      // Only fetch additional games if we still need more
      const remainingSlots = 69 - this.originalGames.length - this.predefinedGames.length - this.preferredGames.length;
      if (remainingSlots > 0) {
        // Set filters and load remaining games
        await this.setFilters({
          filters: {
            productType: 'slots',
            isActive: true,
            channel: 'desktop',
            page: 0,
            size: remainingSlots
          },
          target: 'slots'
        });

        await this.fetchGames({ target: 'slots' });
      }
    } catch (error) {
      console.error('[OriginalGamesSlider] Error initializing:', error);
      this.error = error.message || 'Failed to load games';
    }
  },

  methods: {
    ...mapActions('eva', ['launchGame', 'setFilters', 'fetchGames', 'setCurrentGame']),

    navigateToGame(game) {
      this.$router.push(game.link);
    },

    async handleGameSelect(game) {
      try {
        console.log('[OriginalGamesSlider] Game selected:', game)
        
        // Check authentication first
        if (!this.isAuthenticated) {
          // Store the game info and show login modal
          this.$store.commit('eva/setSelectedGame', game);
          this.$store.dispatch('modalsSetShow', 'Login');
          return;
        }
        
        // Then set the current game in the store and navigate
        await this.setCurrentGame(game)
        await this.$router.push('/games')

      } catch (error) {
        console.error('[OriginalGamesSlider] Error selecting game:', error)
        this.error = error.message || 'Failed to select game'
        this.$store.dispatch('notificationShow', {
          type: 'error',
          message: this.error
        })
      }
    },

    navigateToFullView() {
      this.$router.push({
        name: 'EVASlots',
        query: {
          productType: 'slots'
        }
      })
    },

    scrollLeft(direction) {
      const container = this.$el.querySelector('.game-row');
      if (!container) return;

      // Get the computed style to account for responsive sizes
      const computedStyle = window.getComputedStyle(container);
      const gap = parseInt(computedStyle.gap) || 16;

      // Calculate card width based on screen size
      let cardWidth = 180;
      const screenWidth = window.innerWidth;
      
      if (screenWidth <= 480) {
        cardWidth = 100;
      } else if (screenWidth <= 768) {
        cardWidth = 120;
      } else if (screenWidth <= 1024) {
        cardWidth = 140;
      } else if (screenWidth <= 1440) {
        cardWidth = 160;
      }

      const cardWithGap = cardWidth + gap;

      // Calculate how many cards are visible based on container width
      const containerWidth = container.clientWidth;
      const visibleCards = Math.floor(containerWidth / cardWithGap);
      
      // Calculate scroll amount based on visible cards (minimum 1, maximum 5)
      const cardsToScroll = Math.max(1, Math.min(5, visibleCards));
      const scrollAmount = cardsToScroll * cardWithGap;

      const currentScroll = container.scrollLeft;
      const targetScroll = direction === 'left' 
        ? currentScroll - scrollAmount 
        : currentScroll + scrollAmount;

      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
.game-slider {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 1rem 0;
}

.game-slider::-webkit-scrollbar {
  display: none;
}

.games-container {
  padding: 0.5rem;
  width: 100%;
  overflow: hidden;
}

.game-row {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 180px;
  gap: 1rem;
  padding: 0.5rem;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.game-card {
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.75rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  aspect-ratio: 16/9;
}

.game-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.game-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.game-card:hover .game-image {
  transform: scale(1.05);
}

.game-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7) 50%, transparent);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.game-card:hover .game-overlay {
  opacity: 1;
}

.game-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  transform: translateY(100%);
  transition: transform 0.2s ease;
}

.game-card:hover .game-info {
  transform: translateY(0);
}

.game-title {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.game-provider {
  margin: 0.25rem 0 0;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

/* Updated See More Card Styles */
.see-more-card {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.15),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.see-more-card:hover {
  transform: translateY(-4px);
  box-shadow: 
    0px 8px 16px rgba(0, 0, 0, 0.2),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset;
}

.see-more-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1rem;
}

.see-more-content svg {
  width: 28px;
  height: 34px;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
  transition: transform 0.3s ease;
}

.see-more-card:hover .see-more-content svg {
  transform: scale(1.1);
}

.see-more-text {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.2px;
}

/* Loading and Error States */
.loading-state,
.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 3rem;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.spinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.25rem solid rgba(52, 157, 255, 0.1);
  border-left-color: #349DFF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-icon {
  font-size: 2rem;
  color: #FF4D4D;
}

.error-message {
  color: rgba(255, 255, 255, 0.8);
}

.retry-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.retry-button:hover {
  background: rgba(255, 255, 255, 0.15);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 1440px) {
  .game-row {
    grid-auto-columns: 160px;
  }
}

@media (max-width: 1024px) {
  .game-row {
    grid-auto-columns: 140px;
    gap: 0.75rem;
  }
}

@media (max-width: 768px) {
  .game-row {
    grid-auto-columns: 120px;
    gap: 0.5rem;
  }
  
  .game-title {
    font-size: 0.875rem;
  }
  
  .game-provider {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .game-row {
    grid-auto-columns: 100px;
    gap: 0.5rem;
  }
  
  .games-container {
    padding: 0.25rem;
  }
  
  .game-title {
    font-size: 0.75rem;
  }
  
  .game-provider {
    font-size: 0.625rem;
  }
}
</style>