<template>
  <div class="battles">
    <div class="battles-wrapper">
      <div class="battles-header">
        <BattlesHeaderOverview
          v-if="battlesGetRouteName === 'BattlesOverview'"
        />
        <BattlesHeaderCreate
          v-else-if="battlesGetRouteName === 'BattlesCreate'"
        />
        <BattlesHeaderGame v-else-if="battlesGetRouteName === 'BattlesGame'" />
      </div>
      <div class="battles-content">
        <transition name="slide-fade" mode="out-in">
          <router-view v-bind:key="$route.fullPath" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BattlesHeaderOverview from "@/components/battles/BattlesHeaderOverview";
import BattlesHeaderCreate from "@/components/battles/BattlesHeaderCreate";
import BattlesHeaderGame from "@/components/battles/BattlesHeaderGame";

export default {
  name: "Battles",
  metaInfo: {
    title: "Battles - Bazedbet",
  },
  components: {
    BattlesHeaderOverview,
    BattlesHeaderCreate,
    BattlesHeaderGame,
  },
  methods: {
    ...mapActions(["socketConnectBattles", "socketDisconnectBattles"]),
  },
  computed: {
    battlesGetRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.socketConnectBattles();
  },
  beforeRouteLeave(to, from, next) {
    this.socketDisconnectBattles();
    next();
  },
};
</script>

<style scoped>
.battles {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 12px;
}

.battles .battles-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.5625rem;
}

.battles .battles-wrapper .battles-header {
  width: 95%;
}

.battles .battles-wrapper .battles-content {
  width: 95%;
  margin-top: 20px;
  background: radial-gradient(
      166.64% 109.43% at 138.57% 157.99%,
      rgba(35, 219, 227, 0.45) 0%,
      rgba(35, 219, 227, 0) 100%
    ),
    linear-gradient(180deg, rgba(13, 21, 37, 0) 0%, rgba(13, 21, 37, 0.74) 100%),
    #878787;
  box-shadow:
    inset 0 2px 4px #B7DCFF,
    inset 0 -4px 6px rgb(0 0 0 / 25%);
  padding: 24px;
  border-radius: 1.5rem;
}

@media only screen and (max-width: 1175px) {
  .battles .battles-wrapper .battles-header {
    width: 100%;
  }

  .battles .battles-wrapper .battles-content {
    width: 100%;
  }
}
</style>
