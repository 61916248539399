var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
        _vm.generalSettings === null ||
        (_vm.authToken !== null && _vm.authUser.user === null)
      )?_c('AppLoader',{key:"loading"}):(
        _vm.generalSettings.general.maintenance.enabled === false ||
        (_vm.authUser.user !== null && _vm.authUser.user.rank === 'admin')
      )?_c('div',{key:"page",staticClass:"app-page"},[_c('Chat'),_c('Navbar'),_c('div',{staticClass:"content-container",class:{
          'sidebar-collapsed': _vm.isSidebarCollapsed || _vm.isSmallScreen,
          'chat-open': _vm.generalSidebarMobile === 'Chat',
        }},[_c('div',{staticClass:"side-menu",class:{ collapsed: _vm.isSidebarCollapsed || _vm.isSmallScreen }},[_c('Menu',{on:{"sidebar-toggle":_vm.handleSidebarToggle}})],1),_c('main',{ref:"mainContainer",class:{ expanded: _vm.isSidebarCollapsed || _vm.isSmallScreen }},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('router-view')],1),(_vm.showFooter)?_c('Footer'):_vm._e()],1)]),_c('Modals'),_c('Notifications'),(_vm.isSmallScreen)?_c('MobileMenu'):_vm._e()],1):_c('AppMaintenance',{key:"maintenance"})],1),_c('CookieConsent')],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }