<template>
  <div class="rakeback-guide">
    <div class="guide-header">
      <h2>HOW RAKEBACK WORKS</h2>
    </div>

    <div class="guide-content">
      <div class="steps-container">
        <div class="step-card">
          <div class="step-content">
            <div class="step-icon">
              <span>🎮</span>
              <div class="step-number">1</div>
            </div>
            <h3>PLAY & EARN</h3>
            <p>Play your favorite slots and sports games to earn rakeback. The more you wager, the higher your tier and rakeback rate!</p>
          </div>
        </div>

        <div class="step-card">
          <div class="step-content">
            <div class="step-icon">
              <span>⏰</span>
              <div class="step-number">2</div>
            </div>
            <h3>COLLECT REGULARLY</h3>
            <p>Your rakeback accumulates hourly, daily, weekly, and monthly. Each period has its own pool, so claim them all!</p>
          </div>
        </div>

        <div class="step-card">
          <div class="step-content">
            <div class="step-icon">
              <span>🎁</span>
              <div class="step-number">3</div>
            </div>
            <h3>UNLOCK FEATURES</h3>
            <p>Access exclusive events, special boosts, weekly rewards, and birthday gifts. Higher tiers unlock VIP benefits and personalized offers!</p>
          </div>
        </div>
      </div>

      <div class="quick-facts">
        <div class="fact">
          <span class="fact-icon">📊</span>
          <div class="fact-content">
            <h4>DISTRIBUTION</h4>
            <p>40% Hourly, 30% Daily, 20% Weekly, 10% Monthly</p>
          </div>
        </div>
        <div class="fact">
          <span class="fact-icon">🎯</span>
          <div class="fact-content">
            <h4>BENEFITS</h4>
            <p>Weekly bonuses, reload boosts, and birthday rewards</p>
          </div>
        </div>
        <div class="fact">
          <span class="fact-icon">⚡</span>
          <div class="fact-content">
            <h4>VIP PERKS</h4>
            <p>Priority support, exclusive events, and unique boosts</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RakebackGuide'
}
</script>

<style scoped>
.rakeback-guide {
  background: #1A1F37;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 24px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
}

.guide-header {
  background: #1E2443;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.guide-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 800;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.guide-content {
  padding: 24px;
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.step-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 24px;
  transition: all 0.3s ease;
}

.step-card:hover {
  background: rgba(44, 94, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(44, 94, 255, 0.1);
}

.step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.step-icon {
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  background: rgba(44, 94, 255, 0.1);
  border-radius: 12px;
  transition: all 0.3s ease;
}

.step-card:hover .step-icon {
  background: rgba(44, 94, 255, 0.2);
  transform: scale(1.05);
}

.step-icon span {
  font-size: 24px;
}

.step-number {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  background: #2C5EFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.step-content h3 {
  margin: 0 0 12px;
  font-size: 16px;
  font-weight: 700;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.step-content p {
  margin: 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.quick-facts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.fact {
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  transition: all 0.3s ease;
}

.fact:hover {
  background: rgba(44, 94, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(44, 94, 255, 0.1);
}

.fact-icon {
  font-size: 24px;
  padding: 12px;
  background: rgba(44, 94, 255, 0.1);
  border-radius: 12px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.fact:hover .fact-icon {
  background: rgba(44, 94, 255, 0.2);
  transform: scale(1.05);
}

.fact-content h4 {
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.fact-content p {
  margin: 0;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

@media (max-width: 768px) {
  .guide-content {
    padding: 16px;
  }

  .steps-container,
  .quick-facts {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .step-card,
  .fact {
    padding: 16px;
  }

  .step-content h3 {
    font-size: 14px;
  }

  .step-content p,
  .fact-content p {
    font-size: 12px;
  }

  .fact-icon {
    padding: 8px;
    font-size: 20px;
  }
}
</style> 