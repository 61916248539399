<template>
  <div class="bonus-container">
    <div class="bonus-hero">
      <h1>Unlock
        <span>Endless</span>
        Benefits
      </h1>
      <span>Join now and embark on an exhilarating journey of fortune and rewards!</span>
      <div class="perk-container">
        <div class="perk">
          <img src="@/assets/img/purple-gift.png"/>
          <div class="info">
            <span class="title">Level Up Bonus</span>
            <span>Reach new heights with our Level-Up Bonus</span>
          </div>
        </div>
        <div class="perk">
          <img src="@/assets/img/icons/coin.svg"/>
          <div class="info">
            <span class="title">Deposit Bonus</span>
            <span>Enjoy up to 360% bonus on deposits</span>
          </div>
        </div>
      </div>
      <button>JOIN NOW</button>
    </div>

    <div class="steps-container">
      <div class="step">
        <div class="number">
          1
        </div>
        <span>Sign up & make a deposit</span>
      </div>

      <div class="step">
        <div class="number">
          2
        </div>
        <span>Play your fav games</span>
      </div>

      <div class="step">
        <div class="number">
          3
        </div>
        <span>Increase your VIP levels</span>
      </div>
    </div>

    <div class="claim-container">
      <div class="claim-item">
        <div class="image-container">
          <img src="@/assets/img/icons/coin.svg"/>
        </div>
        <span class="title">Discover Exceptiomal Deposit Bonus</span>
        <span class="description">We are unique in a way that you can see your inital deposit bonus right away and
          offers an 360% bonus for your first deposit!
        </span>
        <button>Claim Your Deposit Bonus</button>
      </div>

      <div class="claim-item">
        <div class="image-container">
          <img src="@/assets/img/ship.png"/>
        </div>
        <span class="title">Reach New Heights with Our Level Up Bonus</span>
        <span class="description">Expand your finances today and grow your balance like never before with amazing
          rewards.
        </span>
        <button>Claim Your Deposit Bonus</button>
      </div>

      <div class="claim-item">
        <div class="image-container">
          <img src="@/assets/img/money-bag.png"/>
        </div>
        <span class="title">Maximize the Potential of Rakeback</span>
        <span class="description">Earn with bonuses and easily unlock their potential!
        </span>
        <button>Claim Your Deposit Bonus</button>
      </div>
    </div>

    <div class="free-perks">Get a lot of
      <span>free</span>
      perks
    </div>

    <div class="events-container">
      <div class="event">
        <div class="title">
          <div class="green-circle"/>
          <span>Leaderboard Races</span>
        </div>
        <span class="description">Compete in our $25,000 weekly race. Climb the leaderboard and grab your share!</span>
        <span class="interval">Every Week</span>
      </div>

      <div class="event">
        <div class="title">
          <div class="green-circle"/>
          <span>Lucky Raffles</span>
        </div>
        <span class="description">Win your share of $10,000 each week. Enter and get lucky!</span>
        <span class="interval">Every Week</span>
      </div>
    </div>

    <div class="rakeback-calendar">
      <div class="left-content">
        <span class="title">Introducing Our Very Own
          <span class="blue">Rakeback</span>
          Calendar
        </span>
        <span class="description">Immerse yourself in the exhilarating world of rakeback, where the power is in your
          hands. Join and
          customize your reward.
        </span>
        <button>JOIN NOW</button>
      </div>
      <div class="right-content">
        <div class="item">
          <img src="@/assets/img/purple-gift.png"/>
          <div>
            <span>Rakeback Booster</span>
            <span>Earn more rakeback after claiming from calendar!</span>
          </div>
        </div>

        <div class="item">
          <img src="@/assets/img/briefcase.png"/>
          <div>
            <span>Rakeback at your pace</span>
            <span>Claim bonuses every 30 mins</span>
          </div>
        </div>

        <div class="item">
          <img src="@/assets/img/up-arrows.png"/>
          <div>
            <span>Rakeback every day</span>
            <span>Claim rakeback daily, weekly and monthly</span>
          </div>
        </div>
      </div>
    </div>

    <div class="more-benefits">
      Wait. We Have
      <span>More</span>
      Benefits For You!
    </div>

    <div class="benefits-container">
      <div class="benefit">
        <span class="title">Lottery</span>
        <span class="description">Experience provably fair gaming and let your lucky numbers do the magic. Don’t wait,
          jump in and try your luck!
        </span>
      </div>

      <div class="benefit">
        <span class="title">Stay Ready for Coin-Drops and Twitter Rewards</span>
        <span class="description">Engage and seize spontaneous rewards with frequent Coin-Drops stay alert, be quick,
          and earn free credits via our Twitter account.
        </span>
      </div>
    </div>

    <div class="join-container">
      <span>Join the Most Rewarding Gaming Experience</span>
      <button>JOIN NOW</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bonus'
};
</script>

<style scoped>
.bonus-container {
  padding: 2rem;
}

.bonus-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  border-radius: 1.5rem;
  background: var(--background-primary);
  padding: 5rem 0;
  text-align: center;
  gap: 1rem;
}

.bonus-hero h1 {
  font-family: Montserrat, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bonus-hero h1 span {
  font-size: 3rem;
  background: linear-gradient(263deg, #FFF -7.09%, var(--highlight-blue) 200%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bonus-hero span {
  color: rgba(255, 255, 255, 0.39);
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.perk-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.perk {
  background: var(--background-secondary);
  border-radius: 1rem;
  position: relative;
  padding: 2.5rem 2rem 2.5rem 10rem;
  width: 575px;
}

.perk:nth-child(2) {
  padding-left: 7rem;
}

.perk img {
  position: absolute;
  height: 10rem;
  top: -15px;
  left: 0;
}

.perk .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.perk .info .title {
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.perk .info span {
  color: rgba(255, 255, 255, 0.39);
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

.bonus-hero button {
  margin-top: 2rem;
  background: var(--highlight-blue);
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: white;
  padding: 1.25rem 5rem;
  border-radius: 0.5rem;
  transition: ease-in-out 0.1s;
}

.bonus-hero button:hover {
  transform: scale(1.05);
  background-color: white;
  color: var(--highlight-blue);
}

.steps-container {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.steps-container .step {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.steps-container .step .number {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  background: var(--background-primary);
  box-shadow: 0 4px 0 0 rgba(255, 255, 255, 0.07) inset;
  width: 4.5rem;
  height: 4.5rem;
  color: rgba(255, 255, 255, 0.39);
  font-family: Montserrat, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.claim-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

.claim-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 1rem;
  padding: 2rem;
  background: var(--background-primary);
  box-shadow: 0 4px 0 0 rgba(255, 255, 255, 0.07) inset;
}

.claim-item .image-container {
  width: 100%;
  height: 13rem;
  border-radius: 1rem;
  background: var(--background-secondary);
  box-shadow: 0 4px 0 0 rgba(255, 255, 255, 0.07) inset;
  position: relative;
}

.claim-item .image-container img {
  position: absolute;
  top: -3.5rem;
  left: 50%;
  transform: translateX(-50%);
  height: 20rem;
  user-select: none;
}

.claim-item:nth-child(2) .image-container img {
  position: absolute;
  top: -10%;
  height: 17rem;
}

.claim-item:nth-child(3) .image-container img {
  position: absolute;
  top: 1rem;
  height: 11rem;
}

.claim-item .title {
  font-family: Montserrat, sans-serif;
  color: white;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.claim-item .description {
  color: rgba(255, 255, 255, 0.39);
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;
}

.claim-item button {
  margin-top: 2rem;
  background: var(--highlight-blue);
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: white;
  padding: 1rem 0;
  width: 100%;
  border-radius: 0.5rem;
  transition: ease-in-out 0.1s;
}

.claim-item button:hover {
  background-color: white;
  color: var(--highlight-blue);
}

.free-perks {
  margin-top: 3rem;
  margin-left: 2rem;
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.free-perks span {
  color: var(--highlight-blue);
}

.events-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

.event {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem;
  border-radius: 1rem;
  background: var(--background-primary);
  box-shadow: 0 4px 0 0 rgba(255, 255, 255, 0.07) inset;
}

.event .title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.event .title .green-circle {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #5AE2F1;
}

.event .description {
  color: rgba(255, 255, 255, 0.39);
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
}

.event .interval {
  width: fit-content;
  color: rgba(255, 255, 255, 0.39);
  font-family: Montserrat, sans-serif;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  border-radius: 0.625rem;
  border: 2px solid rgba(255, 255, 255, 0.08);
  background: var(--background-secondary);
  padding: 0.75rem 3rem;
}

.rakeback-calendar {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 3rem;
  box-shadow: 0 4px 0 0 rgba(255, 255, 255, 0.07) inset;
  background: var(--background-primary);
  border-radius: 1rem;
  padding: 2.5rem;
}

.left-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.left-content .title {
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.left-content .blue {
  color: var(--highlight-blue);
}

.left-content .description {
  color: rgba(255, 255, 255, 0.39);
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.left-content button {
  background: var(--highlight-blue);
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: white;
  padding: 1rem 0;
  border-radius: 0.5rem;
  transition: ease-in-out 0.1s;
}

.left-content button:hover {
  background-color: white;
  color: var(--highlight-blue);
}

.right-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.item {
  display: flex;
  gap: 1rem;
  padding: 0.75rem;
  border-radius: 1rem;
  background: var(--background-secondary);
}

.item img {
  height: 3.5rem;
}

.item div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
}

.item div span {
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.item div span:nth-child(2) {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.39);
}

.more-benefits {
  margin-top: 3rem;
  margin-left: 2rem;
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.more-benefits span {
  color: var(--highlight-blue);
}

.benefits-container {
  margin-top: 3rem;
  display: flex;
  width: 100%;
  gap: 1rem;
}

.benefit {
  width: 100%;
  padding: 2.5rem;
  border-radius: 1rem;
  background: var(--background-primary);
  box-shadow: 0 4px 0 0 rgba(255, 255, 255, 0.07) inset;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.benefit .title {
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.benefit .description {
  color: rgba(255, 255, 255, 0.39);
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}

.join-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  background: var(--background-primary);
  border-radius: 1rem;
  padding: 4rem;
  box-shadow: 0 4px 0 0 rgba(255, 255, 255, 0.07) inset;
}

.join-container span {
  text-align: center;
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.join-container button {
  background: var(--highlight-blue);
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: white;
  padding: 1rem 14rem;
  border-radius: 0.5rem;
  transition: ease-in-out 0.1s;
}

@media (max-width: 1600px) {
  .perk-container {
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .claim-item .image-container img {
    top: -2rem;
    height: 17rem;
  }

  .claim-item:nth-child(2) .image-container img {
    top: 0;
    height: 14rem;
  }

  .claim-item:nth-child(3) .image-container img {
    top: 2.5rem;
    height: 8rem;
  }

  .claim-item .title {
    font-size: 1rem;
  }

  .claim-item .description {
    font-size: 0.8rem;
  }

  .claim-item button {
    font-size: 0.7rem;
  }
}

@media (max-width: 700px) {
  .perk {
    width: 90%;
  }

  .steps-container {
    display: none;
  }

  .claim-container {
    flex-direction: column;
    gap: 2rem;
  }

  .claim-item .title {
    font-size: 1.25rem;
  }

  .claim-item .description {
    font-size: 1rem;
  }

  .claim-item button {
    font-size: 1rem;
  }

  .free-perks {
    margin-left: 0;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  .events-container {
    flex-direction: column;
    gap: 1rem;
  }

  .rakeback-calendar .title {
    text-align: center;
  }

  .rakeback-calendar .description {
    text-align: center;
  }

  .rakeback-calendar button {
    margin-top: 1rem;
  }

  .rakeback-calendar .right-content {
    display: none;
  }

  .more-benefits {
    margin-left: 0;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    text-wrap: nowrap;
    flex-wrap: wrap;
  }

  .benefits-container {
    flex-direction: column;
  }

  .join-container button {
    width: 100%;
    padding: 1rem 0;
  }
}

</style>