<template>
  <div class="active-claims">
    <div class="section-header">
      <h3>Active Bonuses</h3>
    </div>

    <div v-if="loading" class="loading">
      <i class="fas fa-spinner fa-spin"></i>
    </div>

    <div v-else-if="claims.length === 0" class="no-claims">
      <p>You don't have any active bonuses</p>
      <router-link to="/promotions" class="btn btn-primary">
        View Available Promotions
      </router-link>
    </div>

    <div v-else class="claims-list">
      <div v-for="claim in claims" :key="claim._id" class="claim-card">
        <div class="claim-header">
          <h4>{{ claim.promotion.title }}</h4>
          <span :class="['status', claim.status]">{{ claim.status }}</span>
        </div>

        <div class="claim-details">
          <!-- Quick Info Section -->
          <div class="quick-info">
            <div class="info-card reward">
              <span class="info-icon">🎁</span>
              <div class="info-text">
                <span class="info-label">Reward</span>
                <span class="info-value">{{ claim.promotion.reward.amount }}%</span>
              </div>
            </div>
            <div class="info-card deposit">
              <span class="info-icon">💰</span>
              <div class="info-text">
                <span class="info-label">Min. Deposit</span>
                <span class="info-value">{{ formatUSD(claim.promotion.requirements.minDeposit * 1000) }}</span>
              </div>
            </div>
            <div class="info-card wagering">
              <span class="info-icon">🔄</span>
              <div class="info-text">
                <span class="info-label">Wagering</span>
                <span class="info-value">{{ claim.promotion.requirements.wagering }}x</span>
              </div>
            </div>
          </div>

          <!-- Status and Progress Section -->
          <div class="details-section">
            <div v-if="claim.status === 'claimed'" class="detail-row">
              <span class="label">Status:</span>
              <span class="value">Waiting for deposit to activate bonus</span>
            </div>

            <div v-if="claim.status === 'activated' || claim.status === 'active'" class="detail-row">
              <span class="label">Bonus Amount:</span>
              <span class="value">{{ formatAmount(claim.metadata.originalBonusBalance) }} Credits ({{ formatUSD(claim.metadata.originalBonusBalance) }})</span>
            </div>

            <div v-if="claim.status === 'activated' || claim.status === 'active'" class="detail-row">
              <span class="label">Remaining Balance:</span>
              <span class="value">{{ formatAmount(claim.metadata.remainingBonusBalance) }} Credits ({{ formatUSD(claim.metadata.remainingBonusBalance) }})</span>
            </div>

            <div v-if="claim.status === 'activated' || claim.status === 'active'" class="detail-row">
              <span class="label">Wagering Required:</span>
              <span class="value">{{ formatAmount(claim.metadata.wageringRequirement) }} Credits ({{ formatUSD(claim.metadata.wageringRequirement) }})</span>
            </div>

            <div v-if="claim.status === 'activated' || claim.status === 'active'" class="detail-row">
              <span class="label">Wagering Progress:</span>
              <div class="progress">
                <div class="progress-bar" 
                     :style="{ width: getProgressPercentage(claim) + '%' }"
                     :class="{ 'almost-complete': getProgressPercentage(claim) >= 90 }">
                  <span class="progress-text">{{ formatAmount(claim.metadata.wageringProgress || 0) }} / {{ formatAmount(claim.metadata.wageringRequirement) }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Collapsible Sections -->
          <div class="collapsible-sections">
            <!-- Game Restrictions Section -->
            <div v-if="claim.promotion.requirements.gameRestrictions" class="collapsible-section">
              <div class="section-header" @click="toggleSection(claim._id, 'restrictions')">
                <h4>Game Restrictions</h4>
                <span class="toggle-icon" :class="{ 'is-open': isOpen(claim._id, 'restrictions') }">▼</span>
              </div>
              <div class="section-content" v-show="isOpen(claim._id, 'restrictions')">
                <div class="restrictions-grid">
                  <div class="restriction-item">
                    <span class="restriction-icon">🎮</span>
                    <div class="restriction-content">
                      <span class="restriction-label">Game Type</span>
                      <span class="restriction-value">{{ formatGameType(claim.promotion.requirements.gameRestrictions.type) }}</span>
                    </div>
                  </div>
                  <div class="restriction-item" v-if="claim.promotion.requirements.gameRestrictions.providers?.length">
                    <span class="restriction-icon">🏢</span>
                    <div class="restriction-content">
                      <span class="restriction-label">Eligible Providers</span>
                      <div class="providers-list">
                        <span v-for="provider in claim.promotion.requirements.gameRestrictions.providers" 
                              :key="provider" 
                              class="provider-tag">
                          {{ provider }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="restriction-item" v-if="claim.promotion.requirements.gameRestrictions.excludedGames?.length">
                    <span class="restriction-icon">❌</span>
                    <div class="restriction-content">
                      <span class="restriction-label">Excluded Games</span>
                      <div class="games-list">
                        <span v-for="game in claim.promotion.requirements.gameRestrictions.excludedGames" 
                              :key="game" 
                              class="game-tag excluded">
                          {{ game }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Terms Section -->
            <div v-if="claim.promotion.terms?.length" class="collapsible-section">
              <div class="section-header" @click="toggleSection(claim._id, 'terms')">
                <h4>Terms & Conditions</h4>
                <span class="toggle-icon" :class="{ 'is-open': isOpen(claim._id, 'terms') }">▼</span>
              </div>
              <div class="section-content" v-show="isOpen(claim._id, 'terms')">
                <div class="terms-list">
                  <div class="term-item" v-for="(term, index) in claim.promotion.terms" :key="index">
                    <span class="term-bullet">•</span>
                    <span class="term-text">{{ term }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="claim-actions">
          <button v-if="claim.status === 'claimed'"
                  @click="cancelClaim(claim._id)"
                  class="btn btn-danger">
            Cancel Claim
          </button>
          <button v-if="(claim.status === 'activated' || claim.status === 'active') && !hasStartedWagering(claim)"
                  @click="cancelClaim(claim._id)"
                  class="btn btn-danger">
            Cancel Bonus
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ActiveClaims',
  
  data() {
    return {
      loading: true,
      claims: [],
      openSections: {} // Track open/closed state of collapsible sections
    }
  },

  computed: {
    ...mapGetters([
      'socketGeneral',
      'authToken',
      'user'
    ])
  },

  mounted() {
    if (this.socketGeneral?.connected) {
      this.initialize();
    } else {
      this.socketConnectGeneral().then(() => {
        this.initialize();
      });
    }
  },

  beforeDestroy() {
    if (this.socketGeneral) {
      this.removeSocketListeners();
    }
  },

  methods: {
    ...mapActions([
      'socketConnectGeneral'
    ]),

    initialize() {
      this.fetchClaims();
      this.setupSocketListeners();
    },

    fetchClaims() {
      if (!this.socketGeneral?.connected) {
        this.loading = false;
        this.$toast.error('Socket not connected');
        return;
      }

      this.socketGeneral.emit('promotions:my_claims', {}, (response) => {
        this.loading = false;
        if (response.success) {
          console.log('Received claims:', response.claims);
          this.claims = response.claims.filter(claim => claim.status === 'active' || claim.status === 'claimed');
        } else {
          this.$toast.error(response.error.message || 'Failed to fetch claims');
        }
      });
    },

    setupSocketListeners() {
      if (!this.socketGeneral) return;

      this.socketGeneral.on('promotion_progress', this.handleProgressUpdate);
      this.socketGeneral.on('promotion_completed', this.handlePromotionCompleted);
      this.socketGeneral.on('promotion_cancelled', this.handlePromotionCancelled);
      this.socketGeneral.on('promotion_claimed', this.handlePromotionClaimed);
    },

    removeSocketListeners() {
      if (!this.socketGeneral) return;

      this.socketGeneral.off('promotion_progress', this.handleProgressUpdate);
      this.socketGeneral.off('promotion_completed', this.handlePromotionCompleted);
      this.socketGeneral.off('promotion_cancelled', this.handlePromotionCancelled);
      this.socketGeneral.off('promotion_claimed', this.handlePromotionClaimed);
    },

    handleProgressUpdate(data) {
      const claim = this.claims.find(c => c._id === data.claimId);
      if (claim) {
        claim.metadata.wageringProgress = data.wageringProgress;
        claim.metadata.remainingBonusBalance = data.remainingBonus;
      }
    },

    handlePromotionCompleted(data) {
      this.claims = this.claims.filter(c => c._id !== data.claimId);
      this.$toast.success('Bonus completed! Amount added to your balance.');
    },

    handlePromotionCancelled(data) {
      this.claims = this.claims.filter(c => c._id !== data.claimId);
      this.$toast.success('Bonus cancelled successfully.');
    },

    handlePromotionClaimed(data) {
      this.fetchClaims(); // Refresh claims list
      this.$toast.success('Promotion claimed successfully! Make a deposit to activate your bonus.');
      // Open cashier modal
      this.$store.dispatch('modalsSetData', { typeCashier: 'deposit' });
      this.$store.dispatch('modalsSetShow', 'Cashier');
    },

    cancelClaim(claimId) {
      if (!this.socketGeneral?.connected) {
        this.$toast.error('Socket not connected');
        return;
      }

      if (!confirm('Are you sure you want to cancel this bonus? This action cannot be undone.')) {
        return;
      }

      this.socketGeneral.emit('promotions:cancel_claim', { claimId }, (response) => {
        if (response.success) {
          this.claims = this.claims.filter(c => c._id !== claimId);
          this.$toast.success('Bonus cancelled successfully');
        } else {
          this.$toast.error(response.error?.message || 'Failed to cancel bonus');
        }
      });
    },

    formatAmount(amount) {
      return amount ? amount.toLocaleString() : '0';
    },

    formatUSD(credits) {
      return `$${(credits / 1000).toFixed(2)}`;
    },

    getProgressPercentage(claim) {
      if (!claim?.metadata?.wageringRequirement) return 0;
      return Math.min(100, ((claim.metadata.wageringProgress || 0) / claim.metadata.wageringRequirement) * 100);
    },

    hasStartedWagering(claim) {
      return claim?.metadata?.wageringProgress > 0;
    },

    formatGameType(type) {
      if (!type) return 'All Games';
      return type.replace(/_/g, ' ').toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },

    toggleSection(claimId, section) {
      if (!this.openSections[claimId]) {
        this.openSections[claimId] = {};
      }
      this.$set(this.openSections[claimId], section, !this.openSections[claimId][section]);
    },

    isOpen(claimId, section) {
      return this.openSections[claimId]?.[section] || false;
    }
  }
}
</script>

<style scoped>
.active-claims {
  padding: 24px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.section-header {
  margin-bottom: 24px;
}

.section-header h3 {
  font-size: 22px;
  font-weight: 700;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.5px;
  line-height: 1.2;
}

.claims-list {
  display: grid;
  gap: 20px;
}

.claim-card {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.claim-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.claim-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.claim-header h4 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  letter-spacing: -0.3px;
}

.status {
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.status.claimed {
  background: rgba(255, 184, 0, 0.1);
  color: #FFB800;
  border: 1px solid rgba(255, 184, 0, 0.3);
}

.status.activated, .status.active {
  background: rgba(0, 170, 109, 0.1);
  color: #00AA6D;
  border: 1px solid rgba(0, 170, 109, 0.3);
}

.detail-row {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.label {
  min-width: 160px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.value {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  flex: 1;
}

.progress {
  flex: 1;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #00AA6D 0%, #008F5B 100%);
  box-shadow: 0px 0px 8px rgba(0, 170, 109, 0.3);
  transition: width 0.3s ease, background 0.3s ease;
  border-radius: 6px;
  position: relative;
}

.progress-text {
  position: absolute;
  top: -18px;
  right: 0;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.8);
}

.progress-bar.almost-complete {
  background: linear-gradient(90deg, #00AA6D 0%, #00D488 100%);
  box-shadow: 0px 0px 12px rgba(0, 170, 109, 0.5);
}

.claim-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  padding: 8px 20px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.btn-danger {
  background: rgba(255, 59, 48, 0.1);
  color: #ff3b30;
  border: 1px solid rgba(255, 59, 48, 0.3);
}

.btn-danger:hover {
  transform: translateY(-1px);
  background: rgba(255, 59, 48, 0.2);
  box-shadow: 0 4px 12px rgba(255, 59, 48, 0.2);
}

.btn-primary {
  background: linear-gradient(180deg, #2C5EFF 0%, #1F4BCC 100%);
  color: white;
}

.btn-primary:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(44, 94, 255, 0.3);
}

.no-claims {
  text-align: center;
  padding: 48px 24px;
}

.no-claims p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 24px;
  font-size: 15px;
  letter-spacing: 0.2px;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 16px;
}

.loading i {
  font-size: 24px;
  color: rgba(44, 94, 255, 0.8);
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .active-claims {
    padding: 20px;
  }

  .claim-card {
    padding: 16px;
  }

  .quick-info {
    grid-template-columns: 1fr;
  }

  .detail-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .label {
    min-width: auto;
    margin-bottom: 4px;
  }

  .value {
    width: 100%;
  }

  .progress {
    width: 100%;
  }

  .claim-actions {
    flex-direction: column;
  }

  .btn {
    width: 100%;
    text-align: center;
  }

  .restrictions-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .active-claims {
    padding: 16px;
  }

  .section-header h3 {
    font-size: 18px;
  }

  .claim-header h4 {
    font-size: 16px;
  }
}

/* Quick Info Section */
.quick-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 20px;
}

.info-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.info-icon {
  font-size: 20px;
}

.info-text {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.info-label {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.info-value {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

/* Details Section */
.details-section {
  margin-bottom: 20px;
}

.detail-row {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.label {
  min-width: 160px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.value {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  flex: 1;
}

/* Progress Bar */
.progress {
  flex: 1;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #00AA6D 0%, #008F5B 100%);
  box-shadow: 0px 0px 8px rgba(0, 170, 109, 0.3);
  transition: width 0.3s ease, background 0.3s ease;
  border-radius: 6px;
  position: relative;
}

.progress-text {
  position: absolute;
  top: -18px;
  right: 0;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.8);
}

.progress-bar.almost-complete {
  background: linear-gradient(90deg, #00AA6D 0%, #00D488 100%);
  box-shadow: 0px 0px 12px rgba(0, 170, 109, 0.5);
}

/* Collapsible Sections */
.collapsible-sections {
  margin-top: 20px;
}

.collapsible-section {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
}

.collapsible-section .section-header {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 0;
  transition: background-color 0.2s ease;
}

.collapsible-section .section-header:hover {
  background: rgba(255, 255, 255, 0.05);
}

.collapsible-section h4 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.toggle-icon {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  transition: transform 0.2s ease;
}

.toggle-icon.is-open {
  transform: rotate(180deg);
}

.section-content {
  padding: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

/* Game Restrictions */
.restrictions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 12px;
}

.restriction-item {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.restriction-icon {
  font-size: 16px;
}

.restriction-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.restriction-label {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.restriction-value {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
}

.providers-list,
.games-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.provider-tag,
.game-tag {
  font-size: 11px;
  padding: 3px 6px;
  border-radius: 4px;
  background: rgba(44, 94, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(44, 94, 255, 0.2);
}

.game-tag.excluded {
  background: rgba(255, 59, 48, 0.1);
  border-color: rgba(255, 59, 48, 0.2);
  color: #ff3b30;
}

/* Terms Section */
.terms-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.term-item {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.term-bullet {
  color: rgba(44, 94, 255, 0.8);
  font-size: 16px;
  line-height: 1.4;
}

.term-text {
  flex: 1;
  font-size: 13px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.2px;
}

/* Actions */
.claim-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  padding: 8px 20px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .active-claims {
    padding: 20px;
  }

  .claim-card {
    padding: 16px;
  }

  .quick-info {
    grid-template-columns: 1fr;
  }

  .detail-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .label {
    min-width: auto;
    margin-bottom: 4px;
  }

  .value {
    width: 100%;
  }

  .progress {
    width: 100%;
  }

  .claim-actions {
    flex-direction: column;
  }

  .btn {
    width: 100%;
    text-align: center;
  }

  .restrictions-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .active-claims {
    padding: 16px;
  }

  .section-header h3 {
    font-size: 18px;
  }

  .claim-header h4 {
    font-size: 16px;
  }
}
</style> 