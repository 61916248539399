<template>
  <div class="withdraw-form">
    <div class="form-header">
      <button class="back-button" @click="$emit('go-back')">
        <i class="fas fa-arrow-left"></i>
        Back
      </button>
      <div class="selected-currency">
        <div class="currency-icon">
          <img 
            :src="selectedCurrency.logo_url" 
            :alt="selectedCurrency.code"
            @error="handleImageError"
          />
        </div>
        <div class="currency-info">
          <span class="currency-name">{{ selectedCurrency.name }} ({{ selectedCurrency.code.toUpperCase() }})</span>
          <span class="network-name">{{ selectedCurrency.network.toUpperCase() }}</span>
        </div>
      </div>
    </div>

    <div class="form-content">
      <!-- Amount Input with MAX button -->
      <div class="amount-field">
        <div class="amount-label">
          <span>Amount (USD)</span>
          <span class="min-amount" v-if="minAmount">
            Min: ${{ minAmount }}
          </span>
        </div>
        <div class="amount-input-wrapper">
          <span class="currency-symbol">$</span>
          <input 
            type="number" 
            v-model="amount"
            :min="minAmount"
            placeholder="0.00"
            @input="handleAmountChange"
          />
          <button 
            class="max-button" 
            @click="amount = userBalance.toString()"
            v-if="userBalance > minAmount"
          >
            MAX
          </button>
        </div>
      </div>

      <!-- Wallet Address Input -->
      <div class="address-field">
        <div class="address-label">
          <span>Wallet Address</span>
          <span class="network-tag">{{ selectedCurrency.network.toUpperCase() }}</span>
        </div>
        <div class="address-input-wrapper">
          <input 
            type="text" 
            v-model="walletAddress"
            :placeholder="`Enter ${selectedCurrency.code.toUpperCase()} address`"
          />
        </div>
      </div>

      <!-- Estimated Amount -->
      <div class="estimated-amount" v-if="estimatedAmount">
        <div class="estimate-label">You will receive approximately:</div>
        <div class="estimate-value">
          <div class="currency-icon">
            <img 
              :src="selectedCurrency.logo_url" 
              :alt="selectedCurrency.code"
              @error="handleImageError"
            />
          </div>
          <span class="amount">
            {{ estimatedAmount }} {{ selectedCurrency.code.toUpperCase() }}
          </span>
        </div>
      </div>

      <!-- Warning Message -->
      <div class="warning-message">
        <i class="fas fa-exclamation-triangle"></i>
        <span>Please double-check the wallet address. Incorrect addresses may result in permanent loss of funds.</span>
      </div>

      <!-- Error Message -->
      <div class="error-message" v-if="errorMessage">
        <i class="fas fa-exclamation-circle"></i>
        {{ errorMessage }}
      </div>

      <!-- Add Fee Estimate -->
      <div class="fee-estimate" v-if="feeEstimate">
        <div class="estimate-label">Estimated Network Fee:</div>
        <div class="estimate-value">
          <div class="currency-icon">
            <img 
              :src="selectedCurrency.logo_url" 
              :alt="selectedCurrency.code"
              @error="handleImageError"
            />
          </div>
          <span class="amount">
            {{ feeEstimate.fee }} {{ selectedCurrency.code.toUpperCase() }}
          </span>
        </div>
      </div>

      <!-- Update Total Amount -->
      <div class="total-amount" v-if="estimatedAmount && feeEstimate">
        <div class="estimate-label">Total Amount (including fee):</div>
        <div class="estimate-value">
          <div class="currency-icon">
            <img 
              :src="selectedCurrency.logo_url" 
              :alt="selectedCurrency.code"
              @error="handleImageError"
            />
          </div>
          <span class="amount">
            {{ parseFloat(estimatedAmount) + parseFloat(feeEstimate.fee) }} {{ selectedCurrency.code.toUpperCase() }}
          </span>
        </div>
      </div>

      <!-- Submit Button -->
      <button 
        class="submit-button"
        :disabled="!isValidForm || isLoading"
        @click="submitWithdrawal"
      >
        <div class="button-content">
          <i class="fas fa-spinner fa-spin" v-if="isLoading"></i>
          <span v-else>Submit Withdrawal</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { mapActions } from 'vuex';
import nowPaymentsService from '@/services/nowPayments';
import AmountNumber from "@/components/AmountNumber";

export default {
  name: 'WithdrawForm',
  components: {
    AmountNumber
  },
  
  props: {
    selectedCurrency: {
      type: Object,
      required: true
    },
    minAmount: {
      type: Number,
      required: true
    },
    userBalance: {
      type: Number,
      required: true
    }
  },

  setup(props, { emit }) {
    const amount = ref('');
    const walletAddress = ref('');
    const estimatedAmount = ref(null);
    const isLoading = ref(false);
    const feeEstimate = ref(null);
    const addressError = ref(null);
    const cryptoAmount = ref(null);

    const isValidForm = computed(() => {
      const amountNum = parseFloat(amount.value);
      return amountNum >= props.minAmount && 
             amountNum <= props.userBalance && 
             walletAddress.value.length > 0;
    });

    const errorMessage = computed(() => {
      const amountNum = parseFloat(amount.value);
      if (amountNum > props.userBalance) {
        return `Insufficient balance. Maximum withdrawal: $${props.userBalance} USD`;
      }
      if (amountNum < props.minAmount) {
        return `Minimum withdrawal amount: $${props.minAmount} USD`;
      }
      return null;
    });

    // Add debounced fee estimation
    let feeTimeout;
    const updateFeeEstimate = async () => {
      if (!amount.value || isNaN(amount.value)) return;
      
      try {
        // First get crypto equivalent
        const estimate = await nowPaymentsService.getEstimatedPrice(
          parseFloat(amount.value),
          'usd',
          props.selectedCurrency.code
        );

        // Then get fee using crypto amount
        const feeEstimate = await nowPaymentsService.getWithdrawalFeeEstimate(
          props.selectedCurrency.code,
          estimate.estimated_amount
        );

        feeEstimate.value = feeEstimate;
        estimatedAmount.value = estimate.estimated_amount;
        cryptoAmount.value = estimate.estimated_amount;

        console.log('Fee estimate:', {
          usdAmount: amount.value,
          cryptoAmount: estimate.estimated_amount,
          fee: feeEstimate.fee,
          currency: props.selectedCurrency.code
        });
      } catch (error) {
        console.error('Failed to get fee estimate:', error);
      }
    };

    watch(amount, (newValue) => {
      clearTimeout(feeTimeout);
      if (newValue) {
        feeTimeout = setTimeout(updateFeeEstimate, 500);
      }
    });

    const handleSubmit = async () => {
      if (!isValidForm.value || isLoading.value) return;
      
      isLoading.value = true;
      try {
        emit('submit-withdrawal', {
          amount: parseFloat(amount.value),
          address: walletAddress.value,
          currency: props.selectedCurrency.code,
          estimated_amount: parseFloat(estimatedAmount.value),
          fee: feeEstimate.value?.fee
        });
      } catch (error) {
        console.error('Withdrawal submission failed:', error);
      } finally {
        isLoading.value = false;
      }
    };

    return {
      amount,
      walletAddress,
      estimatedAmount,
      isLoading,
      isValidForm,
      errorMessage,
      feeEstimate,
      addressError,
      cryptoAmount,
      handleSubmit
    };
  },

  methods: {
    ...mapActions(['notificationShow']),

    handleImageError(event) {
      const target = event.target;
      const code = this.selectedCurrency.code.toLowerCase();
      target.src = `https://nowpayments.io/images/coins/${code}.svg`;
    },

    async handleAmountChange() {
      if (!this.amount || isNaN(this.amount)) {
        this.estimatedAmount = null;
        this.feeEstimate = null;
        return;
      }

      try {
        // First get the crypto equivalent of USD amount
        const estimate = await nowPaymentsService.getEstimatedPrice(
          parseFloat(this.amount),
          'usd',
          this.selectedCurrency.code
        );

        // Get fee using the crypto amount
        const feeEstimate = await nowPaymentsService.getWithdrawalFeeEstimate(
          this.selectedCurrency.code,
          estimate.estimated_amount // Use the crypto amount here
        );

        this.feeEstimate = feeEstimate;
        this.estimatedAmount = estimate.estimated_amount;
        this.cryptoAmount = estimate.estimated_amount;

        console.log('Amount estimates:', {
          usdAmount: this.amount,
          cryptoAmount: this.cryptoAmount,
          fee: feeEstimate.fee,
          estimatedFinalAmount: parseFloat(this.cryptoAmount) - parseFloat(feeEstimate.fee)
        });

      } catch (error) {
        console.error('Error getting estimates:', error);
        this.notificationShow({
          type: 'error',
          message: 'Failed to get estimates'
        });
      }
    },

    async validateAddress() {
      if (!this.walletAddress) {
        this.addressError = 'Address is required';
        return false;
      }

      try {
        const validation = await nowPaymentsService.validateAddress(
          this.selectedCurrency.code,
          this.walletAddress
        );

        if (!validation.valid) {
          this.addressError = validation.message;
          return false;
        }

        this.addressError = null;
        return true;
      } catch (error) {
        console.error('Address validation error:', error);
        this.addressError = 'Failed to validate address';
        return false;
      }
    },

    async submitWithdrawal() {
      if (!this.isValidForm || this.isLoading) return;

      this.isLoading = true;
      try {
        // First validate the address
        const isValidAddress = await this.validateAddress();
        if (!isValidAddress) {
          throw new Error(this.addressError || 'Invalid wallet address');
        }

        // Format withdrawal data exactly like the example
        const withdrawalData = {
          ipn_callback_url: process.env.VUE_APP_NOWPAYMENTS_IPN_URL,
          withdrawals: [{
            address: this.walletAddress,
            currency: this.selectedCurrency.code.toLowerCase(),
            amount: parseFloat(this.cryptoAmount).toFixed(6),
            ipn_callback_url: process.env.VUE_APP_NOWPAYMENTS_IPN_URL,
            fiat_amount: parseFloat(this.amount),
            fiat_currency: 'usd'
          }],
          fiat_amount: parseFloat(this.amount),
          fiat_currency: 'usd'
        };

        this.$emit('submit-withdrawal', withdrawalData);
      } catch (error) {
        console.error('Withdrawal submission error:', error);
        this.notificationShow({
          type: 'error',
          message: error.message || 'Failed to submit withdrawal'
        });
      } finally {
        this.isLoading = false;
      }
    },

    formatBalance(balance) {
      return parseFloat(balance)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}
</script>

<style scoped>
.withdraw-form {
  width: fit-content;
  min-width: min(100%, 480px);
  margin: 0 auto;
  padding: 16px;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(31, 42, 68, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.2s ease;
}

.back-button:hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(31, 42, 68, 0.5) 100%);
  transform: translateY(-1px);
}

.form-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.selected-currency {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  background: linear-gradient(180deg, rgba(44, 94, 255, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  border: 1px solid rgba(44, 94, 255, 0.2);
  margin-bottom: 20px;
}

.currency-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.currency-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
}

.currency-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.currency-name {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.2px;
}

.network-name {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

.amount-field, .address-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 0;
  padding: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.amount-label, .address-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.2px;
}

.min-amount {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
}

.amount-input-wrapper, .address-input-wrapper {
  position: relative;
}

.amount-input-wrapper input, .address-input-wrapper input {
  width: 100%;
  padding: 12px 70px 12px 32px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(31, 42, 68, 0.2) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  transition: all 0.2s ease;
}

.amount-input-wrapper input:focus, .address-input-wrapper input:focus {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  border-color: rgba(44, 94, 255, 0.3);
  outline: none;
}

.currency-symbol {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.9);
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.max-button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px 12px;
  background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
  border-radius: 6px;
  color: #FFFFFF;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  transition: all 0.2s ease;
}

.max-button:hover {
  background: linear-gradient(90deg, #3468FF 0%, #2152E5 100%);
  transform: translateY(-50%) scale(1.05);
}

.estimated-amount, .fee-estimate, .total-amount {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 0;
  padding: 16px;
  background: linear-gradient(180deg, rgba(44, 94, 255, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(44, 94, 255, 0.2);
}

.estimate-label {
  color: rgba(255, 255, 255, 0.7);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  letter-spacing: 0.2px;
}

.estimate-value {
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 0.9);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.estimate-value img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
}

.warning-message, .error-message {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 0;
  padding: 16px;
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.warning-message {
  background: linear-gradient(180deg, rgba(255, 193, 7, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 193, 7, 0.2);
  color: #FFC107;
}

.error-message {
  background: linear-gradient(180deg, rgba(233, 72, 72, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(233, 72, 72, 0.2);
  color: #E94848;
}

.submit-button {
  width: 100%;
  padding: 14px;
  background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
  border-radius: 10px;
  color: white;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  transition: all 0.2s ease;
}

.submit-button:not(:disabled):hover {
  background: linear-gradient(90deg, #3468FF 0%, #2152E5 100%);
  transform: translateY(-1px);
}

.submit-button:disabled {
  background: linear-gradient(90deg, rgba(44, 94, 255, 0.5) 0%, rgba(29, 67, 192, 0.5) 100%);
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .withdraw-form {
    min-width: min(100%, 360px);
    padding: 12px;
  }

  .form-header {
    padding: 12px;
    flex-direction: column;
    gap: 12px;
  }

  .form-content {
    padding: 16px;
  }

  .amount-field, .address-field {
    padding: 12px;
  }

  .amount-input-wrapper input, .address-input-wrapper input {
    font-size: 14px;
    padding: 10px 60px 10px 28px;
  }

  .estimate-value {
    font-size: 18px;
  }

  .warning-message, .error-message {
    font-size: 12px;
    padding: 12px;
  }

  .submit-button {
    padding: 12px;
    font-size: 13px;
  }

  .currency-icon {
    width: 28px;
    height: 28px;
  }

  .currency-name {
    font-size: 13px;
  }

  .network-name {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .withdraw-form {
    min-width: 100%;
    padding: 8px;
  }

  .form-header {
    padding: 10px;
  }

  .form-content {
    padding: 12px;
  }

  .amount-field, .address-field {
    padding: 10px;
  }

  .amount-label, .address-label {
    font-size: 13px;
  }

  .min-amount {
    font-size: 11px;
  }

  .amount-input-wrapper input, .address-input-wrapper input {
    font-size: 13px;
    padding: 8px 50px 8px 24px;
  }

  .max-button {
    padding: 3px 8px;
    font-size: 11px;
  }

  .estimate-value {
    font-size: 16px;
  }

  .warning-message, .error-message {
    font-size: 11px;
    padding: 10px;
  }

  .submit-button {
    padding: 10px;
    font-size: 12px;
  }

  .currency-icon {
    width: 24px;
    height: 24px;
  }

  .currency-name {
    font-size: 12px;
  }

  .network-name {
    font-size: 10px;
  }
}

/* Add these styles */
.fee-estimate, .total-amount {
  padding: 16px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-bottom: 16px;
}

.total-amount {
  background: rgba(44, 94, 255, 0.1);
}
</style>
