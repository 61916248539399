import EVASlotsGameService from '@/services/EVASlotsGameService'
import { EVA_PROVIDERS } from '@/constants/eva'

// Constants for pagination
export const FETCH_SIZE = 500  // Match backend's SYNC_PAGE_SIZE
export const DISPLAY_SIZE = 500 // Show 500 games per page

const state = {
  allGames: [],         // All fetched games
  pageGames: {},        // Store games by page number
  fullGamesList: [],    // Complete list of all games (unfiltered)
  liveGames: [],        // Live casino games
  pragmaticGames: [],   // Pragmatic games
  megawaysGames: [],    // Megaways games
  originalGames: [],    // Original games (BazedBet, Gamzix, etc.)
  displayedGames: [],   // Currently displayed games
  displayPage: 0,       // Current display page (local pagination)
  totalItems: 0,        // Total items from API
  providers: [],        // Available providers list
  selectedGame: null,   // Store selected game before login
  loading: {
    all: false,         // Main loading state
    live: false,        // Live games loading state
    pragmatic: false,   // Pragmatic games loading state
    originals: false    // Original games loading state
  },
  error: {
    all: null,          // Main error state
    live: null,         // Live games error state
    pragmatic: null,    // Pragmatic games error state
    originals: null,    // Original games error state
    current: null       // Current target error state
  },
  showGameModal: false, // Track if game modal is shown
  activeCategory: '',   // Track active category
  filters: {
    all: {
      searchQuery: '',
      productType: '',
      gameProvider: '',
      size: 500,
      page: 0
    },
    live: {
      productType: 'live-casino',
      size: 500,
      page: 0
    },
    pragmatic: {
      gameProvider: 'pragmatic',
      productType: 'slots',
      size: 500,
      page: 0
    },
    megaways: {
      searchQuery: 'megaways',
      productType: 'slots',
      size: 500,
      page: 0
    },
    originals: {
      gameProvider: ['BazedBet', 'Gamzix'], // Support multiple providers
      productType: 'slots',
      isOriginal: true,
      size: 500,
      page: 0
    }
  },
  currentGame: null,
  hasMoreGames: true,   // Flag to track if more games can be fetched
  currentFetchPage: 0,  // Track current fetch page
  totalServerPages: 0   // Track total pages on server
}

const mutations = {
  SET_PAGE_GAMES(state, { page, games }) {
    console.log('[EVA Mutation] Setting page games:', {
      page,
      gamesCount: games.length,
      totalGames: state.totalItems,
      currentDisplayed: state.displayedGames.length
    });
    
    // Store this page's games
    state.pageGames[page] = games;
    
    // Create a map of all existing games for deduplication
    const allGamesMap = new Map(state.allGames.map(game => [game.gameId, game]));
    
    // Add new games to the map (this automatically handles deduplication)
    games.forEach(game => {
      allGamesMap.set(game.gameId, game);
    });
    
    // Convert map back to array
    state.allGames = Array.from(allGamesMap.values());
    
    // Update displayed games to show all accumulated games
    state.displayedGames = state.allGames;
    
    console.log('[EVA Mutation] Updated games:', {
      pageGamesCount: games.length,
      totalLoadedGames: state.allGames.length,
      displayedGamesCount: state.displayedGames.length,
      currentPage: page,
      loadedPages: Object.keys(state.pageGames),
      activeCategory: state.activeCategory
    });
  },

  SET_FULL_GAMES_LIST(state, games) {
    state.fullGamesList = games
  },

  APPEND_GAMES(state, games) {
    state.allGames = [...state.allGames, ...games]
    state.fullGamesList = [...state.fullGamesList, ...games]
    // Update displayed games
    state.displayedGames = state.allGames
  },

  SET_LIVE_GAMES(state, games) {
    state.liveGames = games;
    console.log('[EVA Mutation] Set live games:', games.length);
  },

  SET_PRAGMATIC_GAMES(state, games) {
    state.pragmaticGames = games;
    console.log('[EVA Mutation] Set pragmatic games:', games.length);
  },

  SET_MEGAWAYS_GAMES(state, games) {
    state.megawaysGames = games;
    console.log('[EVA Mutation] Set megaways games:', games.length);
  },

  SET_DISPLAY_PAGE(state, page) {
    console.log('[EVA Mutation] Setting display page:', page);
    state.displayPage = page;
  },

  SET_TOTAL_ITEMS(state, total) {
    console.log('[EVA Mutation] Setting total items:', total);
    state.totalItems = total;
  },

  SET_LOADING(state, { loading, target = 'all' }) {
    state.loading[target] = loading;
  },

  SET_ERROR(state, { error, target = 'all' }) {
    state.error[target] = error;
  },

  SET_FILTERS(state, { filters, target = 'all' }) {
    // Reset pagination when filters change (except page)
    const { page, ...otherFilters } = filters;
    if (Object.keys(otherFilters).length > 0) {
      state.pageGames = {};
      state.allGames = [];
      state.displayedGames = [];
      state.currentFetchPage = 0;
    }
    state.filters[target] = { ...state.filters[target], ...filters };
  },

  RESET_GAMES(state) {
    state.allGames = [];
    state.pageGames = {};
    state.displayedGames = [];
    state.displayPage = 0;
    state.currentFetchPage = 0;
    state.totalItems = 0;
    state.hasMoreGames = true;
    state.totalServerPages = 0;
    
    // Reset page in filters but keep other filters
    Object.keys(state.filters).forEach(key => {
      state.filters[key] = {
        ...state.filters[key],
        page: 0
      };
    });
  },

  SET_CURRENT_GAME(state, game) {
    state.currentGame = game
  },

  SET_CURRENT_FETCH_PAGE(state, page) {
    state.currentFetchPage = page
  },

  SET_HAS_MORE_GAMES(state, hasMore) {
    state.hasMoreGames = hasMore
  },

  SET_TOTAL_SERVER_PAGES(state, total) {
    console.log('[EVA Mutation] Setting total server pages:', total);
    state.totalServerPages = total;
  },

  APPLY_FILTERS(state, { filters, target = 'all' }) {
    // Apply filters to the full games list
    let filteredGames = [...state.fullGamesList]

    // Apply product type filter
    if (filters.productType) {
      filteredGames = filteredGames.filter(game => game.productType === filters.productType)
    }

    // Apply provider filter
    if (filters.gameProvider) {
      filteredGames = filteredGames.filter(game => game.gameProvider === filters.gameProvider)
    }

    // Apply search filter
    if (filters.searchQuery) {
      const searchLower = filters.searchQuery.toLowerCase()
      filteredGames = filteredGames.filter(game => 
        game.translationKey?.toLowerCase().includes(searchLower) ||
        game.gameProvider?.toLowerCase().includes(searchLower)
      )
    }

    // Update the filtered games
    state.allGames = filteredGames
    const start = state.displayPage * DISPLAY_SIZE
    state.displayedGames = filteredGames.slice(start, start + DISPLAY_SIZE)
    state.totalItems = filteredGames.length
  },

  SET_FILTERED_GAMES(state, games) {
    state.allGames = games
    state.displayedGames = games
  },

  SET_SHOW_GAME_MODAL(state, show) {
    state.showGameModal = show
  },

  SET_ACTIVE_CATEGORY(state, category) {
    console.log('[EVA Mutation] Setting active category:', category);
    state.activeCategory = category;
  },

  SET_ALL_GAMES(state, games) {
    console.log('[EVA Mutation] Setting all games:', games.length);
    state.allGames = games;
  },

  SET_ORIGINAL_GAMES(state, games) {
    state.originalGames = games;
    console.log('[EVA Mutation] Set original games:', games.length);
  },

  SET_PROVIDERS(state, providers) {
    state.providers = providers;
  },

  SET_SELECTED_GAME(state, game) {
    state.selectedGame = game;
  }
}

const actions = {
  async fetchGames({ commit, state }, { target = 'all', forceRefresh = false } = {}) {
    try {
      // Clear any existing errors first
      commit('SET_ERROR', { error: null, target });
      commit('SET_ERROR', { error: null, target: 'current' });
      commit('SET_LOADING', { loading: true, target });
      
      const targetFilters = state.filters[target];
      if (!targetFilters) {
        throw new Error(`Invalid target: ${target}`);
      }

      // If forceRefresh, clear all cached data
      if (forceRefresh) {
        commit('RESET_GAMES');
        commit('SET_ACTIVE_CATEGORY', '');
      }

      // Handle multiple providers for originals
      let params = {
        size: FETCH_SIZE,
        page: state.displayPage || 0,
        ...targetFilters
      };

      // Special handling for originals with multiple providers
      if (target === 'originals' && Array.isArray(targetFilters.gameProvider)) {
        // Fetch games for each provider
        const allGames = [];
        for (const provider of targetFilters.gameProvider) {
          const providerParams = { ...params, gameProvider: provider };
          const response = await EVASlotsGameService.getGames(providerParams);
          if (response.items) {
            allGames.push(...response.items);
          }
        }
        
        // Filter and process all games
        const filteredGames = allGames.filter(game => {
          if (!game.imageUrl) return false;
          if (targetFilters.productType && game.productType !== targetFilters.productType) return false;
          if (targetFilters.isOriginal && !game.isOriginal) return false;
          return true;
        });

        commit('SET_ORIGINAL_GAMES', filteredGames);
        commit('SET_ALL_GAMES', filteredGames);
        commit('SET_TOTAL_ITEMS', filteredGames.length);
        commit('SET_ERROR', { error: null, target });
        return;
      }

      // Regular fetch for other targets
      const response = await EVASlotsGameService.getGames(params);
      console.log(`[EVA] Games response:`, response);

      if (!response.items) {
        throw new Error('Invalid response from server');
      }

      // Store the full response for debugging
      console.log('[EVA] Full response:', {
        receivedItems: response.items.length,
        withImages: response.items.filter(game => game.imageUrl != null && game.imageUrl !== "").length,
        currentPage: response.currentPage,
        totalItems: response.totalItems,
        totalPages: response.totalPages
      });

      // Filter and store games for this page
      const filteredGames = response.items.filter(game => {
        // Always filter out games without images
        if (!game.imageUrl) return false;

        // Apply category-specific filters
        if (targetFilters.productType && game.productType !== targetFilters.productType) return false;
        if (targetFilters.gameProvider && game.gameProvider !== targetFilters.gameProvider) return false;
        if (targetFilters.isOriginal && !game.isOriginal) return false;
        
        // Apply translation key search if it exists and wasn't handled by the backend
        if (targetFilters.translationKey && targetFilters.translationKey.length >= 3) {
          const searchTerm = targetFilters.translationKey.toLowerCase();
          if (!game.translationKey?.toLowerCase().includes(searchTerm)) {
            return false;
          }
        }
        
        return true;
      });

      // Store games for this page
      commit('SET_PAGE_GAMES', {
        page: state.displayPage,
        games: filteredGames
      });

      // Update the appropriate game list based on target
      switch (target) {
        case 'live':
          commit('SET_LIVE_GAMES', filteredGames);
          break;
        case 'pragmatic':
          commit('SET_PRAGMATIC_GAMES', filteredGames);
          break;
        case 'megaways':
          commit('SET_MEGAWAYS_GAMES', filteredGames);
          break;
        case 'originals':
          commit('SET_ALL_GAMES', filteredGames);
          break;
        default:
          commit('SET_ALL_GAMES', filteredGames);
      }
      
      // Update total items and pages
      commit('SET_TOTAL_ITEMS', response.totalItems);
      commit('SET_TOTAL_SERVER_PAGES', response.totalPages);
      commit('SET_ERROR', { error: null, target });

      // Update active category in store
      commit('SET_ACTIVE_CATEGORY', target);

      console.log('[EVA] Updated state:', {
        originalCount: response.items.length,
        filteredCount: filteredGames.length,
        totalItems: response.totalItems,
        totalPages: response.totalPages,
        currentPage: response.currentPage,
        displayPage: state.displayPage,
        activeCategory: target
      });

    } catch (error) {
      console.error(`[EVA] Error fetching ${target} games:`, error);
      commit('SET_ERROR', { error: error.message, target });
      commit('SET_ERROR', { error: error.message, target: 'current' });
    } finally {
      commit('SET_LOADING', { loading: false, target });
    }
  },

  async fetchNextPage({ commit, state, dispatch }, { target = 'all' } = {}) {
    if (!state.hasMoreGames) return

    try {
      commit('SET_LOADING', true)

      // Get the filters for the specific target
      const targetFilters = state.filters[target]

      // Make API call with the target-specific filters
      const params = {
        size: FETCH_SIZE,
        page: state.currentFetchPage,
        ...targetFilters
      }

      // Clean up empty params and ensure proper casing for provider
      Object.keys(params).forEach(key => {
        if (!params[key] && params[key] !== false) {
          delete params[key]
        }
        // Ensure proper casing for provider name
        if (key === 'gameProvider' && params[key] === 'pragmatic') {
          params[key] = 'Pragmatic Play'
        }
      })

      console.log(`[EVA] Fetching ${target} games page ${state.currentFetchPage} with params:`, params)
      
      const response = await EVASlotsGameService.getGames(params)
      console.log(`[EVA] ${target} games response:`, response)

      if (!response.items) {
        throw new Error('Invalid response from server')
      }

      let filteredGames = response.items

      // Apply filters
      if (filteredGames.length > 0) {
        // Filter out games without images
        filteredGames = filteredGames.filter(game => 
          game.imageUrl || game.image || game.thumbnailUrl || game.thumbnailImage
        )

        // Additional filtering based on product type if specified
        if (targetFilters.productType) {
          filteredGames = filteredGames.filter(game => 
            game.productType === targetFilters.productType
          )
        }
      }

      // Update state
      if (state.currentFetchPage === 0) {
        // First page, set the games and total items
        switch (target) {
          case 'live':
            commit('SET_LIVE_GAMES', filteredGames)
            break
          case 'pragmatic':
            commit('SET_PRAGMATIC_GAMES', filteredGames)
            break
          case 'megaways':
            commit('SET_MEGAWAYS_GAMES', filteredGames)
            break
          default:
            commit('SET_ALL_GAMES', filteredGames)
        }
        // Set total items from first page response
        commit('SET_TOTAL_ITEMS', response.totalItems)
        commit('SET_TOTAL_SERVER_PAGES', response.totalPages)
      } else {
        // Subsequent pages, append games
        commit('APPEND_GAMES', filteredGames)
      }
      
      // Check if we have more pages
      const hasMore = response.currentPage < (response.totalPages - 1)
      commit('SET_HAS_MORE_GAMES', hasMore)
      
      if (hasMore) {
        commit('SET_CURRENT_FETCH_PAGE', state.currentFetchPage + 1)
      }

      // Cache the current state only if we have all pages
      if (!hasMore || !state.hasMoreGames) {
        const cacheKey = JSON.stringify({ ...targetFilters, target })
        sessionStorage.setItem(`eva_games_${cacheKey}`, JSON.stringify({
          games: state.allGames,
          timestamp: Date.now(),
          totalServerPages: response.totalPages,
          totalItems: response.totalItems
        }))
      }

      commit('SET_ERROR', null)

      // If we have more pages and this was triggered by setFilters, continue fetching
      if (hasMore && state.filters[target].forceLoadAll) {
        await dispatch('fetchNextPage', { target })
      }
    } catch (error) {
      console.error('[EVA] Error fetching next page:', error)
      commit('SET_ERROR', error.message)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async setFilters({ commit, dispatch }, { filters, target = 'all', loadAll = false, forceRefresh = false }) {
    try {
      commit('SET_LOADING', true)
      
      // If forceRefresh, reset all filters in store first
      if (forceRefresh) {
        commit('SET_FILTERS', {
          filters: {
            searchQuery: '',
            productType: '',
            gameProvider: '',
            size: 500,
            page: 0
          },
          target: 'all'
        });
      }
      
      // Set the new filters
      commit('SET_FILTERS', { 
        filters: { 
          ...filters,
          forceLoadAll: loadAll 
        }, 
        target 
      })
      
      // Fetch and filter games with new filters
      await dispatch('fetchGames', { target, forceRefresh })
      
    } catch (error) {
      console.error('[EVA] Error applying filters:', error)
      commit('SET_ERROR', error.message)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async setDisplayPage({ commit, dispatch, state }, page) {
    try {
      console.log('[EVA] Setting display page:', page);
      // Clear any existing errors
      commit('SET_ERROR', { error: null, target: 'current' });
      
      // Check if we already have the page cached
      if (state.pageGames[page] && state.pageGames[page].length > 0) {
        console.log('[EVA] Using cached page:', page);
        commit('SET_DISPLAY_PAGE', page);
        commit('SET_PAGE_GAMES', {
          page,
          games: state.pageGames[page]
        });
        return;
      }
      
      commit('SET_LOADING', { loading: true, target: 'all' });
      
      // Update params with new page
      const params = {
        ...state.filters[state.activeCategory || 'all'],
        size: FETCH_SIZE,
        page: parseInt(page), // Ensure page is passed as integer
        channel: 'desktop',
        isActive: true
      };
      
      console.log('[EVA] Fetching page with params:', {
        ...params,
        currentPage: page,
        hasCache: !!state.pageGames[page]
      });
      
      // Update filters for the current category
      commit('SET_FILTERS', {
        filters: {
          ...state.filters[state.activeCategory || 'all'],
          page: parseInt(page)
        },
        target: state.activeCategory || 'all'
      });
      
      // Fetch games for the new page
      const response = await EVASlotsGameService.getGames(params);
      
      if (!response.items) {
        throw new Error('Invalid response from server');
      }
      
      console.log('[EVA] Received response:', {
        itemsCount: response.items.length,
        currentPage: response.currentPage,
        requestedPage: page,
        totalPages: response.totalPages,
        totalItems: response.totalItems
      });
      
      // Filter and store games for this page
      const filteredGames = response.items.filter(game => game.imageUrl);
      
      // Update the page number
      commit('SET_DISPLAY_PAGE', page);
      
      // Store games for this page
      commit('SET_PAGE_GAMES', {
        page,
        games: filteredGames
      });
      
      // Update total items and pages
      commit('SET_TOTAL_ITEMS', response.totalItems);
      commit('SET_TOTAL_SERVER_PAGES', response.totalPages);
      
      // Update current fetch page for infinite scroll
      commit('SET_CURRENT_FETCH_PAGE', page);
      commit('SET_HAS_MORE_GAMES', page < (response.totalPages - 1));
      
    } catch (error) {
      console.error('[EVA] Error setting display page:', error);
      commit('SET_ERROR', { error: error.message, target: 'current' });
      commit('SET_DISPLAY_PAGE', Math.max(0, page - 1));
    } finally {
      commit('SET_LOADING', { loading: false, target: 'all' });
    }
  },

  resetGames({ commit }) {
    commit('RESET_GAMES')
  },

  async launchGame({ commit }, params) {
    try {
      commit('SET_LOADING', true)
      console.log('[EVA] Launching game:', params)

      const { gameId, options } = params
      const response = await EVASlotsGameService.launchGame({
        gameId,
        options: {
          demo: options.demo,
          language: options.language || 'en',
          channel: options.channel || 'desktop',
          returnUrl: options.returnUrl,
          auth: options.auth ? {
            userId: options.auth.userId,
            token: options.auth.token
          } : null
        }
      })

      console.log('[EVA] Launch response:', response)

      if (!response?.launchUrl) {
        throw new Error('Invalid launch URL received from server')
      }

      return {
        launchUrl: response.launchUrl,
        isDemo: !!options.demo
      }
    } catch (error) {
      console.error('[EVA] Error launching game:', error)
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchGame({ commit }, gameId) {
    try {
      commit('SET_LOADING', true)
      const game = await EVASlotsGameService.getGame(gameId)
      commit('SET_CURRENT_GAME', game)
      return game
    } catch (error) {
      console.error('[EVA] Error fetching game:', error)
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  setCurrentGame({ commit }, game) {
    commit('SET_CURRENT_GAME', game)
  },

  setShowGameModal({ commit }, show) {
    commit('SET_SHOW_GAME_MODAL', show)
  },

  initializeProviders({ commit }) {
    commit('SET_PROVIDERS', EVA_PROVIDERS);
  }
}

const getters = {
  displayedGames: state => {
    console.log('[EVA Getter] Displayed games count:', state.displayedGames.length);
    return state.displayedGames;
  },
  totalDisplayPages: state => {
    return state.totalServerPages;
  },
  currentDisplayPage: state => {
    return state.displayPage;
  },
  hasNextPage: state => {
    return state.displayPage < (state.totalServerPages - 1);
  },
  hasPreviousPage: state => {
    return state.displayPage > 0;
  },
  isLoading: state => (target = 'all') => state.loading[target],
  hasError: state => (target = 'all') => {
    return !!state.error[target] || !!state.error.current
  },
  errorMessage: state => (target = 'all') => {
    return state.error[target] || state.error.current || null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
} 