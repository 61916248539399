<template>
  <footer id="footer">
    <div class="footer-container">
      <!-- Logo and Description Section -->
      <div class="footer-top">
        <div class="logo-section">
          <img src="@/assets/img/logo.png" alt="Bazedbet Logo" />
          <p class="description">
            BAZEDBET is the best web casino. Fast, secure and transparent gaming
            experience with a wide range of games. Enjoy slots, table games and
            poker.
          </p>
        </div>

        <!-- Links Sections -->
        <div class="links-wrapper">
          <div class="links-section">
            <div class="section-title">Platform</div>
            <button @click="modalsSetShow('Fair')">Provability</button>
            <router-link to="/affiliates">Affiliates</router-link>
            <router-link to="/leaderboard">Leaderboard</router-link>
          </div>

          <div class="links-section">
            <div class="section-title">About Us</div>
            <button @click="modalsSetShow('Terms')">Terms & Conditions</button>
            <button @click="modalsSetShow('Privacy')">Privacy Policy</button>
            <button @click="modalsSetShow('Kyc')">KYC Policy</button>
            <button @click="modalsSetShow('ResponsibleGaming')">
              Responsible Gaming
            </button>
            <button @click="modalsSetShow('Faq')">FAQ</button>
          </div>

          <div class="links-section">
            <div class="section-title">Community</div>
            <div class="social-links">
              <a
                href="https://x.com/bazedarmy"
                target="_blank"
                class="social-link"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  width="24"
                  height="24"
                  stroke-width="2"
                >
                  <path d="M4 4l11.733 16h4.267l-11.733 -16z"></path>
                  <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"></path>
                </svg>
                X
              </a>
              <a
                href="https://t.me/bazedgamesportal"
                target="_blank"
                class="social-link"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  width="24"
                  height="24"
                  stroke-width="2"
                >
                  <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
                </svg>
                Telegram
              </a>
              <a
                href="https://docs.bazedbet.com"
                target="_blank"
                class="social-link"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  width="24"
                  height="24"
                  stroke-width="2"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                  stroke="currentColor"
                >
                  <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                  <path
                    d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
                  ></path>
                  <path d="M9 17h6"></path>
                  <path d="M9 13h6"></path>
                </svg>
                Documentation
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Payment Methods Section -->
      <div class="payment-methods-section">
        <div class="payment-methods-grid">
          <!-- Card Payment Methods -->
          <div class="payment-category">
            <h3 class="payment-category-title">Fiat Payment Options</h3>
            <div class="payment-items">
              <div class="payment-item" @click="handlePaymentClick">
                <i class="fab fa-cc-visa"></i>
                <span class="seo-text">Crypto Casino Bazed Visa Card Payments</span>
              </div>
              <div class="payment-item" @click="handlePaymentClick">
                <i class="fab fa-cc-mastercard"></i>
                <span class="seo-text">Crypto Casino Bazed Mastercard Payments</span>
              </div>
              <div class="payment-item" @click="handlePaymentClick">
                <i class="fab fa-cc-amex"></i>
                <span class="seo-text">Crypto Casino Bazed American Express Payments</span>
              </div>
              <div class="payment-item" @click="handlePaymentClick">
                <i class="fab fa-paypal"></i>
                <span class="seo-text">Crypto Casino Bazed PayPal Payments</span>
              </div>
              <div class="payment-item" @click="handlePaymentClick">
                <i class="fab fa-apple-pay"></i>
                <span class="seo-text">Crypto Casino Bazed Apple Pay Payments</span>
              </div>
              <div class="payment-item" @click="handlePaymentClick">
                <i class="fab fa-google-pay"></i>
                <span class="seo-text">Crypto Casino Bazed Google Pay Payments</span>
              </div>
            </div>
          </div>

          <!-- Cryptocurrencies -->
          <div class="payment-category">
            <h3 class="payment-category-title">Cryptocurrency Options</h3>
            <div class="payment-items crypto-grid">
              <div v-for="currency in availableCurrencies" 
                   :key="currency.code" 
                   class="payment-item"
                   :title="currency.name"
                   @click="handlePaymentClick">
                <img 
                  :src="currency.logo_url"
                  :alt="currency.name"
                  @error="handleImageError($event, currency)"
                />
                <span class="seo-text">Crypto Casino Bazed {{ currency.name }} Payments</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Legal Text Section -->
      <div class="legal-section">
        <div class="legal-content">
          <p class="legal-text">
            Bazedbet.com is owned and operated by FjordFrontSolutions Ltd.
            registration number: 2150237, registered address: 1st Floor,
            Columbus Centre, PO Box 2283, Road Town Tortola VG 1110, British
            Virgin Islands. Contact us support@bazedbet.com. Bazedbet.com is
            licensed and regulated by the Government of the Autonomous Island of
            Anjouan, Union of Comoros and operates under License No.
            ALSI-182407012-F11. Bazedbet.com has passed all regulatory
            compliance and is legally authorized to conduct gaming operations
            for any and all games of chance and wagering.
          </p>
          <div class="license-wrapper">
            <div
              id="anj-8d38334d-bffe-4fef-9667-f44055c8f156"
              data-anj-seal-id="8d38334d-bffe-4fef-9667-f44055c8f156"
              data-anj-image-size="128"
              data-anj-image-type="basic-small"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getAvailableCurrencies } from '@/services/currencyIcons';

export default {
  name: "Footer",
  data() {
    return {
      availableCurrencies: [],
      isLoadingCurrencies: true
    };
  },
  computed: {
    ...mapGetters(['authUser'])
  },
  methods: {
    ...mapActions(["modalsSetShow"]),
    initSeal() {
      // Create and load the script dynamically
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://8d38334d-bffe-4fef-9667-f44055c8f156.snippet.anjouangaming.org/anj-seal.js";
      script.onload = () => {
        // Initialize after script is loaded
        if (window.anj_8d38334d_bffe_4fef_9667_f44055c8f156) {
          setTimeout(() => {
            window.anj_8d38334d_bffe_4fef_9667_f44055c8f156.init();
          }, 100);
        }
      };
      document.body.appendChild(script);
    },
    async fetchAvailableCurrencies() {
      try {
        this.availableCurrencies = getAvailableCurrencies();
      } catch (error) {
        console.error('Error loading currencies:', error);
      } finally {
        this.isLoadingCurrencies = false;
      }
    },
    handleImageError(event, currency) {
      const target = event.target;
      target.src = require('@/assets/img/icons/crypto-default.svg');
    },
    handlePaymentClick() {
      if (this.authUser && this.authUser.user) {
        this.modalsSetShow('Cashier');
      } else {
        this.modalsSetShow('Login');
      }
    }
  },
  mounted() {
    this.initSeal();
    this.fetchAvailableCurrencies();
  },
};
</script>

<style scoped>
.footer-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 2rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    var(--background-primary) 100%
  );
  border-radius: 1rem;
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    0px -1px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px rgba(255, 255, 255, 0.3) inset;
}

.footer-top {
  display: flex;
  gap: 4rem;
  margin-bottom: 2rem;
}

.logo-section img {
  width: 120px;
  margin-bottom: 1rem;
}

.description {
  color: rgba(255, 255, 255, 0.39);
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}

.links-wrapper {
  display: flex;
  gap: 4rem;
  flex: 1;
}

.links-section {
  flex: 1;
}

.section-title {
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.links-section a,
.links-section button {
  display: block;
  color: rgba(255, 255, 255, 0.39);
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5rem 0;
  transition: color 0.3s ease;
  text-align: left;
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
}

.links-section a:hover,
.links-section button:hover {
  color: rgba(255, 255, 255, 0.8);
}

.social-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.social-link {
  display: flex !important;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.39);
  transition: color 0.3s ease;
}

.social-link svg {
  width: 16px;
  height: 16px;
}

.social-link:hover {
  color: rgba(255, 255, 255, 0.8);
}

.legal-section {
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.legal-content {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.legal-text {
  flex: 1;
  color: rgba(255, 255, 255, 0.39);
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.5;
  text-align: justify;
}

.license-wrapper {
  flex-shrink: 0;
  width: 128px; /* Match the seal size */
}

@media (max-width: 1024px) {
  .footer-top {
    flex-direction: column;
    gap: 2rem;
  }

  .links-wrapper {
    flex-wrap: wrap;
    gap: 2rem;
  }

  .links-section {
    flex: 0 0 calc(50% - 1rem);
  }
}

@media (max-width: 768px) {
  .footer-container {
    padding: 1.5rem;
  }

  .links-wrapper {
    gap: 1.5rem;
  }

  .links-section {
    flex: 0 0 100%;
  }

  .social-links {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .social-link {
    flex: 0 0 calc(50% - 0.5rem);
  }

  .legal-content {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1rem;
  }

  .legal-text {
    text-align: center;
  }
}

.payment-methods-section {
  padding: 2rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.payment-methods-grid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.payment-category {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.payment-items {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.crypto-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  width: 100%;
  justify-content: flex-start;
}

.payment-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  transition: all 0.2s ease;
  cursor: pointer;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset;
  flex: 0 0 auto;
}

.payment-item:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.1);
}

.payment-item i {
  font-size: 1.25rem;
  color: #ffffff;
}

.payment-item img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.seo-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 768px) {
  .payment-methods-section {
    padding: 1.5rem 0;
  }

  .payment-items {
    gap: 0.5rem;
  }

  .crypto-grid {
    gap: 0.5rem;
  }

  .payment-item {
    width: 28px;
    height: 28px;
  }

  .payment-item i {
    font-size: 1rem;
  }

  .payment-item img {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 480px) {
  .payment-methods-section {
    padding: 1rem 0;
  }

  .payment-items {
    gap: 0.375rem;
  }

  .crypto-grid {
    gap: 0.375rem;
  }

  .payment-item {
    width: 24px;
    height: 24px;
  }

  .payment-item i {
    font-size: 0.875rem;
  }

  .payment-item img {
    width: 14px;
    height: 14px;
  }
}

.payment-category-title {
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-left: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.payment-category-title::before {
  content: "";
  display: block;
  width: 3px;
  height: 16px;
  background: linear-gradient(180deg, #349DFF 0%, #005EE9 100%);
  border-radius: 2px;
}

@media (max-width: 768px) {
  .payment-category-title {
    font-size: 0.8125rem;
    margin-bottom: 0.75rem;
  }
  
  .payment-category-title::before {
    height: 14px;
  }
}

@media (max-width: 480px) {
  .payment-category-title {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  
  .payment-category-title::before {
    height: 12px;
  }
}
</style>
