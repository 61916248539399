import axios from 'axios';

const state = {
  allGames: [],
  loading: false,
  isLoadingMore: false,
  hasMoreGames: true,
  currentPage: 1,
  pageSize: 24,
  totalGames: 0,
  activeCategory: 'All Games',
  selectedProvider: '',
  searchQuery: '',
  categories: [
    'All Games',
    'Live Casino',
    'Slots',
    'Table Games',
    'Jackpot',
    'Buy Bonus',
    'Game Shows',
    'Crash Games'
  ],
  providers: []
};

const getters = {
  isLoading: state => state.loading,
  hasError: state => !!state.error,
  errorMessage: state => state.error,
  stats: state => ({
    totalLoaded: state.allGames.length,
    totalGames: state.totalGames,
    currentlyShowing: state.allGames.length,
    hasMore: state.hasMoreGames
  })
};

const actions = {
  async loadAllGames({ commit, state }, { isInitial = false } = {}) {
    if (isInitial) {
      commit('RESET_PAGINATION');
    }

    const loadingType = isInitial ? 'SET_LOADING' : 'SET_LOADING_MORE';
    commit(loadingType, true);
    
    try {
      console.log('Loading games for category:', state.activeCategory);
      const response = await axios.get('/api/v1/slotegrator/games', {
        params: {
          page: state.currentPage,
          limit: state.pageSize,
          category: state.activeCategory,
          provider: state.selectedProvider,
          search: state.searchQuery
        }
      });

      console.log('API Response:', {
        endpoint: '/api/v1/slotegrator/games',
        category: state.activeCategory,
        responseData: response.data
      });

      const { games, total, hasMore, providers } = response.data;
      
      if (isInitial) {
        commit('SET_ALL_GAMES', games);
        commit('SET_PROVIDERS', providers);
      } else {
        commit('APPEND_GAMES', games);
      }
      
      commit('SET_TOTAL_GAMES', total);
      commit('SET_HAS_MORE_GAMES', hasMore);
      
    } catch (error) {
      console.error('Error loading games:', error);
      commit('SET_ERROR', error.message || 'Failed to load games');
    } finally {
      commit(loadingType, false);
    }
  },

  async loadMoreGames({ commit, dispatch, state }) {
    if (state.isLoadingMore || !state.hasMoreGames) return;
    
    commit('INCREMENT_PAGE');
    await dispatch('loadAllGames', { isInitial: false });
  },

  async setActiveCategory({ commit, dispatch }, category) {
    console.log('Store: Setting active category to:', category);
    commit('SET_ACTIVE_CATEGORY', category);
    commit('RESET_PAGINATION');
    await dispatch('loadAllGames', { isInitial: true });
  },

  async setProvider({ commit, dispatch }, provider) {
    commit('SET_SELECTED_PROVIDER', provider);
    commit('RESET_PAGINATION');
    await dispatch('loadAllGames', { isInitial: true });
  },

  async setSearchQuery({ commit, dispatch }, query) {
    commit('SET_SEARCH_QUERY', query);
    commit('RESET_PAGINATION');
    await dispatch('loadAllGames', { isInitial: true });
  },

  async initGame({ commit }, gameData) {
    try {
      const response = await axios.post('/api/v1/slotegrator/init', gameData);
      return response.data;
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    }
  },

  async initDemoGame({ commit }, gameData) {
    try {
      const response = await axios.post('/api/v1/slotegrator/init-demo', gameData);
      return response.data;
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    }
  }
};

const mutations = {
  SET_ALL_GAMES(state, games) {
    state.allGames = games;
  },
  
  APPEND_GAMES(state, games) {
    const existingIds = new Set(state.allGames.map(g => g.uuid));
    const newGames = games.filter(game => !existingIds.has(game.uuid));
    state.allGames = [...state.allGames, ...newGames];
  },
  
  RESET_PAGINATION(state) {
    state.currentPage = 1;
    state.hasMoreGames = true;
    state.allGames = [];
  },
  
  INCREMENT_PAGE(state) {
    state.currentPage++;
  },
  
  SET_HAS_MORE_GAMES(state, hasMore) {
    state.hasMoreGames = hasMore;
  },
  
  SET_TOTAL_GAMES(state, total) {
    state.totalGames = total;
  },
  
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  
  SET_LOADING_MORE(state, isLoading) {
    state.isLoadingMore = isLoading;
  },
  
  SET_ACTIVE_CATEGORY(state, category) {
    state.activeCategory = category;
  },
  
  SET_SELECTED_PROVIDER(state, provider) {
    state.selectedProvider = provider;
  },
  
  SET_SEARCH_QUERY(state, query) {
    state.searchQuery = query;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
  
  SET_PROVIDERS(state, providers) {
    state.providers = providers;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};