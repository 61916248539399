const state = {
    bets: null,
    loading: false,
    error: null
};

const getters = {
    sportsBets: state => state
};

const actions = {
    async sportsGetBetsDataSocket({ commit, rootGetters }, data) {
        try {
            commit('SET_LOADING', true);
            rootGetters.socketSports.emit('sports:getBets', data, (response) => {
                if (response.success) {
                    commit('SET_BETS', response.bets);
                } else {
                    commit('SET_ERROR', response.error);
                }
            });
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_LOADING', false);
        }
    }
};

const mutations = {
    SET_BETS(state, bets) {
        // Transform sports bets to match the general bet format
        state.bets = bets.map(bet => ({
            _id: bet.externalId,
            user: bet.userId,
            method: 'sports',
            amount: bet.amount,
            payout: bet.afterBalance - bet.oldBalance,
            multiplier: ((bet.afterBalance - bet.oldBalance) / bet.amount) * 100,
            updatedAt: bet.createdAt || bet.initiatedAt,
            status: bet.status,
            currency: bet.currency,
            platform: bet.platform,
            context: bet.context
        }));
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}; 