var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roll-game-win",class:{
    'win-high': _vm.multiplier / 100 >= 20,
    'win-mid': _vm.multiplier / 100 < 20 && _vm.multiplier / 100 >= 5,
    'win-low': _vm.multiplier / 100 < 5 && _vm.multiplier / 100 >= 2
}},[_c('div',{staticClass:"win-box"},[_c('div',{staticClass:"box-inner"},[_c('div',{staticClass:"inner-card"},[_c('div',{staticClass:"card-inner"},[_c('div',{staticClass:"inner-amount"},[_c('img',{attrs:{"src":require("@/assets/img/icons/coin.svg"),"alt":"icon"}}),_c('div',{staticClass:"amount-value"},[_c('span',[_vm._v(_vm._s(_vm.rollFormatValue(_vm.winAmount).split('.')[0]))]),_vm._v("."+_vm._s(_vm.rollFormatValue(_vm.winAmount).split('.')[1])+" ")])]),_c('div',{staticClass:"inner-multiplier"},[_vm._v(_vm._s(parseFloat(_vm.multiplier / 100).toFixed(2))+"x")]),_c('div',{staticClass:"inner-win"},[_c('div',{staticClass:"win-image",style:({ 
                            'background-image': 'url(' + _vm.rollGetItem.image + ')',
                            'background-repeat': 'no-repeat',
                            'background-position': 'center',
                            'background-size': '110px auto'
                        })}),_vm._v(" "+_vm._s(_vm.rollGetItem.name)+" ")])])])])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }