<template>
    <div class="card">
        <div class="card-header">
            <p class="card-title">24H BAZED BURNT</p>
            <div class="info-logo">
                <img src="@/assets/icons/info.svg" alt="info" />
            </div>
        </div>
        <div class="sport-data">
            <span class="bazed-amount">1,000,000 BAZED</span>
            <span class="card-title">$1,000,000.00</span>
        </div>
        <div class="sport-chart">
            <div class="chart-container">
                <LineChart class="chart" v-bind:chart-options="ChartOptions" v-bind:chart-data="BazedBurntChartData" />
            </div>
        </div>
        <div class="sport-options">
            <div class="sport-opt" :class="{ 'sport-opt-active': activeOption === '24H' }"
                @click="setActiveOption('24H')">
                <span class="original-amount">24H</span>
            </div>
            <div class="sport-opt" :class="{ 'sport-opt-active': activeOption === '1D' }"
                @click="setActiveOption('1D')">
                <span class="original-amount">1D</span>
            </div>
            <div class="sport-opt" :class="{ 'sport-opt-active': activeOption === '30D' }"
                @click="setActiveOption('30D')">
                <span class="original-amount">30D</span>
            </div>
        </div>
    </div>
</template>

<script>
import LineChart from '@/components/LineChart';
import { bazedBurnt } from '../../views/buyback/DataConfig';

export default {
    name: 'BuybackBazedBurntCard',
    components: {
        LineChart,
    },
    data() {
        return {
            activeOption: '24H',
            ChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                layout: {
                    padding: {
                        left: 5,
                    },
                },
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        grid: { display: false },
                        position: 'right',
                        title: {
                            display: true,
                        },
                        ticks: {
                            callback: function (value) {
                                return `$${value / 1000}k`;
                            },
                        },
                    },
                    x: {
                        display: true,
                        grid: { display: false },
                        title: {
                            display: true,
                        },
                    },
                },

            },
            BazedBurntChartData: {
                labels: ['00:00', '06:00', '12:00', '18:00', '24:00'],
                datasets: [
                    {
                        label: 'Burnt',
                        data: bazedBurnt,
                        borderColor: 'rgba(1, 150, 254, 1)',
                        backgroundColor: 'rgba(1, 150, 254, 0.2)',
                        fill: false,
                        tension: 0.4,
                    },
                ],
            },
        };
    },
    methods: {
        setActiveOption(option) {
            this.activeOption = option;
        },
    },
}

</script>

<style scoped>
.bazed-amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.card {
    width: 100%;
    height: 402px;
    padding: 30px 30px 20px 30px;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(46, 99, 212, 0.00) 0%, rgba(46, 99, 212, 0.15) 100%), #10182B;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px -1px 0px 0px rgba(0, 0, 0, 0.73) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset;
    min-width: 330px;
}

.card-header {
    display: flex;
    justify-content: space-between;
}

.card-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #92969D;
    font-size: 16px;
}

.chart {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-top: 10px;
}

.chart-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.info-logo {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    cursor: pointer;
}

.original-amount {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #92969D;
}

.sport-chart {
    display: flex;
    width: 100%;
    height: 179px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #92969D;
    background: #081122;
    margin-top: 20px;
}

.sport-options,
.sport-opt {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 5px;
    background: #081122;
}

.sport-options {
    gap: 3px;
    width: 100%;
    height: 47px;
    margin-top: 23px;
    padding: 3px;
}

.sport-opt {
    width: 32.8%;
    height: 100%;
    align-items: center;
}

.sport-opt-active {
    background: #222A3F;
}

.sport-data {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
</style>