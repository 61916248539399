<template>
    <div class="commission-card">
        <div class="card-content">
            <transition name="fade" mode="out-in">
                <div v-if="loading" class="loading-state" key="loading"></div>
                <div v-else class="commission-info" key="content">
                    <div class="commission-header">
                        <span class="gradient-green">AVAILABLE COMMISSION</span>
                        <div class="commission-value">
                            <img src="@/assets/img/icons/coin.svg" alt="coin" />
                            <span>{{ formattedValue }}</span>
                        </div>
                    </div>
                    <button 
                        @click="handleClaim" 
                        :disabled="isDisabled"
                        class="claim-button"
                    >
                        <img src="@/assets/img/icons/coin.svg" alt="coin" />
                        <span v-if="!isLoggedIn">LOGIN TO CLAIM</span>
                        <span v-else-if="insufficientFunds">NEED 10.00 COINS TO CLAIM</span>
                        <span v-else>CLAIM {{ formattedValue }} COINS</span>
                    </button>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AffiliatesCommissionCard',
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number,
            required: true
        },
        isLoggedIn: {
            type: Boolean,
            required: true
        },
        socketLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        formattedValue() {
            const dollars = this.value / 1000;
            return dollars.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        insufficientFunds() {
            return this.value < 10000;
        },
        isDisabled() {
            return this.socketLoading || !this.isLoggedIn || this.insufficientFunds;
        }
    },
    methods: {
        handleClaim() {
            this.$emit('claim', this.value);
        }
    }
}
</script>

<style scoped>
.commission-card {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                0px 1px 0px rgba(255, 255, 255, 0.3) inset;
    border-radius: 12px;
    overflow: hidden;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    transition: all 0.3s ease;
}

.commission-card:hover {
    transform: translateY(-1px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3),
                0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                0px 1px 0px rgba(255, 255, 255, 0.3) inset;
}

.card-content {
    padding: 20px;
}

.loading-state {
    height: 120px;
    background: linear-gradient(90deg, 
        rgba(255, 255, 255, 0.05) 0%, 
        rgba(255, 255, 255, 0.1) 50%, 
        rgba(255, 255, 255, 0.05) 100%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

.commission-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.commission-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.gradient-green {
    font-size: 12px;
    font-weight: 700;
    background: linear-gradient(90deg, #00FF87 0%, #60EFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.commission-value {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.commission-value img {
    width: 32px;
    height: 32px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.claim-button {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: linear-gradient(90deg, #00FF87 0%, #60EFFF 100%);
    border-radius: 8px;
    color: #0D1727;
    font-weight: 700;
    font-size: 14px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 255, 135, 0.2);
}

.claim-button:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 6px 16px rgba(0, 255, 135, 0.3);
}

.claim-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.claim-button img {
    width: 20px;
    height: 20px;
    filter: brightness(0.2);
}

@keyframes loading {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
}

@media only screen and (max-width: 768px) {
    .card-content {
        padding: 16px;
    }

    .commission-value {
        font-size: 28px;
    }

    .commission-value img {
        width: 28px;
        height: 28px;
    }

    .claim-button {
        height: 44px;
        font-size: 13px;
    }

    .claim-button img {
        width: 18px;
        height: 18px;
    }
}

@media only screen and (max-width: 480px) {
    .card-content {
        padding: 12px;
    }

    .gradient-green {
        font-size: 11px;
    }

    .commission-value {
        font-size: 24px;
    }

    .commission-value img {
        width: 24px;
        height: 24px;
    }

    .claim-button {
        height: 40px;
        font-size: 12px;
    }

    .claim-button img {
        width: 16px;
        height: 16px;
    }
}
</style> 