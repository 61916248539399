var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bets-element"},[_c('div',{staticClass:"element-game"},[_c('div',{staticClass:"game-icon",class:{
      'icon-yellow': _vm.bet.method === 'crash',
      'icon-green': _vm.bet.method === 'roll',
      'icon-blue-dark': _vm.bet.method === 'blackjack',
      'icon-red': _vm.bet.method === 'duels',
      'icon-purple': _vm.bet.method === 'mines',
      'icon-blue': _vm.bet.method === 'towers',
      'icon-pink': _vm.bet.method === 'unbox',
      'icon-orange': _vm.bet.method === 'battles',
      'icon-green': _vm.bet.method === 'sports',
      'icon-purple': _vm.bet.method === 'slots'
    }},[(_vm.bet.method === 'crash')?_c('font-awesome-icon',{attrs:{"icon":"chart-line"}}):(_vm.bet.method === 'roll')?_c('font-awesome-icon',{attrs:{"icon":"dice"}}):(_vm.bet.method === 'blackjack')?_c('font-awesome-icon',{attrs:{"icon":"dice-five"}}):(_vm.bet.method === 'duels')?_c('font-awesome-icon',{attrs:{"icon":"gun"}}):(_vm.bet.method === 'mines')?_c('font-awesome-icon',{attrs:{"icon":"bomb"}}):(_vm.bet.method === 'towers')?_c('font-awesome-icon',{attrs:{"icon":"chess-rook"}}):(_vm.bet.method === 'unbox')?_c('font-awesome-icon',{attrs:{"icon":"box-open"}}):(_vm.bet.method === 'battles')?_c('font-awesome-icon',{attrs:{"icon":"shield"}}):(_vm.bet.method === 'sports')?_c('font-awesome-icon',{attrs:{"icon":"futbol"}}):(_vm.bet.method === 'slots')?_c('font-awesome-icon',{attrs:{"icon":"dice-d6"}}):_vm._e()],1),_c('span',{class:[
      'game-name',
      {
        'gradient-yellow': _vm.bet.method === 'crash',
        'gradient-green': _vm.bet.method === 'roll',
        'gradient-blue-dark': _vm.bet.method === 'blackjack',
        'gradient-red': _vm.bet.method === 'duels',
        'gradient-purple': _vm.bet.method === 'mines',
        'gradient-blue': _vm.bet.method === 'towers',
        'gradient-pink': _vm.bet.method === 'unbox',
        'gradient-orange': _vm.bet.method === 'battles',
        'gradient-green': _vm.bet.method === 'sports',
        'gradient-purple': _vm.bet.method === 'slots'
      }
    ]},[_vm._v(_vm._s(_vm.betsGetMethod))])]),_c('div',{staticClass:"element-user",class:_vm.userClasses},[_c('div',{staticClass:"user-avatar"},[_c('AvatarImage',{attrs:{"image":_vm.bet.user === null ? null : _vm.bet.user.avatar}})],1),(_vm.bet.user !== null)?_c('div',{staticClass:"user-username",domProps:{"innerHTML":_vm._s(_vm.bet.user.username)}}):_c('div',{staticClass:"anonymous"},[_vm._v("Anonymous")])]),_c('div',{staticClass:"element-time"},[_vm._v(" "+_vm._s(_vm.timeAgo)+" ")]),_c('div',{staticClass:"element-wager"},[_c('img',{attrs:{"src":require("@/assets/img/icons/coin.svg"),"alt":"coin"}}),_c('div',{staticClass:"value-container"},[_c('span',{staticClass:"main-value"},[_vm._v(_vm._s(_vm.betsFormatValue(_vm.bet.amount).split('.')[0]))]),_c('span',{staticClass:"decimal-value"},[_vm._v("."+_vm._s(_vm.betsFormatValue(_vm.bet.amount).split('.')[1]))])])]),_c('div',{staticClass:"element-multiplier"},[_c('span',{class:{ 'positive': _vm.betsGetMultiplier > 0 }},[_vm._v(" "+_vm._s(_vm.betsGetMultiplier.toFixed(2))+"x ")])]),_c('div',{staticClass:"element-payout",class:{ 'payout-positive': _vm.bet.payout > 0 }},[_c('img',{attrs:{"src":require("@/assets/img/icons/coin.svg"),"alt":"coin"}}),_c('div',{staticClass:"value-container"},[_c('span',{staticClass:"main-value"},[_vm._v(_vm._s(_vm.betsFormatValue(_vm.bet.payout || 0).split('.')[0]))]),_c('span',{staticClass:"decimal-value"},[_vm._v("."+_vm._s(_vm.betsFormatValue(_vm.bet.payout || 0).split('.')[1]))])])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }