<template>
  <div ref="battles-spinner" class="battles-spinner">
    <div class="spinner-inner">
      <div
        v-if="battlesGameData.game.state === 'pending'"
        class="inner-waiting"
      >
        <div class="waiting-info">
          <div class="info-inner">
            <div class="inner-box"></div>
            Awaiting EOS Block
          </div>
        </div>
      </div>

      <div
        v-for="(bet, index) in battlesGetBets"
        v-bind:key="index"
        class="inner-element"
      >
        <transition name="fade" mode="out-in">
          <div
            v-if="
              ['created', 'countdown'].includes(battlesGameData.game.state) ===
                true && bet === null
            "
            class="element-waiting"
          >
            <svg
              width="25"
              height="27"
              viewBox="0 0 25 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2869 6.62293C14.9566 6.62293 16.3102 5.26943 16.3102 3.5998C16.3102 1.93016 14.9566 0.57666 13.2869 0.57666C11.6172 0.57666 10.2637 1.93016 10.2637 3.5998C10.2637 5.26943 11.6172 6.62293 13.2869 6.62293Z"
              />
              <path
                d="M13.2864 26.4238C14.2881 26.4238 15.1002 25.6117 15.1002 24.6101C15.1002 23.6084 14.2881 22.7964 13.2864 22.7964C12.2847 22.7964 11.4727 23.6084 11.4727 24.6101C11.4727 25.6117 12.2847 26.4238 13.2864 26.4238Z"
              />
              <path
                d="M5.85686 9.39614C7.3597 9.39614 8.57799 8.1779 8.57799 6.67512C8.57799 5.17234 7.3597 3.9541 5.85686 3.9541C4.35403 3.9541 3.13574 5.17234 3.13574 6.67512C3.13574 8.1779 4.35403 9.39614 5.85686 9.39614Z"
              />
              <path
                d="M20.7148 23.0434C21.5496 23.0434 22.2264 22.3666 22.2264 21.5318C22.2264 20.697 21.5496 20.0203 20.7148 20.0203C19.8799 20.0203 19.2031 20.697 19.2031 21.5318C19.2031 22.3666 19.8799 23.0434 20.7148 23.0434Z"
              />
              <path
                d="M2.78032 16.5219C4.11575 16.5219 5.19833 15.4393 5.19833 14.104C5.19833 12.7686 4.11575 11.686 2.78032 11.686C1.44489 11.686 0.362305 12.7686 0.362305 14.104C0.362305 15.4393 1.44489 16.5219 2.78032 16.5219Z"
              />
              <path
                d="M23.7915 15.3134C24.459 15.3134 25 14.7724 25 14.105C25 13.4375 24.459 12.8965 23.7915 12.8965C23.1241 12.8965 22.583 13.4375 22.583 14.105C22.583 14.7724 23.1241 15.3134 23.7915 15.3134Z"
              />
              <path
                d="M4.36239 20.0367C3.53545 20.8636 3.53545 22.2015 4.36239 23.0284C5.18834 23.8553 6.5283 23.8553 7.35425 23.0284C8.18118 22.2015 8.18118 20.8636 7.35425 20.0367C6.5283 19.2088 5.18932 19.201 4.36239 20.0367Z"
              />
              <path
                d="M20.714 7.58346C21.2146 7.58346 21.6204 7.17767 21.6204 6.6771C21.6204 6.17654 21.2146 5.77075 20.714 5.77075C20.2134 5.77075 19.8076 6.17654 19.8076 6.6771C19.8076 7.17767 20.2134 7.58346 20.714 7.58346Z"
              />
            </svg>
            <div class="waiting-text">
              <div class="text-box"></div>
              WAITING FOR PLAYER
            </div>
          </div>
          <div
            v-else-if="
              ['created', 'countdown'].includes(battlesGameData.game.state) ===
                true && bet !== null
            "
            class="element-ready"
          >
            <div class="ready-text">
              <div class="text-box"></div>
              <span>READY TO START</span>
            </div>
          </div>
          <div
            v-else-if="
              ['pending', 'rolling'].includes(battlesGameData.game.state) ===
              true
            "
            class="element-wheel"
          >
            <BattlesReel
              v-bind:ref="'reel-' + (index + 1)"
              v-bind:style="battlesReelStyle"
              v-bind:reel="battlesReels[index + 1]"
              v-bind:pos="battlesReelsPos"
              v-bind:running="battlesRunning"
            />
          </div>
          <div
            v-else-if="
              ['completed'].includes(battlesGameData.game.state) === true
            "
            class="element-completed"
            v-bind:class="{ 'completed-winner': bet.payout > 0 }"
          >
            <div class="completed-text">
              {{ bet.payout > 0 ? "WINNER" : "LOST" }}
            </div>
            <div class="completed-amount">
              <img src="@/assets/img/icons/coin.svg" alt="icon" />
              <div class="amount-value">
                <span>{{ battlesFormatValue(bet.payout).split(".")[0] }}</span
                >.{{ battlesFormatValue(bet.payout).split(".")[1] }}
              </div>
            </div>
            <div class="completed-action">
              <button
                v-if="
                  index === 0 &&
                  authUser.user !== null &&
                  authUser.user._id === bet.user._id
                "
                v-on:click="battlesCreateButton()"
                class="button-recreate"
                v-bind:disabled="socketSendLoading !== null"
              >
                <div class="button-inner">
                  <transition name="fade" mode="out-in">
                    <ButtonLoading
                      v-if="socketSendLoading === 'BattlesCreate'"
                    />
                    <div v-else class="inner-content">
                      <svg
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.47861 7.85824L6.15342 10.5285L5.08395 11.5963L6.15493 12.6649L5.08471 13.7327L3.21144 11.8636L1.07023 14L0 12.9322L2.14121 10.7951L0.267935 8.9268L1.33816 7.859L2.40839 8.92605L3.47861 7.85824ZM0.413256 0L3.09715 0.00226551L12.0412 8.9268L13.1122 7.859L14.1824 8.9268L12.3099 10.7958L14.4503 12.9322L13.3801 14L11.2389 11.8636L9.36561 13.7327L8.29539 12.6649L9.36561 11.5963L0.415526 2.66649L0.413256 0ZM11.3554 0L14.0371 0.00226551L14.0386 2.66271L10.971 5.72264L8.29463 3.05313L11.3554 0Z"
                        />
                      </svg>
                      RE-CREATE BATTLE
                    </div>
                  </transition>
                </div>
              </button>
            </div>
          </div>
        </transition>

        <div class="element-seperator">
          <div class="seperator-box">
            <div class="box-inner">
              <IconGroupGradient
                v-if="
                  battlesGameData.game.mode === 'group' ||
                  (battlesGameData.game.mode === 'team' &&
                    (index === 0 || index === 2))
                "
              />
              <svg
                v-if="
                  battlesGameData.game.mode === 'group' ||
                  (battlesGameData.game.mode === 'team' &&
                    (index === 0 || index === 2))
                "
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8972 8.01057L10.7603 6.87354L8.40832 9.22551C7.98847 9.64535 7.46152 9.91908 6.88916 10.0215L8.38772 11.5201C8.52495 11.6574 8.74759 11.6574 8.88483 11.5201L11.8972 8.50777C12.0346 8.37044 12.0346 8.1478 11.8972 8.01057Z"
                  fill="#00F4FF"
                />
                <path
                  d="M10.2964 6.3429C10.6927 5.94668 10.6928 5.3019 10.2969 4.9054C10.2965 4.90495 10.296 4.90449 10.2955 4.90403C10.1976 4.80617 10.0832 4.73073 9.95855 4.68029C10.0666 4.40701 10.0894 3.94488 9.73151 3.58692C9.7306 3.58601 9.72968 3.585 9.72867 3.58408C9.63118 3.48723 9.51747 3.41252 9.3937 3.36235C9.50173 3.08927 9.52461 2.62704 9.16693 2.26927C9.16666 2.26899 9.16648 2.26881 9.16629 2.26862C9.06824 2.17058 8.95372 2.09496 8.82894 2.04451C8.93696 1.77124 8.95976 1.30901 8.6019 0.951059C8.6018 0.951059 8.6018 0.950967 8.60171 0.950967C8.60162 0.950876 8.60153 0.950784 8.60144 0.950693C8.20494 0.554196 7.55989 0.554196 7.16339 0.950693L6.49683 1.61717L6.83767 1.958C7.40563 2.52597 7.40563 3.45006 6.83776 4.01802C6.6466 4.20909 6.41178 4.34037 6.15581 4.40289C6.09457 4.65246 5.96676 4.88902 5.77204 5.08365C5.58116 5.27453 5.34671 5.40572 5.09101 5.46834C5.02848 5.72394 4.8972 5.95849 4.70642 6.14927C4.51554 6.34015 4.28108 6.47134 4.02539 6.53396C3.96286 6.78956 3.83158 7.02411 3.64079 7.21499C3.36569 7.49 2.99986 7.6416 2.61078 7.6416C2.2217 7.6416 1.85587 7.49009 1.58077 7.2149L1.23993 6.87406L0.102992 8.01109C-0.0343307 8.14842 -0.0343307 8.37097 0.102992 8.50829L3.1153 11.5206C3.25263 11.6579 3.47518 11.6578 3.6125 11.5206L5.76884 9.36436H6.37635C6.95585 9.36436 7.50066 9.13861 7.91043 8.72893L10.2964 6.3429Z"
                  fill="#00F4FF"
                />
                <path
                  d="M2.07826 6.71721C2.37249 7.01153 2.84964 7.01153 3.14388 6.71721C3.43821 6.42297 3.43821 5.94591 3.14388 5.65158C3.43821 5.94591 3.91527 5.94591 4.2096 5.65158C4.50383 5.35734 4.50383 4.88019 4.2096 4.58596C4.50383 4.88019 4.98098 4.88019 5.27522 4.58596C5.56946 4.29172 5.56946 3.81457 5.27522 3.52033C5.56946 3.81457 6.04661 3.81457 6.34085 3.52033C6.63517 3.226 6.63517 2.74894 6.34085 2.45462L4.83835 0.952123C4.54411 0.657886 4.06696 0.657886 3.77273 0.952123C3.47849 1.24636 3.47849 1.72351 3.77273 2.01775L3.96095 2.20606C3.66671 1.91173 3.18956 1.91173 2.89533 2.20606C2.60109 2.5003 2.60109 2.97745 2.89533 3.27169L3.27187 3.64823C2.97763 3.35399 2.50048 3.35399 2.20624 3.64823C1.91191 3.94246 1.91191 4.41961 2.20624 4.71385L2.58278 5.09048C2.28845 4.79615 1.81139 4.79615 1.51706 5.09048C1.22283 5.38472 1.22283 5.86178 1.51706 6.1561L2.07826 6.71721Z"
                  fill="#00F4FF"
                />
                <path
                  d="M6.66638 0.452784C6.71838 0.400785 6.7734 0.352996 6.83062 0.308595L6.625 0.102977C6.51807 -0.00404281 6.35448 -0.0305003 6.21926 0.0370625L5.35156 0.470911L5.99991 1.11935L6.66638 0.452784Z"
                  fill="#00F4FF"
                />
              </svg>
              <svg
                v-else
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.32477 6.35167L5.43975 8.46785L4.59412 9.31408L5.44095 10.1609L4.59472 11.0071L3.11351 9.52593L1.42045 11.219L0.574219 10.3728L2.26728 8.6791L0.786076 7.1985L1.63231 6.35227L2.47854 7.1979L3.32477 6.35167ZM0.900982 0.124023L3.02314 0.125819L10.0952 7.1985L10.9421 6.35227L11.7883 7.1985L10.3077 8.6797L12.0001 10.3728L11.1539 11.219L9.46085 9.52593L7.97965 11.0071L7.13341 10.1609L7.97965 9.31408L0.902777 2.23721L0.900982 0.124023ZM9.55301 0.124023L11.6734 0.125819L11.6746 2.23422L9.24899 4.6592L7.13282 2.54362L9.55301 0.124023Z"
                  fill="#00F4FF"
                />
                <path
                  d="M3.32477 6.35167L5.43975 8.46785L4.59412 9.31408L5.44095 10.1609L4.59472 11.0071L3.11351 9.52593L1.42045 11.219L0.574219 10.3728L2.26728 8.6791L0.786076 7.1985L1.63231 6.35227L2.47854 7.1979L3.32477 6.35167ZM0.900982 0.124023L3.02314 0.125819L10.0952 7.1985L10.9421 6.35227L11.7883 7.1985L10.3077 8.6797L12.0001 10.3728L11.1539 11.219L9.46085 9.52593L7.97965 11.0071L7.13341 10.1609L7.97965 9.31408L0.902777 2.23721L0.900982 0.124023ZM9.55301 0.124023L11.6734 0.125819L11.6746 2.23422L9.24899 4.6592L7.13282 2.54362L9.55301 0.124023Z"
                  fill="#00F4FF"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import IconVersusGradient from "@/components/icons/IconVersusGradient";
import IconGroupGradient from "@/components/icons/IconGroupGradient";
import ButtonLoading from "@/components/ButtonLoading";
import BattlesReel from "@/components/battles/BattlesReel";

export default {
  name: "BattlesSpinner",
  components: {
    IconVersusGradient,
    IconGroupGradient,
    ButtonLoading,
    BattlesReel,
  },
  data() {
    return {
      battlesRunning: false,
      battlesReelsPosRepeater: null,
      battlesReelsPos: 20,
      battlesReels: {
        1: [],
        2: [],
        3: [],
        4: [],
      },
      battlesReelStyle: {
        transform: "translateX(0px) translateY(-2416.5px)",
        transition: "none",
      },
    };
  },
  methods: {
    ...mapActions(["battlesSendCreateSocket"]),
    battlesFormatValue(value) {
      return parseFloat(Math.floor(value / 10) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    battlesGetItemsFormated(items) {
      let pos = 0;

      for (let item of items) {
        pos = pos + item.tickets;

        if (pos < 1000 - 1) {
          item.color = "yellow";
        } else if (pos < 5000 - 1) {
          item.color = "red";
        } else if (pos < 20000 - 1) {
          item.color = "green";
        } else if (pos < 50000 - 1) {
          item.color = "purple";
        } else {
          item.color = "blue";
        }
      }

      return items;
    },
    battlesGetBoxItems(box) {
      let items = [];

      for (let item of this.battlesGetItemsFormated(box.items)) {
        const count = Math.floor(item.tickets / 1000);
        for (let i = 0; i < (count <= 0 ? 1 : count); i++) {
          items.push(item);
        }
      }

      return items;
    },
    battlesGetOutcomeItem(outcome, items) {
      let outcomeItem = null;
      let pos = 0;

      for (const item of this.battlesGetItemsFormated(items)) {
        pos = pos + item.tickets;
        if (outcome <= pos) {
          outcomeItem = item;
          break;
        }
      }

      return outcomeItem;
    },
    battlesAddReels() {
      let items = this.battlesGetBoxItems(
        this.battlesGetBoxes[
          this.battlesGameData.game.bets[0].outcomes.length - 1
        ]
      );
      this.battlesReels = { 1: [], 2: [], 3: [], 4: [] };

      for (const reel of Object.keys(this.battlesReels)) {
        for (let i = 0; i < 80; i++) {
          this.battlesReels[reel].push(
            items[Math.floor(Math.random() * items.length)]
          );
        }
      }
    },
    battlesGetReelsPos() {
      const offset =
        this.$refs["reel-1"][0].$el.getBoundingClientRect().top +
        this.$refs["reel-1"][0].$el.getBoundingClientRect().height / 2 -
        this.$refs["battles-spinner"].getBoundingClientRect().height / 2 -
        this.$refs["battles-spinner"].getBoundingClientRect().top;
      const pos = Math.round(Math.abs(offset + 2416.5) / 125) + 20;

      if (this.battlesReelsPos !== pos) {
        this.battlesReelsPos = pos;

        if (this.battlesRunning === true && this.soundBattles === 1) {
          this.soundTick.volume = this.soundVolume;
          this.soundTick.currentTime = 0;
          this.soundTick.play();
        }
      }

      this.battlesReelsPosRepeater = requestAnimationFrame(
        this.battlesGetReelsPos
      );
    },
    battlesCreateButton() {
      const boxes = this.battlesGameData.game.boxes.map((element) => ({
        _id: element.box._id,
        count: element.count,
      }));

      const data = {
        boxes: boxes,
        playerCount: this.battlesGameData.game.playerCount,
        mode: this.battlesGameData.game.mode,
        levelMin: this.battlesGameData.game.options.levelMin,
        funding: this.battlesGameData.game.options.funding,
        private: this.battlesGameData.game.options.private,
        affiliateOnly: this.battlesGameData.game.options.affiliateOnly,
        cursed: this.battlesGameData.game.options.cursed,
        terminal: this.battlesGameData.game.options.terminal,
      };
      this.battlesSendCreateSocket(data);
    },
  },
  computed: {
    ...mapGetters([
      "soundVolume",
      "soundBattles",
      "soundTick",
      "soundUnbox",
      "soundCash",
      "generalTimeDiff",
      "socketSendLoading",
      "authUser",
      "battlesGameData",
    ]),
    battlesGetBets() {
      let bets = [];

      for (let bet = 0; bet < this.battlesGameData.game.playerCount; bet++) {
        const index = this.battlesGameData.game.bets.findIndex(
          (element) => element.slot === bet
        );

        bets.push(index !== -1 ? this.battlesGameData.game.bets[index] : null);
      }

      return bets;
    },
    battlesGetBoxes() {
      let boxes = [];

      if (this.battlesGameData.game !== null) {
        for (const box of this.battlesGameData.game.boxes) {
          for (let i = 0; i < box.count; i++) {
            boxes.push(box.box);
          }
        }
      }

      return boxes;
    },
  },
  watch: {
    battlesGameData: {
      deep: true,
      handler(data, dataOld) {
        if (this.battlesGameData.game.state === "rolling") {
          this.battlesAddReels();
          this.battlesGetReelsPos();
          this.battlesRunning = true;

          for (const [index, bet] of this.battlesGameData.game.bets.entries()) {
            this.battlesReelStyle = {
              transform: "translateX(0px) translateY(-2416.5px)",
              transition: "none",
            };

            this.battlesReels[index + 1][60] = this.battlesGetOutcomeItem(
              bet.outcomes[bet.outcomes.length - 1],
              this.battlesGetBoxes[bet.outcomes.length - 1].items
            );

            setTimeout(() => {
              const timeEnding =
                new Date(this.battlesGameData.game.updatedAt).getTime() + 5000;
              let timeLeft =
                timeEnding - (new Date().getTime() + this.generalTimeDiff);
              timeLeft = timeLeft > 0 ? timeLeft : 0;

              this.battlesReelStyle = {
                transform:
                  "translateX(0px) translateY(-" +
                  (7364 +
                    (105 / 8) * Math.floor(Math.random() * (7 - 1 + 1)) +
                    1) +
                  "px)",
                transition:
                  "transform " +
                  timeLeft / 1000 +
                  "s cubic-bezier(0.1, 0, 0.2, 1)",
              };

              setTimeout(() => {
                this.battlesReelStyle = {
                  transform: "translateX(0px) translateY(-7416.5px)",
                  transition: "transform 0.25s cubic-bezier(0.1, 0, 0.2, 1)",
                };

                cancelAnimationFrame(this.battlesReelsPosRepeater);
                setTimeout(() => {
                  this.battlesRunning = false;

                  if (this.soundBattles === 1) {
                    this.soundUnbox.volume = this.soundVolume;
                    this.soundUnbox.currentTime = 0;
                    this.soundUnbox.play();
                  }
                }, 250);
              }, timeLeft + 100);
            }, 250);
          }
        } else if (this.battlesGameData.game.state === "completed") {
          if (this.soundBattles === 1) {
            this.soundCash.volume = this.soundVolume;
            this.soundCash.currentTime = 0;
            this.soundCash.play();
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.battles-spinner {
  width: 100%;
  height: 274px;
  position: relative;
  padding: 1px;
  z-index: 1;
}

.battles-spinner .spinner-inner {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  background: #0d1525;
  border-radius: 1.25rem;
  border: 5px solid #ebebeb;
  z-index: 1;
}

.battles-spinner .inner-waiting {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba(2, 21, 36, 0.6);
  backdrop-filter: blur(4px);
  z-index: 10;
}

.battles-spinner .waiting-info {
  width: 330px;
  height: 110px;
  position: relative;
}

.battles-spinner .info-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}

.battles-spinner .inner-box {
  width: 14px;
  height: 14px;
  margin-bottom: 10px;
  transform: rotate(45deg);
}

.battles-spinner .inner-element {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
}

.battles-spinner .inner-element:nth-child(1) .element-completed {
  border-radius: 1rem 0 0 1rem;
}

.battles-spinner .inner-element:nth-child(2) .element-completed {
  border-radius: 0 1rem 1rem 0;
}

.battles-game.game-3 .battles-spinner .inner-element {
  width: 33.33%;
}

.battles-game.game-4 .battles-spinner .inner-element {
  width: 25%;
}

.battles-game .element-waiting,
.battles-game .element-ready,
.battles-game .element-completed {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.battles-game .element-completed {
  justify-content: flex-end;
  padding-bottom: 34px;
  background: linear-gradient(
    180deg,
    rgba(13, 21, 37, 0) 50%,
    rgba(255, 54, 54, 0.4) 100%
  );
}

.battles-game .element-completed.completed-winner {
  background: linear-gradient(
    180deg,
    rgba(13, 21, 37, 0) 50%,
    rgba(0, 255, 194, 0.4) 100%
  );
}

.battles-game .element-waiting svg {
  fill: #6e95b6;
  animation: bet_loading_animation 1.5s infinite linear both;
}

.battles-game .element-ready svg {
  width: 23px;
  height: 23px;
}

.battles-game .waiting-text,
.battles-game .ready-text {
  display: flex;
  align-items: center;
  margin-top: 9px;
  font-size: 14px;
  font-weight: 600;
  color: #6e95b6;
}

.battles-game .ready-text span {
  color: #00f4ff;
}

.battles-game .text-box {
  width: 10px;
  height: 10px;
  margin-right: 9px;
  transform: rotate(45deg);
}

.battles-game .waiting-text .text-box {
  background: radial-gradient(
    140% 140% at 50% 50%,
    #062944 0%,
    rgba(6, 41, 68, 0) 100%
  );
  border: 1px solid #0a304f;
}

.battles-game .ready-text .text-box {
  background: radial-gradient(
    140% 140% at 50% 50%,
    #00f4ff 0%,
    rgba(6, 41, 68, 0) 100%
  );
  border: 1px solid #00f4ff;
}

.battles-game .element-completed svg {
  fill: #f24034;
}

.battles-game .element-completed.completed-winner svg {
  fill: #00ffc2;
}

.battles-game .completed-text {
  color: #f23434;
  text-align: center;
  text-shadow: 0px 0px 6px #f23434;
  font-family: Montserrat;
  font-size: min(5vw, 2.5rem);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.battles-game .element-completed.completed-winner .completed-text {
  color: #00ffc2;
  text-align: center;
  text-shadow: 0px 0px 6px #00ffc2;
  font-family: Montserrat;
  font-size: min(5vw, 2.5rem);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.battles-game .completed-amount {
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.battles-game .completed-amount img {
  width: 20px;
  margin-right: 2px;
}

.battles-game .amount-value {
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}

.battles-game .amount-value span {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.battles-game .completed-action {
  height: 35px;
  margin-top: 35px;
}

.battles-game button.button-recreate {
  width: 200px;
  height: 100%;
  position: relative;
  padding: 1px;
}

.battles-game button.button-recreate .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0d1525;
  border-radius: 8px;
}

.battles-game button.button-recreate .button-loading.fade-leave-active {
  transition: opacity 0.1s;
}

.battles-game button.button-recreate .button-loading.fade-leave-to {
  opacity: 0;
}

.battles-game button.button-recreate .inner-content.fade-enter-active {
  transition: opacity 0.1s;
}

.battles-game button.button-recreate .inner-content.fade-enter {
  opacity: 0;
}

.battles-game button.button-recreate .inner-content {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  color: #ffffff;
}

.battles-game button.button-recreate .inner-content svg {
  margin-right: 8px;
  fill: #ffffff;
}

.battles-spinner .element-wheel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.battles-spinner .element-seperator {
  width: 1px;
  height: 100%;
  position: relative;
  background: linear-gradient(
    146deg,
    #00f4ff 0%,
    #00f4ff 40%,
    transparent 41%,
    transparent 60%,
    #00f4ff 61%,
    #00f4ff 100%
  );
}

.battles-game.game-created .battles-spinner .element-seperator {
  background: linear-gradient(
    146deg,
    #00f4ff 0%,
    #00f4ff 40%,
    transparent 41%,
    transparent 60%,
    #00f4ff 61%,
    #00f4ff 100%
  );
}

.battles-spinner .inner-element:last-child .element-seperator {
  display: none;
}

.battles-spinner .seperator-box {
  width: 35px;
  height: 28px;
  position: absolute;
  top: 50%;
  left: -17px;
  transform: translate(0, -50%);
  padding: 1px;
}

.battles-spinner .box-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.battles-game.game-created .battles-spinner .box-inner {
  mix-blend-mode: luminosity;
}

.battles-spinner .box-inner svg {
  width: 24px;
  height: 24px;
}

.battles-game.game-created .battles-spinner .box-inner svg {
  mix-blend-mode: luminosity;
  opacity: 0.8;
}

@keyframes bet_loading_animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 650px) {
  .battles-game .completed-action {
    display: none;
  }

  .battles-game .element-completed {
    padding-bottom: 100px;
  }
}
</style>
