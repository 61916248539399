<template>
    <div class="affiliates-banner">
        <div class="banner-content">
            <div class="banner-left">
                <div class="banner-title">
                    <h1>AFFILIATES</h1>
                    <div class="title-info">
                        <span class="subtitle">REFER FRIENDS & EARN</span>
                        <div class="rewards">
                            <div class="reward-item">
                                <img src="@/assets/img/icons/coin.svg" alt="coin" />
                                <span>5% COMMISSION</span>
                            </div>
                            <div class="reward-item">
                                <img src="@/assets/img/icons/coin.svg" alt="coin" />
                                <span>INSTANT PAYOUTS</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="banner-right">
                <div class="characters">
                    <img class="character" src="@/assets/img/home/mainbanner/Character_2.png" alt="character">
                </div>
                <div class="coins">
                    <img v-for="i in 3" :key="i" 
                         :class="`coin coin-${i}`"
                         src="@/assets/img/icons/coin.svg" 
                         alt="coin" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AffiliatesBanner'
}
</script>

<style scoped>
.affiliates-banner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, var(--background-primary) 100%);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
    border: 1px solid rgba(44, 94, 255, 0.1);
    position: relative;
}

.banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background: linear-gradient(90deg, rgba(44, 94, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    position: relative;
    min-height: 200px;
}

.banner-left {
    flex: 1;
    z-index: 2;
}

.banner-title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 600px;
}

.banner-title h1 {
    font-family: Montserrat, sans-serif;
    font-size: 48px;
    font-weight: 800;
    background: linear-gradient(135deg, #FFFFFF 0%, #B8C7FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    text-shadow: 0px 0px 8px rgba(52, 157, 255, 0.5);
    letter-spacing: -0.02em;
}

.title-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.subtitle {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #8bacc8;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.rewards {
    display: flex;
    gap: 16px;
}

.reward-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.reward-item:hover {
    border-color: rgba(44, 94, 255, 0.3);
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset,
        0px 0px 8px 0px rgba(45, 111, 255, 0.5);
    transform: translateY(-1px);
}

.reward-item img {
    width: 24px;
    height: 24px;
    filter: drop-shadow(0px 0px 4px rgba(52, 157, 255, 0.5));
}

.reward-item span {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0.05em;
}

.banner-right {
    position: relative;
    height: 100%;
    flex: 0 0 400px;
}

.characters {
    position: absolute;
    right: -40px;
    bottom: -40px;
    z-index: 1;
}

.character {
    height: 220px;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
    animation: float-right 4s ease-in-out infinite;
}

.coins {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.coin {
    position: absolute;
    width: 32px;
    height: 32px;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
    animation: float-coin 3s ease-in-out infinite;
}

.coin-1 { top: 20%; right: 45%; animation-delay: 0s; }
.coin-2 { top: 40%; right: 35%; animation-delay: 0.5s; }
.coin-3 { top: 30%; right: 55%; animation-delay: 1s; }

@keyframes float-right {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-15px); }
}

@keyframes float-coin {
    0%, 100% { transform: translateY(0) rotate(0deg); }
    50% { transform: translateY(-12px) rotate(15deg); }
}

@media only screen and (max-width: 1024px) {
    .banner-content {
        padding: 32px;
    }

    .banner-title h1 {
        font-size: 36px;
    }

    .subtitle {
        font-size: 16px;
    }

    .banner-right {
        flex: 0 0 300px;
    }

    .character {
        height: 180px;
    }
}

@media only screen and (max-width: 768px) {
    .banner-content {
        padding: 24px;
        flex-direction: column;
        text-align: center;
        gap: 24px;
    }

    .banner-left {
        width: 100%;
    }

    .banner-title {
        align-items: center;
        max-width: 100%;
    }

    .banner-title h1 {
        font-size: 32px;
    }

    .subtitle {
        font-size: 14px;
    }

    .rewards {
        justify-content: center;
    }

    .banner-right {
        flex: 0 0 150px;
        width: 100%;
    }

    .character {
        height: 150px;
        right: 50%;
        transform: translateX(50%);
    }

    .coin-1 { right: 60%; }
    .coin-2 { right: 50%; }
    .coin-3 { right: 40%; }
}

@media only screen and (max-width: 480px) {
    .banner-content {
        padding: 20px;
        min-height: 150px;
    }

    .banner-title h1 {
        font-size: 28px;
    }

    .subtitle {
        font-size: 12px;
    }

    .rewards {
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    .banner-right {
        flex: 0 0 120px;
    }

    .character {
        height: 120px;
    }

    .coin {
        width: 24px;
        height: 24px;
    }
}
</style>
