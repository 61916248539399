<template>
    <div class="stats-card" :class="{ highlight }">
        <div class="card-content">
            <transition name="fade" mode="out-in">
                <div v-if="loading" class="loading-state" key="loading"></div>
                <div v-else class="stat-info" key="content">
                    <span class="stat-label">{{ label }}</span>
                    <div class="stat-value">
                        <img v-if="showCoin" src="@/assets/img/icons/coin.svg" alt="coin" />
                        <span>{{ value }}</span>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AffiliatesStatsCard',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: [String, Number],
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        showCoin: {
            type: Boolean,
            default: false
        },
        highlight: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.stats-card {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                0px 1px 0px rgba(255, 255, 255, 0.3) inset;
    border-radius: 12px;
    overflow: hidden;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    transition: all 0.3s ease;
}

.stats-card:hover {
    transform: translateY(-1px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3),
                0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                0px 1px 0px rgba(255, 255, 255, 0.3) inset;
}

.card-content {
    padding: 20px;
}

.loading-state {
    height: 80px;
    background: linear-gradient(90deg, 
        rgba(255, 255, 255, 0.05) 0%, 
        rgba(255, 255, 255, 0.1) 50%, 
        rgba(255, 255, 255, 0.05) 100%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

.stat-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.stat-label {
    font-size: 12px;
    font-weight: 700;
    color: #8bacc8;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.stat-value {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.stat-value img {
    width: 24px;
    height: 24px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

@keyframes loading {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
}

@media only screen and (max-width: 768px) {
    .card-content {
        padding: 16px;
    }

    .stat-value {
        font-size: 20px;
    }

    .stat-value img {
        width: 20px;
        height: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .card-content {
        padding: 12px;
    }

    .stat-label {
        font-size: 11px;
    }

    .stat-value {
        font-size: 18px;
    }

    .stat-value img {
        width: 18px;
        height: 18px;
    }
}
</style> 