<template>
  <div class="modal-faq" :class="{ collapsed: isCollapsed }">
    <div class="faq-header">
      <span>FREQUENTLY ASKED QUESTIONS</span>
      <p>
        Here are some answers to frequently asked questions we have collected
        for you. New content is added regularly to help you quickly find answers. 
        You can also contact our support team directly through live chat.
      </p>
    </div>

    <div class="faq-categories">
      <button 
        v-for="category in categories" 
        :key="category.id"
        :class="['category-button', { active: activeCategory === category.id }]"
        @click="activeCategory = category.id"
      >
        {{ category.name }}
      </button>
    </div>

    <div class="faq-list">
      <div v-for="(faq, index) in filteredFaqs" :key="index">
        <FaqElement
          :title="faq.title"
          :text="faq.text"
          :category="faq.category"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FaqElement from "@/components/faq/FaqElement";

export default {
  name: "ModalFaq",
  components: {
    FaqElement,
  },
  data() {
    return {
      isCollapsed: false,
      activeCategory: 'general',
      categories: [
        { id: 'general', name: 'General' },
        { id: 'account', name: 'Account' },
        { id: 'games', name: 'Games' },
        { id: 'payments', name: 'Payments' },
        { id: 'vip', name: 'VIP' },
        { id: 'sports', name: 'Sports' },
        { id: 'token', name: 'Token' }
      ],
      faqs: [
        {
          category: 'general',
          title: "What is Bazed?",
          text: "Bazed is the pinnacle of Ethereum blockchain gaming, offering a comprehensive suite of casino games, sports betting, and futures trading. We provide a secure, transparent, and exciting gaming experience backed by blockchain technology."
        },
        {
          category: 'general',
          title: "What makes Bazed unique?",
          text: "Bazed stands out with over 14,000 games from 175+ providers, 300+ sports markets, and 300+ deposit methods including major cards and fiat currencies. Our platform combines traditional gaming with cryptocurrency benefits, featuring an industry-leading rakeback system and exclusive token utilities."
        },
        {
          category: 'games',
          title: "How many games do you offer?",
          text: "We offer over 14,000 games from 175+ leading providers, making us one of the largest gaming platforms globally. Our selection includes slots, table games, live casino experiences, and exclusive titles you won't find anywhere else."
        },
        {
          category: 'games',
          title: "Which game providers do you work with?",
          text: "We partner with 175+ top-tier game providers in the industry, ensuring a diverse and high-quality gaming experience. This includes industry leaders and innovative newcomers, all carefully selected to provide the best entertainment."
        },
        {
          category: 'sports',
          title: "How many sports can I bet on?",
          text: "We offer betting markets for over 300 different sports and e-sports, covering everything from major leagues to niche competitions. This includes live betting, pre-match odds, and special event markets."
        },
        {
          category: 'sports',
          title: "Do you offer live sports betting?",
          text: "Yes! We provide comprehensive live betting options across our 300+ sports markets with competitive odds, real-time updates, and instant bet settlement. Our platform ensures minimal delay for the best live betting experience."
        },
        {
          category: 'payments',
          title: "What payment methods do you accept?",
          text: "We offer 300+ deposit methods including all major credit cards, various fiat currencies, and cryptocurrencies. Our platform ensures fast, secure transactions with automatic processing and industry-leading withdrawal times."
        },
        {
          category: 'payments',
          title: "Which cryptocurrencies do you support?",
          text: "We support all major cryptocurrencies with a focus on Ethereum-based tokens. Our platform enables instant deposits and fast withdrawals, with special benefits for Bazed Token holders."
        },
        {
          category: 'payments',
          title: "How fast are withdrawals processed?",
          text: "Withdrawals are processed automatically and typically complete within 5-30 minutes depending on network congestion. VIP members get priority processing. We support multiple currencies and offer some of the highest limits in the industry."
        },
        {
          category: 'token',
          title: "What is the Bazed Token?",
          text: "The Bazed Token is our native cryptocurrency that unlocks exclusive benefits including enhanced cashback offers, rakeback boosts, special giveaways, and higher tier rankings. Token holders get access to unique rewards and platform privileges."
        },
        {
          category: 'token',
          title: "What are the benefits of holding Bazed Token?",
          text: "Bazed Token holders enjoy increased rakeback rates, exclusive giveaway access, boosted rewards, priority support, and special event participation. The more tokens you hold, the higher your potential benefits and platform status."
        },
        {
          category: 'vip',
          title: "How does the Rakeback system work?",
          text: "Our innovative rakeback system rewards players with up to 25% cashback on their gameplay. The rate increases with your tier level, and Bazed Token holders receive additional boosts. Rewards are distributed hourly, daily, weekly, and monthly for maximum benefits."
        },
        {
          category: 'vip',
          title: "What are the VIP tiers?",
          text: "Our VIP program features multiple tiers from Bronze to Legend, each offering increasingly valuable benefits. Progress through tiers by playing regularly and holding Bazed Tokens, unlocking better rakeback rates, exclusive promotions, and special privileges."
        },
        {
          category: 'vip',
          title: "What are the VIP benefits?",
          text: "Our VIP Program offers exclusive benefits including higher rakeback rates, priority withdrawals, personal account managers, custom promotions, and special event invitations. VIP status is earned through regular play and token holdings, with Legend tier members receiving truly exceptional privileges."
        },
        {
          category: 'account',
          title: "How do I start playing?",
          text: "Getting started is easy! Simply connect your Ethereum wallet, accept our terms of service, and you're ready to play. We recommend exploring our welcome bonus and rakeback system to maximize your rewards from day one."
        },
        {
          category: 'account',
          title: "Is there a welcome bonus?",
          text: "Yes! New players receive an attractive welcome package that includes bonus funds and free spins. Additionally, you'll immediately start earning rakeback rewards on your gameplay, with opportunities to boost your earnings through our tier system."
        },
        {
          category: 'games',
          title: "Are your games fair?",
          text: "Absolutely! All our games run on provably fair smart contracts, ensuring complete transparency and fairness. Our partnerships with 175+ licensed providers and blockchain technology guarantee a trustworthy gaming experience."
        },
        {
          category: 'general',
          title: "What makes your platform secure?",
          text: "We utilize advanced blockchain technology, secure smart contracts, and industry-standard encryption to protect your funds and data. Our platform undergoes regular security audits and operates with full transparency."
        },
        {
          category: 'general',
          title: "How can I contact support?",
          text: "Our support team is available 24/7 through live chat. VIP members get priority support with faster response times. We're committed to providing quick, helpful assistance for any questions or concerns."
        },
        {
          category: 'vip',
          title: "How can I earn more rewards?",
          text: "Maximize your rewards by holding Bazed Tokens, participating in regular gameplay, and climbing our tier system. Each tier offers increased benefits, and you can boost your earnings through our rakeback system, special events, and exclusive giveaways."
        }
      ]
    };
  },
  computed: {
    filteredFaqs() {
      return this.faqs.filter(faq => faq.category === this.activeCategory);
    }
  }
};
</script>

<style scoped>
.modal-faq {
  width: 1000px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 15px;
  background: var(--background-secondary);
  transition: all 0.3s ease;
}

.faq-header {
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.faq-header span {
  display: block;
  font-size: 32px;
  font-weight: 900;
  color: white;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
}

.faq-header p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.6;
}

.faq-categories {
  display: flex;
  gap: 8px;
  padding: 24px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.category-button {
  padding: 12px 24px;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.category-button:hover {
  filter: brightness(1.2);
}

.category-button.active {
  background: linear-gradient(90deg, var(--highlight-blue) 0%, #1D43C0 100%);
  color: white;
  box-shadow: 0 0 15px rgba(44, 94, 255, 0.3);
}

.faq-list {
  height: 600px;
  padding: 24px 10px 24px 0;
  overflow-y: auto;
}

.faq-list::-webkit-scrollbar {
  width: 5px;
}

.faq-list::-webkit-scrollbar-track {
  background: var(--background-primary);
  border-radius: 2.5px;
}

.faq-list::-webkit-scrollbar-thumb {
  background: var(--highlight-blue);
  border-radius: 2.5px;
}

@media only screen and (max-width: 1190px) {
  .modal-faq {
    width: calc(100vw - 20px);
  }
  
  .faq-categories {
    overflow-x: auto;
    padding-bottom: 16px;
  }
  
  .category-button {
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 700px) {
  .modal-faq {
    padding: 20px;
  }
  
  .faq-header span {
    font-size: 24px;
  }
  
  .faq-header p {
    font-size: 12px;
  }
  
  .category-button {
    padding: 8px 16px;
    font-size: 12px;
  }
}
</style>
