<template>
  <div class="promotion-details">
    <div class="promotion-container">
      <transition name="fade" mode="out-in">
        <!-- Loading State -->
        <div v-if="loading" class="content-loading" key="loading">
          <div class="loading-spinner"></div>
          <p>Loading promotion details...</p>
        </div>

        <!-- Error State -->
        <div v-else-if="error" class="content-error" key="error">
          <div class="error-content">
            <span class="error-icon">⚠️</span>
            <p class="error-message">{{ error }}</p>
            <button @click="fetchPromotion" class="retry-btn">
              Try Again
            </button>
          </div>
        </div>

        <!-- Content State -->
        <div v-else-if="promotion" class="content-details" key="content">
          <div class="details-header">
            <button class="back-btn" @click="$router.push('/promotions')">
              <span class="back-icon">←</span>
              Back
            </button>
            <div class="header-badges">
              <span class="promo-type" :class="promotion.type.toLowerCase()">
                {{ formatType(promotion.type) }}
              </span>
              <span class="promo-status" :class="{ active: isActive(promotion) }">
                {{ isActive(promotion) ? 'Active' : 'Ended' }}
              </span>
            </div>
          </div>

          <div class="details-hero">
            <div class="hero-content">
              <h1>{{ promotion.title }}</h1>
              <p class="validity-date">
                <span class="date-icon">🕒</span>
                Valid until {{ formatDate(promotion.validUntil) }}
              </p>
            </div>
            <div class="hero-image" :style="{ backgroundImage: `url(${promotion.image})` }">
              <div class="hero-overlay">
                <button 
                  class="claim-btn" 
                  :disabled="!isActive(promotion)"
                  @click="handleClaimClick"
                >
                  <span v-if="isActive(promotion)">
                    {{ isAuthenticated ? 'Claim Now' : 'Register to Claim' }}
                  </span>
                  <span v-else>Promotion Ended</span>
                </button>
              </div>
            </div>
          </div>

          <div class="details-content">
            <!-- Quick Info Section -->
            <div class="quick-info">
              <div class="info-card reward">
                <span class="info-icon">🎁</span>
                <div class="info-text">
                  <span class="info-label">Reward</span>
                  <span class="info-value">{{ promotion.reward.amount }}{{ promotion.reward.type === 'BONUS' ? '%' : '' }} {{ promotion.reward.currency }}</span>
                </div>
              </div>
              <div class="info-card deposit">
                <span class="info-icon">💰</span>
                <div class="info-text">
                  <span class="info-label">Min. Deposit</span>
                  <span class="info-value">${{ promotion.requirements.minDeposit }}</span>
                </div>
              </div>
              <div class="info-card wagering">
                <span class="info-icon">🔄</span>
                <div class="info-text">
                  <span class="info-label">Wagering</span>
                  <span class="info-value">{{ promotion.requirements.wagering }}x</span>
                </div>
              </div>
            </div>

            <!-- Description Section -->
            <div class="description-section">
              <h2>About This Promotion</h2>
              <div class="description">
                <p>{{ promotion.fullDescription }}</p>
              </div>
            </div>

            <!-- Requirements Section -->
            <div class="requirements-section">
              <h2>Requirements</h2>
              <div class="requirements-grid">
                <div class="requirement-item">
                  <span class="requirement-icon">💰</span>
                  <div class="requirement-content">
                    <span class="requirement-label">Minimum Deposit</span>
                    <span class="requirement-value">${{ promotion.requirements.minDeposit }}</span>
                  </div>
                </div>
                <div class="requirement-item" v-if="promotion.requirements.minLevel > 0">
                  <span class="requirement-icon">⭐</span>
                  <div class="requirement-content">
                    <span class="requirement-label">Minimum Level</span>
                    <span class="requirement-value">Level {{ promotion.requirements.minLevel }}</span>
                  </div>
                </div>
                <div class="requirement-item">
                  <span class="requirement-icon">🔄</span>
                  <div class="requirement-content">
                    <span class="requirement-label">Wagering Requirement</span>
                    <span class="requirement-value">{{ promotion.requirements.wagering }}x</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Game Restrictions Section -->
            <div class="game-restrictions-section" v-if="promotion.requirements.gameRestrictions">
              <h2>Game Restrictions</h2>
              <div class="restrictions-grid">
                <div class="restriction-item">
                  <span class="restriction-icon">🎮</span>
                  <div class="restriction-content">
                    <span class="restriction-label">Game Type</span>
                    <span class="restriction-value">{{ formatGameType(promotion.requirements.gameRestrictions.type) }}</span>
                  </div>
                </div>
                <div class="restriction-item" v-if="promotion.requirements.gameRestrictions.providers.length">
                  <span class="restriction-icon">🏢</span>
                  <div class="restriction-content">
                    <span class="restriction-label">Eligible Providers</span>
                    <div class="providers-list">
                      <span v-for="provider in promotion.requirements.gameRestrictions.providers" 
                            :key="provider" 
                            class="provider-tag">
                        {{ provider }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="restriction-item" v-if="promotion.requirements.gameRestrictions.specificGames.length">
                  <span class="restriction-icon">✅</span>
                  <div class="restriction-content">
                    <span class="restriction-label">Eligible Games</span>
                    <div class="games-list">
                      <span v-for="game in promotion.requirements.gameRestrictions.specificGames" 
                            :key="game" 
                            class="game-tag">
                        {{ game }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="restriction-item" v-if="promotion.requirements.gameRestrictions.excludedGames.length">
                  <span class="restriction-icon">❌</span>
                  <div class="restriction-content">
                    <span class="restriction-label">Excluded Games</span>
                    <div class="games-list">
                      <span v-for="game in promotion.requirements.gameRestrictions.excludedGames" 
                            :key="game" 
                            class="game-tag excluded">
                        {{ game }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Terms Section -->
            <div class="terms-section">
              <h2>Terms & Conditions</h2>
              <div class="terms-list">
                <div class="term-item" v-for="(term, index) in promotion.terms" :key="index">
                  <span class="term-bullet">•</span>
                  <span class="term-text">{{ term }}</span>
                </div>
                <div class="terms-footer">
                  <p>For complete terms and conditions, please read our 
                    <a href="#" @click.prevent="openTermsModal">Terms and Conditions</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PromotionDetails',
  
  data() {
    return {
      loading: false,
      error: null
    }
  },

  computed: {
    ...mapGetters([
      'socketGeneral',
      'authToken',
      'promotionsList',
      'promotionsLoading',
      'promotionsClaims',
      'isAuthenticated'
    ]),
    promotion() {
      return this.promotionsList?.find(p => p._id === this.$route.params.id) || null;
    },
    hasClaimed() {
      if (!this.isAuthenticated || !this.promotionsClaims) return false;
      return this.promotionsClaims.some(claim => 
        claim.promotion._id === this.$route.params.id && 
        ['claimed', 'activated'].includes(claim.status)
      );
    }
  },

  methods: {
    ...mapActions([
      'notificationShow',
      'socketConnectGeneral',
      'promotionsGetList',
      'promotionsGetClaims',
      'promotionsClaim',
      'modalsSetShow',
      'modalsSetData'
    ]),
    handleClaimClick() {
      if (!this.isAuthenticated) {
        this.modalsSetData({ modalTab: 'register' });
        this.modalsSetShow('Login');
        return;
      }

      // For affiliate deposit bonus, redirect to deposit page
      if (this.promotion.type === 'AFFILIATE_DEPOSIT_BONUS') {
        this.$router.push('/deposit');
        return;
      }

      this.promotionsClaim(this.promotion._id);
    },
    async claimPromotion() {
      if (!this.promotion) {
        this.notificationShow({
          type: 'error',
          message: 'Promotion not found'
        });
        return;
      }

      await this.promotionsClaim(this.promotion._id);
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    },
    formatType(type) {
      const typeMap = {
        'DEPOSIT_BONUS': 'Deposit Bonus',
        'LEADERBOARD': 'Leaderboard',
        'BLACK_FRIDAY': 'Black Friday',
        'EVENT': 'Event',
        'NEWS': 'News',
        'AFFILIATE_DEPOSIT_BONUS': 'Affiliate Bonus'
      };
      return typeMap[type] || type;
    },
    isActive(promotion) {
      return promotion.active && new Date(promotion.validUntil) > new Date();
    },
    formatGameType(type) {
      if (!type) return 'All Games';
      return type.replace(/_/g, ' ').toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    openTermsModal() {
      this.modalsSetShow('Terms');
    }
  },

  async created() {
    this.loading = true;
    try {
      // Get promotions list for all users
      await this.promotionsGetList();

      // Only connect socket and get claims if user is authenticated
      if (this.isAuthenticated) {
        if (!this.socketGeneral?.connected) {
          await this.socketConnectGeneral();
        }
        await this.promotionsGetClaims();
      }
    } catch (error) {
      this.error = error.message || 'Failed to load promotion details';
      this.notificationShow({
        type: 'error',
        message: this.error
      });
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.promotion-details {
  width: 100%;
  padding: 32px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.promotion-container {
  width: 100%;
  max-width: 800px;
}

/* Header Styles */
.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.back-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(44, 94, 255, 0.1);
  border: 1px solid rgba(44, 94, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
}

.back-btn:hover {
  background: rgba(44, 94, 255, 0.2);
  transform: translateX(-4px);
}

.header-badges {
  display: flex;
  gap: 8px;
}

.promo-type,
.promo-status {
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.promo-type {
  background: rgba(44, 94, 255, 0.15);
  color: #fff;
  border: 1px solid rgba(44, 94, 255, 0.3);
}

.promo-status {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.promo-status.active {
  background: rgba(0, 170, 109, 0.1);
  color: #00AA6D;
  border: 1px solid rgba(0, 170, 109, 0.3);
}

/* Hero Section */
.details-hero {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 24px;
}

.hero-content {
  padding: 32px;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 2;
}

.hero-content h1 {
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 16px;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.5px;
  line-height: 1.2;
}

.validity-date {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.hero-image {
  width: 100%;
  height: 320px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 32px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.claim-btn {
  padding: 12px 32px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(180deg, #2C5EFF 0%, #1F4BCC 100%);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.claim-btn:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(44, 94, 255, 0.3);
}

.claim-btn:disabled {
  background: linear-gradient(180deg, #4A4A4A 0%, #2A2A2A 100%);
  cursor: not-allowed;
  opacity: 0.7;
}

/* Quick Info Section */
.quick-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 32px;
}

.info-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.info-icon {
  font-size: 24px;
}

.info-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info-label {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.info-value {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

/* Content Sections */
.details-content {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 32px;
}

.description-section,
.requirements-section,
.terms-section {
  margin-bottom: 32px;
}

.description-section:last-child,
.requirements-section:last-child,
.terms-section:last-child {
  margin-bottom: 0;
}

h2 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin: 0 0 16px;
  letter-spacing: -0.3px;
}

.description {
  font-size: 15px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.2px;
}

/* Requirements Grid */
.requirements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.requirement-item {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.requirement-icon {
  font-size: 20px;
}

.requirement-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.requirement-label {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.requirement-value {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}

/* Terms List */
.terms-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.term-item {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.term-bullet {
  color: rgba(44, 94, 255, 0.8);
  font-size: 18px;
  line-height: 1.4;
}

.term-text {
  flex: 1;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.2px;
}

/* Loading & Error States */
.content-loading,
.content-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  text-align: center;
  gap: 16px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  padding: 32px;
}

.loading-spinner {
  width: 32px;
  height: 32px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-top-color: rgba(44, 94, 255, 0.8);
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

/* Animations */
@keyframes spin {
  to { transform: rotate(360deg); }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .promotion-details {
    padding: 24px 16px;
  }

  .details-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .hero-content {
    padding: 24px;
  }

  .hero-content h1 {
    font-size: 26px;
  }

  .hero-image {
    height: 280px;
  }

  .hero-overlay {
    padding: 24px;
  }

  .claim-btn {
    padding: 10px 24px;
    font-size: 14px;
  }

  .quick-info {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .details-content {
    padding: 24px;
  }

  .requirements-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .promotion-details {
    padding: 20px 12px;
  }

  .hero-content h1 {
    font-size: 22px;
  }

  .hero-image {
    height: 240px;
  }

  .hero-overlay {
    padding: 20px;
  }

  .claim-btn {
    padding: 8px 20px;
    font-size: 13px;
  }

  .details-content {
    padding: 20px;
  }

  .info-card {
    padding: 12px;
  }

  .info-icon {
    font-size: 20px;
  }

  .info-value {
    font-size: 14px;
  }

  h2 {
    font-size: 18px;
  }

  .description {
    font-size: 14px;
  }

  .term-text {
    font-size: 13px;
  }
}

.game-restrictions-section {
  margin-bottom: 32px;
}

.restrictions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
}

.restriction-item {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.restriction-icon {
  font-size: 20px;
}

.restriction-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.restriction-label {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.restriction-value {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.providers-list,
.games-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.provider-tag,
.game-tag {
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(44, 94, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(44, 94, 255, 0.2);
}

.game-tag.excluded {
  background: rgba(255, 59, 48, 0.1);
  border-color: rgba(255, 59, 48, 0.2);
  color: #ff3b30;
}

.terms-footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.terms-footer p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.terms-footer a {
  color: var(--highlight-blue);
  text-decoration: none;
  font-weight: 500;
}

.terms-footer a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .restrictions-grid {
    grid-template-columns: 1fr;
  }
}
</style> 