// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/coinflip/coinflip-background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.modal-coinflip-game[data-v-3fb83338] {\n  width: 1210px;\n  position: relative;\n  display: flex;\n  align-items: flex-start;\n  padding: 12px;\n  border-radius: 16px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: center;\n  box-shadow:\n    0px 2px 0px 0px rgba(255, 255, 255, 0.2) inset,\n    0px -2px 0px 0px rgba(0, 0, 0, 0.25) inset;\n}\n.modal-coinflip-game .game-content[data-v-3fb83338] {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  padding: 13px 325px 13px 13px;\n}\n@media only screen and (max-width: 1230px) {\n.modal-coinflip-game[data-v-3fb83338] {\n    width: calc(100vw - 20px);\n}\n}\n@media only screen and (max-width: 1050px) {\n.modal-coinflip-game .game-content[data-v-3fb83338] {\n    width: 100%;\n    padding: 8px 0 325px 0;\n}\n}\n", ""]);
// Exports
module.exports = exports;
