<template>
  <div class="blackjack">
    <transition name="slide-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Blackjack",
  metaInfo: {
    title: "Blackjack - Bazedbet",
  },
  methods: {
    ...mapActions(["socketConnectBlackjack", "socketDisconnectBlackjack"]),
  },
  created() {
    this.socketConnectBlackjack();
  },
  beforeRouteLeave(to, from, next) {
    this.socketDisconnectBlackjack();
    next();
  },
};
</script>

<style scoped>
.blackjack {
  width: 100%;
  background-image: url("../../assets/img/blackjack/blackjack-lobby-background.png");
  background-size: cover;
  background-position: center;
}

.blackjack .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.blackjack .slide-fade-enter {
  opacity: 0;
}
</style>
