<template>
  <div
    class="battles-game-element"
    v-bind:class="[
      'element-' + game.state,
      'element-' + game.mode,
      {
        'element-cursed': game.options.cursed === true,
        'element-terminal': game.options.terminal === true,
      },
    ]"
  >
    <div class="element-inner">
      <div class="inner-info">
        <div class="info-type">
          <div class="type-inner">
            <span>{{ battlesGetMode }}</span>
          </div>
        </div>
        <div
          v-if="game.options.cursed === true || game.options.terminal === true"
          class="info-option"
        >
          <div class="option-inner">
            <span>{{ battlesGetOption }}</span>
          </div>
        </div>
      </div>
      <div class="inner-players">
        <div
          v-for="(bet, index) in battlesGetBets"
          v-bind:key="index"
          class="players-element"
        >
          <div
            class="element-user"
            v-bind:class="[
              'user-' +
                (bet === null
                  ? 'empty'
                  : bet.bot === true
                    ? 'bot'
                    : battlesGetRank(bet.user)),
              'user-' +
                (bet !== null && bet.bot !== true
                  ? battlesGetLevelColor(bet.user)
                  : ''),
              { 'user-winner': game.state === 'completed' && bet.payout > 0 },
            ]"
          >
            <AvatarImage
              v-if="bet !== null"
              v-bind:image="bet.bot === true ? null : bet.user.avatar"
            />
            <IconSpinner v-else />
          </div>
          <div
            v-if="
              index + 1 !== game.playerCount &&
              (game.mode !== 'team' || index === 1)
            "
            class="element-separator"
          >
            <div class="separator-inner">
              <svg
                v-if="game.mode === 'group'"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8972 8.01057L10.7603 6.87354L8.40832 9.22551C7.98847 9.64535 7.46152 9.91908 6.88916 10.0215L8.38772 11.5201C8.52495 11.6574 8.74759 11.6574 8.88483 11.5201L11.8972 8.50777C12.0346 8.37044 12.0346 8.1478 11.8972 8.01057Z"
                  fill="var(--highlight-blue)"
                />
                <path
                  d="M10.2964 6.3429C10.6927 5.94668 10.6928 5.3019 10.2969 4.9054C10.2965 4.90495 10.296 4.90449 10.2955 4.90403C10.1976 4.80617 10.0832 4.73073 9.95855 4.68029C10.0666 4.40701 10.0894 3.94488 9.73151 3.58692C9.7306 3.58601 9.72968 3.585 9.72867 3.58408C9.63118 3.48723 9.51747 3.41252 9.3937 3.36235C9.50173 3.08927 9.52461 2.62704 9.16693 2.26927C9.16666 2.26899 9.16648 2.26881 9.16629 2.26862C9.06824 2.17058 8.95372 2.09496 8.82894 2.04451C8.93696 1.77124 8.95976 1.30901 8.6019 0.951059C8.6018 0.951059 8.6018 0.950967 8.60171 0.950967C8.60162 0.950876 8.60153 0.950784 8.60144 0.950693C8.20494 0.554196 7.55989 0.554196 7.16339 0.950693L6.49683 1.61717L6.83767 1.958C7.40563 2.52597 7.40563 3.45006 6.83776 4.01802C6.6466 4.20909 6.41178 4.34037 6.15581 4.40289C6.09457 4.65246 5.96676 4.88902 5.77204 5.08365C5.58116 5.27453 5.34671 5.40572 5.09101 5.46834C5.02848 5.72394 4.8972 5.95849 4.70642 6.14927C4.51554 6.34015 4.28108 6.47134 4.02539 6.53396C3.96286 6.78956 3.83158 7.02411 3.64079 7.21499C3.36569 7.49 2.99986 7.6416 2.61078 7.6416C2.2217 7.6416 1.85587 7.49009 1.58077 7.2149L1.23993 6.87406L0.102992 8.01109C-0.0343307 8.14842 -0.0343307 8.37097 0.102992 8.50829L3.1153 11.5206C3.25263 11.6579 3.47518 11.6578 3.6125 11.5206L5.76884 9.36436H6.37635C6.95585 9.36436 7.50066 9.13861 7.91043 8.72893L10.2964 6.3429Z"
                  fill="var(--highlight-blue)"
                />
                <path
                  d="M2.07826 6.71721C2.37249 7.01153 2.84964 7.01153 3.14388 6.71721C3.43821 6.42297 3.43821 5.94591 3.14388 5.65158C3.43821 5.94591 3.91527 5.94591 4.2096 5.65158C4.50383 5.35734 4.50383 4.88019 4.2096 4.58596C4.50383 4.88019 4.98098 4.88019 5.27522 4.58596C5.56946 4.29172 5.56946 3.81457 5.27522 3.52033C5.56946 3.81457 6.04661 3.81457 6.34085 3.52033C6.63517 3.226 6.63517 2.74894 6.34085 2.45462L4.83835 0.952123C4.54411 0.657886 4.06696 0.657886 3.77273 0.952123C3.47849 1.24636 3.47849 1.72351 3.77273 2.01775L3.96095 2.20606C3.66671 1.91173 3.18956 1.91173 2.89533 2.20606C2.60109 2.5003 2.60109 2.97745 2.89533 3.27169L3.27187 3.64823C2.97763 3.35399 2.50048 3.35399 2.20624 3.64823C1.91191 3.94246 1.91191 4.41961 2.20624 4.71385L2.58278 5.09048C2.28845 4.79615 1.81139 4.79615 1.51706 5.09048C1.22283 5.38472 1.22283 5.86178 1.51706 6.1561L2.07826 6.71721Z"
                  fill="var(--highlight-blue)"
                />
                <path
                  d="M6.66638 0.452784C6.71838 0.400785 6.7734 0.352996 6.83062 0.308595L6.625 0.102977C6.51807 -0.00404281 6.35448 -0.0305003 6.21926 0.0370625L5.35156 0.470911L5.99991 1.11935L6.66638 0.452784Z"
                  fill="var(--highlight-blue)"
                />
              </svg>
              <svg
                v-else
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.32477 6.35167L5.43975 8.46785L4.59412 9.31408L5.44095 10.1609L4.59472 11.0071L3.11351 9.52593L1.42045 11.219L0.574219 10.3728L2.26728 8.6791L0.786076 7.1985L1.63231 6.35227L2.47854 7.1979L3.32477 6.35167ZM0.900982 0.124023L3.02314 0.125819L10.0952 7.1985L10.9421 6.35227L11.7883 7.1985L10.3077 8.6797L12.0001 10.3728L11.1539 11.219L9.46085 9.52593L7.97965 11.0071L7.13341 10.1609L7.97965 9.31408L0.902777 2.23721L0.900982 0.124023ZM9.55301 0.124023L11.6734 0.125819L11.6746 2.23422L9.24899 4.6592L7.13282 2.54362L9.55301 0.124023Z"
                  fill="var(--highlight-blue)"
                />
                <path
                  d="M3.32477 6.35167L5.43975 8.46785L4.59412 9.31408L5.44095 10.1609L4.59472 11.0071L3.11351 9.52593L1.42045 11.219L0.574219 10.3728L2.26728 8.6791L0.786076 7.1985L1.63231 6.35227L2.47854 7.1979L3.32477 6.35167ZM0.900982 0.124023L3.02314 0.125819L10.0952 7.1985L10.9421 6.35227L11.7883 7.1985L10.3077 8.6797L12.0001 10.3728L11.1539 11.219L9.46085 9.52593L7.97965 11.0071L7.13341 10.1609L7.97965 9.31408L0.902777 2.23721L0.900982 0.124023ZM9.55301 0.124023L11.6734 0.125819L11.6746 2.23422L9.24899 4.6592L7.13282 2.54362L9.55301 0.124023Z"
                  fill="var(--highlight-blue)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="inner-cases">
        <BattlesGameBoxes v-bind:game="game" />
      </div>
      <div class="inner-right">
        <div class="right-amount">
          <div class="amount-inner">
            <div class="inner-effective">
              <div class="effective-value">
                <span>{{
                  battlesFormatValue(
                    game.amount -
                      Math.floor((game.amount * game.options.funding) / 100)
                  ).split(".")[0]
                }}</span
                >.{{
                  battlesFormatValue(
                    game.amount -
                      Math.floor((game.amount * game.options.funding) / 100)
                  ).split(".")[1]
                }}
              </div>
            </div>
            <div v-if="game.options.funding > 0" class="inner-real">
              <div class="real-value">
                <span>{{ battlesFormatValue(game.amount).split(".")[0] }}</span
                >.{{ battlesFormatValue(game.amount).split(".")[1] }}
              </div>
            </div>
          </div>
        </div>
        <div class="right-action">
          <button
            v-if="
              ['countdown', 'pending', 'rolling'].includes(game.state) ===
                true ||
              (game.state !== 'completed' &&
                authUser.user !== null &&
                authUser.user._id === game.bets[0].user._id)
            "
            class="button-waiting"
            disabled
          >
            <div class="button-inner">
              <IconSpinner />
            </div>
          </button>
          <button
            v-else-if="game.state !== 'completed'"
            v-on:click="battlesJoinButton()"
            class="button-join"
            v-bind:disabled="socketSendLoading !== null"
          >
            <div v-if="game.options.funding > 0" class="button-funding">
              <div class="funding-inner">
                <span>-{{ game.options.funding }}%</span>
              </div>
            </div>
            <div class="button-inner">
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.47861 7.85824L6.15342 10.5285L5.08395 11.5963L6.15493 12.6649L5.08471 13.7327L3.21144 11.8636L1.07023 14L0 12.9322L2.14121 10.7951L0.267935 8.9268L1.33816 7.859L2.40839 8.92605L3.47861 7.85824ZM0.413256 0L3.09715 0.00226551L12.0412 8.9268L13.1122 7.859L14.1824 8.9268L12.3099 10.7958L14.4503 12.9322L13.3801 14L11.2389 11.8636L9.36561 13.7327L8.29539 12.6649L9.36561 11.5963L0.415526 2.66649L0.413256 0ZM11.3554 0L14.0371 0.00226551L14.0386 2.66271L10.971 5.72264L8.29463 3.05313L11.3554 0Z"
                />
              </svg>
              <div class="inner-text">
                JOIN
                <span v-if="game.options.levelMin > 0"
                  >LVL +{{ game.options.levelMin }}</span
                >
              </div>
            </div>
          </button>
          <router-link v-bind:to="'/battles/' + game._id" class="link-view">
            <div class="link-inner">
              <svg
                width="19"
                height="11"
                viewBox="0 0 19 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.8581 4.63847C18.6885 4.44929 14.6093 0 9.49998 0C4.39071 0 0.311514 4.44929 0.141887 4.63847C-0.0472957 4.84992 -0.0472957 5.16958 0.141887 5.38103C0.311514 5.57021 4.39078 10.0195 9.49998 10.0195C14.6092 10.0195 18.6885 5.57021 18.8581 5.38103C19.0472 5.16958 19.0472 4.84992 18.8581 4.63847ZM9.49998 8.90622C7.35169 8.90622 5.60351 7.15804 5.60351 5.00975C5.60351 2.86146 7.35169 1.11328 9.49998 1.11328C11.6483 1.11328 13.3965 2.86146 13.3965 5.00975C13.3965 7.15804 11.6483 8.90622 9.49998 8.90622Z"
                />
                <path
                  d="M10.0566 3.89648C10.0566 3.3365 10.3346 2.84387 10.7575 2.54088C10.378 2.34661 9.95476 2.22656 9.49999 2.22656C7.96541 2.22656 6.7168 3.47518 6.7168 5.00976C6.7168 6.54433 7.96541 7.79295 9.49999 7.79295C10.8739 7.79295 12.0111 6.78974 12.2358 5.47889C11.1148 5.83978 10.0566 4.99202 10.0566 3.89648Z"
                />
              </svg>
              {{ game.state === "completed" ? "REPLAY" : "" }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AvatarImage from "@/components/AvatarImage";
import IconTerminalGradient from "@/components/icons/IconTerminalGradient";
import IconCursedGradient from "@/components/icons/IconCursedGradient";
import IconVersusGradient from "@/components/icons/IconVersusGradient";
import IconGroupGradient from "@/components/icons/IconGroupGradient";
import IconSpinner from "@/components/icons/IconSpinner";
import BattlesGameBoxes from "@/components/battles/BattlesGameBoxes";

export default {
  name: "BattlesGameElement",
  props: ["game"],
  components: {
    AvatarImage,
    IconTerminalGradient,
    IconCursedGradient,
    IconVersusGradient,
    IconGroupGradient,
    IconSpinner,
    BattlesGameBoxes,
  },
  methods: {
    ...mapActions(["notificationShow", "battlesSendJoinSocket"]),
    battlesFormatValue(value) {
      return parseFloat(Math.floor(value / 10) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    battlesGetRank(user) {
      let rank = user.rakeback;

      if (user.rank !== "user") {
        rank = user.rank;
      }

      return rank;
    },
    battlesGetLevelColor(user) {
      let color = "";

      if (user.level >= 2 && user.level < 26) {
        color = "blue";
      } else if (user.level >= 26 && user.level < 51) {
        color = "green";
      } else if (user.level >= 51 && user.level < 76) {
        color = "orange";
      } else if (user.level >= 76 && user.level < 100) {
        color = "red";
      } else if (user.level >= 100) {
        color = "purple";
      }

      return color;
    },
    battlesJoinButton() {
      if (this.authUser.user === null) {
        this.notificationShow({
          type: "error",
          message: "Please sign in to perform this action.",
        });
        return;
      }

      const data = { gameId: this.game._id, slot: this.game.bets.length };
      this.battlesSendJoinSocket(data);
    },
  },
  computed: {
    ...mapGetters(["socketSendLoading", "authUser"]),
    battlesGetMode() {
      let mode = "1v1";

      if (this.game.mode === "group") {
        mode = "Group";
      } else if (this.game.mode === "team") {
        mode = "2v2";
      } else if (this.game.playerCount === 3) {
        mode = "1v1v1";
      } else if (this.game.playerCount === 4) {
        mode = "1v1v1v1";
      }

      return mode;
    },
    battlesGetOption() {
      let option = "";

      if (
        this.game.options.cursed === true &&
        this.game.options.terminal === false
      ) {
        option = "CURSED";
      } else if (
        this.game.options.cursed === false &&
        this.game.options.terminal === true
      ) {
        option = "TERMINAL";
      }

      return option;
    },
    battlesGetBets() {
      let bets = [];

      for (let bet = 0; bet < this.game.playerCount; bet++) {
        const index = this.game.bets.findIndex(
          (element) => element.slot === bet
        );

        bets.push(index !== -1 ? this.game.bets[index] : null);
      }

      return bets;
    },
    battlesGetBoxes() {
      let boxes = [];

      for (const box of this.game.boxes) {
        for (let i = 0; i < box.count; i++) {
          boxes.push(box.box);
        }
      }

      return boxes;
    },
  },
};
</script>

<style scoped>
.battles-game-element {
  width: 100%;
  margin-top: 8px;
  padding: 1px;
}

.battles-game-element:first-child {
  margin-top: 0;
}

.battles-game-element .element-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.battles-game-element .inner-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  border: 2px solid rgba(255, 255, 255, 0.08);
  background: #161c2c;
  margin-right: 8px;
}

.battles-game-element .info-type,
.battles-game-element .info-option {
  width: 70px;
  height: 35px;
  position: relative;
  padding: 1px;
  z-index: 1;
}

.battles-game-element .type-inner,
.battles-game-element .option-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.battles-game-element .type-inner {
  color: rgba(255, 255, 255, 0.37);
  font-family: Montserrat;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.battles-game-element.element-cursed.element-terminal
  .option-inner
  svg:nth-child(2) {
  margin-right: 0;
}

.battles-game-element .inner-players {
  width: 315px;
  display: flex;
  align-items: center;
  padding-right: 32px;
}

.battles-game-element .players-element {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.battles-game-element .players-element:last-child {
  margin-right: 0;
}

.battles-game-element .element-user {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 50%;
  background: #041b2d;
  border: 1px solid #9e9e9e;
  overflow: hidden;
}

.battles-game-element.element-completed .element-user {
  opacity: 0.35;
  mix-blend-mode: luminosity;
}

.battles-game-element.element-completed .element-user.user-winner {
  opacity: 1;
  mix-blend-mode: normal;
}

.battles-game-element .element-user.user-empty {
  align-items: center;
  border: 1px solid #134166;
}

.battles-game-element .element-user.user-blue {
  border: 1px solid #559ee4;
}

.battles-game-element .element-user.user-green {
  border: 1px solid #b8e92d;
}

.battles-game-element .element-user.user-orange {
  border: 1px solid #fca311;
}

.battles-game-element .element-user.user-red {
  border: 1px solid #ff4e4e;
}

.battles-game-element .element-user.user-purple {
  border: 1px solid #6953f1;
}

.battles-game-element .element-user.user-partner {
  border: 1px solid #eca822;
}

.battles-game-element .element-user.user-mod {
  border: 1px solid #ffb703;
}

.battles-game-element .element-user.user-bot,
.battles-game-element .element-user.user-admin {
  border: 1px solid #00ffc2;
}

.battles-game-element .element-user.user-empty svg {
  width: 14px;
  fill: #134166;
  animation: waiting_animation 1.5s infinite linear both;
}

.battles-game-element .element-user .avatar-image {
  width: 100%;
  height: 100%;
}

.battles-game-element .element-separator {
  width: 29px;
  height: 23px;
  position: relative;
  margin-left: 10px;
  padding: 1px;
  z-index: 1;
}

.battles-game-element .separator-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.battles-game-element .inner-cases {
  width: calc(100% - 825px);
}

.battles-game-element .inner-right {
  width: 375px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
}

.battles-game-element .right-amount {
  position: relative;
}

.battles-game-element .amount-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 0;
}

.battles-game-element .inner-effective,
.battles-game-element .inner-real {
  display: flex;
  align-items: center;
}

.battles-game-element .inner-real {
  margin-top: 2px;
}

.battles-game-element .inner-real img {
  width: 13px;
  height: 13px;
  margin-right: 6px;
  opacity: 0.5;
}

.battles-game-element .effective-value {
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #bbbfd0;
}

.battles-game-element .real-value {
  position: relative;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #83919c;
}

.battles-game-element .effective-value span {
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #ffffff;
}

.battles-game-element .real-value span {
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.battles-game-element .right-action {
  display: flex;
  align-items: center;
}

.battles-game-element button.button-waiting,
.battles-game-element button.button-join {
  width: 96px;
  height: 39px;
  position: relative;
  margin-right: 12px;
  padding: 1px;
}

.battles-game-element a.link-view {
  height: 39px;
  transition: all 0.2s;
}

.battles-game-element a.link-view:active {
  transition-duration: 0.2s;
  scale: 0.9;
}

.battles-game-element .button-funding {
  width: 48px;
  height: 21px;
  position: absolute;
  top: -9px;
  left: -7px;
  transform: rotate(-10deg);
  padding: 1px;
  z-index: 1;
}

.battles-game-element .funding-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      242deg,
      rgba(0, 255, 194, 0.4) 16%,
      rgba(0, 231, 170, 0.22) 32%,
      rgba(0, 218, 157, 0.1) 50%,
      rgba(0, 195, 134, 0.26) 80%,
      rgba(0, 170, 109, 0.44) 100%
    ),
    linear-gradient(180deg, #073137 0%, #082538 100%);
}

.battles-game-element .funding-inner span {
  font-size: 11px;
  font-weight: 800;
  background: linear-gradient(239deg, #00ffc2 -100%, #00aa6d 120%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.battles-game-element button.button-waiting .button-inner,
.battles-game-element button.button-join .button-inner,
.battles-game-element a.link-view .link-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  color: #ffffff;
  border-radius: 8px;
}

.battles-game-element button.button-waiting .button-inner {
  background: linear-gradient(
      255deg,
      rgba(0, 255, 194, 0.15) 0%,
      rgba(0, 170, 109, 0.15) 100%
    ),
    #051f35;
}

.battles-game-element button.button-join .button-inner {
  background: var(--highlight-blue);
}

.battles-game-element a.link-view .link-inner {
  font-size: 13px;
  font-weight: 700;
  color: #5e768e;
  transition: color 0.3s ease;
  padding: 0 10px;
}

.battles-game-element a.link-view:hover .link-inner {
  color: #ffffff;
}

.battles-game-element button.button-waiting .button-inner svg {
  fill: #00ffc2;
  animation: waiting_animation 1.5s infinite linear both;
}

.battles-game-element button.button-join .button-inner svg {
  margin-right: 8px;
  fill: #ffffff;
}

.battles-game-element a.link-view .link-inner {
  border: 2px solid rgba(255, 255, 255, 0.08);
  background: #161c2c;
  border-radius: 6px;
}
.battles-game-element a.link-view .link-inner:hover {
  filter: brightness(1.2);
}

.battles-game-element a.link-view .link-inner svg {
  fill: #5e768e;
  transition: fill 0.3s ease;
}

.battles-game-element.element-completed a.link-view .link-inner svg {
  margin-right: 8px;
}

.battles-game-element a.link-view:hover .link-inner svg {
  fill: #ffffff;
}

.battles-game-element button.button-join .inner-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.battles-game-element button.button-join .inner-text span {
  font-size: 9px;
  font-weight: 700;
}

.battles-game-element .option-inner {
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  background: linear-gradient(180deg, #073137 0%, #082538 100%);
}

.battles-game-element.element-cursed .option-inner {
  background: linear-gradient(180deg, #370707 0%, #250808 100%);
}

.battles-game-element.element-cursed button.button-join .button-inner {
  background: linear-gradient(180deg, #ff4e4e 0%, #b30000 100%);
}

@keyframes waiting_animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1368px) {
  .battles-game-element .inner-cases {
    display: none;
  }
}

@media only screen and (max-width: 1175px) {
  .battles-game-element .inner-cases {
    display: flex;
  }
}

@media only screen and (max-width: 1012px) {
  .battles-game-element .inner-cases {
    display: none;
  }

  .battles-game-element .inner-right {
    padding: 0;
  }
}

@media only screen and (max-width: 640px) {
  .battles-game-element .inner-players {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  a.link-view {
    display: none;
  }

  .battles-game-element button.button-join {
    width: fit-content;
    margin-right: 0;
  }

  .battles-game-element button.button-join .button-inner {
    padding: 0 10px;
}

  .battles-game-element button.button-join .button-inner svg {
    margin: 0;
  }

  .battles-game-element button.button-join .button-inner .inner-text {
    display: none;
  }
}
</style>
