<template>
    <div class="fair-mines">
        <div class="mines-title">MINES</div>
        <div class="mines-text">
            <p>Each Mines game board is created using three key elements:</p>
            <p><span class="highlight">Server Seed:</span> Our secret code that determines mine placement.</p>
            <p><span class="highlight">Nonce:</span> A counter that changes with each new game you play.</p>
            <p><span class="highlight">Client Seed:</span> Your personal seed that you can change anytime.</p>
            <p>This creates a unique, verifiable board layout for every game.</p>
        </div>
        <div class="mines-code">
            <pre>
                <code>
const crypto = require('crypto');
const ChanceJs = require('chance');

const minesCount = 1;
const serverSeed = '4c60603de55e3b85fe67450aa124ec34509cb73b0f1fde15';
const nonce = 1;
const clientSeed = 'customClientSeed';

const fairGenerateMinesDeck= () => {
    let deck = [];

    for(let i = 0; i &lt; 25; i++) {
        if(i &lt; minesCount) {
            deck.push('mine');
        } else {
            deck.push('coin');
        }
    }

    return deck;
}

const fairGetMinesOutcome = () => {
    let deck = fairGenerateMinesDeck();

    const combined = `${serverSeed}-${nonce}-${clientSeed}`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');

    const chance = new ChanceJs(hash);
    deck = chance.shuffle(deck);

    console.log(deck);
}

fairGetMinesOutcome();
                </code>
            </pre>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FairMines'
    }
</script>

<style scoped>
    .fair-mines {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
        border-radius: 10px;
    }

    .mines-title {
        font-size: 24px;
        font-weight: 900;
        font-family: Montserrat, sans-serif;
        background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .mines-text {
        margin-top: 12px;
        width: 100%;
    }

    .mines-text p {
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
        margin-bottom: 8px;
        font-family: Montserrat, sans-serif;
    }

    .mines-text p .highlight {
        color: #2C5EFF;
        font-weight: 700;
    }

    .mines-code {
        width: 100%;
        margin-top: 25px;
        padding: 16px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
    }

    .mines-code pre {
        width: 100%;
        height: 225px;
        overflow: auto;
    }

    .mines-code pre code {
        font-family: 'Courier New', monospace;
        font-size: 13px;
        font-weight: 600;
        color: #bbbfd0;
    }

    /* Custom scrollbar styling */
    .mines-code pre::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    .mines-code pre::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
    }

    .mines-code pre::-webkit-scrollbar-thumb {
        background: #2C5EFF;
        border-radius: 2px;
    }

    @media only screen and (max-width: 768px) {
        .fair-mines {
            padding: 15px;
        }
        
        .mines-title {
            font-size: 20px;
        }
        
        .mines-code {
            padding: 12px;
        }
    }
</style>