const state = {
    affiliatesData: {
        data: {
            codes: [],
            earned: 0,
            available: 0,
            claims: []
        },
        referred: [],
        totalCount: 0,
        loading: false
    }
}

const getters = {
    affiliatesData: state => state.affiliatesData
}

const mutations = {
    affiliates_set_data_data(state, data = {}) {
        // Ensure we have a valid data object with default values
        state.affiliatesData.data = {
            codes: Array.isArray(data?.codes) ? data.codes : [],
            earned: Number(data?.earned || 0),
            available: Number(data?.available || 0),
            claims: Array.isArray(data?.claims) ? data.claims : []
        };
    },
    affiliates_set_data_referred(state, { referred = [], totalCount = 0 }) {
        // Ensure we store all referred users
        state.affiliatesData.referred = Array.isArray(referred) ? referred : [];
        state.affiliatesData.totalCount = Number(totalCount);
    },
    affiliates_set_data_loading(state, status) {
        state.affiliatesData.loading = Boolean(status);
    },
    affiliates_update_code(state, { code, data }) {
        if (!state.affiliatesData.data.codes) {
            state.affiliatesData.data.codes = [];
        }
        const codeIndex = state.affiliatesData.data.codes.findIndex(c => c?.code === code);
        if (codeIndex !== -1) {
            state.affiliatesData.data.codes[codeIndex] = {
                ...state.affiliatesData.data.codes[codeIndex],
                ...data
            };
        }
    }
}

const actions = {
    affiliatesGetDataSocket({ getters, commit, dispatch }) {
        if(getters.socketGeneral === null || getters.affiliatesData.loading === true) { return; }
        commit('affiliates_set_data_loading', true);

        // Always request all users by setting limit to 0
        getters.socketGeneral.emit('getAffiliateData', {
            limit: 0  // Request all users
        }, (res) => {
            if(res.success === true) {
                commit('affiliates_set_data_data', res.data);
                commit('affiliates_set_data_referred', {
                    referred: res.referred,
                    totalCount: res.totalCount
                });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('affiliates_set_data_loading', false);
        });
    },
    affiliatesSendCodeSocket({ getters, commit, dispatch }, data) {
        if(getters.socketGeneral === null || getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesCode');

        getters.socketGeneral.emit('sendAffiliateCode', data, (res) => {
            if(res.success === true) {
                commit('affiliates_set_data_data', res.data);
                
                dispatch('notificationShow', { type: 'success', message: 'You have successfully created your affiliate code.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    affiliatesSendClaimCodeSocket({ getters, commit, dispatch }, data) {
        if(getters.socketGeneral === null || getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesClaimCode');

        getters.socketGeneral.emit('sendAffiliateClaimCode', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                if (res.promotion) {
                    dispatch('notificationShow', { 
                        type: 'success', 
                        message: `Successfully claimed affiliate code. Check your promotions for your ${res.promotion.reward.amount}% deposit bonus!` 
                    });
                } else {
                    dispatch('notificationShow', { 
                        type: 'success', 
                        message: 'Successfully claimed affiliate code.' 
                    });
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    affiliatesSendClaimEarningsSocket({ getters, commit, dispatch }, data) {
        if(getters.socketGeneral === null || getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesClaimEarnings');

        getters.socketGeneral.emit('sendAffiliateClaimEarnings', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                commit('affiliates_set_data_data', res.user.affiliates);

                dispatch('notificationShow', { type: 'success', message: 'You have successfully claimed your affiliate earnings.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const affiliates = {
    state,
    mutations,
    actions,
    getters
}

export default affiliates;
