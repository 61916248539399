<template>
  <div class="modal-claim">
    <button v-on:click="() => modalsSetShow(null)" class="button-close">
      <font-awesome-icon :icon="['fas', 'times']" />
    </button>
    <div class="claim-header">
      <div class="header-icon">
        <font-awesome-icon :icon="['fas', 'gift']" />
      </div>
      <span>CLAIM FREE COINS</span>
    </div>
    <div class="claim-info">
      Claim free coins by inviting users, playing on our games or by being
      active on social media!
    </div>
    <div class="claim-input input-affiliate">
      <div class="input-title">Affiliate Code</div>
      <div class="input-info">
        Don't have an affiliate code? Use code "<span>BAZED</span>" for a free
        <img src="@/assets/img/icons/coin.svg" alt="icon" />
        <span>10 credits</span>
      </div>
      <div class="input-content">
        <input
          v-model="modalAffiliateCode"
          type="text"
          placeholder="Enter a affiliate code..."
        />
        <button v-on:click="modalAffiliateButton()" class="button-claim">
          <div class="button-inner">CLAIM</div>
        </button>
      </div>
    </div>
    <div class="claim-input">
      <div class="input-title">Promotional Code</div>
      <div class="input-info">
        Be active in our community on X and Telegram to get access to new promotional codes.
      </div>
      <div class="input-content">
        <input
          v-model="modalPromoCode"
          type="text"
          placeholder="Enter a promo code..."
        />
        <button v-on:click="modalPromoButton()" class="button-claim">
          <div class="button-inner">CLAIM</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ModalClaim",
  data() {
    return {
      modalAffiliateCode: null,
      modalPromoCode: null,
    };
  },
  methods: {
    ...mapActions(["notificationShow", "modalsSetShow", "modalsSetData"]),
    modalAffiliateButton() {
      if (
        this.modalAffiliateCode === null ||
        this.modalAffiliateCode.trim() === ""
      ) {
        this.notificationShow({
          type: "error",
          message: "Your entered affiliate code is invalid.",
        });
        return;
      }

      this.modalsSetShow(null);

      setTimeout(() => {
        this.modalsSetData({
          typeCaptcha: "affiliatesClaim",
          data: { code: this.modalAffiliateCode },
        });
        this.modalsSetShow("Captcha");
      }, 200);
    },
    modalPromoButton() {
      if (this.modalPromoCode === null || this.modalPromoCode.trim() === "") {
        this.notificationShow({
          type: "error",
          message: "Your entered promo code is invalid.",
        });
        return;
      }

      this.modalsSetShow(null);

      setTimeout(() => {
        this.modalsSetData({
          typeCaptcha: "promoClaim",
          data: { code: this.modalPromoCode },
        });
        this.modalsSetShow("Captcha");
      }, 200);
    },
  },
  computed: {
    ...mapGetters(["modalsData"]),
  },
  created() {
    if (this.modalsData !== null && this.modalsData.code !== undefined) {
      this.modalAffiliateCode = this.modalsData.code;
    }
  },
};
</script>

<style scoped>
.modal-claim {
  width: 720px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 30px 30px 30px;
  border-radius: 15px;
  background: linear-gradient(180deg, 
    rgba(255, 255, 255, 0.04) 0%, 
    rgba(31, 42, 68, 0.3) 100%
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.15),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.modal-claim button.button-close {
  position: absolute;
  top: 10px;
  right: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
}

.modal-claim button.button-close:hover {
  transform: scale(1.1);
}

.modal-claim button.button-close svg {
  width: 10px;
  height: 9px;
  fill: rgba(255, 255, 255, 0.5);
  transition: fill 0.3s ease;
}

.modal-claim button.button-close:hover svg {
  fill: rgba(255, 255, 255, 0.8);
}

.modal-claim .claim-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.modal-claim .header-icon {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.modal-claim .header-icon svg {
  height: 54px;
  width: 54px;
  color: #2C5EFF;
  filter: drop-shadow(0px 0px 8px rgba(44, 94, 255, 0.5));
}

.modal-claim .claim-header span {
  margin-top: 20px;
  text-align: center;
  font-size: 32px;
  font-weight: 900;
}

.modal-claim .claim-info {
  width: 100%;
  margin-top: 12px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}

.modal-claim .claim-input {
  width: 100%;
  margin-top: 25px;
}

.modal-claim .claim-input.input-affiliate {
  margin-top: 35px;
}

.modal-claim .input-title {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}

.modal-claim .input-info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}

.modal-claim .input-info span {
  font-weight: 700;
  color: #ffffff;
}

.modal-claim .input-info img {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

.modal-claim .input-content {
  width: 100%;
  position: relative;
  margin-top: 16px;
}

.modal-claim .input-content input {
  width: 100%;
  height: 56px;
  padding: 0 98px 0 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  background: rgba(31, 42, 68, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.modal-claim .input-content input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.modal-claim button.button-claim {
  width: 90px;
  height: 42px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(0, -50%);
  transition: all 0.2s;
  border: none;
  cursor: pointer;
}

.button-claim:hover {
  transform: translate(0, -50%) scale(0.95);
  opacity: 0.9;
}

.button-claim:active {
  transform: translate(0, -50%) scale(0.9);
}

.modal-claim button.button-claim .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  color: #ffffff;
  background: #2C5EFF;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(44, 94, 255, 0.5);
}

@media only screen and (max-width: 740px) {
  .modal-claim {
    width: calc(100vw - 20px);
    padding: 35px 10px 30px 10px;
  }
}

@media only screen and (max-width: 500px) {
  .modal-claim {
    padding: 65px 10px 30px 10px;
  }
}
</style>
