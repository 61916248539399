<template>
    <div class="stats-container">
        <div class="stats-grid">
            <div class="stats-card">
                <div class="card-header">
                    <span>Available to Claim</span>
                </div>
                <div class="card-content">
                    <div class="amount">${{ formatMoney(availableEarnings) }}</div>
                    <button class="claim-button" @click="claimEarnings" :disabled="!canClaim">
                        <font-awesome-icon :icon="['fas', 'coins']" />
                        Claim Earnings
                    </button>
                </div>
            </div>

            <div class="stats-card">
                <div class="card-header">
                    <span>Total Claimed</span>
                </div>
                <div class="card-content">
                    <div class="amount">${{ formatMoney(totalEarned) }}</div>
                </div>
            </div>

            <div class="stats-card">
                <div class="card-header">
                    <span>Total Deposits</span>
                </div>
                <div class="card-content">
                    <div class="amount">${{ formatMoney(totalDeposits) }}</div>
                </div>
            </div>

            <div class="stats-card">
                <div class="card-header">
                    <span>Total Wagered</span>
                </div>
                <div class="card-content">
                    <div class="amount">${{ formatMoney(totalWagered) }}</div>
                </div>
            </div>
        </div>

        <div class="claims-history">
            <div class="card-header">
                <span>Claims History</span>
            </div>
            <div class="history-content">
                <div v-if="claimsHistory.length === 0" class="no-history">
                    No claims history available
                </div>
                <div v-else class="history-grid">
                    <div v-for="(claim, index) in claimsHistory" :key="index" class="history-item">
                        <div class="claim-info">
                            <span class="claim-amount">${{ formatMoney(claim.amount) }}</span>
                            <span class="claim-date">{{ formatDate(claim.date) }}</span>
                        </div>
                        <div class="claim-status">
                            <font-awesome-icon :icon="['fas', 'check-circle']" />
                            Claimed
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AffiliatesStatsCard from './AffiliatesStatsCard.vue';
    import AffiliatesCommissionCard from './AffiliatesCommissionCard.vue';

    export default {
        name: 'AffiliatesClaim',
        components: {
            AffiliatesStatsCard,
            AffiliatesCommissionCard
        },
        computed: {
            ...mapGetters([
                'socketSendLoading', 
                'affiliatesData',
                'authUser'
            ]),
            totalReferrals() {
                const totalFromCodes = (this.affiliatesData.data?.codes || []).reduce((total, code) => {
                    return total + (code.referredCount || 0);
                }, 0);

                const uniqueReferrals = new Set(
                    (this.affiliatesData.referred || []).map(ref => ref.user?._id).filter(Boolean)
                ).size;

                return Math.max(totalFromCodes, uniqueReferrals);
            },
            totalDeposits() {
                return (this.affiliatesData.referred || []).reduce((total, ref) => {
                    return total + (ref.stats?.deposit || 0);
                }, 0);
            },
            totalWagered() {
                return (this.affiliatesData.referred || []).reduce((total, ref) => {
                    const statsExtra = ref.statsExtra || {};
                    return total + Object.values(statsExtra).reduce((sum, stats) => {
                        return sum + (stats.bet || 0);
                    }, 0);
                }, 0);
            },
            availableEarnings() {
                return this.affiliatesData.data?.availableEarnings || 0;
            },
            totalEarned() {
                return this.affiliatesData.data?.totalEarned || 0;
            },
            canClaim() {
                return this.availableEarnings > 0;
            },
            claimsHistory() {
                return this.affiliatesData.data?.claimsHistory || [];
            }
        },
        methods: {
            ...mapActions(['affiliatesClaimButton']),
            formatMoney(value) {
                // Convert to dollars (assuming value is in cents)
                const dollars = value / 100;
                return dollars.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            },
            formatDate(date) {
                return new Date(date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                });
            },
            claimEarnings() {
                if (this.canClaim) {
                    this.affiliatesClaimButton();
                }
            }
        }
    }
</script>

<style scoped>
.stats-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin-bottom: 24px;
}

.stats-card {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(44, 94, 255, 0.05) 100%);
    border-radius: 16px;
    border: 1px solid rgba(44, 94, 255, 0.1);
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
    transition: all 0.3s ease;
    overflow: hidden;
}

.stats-card:hover {
    transform: translateY(-2px);
    border-color: rgba(44, 94, 255, 0.3);
    box-shadow: 
        0px 8px 16px rgba(0, 0, 0, 0.3),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset,
        0px 0px 20px rgba(44, 94, 255, 0.1);
}

.card-header {
    background: linear-gradient(180deg, rgba(52, 157, 255, 0.1) 0%, rgba(0, 94, 233, 0.1) 100%);
    padding: 16px;
    border-bottom: 1px solid rgba(44, 94, 255, 0.1);
}

.card-header span {
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.card-content {
    padding: 20px;
    text-align: center;
}

.amount {
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    margin-bottom: 8px;
    text-shadow: 0px 0px 8px rgba(52, 157, 255, 0.3);
}

.claim-button {
    background: linear-gradient(135deg, #2C5EFF 0%, #2C5EFF 100%);
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    color: #FFFFFF;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    margin-top: 16px;
    transition: all 0.3s ease;
}

.claim-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0px 4px 8px rgba(44, 94, 255, 0.3);
}

.claim-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.claims-history {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(44, 94, 255, 0.05) 100%);
    border-radius: 16px;
    border: 1px solid rgba(44, 94, 255, 0.1);
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
    overflow: hidden;
}

.history-content {
    padding: 20px;
}

.history-grid {
    display: grid;
    gap: 16px;
}

.history-item {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(44, 94, 255, 0.05) 100%);
    border-radius: 12px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(44, 94, 255, 0.1);
    transition: all 0.3s ease;
}

.history-item:hover {
    border-color: rgba(44, 94, 255, 0.3);
    box-shadow: 
        0px 4px 8px rgba(0, 0, 0, 0.2),
        0px 0px 12px rgba(44, 94, 255, 0.1);
    transform: translateY(-1px);
}

.claim-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.claim-amount {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
}

.claim-date {
    font-family: Inter, sans-serif;
    font-size: 12px;
    color: #8BACC8;
}

.claim-status {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #4CAF50;
    font-family: Inter, sans-serif;
    font-size: 14px;
}

.no-history {
    text-align: center;
    padding: 32px;
    color: #8BACC8;
    font-family: Inter, sans-serif;
    font-size: 14px;
}

@media only screen and (max-width: 1150px) {
    .stats-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }

    .card-content {
        padding: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .stats-grid {
        grid-template-columns: 1fr;
        gap: 12px;
    }

    .amount {
        font-size: 20px;
    }

    .card-header span {
        font-size: 12px;
    }

    .history-item {
        padding: 12px;
    }
}
</style>
