<template>
    <div v-if="globalRates" class="code-card">
        <div class="card-header">
            <span>YOUR GLOBAL COMMISSION RATES</span>
        </div>
        <div class="card-content">
            <div class="rates-grid">
                <div class="rate-item" v-for="(rate, game) in globalRates" :key="game">
                    <span class="rate-game">{{ game }}</span>
                    <span class="rate-value">{{ (rate * 100).toFixed(1) }}%</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import '@/components/affiliates/code/styles/CardStyles.vue';

export default {
    name: 'GlobalCommissionRates',
    computed: {
        ...mapGetters(['affiliatesData']),
        globalRates() {
            return this.affiliatesData?.data?.rates;
        }
    }
}
</script>

<style scoped>
.code-card {
    background: linear-gradient(135deg, rgba(44, 94, 255, 0.15) 0%, rgba(44, 94, 255, 0.05) 100%);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2),
                0px -1px 0px rgba(0, 0, 0, 0.3) inset,
                0px 1px 0px rgba(255, 255, 255, 0.1) inset;
    border: 1px solid rgba(44, 94, 255, 0.1);
}

.card-header {
    padding: 20px;
    background: rgba(44, 94, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.card-header span {
    font-size: 14px;
    font-weight: 700;
    color: #8bacc8;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.card-content {
    padding: 24px;
}

.rates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
    padding: 8px;
}

.rate-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background: rgba(13, 23, 39, 0.3);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.rate-game {
    color: #8bacc8;
    font-size: 14px;
    text-transform: capitalize;
}

.rate-value {
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
}

@media only screen and (max-width: 480px) {
    .card-content {
        padding: 16px;
    }
}
</style> 