<template>
  <div class="rakeback-claims">
    <div class="claims-header">
      <h2>Available Claims</h2>
    </div>

    <div class="claims-grid">
      <!-- Hourly Claim -->
      <div class="claim-card" :class="{ 'can-claim': hourlyClaimAvailable }">
        <div class="claim-header">
          <span class="claim-icon">⏰</span>
          <h3>Hourly Rakeback</h3>
        </div>
        <div class="claim-amount" v-if="hourlyClaimAvailable">
          ${{ formatAmount(claims.hourly.amount) }}
        </div>
        <div class="claim-countdown" v-else>
          {{ formatTime(claims.hourly.timeLeft) }}
        </div>
        <div class="claim-info">
          <span>{{ hourlyClaimAvailable ? 'Available to claim' : 'Next claim available in' }}</span>
        </div>
        <button 
          class="claim-button" 
          :disabled="!isAuthenticated || !hourlyClaimAvailable"
          @click="handleClaimClick('hourly')"
        >
          {{ getClaimButtonText('hourly') }}
        </button>
      </div>

      <!-- Daily Claim -->
      <div class="claim-card" :class="{ 'can-claim': dailyClaimAvailable }">
        <div class="claim-header">
          <span class="claim-icon">📅</span>
          <h3>Daily Rakeback</h3>
        </div>
        <div class="claim-amount" v-if="dailyClaimAvailable">
          ${{ formatAmount(claims.daily.amount) }}
        </div>
        <div class="claim-countdown" v-else>
          {{ formatTime(claims.daily.timeLeft) }}
        </div>
        <div class="claim-info">
          <span>{{ dailyClaimAvailable ? 'Available to claim' : 'Next claim available in' }}</span>
        </div>
        <button 
          class="claim-button" 
          :disabled="!isAuthenticated || !dailyClaimAvailable"
          @click="handleClaimClick('daily')"
        >
          {{ getClaimButtonText('daily') }}
        </button>
      </div>

      <!-- Weekly Claim -->
      <div class="claim-card" :class="{ 'can-claim': weeklyClaimAvailable }">
        <div class="claim-header">
          <span class="claim-icon">🗓️</span>
          <h3>Weekly Rakeback</h3>
        </div>
        <div class="claim-amount" v-if="weeklyClaimAvailable">
          ${{ formatAmount(claims.weekly.amount) }}
        </div>
        <div class="claim-countdown" v-else>
          {{ formatTime(claims.weekly.timeLeft) }}
        </div>
        <div class="claim-info">
          <span>{{ weeklyClaimAvailable ? 'Available to claim' : 'Next claim available in' }}</span>
        </div>
        <button 
          class="claim-button" 
          :disabled="!isAuthenticated || !weeklyClaimAvailable"
          @click="handleClaimClick('weekly')"
        >
          {{ getClaimButtonText('weekly') }}
        </button>
      </div>

      <!-- Monthly Claim -->
      <div class="claim-card" :class="{ 'can-claim': monthlyClaimAvailable }">
        <div class="claim-header">
          <span class="claim-icon">📊</span>
          <h3>Monthly Rakeback</h3>
        </div>
        <div class="claim-amount" v-if="monthlyClaimAvailable">
          ${{ formatAmount(claims.monthly.amount) }}
        </div>
        <div class="claim-countdown" v-else>
          {{ formatTime(claims.monthly.timeLeft) }}
        </div>
        <div class="claim-info">
          <span>{{ monthlyClaimAvailable ? 'Available to claim' : 'Next claim available in' }}</span>
        </div>
        <button 
          class="claim-button" 
          :disabled="!isAuthenticated || !monthlyClaimAvailable"
          @click="handleClaimClick('monthly')"
        >
          {{ getClaimButtonText('monthly') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'RakebackClaims',
  props: {
    claims: {
      type: Object,
      default: () => ({
        hourly: { amount: 0, canClaim: false, timeLeft: 0 },
        daily: { amount: 0, canClaim: false, timeLeft: 0 },
        weekly: { amount: 0, canClaim: false, timeLeft: 0 },
        monthly: { amount: 0, canClaim: false, timeLeft: 0 }
      })
    }
  },
  data() {
    return {
      claimLoading: null,
      minClaimAmount: 0.01 // Minimum amount to claim
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    
    hourlyClaimAvailable() {
      return this.claims?.hourly?.canClaim && this.claims?.hourly?.amount >= this.minClaimAmount;
    },
    
    dailyClaimAvailable() {
      return this.claims?.daily?.canClaim && this.claims?.daily?.amount >= this.minClaimAmount;
    },
    
    weeklyClaimAvailable() {
      return this.claims?.weekly?.canClaim && this.claims?.weekly?.amount >= this.minClaimAmount;
    },
    
    monthlyClaimAvailable() {
      return this.claims?.monthly?.canClaim && this.claims?.monthly?.amount >= this.minClaimAmount;
    }
  },
  methods: {
    ...mapActions(['rakebackSendClaimSocket']),
    
    async handleClaimClick(type) {
      if (!this.isAuthenticated) {
        this.$router.push('/login');
        return;
      }

      if (!this.claims?.[type]?.canClaim || this.claims[type].amount < this.minClaimAmount) {
        return;
      }

      // Prevent multiple claims at once
      if (this.claimLoading) return;

      this.claimLoading = type;

      try {
        const response = await this.rakebackSendClaimSocket({ type });
        
        if (response?.success) {
          this.$toast.success(`Successfully claimed ${type} rakeback!`);
          this.$emit('claim-success', type);
        } else {
          const errorMessage = response?.error?.message || `Failed to claim ${type} rakeback`;
          this.$toast.error(errorMessage);
        }
      } catch (error) {
        console.error('Error claiming rakeback:', error);
        this.$toast.error(error?.message || `Failed to claim ${type} rakeback`);
      } finally {
        this.claimLoading = null;
      }
    },
    
    formatAmount(amount) {
      return Number(amount || 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    
    formatTime(timeLeft) {
      if (!timeLeft) return '00:00:00';
      
      const hours = Math.floor(timeLeft / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
      
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },

    getClaimButtonText(type) {
      if (!this.isAuthenticated) return 'Login to Claim';
      if (this.claimLoading === type) return 'Claiming...';
      if (this.claims[type]?.canClaim) {
        if (this.claims[type].amount < this.minClaimAmount) return 'Amount too small';
        return 'Claim Now';
      }
      return 'Wait';
    }
  }
}
</script>

<style scoped>
.rakeback-claims {
  background: #1A1F37;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 24px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
}

.claims-header {
  background: #1E2443;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.claims-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 800;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.claims-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.claim-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.3s ease;
}

.claim-card:hover {
  background: rgba(44, 94, 255, 0.1);
  transform: translateY(-1px);
}

.claim-card.can-claim {
  background: linear-gradient(135deg, rgba(44, 94, 255, 0.1) 0%, rgba(44, 94, 255, 0.2) 100%);
  box-shadow: 0 0 20px rgba(44, 94, 255, 0.1);
}

.claim-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.claim-icon {
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(44, 94, 255, 0.1);
  border-radius: 10px;
}

.claim-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.claim-amount {
  font-size: 32px;
  font-weight: 800;
  color: #2C5EFF;
  text-shadow: 0 2px 4px rgba(44, 94, 255, 0.3);
}

.claim-countdown {
  font-family: monospace;
  font-size: 28px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}

.claim-info {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.claim-button {
  background: #2C5EFF;
  border: none;
  border-radius: 8px;
  padding: 12px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 12px rgba(44, 94, 255, 0.2);
}

.claim-button:hover:not(:disabled) {
  background: #1E4FFF;
  transform: translateY(-1px);
  box-shadow: 0 6px 16px rgba(44, 94, 255, 0.3);
}

.claim-button:disabled {
  background: rgba(255, 255, 255, 0.1);
  cursor: not-allowed;
  box-shadow: none;
}

@media (max-width: 768px) {
  .claims-grid {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 16px;
  }

  .claim-card {
    padding: 16px;
  }

  .claim-amount {
    font-size: 28px;
  }

  .claim-countdown {
    font-size: 24px;
  }
}
</style> 