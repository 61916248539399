const state = {
    promotions: [],
    loading: false,
    activeClaims: [],
    claimsLoading: false
}

const mutations = {
    promotions_set_list(state, promotions) {
        state.promotions = promotions;
    },
    promotions_set_loading(state, loading) {
        state.loading = loading;
    },
    promotions_set_claims(state, claims) {
        state.activeClaims = claims;
    },
    promotions_set_claims_loading(state, loading) {
        state.claimsLoading = loading;
    },
    promotions_add_claim(state, claim) {
        state.activeClaims.unshift(claim);
    },
    promotions_remove_claim(state, claimId) {
        state.activeClaims = state.activeClaims.filter(c => c._id !== claimId);
    },
    promotions_update_claim(state, updatedClaim) {
        const index = state.activeClaims.findIndex(c => c._id === updatedClaim._id);
        if (index !== -1) {
            state.activeClaims.splice(index, 1, updatedClaim);
        }
    }
}

const actions = {
    promotionsGetList({ getters, commit }) {
        if (!getters.socketGeneral?.connected || state.loading) return;
        commit('promotions_set_loading', true);

        getters.socketGeneral.emit('promotions:list', {}, (response) => {
            if (response.success) {
                commit('promotions_set_list', response.data);
            }
            commit('promotions_set_loading', false);
        });
    },
    promotionsGetClaims({ getters, commit }) {
        if (!getters.socketGeneral?.connected || state.claimsLoading) return;
        commit('promotions_set_claims_loading', true);

        getters.socketGeneral.emit('promotions:my_claims', {}, (response) => {
            if (response.success) {
                commit('promotions_set_claims', response.claims);
            }
            commit('promotions_set_claims_loading', false);
        });
    },
    promotionsClaim({ getters, commit, dispatch }, promotionId) {
        if (!getters.socketGeneral?.connected) return;

        getters.socketGeneral.emit('promotions:claim', { promotionId }, (response) => {
            if (!response.success) {
                dispatch('notificationShow', response.error);
            }
        });
    },
    promotionsCancelClaim({ getters, commit, dispatch }, claimId) {
        if (!getters.socketGeneral?.connected) return;

        getters.socketGeneral.emit('promotions:cancel_claim', { claimId }, (response) => {
            if (response.success) {
                commit('promotions_remove_claim', claimId);
                dispatch('notificationShow', { 
                    type: 'success', 
                    message: 'Claim cancelled successfully' 
                });
            } else {
                dispatch('notificationShow', response.error);
            }
        });
    }
}

const getters = {
    promotionsList: state => state.promotions,
    promotionsLoading: state => state.loading,
    promotionsClaims: state => state.activeClaims,
    promotionsClaimsLoading: state => state.claimsLoading
}

export default {
    state,
    mutations,
    actions,
    getters
} 