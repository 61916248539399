<template>
    <div class="buyback">
        <BuybackHeader />
        <div class="grid">
            <div class="first-row">
                <BuybackCasinoCard />
                <BuybackCryptoCard />
                <BuybackSportCard class="item-3"/>
            </div>
            <div class="last-row">
                <BuybackBazedSupplyCard />
                <BuybackBazedBurntCard />
            </div>
        </div>
    </div>
</template>

<script>
import BuybackHeader from '@/components/buyback/BuyBackHeader.vue';
import BuybackCasinoCard from '@/components/buyback/BuybackCasinoCard.vue';
import BuybackCryptoCard from '@/components/buyback/BuybackCryptoCard.vue';
import BuybackSportCard from '@/components/buyback/BuybackSportCard.vue';
import BuybackBazedSupplyCard from '@/components/buyback/BuybackBazedSupplyCard.vue';
import BuybackBazedBurntCard from '@/components/buyback/BuybackBazedBurntCard.vue';

export default {
    name: 'Buyback',
    components: {
        BuybackHeader,
        BuybackCasinoCard,
        BuybackCryptoCard,
        BuybackSportCard,
        BuybackBazedSupplyCard,
        BuybackBazedBurntCard,
    },
};
</script>

<style scoped>
.buyback {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    gap: 1rem;
    padding: 60px;
    max-width: 1568px;
}

.first-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    gap: 25px;
}

.last-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

@media (max-width: 1175px) {
    .buyback {
        padding: 10px;
    }

    .first-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
        gap: 1rem;
        width: 100%;
    }

    .item-3 {
        grid-column: span 2;
    }
}

@media (max-width: 668px) {
    .buyback {
        padding: 10px;
    }

    .first-row {
        display: flex;
        flex-direction: column;
    }

    .last-row {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .grid {
        grid-template-columns: 1fr;
    }
}
</style>