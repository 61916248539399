<template>
  <div class="modals">
    <transition name="fade">
      <div class="modals-overlay" v-show="modalsShow !== null"></div>
    </transition>
    <transition name="slide-fade" mode="out-in">
      <div class="modals-holder" v-if="modalsShow !== null" v-on:click="modalsCloseButton">
        <div class="holder-body">

          <div class="body-modal">
            <button v-if="['DuelsGame', 'BattlesSelect', 'Box'].includes(modalsShow) !== true"
                    v-on:click="modalsSetShow(null)" class="close-button">
              <i class="fas fa-times"></i>
            </button>

            <component v-bind:is="'Modal' + modalsShow"/>
          </div>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import IconClose from '@/components/icons/IconClose';
import ModalCaptcha from '@/components/modals/ModalCaptcha';
import ModalLogin from '@/components/modals/ModalLogin';
import ModalLink from '@/components/modals/ModalLink';
import ModalLinkBazed from '@/components/modals/ModalLinkBazed';
import ModalReset from '@/components/modals/ModalReset';
import ModalClaim from '@/components/modals/ModalClaim';
import ModalChatRules from '@/components/modals/ModalChatRules';
import ModalChatUser from '@/components/modals/ModalChatUser';
import ModalRemove from '@/components/modals/ModalRemove';
import ModalMute from '@/components/modals/ModalMute';
import ModalBan from '@/components/modals/ModalBan';
import ModalCashier from '@/components/modals/ModalCashier';
import ModalBazedCurrency from '@/components/modals/ModalBazedCurrency';
import ModalCredit from '@/components/modals/ModalCredit';
import ModalCrypto from '@/components/modals/ModalCrypto';
import ModalGift from '@/components/modals/ModalGift';
import ModalProceed from '@/components/modals/ModalProceed';
import ModalTwoStep from '@/components/modals/ModalTwoStep';
import ModalVault from '@/components/modals/ModalVault';
import ModalTip from '@/components/modals/ModalTip';
import ModalFair from '@/components/modals/ModalFair';
import ModalFairSeed from '@/components/modals/ModalFairSeed';
import ModalFairGame from '@/components/modals/ModalFairGame';
import ModalFaq from '@/components/modals/ModalFaq';
import ModalTerms from '@/components/modals/ModalTerms';
import ModalPrivacy from '@/components/modals/ModalPrivacy';
import ModalBlackjackRules from '@/components/modals/ModalBlackjackRules';
import ModalDuelsGame from '@/components/modals/ModalDuelsGame';
import ModalCoinflipGame from '@/components/modals/ModalCoinflipGame';
import ModalBattlesModes from '@/components/modals/ModalBattlesModes';
import ModalBattlesSelect from '@/components/modals/ModalBattlesSelect';
import ModalBox from '@/components/modals/ModalBox';
import ModalAdminConfirm from '@/components/modals/ModalAdminConfirm';
import ModalAdminUser from '@/components/modals/ModalAdminUser';
import ModalAdminAffiliate from '@/components/modals/ModalAdminAffiliate';
import ModalAdminCrypto from '@/components/modals/ModalAdminCrypto';
import ModalKyc from '@/components/modals/ModalKyc';
import ModalResponsibleGaming from '@/components/modals/ModalResponsibleGaming';

export default {
  components: {
    IconClose,
    ModalCaptcha,
    ModalLogin,
    ModalLink,
    ModalLinkBazed,
    ModalReset,
    ModalClaim,
    ModalChatRules,
    ModalChatUser,
    ModalRemove,
    ModalMute,
    ModalBan,
    ModalVault,
    ModalCashier,
    ModalBazedCurrency,
    ModalCredit,
    ModalCrypto,
    ModalGift,
    ModalProceed,
    ModalTwoStep,
    ModalTip,
    ModalFair,
    ModalFairSeed,
    ModalFairGame,
    ModalFaq,
    ModalTerms,
    ModalPrivacy,
    ModalKyc,
    ModalResponsibleGaming,
    ModalBlackjackRules,
    ModalDuelsGame,
    ModalCoinflipGame,
    ModalBattlesModes,
    ModalBattlesSelect,
    ModalBox,
    ModalAdminConfirm,
    ModalAdminUser,
    ModalAdminAffiliate,
    ModalAdminCrypto
  },
  methods: {
    ...mapActions([
      'modalsSetShow'
    ]),
    modalsCloseButton(e) {
      if (e.target.className === 'modals-holder' || e.target.className === 'holder-body') {
        this.modalsSetShow(null);
      }
    }
  },
  computed: {
    ...mapGetters([
      'modalsShow'
    ])
  }
}
</script>

<style scoped>
.modals {
  position: relative;
}

.modals .modals-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(2, 20, 35, 0.85);
  user-select: none;
  z-index: 101;
}

.modals .modals-overlay.fade-enter-active,
.modals .modals-overlay.fade-leave-active {
  transition: opacity .3s ease;
}

.modals .modals-overlay.fade-enter,
.modals .modals-overlay.fade-leave-to {
  opacity: 0;
}

.modals .modals-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px 0;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  z-index: 102;
}

.modals .modals-holder::-webkit-scrollbar-track {
  background-color: transparent;
}

.modals .modals-holder::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.modals .modals-holder.slide-fade-enter-active,
.modals .modals-holder.slide-fade-leave-active {
  transition: all .3s ease;
}

.modals .modals-holder.slide-fade-enter,
.modals .modals-holder.slide-fade-leave-to {
  transform: translate(0, 50px);
  opacity: 0;
}

.modals .holder-body {
  width: 100%;
  display: flex;
  justify-content: center;
}

.modals .body-modal {
  position: relative;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

.modals .body-modal button.button-close {
  display: none;
}

.modals .body-modal.modal-square button.button-close {
  top: 15px;
  right: 15px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, #510c3d 100%);
}

.modals .body-modal button.button-close svg {
  fill: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  width: 16px;
  height: 16px;
}

.modals .body-modal button.button-close:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.2) inset;
}

.modals .body-modal button.button-close:hover svg {
  fill: #ffffff;
}

.close-button {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(31, 42, 68, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.2s ease;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.close-button:hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(31, 42, 68, 0.5) 100%);
  color: rgba(255, 255, 255, 0.9);
  transform: translateY(-1px);
}

.close-button i {
  font-size: 16px;
  line-height: 1;
}

.modals .body-modal.modal-square .close-button {
  top: 15px;
  right: 15px;
}

@media only screen and (max-width: 840px) {
  .close-button {
    width: 32px;
    height: 32px;
    top: 16px;
    right: 16px;
  }

  .close-button i {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .close-button {
    width: 28px;
    height: 28px;
    top: 12px;
    right: 12px;
  }

  .close-button i {
    font-size: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .close-button {
    width: 26px;
    height: 26px;
    top: 10px;
    right: 10px;
  }
}
</style>
