<template>
    <div class="referred-container">
        <div class="referred-card">
            <div class="card-header">
                <div class="header-item user">USER</div>
                <div class="header-item earned">EARNED</div>
                <div class="header-item deposits">DEPOSITS</div>
                <div class="header-item wagered">WAGERED</div>
            </div>

            <div class="card-content">
                <transition name="fade" mode="out-in">
                    <div v-if="affiliatesData.loading" class="loading-state" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="affiliatesData.referred?.length" class="referred-list" key="data">
                        <AffiliatesReferredElement 
                            v-for="(referred, index) in affiliatesData.referred" 
                            :key="index" 
                            :referred="referred" 
                        />
                    </div>
                    <div v-else class="empty-state" key="empty">
                        No referred users found
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import AffiliatesReferredElement from '@/components/affiliates/AffiliatesReferredElement';

    export default {
        name: 'AffiliatesReferred',
        components: {
            LoadingAnimation,
            AffiliatesReferredElement
        },
        computed: {
            ...mapGetters(['affiliatesData'])
        },
        methods: {
            ...mapActions(['affiliatesGetDataSocket'])
        },
        created() {
            this.affiliatesGetDataSocket({ limit: 0 });
        }
    }
</script>

<style scoped>
.referred-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.referred-card {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, var(--background-primary) 100%);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
    border: 1px solid rgba(44, 94, 255, 0.1);
}

.card-header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 16px;
    padding: 16px 24px;
    background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-item {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 700;
    color: #8bacc8;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.card-content {
    min-height: 200px;
    padding: 0;
}

.referred-list {
    display: flex;
    flex-direction: column;
}

.loading-state,
.empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    color: #8bacc8;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

@media only screen and (max-width: 768px) {
    .card-header {
        padding: 12px 16px;
        gap: 12px;
        grid-template-columns: 2fr 1fr 1fr;
    }

    .header-item.wagered {
        display: none;
    }

    .loading-state,
    .empty-state {
        min-height: 150px;
    }
}

@media only screen and (max-width: 480px) {
    .card-header {
        padding: 10px 12px;
        gap: 8px;
        grid-template-columns: 2fr 1fr;
    }

    .header-item.deposits {
        display: none;
    }
}
</style>
