<template>
  <div class="modal-terms">
    <div class="terms-header">
      <span>Responsible Gaming</span>
      <p>Last updated: 3.7.2024</p>
    </div>
    <div class="terms-list">
      <div class="list-element">
        <div class="element-title">1. Our Commitment</div>
        <div class="element-text">
          <p>bazedbet.com is here to provide an excellent and enjoyable gaming experience and to recognize our responsibility in problematic activity prevention. We advise all players to take the following into account, and game responsibly:</p>
          <p>• Play for entertainment, not for profit</p>
          <p>• Avoid chasing losses</p>
          <p>• Establish limits for yourself</p>
          <p>• Do not let gambling interfere with your daily responsibilities</p>
          <p>• Never gamble unless you can cover losses</p>
          <p>• Take breaks</p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">2. Warning Signs</div>
        <div class="element-text">
          <p>If you answer "YES" to majority of these questions, we advise you take action to prevent gambling from negatively impacting your life:</p>
          <p>• Does gambling affect your work?</p>
          <p>• Has gambling caused arguments with family/friends?</p>
          <p>• Do you always return to win back your losses?</p>
          <p>• Have you borrowed money to gamble?</p>
          <p>• Do you see gambling as a source of income?</p>
          <p>• Do you find it difficult to limit your gambling?</p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">3. Support Organizations</div>
        <div class="element-text">
          <p>Listed below are reputed organizations committed to helping those who struggle with gambling problems, and can be contacted at any time:</p>
          <p>• Gamblers Anonymous</p>
          <p>• Gambling Therapy</p>
          <p>• GamCare</p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">4. Self-Exclusion</div>
        <div class="element-text">
          <p>We advise all players concerned about their gambling behavior to take a break by excluding themselves from their gaming account. Self-exclusion will lock your account for a minimum of 6 months and no promotional material will be sent.</p>
          <p>Contact our experienced Customer Support team at any time to request this and they will kindly assist you. A 7 day cooling off period is also available. We also recommend that you contact all other gambling sites where you have an account and request self-exclusion there as well.</p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">5. Underage Gambling Prevention</div>
        <div class="element-text">
          <p>Players must be of legal gambling age in their jurisdiction (at least 18+) in order to play at bazedbet.com. It is their responsibility to be aware of the age restriction where they reside and play, and to confirm their legitimacy when creating an account at bazedbet.com.</p>
          <p>We advise parents to do the following:</p>
          <p>• Password protect computer, mobile, and/or tablet</p>
          <p>• Do not leave device unattended when logged into your account</p>
          <p>• Make sure all account details and credit cards are inaccessible to children</p>
          <p>• Do not save passwords on your computer, write them down and keep somewhere out of reach</p>
          <p>• Download filtering software (e.g. Net Nanny) to prevent minors from accessing inappropriate sites</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalResponsibleGaming'
}
</script>

<style scoped>
.modal-terms {
  width: 1000px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 0 30px;
  border-radius: 15px;
  background: var(--background-secondary);
}

.terms-header {
  width: 100%;
  padding-bottom: 30px;
}

.terms-header span {
  font-size: 32px;
  font-weight: 900;
  color: white;
  font-family: Montserrat, sans-serif;
}

.terms-header p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-family: Montserrat, sans-serif;
}

.terms-list {
  width: 100%;
  height: 600px;
  padding: 30px 20px 30px 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.terms-list::-webkit-scrollbar {
  width: 5px;
  height: 0;
}

.terms-list::-webkit-scrollbar-track {
  margin: 30px 0;
  background: var(--background-primary);
}

.terms-list::-webkit-scrollbar-thumb {
  background: var(--highlight-blue);
}

.list-element {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.list-element:first-child {
  margin-top: 0;
}

.element-title {
  font-size: 24px;
  font-weight: 800;
  color: #ffffff;
  font-family: Montserrat, sans-serif;
}

.element-text {
  width: 100%;
  margin-top: 15px;
  padding-left: 30px;
}

.element-text p {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-family: Montserrat, sans-serif;
  line-height: 1.6;
}

.element-text p:first-child {
  margin-top: 0;
}

@media only screen and (max-width: 1190px) {
  .modal-terms {
    width: calc(100vw - 20px);
  }
}

@media only screen and (max-width: 700px) {
  .modal-terms {
    padding: 80px 15px 0 15px;
  }
  
  .terms-header span {
    font-size: 24px;
  }
  
  .element-title {
    font-size: 20px;
  }
  
  .element-text {
    padding-left: 15px;
  }
}
</style> 