<template>
  <router-link class="unbox-box-element" v-bind:to="'/unbox/' + box._id">
    <div class="element-image">
      <img v-bind:src="unboxImagePath + '/img/' + box.slug + '.png'" />
    </div>
    <div class="element-name">
      <div class="name-inner">{{ box.name }}</div>
    </div>
    <div class="element-price">
      <img src="@/assets/img/icons/coin.svg" alt="icon" />
      <div class="price-value">
        <span>{{ unboxFormatValue(this.box.amount).split(".")[0] }}</span
        >.{{ unboxFormatValue(this.box.amount).split(".")[1] }}
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "UnboxBoxElement",
  props: ["box"],
  data() {
    return {
      unboxImagePath: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    unboxFormatValue(value) {
      return parseFloat(Math.floor(value / 10) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
.unbox-box-element {
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 10px;
  border: 2px solid rgba(52, 163, 255, 1);
  border-radius: 0.625rem;
  background: radial-gradient(
      156.29% 158.91% at 112.28% 178.64%,
      rgba(52, 163, 255, 0.9) 0%,
      rgba(52, 163, 255, 0) 100%
    ),
    #161b35;
  background-blend-mode: hard-light, normal;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease;
  z-index: 1;
}
.unbox-box-element:hover {
  transform: translateY(-4px);
}
.unbox-box-element:active {
  transform: translateY(2px);
}

.unbox-box-element:nth-child(7n) {
  margin-right: 0;
}

.unbox-box-element .element-name {
  width: 100%;
  position: relative;
  padding: 1px;
  margin-top: 18px;
  z-index: 1;
}

.unbox-box-element .name-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #5e768e;
}

.unbox-box-element .element-image {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unbox-box-element .element-image img {
  width: 130px;
}

.unbox-box-element .element-price {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.unbox-box-element .element-price img {
  height: 24px;
  margin-right: 2px;
}

.unbox-box-element .price-value {
  font-size: 10px;
  font-weight: 600;
  color: #bbbfd0;
}

.unbox-box-element .price-value span {
  font-size: 14px;
  font-weight: 800;
  color: #ffffff;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1050px) {
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 650px) {
}

@media only screen and (max-width: 500px) {
}
</style>
