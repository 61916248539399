import { render, staticRenderFns } from "./RollRecentElement.vue?vue&type=template&id=1ee19682&scoped=true"
import script from "./RollRecentElement.vue?vue&type=script&lang=js"
export * from "./RollRecentElement.vue?vue&type=script&lang=js"
import style0 from "./RollRecentElement.vue?vue&type=style&index=0&id=1ee19682&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ee19682",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ee19682')) {
      api.createRecord('1ee19682', component.options)
    } else {
      api.reload('1ee19682', component.options)
    }
    module.hot.accept("./RollRecentElement.vue?vue&type=template&id=1ee19682&scoped=true", function () {
      api.rerender('1ee19682', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/roll/RollRecentElement.vue"
export default component.exports