<template>
    <div class="profile-settings">
        <div class="settings-section">
            <div class="section-head">
                <div class="head-name">SETTING</div>
                <div class="head-action">ACTION</div>
            </div>
            <div class="section-content">
                <ProfileSettingsElement name="ANONYMOUS MODE">
                    <button v-on:click="userToggleAnonymous" class="button-toggle" v-bind:class="{ 
                        'button-active': authUser.user.anonymous === true 
                    }" v-bind:disabled="socketSendLoading !== null"></button>
                </ProfileSettingsElement>
                <ProfileSettingsElement name="SOUND VOLUME">
                    <input 
                        v-model="userVolume" 
                        v-on:input="soundSetVolume(userVolume)" 
                        type="range" 
                        min="0" 
                        max="1" 
                        step="0.01" 
                        :style="{ 
                            '--thumbColor': userVolume < 0.01 
                                ? 'rgba(255, 255, 255, 0.08)' 
                                : 'linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%)'  
                        }"
                    >
                </ProfileSettingsElement>
            </div>
        </div>

        <div class="settings-section">
            <div class="section-head">
                <div class="head-name">LINK ACCOUNTS</div>
            </div>
            <div class="section-content">
                <ProfileSettingsElement name="EMAIL" v-bind:info="authUser.user.local === undefined || authUser.user.local.emailVerified === undefined ? 'warning' : 'success'">
                    <button v-if="authUser.user.local === undefined" v-on:click="modalsSetShow('Link')" class="button-link button-email">
                        <div class="button-inner">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                            </svg>
                            LINK EMAIL
                        </div>
                    </button>
                    <button v-else-if="authUser.user.local.emailVerified === undefined" v-on:click="userVerifyButton()" class="button-verify" v-bind:disabled="authSendLoginLoading === true">
                        <div class="button-inner">VERIFY</div>
                    </button>
                    <div v-if="authUser.user.local !== undefined" class="element-info">
                        <span>{{ userShowEmail === false ? '\u2022'.repeat(authUser.user.local.email.length) : authUser.user.local.email }}</span>
                        <button v-on:click="userToggleShowEmail()">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                                <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"/>
                            </svg>
                        </button>
                    </div>
                </ProfileSettingsElement>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ProfileSettingsElement from '@/components/profile/ProfileSettingsElement';

    export default {
        name: 'ProfileSettings',
        components: {
            ProfileSettingsElement
        },
        data() {
            return {
                userVolume: 1,
                userShowEmail: false,
            }
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'soundSetVolume',
                'modalsSetShow',
                'userSendUserAnonymousSocket',  
                'userSendUserDiscordSocket',
                'authSendCredentialsRequest'
            ]),
            userToggleAnonymous() {
                const data = { anonymous: !this.authUser.user.anonymous };
                this.userSendUserAnonymousSocket(data);
            },
            userToggleShowEmail() {
                this.userShowEmail = !this.userShowEmail;
            },
            userVerifyButton() {
                const data = { type: 'verify', email: this.authUser.user.local.email };
                this.authSendCredentialsRequest(data);
            }
        },
        computed: {
            ...mapGetters([
                'soundVolume',
                'authSendLoginLoading',
                'socketSendLoading',
                'authUser' 
            ]),
        },
        created() {
            this.userVolume = this.soundVolume;
        }
    }
</script>

<style scoped>
    .profile-settings {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile-settings .settings-section {
        width: 100%;
        margin-top: 25px;
    }

    .profile-settings .settings-section:first-child {
        margin-top: 0;
    }

    .profile-settings .section-head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
    }

    .profile-settings .head-name,
    .profile-settings .head-action {
        font-size: 13px;
        font-weight: 700;
        color: #5e768e;
    }

    .profile-settings .section-content {
        width: 100%;
        margin-top: 15px;
    }

    .profile-settings .profile-settings-element button.button-toggle {
        width: 48px;
        height: 24px;
        position: relative;
        background: rgba(255, 255, 255, 0.08);
        border-radius: 12px;
        transition: all 0.3s ease;
    }

    .profile-settings .profile-settings-element button.button-toggle::after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: 2px;
        left: 2px;
        background: white;
        border-radius: 50%;
        transition: all 0.3s ease;
    }

    .profile-settings .profile-settings-element button.button-toggle.button-active {
        background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
        box-shadow: 0 0 15px rgba(44, 94, 255, 0.3);
    }

    .profile-settings .profile-settings-element button.button-toggle.button-active::after {
        transform: translateX(24px);
    }

    .profile-settings .profile-settings-element input[type="range"] {
        width: 200px;
        height: 4px;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: rgba(255, 255, 255, 0.08);
        border-radius: 2px;
        outline: none;
    }

    .profile-settings .profile-settings-element input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
        cursor: pointer;
        box-shadow: 0 0 10px rgba(44, 94, 255, 0.3);
        border: 2px solid rgba(255, 255, 255, 0.8);
        transition: all 0.3s ease;
    }

    .profile-settings .profile-settings-element input[type="range"]::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
        cursor: pointer;
        box-shadow: 0 0 10px rgba(44, 94, 255, 0.3);
        border: 2px solid rgba(255, 255, 255, 0.8);
        transition: all 0.3s ease;
    }

    .profile-settings .profile-settings-element input[type="range"]::-webkit-slider-thumb:hover,
    .profile-settings .profile-settings-element input[type="range"]::-moz-range-thumb:hover {
        transform: scale(1.1);
    }

    .profile-settings .profile-settings-element button.button-toggle::before,
    .profile-settings .profile-settings-element input[type="range"]::before {
        display: none;
    }

    .profile-settings .profile-settings-element button.button-link,
    .profile-settings .profile-settings-element button.button-verify {
        height: 36px;
        padding: 0 20px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.08);
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profile-settings .profile-settings-element button.button-link:hover,
    .profile-settings .profile-settings-element button.button-verify:hover {
        background: rgba(44, 94, 255, 0.1);
        border: 1px solid rgba(44, 94, 255, 0.3);
        transform: translateY(-1px);
    }

    .profile-settings .profile-settings-element button.button-link .button-inner,
    .profile-settings .profile-settings-element button.button-verify .button-inner {
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: Montserrat, sans-serif;
        font-size: 13px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.5);
        transition: color 0.3s ease;
    }

    .profile-settings .profile-settings-element button.button-link:hover .button-inner,
    .profile-settings .profile-settings-element button.button-verify:hover .button-inner {
        color: #FFFFFF;
    }

    .profile-settings .profile-settings-element button.button-link .button-inner svg {
        width: 15px;
        height: 15px;
        fill: rgba(255, 255, 255, 0.5);
        transition: fill 0.3s ease;
    }

    .profile-settings .profile-settings-element button.button-link:hover .button-inner svg {
        fill: #FFFFFF;
    }

    .profile-settings .profile-settings-element .element-info {
        height: 36px;
        padding: 0 12px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.08);
        display: flex;
        align-items: center;
        gap: 12px;
        min-width: 200px;
    }

    .profile-settings .profile-settings-element .element-info span {
        font-family: Montserrat, sans-serif;
        font-size: 13px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .profile-settings .profile-settings-element .element-info button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        border-radius: 6px;
        transition: all 0.3s ease;
    }

    .profile-settings .profile-settings-element .element-info button:hover {
        background: rgba(44, 94, 255, 0.1);
    }

    .profile-settings .profile-settings-element .element-info button svg {
        width: 16px;
        height: 16px;
        fill: rgba(255, 255, 255, 0.5);
        transition: fill 0.3s ease;
    }

    .profile-settings .profile-settings-element .element-info button:hover svg {
        fill: #FFFFFF;
    }

    @media only screen and (max-width: 450px) {

        .profile-settings .content-element.element-slider input {
            width: 130px;
        }

    }
</style>