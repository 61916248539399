<template>
  <aside
    id="chat"
    v-bind:class="{
      'chat-open': generalSidebarMobile === 'Chat',
      'chat-rain': generalRain.active !== null,
    }"
  >
    <div class="chat-toggle"></div>
    <div class="chat-header">
      <div class="top-elements">
        <button v-on:click="generalSetSidebarMobile(null)" class="button-close">
          <img src="@/assets/img/icons/close.svg" />
        </button>
        <!--<div class="header-rooms">
          <ChatRoomDropdown />
        </div>-->
        <div class="general-chat">
          <span class="general-chat-title">CHAT</span>
          <RainTipDropdown />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            viewBox="0 0 34 34"
            fill="none"
          >
            <g filter="url(#filter0_d_1625_1282)">
              <path
                d="M19.6041 6.71466C22.7036 6.75822 25.2471 9.26578 25.2471 12.2898C25.2471 15.3449 22.6589 17.8649 19.5211 17.8649C18.588 17.8649 17.6997 17.6409 16.9137 17.2427C15.7186 16.6391 14.76 15.6311 14.236 14.4116C14.1977 14.3244 14.1593 14.2311 14.1274 14.144C13.9101 13.5591 13.795 12.9369 13.795 12.2836C13.795 9.09778 16.3833 6.70844 19.5211 6.70844C19.5466 6.71467 19.5722 6.71466 19.6041 6.71466Z"
                fill="url(#paint0_linear_1625_1282)"
              />
              <path
                d="M11.7501 12.2898C11.7501 12.8779 11.155 13.3281 10.6866 12.9727C9.34249 11.9527 8.47809 10.36 8.47809 8.57511C8.47809 5.49511 11.0407 3 14.2041 3C16.1847 3 15.6859 5.46202 14.2252 6.79993C12.7093 8.18834 11.7501 10.1489 11.7501 12.2898Z"
                fill="url(#paint1_linear_1625_1282)"
              />
              <path
                d="M4.66205 25.6924C6.15406 25.6924 7.47018 24.792 8.33259 23.5741C9.31551 22.186 10.5613 20.9935 11.999 20.063C13.2036 19.2834 13.4955 17.2288 12.7656 15.9932C12.33 15.2557 11.5084 14.7518 10.6965 15.024C6.56413 16.4097 3.55443 20.0293 3.0205 24.3671C2.88629 25.1636 3.4295 25.6924 4.11329 25.6924H4.66205Z"
                fill="url(#paint2_linear_1625_1282)"
              />
              <path
                d="M15.1627 20.6773C12.6895 21.6542 10.6445 23.4338 9.37278 25.6924C8.69538 26.8996 8.24164 28.2436 8.06909 29.6747C7.93489 30.4711 8.47809 31 9.16189 31H29.8867C30.5705 31 31.1137 30.3342 30.9795 29.6747C30.2957 24.0996 25.3877 19.7191 19.5275 19.7191C18.5753 19.7191 17.6167 19.8498 16.7987 20.1173C16.6709 20.1484 16.5622 20.192 16.4664 20.248C16.0126 20.3724 15.5845 20.5093 15.1627 20.6773Z"
                fill="url(#paint3_linear_1625_1282)"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_1625_1282"
                x="0"
                y="0"
                width="34"
                height="34"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="1.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.175 0 0 0 0 0.43625 0 0 0 0 1 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_1625_1282"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_1625_1282"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear_1625_1282"
                x1="17"
                y1="3"
                x2="17"
                y2="31"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#349DFF" />
                <stop offset="1" stop-color="#005EE9" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1625_1282"
                x1="17"
                y1="3"
                x2="17"
                y2="31"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#349DFF" />
                <stop offset="1" stop-color="#005EE9" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1625_1282"
                x1="17"
                y1="3"
                x2="17"
                y2="31"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#349DFF" />
                <stop offset="1" stop-color="#005EE9" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_1625_1282"
                x1="17"
                y1="3"
                x2="17"
                y2="31"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#349DFF" />
                <stop offset="1" stop-color="#005EE9" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div class="status-wrapper">
        <!-- <div class="online-users">
          Online: <span class="count">{{ chatGetOnline }}</span>
        </div> -->
       
      </div>
    </div>
    <div class="chat-content">
      <div
        v-on:scroll="chatHandleScroll()"
        class="content-messages"
        ref="chatMessages"
      >
        <transition name="fade" mode="out-in">
          <div
            v-if="chatMessages.data === null || chatMessages.loading === true"
            class="messages-loading"
            key="loading"
          >
            <div
              v-for="index in 4"
              v-bind:key="index"
              class="loading-placeholder"
            >
              <div class="placeholder-user">
                <div class="user-avatar"></div>
                <div class="user-username"></div>
              </div>
              <div class="placeholder-text"></div>
            </div>
          </div>
          <div v-else class="messages-list" key="list">
            <ChatMessageElement
              v-for="message in chatMessages.data"
              v-bind:key="message._id"
              v-bind:message="message"
            />
          </div>
        </transition>
      </div>
      <div class="content-lock"></div>
      <div class="content-rain">
        <transition name="fade-slide" mode="out-in">
          <RainJoin v-if="generalRain.active !== null" />
        </transition>
      </div>
    </div>
    <div class="chat-footer">
      <div class="footer-input">
        <div class="left-side">
          <!--<button v-on:click="modalsSetShow('ChatRules')" class="button-rules">
            <IconRules/>
          </button>-->
          <input
            v-model="chatMessage"
            v-on:keyup.enter="chatMessageButton"
            type="text"
            placeholder="Type a message..."
          />
        </div>
        <div class="right-side">
          <!--<ChatEmojisDropdown/>-->
          <button
            v-on:click="chatMessageButton()"
            class="button-send"
            v-bind:disabled="socketSendLoading !== null"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
            >
              <g filter="url(#filter0_d_1625_1289)">
                <path
                  d="M19.2923 25.6743L12.1605 23.8154C9.44558 23.1078 9.23111 19.3357 11.8484 18.3249L33.0081 10.1527C34.7855 9.46626 36.5337 11.2145 35.8473 12.9919L27.6752 34.1516C26.6644 36.7689 22.8923 36.5544 22.1846 33.8395L20.3258 26.7077C20.1939 26.2015 19.7985 25.8062 19.2923 25.6743Z"
                  fill="url(#paint0_linear_1625_1289)"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_1625_1289"
                  x="7"
                  y="7"
                  width="32"
                  height="32"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.175 0 0 0 0 0.43625 0 0 0 0 1 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1625_1289"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1625_1289"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_1625_1289"
                  x1="23"
                  y1="10"
                  x2="23"
                  y2="36"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#349DFF" />
                  <stop offset="1" stop-color="#005EE9" />
                </linearGradient>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import IconChatGradient from "@/components/icons/IconChatGradient";
import IconClose from "@/components/icons/IconClose";
import IconUsers from "@/components/icons/IconUsers";
import IconMessage from "@/components/icons/IconMessage";
import IconRules from "@/components/icons/IconRules";
import ChatRoomDropdown from "@/components/chat/ChatRoomDropdown";
import ChatMessageElement from "@/components/chat/ChatMessageElement";
import ChatEmojisDropdown from "@/components/chat/ChatEmojisDropdown";
import RainTipDropdown from "@/components/rain/RainTipDropdown";
import RainJoin from "@/components/rain/RainJoin";

export default {
  name: "Chat",
  components: {
    IconChatGradient,
    IconClose,
    IconUsers,
    IconMessage,
    IconRules,
    ChatRoomDropdown,
    ChatMessageElement,
    ChatEmojisDropdown,
    RainTipDropdown,
    RainJoin,
  },
  data() {
    return {
      chatMessage: "",
    };
  },
  methods: {
    ...mapActions([
      "notificationShow",
      "modalsSetShow",
      "modalsSetData",
      "generalSetSidebarMobile",
      "chatSetScroll",
      "chatGetMessagesSocket",
      "chatSendMessageSocket",
      "chatSendClearSocket",
      "chatSendLockSocket",
      "generalSetUserInfoData",
      "generalGetUserInfoSocket",
      "generalSendRainCreateSocket",
    ]),
    chatScrollToBottom() {
      this.$nextTick(() => {
        let container = this.$refs.chatMessages;
        container.scrollTop = container.scrollHeight;
      });
    },
    chatAddEmoji(emoji) {
      this.chatMessage = this.chatMessage + emoji;
    },
    chatHandleScroll() {
      let container = this.$refs.chatMessages;
      this.chatSetScroll(
        container.scrollHeight -
          (container.scrollTop + container.clientHeight) <
          100
      );
    },
    chatHandleCommand(command) {
      this.generalSetUserInfoData(null);
      const args = command.split(" ");

      if (
        args[0] === "/tip" ||
        (["/mute", "/ban"].includes(args[0]) === true &&
          ["admin", "mod"].includes(this.authUser.user.rank) === true)
      ) {
        if (
          args[1] === undefined ||
          args[1].trim() === "" ||
          args[1].match(/^[0-9a-fA-F]{24}$/) === null
        ) {
          this.notificationShow({
            type: "error",
            message: "You need to provide a valid user id.",
          });
          return;
        }

        const data = { userId: args[1] };
        this.generalGetUserInfoSocket(data);

        if (args[0] === "/tip") {
          this.modalsSetShow("Tip");
        } else if (args[0] === "/mute") {
          this.modalsSetShow("Mute");
        } else if (args[0] === "/ban") {
          this.modalsSetShow("Ban");
        }
      } else if (args[0] === "/rain") {
        const data = { amount: Math.floor(args[1] * 1000) };
        this.generalSendRainCreateSocket(data);
      } else if (args[0] === "/clear") {
        const data = {};
        this.chatSendClearSocket(data);
      } else if (args[0] === "/lock") {
        const data = { value: false };
        this.chatSendLockSocket(data);
      } else if (args[0] === "/unlock") {
        const data = { value: true };
        this.chatSendLockSocket(data);
      }
    },
    chatMessageButton() {
      if (this.authUser.user === null) {
        this.notificationShow({
          type: "error",
          message: "Please sign in to perform this action.",
        });
        return;
      }

      if (
        this.generalSettings.chat.enabled === false &&
        this.authUser.user.rank !== "admin"
      ) {
        this.notificationShow({
          type: "error",
          message:
            "You can not send a chat message because the action is not available at the moment.",
        });
        return;
      }

      if (
        this.generalSettings.chat.mode === "normal" &&
        this.authUser.user.rank !== "admin" &&
        new Date().getTime() - this.chatLastMessage < 3000
      ) {
        this.notificationShow({
          type: "error",
          message: "You can only send 1 message every 3 seconds.",
        });
        return;
      }

      if (
        this.generalSettings.chat.mode === "slow" &&
        this.authUser.user.rank !== "admin" &&
        new Date().getTime() - this.chatLastMessage < 6000
      ) {
        this.notificationShow({
          type: "error",
          message:
            "You can only send 1 message every 6 seconds because the chat is in slow mode.",
        });
        return;
      }

      const message = this.chatMessage.trim();

      if (message === "") {
        this.notificationShow({
          type: "error",
          message: "Please enter a message.",
        });
        return;
      }

      if (["/tip", "/rain"].includes(message.split(" ")[0]) === true) {
        this.chatHandleCommand(message);
      } else if (
        ["mod", "admin"].includes(this.authUser.user.rank) === true &&
        ["/clear", "/lock", "/unlock", "/mute", "/ban"].includes(
          message.split(" ")[0]
        ) === true
      ) {
        this.chatHandleCommand(message);
      } else {
        const data = { message: message };
        this.chatSendMessageSocket(data);
      }

      this.chatMessage = "";
    },
  },
  computed: {
    ...mapGetters([
      "socketSendLoading",
      "generalSidebarMobile",
      "generalSettings",
      "authUser",
      "chatScroll",
      "chatRoom",
      "chatOnline",
      "chatMessages",
      "chatLastMessage",
      "generalRain",
    ]),
    chatGetOnline() {
      let online = 0;

      for (const [key, value] of Object.entries(this.chatOnline)) {
        online = online + value;
      }

      return online;
    },
  },
  watch: {
    "chatMessages.data": {
      handler(state, oldState) {
        const message =
          this.chatMessages.data[this.chatMessages.data.length - 1];

        if (
          this.chatScroll === true ||
          this.chatMessages.data.length === 0 ||
          (this.authUser.user !== null &&
            message !== undefined &&
            message.type === "user" &&
            message.user._id === this.authUser.user._id)
        ) {
          setTimeout(() => {
            this.chatScrollToBottom();
          }, 200);
        }
      },
      deep: true,
    },
    "generalRain.active": {
      handler(state, oldState) {
        setTimeout(() => {
          this.chatScrollToBottom();
        }, 125);
      },
      deep: true,
    },
    generalSidebarMobile: {
      handler(state, oldState) {
        if (this.generalSidebarMobile === "Chat") {
          this.chatScrollToBottom();
        }
      },
    },
  },
  mounted() {
    this.chatScrollToBottom();
  },
};
</script>

<style scoped>
aside#chat {
  width: 300px;
  height: calc(100% - 103px);
  position: fixed;
  top: 100px;
  right: 0;
  border-bottom: 1px solid rgba(121, 121, 121, 0.2);
  background: var(--background-secondary);
  box-shadow: 0px 4px 8.9px 0px rgba(0, 0, 0, 0.13);
  z-index: 40;
  transition: transform 0.3s ease;
  transform: translate(390px, 0);
}

aside#chat.chat-open {
  transform: translate(0, 0);
}

aside#chat .chat-toggle {
  position: absolute;
  display: none;
  bottom: 50px;
  left: -65px;
}

aside#chat .chat-toggle button {
  width: 50px;
  height: 50px;
}

aside#chat .chat-toggle button .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

aside#chat .chat-toggle button .button-inner svg {
  width: 19px;
  height: 19px;
  margin-top: 3px;
  fill: #ffffff;
}

aside#chat .chat-header {
  position: relative;
  padding: 0 15px;
}

aside#chat .chat-header button.button-close img {
  fill: white;
  width: 12px;
  height: 12px;
}

aside#chat .chat-header .top-elements {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
}

aside#chat .general-chat {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  justify-content: space-around;
}


aside#chat .general-chat span {
  text-align: center;
  text-shadow: 0px 0px 3px #2d6fff;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #2d6fff;
}

aside#chat .chat-header .status-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

aside#chat .chat-header .online-users {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-weight: 500;
  font-size: 0.9375rem;
  color: rgba(255, 255, 255, 0.29);
}

aside#chat .chat-header .online-users .count {
  color: #ffffff;
}

aside#chat .chat-header .top-elements .button-close {
  height: fit-content;
  border-radius: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.625rem;
  background: linear-gradient(
      0deg,
      rgba(46, 99, 212, 0) 0%,
      rgba(46, 99, 212, 0.15) 100%
    ),
    #1f2a44;
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset;
  display: flex;
  align-items: center;
}

aside#chat .chat-header .top-elements .button-close img {
  width: 1rem;
}

aside#chat .header-online {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.29);
  font-family: Montserrat, sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 0.5rem;
}

aside#chat .header-online svg {
  margin-right: 9px;
}

aside#chat .header-rooms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

aside#chat .chat-content {
  width: 100%;
  margin-top: 16px;
  padding-bottom: 10px;
  height: calc(100% - 221px);
  position: relative;
}

aside#chat .content-messages {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  transition: height 0.3s ease;
}

aside#chat.chat-rain .chat-content .content-messages {
  height: calc(100% - 145px);
}

aside#chat .content-messages::-webkit-scrollbar-track {
  background-color: transparent;
}

aside#chat .content-messages::-webkit-scrollbar {
  width: 0;
  height: 0;
}

aside#chat .messages-loading {
  width: 100%;
  padding: 0 15px;
}

aside#chat .messages-loading.fade-leave-active {
  transition: opacity 0.2s;
}

aside#chat .messages-loading.fade-leave-to {
  opacity: 0;
}

aside#chat .content-lock {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translate(-50%, 0);
}

aside#chat.chat-rain .content-lock {
  bottom: 157px;
}

aside#chat .content-lock button.button-lock {
  height: 30px;
  position: relative;
  padding: 1px;
  z-index: 1;
}

aside#chat .content-lock button.button-lock .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
}

aside#chat .content-rain {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 15px 0 15px;
  z-index: 1;
}

aside#chat .content-rain .fade-slide-enter-active,
aside#chat .content-rain .fade-slide-leave-active {
  transition: all 0.3s ease;
}

aside#chat .content-rain .fade-slide-enter,
aside#chat .content-rain .fade-slide-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}

aside#chat .loading-placeholder {
  width: 100%;
  margin-top: 15px;
  padding-bottom: 15px;
}

aside#chat .loading-placeholder:first-of-type {
  margin-top: 0;
}

aside#chat .placeholder-user {
  display: flex;
  align-items: center;
}

aside#chat .user-avatar {
  width: 37px;
  height: 37px;
  position: relative;
  margin-right: 13px;
  border-radius: 50%;
  overflow: hidden;
}

aside#chat .user-username {
  width: 75px;
  height: 20px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

aside#chat .placeholder-text {
  width: 100%;
  height: 19px;
  position: relative;
  margin-top: 8px;
  border-radius: 5px;
  overflow: hidden;
}

aside#chat .messages-list {
  width: 100%;
  height: 100%;
  padding: 0 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

aside#chat .messages-list.fade-enter-active {
  transition: opacity 0.1s;
}

aside#chat .messages-list.fade-enter-from {
  opacity: 0;
}

aside#chat .chat-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

aside#chat .footer-input {
  padding: 0 0.6rem 0 0.75rem;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  border-radius: 0.75rem;
  border: 1px solid rgba(232, 234, 237, 0.22);
  background: var(--background-primary);
}

.footer-input .left-side {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.footer-input .right-side {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 100%;
}

aside#chat .footer-input input {
  width: 95%;
  height: 100%;
  color: #ffffff;
  background-color: transparent;
  font-family: Montserrat, sans-serif;
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

aside#chat .footer-input input::placeholder {
  color: rgba(232, 234, 237, 0.22);
}

aside#chat .footer-input button.button-send {
  display: flex;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  position: relative;
}

aside#chat .footer-input button.button-send::before {
  content: "";
  position: absolute;
  left: -8px;
  height: 90%;
  width: 3px;
  background: linear-gradient(180deg, #349dff 0%, #005ee9 100%);
  border-radius: 2px;
}

aside#chat .footer-input button.button-rules {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  opacity: 50%;
  transition: ease-in-out 0.2s;
}

aside#chat .footer-input button.button-rules:hover {
  opacity: 100%;
}

aside#chat .footer-actions {
  display: flex;
  align-items: center;
}

aside#chat .footer-actions button.button-rules {
  display: flex;
  align-items: center;
}

aside#chat .footer-actions button.button-rules {
  margin-right: 15px;
  color: white;
}

aside#chat .footer-actions button.button-rules svg {
  margin-right: 7px;
}

@keyframes loading_animation {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media only screen and (max-width: 1500px) {
  aside#chat {
    position: fixed;
    transform: translate(100%, 0);
  }
}

@media only screen and (max-width: 1175px) {
  aside#chat {
    position: fixed;
    width: 100%;
    height: calc(100% - 178px);
    transform: translate(100%, 0);
  }

  aside#chat .chat-toggle {
    display: none;
  }
}

</style>
