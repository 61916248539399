import Vue from 'vue';
import VueMeta from 'vue-meta';
import axios from 'axios';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/css/global.css';

import Hotjar from '@hotjar/browser';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { 
    faBorderAll,
    faDiceFive,
    faCrown,
    faTrophy,
    faTv,
    faBolt,
    faBullseye,
    faSearch,
    faTimes,
    faChevronDown,
    faStar,
    faChevronLeft,
    faExpand,
    faCompress,
    faExclamationCircle,
    faGamepad,
    faGift,
    faUser,
    faPercentage,
    faChartLine,
    faArrowLeft,
    faDice,
    faBomb,
    faBoxOpen,
    faShield,
    faFutbol,
    faDiceD6,
    faCoins,
    faGun,
    faChessRook
} from '@fortawesome/free-solid-svg-icons';

// Add all icons to library
library.add(
    faBorderAll,
    faDiceFive,
    faCrown,
    faTrophy,
    faTv,
    faBolt,
    faBullseye,
    faSearch,
    faTimes,
    faChevronDown,
    faStar,
    faChevronLeft,
    faExpand,
    faCompress,
    faExclamationCircle,
    faGamepad,
    faGift,
    faUser,
    faPercentage,
    faChartLine,
    faArrowLeft,
    faDice,
    faBomb,
    faBoxOpen,
    faShield,
    faFutbol,
    faDiceD6,
    faCoins,
    faGun,
    faChessRook
);

// Register Font Awesome component
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueMeta);
Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

const token = localStorage.getItem('token');
if (token !== undefined && token !== null) {
    axios.defaults.headers.common['x-auth-token'] = token;
}

// Initialize Hotjar
const siteId = process.env.VUE_APP_HOTJAR_ID;
const hotjarVersion = process.env.VUE_APP_HOTJAR_VERSION;
Hotjar.init(siteId, hotjarVersion);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
