export const depositConfig = {
  // Pagination settings
  pagination: {
    itemsPerPage: 6,
    loadMoreThreshold: 50 // pixels from bottom to trigger load more
  },

  // Custom categories configuration
  categories: {
    MEME: {
      name: 'Meme Coins',
      coins: ['DOGE', 'SHIB', 'PEPE', 'FLOKI', 'BONE'],
      icon: 'meme' // corresponds to /assets/icons/networks/meme.svg
    },
    DEFI: {
      name: 'DeFi',
      coins: ['UNI', 'AAVE', 'CAKE', 'SUSHI', 'CRV'],
      icon: 'defi'
    },
    // Add more custom categories as needed
  },

  // Network categories mapping
  networkCategories: {
    ETH: {
      name: 'Ethereum',
      networks: ['eth', 'arbitrum', 'op', 'zksync', 'base'],
      icon: 'eth'
    },
    BSC: {
      name: 'Binance',
      networks: ['bsc', 'bnb'],
      icon: 'bsc'
    },
    TRX: {
      name: 'TRON',
      networks: ['trx', 'tron'],
      icon: 'trx'
    },
    SOL: {
      name: 'Solana',
      networks: ['sol', 'solana'],
      icon: 'sol'
    },
    BTC: {
      name: 'Bitcoin',
      networks: ['btc', 'bitcoin'],
      icon: 'btc'
    }
  },

  // Featured coins (will be shown first)
  featuredCoins: ['BTC', 'ETH', 'USDT', 'SOL', 'TRX', 'BNB']
} 