<template>
    <div class="code-card create-code-section">
        <div class="card-header">
            <span>CREATE AFFILIATE CODE</span>
        </div>
        <div class="card-content">
            <div class="input-group">
                <input 
                    v-model="affiliatesCode" 
                    type="text" 
                    placeholder="Enter your referral code" 
                    class="code-input"
                />
                <button 
                    @click="createCode" 
                    :disabled="socketSendLoading !== null"
                    class="set-button"
                >
                    <transition name="fade" mode="out-in">
                        <ButtonLoading 
                            v-if="socketSendLoading === 'AffiliatesCode'" 
                            key="loading" 
                        />
                        <span v-else key="text">CREATE CODE</span>
                    </transition>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ButtonLoading from '@/components/ButtonLoading';
import '@/components/affiliates/code/styles/CardStyles.vue';

export default {
    name: 'CreateAffiliateCode',
    components: {
        ButtonLoading
    },
    data() {
        return {
            affiliatesCode: ''
        }
    },
    computed: {
        ...mapGetters(['socketSendLoading'])
    },
    methods: {
        ...mapActions(['notificationShow', 'affiliatesSendCodeSocket']),
        createCode() {
            if(this.affiliatesCode === null || this.affiliatesCode.trim() === '' || this.affiliatesCode.trim().length < 2) {
                this.notificationShow({ type: 'error', message: 'Your entered affiliate code is invalid.' });
                return;
            }

            const data = { code: this.affiliatesCode };
            this.affiliatesSendCodeSocket(data);
            this.affiliatesCode = '';
        }
    }
}
</script>

<style scoped>
.input-group {
    display: flex;
    gap: 12px;
}

.code-input {
    flex: 1;
    height: 48px;
    background: rgba(13, 23, 39, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 0 16px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.code-input:focus {
    border-color: rgba(44, 94, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(44, 94, 255, 0.2);
}

.code-input::placeholder {
    color: rgba(255, 255, 255, 0.3);
}

.set-button {
    height: 48px;
    padding: 0 32px;
    background: linear-gradient(135deg, #2C5EFF 0%, #1A3ECC 100%);
    border-radius: 12px;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.05em;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(44, 94, 255, 0.3);
}

.set-button:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 6px 16px rgba(44, 94, 255, 0.4);
}

.set-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
    .input-group {
        flex-direction: column;
    }

    .set-button {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .code-input {
        height: 44px;
        font-size: 13px;
    }

    .set-button {
        height: 44px;
        font-size: 13px;
    }
}
</style> 