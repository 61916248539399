import axios from 'axios'

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL || 'http://localhost:5000';

// Helper function to create Basic Auth token
const createBasicAuthToken = () => {
  // Use EVA credentials from environment variables
  const credentials = `${process.env.VUE_APP_EVA_USERNAME}:${process.env.VUE_APP_EVA_PASSWORD}`;
  return Buffer.from(credentials).toString('base64');
};

class EVASlotsGameService {
  constructor() {
    if (EVASlotsGameService.instance) {
      return EVASlotsGameService.instance;
    }

    // For internal API calls (games, etc)
    this.api = axios.create({
      baseURL: `${API_BASE_URL}/api/eva-slots`,
      timeout: 30000,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    // For EVA API calls (players/transactions)
    this.evaApi = axios.create({
      baseURL: `${API_BASE_URL}/api/v1`,
      timeout: 30000,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Basic ${createBasicAuthToken()}`,
        'X-Partner-Key': process.env.VUE_APP_EVA_PARTNER_KEY
      }
    });

    // Add request interceptor for auth token and headers
    const addHeaders = config => {
      // Ensure Content-Type is exactly 'application/json'
      config.headers['Content-Type'] = 'application/json';
      
      // Add auth token if not present
      if (!config.headers['Authorization']) {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      return config;
    };

    this.api.interceptors.request.use(addHeaders);
    this.evaApi.interceptors.request.use(addHeaders);

    // Add response interceptor for error handling
    const handleResponse = response => {
      if (response.data.success === false) {
        throw new Error(response.data.error?.message || 'API request failed');
      }
      return response.data.data || response.data;
    };

    const handleError = error => {
      console.error('[EVA API] Error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });

      if (error.response?.status === 401) {
        console.warn('[EVA API] Authentication error, clearing token');
        localStorage.removeItem('token');
      }

      // Format error according to EVA's specification
      const evaError = {
        at: new Date().toISOString(),
        errorCode: error.response?.data?.code || 'error.system.unexpected',
        message: error.response?.data?.message || error.message
      };

      throw evaError;
    };

    this.api.interceptors.response.use(handleResponse, handleError);
    this.evaApi.interceptors.response.use(response => response.data, handleError);

    EVASlotsGameService.instance = this;
    return EVASlotsGameService.instance;
  }

  // Get games list with filters (internal API)
  async getGames(params = {}) {
    try {
      // Clean and validate parameters
      const cleanParams = {
        size: parseInt(params.size || 500),
        page: parseInt(params.page || 0)
      };

      // Only add these if they are explicitly set
      if (params.channel) cleanParams.channel = params.channel;
      if (params.isActive !== undefined) cleanParams.isActive = params.isActive;
      if (params.gameProvider) cleanParams.gameProvider = params.gameProvider;
      if (params.productType) cleanParams.productType = params.productType;
      if (params.translationKey) cleanParams.translationKey = params.translationKey;
      if (params.gameId) cleanParams.gameId = params.gameId;

      console.log('[EVA] Fetching games with params:', cleanParams);

      // Check if we're filtering
      const isFiltering = !!(cleanParams.translationKey || cleanParams.productType || cleanParams.gameProvider || cleanParams.gameId);

      // If filtering by category, fetch all pages
      if (isFiltering) {
        console.log('[EVA] Filtering mode detected, fetching all pages');
        
        // First request to get total pages
        const firstResponse = await this.api.get('/games', {
          params: cleanParams
        });
        console.log('[EVA] Initial response:', firstResponse);

        const totalPages = firstResponse.totalPages || 1;
        let allItems = firstResponse.items || [];

        // If there are more pages, fetch them in parallel
        if (totalPages > 1) {
          console.log(`[EVA] Fetching remaining ${totalPages - 1} pages in parallel`);
          
          const remainingRequests = [];
          for (let page = 1; page < totalPages; page++) {
            remainingRequests.push(
              this.api.get('/games', {
                params: {
                  ...cleanParams,
                  page
                }
              })
            );
          }

          // Wait for all remaining pages
          const remainingResponses = await Promise.all(remainingRequests);
          
          // Combine all items
          remainingResponses.forEach(response => {
            if (response.items && response.items.length > 0) {
              allItems = [...allItems, ...response.items];
            }
          });

          console.log('[EVA] All pages fetched successfully:', {
            totalPages,
            totalItems: allItems.length,
            itemsPerPage: cleanParams.size
          });
        }

        // Return combined result
        return {
          items: allItems,
          totalItems: allItems.length,
          currentPage: 0,
          totalPages: 1 // Since we're returning all items at once
        };
      }

      // For non-filtering requests, use regular pagination
      console.log('[EVA] Regular mode, using pagination:', cleanParams);
      const response = await this.api.get('/games', { params: cleanParams });

      return {
        items: response.items || [],
        totalItems: response.totalItems || response.total || 0,
        currentPage: cleanParams.page,
        totalPages: response.totalPages || 1
      };
    } catch (error) {
      console.error('[EVA] Failed to fetch games:', {
        error: error.message,
        params: params,
        stack: error.stack
      });
      throw error;
    }
  }

  // Get game details (internal API)
  async getGame(gameId) {
    try {
      return await this.api.get(`/games/${gameId}`);
    } catch (error) {
      console.error('[EVA] Failed to fetch game details:', error);
      throw error;
    }
  }

  // Create session (internal API)
  async createSession(data) {
    try {
      return await this.api.post('/sessions', data);
    } catch (error) {
      console.error('[EVA] Failed to create session:', error);
      throw error;
    }
  }

  // Get player balance (EVA API)
  async getPlayerBalance(playerId) {
    try {
      console.log('[EVA Wallet] Getting player balance', { playerId });
      const response = await this.evaApi.get(`/players/accounts/${playerId}`, {
        params: {
          providerId: process.env.VUE_APP_EVA_PROVIDER_ID
        }
      });
      console.log('[EVA Wallet] Balance response:', response);
      
      // EVA response is already in the correct format
      return {
        success: true,
        data: response
      };
    } catch (error) {
      console.error('[EVA Wallet] Failed to get balance:', error);
      throw error;
    }
  }

  // Launch game
  async getLaunchURL(gameId, options = {}) {
    try {
      if (!gameId) {
        throw new Error('Game ID is required');
      }

      // Build EVA launch URL directly
      const params = new URLSearchParams();
      params.append('gameId', gameId);
      params.append('partnerKey', process.env.VUE_APP_EVA_PARTNER_KEY);
      params.append('channel', options.channel || 'desktop');
      params.append('language', options.language || 'en');
      
      // Use provided returnUrl, env variable, or fallback
      const lobbyUrl = options.returnUrl || process.env.VUE_APP_EVA_LOBBY_URL || 'https://google.com';
      params.append('lobbyUrl', lobbyUrl);

      // Add session token for real money mode
      if (!options.demo && options.sessionToken) {
        params.append('sessionToken', options.sessionToken);
      }

      // Construct direct EVA launch URL
      const launchUrl = `${process.env.VUE_APP_EVA_LAUNCHER_URL}?${params.toString()}`;

      console.log('[EVA] Direct launch URL constructed:', { 
        hasUrl: true,
        urlLength: launchUrl.length,
        isDemo: !!options.demo,
        params: Object.fromEntries(params.entries())
      });

      return {
        launchUrl,
        isDemo: !!options.demo
      };
    } catch (error) {
      console.error('[EVA] Failed to construct game launch URL:', error);
      throw error;
    }
  }

  // Launch game with proper game object
  async launchGame(params) {
    try {
      console.group('[EVA Wallet] Launching game');
      const { gameId, options = {} } = params;
      
      console.log('Launch request:', {
        gameId,
        options: {
          ...options,
          auth: options.auth ? {
            hasToken: !!options.auth.token,
            userId: options.auth.userId
          } : null,
          demo: options.demo
        },
        timestamp: new Date().toISOString()
      });

      // Validate wallet state if not in demo mode
      if (!options.demo && options.auth?.userId) {
        console.log('[EVA Wallet] Validating wallet state');
        try {
          const response = await this.getPlayerBalance(options.auth.userId);
          if (!response || !response.success || response.data?.balance === undefined) {
            throw new Error('Invalid balance response');
          }

          console.log('[EVA Wallet] Balance validation successful', {
            hasBalance: response.data.balance.main > 0,
            balance: response.data.balance,
            currency: response.data.currency
          });
        } catch (error) {
          console.error('[EVA Wallet] Balance validation failed:', {
            error: error.message,
            userId: options.auth.userId,
            timestamp: new Date().toISOString()
          });
          throw new Error('Failed to validate wallet state');
        }
      }

      if (!gameId) {
        console.error('[EVA Wallet] Game ID missing');
        console.groupEnd();
        throw new Error('Game ID is required');
      }

      // Store return URL for later
      if (options.returnUrl) {
        console.log('[EVA Wallet] Storing return URL:', options.returnUrl);
        sessionStorage.setItem('returnToLobby', options.returnUrl);
      }

      // Check authentication from provided auth data
      const isAuthenticated = !!(options.auth?.token && options.auth?.userId);
      console.log('[EVA Wallet] Auth state:', { 
        isAuthenticated,
        hasToken: !!options.auth?.token,
        userId: options.auth?.userId,
        requestedDemo: options.demo
      });

      // Force demo mode if not authenticated
      const isDemo = !isAuthenticated || options.demo;
      let sessionToken = null;
      console.log('[EVA Wallet] Game mode determination:', { 
        isDemo, 
        forcedDemo: !isAuthenticated,
        requestedDemo: options.demo,
        willAttemptSession: isAuthenticated && !isDemo
      });

      // Only get session token if authenticated and not in demo mode
      if (isAuthenticated && !isDemo) {
        try {
          console.log('[EVA Wallet] Requesting session token for user:', {
            userId: options.auth.userId,
            gameId,
            hasToken: !!options.auth.token
          });

          // Get session token from auth service
          const session = await this.api.post('/sessions', {
            gameId,
            userId: options.auth.userId,
            provider: options.provider
          }, {
            headers: {
              'Authorization': `Bearer ${options.auth.token}`
            }
          });

          // Extract session token from response (handle both formats)
          sessionToken = session.token || session.sessionToken || session.data?.token || session.data?.sessionToken;
          console.log('[EVA Wallet] Session token response:', { 
            received: !!session,
            hasToken: !!sessionToken,
            tokenLength: sessionToken?.length,
            provider: options.provider,
            session
          });

          if (!sessionToken) {
            console.error('[EVA Wallet] Session response format:', session);
            throw new Error('No session token received from server');
          }
        } catch (error) {
          console.error('[EVA Wallet] Session token request failed:', {
            error: error.message,
            response: error.response?.data,
            status: error.response?.status,
            auth: {
              hasToken: !!options.auth?.token,
              userId: options.auth?.userId
            }
          });
          console.groupEnd();
          throw new Error('Failed to authenticate game session');
        }
      }

      // Get launch URL with session token if available
      const launchData = await this.getLaunchURL(gameId, {
        demo: isDemo,
        language: options.language || 'en',
        sessionToken,
        returnUrl: options.returnUrl
      });

      console.log('[EVA Wallet] Launch URL received:', { 
        hasUrl: !!launchData.launchUrl,
        urlLength: launchData.launchUrl?.length,
        isDemo: launchData.isDemo
      });

      console.groupEnd();
      return launchData;
    } catch (error) {
      console.error('[EVA Wallet] Game launch failed:', error);
      console.groupEnd();
      throw error;
    }
  }

  // End game session
  async endSession(sessionToken) {
    try {
      await this.api.delete(`/sessions/${sessionToken}`);
    } catch (error) {
      console.error('[EVA] Failed to end session:', error);
      throw error;
    }
  }

  // Get player history with enhanced logging
  async getPlayerHistory(playerId, params = {}) {
    try {
      console.group('[EVA Wallet] Getting player history');
      console.log('Request:', { playerId, params });
      
      const response = await this.api.get(`/players/${playerId}/history`, { params });
      
      console.log('Response:', {
        success: true,
        historyCount: response.items?.length,
        params,
        timestamp: new Date().toISOString()
      });
      console.groupEnd();
      
      return response;
    } catch (error) {
      console.error('[EVA Wallet] Failed to get player history:', {
        error: error.message,
        playerId,
        params,
        response: error.response?.data,
        status: error.response?.status,
        timestamp: new Date().toISOString()
      });
      console.groupEnd();
      throw error;
    }
  }

  // Get player session info (EVA API)
  async getPlayerSessionInfo(sessionToken) {
    try {
      console.log('[EVA Wallet] Getting session info', { sessionToken });
      const response = await this.evaApi.get(`/players/sessions/${sessionToken}`, {
        params: {
          providerId: process.env.VUE_APP_EVA_PROVIDER_ID
        }
      });
      console.log('[EVA Wallet] Session info response:', response);
      return response;
    } catch (error) {
      console.error('[EVA Wallet] Failed to get session info:', error);
      throw error;
    }
  }

  // Handle bet transaction (EVA API)
  async handleBet(data) {
    try {
      console.log('[EVA Wallet] Processing bet', data);
      const response = await this.evaApi.post('/transactions/bet', {
        amount: Math.floor(data.amount * 100), // Convert to cents
        currency: data.currency,
        gameId: data.gameId,
        playerId: data.playerId,
        provider: data.provider, // Use the provider from the game data
        reason: 'bet',
        roundClosed: data.roundClosed || false,
        roundId: data.roundId,
        sessionToken: data.sessionToken,
        txId: data.txId,
        ...(data.sideSplit && { sideSplit: {
          base: Math.floor(data.sideSplit.base * 100),
          side: Math.floor(data.sideSplit.side * 100)
        }})
      });
      console.log('[EVA Wallet] Bet response:', response);
      return response;
    } catch (error) {
      console.error('[EVA Wallet] Failed to process bet:', error);
      throw error;
    }
  }

  // Get single game by provider and translation key
  async getSingleGame(provider, translationKey, gameId) {
    try {
      console.log('[EVA] Fetching single game:', { provider, translationKey, gameId });
      
      // Use the exact parameters we want
      const params = {
        size: 1,
        page: 0,
        gameProvider: provider,
        translationKey: translationKey
      };

      // Remove any undefined parameters
      Object.keys(params).forEach(key => {
        if (params[key] === undefined) {
          delete params[key];
        }
      });

      console.log('[EVA] Request params:', params);
      const response = await this.api.get('/games', { params });
      
      if (!response.items || response.items.length === 0) {
        throw new Error(`Game not found: ${translationKey}`);
      }

      return response.items[0];
    } catch (error) {
      console.error('[EVA] Failed to fetch single game:', error);
      throw error;
    }
  }

  // Get multiple predefined games
  async getPredefinedGames(gamesList) {
    try {
      console.log('[EVA] Fetching predefined games:', gamesList.length);
      
      const gamePromises = gamesList.map(game => 
        this.getSingleGame(game.provider, game.translationKey, game.gameId)
      );

      const games = await Promise.all(
        gamePromises.map(p => p.catch(error => {
          console.error('[EVA] Error fetching game:', error);
          return null;
        }))
      );

      // Filter out failed requests
      return games.filter(game => game !== null);
    } catch (error) {
      console.error('[EVA] Failed to fetch predefined games:', error);
      throw error;
    }
  }
}

// Create and export singleton instance
const instance = new EVASlotsGameService();
Object.freeze(instance);

export default instance;

// Export constants for product types
export const PRODUCT_TYPES = {
  SLOTS: 'slots',
  VIRTUAL_SPORTS: 'virtual-sports',
  TV_GAMES: 'tv-games',
  LIVE_CASINO: 'live-casino',
  INSTANT_GAMES: 'instant-games',
  BINGO: 'bingo'
};

// Export constants for channels
export const CHANNELS = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  OMNI: 'omni'
}; 