<template>
  <div class="game-restrictions-form">
    <!-- Product Type Restrictions -->
    <div class="form-group">
      <label>Restricted Product Types</label>
      <div class="product-types-grid">
        <div v-for="(value, key) in PRODUCT_TYPES" 
             :key="key"
             class="product-type-toggle"
             :class="{ 
               active: !allowedTypes.includes(value),
               'is-slots': value === 'slots'
             }"
             @click="toggleProductType(value)">
          {{ formatProductType(key) }}
        </div>
      </div>
      <small class="help-text">Selected types are restricted from using bonus. Slots cannot be restricted.</small>
    </div>

    <div class="form-group">
      <label>Eligible Providers</label>
      <div class="tags-input">
        <div class="tags-list">
          <span v-for="provider in providers" 
                :key="provider" 
                class="tag">
            {{ provider }}
            <button @click="removeProvider(provider)" class="tag-remove">×</button>
          </span>
        </div>
        <input type="text" 
               v-model="newProvider"
               @keydown.enter.prevent="addProvider"
               placeholder="Type and press Enter to add provider">
      </div>
    </div>

    <div class="form-group">
      <label>Specific Eligible Games (Optional)</label>
      <div class="selected-games">
        <div v-for="game in specificGames" 
             :key="game.gameId" 
             class="game-tag">
          <img :src="game.imageUrl" :alt="game.translationKey" class="game-thumbnail">
          <span class="game-name">{{ game.translationKey }}</span>
          <button @click="removeSpecificGame(game)" class="tag-remove">×</button>
        </div>
      </div>
      <button type="button" 
              class="btn-select-games"
              @click="openGameSelector('specific')">
        Select Games
      </button>
    </div>

    <div class="form-group">
      <label>Excluded Games (Optional)</label>
      <div class="selected-games">
        <div v-for="game in excludedGames" 
             :key="game.gameId" 
             class="game-tag">
          <img :src="game.imageUrl" :alt="game.translationKey" class="game-thumbnail">
          <span class="game-name">{{ game.translationKey }}</span>
          <button @click="removeExcludedGame(game)" class="tag-remove">×</button>
        </div>
      </div>
      <button type="button" 
              class="btn-select-games"
              @click="openGameSelector('excluded')">
        Select Games
      </button>
    </div>

    <modal-game-selector
      v-if="showGameSelector"
      :show="showGameSelector"
      :selection-mode="gameSelectorMode"
      :initial-selected-games="getInitialSelectedGames()"
      @close="closeGameSelector"
      @games-selected="handleGamesSelected"
    />
  </div>
</template>

<script>
import { PRODUCT_TYPES } from '@/constants/bonus'
import ModalGameSelector from '@/components/modals/ModalGameSelector.vue'

export default {
  name: 'GameRestrictionsForm',
  components: {
    ModalGameSelector
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      PRODUCT_TYPES,
      newProvider: '',
      showGameSelector: false,
      gameSelectorMode: 'specific'
    }
  },
  computed: {
    allowedTypes: {
      get() {
        return this.value.allowedProductTypes || ['slots']
      },
      set(value) {
        this.$emit('input', { ...this.value, allowedProductTypes: value })
      }
    },
    providers: {
      get() {
        return this.value.providers || []
      },
      set(value) {
        this.$emit('input', { ...this.value, providers: value })
      }
    },
    specificGames: {
      get() {
        return this.value.specificGames || []
      },
      set(value) {
        this.$emit('input', { ...this.value, specificGames: value })
      }
    },
    excludedGames: {
      get() {
        return this.value.excludedGames || []
      },
      set(value) {
        this.$emit('input', { ...this.value, excludedGames: value })
      }
    }
  },
  methods: {
    toggleProductType(type) {
      if (type === 'slots') return; // Can't toggle slots
      
      const types = [...this.allowedTypes];
      if (!types.includes(type)) {
        types.push(type);
      } else {
        const index = types.indexOf(type);
        if (index > -1) {
          types.splice(index, 1);
        }
      }
      this.allowedTypes = types;
    },
    addProvider() {
      if (this.newProvider.trim()) {
        this.providers = [...this.providers, this.newProvider.trim()];
        this.newProvider = '';
      }
    },
    removeProvider(provider) {
      this.providers = this.providers.filter(p => p !== provider);
    },
    removeSpecificGame(game) {
      this.specificGames = this.specificGames.filter(g => g.gameId !== game.gameId);
    },
    removeExcludedGame(game) {
      this.excludedGames = this.excludedGames.filter(g => g.gameId !== game.gameId);
    },
    openGameSelector(mode) {
      this.gameSelectorMode = mode;
      this.showGameSelector = true;
    },
    closeGameSelector() {
      this.showGameSelector = false;
    },
    getInitialSelectedGames() {
      return this.gameSelectorMode === 'specific' 
        ? this.specificGames
        : this.excludedGames;
    },
    handleGamesSelected(games) {
      if (this.gameSelectorMode === 'specific') {
        this.specificGames = games;
      } else {
        this.excludedGames = games;
      }
    },
    formatProductType(type) {
      return type.split('_').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ).join(' ');
    }
  }
}
</script>

<style scoped>
.product-types-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  margin-bottom: 8px;
}

.product-type-toggle {
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease;
  user-select: none;
}

.product-type-toggle:not(.is-slots):hover {
  background: rgba(255, 255, 255, 0.1);
}

.product-type-toggle.active {
  background: rgba(255, 59, 48, 0.1);
  border-color: rgba(255, 59, 48, 0.2);
  color: #ff3b30;
}

.product-type-toggle.is-slots {
  opacity: 0.5;
  cursor: not-allowed;
  background: rgba(76, 175, 80, 0.1);
  border-color: rgba(76, 175, 80, 0.2);
  color: #4CAF50;
}

.help-text {
  display: block;
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

.form-group {
  margin-bottom: 16px;
}

.tags-input {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 8px;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: rgba(44, 94, 255, 0.1);
  border: 1px solid rgba(44, 94, 255, 0.2);
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
}

.tag-remove {
  background: none;
  border: none;
  color: inherit;
  font-size: 16px;
  padding: 0;
  margin-left: 4px;
  cursor: pointer;
  opacity: 0.7;
}

.tag-remove:hover {
  opacity: 1;
}

.selected-games {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
  min-height: 44px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.game-tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px 4px 4px;
  background: rgba(44, 94, 255, 0.1);
  border: 1px solid rgba(44, 94, 255, 0.2);
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
}

.game-thumbnail {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
}

.game-name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-select-games {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(44, 94, 255, 0.1);
  border: 1px solid rgba(44, 94, 255, 0.2);
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-select-games:hover {
  background: rgba(44, 94, 255, 0.2);
  transform: translateY(-1px);
}
</style> 