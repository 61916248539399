<template>
    <div class="fair-unbox">
        <div class="unbox-title">UNBOX</div>
        <div class="unbox-text">
            <p>Each unbox result is determined using three key elements:</p>
            <p><span class="highlight">Server Seed:</span> Our secret code that's revealed after your unbox.</p>
            <p><span class="highlight">Nonce:</span> A unique number for each unbox attempt.</p>
            <p><span class="highlight">Client Seed:</span> Your customizable seed that you can change anytime.</p>
            <p>This creates a verifiable and fair unboxing experience every time.</p>
        </div>
        <div class="unbox-code">
            <pre>
                <code>
const crypto = require('crypto');

const serverSeed = '4c60603de55e3b85fe67450aa124ec34509cb73b0f1fde15';
const nonce = 1;
const clientSeed = 'customClientSeed';

const fairGetUnboxOutcome = () => {
    const combined = `${serverSeed}-${nonce}-${clientSeed}`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');
    const outcome = parseInt(hash.substr(0, 8), 16) % 100000;

    console.log(outcome);
}

fairGetUnboxOutcome();
                </code>
            </pre>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FairUnbox'
    }
</script>

<style scoped>
    .fair-unbox {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
        border-radius: 10px;
    }

    .unbox-title {
        font-size: 24px;
        font-weight: 900;
        font-family: Montserrat, sans-serif;
        background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .unbox-text {
        margin-top: 12px;
        width: 100%;
    }

    .unbox-text p {
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
        margin-bottom: 8px;
        font-family: Montserrat, sans-serif;
    }

    .unbox-text p .highlight {
        color: #2C5EFF;
        font-weight: 700;
    }

    .unbox-code {
        width: 100%;
        margin-top: 25px;
        padding: 16px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
    }

    .unbox-code pre {
        width: 100%;
        height: 225px;
        overflow: auto;
    }

    .unbox-code pre code {
        font-family: 'Courier New', monospace;
        font-size: 13px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .unbox-code pre::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    .unbox-code pre::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
    }

    .unbox-code pre::-webkit-scrollbar-thumb {
        background: #2C5EFF;
        border-radius: 2px;
    }

    @media only screen and (max-width: 768px) {
        .fair-unbox {
            padding: 15px;
        }
        
        .unbox-title {
            font-size: 20px;
        }
        
        .unbox-code {
            padding: 12px;
        }
    }
</style>