<template>
  <Transition name="fade">
    <div v-if="!cookiesAccepted && !cookiesDeclined" class="cookie-consent">
      <div class="cookie-content">
        <div class="cookie-text">
          <h3>🍪 Cookie Notice</h3>
          <p>We use cookies to enhance your experience and analyze our website traffic. By clicking "Accept", you consent to our use of cookies.</p>
        </div>
        <div class="cookie-actions">
          <button class="btn-accept" @click="acceptCookies">Accept</button>
          <button class="btn-decline" @click="declineCookies">Decline</button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'CookieConsent',
  data() {
    return {
      cookiesAccepted: false,
      cookiesDeclined: false
    }
  },
  created() {
    // Check if user has already made a choice and if it hasn't expired
    const acceptedTime = localStorage.getItem('cookiesAcceptedTime')
    const declinedTime = localStorage.getItem('cookiesDeclinedTime')
    const thirtyDays = 30 * 24 * 60 * 60 * 1000 // 30 days in milliseconds

    if (acceptedTime) {
      const elapsed = Date.now() - parseInt(acceptedTime)
      this.cookiesAccepted = elapsed < thirtyDays
      if (!this.cookiesAccepted) {
        // Clear expired consent
        localStorage.removeItem('cookiesAccepted')
        localStorage.removeItem('cookiesAcceptedTime')
      }
    }

    if (declinedTime) {
      const elapsed = Date.now() - parseInt(declinedTime)
      this.cookiesDeclined = elapsed < thirtyDays
      if (!this.cookiesDeclined) {
        // Clear expired decline
        localStorage.removeItem('cookiesDeclined')
        localStorage.removeItem('cookiesDeclinedTime')
      }
    }
  },
  methods: {
    acceptCookies() {
      this.cookiesAccepted = true
      localStorage.setItem('cookiesAccepted', 'true')
      localStorage.setItem('cookiesAcceptedTime', Date.now().toString())
      localStorage.removeItem('cookiesDeclined')
      localStorage.removeItem('cookiesDeclinedTime')
      this.$store.dispatch('general/setCookieConsent', true)
    },
    declineCookies() {
      this.cookiesDeclined = true
      localStorage.setItem('cookiesDeclined', 'true')
      localStorage.setItem('cookiesDeclinedTime', Date.now().toString())
      localStorage.removeItem('cookiesAccepted')
      localStorage.removeItem('cookiesAcceptedTime')
      this.$store.dispatch('general/setCookieConsent', false)
    }
  }
}
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(28, 28, 35, 0.95);
  backdrop-filter: blur(10px);
  z-index: 9999;
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.cookie-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 0.5rem;
}

.cookie-text h3 {
  color: #fff;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.cookie-text p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  margin: 0;
}

.cookie-actions {
  display: flex;
  gap: 1rem;
  flex-shrink: 0;
}

.btn-accept, .btn-decline {
  padding: 0.75rem 1.5rem;
  border-radius: 0.625rem;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-accept {
  background: linear-gradient(180deg, #349DFF 0%, #005EE9 100%);
  border: none;
  color: white;
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.btn-accept:hover {
  transform: translateY(-2px);
  filter: brightness(1.1);
  box-shadow: 
    0px 8px 16px rgba(52, 157, 255, 0.3),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.btn-decline {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.6);
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  border-radius: 0.625rem;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-decline:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-1px);
  color: rgba(255, 255, 255, 0.8);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .cookie-content {
    flex-direction: column;
    text-align: center;
  }
  
  .cookie-actions {
    width: 100%;
    justify-content: center;
  }
}
</style> 