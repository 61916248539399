var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"duels-game-winner"},[_c('span',[_vm._v("COINFLIP WINNER:")]),(_vm.duelsGame.state !== 'completed')?_c('div',{staticClass:"winner-placeholder"}):_c('div',{staticClass:"winner-content"},[_c('div',{staticClass:"content-inner"},[_c('div',{staticClass:"inner-user",class:[
          'user-' +
            (_vm.duelsGame.winner.bot === true
              ? 'bot'
              : _vm.duelsGetRank(_vm.duelsGame.winner.user)),
          'user-' +
            (_vm.duelsGame.winner.bot === true
              ? ''
              : _vm.duelsGetLevelColor(_vm.duelsGame.winner.user)),
        ]},[_c('div',{staticClass:"user-avatar"},[_c('AvatarImage',{attrs:{"image":_vm.duelsGame.winner.bot ? null : _vm.duelsGame.winner.user.avatar}})],1),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.duelsGame.winner.bot === true
              ? _vm.duelsGetBotName
              : _vm.duelsGame.winner.user.username
          )}})]),_c('div',{staticClass:"inner-roll"},[_c('div',{staticClass:"roll-inner"},[_c('span',[_vm._v(_vm._s(parseFloat(_vm.duelsGame.winner.roll / 100).toFixed(2)))])])])])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }