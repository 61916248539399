const sportsbookService = {
  async getJwtToken(userId, currency) {
    try {
      console.log('⚽ Getting JWT token for user:', {
        userId,
        operatorId: process.env.VUE_APP_GR8_TECH_OPERATOR_ID,
        brand: process.env.VUE_APP_GR8_TECH_BRAND
      });

      const headers = {
        'Content-Type': 'application/json',
        'X-Operator-Id': process.env.VUE_APP_GR8_TECH_OPERATOR_ID,
        'X-Brand': process.env.VUE_APP_GR8_TECH_BRAND
      };

      console.log('⚽ Request headers:', headers);

      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/api/sportsbook/auth/get-jwt-token/${userId}`,
        { headers }
      );
      
      if (!response.ok) {
        const error = await response.json();
        console.error('⚽ JWT token error:', {
          status: response.status,
          statusText: response.statusText,
          error,
          headers,
          requestUrl: `${process.env.VUE_APP_API_URL}/api/sportsbook/auth/get-jwt-token/${userId}`
        });
        throw error;
      }

      const data = await response.json();
      console.log('⚽ JWT token received successfully');
      return data.token;
    } catch (error) {
      console.error('⚽ Failed to get JWT token:', {
        error,
        userId,
        apiUrl: process.env.VUE_APP_API_URL,
        operatorId: process.env.VUE_APP_GR8_TECH_OPERATOR_ID,
        brand: process.env.VUE_APP_GR8_TECH_BRAND
      });
      throw error;
    }
  },

  async getBalance(userId) {
    try {
      console.log('⚽ Checking balance for user:', userId);
      
      // Log headers being sent
      const headers = {
        'Content-Type': 'application/json',
        'X-Operator-Id': process.env.VUE_APP_GR8_TECH_OPERATOR_ID,
        'X-Brand': process.env.VUE_APP_GR8_TECH_BRAND
      };
      
      console.log('⚽ Request headers:', headers);

      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/api/sportsbook/wallet/${userId}/balances`,
        { headers }
      );
      
      if (!response.ok) {
        const error = await response.json();
        console.error('⚽ Balance check error:', error);
        switch(error.error?.code) {
          case 'error.user.not-found':
            throw new Error('Player was not found');
          case 'decline.player.blocked':
            throw new Error('Player was blocked');
          case 'decline.lowbalance':
            throw new Error('Insufficient funds');
          case 'decline.restrictions':
            throw new Error('Regulator restriction');
          default:
            throw new Error(error.error?.message || 'Failed to get balance');
        }
      }
      
      const data = await response.json();
      console.log('⚽ Balance check successful:', data);
      return data;
    } catch (error) {
      console.error('⚽ Balance check failed:', error);
      throw error;
    }
  },

  async performTransaction(userId, transactionData) {
    try {
      console.log('⚽ Processing transaction for user:', userId, transactionData);
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/api/sportsbook/wallet/${userId}/transactions`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Operator-Id': process.env.VUE_APP_GR8_TECH_OPERATOR_ID,
            'X-Brand': process.env.VUE_APP_GR8_TECH_BRAND
          },
          body: JSON.stringify(transactionData)
        }
      );
      
      if (!response.ok) {
        const error = await response.json();
        console.error('⚽ Transaction error:', error);
        throw error;
      }
      
      const data = await response.json();
      console.log('⚽ Transaction processed successfully:', data);
      // Balance update will come through socket event
      return data;
    } catch (error) {
      console.error('⚽ Transaction failed:', error);
      throw error;
    }
  },

  async getTransactionStatus(userId, transactionId) {
    try {
      console.log('⚽ Checking transaction status:', { userId, transactionId });
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/api/sportsbook/wallet/${userId}/transactions/${transactionId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Operator-Id': process.env.VUE_APP_GR8_TECH_OPERATOR_ID,
            'X-Brand': process.env.VUE_APP_GR8_TECH_BRAND
          }
        }
      );

      if (response.status === 204) {
        console.log('⚽ Transaction not found');
        return null;
      }
      
      if (!response.ok) {
        const error = await response.json();
        console.error('⚽ Transaction status error:', error);
        throw error;
      }
      
      const data = await response.json();
      console.log('⚽ Transaction status retrieved:', data);
      return data;
    } catch (error) {
      console.error('⚽ Failed to get transaction status:', error);
      throw error;
    }
  }
};

export default sportsbookService;
