<template>
    <div class="affiliate-overview">
        <AffiliatesSteps />
        
        <div class="overview-card">
            <div class="card-header">
                <span>YOUR COMMISSION RATES</span>
            </div>
            <div class="card-content">
                <div class="rates-grid">
                    <div class="rate-item">
                        <div class="rate-label">
                            <i class="fas fa-dice"></i>
                            <span>Slots</span>
                        </div>
                        <div class="rate-value">{{slotsRate}}%</div>
                    </div>
                    <div class="rate-item">
                        <div class="rate-label">
                            <i class="fas fa-futbol"></i>
                            <span>Sports</span>
                        </div>
                        <div class="rate-value">{{sportsRate}}%</div>
                    </div>
                    <div class="rate-item">
                        <div class="rate-label">
                            <i class="fas fa-gamepad"></i>
                            <span>Other Games</span>
                        </div>
                        <div class="rate-value">{{originalRate}}%</div>
                    </div>
                </div>

                <div class="divider"></div>

                <div class="requirements-grid">
                    <div class="requirement-item">
                        <span class="requirement-label">Min Claim Amount</span>
                        <div class="requirement-value">
                            <i class="fas fa-coins"></i>
                            <span>$10.00</span>
                        </div>
                    </div>
                    <div class="requirement-item">
                        <span class="requirement-label">Max Claim Amount</span>
                        <div class="requirement-value">
                            <i class="fas fa-coins"></i>
                            <span>$1,000.00</span>
                        </div>
                    </div>
                    <div class="requirement-item">
                        <span class="requirement-label">Min NGR for Payout</span>
                        <div class="requirement-value">
                            <i class="fas fa-coins"></i>
                            <span>$10.00</span>
                        </div>
                    </div>
                </div>

                <div class="divider"></div>

                <div class="info-section">
                    <div class="info-title">How Commission is Calculated</div>
                    <div class="info-content">
                        <p>Your commission is based on Net Gaming Revenue (NGR) which is calculated as:</p>
                        <ul>
                            <li>NGR = Total Bets - Total Wins</li>
                            <li>Adjusted NGR = NGR after house edge and operational costs</li>
                            <li>Commission = Adjusted NGR × Game-specific Rate</li>
                        </ul>
                        <p>Different games have different commission rates to reflect their unique characteristics.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-card">
            <div class="card-header">
                <span>Requirements</span>
            </div>
            <div class="card-content">
                <div class="requirements-grid">
                    <div class="requirement-item">
                        <span class="requirement-label">Minimum Deposit</span>
                        <span class="requirement-value">$10</span>
                    </div>
                    <div class="requirement-item">
                        <span class="requirement-label">Minimum Wager</span>
                        <span class="requirement-value">$100</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-card faq-section">
            <div class="card-header">
                <span>Frequently Asked Questions</span>
            </div>
            <div class="card-content">
                <div class="faq-grid">
                    <div class="faq-item">
                        <div class="faq-question">
                            <font-awesome-icon :icon="['fas', 'question-circle']" />
                            <h4>How do I become an affiliate?</h4>
                        </div>
                        <p>Simply register an account and navigate to the Code section to get your unique referral code.</p>
                    </div>
                    <div class="faq-item">
                        <div class="faq-question">
                            <font-awesome-icon :icon="['fas', 'question-circle']" />
                            <h4>When do I get paid?</h4>
                        </div>
                        <p>Earnings are available for instant withdrawal as soon as your referred players generate commission.</p>
                    </div>
                    <div class="faq-item">
                        <div class="faq-question">
                            <font-awesome-icon :icon="['fas', 'question-circle']" />
                            <h4>How is commission calculated?</h4>
                        </div>
                        <p>You earn 5% of the house edge from all bets placed by your referred players.</p>
                    </div>
                    <div class="faq-item">
                        <div class="faq-question">
                            <font-awesome-icon :icon="['fas', 'question-circle']" />
                            <h4>Is there a minimum withdrawal?</h4>
                        </div>
                        <p>No, you can withdraw your earnings at any time with no minimum amount required.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AffiliatesSteps from './AffiliatesSteps.vue'

export default {
    name: 'AffiliatesOverview',
    components: {
        AffiliatesSteps
    },
    computed: {
        ...mapGetters(['affiliatesData', 'authUser']),
        
        // Get the best rates by comparing tier rates and affiliate rates
        bestRates() {
            // Default affiliate rates
            const defaultRates = {
                slots: 0.05,    // 5% default
                sports: 0.05,   // 5% default
                original: 0.05  // 5% default
            };

            // Get tier rates from user
            const tierRates = this.authUser?.user?.tier?.rates || {
                slots: 0.005,    // 0.5% tier default
                sports: 0.003,   // 0.3% tier default
                original: 0.004  // 0.4% tier default
            };

            // Get direct affiliate rates if they exist
            const directRates = this.affiliatesData?.affiliates?.rates || {
                slots: 0,
                sports: 0,
                original: 0
            };

            // Get affiliate code rates from first code if exists
            const codeRates = this.affiliatesData?.affiliates?.codes?.[0]?.rates || defaultRates;

            console.log('Rate sources:', {
                tierRates,
                directRates,
                codeRates,
                defaultRates
            });

            // Return the highest rates among all sources
            return {
                slots: Math.max(
                    tierRates.slots || 0,
                    directRates.slots || 0,
                    codeRates.slots || 0,
                    defaultRates.slots
                ),
                sports: Math.max(
                    tierRates.sports || 0,
                    directRates.sports || 0,
                    codeRates.sports || 0,
                    defaultRates.sports
                ),
                original: Math.max(
                    tierRates.original || 0,
                    directRates.original || 0,
                    codeRates.original || 0,
                    defaultRates.original
                )
            };
        },

        slotsRate() {
            return (this.bestRates.slots * 100).toFixed(1);
        },

        sportsRate() {
            return (this.bestRates.sports * 100).toFixed(1);
        },

        originalRate() {
            return (this.bestRates.original * 100).toFixed(1);
        }
    }
}
</script>

<style scoped>
.affiliate-overview {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.overview-card {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, var(--background-primary) 100%);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
    border: 1px solid rgba(44, 94, 255, 0.1);
}

.card-header {
    padding: 20px;
    background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.card-header span {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #8bacc8;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.card-content {
    padding: 24px;
}

.rates-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.rate-item {
    background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 16px;
    transition: all 0.3s ease;
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.rate-item:hover {
    border-color: rgba(44, 94, 255, 0.3);
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset,
        0px 0px 8px 0px rgba(45, 111, 255, 0.5);
}

.rate-label {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.rate-label i {
    font-size: 16px;
    color: #8bacc8;
    filter: drop-shadow(0px 0px 4px rgba(52, 157, 255, 0.5));
}

.rate-label span {
    color: #8bacc8;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.rate-value {
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-shadow: 0px 0px 4px rgba(52, 157, 255, 0.5);
}

.divider {
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    margin: 24px 0;
}

.requirements-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.requirement-item {
    background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 16px;
    transition: all 0.3s ease;
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.requirement-item:hover {
    border-color: rgba(44, 94, 255, 0.3);
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset,
        0px 0px 8px 0px rgba(45, 111, 255, 0.5);
}

.requirement-label {
    color: #8bacc8;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 8px;
}

.requirement-value {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.requirement-value i {
    font-size: 16px;
    color: #349DFF;
    filter: drop-shadow(0px 0px 4px rgba(52, 157, 255, 0.5));
}

.info-section {
    background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
    border-radius: 12px;
    padding: 20px;
    margin-top: 24px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.info-title {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 12px;
    text-shadow: 0px 0px 4px rgba(52, 157, 255, 0.5);
}

.info-content {
    color: #8bacc8;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    line-height: 1.6;
}

.info-content p {
    margin-bottom: 12px;
}

.info-content ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 12px;
}

.info-content li {
    position: relative;
    padding-left: 16px;
    margin-bottom: 8px;
}

.info-content li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #349DFF;
    text-shadow: 0px 0px 4px rgba(52, 157, 255, 0.5);
}

/* Mobile Responsive Styles */
@media only screen and (max-width: 1150px) {
    .rates-grid,
    .requirements-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .rates-grid,
    .requirements-grid {
        grid-template-columns: 1fr;
        gap: 12px;
    }

    .card-content {
        padding: 16px;
    }

    .rate-item,
    .requirement-item {
        padding: 12px;
    }

    .rate-value {
        font-size: 20px;
    }

    .info-section {
        padding: 16px;
    }
}

@media only screen and (max-width: 480px) {
    .card-content {
        padding: 12px;
    }

    .rate-item,
    .requirement-item {
        padding: 10px;
    }

    .rate-value {
        font-size: 18px;
    }

    .requirement-value {
        font-size: 14px;
    }

    .info-section {
        padding: 12px;
    }

    .info-content {
        font-size: 13px;
    }
}

.faq-section {
    margin-top: 24px;
}

.faq-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}

.faq-item {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(44, 94, 255, 0.05) 100%);
    border-radius: 12px;
    padding: 20px;
    border: 1px solid rgba(44, 94, 255, 0.1);
    transition: all 0.3s ease;
}

.faq-item:hover {
    border-color: rgba(44, 94, 255, 0.3);
    box-shadow: 
        0px 4px 8px rgba(0, 0, 0, 0.2),
        0px 0px 12px rgba(44, 94, 255, 0.1);
}

.faq-question {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
}

.faq-question svg {
    color: #2C5EFF;
    font-size: 20px;
}

.faq-question h4 {
    margin: 0;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
}

.faq-item p {
    margin: 0;
    font-family: Inter, sans-serif;
    font-size: 14px;
    color: #8BACC8;
    line-height: 1.5;
}

@media only screen and (max-width: 1024px) {
    .faq-grid {
        gap: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .faq-grid {
        grid-template-columns: 1fr;
    }
}
</style> 