<template>
  <div class="min-h-screen bg-[#080E1C]" :class="containerClasses">
    <div class="content-wrapper">
      <!-- Header with Back Button when game is active -->
      <div v-if="showGameModal" class="game-header">
        <button 
          @click="closeGameModal" 
          class="back-button"
        >
          <font-awesome-icon icon="chevron-left" />
          <span class="hidden sm:inline">Back to Games</span>
        </button>
        <h2 class="game-title truncate">
          {{ selectedGame?.translationKey || selectedGame?.name || 'Game' }}
        </h2>
        <div class="header-controls">
          <button 
            @click="toggleFullscreen" 
            class="control-button"
            :class="{ active: isFullscreen }"
          >
            <font-awesome-icon :icon="isFullscreen ? 'compress' : 'expand'" />
          </button>
          <button 
            @click="closeGameModal" 
            class="control-button"
          >
            <font-awesome-icon icon="times" />
          </button>
        </div>
      </div>

      <!-- Main Content -->
      <div v-if="!showGameModal" class="main-content">
        <!-- Search and Filters -->
        <EVASearchFilters class="filters-container" />

        <!-- Games Grid -->
        <div class="games-container">
          <EVAGamesList
            ref="gamesList"
            @game-selected="handleGameSelect"
          />
        </div>
      </div>

      <!-- Game View -->
      <transition name="fade">
        <div 
          v-if="showGameModal" 
          class="game-view-wrapper"
        >
          <div 
            class="game-view-container"
            :class="{ 'fullscreen': isFullscreen }"
          >
            <!-- Loading Overlay -->
            <div v-if="loading" class="loading-overlay">
              <div class="spinner"></div>
              <p class="loading-text">Loading game...</p>
            </div>

            <!-- Error Display -->
            <div v-if="error" class="error-overlay">
              <div class="error-content">
                <font-awesome-icon icon="exclamation-circle" class="error-icon" />
                <p class="error-message">{{ error }}</p>
                <button @click="closeGameModal" class="error-button">
                  Back to Games
                </button>
              </div>
            </div>

            <!-- Game Container -->
            <EVAGameContainer
              v-if="gameUrl && !error"
              :gameUrl="gameUrl"
              :gameName="selectedGame?.translationKey || selectedGame?.name"
              @error="handleGameError"
              :showControls="false"
            />
          </div>

          <!-- Similar Games Section -->
          <EVASimilarGames
            v-if="selectedGame && !error"
            :currentGame="selectedGame"
            @game-selected="handleGameSelect"
            class="similar-games-section"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EVAGamesList from '@/components/slots/EVAGamesList.vue'
import EVASearchFilters from '@/components/slots/EVASearchFilters.vue'
import EVAGameContainer from '@/components/slots/EVAGameContainer.vue'
import EVASimilarGames from '@/components/slots/EVASimilarGames.vue'

export default {
  name: 'EVASlotsView',
  
  components: {
    EVAGamesList,
    EVASearchFilters,
    EVAGameContainer,
    EVASimilarGames
  },
  
  data() {
    return {
      selectedGame: null,
      gameUrl: null,
      error: null,
      loading: false,
      isFullscreen: false
    }
  },

  computed: {
    ...mapState({
      authUser: state => state.auth.authUser,
      authToken: state => state.auth.authToken,
      currentGame: state => state.eva.currentGame,
      allGames: state => state.eva.allGames,
      showGameModal: state => state.eva.showGameModal,
      currentDisplayPage: state => state.eva.displayPage
    }),
    
    isAuthenticated() {
      return !!(this.authUser?.user?._id && this.authToken)
    },
    
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i
      return mobileRegex.test(userAgent.toLowerCase()) || window.innerWidth <= 768
    },

    lobbyUrl() {
      return `${window.location.origin}/games`
    },

    containerClasses() {
      return {
        'fullscreen-mode': this.showGameModal,
        'has-fullscreen-game': this.isFullscreen
      }
    }
  },

  watch: {
    currentGame: {
      immediate: true,
      handler(game) {
        if (game) {
          // If we already have a game URL, we're in the middle of a game session
          if (!this.gameUrl) {
            this.handleGameSelect(game)
          }
          // Always update the selected game for similar games to work
          this.selectedGame = game
        }
      }
    },

    // Watch for page changes to update URL
    currentDisplayPage: {
      handler(newPage) {
        // Update URL with current page
        const query = { ...this.$route.query, page: newPage + 1 }
        this.$router.replace({ query }).catch(() => {})
      }
    }
  },

  methods: {
    ...mapActions('eva', [
      'launchGame', 
      'fetchGames', 
      'setFilters',
      'setCurrentGame',
      'setShowGameModal',
      'setDisplayPage'
    ]),

    async handleGameSelect(game) {
      try {
        console.log('[EVASlotsView] Game selected:', game)
        
        // Check authentication first
        if (!this.isAuthenticated) {
          // Store the game info and show login modal
          this.$store.commit('eva/setSelectedGame', game);
          this.$store.dispatch('modalsSetShow', 'Login');
          return;
        }
        
        // Check if this is an original game by checking the provider
        if (game.gameProvider === 'BazedBet') {
          const routeMap = {
            'mines': '/mines',
            'crash': '/crash',
            'blackjack': '/blackjack',
            'towers': '/towers',
            'duels': '/duels',
            'coinflip': '/coinflip'
          }
          
          const route = routeMap[game.gameId]
          if (route) {
            this.$router.push(route)
            return
          }
        }
        
        this.loading = true
        this.error = null
        this.selectedGame = game
        this.setShowGameModal(true)
        this.gameUrl = null

        // Ensure we have all games loaded for similar games to work
        await this.ensureGamesLoaded()

        const launchData = await this.launchGame({
          gameId: game.gameId,
          gameProvider: game.gameProvider,
          productType: game.productType,
          options: {
            demo: !this.isAuthenticated,
            language: 'en',
            channel: this.isMobile ? 'mobile' : 'desktop',
            returnUrl: this.lobbyUrl,
            auth: this.isAuthenticated ? {
              userId: this.authUser.user._id,
              token: this.authToken
            } : null
          }
        })

        console.log('[EVASlotsView] Launch data received:', launchData)
        
        if (!launchData?.launchUrl) {
          throw new Error('Invalid launch URL received from server')
        }

        // Store game session
        sessionStorage.setItem('eva_current_game', JSON.stringify({
          gameId: game.gameId,
          name: game.translationKey || game.name,
          provider: game.gameProvider,
          launchUrl: launchData.launchUrl,
          isDemo: launchData.isDemo,
          timestamp: Date.now()
        }))

        // Set game URL to trigger container
        this.gameUrl = launchData.launchUrl

        // Wait for next tick and scroll to top
        this.$nextTick(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })

          // Handle mobile view
          if (this.isMobile) {
            this.isFullscreen = true
          }
        })

      } catch (error) {
        console.error('[EVASlotsView] Error launching game:', error)
        this.error = error.message || 'Failed to launch game'
      } finally {
        this.loading = false
      }
    },

    async ensureGamesLoaded() {
      // Set filters to load all games
      await this.setFilters({
        filters: {
          isActive: true,
          channel: this.isMobile ? 'mobile' : 'desktop'
        },
        target: 'all',
        loadAll: true
      })
      
      // Fetch all games if not already loaded
      if (!this.allGames?.length) {
        await this.fetchGames({ target: 'all' })
      }
    },

    closeGameModal() {
      this.setShowGameModal(false)
      this.gameUrl = null
      this.error = null
      this.selectedGame = null
      this.isFullscreen = false
      
      if (document.fullscreenElement) {
        document.exitFullscreen()
      }

      // Clear stored game session
      sessionStorage.removeItem('eva_current_game')
    },

    handleGameError(error) {
      this.error = error
      this.gameUrl = null
    },

    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      
      if (this.isFullscreen) {
        const gameContainer = this.$el.querySelector('.game-view-container');
        if (gameContainer?.requestFullscreen) {
          gameContainer.requestFullscreen().catch(err => {
            console.error('Error attempting to enable fullscreen:', err);
            this.isFullscreen = false;
          });
        }
      } else {
        if (document.fullscreenElement) {
          document.exitFullscreen().catch(err => {
            console.error('Error attempting to exit fullscreen:', err);
            this.isFullscreen = true;
          });
        }
      }
    },

    handleFullscreenChange() {
      if (!document.fullscreenElement && this.isFullscreen) {
        this.isFullscreen = false;
      }
    }
  },

  async mounted() {
    // Initialize providers list
    this.$store.dispatch('eva/initializeProviders')
    
    // Listen for fullscreen changes
    document.addEventListener('fullscreenchange', this.handleFullscreenChange)
    document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange)
    document.addEventListener('mozfullscreenchange', this.handleFullscreenChange)
    document.addEventListener('MSFullscreenChange', this.handleFullscreenChange)

    // Check for page in URL and set it
    const page = parseInt(this.$route.query.page) || 1
    if (page > 1) {
      await this.setDisplayPage(page - 1)
    }
  },

  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
  }
}
</script>

<style scoped>
.content-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.game-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 0.625rem;
  background: rgba(31, 42, 68, 0.95);
  backdrop-filter: blur(8px);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.back-button,
.fullscreen-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
  color: #E8EAED;
  font-size: 0.875rem;
  font-weight: 600;
  transition: all 0.2s ease;
}

.back-button:hover,
.fullscreen-button:hover {
  filter: brightness(1.2);
}

.game-title {
  flex: 1;
  text-align: center;
  color: #E8EAED;
  font-family: Montserrat, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filters-container {
  border-radius: 0.625rem;
  background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  padding: 1rem;
}

.games-container {
  border-radius: 0.625rem;
  background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  padding: 1rem;
}

.game-view-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding-bottom: 2rem;
}

.game-view-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 0.625rem;
  background: #000;
  overflow: hidden;
  transition: all 0.3s ease;
}

.game-view-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  aspect-ratio: unset;
  border-radius: 0;
  z-index: 50;
}

.game-view-container.fullscreen ~ .similar-games-section {
  display: none;
}

.loading-overlay,
.error-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  color: #E8EAED;
  z-index: 10;
}

.error-content {
  text-align: center;
  padding: 2rem;
}

.error-icon {
  font-size: 3rem;
  color: #ff4d4d;
  margin-bottom: 1rem;
}

.error-message {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.error-button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  background: linear-gradient(90deg, #2c5eff 0%, #1d43c0 100%);
  color: #fff;
  font-weight: 600;
  transition: all 0.2s ease;
}

.error-button:hover {
  filter: brightness(1.2);
  transform: translateY(-1px);
}

.loading-text {
  margin-top: 1rem;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
}

.spinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.25rem solid rgba(52, 157, 255, 0.1);
  border-left-color: #349DFF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .content-wrapper {
    padding: 0;
  }

  .game-header {
    display: none; /* Hide game header on mobile */
  }

  .game-view-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; /* Remove fixed bottom spacing */
    padding: 0;
    z-index: 40;
    background: #080E1C;
    gap: 0;
  }

  .game-view-container {
    flex: 1;
    height: 100vh;
    max-height: -webkit-fill-available; /* For iOS Safari */
    border-radius: 0;
    margin: 0;
  }

  .game-view-container.fullscreen {
    height: 100vh !important;
    max-height: -webkit-fill-available !important; /* For iOS Safari */
  }

  .main-content {
    padding: 1rem;
    padding-bottom: calc(env(safe-area-inset-bottom, 1rem) + 4rem);
  }

  .filters-container,
  .games-container {
    margin: 0 -1rem;
    border-radius: 0;
  }

  .similar-games-section {
    display: none;
  }
}

/* Add iOS-specific styles */
@supports (-webkit-touch-callout: none) {
  .game-view-container {
    height: 100vh;
    max-height: -webkit-fill-available;
  }

  .game-view-container.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh !important;
    max-height: -webkit-fill-available !important;
  }
}

.fullscreen-mode {
  min-height: 100vh;
  height: auto;
  overflow-y: auto;
}

.fullscreen-mode.has-fullscreen-game {
  overflow: hidden;
}

.header-controls {
  display: flex;
  gap: 0.5rem;
}

.control-button {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  color: #E8EAED;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.control-button .svg-inline--fa {
  width: 1.25rem;
  height: 1.25rem;
}

.control-button:hover {
  filter: brightness(1.2);
}

.control-button.active {
  background: linear-gradient(90deg, #2c5eff 0%, #1d43c0 100%);
  box-shadow: 0 0 15px rgba(44, 94, 255, 0.3);
}

.similar-games-section {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .game-view-wrapper {
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .game-view-container {
    border-radius: 0;
  }
}
</style> 