<template>
    <div class="crash-history">
        <transition-group class="history-list" name="list" tag="div">

            <CrashHistoryElement v-for="game of crashHistory" v-bind:key="game._id" v-bind:game="game" />

        </transition-group>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import CrashHistoryElement from '@/components/crash/CrashHistoryElement';

    export default {
        name: 'CrashHistory',
        components: {
            CrashHistoryElement
        },
        computed: {
            ...mapGetters([
                'crashHistory'
            ])
        }
    }
</script>

<style scoped>
    .crash-history {
        width: 100%;
        height: 35px;
        position: relative;
        overflow: hidden;
        border-radius: 4px;
    }

    .crash-history .history-list {
        display: flex;
        gap: 24px;
    }

    .crash-history .list-enter-active {
        transition: all 0.5s;
    }

    .crash-history .list-enter {
        opacity: 0;
        transform: translateX(-15px);
    }

    @media only screen and (max-width: 950px) {
        .crash-history {
            height: min(35px, 4vh);
        }

        .crash-history .history-list {
            gap: min(24px, 2.5vh);
        }
    }

    @media only screen and (max-width: 550px) {
        .crash-history {
            height: min(28px, 3.5vh);
        }
    }

</style>
