<template>
  <div class="transaction-history">
    <div class="history-header">
      <h3>Transaction History</h3>
      <div class="filters">
        <div class="filter-buttons">
          <button 
            class="filter-button" 
            :class="{ active: selectedType === 'all' }"
            @click="selectedType = 'all'"
            title="All Transactions"
          >
            <i class="fas fa-list"></i>
          </button>
          <button 
            class="filter-button" 
            :class="{ active: selectedType === 'deposit' }"
            @click="selectedType = 'deposit'"
            title="Deposits"
          >
            <i class="fas fa-arrow-down"></i>
          </button>
          <button 
            class="filter-button" 
            :class="{ active: selectedType === 'withdraw' }"
            @click="selectedType = 'withdraw'"
            title="Withdrawals"
          >
            <i class="fas fa-arrow-up"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Transactions List -->
    <div class="transactions-container">
      <transition name="fade" mode="out-in">
        <div v-if="loading" class="loading-state" key="loading">
          <i class="fas fa-spinner fa-spin"></i>
          Loading transactions...
        </div>

        <div v-else-if="!transactions.length" class="empty-state" key="empty">
          <i class="fas fa-receipt"></i>
          <p>No transactions found</p>
        </div>

        <div v-else class="transactions-list" key="list">
          <transition-group name="list" tag="div">
            <div 
              v-for="transaction in transactions" 
              :key="transaction._id"
              class="transaction-card"
              :class="[transaction.type, { 'cancelling': cancelling === transaction._id }]"
            >
              <!-- Transaction Icon -->
              <div class="transaction-icon">
                <img 
                  :src="getCurrencyIcon(transaction.pay_currency)"
                  :alt="transaction.pay_currency"
                  @error="handleImageError($event, transaction.pay_currency)"
                />
              </div>

              <!-- Transaction Details -->
              <div class="transaction-details">
                <div class="transaction-header">
                  <span class="transaction-type">
                    {{ formatTransactionType(transaction.type) }}
                  </span>
                  <span class="transaction-date">
                    {{ formatDate(transaction.created_at) }}
                  </span>
                </div>

                <div class="transaction-amounts">
                  <div class="fiat-amount">
                    ${{ transaction.price_amount }} USD
                  </div>
                  <div class="crypto-amount">
                    {{ transaction.pay_amount }} {{ transaction.pay_currency.toUpperCase() }}
                  </div>
                </div>

                <!-- Status Badge -->
                <div class="status-badge" :class="transaction.payment_status">
                  {{ formatStatus(transaction.payment_status) }}
                </div>
              </div>

              <!-- Transaction Actions -->
              <div class="transaction-actions">
                <button 
                  v-if="transaction.can_cancel"
                  class="action-button cancel-button"
                  @click="cancelTransaction(transaction)"
                  :disabled="cancelling === transaction._id"
                >
                  <i v-if="cancelling === transaction._id" class="fas fa-spinner fa-spin"></i>
                  <i v-else class="fas fa-times"></i>
                </button>
                <button 
                  class="action-button"
                  @click="showTransactionDetails(transaction)"
                >
                  <i class="fas fa-info-circle"></i>
                </button>
              </div>
            </div>
          </transition-group>
        </div>
      </transition>

      <!-- Load More Button -->
      <button 
        v-if="hasMoreTransactions"
        class="load-more-button"
        :disabled="loadingMore"
        @click="loadMore"
      >
        <i v-if="loadingMore" class="fas fa-spinner fa-spin"></i>
        <span v-else>Load More</span>
      </button>
    </div>

    <!-- Transaction Details Modal -->
    <BaseModal
      v-if="selectedTransaction"
      @close="selectedTransaction = null"
    >
      <template #header>
        Transaction Details
      </template>
      
      <template #content>
        <div class="transaction-modal-content">
          <div class="modal-row">
            <span class="label">Type:</span>
            <span class="value">{{ formatTransactionType(selectedTransaction.type) }}</span>
          </div>
          
          <div class="modal-row">
            <span class="label">Status:</span>
            <span class="value status" :class="selectedTransaction.payment_status">
              {{ formatStatus(selectedTransaction.payment_status) }}
            </span>
          </div>

          <div class="modal-row">
            <span class="label">Amount:</span>
            <span class="value">
              ${{ selectedTransaction.price_amount }} USD
              <br>
              {{ selectedTransaction.pay_amount }} {{ selectedTransaction.pay_currency.toUpperCase() }}
            </span>
          </div>

          <div class="modal-row">
            <span class="label">Date:</span>
            <span class="value">{{ formatDate(selectedTransaction.created_at, true) }}</span>
          </div>

          <div class="modal-row" v-if="selectedTransaction.pay_address">
            <span class="label">Address:</span>
            <div class="copy-field">
              <input type="text" readonly :value="selectedTransaction.pay_address" />
              <button @click="copyToClipboard(selectedTransaction.pay_address)">
                <i class="fas fa-copy"></i>
              </button>
            </div>
          </div>

          <div class="modal-row" v-if="selectedTransaction.payin_hash">
            <span class="label">Transaction Hash:</span>
            <div class="copy-field">
              <input type="text" readonly :value="selectedTransaction.payin_hash" />
              <button @click="copyToClipboard(selectedTransaction.payin_hash)">
                <i class="fas fa-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { mapActions } from 'vuex';
import BaseModal from '@/components/base/BaseModal.vue';
import axios from 'axios';

export default {
  name: 'TransactionHistory',
  
  components: {
    BaseModal
  },

  setup() {
    const transactions = ref([]);
    const loading = ref(true);
    const loadingMore = ref(false);
    const selectedType = ref('all');
    const page = ref(1);
    const hasMoreTransactions = ref(true);
    const selectedTransaction = ref(null);
    const cancelling = ref(null);

    const loadTransactions = async (reset = false) => {
      if (reset) {
        page.value = 1;
        transactions.value = [];
      }

      try {
        if (reset) {
          loading.value = true;
        } else {
          loadingMore.value = true;
        }

        const response = await axios.get(`/api/nowpayments/transactions`, {
          params: {
            page: page.value,
            type: selectedType.value === 'all' ? undefined : selectedType.value,
            limit: 5
          }
        });

        const data = response.data;
        if (!data || !data.transactions) {
          console.error('Invalid response format:', data);
          return;
        }

        let newTransactions = data.transactions;

        // Sort transactions by date in descending order (newest first)
        newTransactions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        if (reset) {
          transactions.value = newTransactions;
        } else {
          // Merge and sort all transactions
          transactions.value = [...transactions.value, ...newTransactions]
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }

        hasMoreTransactions.value = data.pagination.page < data.pagination.pages;
      } catch (error) {
        console.error('Failed to load transactions:', error);
        notificationShow({
          type: 'error',
          message: 'Failed to load transactions'
        });
      } finally {
        loading.value = false;
        loadingMore.value = false;
      }
    };

    // Watch for filter changes
    watch(selectedType, () => {
      loadTransactions(true);
    });

    // Load initial data
    onMounted(() => {
      loadTransactions(true);
    });

    const loadMore = async () => {
      if (loadingMore.value || !hasMoreTransactions.value) return;
      page.value++;
      await loadTransactions(false);
    };

    return {
      transactions,
      loading,
      loadingMore,
      selectedType,
      hasMoreTransactions,
      selectedTransaction,
      cancelling,
      loadTransactions,
      loadMore,
      page
    };
  },

  methods: {
    ...mapActions(['notificationShow']),

    formatTransactionType(type) {
      const types = {
        'deposit': 'Deposit',
        'withdraw': 'Withdrawal'
      };
      return types[type] || type;
    },

    formatStatus(status) {
      const statuses = {
        'waiting': 'Waiting',
        'creating': 'Creating',
        'confirming': 'Confirming',
        'confirmed': 'Confirmed',
        'sending': 'Processing',
        'finished': 'Completed',
        'failed': 'Failed',
        'expired': 'Expired',
        'cancelled': 'Canceled',
        'user_cancelled': 'Canceled',
        'partially_paid': 'Partial Payment'
      };
      return statuses[status] || status;
    },

    formatDate(date, detailed = false) {
      const d = new Date(date);
      if (detailed) {
        return d.toLocaleString();
      }
      // Return relative time
      const now = new Date();
      const diffTime = Math.abs(now - d);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
      const diffMinutes = Math.floor(diffTime / (1000 * 60));

      if (diffMinutes < 60) {
        return `${diffMinutes} minutes ago`;
      } else if (diffHours < 24) {
        return `${diffHours} hours ago`;
      } else if (diffDays === 1) {
        return 'yesterday';
      } else {
        return `${diffDays} days ago`;
      }
    },

    getCurrencyIcon(currency) {
      return `https://nowpayments.io/images/coins/${currency.toLowerCase()}.svg`;
    },

    handleImageError(event, currency) {
      event.target.src = `https://nowpayments.io/images/coins/${currency.toLowerCase()}.svg`;
    },

    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.notificationShow({
          type: 'success',
          message: 'Copied to clipboard!'
        });
      } catch (error) {
        console.error('Failed to copy:', error);
      }
    },

    showTransactionDetails(transaction) {
      this.selectedTransaction = transaction;
    },

    async cancelTransaction(transaction) {
      if (this.cancelling || !transaction.can_cancel) return;

      try {
        this.cancelling = transaction._id;
        
        // Optimistically update the UI
        const transactionIndex = this.transactions.findIndex(t => t._id === transaction._id);
        if (transactionIndex !== -1) {
          this.transactions[transactionIndex] = {
            ...transaction,
            payment_status: 'user_cancelled',
            can_cancel: false
          };
        }

        const response = await axios.post(`/api/nowpayments/transactions/${transaction._id}/cancel`, {
          type: transaction.type
        });

        const data = response.data;
        if (data.status) {
          this.notificationShow({
            type: 'success',
            message: data.message
          });
          
          // Wait a moment before reloading to allow for smooth transition
          setTimeout(() => {
            this.loadTransactions(true);
          }, 300);
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        console.error('Failed to cancel transaction:', error);
        this.notificationShow({
          type: 'error',
          message: error.response?.data?.message || 'Failed to cancel transaction'
        });
        
        // Revert optimistic update if failed
        const transactionIndex = this.transactions.findIndex(t => t._id === transaction._id);
        if (transactionIndex !== -1) {
          this.transactions[transactionIndex] = transaction;
        }
      } finally {
        setTimeout(() => {
          this.cancelling = null;
        }, 300);
      }
    }
  },

  onMounted() {
    this.loadTransactions();
  }
}
</script>

<style scoped>
.transaction-history {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.history-header h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--highlight-white);
  margin: 0;
}

.filters {
  display: flex;
  align-items: center;
}

.filter-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-button:hover {
  background: rgba(255, 255, 255, 0.08);
  color: #FFFFFF;
}

.filter-button.active {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

.filter-button i {
  font-size: 14px;
}

.filter-button.active i {
  color: inherit;
}

.filter-button:not(.active) .fa-arrow-down {
  color: #4CAF50;
}

.filter-button:not(.active) .fa-arrow-up {
  color: #F44336;
}

.filter-button:not(.active) .fa-list {
  color: #2196F3;
}

.transactions-container {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
}

.transaction-card {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: background 0.2s ease;
}

.transaction-card:hover {
  background: rgba(255, 255, 255, 0.02);
}

.transaction-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  margin-right: 16px;
}

.transaction-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.transaction-details {
  flex: 1;
}

.transaction-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.transaction-type {
  font-weight: 600;
  color: var(--highlight-white);
}

.transaction-date {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.transaction-amounts {
  margin-bottom: 8px;
}

.fiat-amount {
  font-size: 16px;
  font-weight: 500;
  color: var(--highlight-white);
}

.crypto-amount {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
}

/* Success states */
.status-badge.finished {
  background: rgba(76, 175, 80, 0.15);
  color: #4CAF50;
  border: 1px solid rgba(76, 175, 80, 0.2);
}

/* Error states */
.status-badge.failed,
.status-badge.expired,
.status-badge.cancelled,
.status-badge.rejected,
.status-badge.user_cancelled {
  background: rgba(244, 67, 54, 0.15);
  color: #F44336;
  border: 1px solid rgba(244, 67, 54, 0.2);
}

/* Warning states */
.status-badge.partially_paid {
  background: rgba(255, 152, 0, 0.15);
  color: #FF9800;
  border: 1px solid rgba(255, 152, 0, 0.2);
}

/* Processing states */
.status-badge.waiting,
.status-badge.creating,
.status-badge.confirming,
.status-badge.confirmed,
.status-badge.processing,
.status-badge.sending {
  background: rgba(33, 150, 243, 0.15);
  color: #2196F3;
  border: 1px solid rgba(33, 150, 243, 0.2);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.transaction-actions {
  margin-left: 16px;
}

.action-button {
  padding: 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.2s ease;
}

.action-button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: var(--highlight-white);
}

.load-more-button {
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  color: var(--highlight-white);
  font-weight: 500;
  transition: all 0.2s ease;
}

.load-more-button:not(:disabled):hover {
  background: rgba(255, 255, 255, 0.1);
}

.load-more-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Modal Styles */
.transaction-modal-content {
  padding: 20px;
}

.modal-row {
  margin-bottom: 16px;
}

.modal-row .label {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 4px;
}

.modal-row .value {
  color: var(--highlight-white);
  font-size: 16px;
}

.copy-field {
  display: flex;
  gap: 8px;
}

.copy-field input {
  flex: 1;
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
}

.copy-field button {
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  color: #FFFFFF;
}

/* Loading and Empty States */
.loading-state,
.empty-state {
  padding: 40px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
}

.empty-state i {
  font-size: 24px;
  margin-bottom: 8px;
}

.cancel-button {
  margin-right: 8px;
  background: rgba(244, 67, 54, 0.1);
  color: #F44336;
}

.cancel-button:hover:not(:disabled) {
  background: rgba(244, 67, 54, 0.2);
}

.cancel-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Add new transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}

.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* Add cancelling state styles */
.transaction-card.cancelling {
  opacity: 0.7;
  pointer-events: none;
  transform: scale(0.98);
  transition: all 0.3s ease;
}

/* Update loading state animation */
.loading-state {
  animation: fadeInUp 0.3s ease;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Update the cancel button transition */
.cancel-button {
  transition: all 0.2s ease;
}

.cancel-button:disabled {
  opacity: 0.5;
  transform: scale(0.95);
  cursor: not-allowed;
}

/* Smooth transition for status changes */
.status-badge {
  transition: all 0.3s ease;
}

/* Add responsive styles for transaction cards */
@media (max-width: 600px) {
  .transaction-history {
    padding: 12px;
  }

  .history-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .filters {
    width: 100%;
  }

  .filter-buttons {
    width: 100%;
    justify-content: flex-start;
  }

  .filter-button {
    flex: 0 0 auto;
  }

  .transaction-card {
    padding: 12px;
    position: relative;
  }

  .transaction-icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }

  .transaction-details {
    flex: 1;
    min-width: 0;
  }

  .transaction-amounts {
    margin-right: 40px;
  }

  .transaction-actions {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 0;
    display: flex;
    gap: 4px;
  }

  .action-button {
    padding: 6px;
    font-size: 12px;
  }

  .fiat-amount {
    font-size: 14px;
  }

  .crypto-amount {
    font-size: 12px;
  }

  .status-badge {
    font-size: 11px;
  }
}

/* Ensure action buttons don't break layout */
.action-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
}

.cancel-button {
  margin-right: 4px;
}
</style>
