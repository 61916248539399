# Create new file
<template>
  <div class="game-container" ref="gameContainer">
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
      <p class="loading-text">Loading game...</p>
    </div>
    
    <div v-if="error" class="error-overlay">
      <div class="error-content">
        <font-awesome-icon icon="exclamation-circle" class="error-icon" />
        <p class="error-message">{{ error }}</p>
        <button @click="$emit('close')" class="action-button">
          Return to Lobby
        </button>
      </div>
    </div>

    <div v-show="!loading && !error" class="game-content" ref="gameContent">
      <iframe
        v-if="gameUrl"
        :src="gameUrl"
        :class="iframeClasses"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        @load="handleLoad"
        @error="handleError"
      ></iframe>
    </div>

    <!-- Game Controls (Optional) -->
    <div v-if="showControls && !loading && !error" class="game-controls" :class="{ mobile: isMobile }">
      <button 
        v-if="isMobile"
        @click="toggleFullscreen" 
        class="control-button"
        :class="{ active: isFullscreen }"
      >
        <font-awesome-icon :icon="isFullscreen ? 'compress' : 'expand'" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EVAGameContainer',

  props: {
    gameUrl: {
      type: String,
      required: true
    },
    gameName: {
      type: String,
      default: 'Game'
    },
    showControls: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      loading: true,
      error: null,
      isFullscreen: false,
      isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
      viewportHeight: window.innerHeight,
      viewportWidth: window.innerWidth
    }
  },

  computed: {
    iframeClasses() {
      return {
        'game-frame': true,
        'mobile': this.isMobile,
        'ios': this.isIOS,
        'desktop': !this.isMobile,
        'fullscreen': this.isFullscreen
      }
    }
  },

  methods: {
    handleLoad(event) {
      console.log('[EVAGameContainer] Game loaded:', {
        url: this.gameUrl,
        event,
        viewport: {
          width: this.viewportWidth,
          height: this.viewportHeight
        },
        device: {
          isMobile: this.isMobile,
          isIOS: this.isIOS
        }
      });
      this.loading = false;
      this.error = null;
      
      // Force layout recalculation after iframe loads
      if (this.isMobile && this.$refs.gameContainer) {
        this.$refs.gameContainer.style.display = 'none';
        this.$refs.gameContainer.offsetHeight; // Force reflow
        this.$refs.gameContainer.style.display = '';
        
        // Ensure proper positioning
        this.updateContainerPosition();
      }
    },

    updateContainerPosition() {
      if (!this.isMobile || !this.$refs.gameContainer) return;

      const container = this.$refs.gameContainer;
      const gameFrame = container.querySelector('.game-frame');
      if (!gameFrame) return;

      // Calculate available space between nav bars
      const navbarHeight = 100;
      const mobileMenuHeight = 78;
      const safeAreaTop = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top')) || 0;
      const safeAreaBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-bottom')) || 0;
      
      const availableHeight = window.innerHeight - navbarHeight - mobileMenuHeight - safeAreaTop - safeAreaBottom;
      
      if (!this.isFullscreen) {
        container.style.height = `${availableHeight}px`;
        container.style.top = `${navbarHeight + safeAreaTop}px`;
        container.style.bottom = `${mobileMenuHeight + safeAreaBottom}px`;
        
        // Ensure iframe maintains position
        if (gameFrame) {
          gameFrame.style.height = '100%';
          gameFrame.style.position = 'absolute';
          gameFrame.style.top = '0';
          gameFrame.style.left = '0';
          gameFrame.style.right = '0';
          gameFrame.style.bottom = '0';
        }
      }
    },

    handleError(event) {
      console.error('[EVAGameContainer] Game load error:', {
        url: this.gameUrl,
        event
      });
      this.error = 'Failed to load game';
      this.loading = false;
      this.$emit('error', 'Failed to load game');
    },

    async enterFullscreen() {
      try {
        const container = this.$refs.gameContainer;
        
        if (this.isIOS) {
          // For iOS, we use a different approach since requestFullscreen doesn't work well
          this.isFullscreen = true;
          document.documentElement.style.overflow = 'hidden';
          document.body.style.overflow = 'hidden';
          
          // Force viewport update
          const viewport = document.querySelector('meta[name=viewport]');
          if (viewport) {
            viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
          } else {
            const meta = document.createElement('meta');
            meta.name = 'viewport';
            meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
            document.head.appendChild(meta);
          }
        } else if (container?.requestFullscreen) {
          await container.requestFullscreen();
          this.isFullscreen = true;
          
          // Update screen orientation if supported
          if (screen.orientation && this.isMobile) {
            try {
              await screen.orientation.lock('landscape');
            } catch (err) {
              console.warn('[EVAGameContainer] Failed to lock orientation:', err);
            }
          }
        }
      } catch (err) {
        console.error('[EVAGameContainer] Fullscreen error:', err);
      }
    },

    async exitFullscreen() {
      try {
        if (this.isIOS) {
          this.isFullscreen = false;
          document.documentElement.style.overflow = '';
          document.body.style.overflow = '';
          
          // Reset viewport
          const viewport = document.querySelector('meta[name=viewport]');
          if (viewport) {
            viewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
          }
        } else if (document.fullscreenElement) {
          await document.exitFullscreen();
          this.isFullscreen = false;
          
          // Unlock screen orientation if supported
          if (screen.orientation && this.isMobile) {
            try {
              await screen.orientation.unlock();
            } catch (err) {
              console.warn('[EVAGameContainer] Failed to unlock orientation:', err);
            }
          }
        }
      } catch (err) {
        console.error('[EVAGameContainer] Exit fullscreen error:', err);
      }
    },

    async toggleFullscreen() {
      if (!this.isFullscreen) {
        await this.enterFullscreen();
      } else {
        await this.exitFullscreen();
      }
    },

    handleFullscreenChange() {
      if (!document.fullscreenElement && !this.isIOS) {
        this.isFullscreen = false;
      }
    },

    handleResize() {
      this.viewportHeight = window.innerHeight;
      this.viewportWidth = window.innerWidth;
      
      if (this.isFullscreen && this.$refs.gameContainer) {
        this.$refs.gameContainer.style.height = `${this.viewportHeight}px`;
        this.$refs.gameContainer.style.width = `${this.viewportWidth}px`;
      } else {
        this.updateContainerPosition();
      }
    },

    handleOrientationChange() {
      // Small delay to ensure dimensions are updated
      setTimeout(this.handleResize, 100);
    }
  },

  mounted() {
    // Add CSS variables for safe area insets
    if (this.isMobile) {
      document.documentElement.style.setProperty('--safe-area-inset-top', 'env(safe-area-inset-top, 0px)');
      document.documentElement.style.setProperty('--safe-area-inset-bottom', 'env(safe-area-inset-bottom, 0px)');
    }
    
    document.addEventListener('fullscreenchange', this.handleFullscreenChange);
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleOrientationChange);
    
    // Initial positioning
    this.$nextTick(() => {
      this.handleResize();
      this.updateContainerPosition();
    });
  },

  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleOrientationChange);
    
    // Cleanup fullscreen state
    if (this.isFullscreen) {
      this.exitFullscreen();
    }
  },

  watch: {
    gameUrl: {
      immediate: true,
      handler(newUrl) {
        if (newUrl) {
          this.loading = true;
          this.error = null;
        }
      }
    }
  }
}
</script>

<style scoped>
.game-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  overflow: hidden;
  border-radius: 0.625rem;
  transform: translateZ(0); /* Force GPU acceleration */
  will-change: transform; /* Optimize for animations */
}

@media (max-width: 768px) {
  .game-container {
    position: fixed;
    top: var(--safe-area-inset-top, 0);
    margin-top: 100px; /* navbar height */
    left: 0;
    right: 0;
    bottom: calc(78px + var(--safe-area-inset-bottom, 0)); /* mobile menu height + safe area */
    height: auto;
    border-radius: 0;
    z-index: 40;
  }

  .game-container.fullscreen {
    margin-top: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }
}

.game-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.game-frame {
  width: 100%;
  height: 100%;
  background: #080E1C;
  transform: translateZ(0); /* Force GPU acceleration */
}

/* Mobile styles for non-fullscreen */
@media (max-width: 768px) {
  .game-frame.mobile:not(.fullscreen) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100% !important; /* Force height to container */
    margin: 0;
  }
}

/* Remove viewport height calculations and use container-relative sizing */
@media (max-width: 768px) {
  .game-frame.mobile:not(.fullscreen),
  .game-frame.ios:not(.fullscreen) {
    height: 100% !important;
    position: absolute;
    inset: 0;
    margin: 0;
  }
}

/* Fullscreen styles */
.game-frame.mobile.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  margin: 0;
  z-index: 9999;
}

/* iOS specific styles */
@supports (-webkit-touch-callout: none) {
  .game-frame.ios:not(.fullscreen) {
    height: calc(100vh - 100px - 78px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    margin-top: calc(env(safe-area-inset-top));
  }

  .game-frame.ios.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw !important;
    height: 100vh !important;
    /* Use -webkit-fill-available for more reliable height on iOS */
    height: -webkit-fill-available !important;
    max-height: -webkit-fill-available !important;
    margin: 0;
    z-index: 9999;
  }
}

/* Firefox specific styles */
@-moz-document url-prefix() {
  .game-frame.mobile:not(.fullscreen) {
    height: calc(100vh - 100px - 78px);
  }
}

/* Edge/Chrome specific styles */
@supports (-ms-ime-align: auto) {
  .game-frame.mobile:not(.fullscreen) {
    height: calc(100vh - 100px - 78px);
  }
}

.loading-overlay,
.error-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  color: #E8EAED;
  z-index: 10;
}

.loading-text,
.error-message {
  margin: 1rem 0;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
}

.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
  border-radius: 0.625rem;
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.error-content i {
  font-size: 2rem;
  color: #FF4D4D;
  margin-bottom: 1rem;
}

.action-button {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.625rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  color: #E8EAED;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.action-button:hover {
  filter: brightness(1.2);
}

.game-controls {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  z-index: 20;
}

.game-controls.mobile {
  top: env(safe-area-inset-top, 1rem);
  right: env(safe-area-inset-right, 1rem);
}

.control-button {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  color: #E8EAED;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.control-button:hover {
  filter: brightness(1.2);
}

.control-button.active {
  background: linear-gradient(90deg, #2c5eff 0%, #1d43c0 100%);
  box-shadow: 0 0 15px rgba(44, 94, 255, 0.3);
}

.spinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.25rem solid rgba(52, 157, 255, 0.1);
  border-left-color: #349DFF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .game-container {
    border-radius: 0;
    height: 100vh;
    max-height: -webkit-fill-available; /* For iOS Safari */
  }

  .game-frame.mobile:not(.fullscreen) {
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  }

  .game-controls {
    top: max(env(safe-area-inset-top), 1rem);
    right: max(env(safe-area-inset-right), 1rem);
  }

  .control-button {
    width: 3rem;
    height: 3rem;
    font-size: 1.25rem;
    background: rgba(31, 42, 68, 0.95);
    backdrop-filter: blur(8px);
  }
}

/* Add specific iOS fullscreen styles */
@supports (-webkit-touch-callout: none) {
  .game-frame.ios.fullscreen {
    height: -webkit-fill-available !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.error-icon {
  font-size: 2rem;
  color: #FF4D4D;
  margin-bottom: 1rem;
}

.control-button .svg-inline--fa {
  width: 1.25rem;
  height: 1.25rem;
}
</style> 