<template>
  <div
    class="battles-filter-mode"
    v-bind:class="{
      'mode-open': battlesDropdown === true,
      'mode-group': battlesFilterType === 'group',
    }"
  >
    <button v-on:click="battlesToggleDropdown()" class="button-toggle">
      <div class="button-value">
        MODE: <span>{{ battlesFilterMode }}</span>
      </div>
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5176 1.66411e-06L0.482354 8.43375e-08C0.0547936 9.58042e-09 -0.16302 0.516304 0.143533 0.822859L4.66115 5.34052C4.8467 5.52607 5.15325 5.52607 5.33888 5.34052L9.8565 0.822861C10.163 0.516306 9.94516 1.73887e-06 9.5176 1.66411e-06Z"
        />
      </svg>
    </button>
    <div class="mode-menu">
      <div class="menu-inner">
        <button v-on:click="battlesModeButton('1v1')">1v1</button>
        <button v-on:click="battlesModeButton('1v1v1')">1v1v1</button>
        <button v-on:click="battlesModeButton('1v1v1v1')">1v1v1v1</button>
        <button
          v-if="battlesFilterType === 'standard'"
          v-on:click="battlesModeButton('2v2')"
        >
          2v2
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "BattlesFilterMode",
  data() {
    return {
      battlesDropdown: false,
    };
  },
  methods: {
    ...mapActions(["battlesSetFilterMode"]),
    battlesToggleDropdown() {
      this.battlesDropdown = !this.battlesDropdown;
    },
    battlesModeButton(value) {
      this.battlesSetFilterMode(value);
      this.battlesToggleDropdown();
    },
  },
  computed: {
    ...mapGetters(["battlesFilterMode", "battlesFilterType"]),
  },
  created() {
    let self = this;
    document.addEventListener("click", function (event) {
      if (!self.$el.contains(event.target) && self.battlesDropdown == true) {
        self.battlesToggleDropdown();
      }
    });
  },
};
</script>

<style scoped>
.battles-filter-mode {
  width: 177px;
  font-family: "Montserrat", sans-serif;
  position: relative;
  z-index: 15;
}

.battles-filter-mode button.button-toggle {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.08);
  background: rgba(45, 60, 100, 0.19);
  border-radius: 0.625rem;
}

.battles-filter-mode button.button-toggle svg {
  width: 8px;
  fill: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
}

.battles-filter-mode.mode-open button.button-toggle svg {
  transform: rotate(180deg);
}

.battles-filter-mode .button-value {
  font-size: 14px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.5);
}

.battles-filter-mode .button-value span {
  font-weight: 600;
  color: #ffffff;
}

.battles-filter-mode .mode-menu {
  width: 100%;
  height: 0;
  position: absolute;
  top: 45px;
  left: 0;
  overflow: hidden;
  transition: height 0.2s ease;
}

.battles-filter-mode.mode-open .mode-menu {
  height: 192px;
}

.battles-filter-mode.mode-open.mode-group .mode-menu {
  height: 143px;
}

.battles-filter-mode .menu-inner {
  width: 100%;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.08);
  background: #111a2a;
}

.battles-filter-mode .menu-inner button {
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border-bottom: 2px solid rgba(255, 255, 255, 0.08);
  transition: all 0.3s ease;
}

.battles-filter-mode .menu-inner button:last-child {
  border-bottom: none;
}

.battles-filter-mode .menu-inner button:hover {
  background: rgba(19, 66, 88, 0.15);
}

@media only screen and (max-width: 1100px) {
  .battles-filter-mode {
    width: calc(100% - 271px);
  }
}

@media only screen and (max-width: 500px) {
  .battles-filter-mode {
    width: 100%;
  }
}
</style>
