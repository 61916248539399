<template>
    <div class="performance-overview">
        <div class="overview-stats">
            <div class="overview-stat">
                <span class="stat-label">Total Referrals</span>
                <span class="stat-value">{{ totalReferrals }}</span>
            </div>
            <div class="overview-stat">
                <span class="stat-label">Total Deposits</span>
                <span class="stat-value">${{ totalDeposits.toFixed(2) }}</span>
            </div>
            <div class="overview-stat">
                <span class="stat-label">Total Wagered</span>
                <span class="stat-value">${{ totalWagered.toFixed(2) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'PerformanceOverview',
    computed: {
        ...mapGetters(['affiliatesData']),
        totalReferrals() {
            const codes = this.affiliatesData?.data?.codes || [];
            return codes.reduce((total, code) => total + (code.referredCount || 0), 0);
        },
        totalDeposits() {
            const referred = this.affiliatesData?.referred || [];
            const total = referred.reduce((sum, user) => {
                const deposit = user.stats?.deposit || 0;
                return sum + deposit;
            }, 0);
            return total / 1000;
        },
        totalWagered() {
            const referred = this.affiliatesData?.referred || [];
            const total = referred.reduce((sum, user) => {
                const statsExtra = user.statsExtra || {};
                const userTotal = Object.values(statsExtra).reduce((gameSum, gameStats) => {
                    return gameSum + (gameStats.bet || 0);
                }, 0);
                return sum + userTotal;
            }, 0);
            return total / 1000;
        }
    }
}
</script>

<style scoped>
.performance-overview {
    background: linear-gradient(135deg, rgba(44, 94, 255, 0.15) 0%, rgba(44, 94, 255, 0.05) 100%);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(44, 94, 255, 0.1);
}

.overview-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
    background: rgba(255, 255, 255, 0.1);
}

.overview-stat {
    background: rgba(13, 23, 39, 0.5);
    padding: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    transition: all 0.3s ease;
}

.overview-stat:hover {
    background: rgba(44, 94, 255, 0.1);
}

.stat-label {
    color: #8bacc8;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.stat-value {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 768px) {
    .overview-stats {
        grid-template-columns: 1fr;
    }

    .overview-stat {
        padding: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .stat-value {
        font-size: 20px;
    }
}
</style> 