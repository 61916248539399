<template>
  <div class="tier-comparison">
    <div class="comparison-header">
      <div class="header-content">
        <span class="header-icon">👑</span>
        <h3>TIER BENEFITS COMPARISON</h3>
      </div>
      <div class="header-filters">
        <button 
          v-for="category in categories" 
          :key="category.id"
          :class="['filter-btn', { active: selectedCategory === category.id }]"
          @click="selectedCategory = category.id"
        >
          {{ category.name }}
        </button>
      </div>
    </div>

    <div class="table-wrapper custom-scrollbar">
      <table class="comparison-table">
        <thead>
          <tr>
            <th class="feature-col">Feature</th>
            <th 
              v-for="tier in tiersList" 
              :key="tier.name"
              :class="['tier-col', { 'current': tier.name === currentTier }]"
            >
              <div class="tier-header">
                <div class="tier-badge">
                  <span class="tier-icon">{{ tier.icon }}</span>
                </div>
                <span class="tier-name">{{ tier.name.toUpperCase() }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Rakeback Section -->
          <template v-if="selectedCategory === 'rakeback' || selectedCategory === 'all'">
            <tr class="section-header">
              <td colspan="100%">Rakeback Benefits</td>
            </tr>
            <tr>
              <td class="feature-col">Slots Rakeback</td>
              <td 
                v-for="tier in tiersList" 
                :key="tier.name + '-slots'"
                :class="{ 'current-value': tier.name === currentTier }"
              >
                <span class="value-text">{{ (tier.slotsRate * 100).toFixed(1) }}%</span>
              </td>
            </tr>
            <tr>
              <td class="feature-col">Min Wager Required</td>
              <td 
                v-for="tier in tiersList" 
                :key="tier.name + '-wager'"
                :class="{ 'current-value': tier.name === currentTier }"
              >
                <span class="value-text">${{ formatAmount(tier.minWager) }}</span>
              </td>
            </tr>
          </template>

          <!-- Bonus Section -->
          <template v-if="selectedCategory === 'bonus' || selectedCategory === 'all'">
            <tr class="section-header">
              <td colspan="100%">Bonus Benefits</td>
            </tr>
            <tr>
              <td class="feature-col">Reload Bonus</td>
              <td 
                v-for="tier in tiersList" 
                :key="tier.name + '-reload'"
                :class="{ 'current-value': tier.name === currentTier }"
              >
                <span class="value-text">{{ (tier.benefits.reloadBonus * 100).toFixed() }}%</span>
              </td>
            </tr>
            <tr>
              <td class="feature-col">Birthday Bonus</td>
              <td 
                v-for="tier in tiersList" 
                :key="tier.name + '-birthday'"
                :class="{ 'current-value': tier.name === currentTier }"
              >
                <span :class="['status-icon', 'check']">✓</span>
              </td>
            </tr>
          </template>

          <!-- VIP Benefits -->
          <template v-if="selectedCategory === 'vip' || selectedCategory === 'all'">
            <tr class="section-header">
              <td colspan="100%">VIP Benefits</td>
            </tr>
            <tr>
              <td class="feature-col">Event Access</td>
              <td 
                v-for="tier in tiersList" 
                :key="tier.name + '-events'"
                :class="{ 'current-value': tier.name === currentTier }"
              >
                <span :class="['status-icon', tier.name.toLowerCase().includes('silver') || tier.name.toLowerCase().includes('gold') || tier.name.toLowerCase().includes('platinum') || tier.name.toLowerCase().includes('diamond') || tier.name.toLowerCase().includes('master') || tier.name.toLowerCase().includes('legend') ? 'check' : 'cross']">
                  {{ tier.name.toLowerCase().includes('silver') || tier.name.toLowerCase().includes('gold') || tier.name.toLowerCase().includes('platinum') || tier.name.toLowerCase().includes('diamond') || tier.name.toLowerCase().includes('master') || tier.name.toLowerCase().includes('legend') ? '✓' : '×' }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="feature-col">Exclusive Events</td>
              <td 
                v-for="tier in tiersList" 
                :key="tier.name + '-exclusive'"
                :class="{ 'current-value': tier.name === currentTier }"
              >
                <span :class="['status-icon', tier.name.toLowerCase().includes('silver iii') || tier.name.toLowerCase().includes('silver iv') || tier.name.toLowerCase().includes('silver v') || tier.name.toLowerCase().includes('gold') || tier.name.toLowerCase().includes('platinum') || tier.name.toLowerCase().includes('diamond') || tier.name.toLowerCase().includes('master') || tier.name.toLowerCase().includes('legend') ? 'check' : 'cross']">
                  {{ tier.name.toLowerCase().includes('silver iii') || tier.name.toLowerCase().includes('silver iv') || tier.name.toLowerCase().includes('silver v') || tier.name.toLowerCase().includes('gold') || tier.name.toLowerCase().includes('platinum') || tier.name.toLowerCase().includes('diamond') || tier.name.toLowerCase().includes('master') || tier.name.toLowerCase().includes('legend') ? '✓' : '×' }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="feature-col">Private Games</td>
              <td 
                v-for="tier in tiersList" 
                :key="tier.name + '-private'"
                :class="{ 'current-value': tier.name === currentTier }"
              >
                <span :class="['status-icon', tier.name.toLowerCase().includes('gold iii') || tier.name.toLowerCase().includes('gold iv') || tier.name.toLowerCase().includes('gold v') || tier.name.toLowerCase().includes('platinum') || tier.name.toLowerCase().includes('diamond') || tier.name.toLowerCase().includes('master') || tier.name.toLowerCase().includes('legend') ? 'check' : 'cross']">
                  {{ tier.name.toLowerCase().includes('gold iii') || tier.name.toLowerCase().includes('gold iv') || tier.name.toLowerCase().includes('gold v') || tier.name.toLowerCase().includes('platinum') || tier.name.toLowerCase().includes('diamond') || tier.name.toLowerCase().includes('master') || tier.name.toLowerCase().includes('legend') ? '✓' : '×' }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="feature-col">Custom Limits</td>
              <td 
                v-for="tier in tiersList" 
                :key="tier.name + '-limits'"
                :class="{ 'current-value': tier.name === currentTier }"
              >
                <span :class="['status-icon', tier.name.toLowerCase().includes('platinum iii') || tier.name.toLowerCase().includes('platinum iv') || tier.name.toLowerCase().includes('platinum v') || tier.name.toLowerCase().includes('diamond') || tier.name.toLowerCase().includes('master') || tier.name.toLowerCase().includes('legend') ? 'check' : 'cross']">
                  {{ tier.name.toLowerCase().includes('platinum iii') || tier.name.toLowerCase().includes('platinum iv') || tier.name.toLowerCase().includes('platinum v') || tier.name.toLowerCase().includes('diamond') || tier.name.toLowerCase().includes('master') || tier.name.toLowerCase().includes('legend') ? '✓' : '×' }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="feature-col">Luxury Gifts</td>
              <td 
                v-for="tier in tiersList" 
                :key="tier.name + '-gifts'"
                :class="{ 'current-value': tier.name === currentTier }"
              >
                <span :class="['status-icon', tier.name.toLowerCase().includes('diamond iii') || tier.name.toLowerCase().includes('diamond iv') || tier.name.toLowerCase().includes('diamond v') || tier.name.toLowerCase().includes('master') || tier.name.toLowerCase().includes('legend') ? 'check' : 'cross']">
                  {{ tier.name.toLowerCase().includes('diamond iii') || tier.name.toLowerCase().includes('diamond iv') || tier.name.toLowerCase().includes('diamond v') || tier.name.toLowerCase().includes('master') || tier.name.toLowerCase().includes('legend') ? '✓' : '×' }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="feature-col">Withdrawal Limit</td>
              <td 
                v-for="tier in tiersList" 
                :key="tier.name + '-limit'"
                :class="{ 'current-value': tier.name === currentTier }"
              >
                <span class="value-text">
                  {{ tier.benefits.withdrawalLimit === -1 ? 'Unlimited' : '$' + formatAmount(tier.benefits.withdrawalLimit) }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="feature-col">Giveaway Entries</td>
              <td 
                v-for="tier in tiersList" 
                :key="tier.name + '-giveaway'"
                :class="{ 'current-value': tier.name === currentTier }"
              >
                <span class="value-text">x{{ tier.benefits.giveawayEntries }}</span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { RAKEBACK_TIERS } from '../../constants/rakeback';

export default {
  name: 'TierComparisonTable',

  props: {
    currentTier: {
      type: String,
      default: 'bronze I'
    }
  },

  data() {
    return {
      selectedCategory: 'all',
      categories: [
        { id: 'all', name: 'All Benefits' },
        { id: 'rakeback', name: 'Rakeback' },
        { id: 'bonus', name: 'Bonuses' },
        { id: 'vip', name: 'VIP Benefits' }
      ]
    }
  },

  computed: {
    tiersList() {
      // Convert RAKEBACK_TIERS object to array and sort by minWager
      return Object.values(RAKEBACK_TIERS).sort((a, b) => a.minWager - b.minWager);
    },

    hasLegendTier() {
      return this.tiersList.some(tier => tier.name.toLowerCase() === 'legend');
    }
  },

  methods: {
    formatAmount(amount) {
      return Number(amount || 0).toLocaleString();
    },

    formatSupportTime(hours) {
      if (hours === 0) return 'Instant';
      if (hours < 1) return `${Math.round(hours * 60)}min`;
      return `${hours}h`;
    },

    formatBenefitName(benefit) {
      return benefit
        .replace(/([A-Z])/g, ' $1')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  }
}
</script>

<style scoped>
.tier-comparison {
  background: #1A1F37;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
}

.comparison-header {
  background: #1E2443;
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-content {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.header-icon {
  font-size: 24px;
}

.comparison-header h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 800;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.header-filters {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.filter-btn {
  background: rgba(44, 94, 255, 0.1);
  border: 1px solid rgba(44, 94, 255, 0.2);
  border-radius: 8px;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-btn:hover {
  background: rgba(44, 94, 255, 0.2);
  border-color: rgba(44, 94, 255, 0.3);
}

.filter-btn.active {
  background: #2C5EFF;
  border-color: #2C5EFF;
  color: #FFFFFF;
  box-shadow: 0 4px 12px rgba(44, 94, 255, 0.3);
}

.table-wrapper {
  overflow-x: auto;
  padding: 24px;
  margin: 0 -24px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(44, 94, 255, 0.5) rgba(255, 255, 255, 0.1);
}

.custom-scrollbar::-webkit-scrollbar {
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(44, 94, 255, 0.5);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(44, 94, 255, 0.7);
}

.comparison-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 14px;
  min-width: 1000px;
}

.comparison-table th,
.comparison-table td {
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.feature-col {
  text-align: left !important;
  position: sticky;
  left: 0;
  background: #1A1F37;
  min-width: 200px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  z-index: 1;
}

.tier-col {
  min-width: 160px;
  background: #1A1F37;
}

.tier-col.current {
  background: rgba(44, 94, 255, 0.1);
}

.tier-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.tier-badge {
  width: 48px;
  height: 48px;
  background: rgba(44, 94, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tier-icon {
  font-size: 24px;
}

.tier-name {
  font-weight: 700;
  color: #FFFFFF;
  letter-spacing: 0.5px;
}

.section-header td {
  background: rgba(44, 94, 255, 0.05);
  font-weight: 700;
  color: #FFFFFF;
  text-align: left;
  padding: 12px 16px;
  font-size: 16px;
}

.check {
  color: #4CAF50;
  font-weight: 700;
  font-size: 16px;
}

.cross {
  color: #FF5252;
  font-weight: 700;
  font-size: 16px;
}

@media (max-width: 1200px) {
  .comparison-table {
    min-width: 800px;
  }

  .feature-col {
    min-width: 180px;
  }

  .tier-col {
    min-width: 140px;
  }
}

@media (max-width: 768px) {
  .comparison-header {
    padding: 20px;
  }

  .header-content {
    margin-bottom: 12px;
  }

  .comparison-header h3 {
    font-size: 18px;
  }

  .filter-btn {
    padding: 8px 16px;
    font-size: 13px;
  }

  .table-wrapper {
    padding: 16px;
    margin: 0 -16px;
  }

  .comparison-table {
    min-width: 600px;
  }

  .comparison-table th,
  .comparison-table td {
    padding: 12px;
    font-size: 13px;
  }

  .feature-col {
    min-width: 160px;
  }

  .tier-col {
    min-width: 120px;
  }

  .tier-badge {
    width: 40px;
    height: 40px;
  }

  .tier-icon {
    font-size: 20px;
  }

  .section-header td {
    font-size: 14px;
    padding: 10px 12px;
  }
}

@media (max-width: 480px) {
  .comparison-header {
    padding: 16px;
  }

  .header-filters {
    gap: 8px;
  }

  .filter-btn {
    padding: 6px 12px;
    font-size: 12px;
  }

  .comparison-table {
    min-width: 480px;
  }

  .feature-col {
    min-width: 140px;
  }

  .tier-col {
    min-width: 100px;
  }
}

.comparison-table td {
  color: #FFFFFF;
  font-weight: 500;
  transition: all 0.2s ease;
}

.current-value {
  background: rgba(44, 94, 255, 0.15);
  position: relative;
}

.current-value::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #2C5EFF;
}

.value-text {
  color: #FFFFFF;
  font-weight: 600;
}

.status-icon {
  font-size: 18px;
  font-weight: 700;
}

.status-icon.check {
  color: #4CAF50;
}

.status-icon.cross {
  color: #FF5252;
}

.current-value .value-text {
  color: #2C5EFF;
  text-shadow: 0 0 8px rgba(44, 94, 255, 0.3);
}

.current-value .status-icon.check {
  color: #45D154;
  text-shadow: 0 0 8px rgba(69, 209, 84, 0.3);
}

.current-value .status-icon.cross {
  color: #FF6B6B;
  text-shadow: 0 0 8px rgba(255, 107, 107, 0.3);
}
</style> 