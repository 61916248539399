<template>
  <button v-on:click="modalsSetShow('Login')" class="auth-button">
    <div class="button-inner">
      Register
    </div>
  </button>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AuthButton",
  methods: {
    ...mapActions(["modalsSetShow"]),
  },
};
</script>

<style scoped>
button.auth-button {
  height: 50px;
  transition: ease-in-out 0.15s;
}

.auth-button:hover {
  transform: translateY(-1px);
}

.auth-button:hover .button-inner {
  filter: brightness(1.2);
}

.auth-button:active {
  transition-duration: 0.2s;
  transform: translateY(2px);
}

button.auth-button .button-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.9rem;
  text-align: center;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  border-radius: 0.5rem;
  background: linear-gradient(180deg, #34b1ff 0%, #005ee9 100%);
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  transition: ease-in-out 0.15s;
  color: #ffffff;
}

button.auth-button .button-inner svg {
  min-height: min(1vw, 1.5rem);
  min-width: min(1vw, 1.5rem);
  margin-right: 11px;
  fill: #ffffff;
}
</style>
