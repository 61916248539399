import { render, staticRenderFns } from "./RollCard.vue?vue&type=template&id=10e1c73f&scoped=true"
import script from "./RollCard.vue?vue&type=script&lang=js"
export * from "./RollCard.vue?vue&type=script&lang=js"
import style0 from "./RollCard.vue?vue&type=style&index=0&id=10e1c73f&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10e1c73f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10e1c73f')) {
      api.createRecord('10e1c73f', component.options)
    } else {
      api.reload('10e1c73f', component.options)
    }
    module.hot.accept("./RollCard.vue?vue&type=template&id=10e1c73f&scoped=true", function () {
      api.rerender('10e1c73f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/roll/RollCard.vue"
export default component.exports