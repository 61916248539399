<template>
    <div class="leaderboard">
        <LeaderboardBanner />

        <div class="leaderboard-container">
            <!-- Timer Section -->
            <div class="timer-card">
                <div class="timer-inner">
                    <IconTimer />
                    <div class="timer-value">
                        <span>{{leaderboardTimer.split(':')[0]}}</span>d 
                        <span>{{leaderboardTimer.split(':')[1]}}</span>h 
                        <span>{{leaderboardTimer.split(':')[2]}}</span>m 
                        <span>{{leaderboardTimer.split(':')[3]}}</span>s
                    </div>
                </div>
            </div>

            <!-- Ranks Section -->
            <div class="ranks-card">
                <div class="ranks-header">
                    <div class="header-item rank-pos">#</div>
                    <div class="header-item rank-user">USER</div>
                    <div class="header-item rank-prize">PRIZE</div>
                    <div class="header-item rank-wagered">WAGERED</div>
                </div>
                
                <div class="ranks-content">
                    <transition name="fade" mode="out-in">
                        <div v-if="leaderboardData.loading" class="content-loading" key="loading">
                            <LoadingAnimation />
                        </div>
                        <div v-else-if="leaderboardData.data" class="content-list" key="data">
                            <LeaderboardUserElement 
                                v-for="(winner, index) in leaderboardData.data.winners" 
                                :key="index" 
                                :position="index + 1" 
                                :winner="winner" 
                            />
                        </div>
                        <div v-else class="content-empty" key="empty">
                            No leaderboard data available
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.leaderboard {
    width: 100%;
    padding: 65px 20px 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leaderboard-container {
    width: 100%;
    max-width: 955px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

/* Timer Card Styles */
.timer-card {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                0px 1px 0px rgba(255, 255, 255, 0.3) inset;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.timer-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.timer-inner svg {
    width: 29px;
    height: 35px;
    fill: #ffffff;
}

.timer-value {
    font-size: 14px;
    font-weight: 600;
    color: #bbbfd0;
}

.timer-value span {
    font-size: 18px;
    font-weight: 800;
    color: #ffffff;
    margin: 0 2px;
}

/* Ranks Card Styles */
.ranks-card {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                0px 1px 0px rgba(255, 255, 255, 0.3) inset;
    border-radius: 10px;
    padding: 20px;
}

.ranks-header {
    display: flex;
    padding: 12px 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    margin-bottom: 12px;
}

.header-item {
    color: #8bacc8;
    font-weight: 700;
    font-size: 14px;
}

.rank-pos { width: 5%; }
.rank-user { width: 59%; }
.rank-prize, .rank-wagered { width: 18%; }

.ranks-content {
    min-height: 200px;
}

.content-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.content-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.content-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    color: #5e768e;
    font-size: 14px;
    font-weight: 600;
}

/* Transitions */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
    .leaderboard {
        padding: 45px 10px 25px;
    }

    .ranks-header {
        display: none;
    }

    .timer-card {
        margin-top: 10px;
    }

    .timer-value {
        font-size: 12px;
    }

    .timer-value span {
        font-size: 16px;
    }
}
</style>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconTimer from '@/components/icons/IconTimer';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import LeaderboardBanner from '@/components/leaderboard/LeaderboardBanner';
    import LeaderboardUserElement from '@/components/leaderboard/LeaderboardUserElement';

    export default {
        name: 'Leaderboard',
        metaInfo: {
            title: 'Leaderboard - Bazedbet'
        },
        components: {
            IconTimer,
            LoadingAnimation,
            LeaderboardBanner,
            LeaderboardUserElement
        },
        data() {
            return {
                leaderboardTimerInterval: null,
                leaderboardTimer: '00:00:00:00'
            }
        },
        methods: {
            ...mapActions([
                'leaderboardGetDataSocket'
            ]),
            leaderboardStartTimer() {
               const endingTime = new Date(this.leaderboardData.data.updatedAt).getTime() + (1000 * 60 * 60 * 24 * Number(this.leaderboardData.data.duration));
               let timeLeft = Math.floor((endingTime - (new Date().getTime() + this.generalTimeDiff)) / 1000);
               timeLeft = timeLeft <= 0 ? 0 : timeLeft;

               let timeDays = Math.floor(timeLeft / (60 * 60 * 24));
               timeDays = timeDays < 10 ? '0' + timeDays : timeDays;
               let timeHours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
               timeHours = timeHours < 10 ? '0' + timeHours : timeHours;
               let timeMinutes = Math.floor((timeLeft % (60 * 60)) / 60);
               timeMinutes = timeMinutes < 10 ? '0' + timeMinutes : timeMinutes;
               let timeSeconds = Math.floor(timeLeft % 60);
               timeSeconds = timeSeconds < 10 ? '0' + timeSeconds : timeSeconds;

               this.leaderboardTimer = timeDays.toString() + ':' + timeHours.toString() + ':' + timeMinutes.toString() + ':' + timeSeconds.toString();
           }
        },
        computed: {
            ...mapGetters([
                'generalTimeDiff', 
                'leaderboardData'
            ])
        },
        watch: {
            'leaderboardData.data': {
                handler() {
                    if(this.leaderboardData.data !== null) { 
                        clearInterval(this.leaderboardTimerInterval);
                        this.leaderboardTimerInterval = setInterval(() => { this.leaderboardStartTimer(); }, 1000);
                    }
                },
                deep: true
            }
        },  
        created() {
            if(this.leaderboardData.loading === false) {
                const data = {};
                this.leaderboardGetDataSocket(data);
            }
        }
    }
</script>
