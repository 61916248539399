const state = {
  isCollapsed: true,
};

const mutations = {
  SET_COLLAPSE_STATE(state, isCollapsed) {
    state.isCollapsed = isCollapsed;
  },
};

const actions = {
  updateCollapseState({ commit }, isCollapsed) {
    commit("SET_COLLAPSE_STATE", isCollapsed);
  },
};

const getters = {
  isCollapsed: (state) => state.isCollapsed,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
