<template>
  <div class="profile">
    <div class="profile-container">
      <ProfileHeader/>
      <ProfileBalance/>
      <ProfileStats/>
      <div class="container-nav">
        <ProfileFilterNav/>
      </div>
      <div class="container-content">
        <transition name="slide-fade" mode="out-in">
          <router-view/>
        </transition>
      </div>
      <div class="profile-section">
        <active-claims />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileHeader from '@/components/profile/ProfileHeader';
import ProfileStats from '@/components/profile/ProfileStats';
import ProfileFilterNav from '@/components/profile/ProfileFilterNav';
import ProfileBalance from '@/components/profile/ProfileBalance';
import ActiveClaims from './components/ActiveClaims.vue';

export default {
  name: 'Profile',
  components: {
    ProfileHeader,
    ProfileStats,
    ProfileFilterNav,
    ProfileBalance,
    ActiveClaims
  },
  metaInfo: {
    title: 'Profile - Bazedbet'
  }
};
</script>

<style scoped>
.profile {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 55px 0 45px 0;
}

.profile .profile-container {
  width: 1160px;
  padding: 50px 40px 25px 40px;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.profile .container-nav {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  padding: 0 20px;
}

.profile .container-nav::before {
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  left: 20px;
  right: 220px;
  transform: translate(0, -50%);
  background: rgba(255, 255, 255, 0.08);
}

.profile .container-content {
  width: 100%;
  margin-top: 25px;
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.15),
    0px -1px 0px 0px rgba(0, 0, 0, 0.15) inset;
}

/* Transition animations */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media only screen and (max-width: 1180px) {
  .profile {
    padding: 55px 10px 45px 10px;
  }

  .profile .profile-container {
    width: 100%;
    padding: 30px 15px 25px 15px;
  }

  .profile .container-nav::before {
    right: 180px;
    left: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .profile {
    padding: 30px 10px;
  }

  .profile .profile-container {
    padding: 20px 10px;
  }

  .profile .container-nav {
    margin-top: 30px;
    padding: 0 10px;
  }

  .profile .container-nav::before {
    display: none;
  }

  .profile .container-content {
    padding: 15px;
    margin-top: 15px;
  }
}
</style>