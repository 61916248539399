<template>
  <div class="duels-game-stats">
    <div class="stats-card buy-in">
      <div class="card-content">
        <div class="label">BUY IN</div>
        <div class="value">
          <img src="@/assets/img/icons/coin.svg" />
          <span class="amount">{{ duelsFormatValue(duelsGame.amount) }}</span>
        </div>
      </div>
      <div class="card-background"></div>
    </div>

    <div class="stats-card potential">
      <div class="card-content">
        <div class="label">POTENTIAL WINNINGS</div>
        <div class="value">
          <img src="@/assets/img/icons/coin.svg" />
          <span class="amount">
            {{
              duelsFormatValue(
                Math.floor(duelsGame.amount * duelsGame.playerCount * 0.95)
              )
            }}
          </span>
        </div>
      </div>
      <div class="card-background"></div>
    </div>

    <div class="stats-card players">
      <div class="card-content">
        <div class="label">PLAYERS</div>
        <div class="value">
          <IconUserGradient class="icon" />
          <span class="amount">{{ duelsGame.playerCount }}</span>
        </div>
      </div>
      <div class="card-background"></div>
    </div>
  </div>
</template>

<script>
import IconUserGradient from "@/components/icons/IconUserGradient";

export default {
  name: "DuelsGameStats",
  props: ["duelsGame"],
  components: {
    IconUserGradient,
  },
  methods: {
    duelsFormatValue(value) {
      return parseFloat(Math.floor(value / 10) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
.duels-game-stats {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin: 2rem 0 0 0;
  padding: 0 0.5rem;
}

.stats-card {
  position: relative;
  min-height: 120px;
  border-radius: 16px;
  background: linear-gradient(
    145deg,
    var(--background-secondary) 0%,
    rgba(var(--background-secondary-rgb), 0.8) 100%
  );
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: var(--background-secondary);
  box-shadow:
    0px 2px 0px 0px rgba(255, 255, 255, 0.2) inset,
    0px -2px 0px 0px rgba(0, 0, 0, 0.25) inset;
}

.stats-card:hover {
  transform: translateY(-4px);
}

.stats-card:hover .card-background {
  opacity: 1;
}

.card-content {
  position: relative;
  padding: 1.5rem;
  height: 100%;
  z-index: 2;
  color: white;
}

.card-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(var(--highlight-blue-rgb), 0.1) 0%,
    rgba(var(--highlight-blue-rgb), 0) 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.label {
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.value {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--highlight-blue);
}

.amount {
  font-size: 1.5rem;
  font-weight: 800;
  color: var(--text-primary);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Card-specific styles */
.stats-card.buy-in .card-background {
  background: linear-gradient(
    135deg,
    rgba(var(--highlight-blue-rgb), 0.1) 0%,
    rgba(var(--highlight-blue-rgb), 0) 100%
  );
}

.stats-card.potential .card-background {
  background: linear-gradient(
    135deg,
    rgba(var(--highlight-green-rgb), 0.1) 0%,
    rgba(var(--highlight-green-rgb), 0) 100%
  );
}

.stats-card.players .card-background {
  background: linear-gradient(
    135deg,
    rgba(var(--highlight-purple-rgb), 0.1) 0%,
    rgba(var(--highlight-purple-rgb), 0) 100%
  );
}

/* Mobile responsiveness */
@media (max-width: 1024px) {
  .duels-game-stats {
    gap: 1rem;
  }

  .card-content {
    padding: 1.25rem;
  }

  .amount {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .duels-game-stats {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .duels-game-stats {
    grid-template-columns: 1fr;
  }

  .stats-card {
    min-height: 100px;
  }

  .card-content {
    padding: 1rem;
  }

  .label {
    margin-bottom: 0.75rem;
  }
}
</style>
