import { render, staticRenderFns } from "./Limiteds.vue?vue&type=template&id=08fb8976&scoped=true"
import script from "./Limiteds.vue?vue&type=script&lang=js"
export * from "./Limiteds.vue?vue&type=script&lang=js"
import style0 from "./Limiteds.vue?vue&type=style&index=0&id=08fb8976&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08fb8976",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08fb8976')) {
      api.createRecord('08fb8976', component.options)
    } else {
      api.reload('08fb8976', component.options)
    }
    module.hot.accept("./Limiteds.vue?vue&type=template&id=08fb8976&scoped=true", function () {
      api.rerender('08fb8976', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/limiteds/Limiteds.vue"
export default component.exports