<template>
  <div class="credit-card-container">
    <iframe 
      frameborder="0" 
      allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *" 
      src="https://flooz.xyz/embed/trade?swapDisabled=false&swapNetwork=eth&swapLockToToken=true&onRampDisabled=false&onRampNetwork=eth&onRampAsDefault=true&onRampTokenAddress=0x3ee4b152824b657644c7a9b50694787e80eb8f4a&onRampLockToken=true&network=eth&lightMode=true&backgroundColor=transparent">
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'CashierCreditCard'
}
</script>

<style scoped>
.credit-card-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 200px);
  max-height: 800px;
  overflow-y: auto;
  padding: 20px;
}

iframe {
  width: 100%;
  max-width: 500px;
  height: 100%;
  border-radius: 12px;
  background: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style> 