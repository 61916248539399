<template>
  <div class="amount-input">
    <div class="input-header">
      <button class="back-button" @click="$emit('go-back')">
        <i class="fas fa-arrow-left"></i>
        Back
      </button>
      <div class="selected-currency">
        <div class="currency-icon">
          <img 
            :src="selectedCurrency.logo_url" 
            :alt="selectedCurrency.code"
            @error="handleImageError"
          />
        </div>
        <div class="currency-info">
          <span class="currency-name">{{ selectedCurrency.name }} ({{ selectedCurrency.code.toUpperCase() }})</span>
          <span class="network-name">{{ selectedCurrency.network.toUpperCase() }}</span>
        </div>
      </div>
    </div>

    <div class="input-container">
      <div class="amount-field">
        <div class="amount-label">
          <span>Amount (USD)</span>
          <span class="min-amount" v-if="minAmount">
            Min: ${{ minAmount }}
          </span>
        </div>
        <div class="amount-input-wrapper">
          <span class="currency-symbol">$</span>
          <input 
            type="number" 
            v-model="amount"
            :min="minAmount"
            placeholder="0.00"
            @input="handleAmountChange"
          />
        </div>
      </div>

      <div class="estimated-amount" v-if="estimatedAmount">
        <div class="estimate-label">You will pay approximately:</div>
        <div class="estimate-value">
          <img 
            :src="selectedCurrency.logo_url" 
            :alt="selectedCurrency.code"
            @error="handleImageError"
          />
          <span>{{ estimatedAmount }} {{ selectedCurrency.code.toUpperCase() }}</span>
        </div>
      </div>

      <button 
        class="generate-button"
        :disabled="!isValidAmount || isLoading"
        @click="generateAddress"
      >
        <div class="button-content">
          <i class="fas fa-spinner fa-spin" v-if="isLoading"></i>
          <span v-else>Generate Payment Address</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { mapState, mapActions } from 'vuex';
import nowPaymentsService from '@/services/nowPayments';

export default {
  name: 'AmountInput',
  
  props: {
    selectedCurrency: {
      type: Object,
      required: true
    },
    minAmount: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },

  setup(props, { emit }) {
    const amount = ref('');
    const estimatedAmount = ref(null);
    const isLoading = ref(false);

    const isValidAmount = computed(() => {
      const value = parseFloat(amount.value);
      return !isNaN(value) && value >= props.minAmount;
    });

    async function handleAmountChange() {
      const value = parseFloat(amount.value);
      if (isNaN(value) || value <= 0) {
        estimatedAmount.value = null;
        return;
      }

      try {
        const estimate = await nowPaymentsService.getEstimatedPrice(
          amount.value,
          'usd',
          props.selectedCurrency.code
        );
        estimatedAmount.value = estimate.estimated_amount;
      } catch (error) {
        console.error('Error getting estimate:', error);
        estimatedAmount.value = null;
      }
    }

    async function generateAddress() {
      if (!isValidAmount.value) return;

      isLoading.value = true;
      try {
        const amountNum = parseFloat(amount.value);
        if (amountNum < props.minAmount) {
          emit('notification-show', {
            type: 'error',
            message: `Minimum amount is ${props.minAmount} USD`
          });
          return;
        }

        const paymentData = {
          price_amount: amountNum,
          price_currency: 'usd',
          pay_currency: props.selectedCurrency.code.toLowerCase(),
          order_id: `order_${Date.now()}`,
          order_description: `Deposit ${amountNum} USD`,
          is_fixed_rate: false,
          is_fee_paid_by_user: false,
          currency_to: 'usdttrc20'
        };

        console.log('Generating payment with data:', paymentData);
        await emit('generate-address', paymentData);
      } catch (error) {
        console.error('Error generating address:', error);
        if (error.response?.status === 401) {
          emit('notification-show', {
            type: 'error',
            message: 'Session expired. Please log in again.'
          });
          window.location.reload();
          return;
        }
        
        // More specific error messages based on the error type
        let errorMessage = 'Failed to generate payment address';
        if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.details) {
          errorMessage = error.response.data.details;
        } else if (error.message) {
          errorMessage = error.message;
        }
        
        emit('notification-show', {
          type: 'error',
          message: errorMessage
        });
      } finally {
        isLoading.value = false;
      }
    }

    return {
      amount,
      estimatedAmount,
      isLoading,
      isValidAmount,
      handleAmountChange,
      generateAddress
    }
  },

  methods: {
    ...mapActions(['notificationShow']),
    handleImageError(event) {
      const target = event.target;
      const code = this.selectedCurrency.code.toLowerCase();
      target.src = `https://nowpayments.io/images/coins/${code}.svg`;
    }
  }
}
</script>

<style scoped>
.amount-input {
  width: fit-content;
  min-width: min(100%, 480px);
  margin: 0 auto;
  padding: 16px;
}

.input-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(31, 42, 68, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.2s ease;
}

.back-button:hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(31, 42, 68, 0.5) 100%);
  transform: translateY(-1px);
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.amount-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.amount-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.2px;
}

.min-amount {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
}

.amount-input-wrapper {
  position: relative;
}

.amount-input-wrapper input {
  width: 100%;
  padding: 12px 12px 12px 32px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(31, 42, 68, 0.2) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  transition: all 0.2s ease;
}

.amount-input-wrapper input:focus {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  border-color: rgba(44, 94, 255, 0.3);
  outline: none;
}

.currency-symbol {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.9);
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.estimated-amount {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: linear-gradient(180deg, rgba(44, 94, 255, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(44, 94, 255, 0.2);
}

.estimate-label {
  color: rgba(255, 255, 255, 0.7);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  letter-spacing: 0.2px;
}

.estimate-value {
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 0.9);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.estimate-value img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
}

.generate-button {
  width: 100%;
  padding: 14px;
  background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
  border-radius: 10px;
  color: white;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  transition: all 0.2s ease;
}

.generate-button:not(:disabled):hover {
  background: linear-gradient(90deg, #3468FF 0%, #2152E5 100%);
  transform: translateY(-1px);
}

.generate-button:disabled {
  background: linear-gradient(90deg, rgba(44, 94, 255, 0.5) 0%, rgba(29, 67, 192, 0.5) 100%);
  cursor: not-allowed;
}

.selected-currency {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  background: linear-gradient(180deg, rgba(44, 94, 255, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  border: 1px solid rgba(44, 94, 255, 0.2);
}

.currency-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.currency-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
}

.currency-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.currency-name {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.2px;
}

.network-name {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .amount-input {
    min-width: min(100%, 360px);
    padding: 12px;
  }

  .input-header {
    padding: 12px;
    flex-direction: column;
    gap: 12px;
  }

  .input-container {
    padding: 16px;
  }

  .amount-field {
    padding: 12px;
  }

  .amount-input-wrapper input {
    font-size: 14px;
    padding: 10px 10px 10px 28px;
  }

  .estimate-value {
    font-size: 18px;
  }

  .generate-button {
    padding: 12px;
    font-size: 13px;
  }

  .currency-icon {
    width: 28px;
    height: 28px;
  }

  .currency-name {
    font-size: 13px;
  }

  .network-name {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .amount-input {
    min-width: 100%;
    padding: 8px;
  }

  .input-header {
    padding: 10px;
  }

  .input-container {
    padding: 12px;
  }

  .amount-field {
    padding: 10px;
  }

  .amount-label {
    font-size: 13px;
  }

  .min-amount {
    font-size: 11px;
  }

  .amount-input-wrapper input {
    font-size: 13px;
    padding: 8px 8px 8px 24px;
  }

  .currency-symbol {
    left: 10px;
    font-size: 13px;
  }

  .estimate-value {
    font-size: 16px;
  }

  .estimate-value img {
    width: 20px;
    height: 20px;
  }

  .generate-button {
    padding: 10px;
    font-size: 12px;
  }

  .currency-icon {
    width: 24px;
    height: 24px;
  }

  .currency-name {
    font-size: 12px;
  }

  .network-name {
    font-size: 10px;
  }
}
</style> 