<template>
  <div class="bonus-templates">
    <div class="templates-header">
      <h2>Deposit Bonus Templates</h2>
      <button class="btn-primary" @click="showCreateModal = true">
        Create New Template
      </button>
    </div>

    <!-- Templates List -->
    <div class="templates-list">
      <div v-for="template in templates" :key="template._id" class="template-card">
        <div class="template-info">
          <h3>{{ template.name }}</h3>
          <p>{{ template.description }}</p>
          <div class="template-details">
            <span>{{ template.bonusPercentage }}% Bonus</span>
            <span>{{ template.wageringMultiplier }}x Wagering</span>
            <span>Min Deposit: ${{ template.minDeposit }}</span>
            <span>Max Bonus: ${{ template.maxBonus }}</span>
            <span>Expires in {{ template.expiryDays }} days</span>
          </div>
        </div>
        <div class="template-actions">
          <button 
            class="btn-toggle" 
            :class="{ active: template.isActive }"
            @click="toggleTemplate(template._id)"
          >
            {{ template.isActive ? 'Active' : 'Inactive' }}
          </button>
          <button class="btn-delete" @click="deleteTemplate(template._id)">
            Delete
          </button>
        </div>
      </div>
    </div>

    <!-- Create Template Modal -->
    <div v-if="showCreateModal" class="modal">
      <div class="modal-content">
        <h3>Create Bonus Template</h3>
        <form @submit.prevent="createTemplate">
          <div class="form-group">
            <label>Name</label>
            <input v-model="newTemplate.name" required>
          </div>
          <div class="form-group">
            <label>Description</label>
            <textarea v-model="newTemplate.description"></textarea>
          </div>
          <div class="form-group">
            <label>Bonus Percentage</label>
            <input type="number" v-model="newTemplate.bonusPercentage" min="0" max="100" required>
          </div>
          <div class="form-group">
            <label>Wagering Multiplier</label>
            <input type="number" v-model="newTemplate.wageringMultiplier" min="0" required>
          </div>
          <div class="form-group">
            <label>Minimum Deposit ($)</label>
            <input type="number" v-model="newTemplate.minDeposit" min="0" required>
          </div>
          <div class="form-group">
            <label>Maximum Bonus ($)</label>
            <input type="number" v-model="newTemplate.maxBonus" min="0" required>
          </div>
          <div class="form-group">
            <label>Expiry Days</label>
            <input type="number" v-model="newTemplate.expiryDays" min="1" required>
          </div>
          
          <!-- Product Type Restrictions -->
          <div class="form-group">
            <label>Restricted Product Types</label>
            <div class="product-types-grid">
              <div v-for="(value, key) in PRODUCT_TYPES" 
                   :key="key"
                   class="product-type-toggle"
                   :class="{ 
                     active: !newTemplate.allowedProductTypes.includes(value),
                     'is-slots': value === 'slots'
                   }"
                   @click="toggleProductType(value)">
                {{ formatProductType(key) }}
              </div>
            </div>
            <small class="help-text">Selected types are restricted from using bonus. Slots cannot be restricted.</small>
          </div>

          <div class="modal-actions">
            <button type="button" class="btn-secondary" @click="showCreateModal = false">
              Cancel
            </button>
            <button type="submit" class="btn-primary">
              Create Template
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { PRODUCT_TYPES } from '@/constants/bonus'

export default {
  name: 'BonusTemplates',
  data() {
    return {
      templates: [],
      showCreateModal: false,
      PRODUCT_TYPES,
      newTemplate: {
        name: '',
        description: '',
        bonusPercentage: 100,
        wageringMultiplier: 20,
        minDeposit: 10,
        maxBonus: 1000,
        expiryDays: 7,
        allowedProductTypes: ['slots'] // Initialize with only slots allowed
      }
    }
  },
  methods: {
    async fetchTemplates() {
      try {
        const response = await this.$http.get('/api/admin/bonus-templates');
        this.templates = response.data.templates;
      } catch (error) {
        this.$toast.error('Failed to fetch bonus templates');
      }
    },
    async createTemplate() {
      try {
        await this.$http.post('/api/admin/bonus-templates', this.newTemplate);
        this.$toast.success('Bonus template created successfully');
        this.showCreateModal = false;
        await this.fetchTemplates();
      } catch (error) {
        this.$toast.error('Failed to create bonus template');
      }
    },
    async toggleTemplate(templateId) {
      try {
        await this.$http.patch(`/api/admin/bonus-templates/${templateId}/toggle`);
        await this.fetchTemplates();
      } catch (error) {
        this.$toast.error('Failed to toggle template status');
      }
    },
    async deleteTemplate(templateId) {
      if (!confirm('Are you sure you want to delete this template?')) return;
      
      try {
        await this.$http.delete(`/api/admin/bonus-templates/${templateId}`);
        this.$toast.success('Template deleted successfully');
        await this.fetchTemplates();
      } catch (error) {
        this.$toast.error('Failed to delete template');
      }
    },
    toggleProductType(type) {
      if (type === 'slots') return; // Can't toggle slots
      
      if (!this.newTemplate.allowedProductTypes.includes(type)) {
        this.newTemplate.allowedProductTypes.push(type);
      } else {
        const index = this.newTemplate.allowedProductTypes.indexOf(type);
        if (index > -1) {
          this.newTemplate.allowedProductTypes.splice(index, 1);
        }
      }
    },
    formatProductType(type) {
      return type.split('_').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ).join(' ');
    }
  },
  mounted() {
    this.fetchTemplates();
  }
}
</script>

<style scoped>
.bonus-templates {
  padding: 20px;
}

.templates-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.template-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-info h3 {
  margin: 0 0 10px 0;
  color: var(--text-primary);
}

.template-details {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.template-details span {
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
}

.template-actions {
  display: flex;
  gap: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: var(--background-secondary);
  padding: 30px;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--text-secondary);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--text-primary);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn-toggle {
  padding: 5px 15px;
  border-radius: 5px;
  background: var(--background-tertiary);
  color: var(--text-secondary);
}

.btn-toggle.active {
  background: var(--color-success);
  color: white;
}

.btn-delete {
  padding: 5px 15px;
  border-radius: 5px;
  background: var(--color-danger);
  color: white;
}

@media (max-width: 768px) {
  .template-card {
    flex-direction: column;
    gap: 15px;
  }
  
  .template-actions {
    width: 100%;
    justify-content: flex-end;
  }
}

.product-types-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  margin-bottom: 8px;
}

.product-type-toggle {
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease;
  user-select: none;
}

.product-type-toggle:not(.is-slots):hover {
  background: rgba(255, 255, 255, 0.1);
}

.product-type-toggle.active {
  background: rgba(255, 59, 48, 0.1);
  border-color: rgba(255, 59, 48, 0.2);
  color: #ff3b30;
}

.product-type-toggle.is-slots {
  opacity: 0.5;
  cursor: not-allowed;
  background: rgba(76, 175, 80, 0.1);
  border-color: rgba(76, 175, 80, 0.2);
  color: #4CAF50;
}

.help-text {
  display: block;
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}
</style> 