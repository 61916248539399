<template>
  <div
    class="battles-filter-sort"
    v-bind:class="['sort-' + battlesFilterSortGames]"
  >
    SORT BY <span>DATE</span>
    <button
      v-on:click="
        battlesSetFilterSortGames(
          battlesFilterSortGames === 'date' ? 'price' : 'date'
        )
      "
    ></button>
    <span>PRICE</span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "BattlesFilterSort",
  methods: {
    ...mapActions(["battlesSetFilterSortGames"]),
  },
  computed: {
    ...mapGetters(["battlesFilterSortGames"]),
  },
};
</script>

<style scoped>
.battles-filter-sort {
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  color: #ffffff;
}

.battles-filter-sort span {
  margin-left: 5px;
  transition: color 0.3s ease;
}

.battles-filter-sort.sort-date span:first-of-type {
  color: #23DBE3;
}

.battles-filter-sort.sort-price span:last-of-type {
  color: #23DBE3;
}

.battles-filter-sort button {
  width: 45px;
  height: 24px;
  position: relative;
  margin: 0 10px;
}

.battles-filter-sort button::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  border-radius: 100px;
}

.battles-filter-sort button::after {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background: #23DBE3;
  transition: all 0.3s ease;
}

.battles-filter-sort.sort-price button::after {
  transform: translate(21px, 0);
}
</style>
