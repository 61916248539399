<template>
  <div class="duels-game-winner">
    <span>DUEL WINNER:</span>
    <div
      v-if="duelsGame.state !== 'completed'"
      class="winner-placeholder"
    ></div>
    <div v-else class="winner-content">
      <div class="content-inner">
        <div
          class="inner-user"
          v-bind:class="[
            'user-' +
              (duelsGame.winner.bot === true
                ? 'bot'
                : duelsGetRank(duelsGame.winner.user)),
            'user-' +
              (duelsGame.winner.bot === true
                ? ''
                : duelsGetLevelColor(duelsGame.winner.user)),
          ]"
        >
          <div class="user-avatar">
            <AvatarImage
              v-bind:image="
                duelsGame.winner.bot ? null : duelsGame.winner.user.avatar
              "
            />
          </div>
          <span
            v-html="
              duelsGame.winner.bot === true
                ? duelsGetBotName
                : duelsGame.winner.user.username
            "
          ></span>
        </div>
        <div class="inner-roll">
          <div class="roll-inner">
            <span>{{
              parseFloat(duelsGame.winner.roll / 100).toFixed(2)
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarImage from "@/components/AvatarImage";

export default {
  name: "DuelsGameWinner",
  props: ["duelsGame"],
  components: {
    AvatarImage,
  },
  methods: {
    duelsGetRank(user) {
      let rank = user.rakeback;

      if (user.rank !== "user") {
        rank = user.rank;
      }

      return rank;
    },
    duelsGetLevelColor(user) {
      let color = "";

      if (user.level >= 2 && user.level < 26) {
        color = "blue";
      } else if (user.level >= 26 && user.level < 51) {
        color = "green";
      } else if (user.level >= 51 && user.level < 76) {
        color = "orange";
      } else if (user.level >= 76 && user.level < 100) {
        color = "red";
      } else if (user.level >= 100) {
        color = "purple";
      }

      return color;
    },
  },
  computed: {
    duelsGetBotName() {
      const pos = this.duelsGame.bets.findIndex(
        (element) => element._id === this.duelsGame.winner._id
      );
      const names = [
        "Specter",
        "Lorenz",
        "Gio",
        "Cup",
        "Dip",
        "Fowntain",
        "Levrock26",
        "Chunkeh",
        "Mateheus",
      ];

      return names[pos - 1];
    },
  },
};
</script>

<style scoped>
.duels-game-winner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  font-size: 14px;
  font-weight: 800;
  color: var(--highlight-blue);
}

.duels-game-winner .winner-placeholder,
.duels-game-winner .winner-content {
  width: 460px;
  height: 78px;
  margin-top: 25px;
  border-radius: 8px;
}

.duels-game-winner .winner-placeholder {
  border: 1px solid var(--highlight-blue);
}

.duels-game-winner .winner-content {
  position: relative;
  padding: 1px;
  z-index: 1;
}

.duels-game-winner .winner-content:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background: var(--highlight-blue);
  z-index: -1;
}

.modal-duels-game .winner-content:after {
  content: "";
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 8px;
  background: var(--background-primary);
  z-index: -1;
}

.modal-duels-game .content-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  border-radius: 8px;
  background: var(--highlight-blue);
  box-shadow: inset 0px 5px 50px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.modal-duels-game .inner-user {
  display: flex;
  align-items: center;
}

.modal-duels-game .user-avatar {
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-right: 12px;
  border-radius: 50%;
  background-color: #33002c;
  border: 1px solid #9e9e9e;
  overflow: hidden;
}

.modal-duels-game .inner-user.user-blue .user-avatar {
  border: 1px solid #559ee4;
}

.modal-duels-game .inner-user.user-green .user-avatar {
  border: 1px solid #b8e92d;
}

.modal-duels-game .inner-user.user-orange .user-avatar {
  border: 1px solid #fca311;
}

.modal-duels-game .inner-user.user-red .user-avatar {
  border: 1px solid #ff4e4e;
}

.modal-duels-game .inner-user.user-purple .user-avatar {
  border: 1px solid #6953f1;
}

.modal-duels-game .inner-user.user-partner .user-avatar {
  border: 1px solid #eca822;
}

.modal-duels-game .inner-user.user-mod .user-avatar {
  border: 1px solid #ffb703;
}

.modal-duels-game .inner-user.user-bot .user-avatar,
.modal-duels-game .inner-user.user-admin .user-avatar {
  border: 1px solid #00ffc2;
}

.modal-duels-game .user-avatar .avatar-image {
  width: 100%;
  height: 100%;
}

.modal-duels-game .inner-user span {
  width: 100px;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

.modal-duels-game .inner-roll {
  width: 70px;
  height: 35px;
  position: relative;
  padding: 1px;
  z-index: 1;
}

.modal-duels-game .roll-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  background: var(--background-secondary);
  border-radius: 0.5rem;
}

@media only screen and (max-width: 510px) {
  .duels-game-winner {
    width: 100%;
  }

  .duels-game-winner .winner-placeholder,
  .duels-game-winner .winner-content {
    width: 100%;
  }
}
</style>
