<template>
    <div class="leaderboard-banner">
        <div class="banner-content">
            <!-- Title Section -->
            <div class="banner-title">
                <h1>LEADERBOARD</h1>
                <span class="subtitle">WIN AND EARN FREE MONEY!</span>
            </div>

            <!-- Ranks Display -->
            <div class="ranks-display">
                <div class="rank-item second">
                    <span class="position">2</span>
                    <div class="rank-platform"></div>
                </div>
                <div class="rank-item first">
                    <span class="position">1</span>
                    <div class="rank-platform"></div>
                </div>
                <div class="rank-item third">
                    <span class="position">3</span>
                    <div class="rank-platform"></div>
                </div>
            </div>

            <!-- Decorative Elements -->
            <div class="banner-decorations">
                <img class="character left" src="@/assets/img/banner-character.png" alt="character">
                <img class="character right" src="@/assets/img/home/mainbanner/Character_2.png" alt="character">
                <img class="coin coin-1" src="@/assets/img/icons/coin.svg" alt="coin">
                <img class="coin coin-2" src="@/assets/img/icons/coin.svg" alt="coin">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LeaderboardBanner'
}
</script>

<style scoped>
.leaderboard-banner {
    width: 100%;
    max-width: 955px;
    margin: 0 auto;
    position: relative;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                0px 1px 0px rgba(255, 255, 255, 0.3) inset;
    border-radius: 16px;
    overflow: hidden;
}

.banner-content {
    position: relative;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(90deg, 
        rgba(44, 94, 255, 0.1) 0%, 
        rgba(44, 94, 255, 0.05) 100%
    );
}

/* Title Styles */
.banner-title {
    text-align: center;
    position: relative;
    z-index: 2;
}

.banner-title h1 {
    font-size: 42px;
    font-weight: 800;
    color: #FFFFFF;
    margin: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner-title .subtitle {
    display: block;
    font-size: 18px;
    color: #8bacc8;
    margin-top: 8px;
    font-weight: 600;
}

/* Ranks Display */
.ranks-display {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
}

.rank-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.rank-platform {
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
    border-radius: 2px;
    margin-top: 8px;
    box-shadow: 0 2px 4px rgba(44, 94, 255, 0.3);
}

.position {
    font-size: 32px;
    font-weight: 800;
    background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Rank Variations */
.rank-item.first .position {
    font-size: 48px;
    color: #FFD700;
    background: linear-gradient(180deg, #FFD700 0%, #FFA500 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rank-item.first .rank-platform {
    width: 100px;
    background: linear-gradient(90deg, #FFD700 0%, #FFA500 100%);
    box-shadow: 0 2px 4px rgba(255, 215, 0, 0.3);
}

.rank-item.second .position,
.rank-item.third .position {
    opacity: 0.8;
}

/* Decorative Elements */
.banner-decorations {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

.character {
    position: absolute;
    height: 180px;
    bottom: 0;
    transition: transform 0.3s ease;
}

.character.left {
    left: 5%;
    transform: translateX(-50%);
}

.character.right {
    right: 5%;
    transform: translateX(50%);
}

.coin {
    position: absolute;
    width: 40px;
    height: 40px;
    animation: float 3s ease-in-out infinite;
}

.coin-1 {
    top: 20%;
    left: 15%;
    animation-delay: 0.5s;
}

.coin-2 {
    top: 30%;
    right: 15%;
    animation-delay: 1s;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

/* Media Queries */
@media only screen and (max-width: 768px) {
    .banner-content {
        padding: 30px 15px;
    }

    .banner-title h1 {
        font-size: 32px;
    }

    .banner-title .subtitle {
        font-size: 16px;
    }

    .character {
        height: 140px;
    }

    .character.left {
        left: 0;
        transform: translateX(-30%);
    }

    .character.right {
        right: 0;
        transform: translateX(30%);
    }

    .coin {
        width: 30px;
        height: 30px;
    }
}

@media only screen and (max-width: 480px) {
    .banner-title h1 {
        font-size: 28px;
    }

    .banner-title .subtitle {
        font-size: 14px;
    }

    .character {
        height: 120px;
    }

    .ranks-display {
        gap: 15px;
    }

    .rank-platform {
        width: 60px;
    }

    .rank-item.first .rank-platform {
        width: 80px;
    }
}
</style>
