// Product type restrictions
export const PRODUCT_TYPES = {
  SLOTS: 'slots',
  LIVE_CASINO: 'live-casino',
  VIRTUAL_SPORTS: 'virtual-sports',
  TV_GAMES: 'tv-games',
  INSTANT_GAMES: 'instant-games',
  BINGO: 'bingo'
}

// List of product types that can use bonus balance
export const BONUS_ELIGIBLE_PRODUCT_TYPES = [PRODUCT_TYPES.SLOTS]

// List of providers that are eligible for bonus wagering
export const BONUS_ELIGIBLE_PROVIDERS = [
  'pragmatic',
  'spinomenal',
  'betsoft',
  'habanero',
  'pgsoft',
  'nolimitcity',
  'thunderkick',
  'gameart',
  'kalamba-games',
  'onetouch'
]

// Excluded game names (even if they're from eligible providers)
export const BONUS_EXCLUDED_GAMES = [
  'Book of Dead',
  'Starburst',
  'Gonzo\'s Quest',
  'Sweet Bonanza'
]

// Helper function to check if a game is eligible for bonus wagering
export const isGameEligibleForBonus = (game) => {
  // Check game type
  if (!BONUS_ELIGIBLE_PRODUCT_TYPES.includes(game.type)) {
    return false
  }

  // Check provider
  if (!BONUS_ELIGIBLE_PROVIDERS.includes(game.provider)) {
    return false
  }

  // Check if game is specifically excluded
  if (BONUS_EXCLUDED_GAMES.includes(game.name)) {
    return false
  }

  return true
} 