<template>
  <div class="rewards-container">
    <div class="rewards-header">
      <h1>Rewards & VIP</h1>
      <p>Earn rakeback and exclusive benefits as you play</p>
    </div>

    <div class="rewards-content" v-if="!isLoading">
      <div class="rewards-stats">
        <div class="stat-card">
          <div class="stat-icon">🎰</div>
          <div class="stat-info">
            <span class="stat-label">Total Wagered</span>
            <span class="stat-value">${{ formatAmount(totalWagered) }}</span>
          </div>
        </div>
        <div class="stat-card">
          <div class="stat-icon">💎</div>
          <div class="stat-info">
            <span class="stat-label">Current Tier</span>
            <span class="stat-value tier-value">{{ currentTier ? currentTier.name.toUpperCase() : 'BRONZE I' }}</span>
            <div class="tier-progress" v-if="nextTier">
              <div class="progress-bar">
                <div class="progress-fill" :style="{ width: `${Math.min(userProgress * 100, 100)}%` }"></div>
              </div>
              <span class="progress-text">{{ Math.round(userProgress * 100) }}% to {{ nextTier.name.toUpperCase() }}</span>
            </div>
          </div>
        </div>
        <div class="stat-card">
          <div class="stat-icon">🎁</div>
          <div class="stat-info">
            <span class="stat-label">Available Rakeback</span>
            <span class="stat-value">${{ formatAmount(availableRakeback) }}</span>
          </div>
        </div>
      </div>

      <RakebackClaims 
        :available-claims="availableClaims"
        :pending-claims="pendingClaims"
        @claim="handleClaim"
      />
      <!-- Rakeback Guide "How Rakeback Works" -->
      <RakebackGuide />

      <div class="daily-cases">
        <div class="section-header">
          <h2>DAILY CASES</h2>
        </div>
        <div class="cases-content">
          <transition name="fade" mode="out-in">
            <div v-if="isLoading" class="content-loading" key="loading">
              <div class="loading-placeholder" v-for="i in getPlaceholderCount" :key="i"></div>
            </div>
            <div v-else-if="rakebackData.boxes && rakebackData.boxes.length > 0" class="content-list" key="data">
              <RewardsBoxElement v-for="box of rakebackData.boxes" :key="box._id" :box="box" />
            </div>
            <div v-else class="content-empty" key="empty">
              <div class="empty-icon">📦</div>
              <span>There are no cases available</span>
            </div>
          </transition>
        </div>
      </div>

      <TierComparisonTable 
        :current-tier="currentTier ? currentTier.name : 'bronze I'"
      />

      <RakebackFAQ class="rewards-faq" />
    </div>

    <div v-else class="loading-container">
      <div class="loading-spinner"></div>
      <p>Loading rewards data...</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import RakebackClaims from '@/components/rewards/RakebackClaims.vue'
import TierComparisonTable from '@/components/rewards/TierComparisonTable.vue'
import RewardsBoxElement from '@/components/rewards/RewardsBoxElement.vue'
import RakebackFAQ from '@/components/rewards/RakebackFAQ.vue'
import RakebackGuide from '@/components/rewards/RakebackGuide.vue'
import socketService from '@/services/socket';
import { RAKEBACK_TIERS } from '../constants/rakeback';

export default {
  name: 'Rewards',
  
  components: {
    
    RakebackClaims,
    TierComparisonTable,
    RewardsBoxElement,
    RakebackFAQ,
    RakebackGuide
  },
  
  data() {
    return {
      isLoading: true,
      error: null,
      retryCount: 0,
      maxRetries: 3,
      socket: null,
      lastWeeklyClaim: null,
      lastReloadClaim: null,
      lastBirthdayClaim: null,
      pendingClaims: [],
      defaultTier: {
        name: 'bronze I',
        benefits: {
          weeklyBonus: 10,
          reloadBonus: 0.05,
          birthdayBonus: 50,
          weeklyBonusEnabled: true,
          reloadBonusEnabled: true
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'user',
      'getRakebackData',
      'socketGeneral',
      'authUser'
    ]),

    rakebackData() {
      const defaultData = {
        totalWagered: this.authUser?.stats?.bet || 0,
        earned: 0,
        currentTier: this.defaultTier,
        nextTier: null,
        pools: {
          hourly: { amount: 0, canClaim: false, timeLeft: 0 },
          daily: { amount: 0, canClaim: false, timeLeft: 0 },
          weekly: { amount: 0, canClaim: false, timeLeft: 0 },
          monthly: { amount: 0, canClaim: false, timeLeft: 0 }
        }
      };

      return this.getRakebackData || defaultData;
    },

    totalWagered() {
      return this.rakebackData.totalWagered;
    },

    availableRakeback() {
      const pools = this.rakebackData.pools || {};
      return Object.values(pools).reduce((total, pool) => total + pool.amount, 0);
    },

    allTiers() {
      return Object.values(RAKEBACK_TIERS).sort((a, b) => a.minWager - b.minWager);
    },

    currentTier() {
      return this.rakebackData.currentTier;
    },

    nextTier() {
      return this.rakebackData.nextTier;
    },

    userProgress() {
      if (!this.nextTier || !this.currentTier) return 0;
      const progress = (this.totalWagered - this.currentTier.minWager) / 
        (this.nextTier.minWager - this.currentTier.minWager);
      return Math.min(Math.max(progress, 0), 1);
    },

    canClaimWeekly() {
      if (!this.lastWeeklyClaim) return true;
      const weekInMs = 7 * 24 * 60 * 60 * 1000;
      return Date.now() - this.lastWeeklyClaim > weekInMs;
    },

    canClaimReload() {
      if (!this.lastReloadClaim) return true;
      const dayInMs = 24 * 60 * 60 * 1000;
      return Date.now() - this.lastReloadClaim > dayInMs;
    },

    canClaimBirthday() {
      if (!this.user?.birthday || this.lastBirthdayClaim) return false;
      const today = new Date();
      const birthday = new Date(this.user.birthday);
      return today.getMonth() === birthday.getMonth() && 
             today.getDate() === birthday.getDate();
    },

    timeUntilWeeklyClaim() {
      if (!this.lastWeeklyClaim) return 'Claim Now';
      const weekInMs = 7 * 24 * 60 * 60 * 1000;
      const timeLeft = weekInMs - (Date.now() - this.lastWeeklyClaim);
      return this.formatTimeLeft(timeLeft);
    },

    timeUntilReloadClaim() {
      if (!this.lastReloadClaim) return 'Claim Now';
      const dayInMs = 24 * 60 * 60 * 1000;
      const timeLeft = dayInMs - (Date.now() - this.lastReloadClaim);
      return this.formatTimeLeft(timeLeft);
    },

    daysUntilBirthday() {
      if (!this.user?.birthday) return 'Set birthday in profile';
      const today = new Date();
      const birthday = new Date(this.user.birthday);
      birthday.setFullYear(today.getFullYear());
      if (birthday < today) {
        birthday.setFullYear(today.getFullYear() + 1);
      }
      const diff = birthday - today;
      return `${Math.ceil(diff / (1000 * 60 * 60 * 24))} days`;
    },

    getPlaceholderCount() {
      if (window.innerWidth <= 480) return 2;
      if (window.innerWidth <= 768) return 3;
      if (window.innerWidth <= 1024) return 4;
      return 7;
    },

    availableClaims() {
      return this.rakebackData.pools || {};
    }
  },

  methods: {
    ...mapActions([
      'rakebackGetDataSocket',
      'rakebackClaimSocket',
      'socketConnectGeneral'
    ]),

    async initializeSocket() {
      try {
        // Connect to socket if not already connected
        if (!this.socketGeneral) {
          await this.socketConnectGeneral();
        }

        // Initialize data
        await this.initializeRakebackData();

        // Set up socket reconnection
        this.socketGeneral.on('disconnect', () => {
          console.log('Socket disconnected, attempting to reconnect...');
          setTimeout(() => this.initializeSocket(), 1000);
        });

      } catch (error) {
        console.error('Error initializing socket:', error);
        this.error = 'Failed to connect to server';
        // Retry connection
        setTimeout(() => this.initializeSocket(), 5000);
      }
    },

    async initializeRakebackData() {
      if (this.retryCount >= this.maxRetries) {
        this.error = 'Unable to load rakeback data after multiple attempts';
        this.isLoading = false;
        return;
      }

      try {
        // Set initial data
        this.$store.commit('rakeback_set_data', {
          totalWagered: this.authUser?.stats?.bet || 0,
          earned: 0,
          currentTier: this.defaultTier,
          nextTier: null,
          pools: {
            hourly: { amount: 0, canClaim: false, timeLeft: 0 },
            daily: { amount: 0, canClaim: false, timeLeft: 0 },
            weekly: { amount: 0, canClaim: false, timeLeft: 0 },
            monthly: { amount: 0, canClaim: false, timeLeft: 0 }
          }
        });

        // Fetch rakeback data
        await this.rakebackGetDataSocket();
        this.isLoading = false;
        this.error = null;
      } catch (error) {
        console.error('Error initializing rewards:', error);
        this.retryCount++;
        setTimeout(() => this.initializeRakebackData(), 1000);
      }
    },

    formatAmount(amount) {
      return Number(amount || 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },

    formatTimeLeft(ms) {
      if (ms <= 0) return '00:00:00';
      const hours = Math.floor(ms / (1000 * 60 * 60));
      const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((ms % (1000 * 60)) / 1000);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },

    handleClaim(type) {
      if (!this.rakebackData.pools?.[type]?.canClaim) {
        return;
      }

      this.rakebackClaimSocket({ type })
        .then(response => {
          if (response?.success) {
            this.$toast.success(`Successfully claimed ${type} rakeback!`);
          } else {
            throw new Error(response?.error?.message || `Failed to claim ${type} rakeback`);
          }
        })
        .catch(error => {
          console.error('Error claiming rakeback:', error);
          this.$toast.error(error.message);
        });
    }
  },

  created() {
    // Initialize socket connection
    this.initializeSocket();
  },

  mounted() {
    // Add socket event listener for real-time updates
    if (this.socketGeneral) {
      this.socketGeneral.on('rakeback:update', (data) => {
        if (data) {
          this.$store.commit('rakeback_set_data', data);
        }
      });

      // Handle socket errors
      this.socketGeneral.on('error', (error) => {
        console.error('Socket error:', error);
        this.$toast.error('Connection error. Attempting to reconnect...');
      });
    }
  },

  beforeDestroy() {
    // Clean up socket listeners
    if (this.socketGeneral) {
      this.socketGeneral.off('rakeback:update');
      this.socketGeneral.off('error');
      this.socketGeneral.off('disconnect');
    }
  }
}
</script>

<style scoped>
.rewards-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.rewards-header {
  text-align: center;
  margin-bottom: 32px;
}

.rewards-header h1 {
  font-size: 36px;
  font-weight: 800;
  margin: 0;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rewards-header p {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 8px;
}

.rewards-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 32px;
}

.stat-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  transition: all 0.3s ease;
}

.stat-card:hover {
  background: rgba(44, 94, 255, 0.1);
  transform: translateY(-1px);
}

.stat-icon {
  font-size: 32px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(44, 94, 255, 0.1);
  border-radius: 12px;
}

.stat-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.stat-label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
}

.stat-value {
  font-size: 24px;
  font-weight: 700;
  color: #FFFFFF;
}

.tier-value {
  font-size: 18px;
  font-weight: 700;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tier-progress-text {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
  text-shadow: none;
  font-weight: normal;
  line-height: 1.5;
}

.daily-cases {
  background: #1A1F37;
  border-radius: 12px;
  overflow: hidden;
  margin: 24px 0;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
}

.section-header {
  background: #1E2443;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.section-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 800;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.cases-content {
  padding: 24px;
}

.content-loading {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}

.loading-placeholder {
  aspect-ratio: 1;
  background: linear-gradient(223deg, rgba(44, 94, 255, 0.1) 0%, rgba(44, 94, 255, 0.05) 50%, rgba(44, 94, 255, 0.1) 100%);
  border-radius: 8px;
  animation: pulse 1.5s infinite;
}

.content-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}

.content-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  gap: 16px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.empty-icon {
  font-size: 48px;
  opacity: 0.5;
}

.rewards-faq {
  margin-top: 32px;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 0.3; }
  100% { opacity: 0.6; }
}

@media (max-width: 768px) {
  .rewards-container {
    padding: 16px;
  }

  .rewards-stats {
    grid-template-columns: 1fr;
  }

  .cases-content {
    padding: 16px;
  }

  .content-loading,
  .content-list {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 12px;
  }
}

@media (max-width: 480px) {
  .content-loading,
  .content-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
}

.tier-display {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tier-name {
  font-size: 24px;
  font-weight: 800;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.tier-progress {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
}

.progress-bar {
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #2C5EFF 0%, #5F8AFF 100%);
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  text-align: right;
}
</style>