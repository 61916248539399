<template>
  <div class="payment-details">
    <!-- Header -->
    <div class="details-header">
      <button class="back-button" @click="$emit('go-back')">
        <i class="fas fa-arrow-left"></i>
        Back
      </button>
      <div class="selected-currency">
        <div class="currency-icon">
          <img 
            :src="selectedCurrency.logo_url" 
            :alt="selectedCurrency.code"
            @error="handleImageError"
          />
        </div>
        <div class="currency-info">
          <span class="currency-name">{{ selectedCurrency.name }} ({{ selectedCurrency.code.toUpperCase() }})</span>
          <span class="network-name">{{ selectedCurrency.network.toUpperCase() }}</span>
        </div>
      </div>
    </div>

    <div class="details-container">
      <!-- QR Code -->
      <div class="qr-section" v-if="qrCodeUrl">
        <div class="qr-wrapper">
          <img :src="qrCodeUrl" :alt="paymentData.pay_currency" />
        </div>
      </div>

      <!-- Payment Information -->
      <div class="payment-info">
        <!-- Amount to Send -->
        <div class="info-card">
          <div class="info-header">Send exactly:</div>
          <div class="amount-display">
            <img 
              :src="selectedCurrency.logo_url" 
              :alt="selectedCurrency.code"
              @error="handleImageError"
            />
            <span class="amount">{{ paymentData.pay_amount }} {{ paymentData.pay_currency.toUpperCase() }}</span>
          </div>
        </div>

        <!-- Payment Address -->
        <div class="info-card">
          <div class="info-header">To address:</div>
          <div class="copy-field">
            <input type="text" readonly :value="paymentData.pay_address" />
            <button @click="copyToClipboard(paymentData.pay_address)" class="copy-button">
              <i class="fas fa-copy"></i>
            </button>
          </div>
        </div>

        <!-- Memo Field if required -->
        <div class="info-card" v-if="paymentData.payin_extra_id">
          <div class="info-header">Memo (Required):</div>
          <div class="copy-field">
            <input type="text" readonly :value="paymentData.payin_extra_id" />
            <button @click="copyToClipboard(paymentData.payin_extra_id)" class="copy-button">
              <i class="fas fa-copy"></i>
            </button>
          </div>
        </div>

        <!-- Payment Status -->
        <div class="status-card" :class="paymentData.payment_status">
          <div class="status-icon">
            <i class="fas" :class="statusIcon"></i>
          </div>
          <div class="status-content">
            <div class="status-text">{{ statusMessage }}</div>
            <div class="progress-bar">
              <div class="progress" :style="{ width: `${paymentProgress}%` }"></div>
            </div>
          </div>
        </div>

        <!-- Warning Message -->
        <div class="warning-card">
          <i class="fas fa-exclamation-triangle"></i>
          <span>Please send the exact amount. Sending a different amount may result in transaction failure.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import QRCode from 'qrcode';
import nowPaymentsService from '@/services/nowPayments';
import { mapActions } from 'vuex';

export default {
  name: 'PaymentDetails',
  
  props: {
    paymentData: {
      type: Object,
      required: true
    },
    selectedCurrency: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const qrCodeUrl = ref(null);
    const statusCheckInterval = ref(null);
    const paymentStatus = ref(props.paymentData.payment_status);

    const generateQRCode = async () => {
      try {
        qrCodeUrl.value = await QRCode.toDataURL(props.paymentData.pay_address);
      } catch (error) {
        console.error('Failed to generate QR code:', error);
      }
    };

    const startStatusCheck = () => {
      let checkCount = 0;
      const maxChecks = 120; // Maximum 2 minutes of fast checking
      
      statusCheckInterval.value = setInterval(async () => {
        try {
          checkCount++;
          const status = await nowPaymentsService.getPaymentStatus(props.paymentData.payment_id);
          paymentStatus.value = status.payment_status;
          
          // If payment is finished
          if (status.payment_status === 'finished') {
            clearInterval(statusCheckInterval.value);
            
            emit('notification-show', {
              type: 'success',
              message: 'Payment confirmed successfully!'
            });

            // Remove page refresh, balance will be updated via socket
            emit('payment-completed');
          }
          
          // If payment failed or expired
          if (['failed', 'expired'].includes(status.payment_status)) {
            clearInterval(statusCheckInterval.value);
            
            emit('notification-show', {
              type: 'error',
              message: `Payment ${status.payment_status}. Please try again.`
            });
          }

          // After 2 minutes of checking, slow down the interval
          if (checkCount === maxChecks) {
            clearInterval(statusCheckInterval.value);
            statusCheckInterval.value = setInterval(async () => {
              const slowStatus = await nowPaymentsService.getPaymentStatus(props.paymentData.payment_id);
              paymentStatus.value = slowStatus.payment_status;
              
              if (slowStatus.payment_status === 'finished') {
                clearInterval(statusCheckInterval.value);
                emit('notification-show', {
                  type: 'success',
                  message: 'Payment confirmed successfully!'
                });
                // Remove page refresh, balance will be updated via socket
                emit('payment-completed');
              }
            }, 30000); // Check every 30 seconds after the first 2 minutes
          }
        } catch (error) {
          console.error('Failed to check payment status:', error);
          if (error.response?.status === 401) {
            // Instead of page refresh, handle auth error appropriately
            emit('auth-error');
          }
        }
      }, 1000); // Check every second for the first 2 minutes
    };

    onMounted(() => {
      generateQRCode();
      startStatusCheck();
    });

    onBeforeUnmount(() => {
      if (statusCheckInterval.value) {
        clearInterval(statusCheckInterval.value);
      }
    });

    return {
      qrCodeUrl,
      paymentStatus,
      startStatusCheck
    };
  },

  computed: {
    statusIcon() {
      const icons = {
        'waiting': 'fa-clock',
        'confirming': 'fa-spinner fa-spin',
        'confirmed': 'fa-spinner fa-spin',
        'sending': 'fa-spinner fa-spin',
        'finished': 'fa-check-circle',
        'failed': 'fa-times-circle',
        'expired': 'fa-times-circle',
        'partially_paid': 'fa-exclamation-circle'
      };
      return icons[this.paymentStatus] || 'fa-clock';
    },

    statusMessage() {
      const messages = {
        'waiting': 'Waiting for payment...',
        'confirming': 'Payment detected, confirming...',
        'confirmed': 'Payment confirmed, processing...',
        'sending': 'Processing your payment...',
        'finished': 'Payment completed!',
        'failed': 'Payment failed',
        'expired': 'Payment expired',
        'partially_paid': 'Partial payment detected'
      };
      return messages[this.paymentStatus] || 'Waiting for payment...';
    },

    paymentProgress() {
      const progress = {
        'waiting': 0,
        'confirming': 25,
        'confirmed': 50,
        'sending': 75,
        'finished': 100,
        'failed': 100,
        'expired': 100,
        'partially_paid': 100
      };
      return progress[this.paymentStatus] || 0;
    }
  },

  methods: {
    ...mapActions(['notificationShow']),

    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.notificationShow({
          type: 'success',
          message: 'Copied to clipboard!'
        });
      } catch (error) {
        console.error('Failed to copy:', error);
      }
    },

    handleImageError(event) {
      const target = event.target;
      const code = this.selectedCurrency.code.toLowerCase();
      target.src = `https://nowpayments.io/images/coins/${code}.svg`;
    }
  }
}
</script>

<style scoped>
.payment-details {
  padding: 16px;
  width: fit-content;
  min-width: min(100%, 480px);
  margin: 0 auto;
}

.details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(31, 42, 68, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.2s ease;
}

.back-button:hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(31, 42, 68, 0.5) 100%);
  transform: translateY(-1px);
}

.details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.qr-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.qr-wrapper {
  display: inline-block;
  padding: 16px;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.payment-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.info-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.info-header {
  color: rgba(255, 255, 255, 0.7);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  letter-spacing: 0.2px;
}

.amount-display {
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 0.9);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.amount-display img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
}

.copy-field {
  display: flex;
  gap: 8px;
  width: 100%;
}

.copy-field input {
  flex: 1;
  padding: 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(31, 42, 68, 0.2) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  letter-spacing: 0.2px;
  min-width: 0;
  width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
  height: auto;
  min-height: 44px;
  resize: none;
  overflow-wrap: break-word;
  line-height: 1.4;
}

.copy-button {
  padding: 0 16px;
  height: 44px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(31, 42, 68, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.2s ease;
  white-space: nowrap;
  align-self: flex-start;
}

.copy-button:hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(31, 42, 68, 0.5) 100%);
  transform: translateY(-1px);
}

.status-card {
  padding: 16px;
  background: linear-gradient(180deg, rgba(44, 94, 255, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(44, 94, 255, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  display: flex;
  align-items: center;
  gap: 16px;
}

.status-card.finished {
  background: linear-gradient(180deg, rgba(76, 175, 80, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  border: 1px solid rgba(76, 175, 80, 0.2);
}

.status-card.failed,
.status-card.rejected,
.status-card.cancelled {
  background: linear-gradient(180deg, rgba(233, 72, 72, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  border: 1px solid rgba(233, 72, 72, 0.2);
}

.status-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #2C5EFF;
  flex-shrink: 0;
}

.status-card.finished .status-icon {
  color: #4CAF50;
}

.status-card.failed .status-icon,
.status-card.rejected .status-icon,
.status-card.cancelled .status-icon {
  color: #E94848;
}

.status-content {
  flex: 1;
  min-width: 0;
}

.status-text {
  color: rgba(255, 255, 255, 0.9);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
}

.progress-bar {
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
  box-shadow: 0px 0px 8px rgba(44, 94, 255, 0.5);
  transition: width 0.3s ease;
}

.warning-card {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: linear-gradient(180deg, rgba(255, 193, 7, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 193, 7, 0.2);
  color: #FFC107;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.selected-currency {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  background: linear-gradient(180deg, rgba(44, 94, 255, 0.1) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  border: 1px solid rgba(44, 94, 255, 0.2);
}

.currency-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.currency-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
}

.currency-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.currency-name {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.2px;
}

.network-name {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .payment-details {
    min-width: min(100%, 360px);
    padding: 12px;
  }

  .details-header {
    padding: 12px;
  }

  .details-container {
    padding: 16px;
  }

  .status-card {
    padding: 12px;
  }

  .status-icon {
    width: 32px;
    height: 32px;
    font-size: 20px;
  }

  .status-text {
    font-size: 13px;
  }

  .info-card {
    padding: 12px;
  }

  .info-header {
    font-size: 13px;
  }

  .amount-display {
    font-size: 18px;
  }

  .copy-field {
    flex-direction: column;
  }

  .copy-field input {
    font-size: 13px;
    padding: 10px;
    min-height: 40px;
  }

  .copy-button {
    width: 100%;
    height: 40px;
    align-self: stretch;
  }

  .warning-card {
    font-size: 12px;
    padding: 12px;
  }

  .currency-name {
    font-size: 13px;
  }

  .network-name {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .payment-details {
    min-width: 100%;
    padding: 8px;
  }

  .details-header {
    padding: 10px;
  }

  .details-container {
    padding: 12px;
  }

  .status-card {
    padding: 10px;
    gap: 12px;
  }

  .status-icon {
    width: 28px;
    height: 28px;
    font-size: 18px;
  }

  .status-text {
    font-size: 12px;
  }

  .info-card {
    padding: 10px;
  }

  .info-header {
    font-size: 12px;
  }

  .amount-display {
    font-size: 16px;
  }

  .copy-field input {
    font-size: 12px;
    padding: 8px;
    min-height: 36px;
  }

  .copy-button {
    height: 36px;
  }

  .warning-card {
    font-size: 11px;
    padding: 10px;
  }

  .currency-icon {
    width: 28px;
    height: 28px;
  }

  .currency-name {
    font-size: 12px;
  }

  .network-name {
    font-size: 10px;
  }
}
</style> 