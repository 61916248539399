<template>
  <div class="game-slider feature-games" ref="slider">
    <!-- Games List -->
    <div class="games-container">
      <div class="game-row">
        <!-- Regular Game Cards -->
        <div
          v-for="(game, index) in displayedGames"
          :key="game.gameId"
          class="game-card"
          @click="handleGameSelect(game)"
        >
          <div class="game">
            <img
              :src="game.imageUrl"
              :alt="game.translationKey || game.name"
              class="game-image"
              loading="lazy"
            />
            <div class="game-overlay"></div>
            <div class="game-info">
              <div class="game-name">{{ game.translationKey || game.name }}</div>
              <div class="provider-name">{{ game.gameProvider || 'Megaways' }}</div>
            </div>
          </div>
        </div>

        <!-- See More Card -->
        <div 
          v-if="displayedGames.length >= 31" 
          class="game-card see-more-card"
          @click="navigateToFullView"
        >
          <div class="see-more-content">
            <font-awesome-icon icon="arrow-right" class="see-more-icon" />
            <span class="see-more-text">See More</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading State -->
    <div v-if="loading" class="loading-state">
      <div class="spinner"></div>
      <p>Loading games...</p>
    </div>

    <!-- Error State -->
    <div v-if="error" class="error-state">
      <font-awesome-icon icon="exclamation-circle" class="error-icon" />
      <p class="error-message">{{ error }}</p>
      <button @click="fetchGames" class="retry-button">
        <font-awesome-icon icon="redo" />
        Retry
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'SecondFeatureGamesSlider',

  data() {
    return {
      loading: false,
      error: null
    }
  },

  computed: {
    ...mapState({
      authUser: state => state.auth.authUser,
      authToken: state => state.auth.authToken,
      filters: state => state.eva.filters.megaways,
      games: state => state.eva.megawaysGames
    }),
    
    isAuthenticated() {
      return !!(this.authUser?.user?._id && this.authToken)
    },

    displayedGames() {
      return this.games.slice(0, 31)
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },

    lobbyUrl() {
      return `${window.location.origin}/games`
    }
  },

  mounted() {
    // Set initial filters for megaways games
    this.setFilters({
      filters: {
        searchQuery: 'megaways',
        isActive: true,
        channel: 'desktop'
      },
      target: 'megaways',
      loadAll: true // Fetch all pages
    })
  },

  methods: {
    ...mapActions('eva', ['launchGame', 'setFilters', 'fetchGames', 'setCurrentGame']),

    async handleGameSelect(game) {
      try {
        console.log('[SecondFeatureGamesSlider] Game selected:', game)
        
        // Get current category from store
        const currentCategory = this.filters.isOriginal

        // If we're in the Originals category, use direct routing
        if (currentCategory) {
          const routeMap = {
            'mines': '/mines',
            'crash': '/crash',
            'blackjack': '/blackjack',
            'towers': '/tower',
            'duels': '/diceduels',
            'coinflip': '/coinflip'
          }
          
          const route = routeMap[game.gameId]
          if (route) {
            this.$router.push(route)
            return
          }
        }
        
        // First navigate to /games
        await this.$router.push('/games')
        
        // Then set the current game in the store
        await this.setCurrentGame(game)

      } catch (error) {
        console.error('[SecondFeatureGamesSlider] Error selecting game:', error)
        this.error = error.message || 'Failed to select game'
        this.$store.dispatch('notificationShow', {
          type: 'error',
          message: this.error
        })
      }
    },

    navigateToFullView() {
      this.$router.push({
        name: 'Games',
        query: {
          search: 'megaways'
        }
      })
    }
  }
}
</script>

<style scoped>
@import '../../styles/gameCard.css';

.game-slider {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 1rem 0;
}

.game-slider::-webkit-scrollbar {
  display: none;
}

.games-container {
  padding: 0.5rem;
  width: 100%;
  overflow: hidden;
}

.game-row {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: 180px;
  gap: 1rem;
  padding: 0.5rem;
  overflow-x: auto;
}

.game-card {
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.75rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.game-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.game {
  position: relative;
  width: 100%;
  height: 100%;
}

.game-image {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.game-card:hover .game-image {
  transform: scale(1.05);
}

.game-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7) 50%, transparent);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.game-card:hover .game-overlay {
  opacity: 1;
}

.game-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  transform: translateY(100%);
  transition: transform 0.2s ease;
}

.game-card:hover .game-info {
  transform: translateY(0);
}

.game-name {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.provider-name {
  margin: 0.25rem 0 0;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

/* See More Card */
.see-more-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(44, 94, 255, 0.1) 0%, rgba(44, 94, 255, 0.2) 100%);
}

.see-more-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
}

.see-more-icon {
  font-size: 1.5rem;
  transition: transform 0.2s ease;
}

.see-more-card:hover .see-more-icon {
  transform: translateX(4px);
}

.see-more-text {
  font-size: 0.875rem;
  font-weight: 500;
}

/* Loading and Error States */
.loading-state,
.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 3rem;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.spinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.25rem solid rgba(52, 157, 255, 0.1);
  border-left-color: #349DFF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-icon {
  font-size: 2rem;
  color: #FF4D4D;
}

.error-message {
  color: rgba(255, 255, 255, 0.8);
}

.retry-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.retry-button:hover {
  background: rgba(255, 255, 255, 0.15);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 1440px) {
  .game-row {
    grid-auto-columns: 160px;
  }
}

@media (max-width: 1024px) {
  .game-row {
    grid-auto-columns: 140px;
    gap: 0.75rem;
  }
}

@media (max-width: 768px) {
  .game-row {
    grid-auto-columns: 120px;
    gap: 0.5rem;
  }
  
  .game-name {
    font-size: 0.875rem;
  }
  
  .provider-name {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .game-row {
    grid-auto-columns: 100px;
    gap: 0.5rem;
  }
  
  .games-container {
    padding: 0.25rem;
  }
  
  .game-name {
    font-size: 0.75rem;
  }
  
  .provider-name {
    font-size: 0.625rem;
  }
}
</style>