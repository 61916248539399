<template>
  <nav class="mobile-menu">
    <button
      v-for="(item, index) in menuItems"
      :key="index"
      class="menu-item"
      :class="{ selected: selectedItem === item.name }"
      @click="selectItem(item.name)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="item.width || '24'"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path :d="getItemPath(item)" />
      </svg>
      <span>{{ getItemName(item) }}</span>
    </button>
  </nav>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      selectedItem: "Explore",
      menuItems: [
        {
          name: "Menu",
          path: "M3.25 18V16H21.25V18H3.25ZM3.25 13V11H21.25V13H3.25ZM3.25 8V6H21.25V8H3.25Z"
        },
        {
          name: "Explore",
          path: "M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z",
          backPath: "M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
        },
        {
          name: "Casino",
          path: "M8 18C8.41667 18 8.77083 17.8542 9.0625 17.5625C9.35417 17.2708 9.5 16.9167 9.5 16.5C9.5 16.0833 9.35417 15.7292 9.0625 15.4375C8.77083 15.1458 8.41667 15 8 15C7.58333 15 7.22917 15.1458 6.9375 15.4375C6.64583 15.7292 6.5 16.0833 6.5 16.5C6.5 16.9167 6.64583 17.2708 6.9375 17.5625C7.22917 17.8542 7.58333 18 8 18ZM8 9C8.41667 9 8.77083 8.85417 9.0625 8.5625C9.35417 8.27083 9.5 7.91667 9.5 7.5C9.5 7.08333 9.35417 6.72917 9.0625 6.4375C8.77083 6.14583 8.41667 6 8 6C7.58333 6 7.22917 6.14583 6.9375 6.4375C6.64583 6.72917 6.5 7.08333 6.5 7.5C6.5 7.91667 6.64583 8.27083 6.9375 8.5625C7.22917 8.85417 7.58333 9 8 9ZM12.5 13.5C12.9167 13.5 13.2708 13.3542 13.5625 13.0625C13.8542 12.7708 14 12.4167 14 12C14 11.5833 13.8542 11.2292 13.5625 10.9375C13.2708 10.6458 12.9167 10.5 12.5 10.5C12.0833 10.5 11.7292 10.6458 11.4375 10.9375C11.1458 11.2292 11 11.5833 11 12C11 12.4167 11.1458 12.7708 11.4375 13.0625C11.7292 13.3542 12.0833 13.5 12.5 13.5ZM17 18C17.4167 18 17.7708 17.8542 18.0625 17.5625C18.3542 17.2708 18.5 16.9167 18.5 16.5C18.5 16.0833 18.3542 15.7292 18.0625 15.4375C17.7708 15.1458 17.4167 15 17 15C16.5833 15 16.2292 15.1458 15.9375 15.4375C15.6458 15.7292 15.5 16.0833 15.5 16.5C15.5 16.9167 15.6458 17.2708 15.9375 17.5625C16.2292 17.8542 16.5833 18 17 18ZM17 9C17.4167 9 17.7708 8.85417 18.0625 8.5625C18.3542 8.27083 18.5 7.91667 18.5 7.5C18.5 7.08333 18.3542 6.72917 18.0625 6.4375C17.7708 6.14583 17.4167 6 17 6C16.5833 6 16.2292 6.14583 15.9375 6.4375C15.6458 6.72917 15.5 7.08333 15.5 7.5C15.5 7.91667 15.6458 8.27083 15.9375 8.5625C16.2292 8.85417 16.5833 9 17 9ZM5.5 21C4.95 21 4.47917 20.8042 4.0875 20.4125C3.69583 20.0208 3.5 19.55 3.5 19V5C3.5 4.45 3.69583 3.97917 4.0875 3.5875C4.47917 3.19583 4.95 3 5.5 3H19.5C20.05 3 20.5208 3.19583 20.9125 3.5875C21.3042 3.97917 21.5 4.45 21.5 5V19C21.5 19.55 21.3042 20.0208 20.9125 20.4125C20.5208 20.8042 20.05 21 19.5 21H5.5Z",
        },
        {
          name: "Sports",
          path: "M4 11.0729C4.07538 10.304 4.23367 9.57663 4.47487 8.8907C4.71608 8.20477 5.04774 7.5603 5.46985 6.95729C5.99749 7.5 6.43844 8.1294 6.79271 8.84548C7.14698 9.56156 7.37688 10.304 7.48241 11.0729H4ZM18.5176 11.0729C18.6231 10.304 18.8492 9.56533 19.196 8.85678C19.5427 8.14824 19.9874 7.52261 20.5302 6.9799C20.9523 7.56784 21.2839 8.20854 21.5251 8.90201C21.7663 9.59548 21.9246 10.3191 22 11.0729H18.5176ZM5.46985 17.0201C5.04774 16.4322 4.71608 15.7952 4.47487 15.1093C4.23367 14.4234 4.07538 13.696 4 12.9271H7.48241C7.37688 13.696 7.14698 14.4347 6.79271 15.1432C6.43844 15.8518 5.99749 16.4774 5.46985 17.0201ZM20.5302 17.0201C19.9874 16.4774 19.5427 15.8518 19.196 15.1432C18.8492 14.4347 18.6231 13.696 18.5176 12.9271H22C21.9246 13.6809 21.7663 14.4045 21.5251 15.098C21.2839 15.7915 20.9523 16.4322 20.5302 17.0201ZM9.31407 11.0729C9.19347 9.98744 8.8995 8.98492 8.43216 8.06533C7.96482 7.14573 7.35427 6.31658 6.6005 5.57789C7.32412 4.85427 8.1495 4.27387 9.07663 3.83668C10.0038 3.3995 11.0025 3.1206 12.0729 3V11.0729H9.31407ZM13.9271 11.0729V3C14.9975 3.1206 15.9962 3.3995 16.9234 3.83668C17.8505 4.27387 18.6759 4.85427 19.3995 5.57789C18.6307 6.30151 18.0163 7.12688 17.5565 8.05402C17.0967 8.98116 16.8065 9.98744 16.6859 11.0729H13.9271ZM12.0729 21C10.9874 20.8794 9.98492 20.5967 9.06533 20.152C8.14573 19.7073 7.32412 19.1231 6.6005 18.3995C7.36935 17.6759 7.98367 16.8543 8.44347 15.9347C8.90327 15.0151 9.19347 14.0126 9.31407 12.9271H12.0729V21ZM13.9271 21V12.9271H16.6859C16.8065 14.0126 17.0967 15.0188 17.5565 15.946C18.0163 16.8731 18.6307 17.6985 19.3995 18.4221C18.6759 19.1457 17.8505 19.7261 16.9234 20.1633C15.9962 20.6005 14.9975 20.8794 13.9271 21Z",
        },
        {
          name: "Chat",
          path: "M3 21V4.8C3 4.305 3.17625 3.88125 3.52875 3.52875C3.88125 3.17625 4.305 3 4.8 3H19.2C19.695 3 20.1187 3.17625 20.4713 3.52875C20.8237 3.88125 21 4.305 21 4.8V15.6C21 16.095 20.8237 16.5188 20.4713 16.8713C20.1187 17.2238 19.695 17.4 19.2 17.4H6.6L3 21ZM6.6 13.8H13.8V12H6.6V13.8ZM6.6 11.1H17.4V9.3H6.6V11.1ZM6.6 8.4H17.4V6.6H6.6V8.4Z",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["generalSetSidebarMobile"]),
    ...mapActions('eva', ['setShowGameModal']),
    async selectItem(itemName) {
      // If we're in game mode and Explore is clicked, treat it as back button
      if (itemName === "Explore" && this.showGameModal) {
        this.setShowGameModal(false);
        return;
      }

      this.selectedItem = itemName;

      if (itemName !== "Menu") {
        this.generalSetSidebarMobile(null);
      }

      try {
        switch (itemName) {
          case "Menu":
            this.generalSetSidebarMobile(
              this.generalSidebarMobile === "Navbar" ? null : "Navbar"
            );
            break;
          case "Explore":
            await this.$router.push("/");
            break;
          case "Casino":
            await this.$router.push("/games");
            break;
          case "Sports":
            await this.$router.push("/sports");
            break;
          case "Chat":
            this.generalSetSidebarMobile("Chat");
            break;
        }
      } catch (error) {
        console.error('Navigation error:', error);
        this.selectedItem = this.$route.path === "/" ? "Explore" : 
                          this.$route.path === "/games" ? "Casino" :
                          this.$route.path === "/sports" ? "Sports" : "Explore";
      }
    },

    getItemPath(item) {
      if (item.name === "Explore" && this.showGameModal) {
        return item.backPath;
      }
      return item.path;
    },

    getItemName(item) {
      if (item.name === "Explore" && this.showGameModal) {
        return "Back";
      }
      return item.name;
    }
  },
  computed: {
    ...mapGetters(["generalSidebarMobile"]),
    ...mapState({
      showGameModal: state => state.eva.showGameModal
    })
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(path) {
        switch(path) {
          case '/':
            this.selectedItem = 'Explore';
            break;
          case '/games':
            this.selectedItem = 'Casino';
            break;
          case '/sports':
            this.selectedItem = 'Sports';
            break;
        }
      }
    }
  },
  created() {
    const path = this.$route.path;
    this.selectedItem = path === "/" ? "Explore" : 
                       path === "/games" ? "Casino" :
                       path === "/sports" ? "Sports" : "Explore";
  }
};
</script>

<style scoped>
.mobile-menu {
  display: grid;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem;
  z-index: 100;
  border-top: 1px solid rgba(121, 121, 121, 0.2);
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    #161c2c;
  box-shadow: 0px 4px 8.9px 0px rgba(0, 0, 0, 0.13);
  height: 78px;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.menu-item svg {
  margin-bottom: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
}

.menu-item.selected {
  color: var(--highlight-blue);
  text-shadow: 0px 0px 4px #00468a;
}

.menu-item.selected svg {
  fill: var(--highlight-blue);
  filter: drop-shadow(0px 0px 4px #00468a);
}

.mobile-menu {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5rem;
}
</style>
