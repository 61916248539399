<template>
  <div class="futures-slider">
    <div class="futures-container">
      <FuturesItem 
        v-for="future in futures" 
        :key="future.symbol"
        :symbol="future.symbol"
        :price="future.price"
        :change="future.change"
        :volume="future.volume"
        :leverage="future.leverage"
        :image="future.image"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import FuturesItem from './FuturesItem.vue'

defineProps({
  futures: {
    type: Array,
    required: true
  }
})
</script>

<style scoped>
.futures-slider {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.futures-slider::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.futures-container {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  width: max-content; /* Allow container to grow based on content */
}
</style>
