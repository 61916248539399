<template>
  <div class="unbox">
    <div class="unbox-wrapper">
      <img src="@/assets/img/unbox/unbox-background.png" />
      <div class="unbox-header">
        <UnboxHeaderOverview v-if="unboxGetRouteName === 'UnboxOverview'" />
        <UnboxHeaderBox v-else-if="unboxGetRouteName === 'UnboxBox'" />
      </div>
      <div class="unbox-content">
        <transition name="slide-fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UnboxHeaderOverview from "@/components/unbox/UnboxHeaderOverview";
import UnboxHeaderBox from "@/components/unbox/UnboxHeaderBox";

export default {
  name: "Unbox",
  metaInfo: {
    title: "Unbox - Bazedbet",
  },
  components: {
    UnboxHeaderOverview,
    UnboxHeaderBox,
  },
  methods: {
    ...mapActions(["socketConnectUnbox", "socketDisconnectUnbox"]),
  },
  computed: {
    unboxGetRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.socketConnectUnbox();
  },
  beforeRouteLeave(to, from, next) {
    this.socketDisconnectUnbox();
    next();
  },
};
</script>

<style scoped>
.unbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  padding-top: 54px;
}

.unbox .unbox-wrapper {
  width: 90%;
  padding: 32px;
  border: 3px solid #00ffc2;
  border-radius: 16px;
  position: relative;
}

.unbox .unbox-wrapper img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: 0.15;
  user-select: none;
  pointer-events: none;
}

.unbox .unbox-wrapper .unbox-header {
  width: 110%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(28, 71, 182, 0.15);
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3.125rem;
  padding: 12px 24px;
  background: linear-gradient(180deg, #cad9eb 0%, #7e8da5 100%);
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -4px 0px 0px #979797 inset,
    0px 3px 0px 0px #fff inset;
}

.unbox .unbox-wrapper .unbox-content {
  width: 100%;
  margin-top: 86px;
}

@media only screen and (max-width: 1383px) {
  .unbox .unbox-wrapper .unbox-content{
    margin-top: 132px;
  }
}

@media only screen and (max-width: 1183px) {
  .unbox .unbox-wrapper .unbox-content{
    margin-top: 86px;
  }
}

@media only screen and (max-width: 1083px) {
  .unbox .unbox-wrapper .unbox-content{
    margin-top: 132px;
  }
}
</style>
