<template>
  <div class="admin-promotions-container">
    <div class="admin-header">
      <div class="header-nav">
        <button v-if="showForm" class="btn-back" @click="cancelForm">
          <span class="back-icon">←</span> Back to List
        </button>
        <h1>Promotions Management</h1>
      </div>
      <p>Create and manage promotional campaigns</p>
    </div>

    <!-- Create/Edit Form -->
    <div class="content-section" v-if="showForm">
      <div class="section-header">
        <h2>{{ editingPromotion ? 'Edit Promotion' : 'Create New Promotion' }}</h2>
        <div class="header-actions">
          <button class="btn-template" @click="fillExampleTemplate">
            <i class="fas fa-magic"></i> Fill Example Template
          </button>
          <div class="step-indicator">
            Step {{ currentStep }} of {{ totalSteps }}
          </div>
        </div>
      </div>

      <form @submit.prevent="savePromotion" class="promotion-form">
        <!-- Step 1: Type Selection (Always first) -->
        <div class="form-step" :class="{ 'step-complete': currentStep > 1 }">
          <div class="step-header" @click="toggleStep(1)">
            <h3>1. Type & Basic Information</h3>
            <span class="step-toggle">{{ currentStep > 1 ? '▼' : '▶' }}</span>
          </div>
          <div class="step-content" :class="{ 'step-collapsed': currentStep > 1 }">
            <div class="type-selection">
              <h3>Select Promotion Type</h3>
              <div class="type-grid">
                <div 
                  v-for="(config, type) in promotionTypes" 
                  :key="type"
                  class="type-card"
                  :class="{ active: formData.type === type }"
                  @click="formData.type = type"
                >
                  <h4>{{ config.label }}</h4>
                  <p>{{ config.description }}</p>
                </div>
              </div>
            </div>

            <div class="form-section">
              <h3>Basic Information</h3>
              <div class="form-group">
                <label>Title</label>
                <input v-model="formData.title" type="text" required>
              </div>
              <div class="form-group">
                <label>Description (Short)</label>
                <textarea v-model="formData.description" required></textarea>
              </div>
              <div class="form-group">
                <label>Full Description</label>
                <textarea 
                  v-model="formData.fullDescription" 
                  rows="5"
                  required
                  placeholder="Provide a detailed description of the promotion, including all important details and benefits."
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <!-- Step 2: Media & Display -->
        <div v-if="currentStep >= 2" class="form-step" :class="{ 'step-complete': currentStep > 2 }">
          <div class="step-header" @click="toggleStep(2)">
            <h3>2. Media & Display</h3>
            <span class="step-toggle">{{ currentStep > 2 ? '▼' : '▶' }}</span>
          </div>
          <div class="step-content" :class="{ 'step-collapsed': currentStep > 2 }">
            <div class="form-section">
              <h3>Media & Display</h3>
              <div class="form-group">
                <label>Image URL</label>
                <input v-model="formData.image" type="url" required>
              </div>
              <div class="form-group">
                <label>Button Text</label>
                <input v-model="formData.buttonText" type="text" required>
              </div>
              <div class="form-group" v-if="currentTypeConfig.defaultLink !== null">
                <label>Custom Link (Optional)</label>
                <input v-model="formData.link" type="text" placeholder="Leave empty to use default">
              </div>
              <div class="form-group">
                <label>Valid Until</label>
                <input v-model="formData.validUntil" type="datetime-local" required>
              </div>
            </div>
          </div>
        </div>

        <!-- Step 3: Type Specific Content -->
        <div v-if="currentStep >= 3" class="form-step" :class="{ 'step-complete': currentStep > 3 }">
          <div class="step-header" @click="toggleStep(3)">
            <h3>3. {{ stepTitle }}</h3>
            <span class="step-toggle">{{ currentStep > 3 ? '▼' : '▶' }}</span>
          </div>
          <div class="step-content" :class="{ 'step-collapsed': currentStep > 3 }">
            <!-- Reward Configuration for Bonus Types -->
            <div v-if="showRewardSection" class="form-section">
              <h3>Reward Configuration</h3>
              <div class="form-group">
                <label>Reward Type</label>
                <select v-model="formData.reward.type" required>
                  <option value="BONUS">Bonus</option>
                  <option value="RAKEBACK">Rakeback</option>
                  <option value="FREE_SPINS">Free Spins</option>
                  <option value="CASH">Cash</option>
                </select>
              </div>
              <div class="form-row">
                <div class="form-group">
                  <label>Amount</label>
                  <input v-model.number="formData.reward.amount" type="number" min="0" required>
                </div>
                <div class="form-group">
                  <label>Currency</label>
                  <input v-model="formData.reward.currency" type="text" required>
                </div>
              </div>
            </div>

            <!-- Competition Settings -->
            <div v-if="formData.type === 'LEADERBOARD'" class="form-section">
              <h3>Competition Settings</h3>
              <div class="form-group">
                <label>Minimum Wager to Qualify</label>
                <input v-model.number="formData.competition.minWager" type="number" min="0" required>
              </div>
              <div class="form-group">
                <label>Prize Structure</label>
                <div v-for="(prize, index) in formData.competition.prizes" :key="index" class="prize-row">
                  <input v-model="prize.position" type="number" placeholder="Position">
                  <input v-model="prize.amount" type="number" placeholder="Amount">
                  <button type="button" @click="removePrize(index)">Remove</button>
                </div>
                <button type="button" @click="addPrize">Add Prize</button>
              </div>
            </div>

            <!-- Event Settings -->
            <div v-if="formData.type === 'EVENT'" class="form-section">
              <h3>Event Details</h3>
              <div class="form-group">
                <label>Location/Platform</label>
                <input v-model="formData.event.location" type="text" required>
              </div>
              <div class="form-group">
                <label>Maximum Participants</label>
                <input v-model.number="formData.event.maxParticipants" type="number" min="0">
              </div>
              <div class="form-group">
                <label>Schedule</label>
                <div v-for="(item, index) in formData.event.schedule" :key="index" class="schedule-row">
                  <input type="datetime-local" v-model="item.time">
                  <input type="text" v-model="item.description" placeholder="Description">
                  <button type="button" @click="removeScheduleItem(index)">Remove</button>
                </div>
                <button type="button" @click="addScheduleItem">Add Schedule Item</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Step 4: Requirements (if applicable) -->
        <div v-if="currentStep >= 4 && showRewardSection" class="form-step" :class="{ 'step-complete': currentStep > 4 }">
          <div class="step-header" @click="toggleStep(4)">
            <h3>4. Requirements</h3>
            <span class="step-toggle">{{ currentStep > 4 ? '▼' : '▶' }}</span>
          </div>
          <div class="step-content" :class="{ 'step-collapsed': currentStep > 4 }">
            <div class="form-section">
              <h3>Requirements</h3>
              <div class="form-row">
                <div class="form-group">
                  <label>Minimum Deposit</label>
                  <input v-model.number="formData.requirements.minDeposit" type="number" min="0" required>
                </div>
                <div class="form-group">
                  <label>Minimum Level</label>
                  <input v-model.number="formData.requirements.minLevel" type="number" min="0">
                </div>
                <div class="form-group">
                  <label>Wagering Multiplier</label>
                  <input v-model.number="formData.requirements.wagering" type="number" min="0" required>
                </div>
              </div>
            </div>

            <!-- Game Restrictions Section -->
            <div class="form-section" v-if="['DEPOSIT_BONUS', 'BLACK_FRIDAY'].includes(formData.type)">
              <h3>Game Restrictions</h3>
              <game-restrictions-form v-model="formData.requirements.gameRestrictions" />
            </div>

            <!-- Terms Section -->
            <div class="form-section">
              <h3>Terms & Conditions</h3>
              <div class="form-group">
                <label>Terms (one per line)</label>
                <textarea 
                  v-model="termsText" 
                  @input="formData.terms = termsText.split('\n').filter(t => t.trim())"
                  placeholder="Enter each term on a new line"
                  rows="5"
                  required
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <!-- Step 5: Final Settings -->
        <div v-if="currentStep >= 5" class="form-step">
          <div class="step-header" @click="toggleStep(5)">
            <h3>5. Final Settings</h3>
            <span class="step-toggle">▶</span>
          </div>
          <div class="step-content">
            <!-- Claim Limits if applicable -->
            <div v-if="showClaimLimits" class="form-section">
              <h3>Claim Limits</h3>
              <div class="form-row">
                <div class="form-group">
                  <label>Per User</label>
                  <input v-model.number="formData.claimLimit.perUser" type="number" min="1" required>
                </div>
                <div class="form-group">
                  <label>Total</label>
                  <input v-model.number="formData.claimLimit.total" type="number" min="1" required>
                </div>
              </div>
            </div>

            <!-- Display Settings -->
            <div class="form-section">
              <h3>Display Settings</h3>
              <div class="form-row">
                <div class="form-group">
                  <label>Order</label>
                  <input v-model.number="formData.order" type="number" min="0">
                </div>
                <div class="form-group checkbox">
                  <label>
                    <input v-model="formData.active" type="checkbox">
                    Active
                  </label>
                </div>
              </div>
            </div>

            <!-- Terms -->
            <div class="form-section">
              <h3>Terms & Conditions</h3>
              <div class="form-group">
                <label>Terms (one per line)</label>
                <textarea 
                  v-model="termsText" 
                  @input="formData.terms = termsText.split('\n').filter(t => t.trim())"
                  placeholder="Enter each term on a new line"
                  rows="5"
                  required
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <!-- Navigation Buttons -->
        <div class="form-actions">
          <button type="button" class="btn-next" @click="nextStep" v-if="currentStep < totalSteps">
            Continue to {{ getNextStepTitle() }}
          </button>
          <button 
            v-else
            type="submit" 
            class="btn-save" 
            :disabled="isSubmitting"
          >
            {{ isSubmitting ? 'Saving...' : (editingPromotion ? 'Update' : 'Create') }}
          </button>
        </div>
      </form>
    </div>

    <!-- Promotions List -->
    <div class="content-section" v-else>
      <div class="section-header">
        <h2>Active Promotions</h2>
        <button class="btn-create" @click="createPromotion">Create New</button>
      </div>

      <div class="list-filters">
        <div class="search">
          <input v-model="search" type="text" placeholder="Search promotions...">
        </div>
        <div class="filter-group">
          <select v-model="filterType">
            <option value="">All Types</option>
            <option value="BLACK_FRIDAY">Black Friday</option>
            <option value="DEPOSIT_BONUS">Deposit Bonus</option>
            <option value="COMPETITION">Competition</option>
            <option value="EVENT">Event</option>
            <option value="SLOTS">Slots</option>
            <option value="SPORTS">Sports</option>
            <option value="FUTURES">Futures</option>
            <option value="ORIGINALS">Originals</option>
            <option value="NEWS">News</option>
          </select>
          <select v-model="filterStatus">
            <option value="">All Status</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        </div>
      </div>

      <div class="promotions-grid">
        <div v-for="promo in filteredPromotions" :key="promo._id" class="promotion-card">
          <div class="promotion-image">
            <img :src="promo.image" :alt="promo.title">
            <div class="promotion-type">{{ promo.type.replace('_', ' ') }}</div>
          </div>
          <div class="promotion-content">
            <h3>{{ promo.title }}</h3>
            <p>{{ promo.description }}</p>
            <div class="promotion-meta">
              <span class="status-badge" :class="{ active: promo.active }">
                {{ promo.active ? 'Active' : 'Inactive' }}
              </span>
              <span class="expiry">Valid until: {{ formatDate(promo.validUntil) }}</span>
            </div>
            <div class="promotion-actions">
              <button class="btn-edit" @click="editPromotion(promo)">Edit</button>
              <button class="btn-delete" @click="deletePromotion(promo)">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal-game-selector
      v-if="showGameSelector"
      :show="showGameSelector"
      :selection-mode="gameSelectorMode"
      :initial-selected-games="getInitialSelectedGames()"
      @close="closeGameSelector"
      @games-selected="handleGamesSelected"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModalGameSelector from '@/components/modals/ModalGameSelector.vue'
import GameRestrictionsForm from '@/components/admin/promotions/GameRestrictionsForm.vue'
import BonusTemplates from '@/components/admin/promotions/BonusTemplates.vue'
import { PRODUCT_TYPES } from '@/constants/bonus'

export default {
  name: 'AdminPromotions',
  components: {
    ModalGameSelector,
    GameRestrictionsForm,
    BonusTemplates
  },
  data() {
    return {
      showForm: false,
      editingPromotion: null,
      search: '',
      filterType: '',
      filterStatus: '',
      isSubmitting: false,
      termsText: '',
      currentStep: 1,
      newProvider: '',
      newSpecificGame: '',
      newExcludedGame: '',
      PRODUCT_TYPES,
      formData: this.getDefaultFormData(),
      promotionTypes: {
        DEPOSIT_BONUS: {
          label: 'Deposit Bonus',
          description: 'Bonus rewards for deposits',
          steps: ['basic', 'media', 'reward', 'requirements', 'limits'],
          requiresClaim: true,
          hasReward: true,
          defaultButton: 'Claim Now',
          defaultLink: null
        },
        LEADERBOARD: {
          label: 'Leaderboard Competition',
          description: 'Compete with other players based on wager amount',
          steps: ['basic', 'media', 'competition', 'prizes'],
          requiresClaim: false,
          hasReward: false,
          defaultButton: 'View Leaderboard',
          defaultLink: '/leaderboard'
        },
        BLACK_FRIDAY: {
          label: 'Black Friday',
          description: 'Special Black Friday offers',
          steps: ['basic', 'media', 'reward', 'requirements', 'limits'],
          requiresClaim: true,
          hasReward: true,
          defaultButton: 'Claim Offer',
          defaultLink: null
        },
        EVENT: {
          label: 'Event',
          description: 'Special events and tournaments',
          steps: ['basic', 'media', 'event', 'prizes'],
          requiresClaim: false,
          hasReward: false,
          defaultButton: 'Join Event',
          defaultLink: '/events'
        },
        NEWS: {
          label: 'News',
          description: 'News and announcements',
          steps: ['basic', 'media'],
          requiresClaim: false,
          hasReward: false,
          defaultButton: 'Read More',
          defaultLink: '/news'
        }
      },
      showGameSelector: false,
      gameSelectorMode: 'specific'
    }
  },
  computed: {
    ...mapGetters([
      'socketSendLoading',
      'adminPromotionsList'
    ]),
    currentTypeConfig() {
      return this.promotionTypes[this.formData.type] || this.promotionTypes.DEPOSIT_BONUS;
    },
    totalSteps() {
      return this.currentTypeConfig.steps.length;
    },
    stepTitle() {
      const titles = {
        basic: 'Basic Information',
        media: 'Media & Display',
        reward: 'Reward Configuration',
        requirements: 'Requirements',
        limits: 'Claim Limits',
        competition: 'Competition Settings',
        prizes: 'Prize Structure',
        event: 'Event Details'
      };
      return titles[this.currentTypeConfig.steps[this.currentStep - 1]] || '';
    },
    showRewardSection() {
      return this.currentTypeConfig.hasReward;
    },
    showClaimLimits() {
      return this.currentTypeConfig.requiresClaim;
    },
    filteredPromotions() {
      if (!this.adminPromotionsList.data) return [];
      return this.adminPromotionsList.data.filter(promo => {
        const matchesSearch = promo.title.toLowerCase().includes(this.search.toLowerCase());
        const matchesType = !this.filterType || promo.type === this.filterType;
        const matchesStatus = !this.filterStatus || String(promo.active) === this.filterStatus;
        return matchesSearch && matchesType && matchesStatus;
      });
    }
  },
  watch: {
    'formData.type'(newType) {
      // Reset to first step when type changes
      this.currentStep = 1;
      
      // Update button text and link based on type
      const config = this.promotionTypes[newType];
      if (config) {
        this.formData.buttonText = config.defaultButton;
        this.formData.link = config.defaultLink;
      }
    }
  },
  methods: {
    ...mapActions([
      'notificationShow',
      'adminGetPromotionsListSocket',
      'adminCreatePromotionSocket',
      'adminUpdatePromotionSocket',
      'adminDeletePromotionSocket'
    ]),
    getDefaultFormData() {
      // Get all product types except 'slots' as restricted by default
      const restrictedTypes = Object.values(PRODUCT_TYPES).filter(type => type !== 'slots');
      
      return {
        title: '',
        type: 'DEPOSIT_BONUS',
        description: '',
        fullDescription: 'Detailed description of the promotion will go here.',
        image: '',
        buttonText: 'Claim Now',
        link: null,
        validUntil: '',
        terms: [],
        active: false,
        order: 0,
        reward: {
          type: 'BONUS',
          amount: 100,
          currency: 'USD'
        },
        requirements: {
          minDeposit: 20,
          minLevel: 0,
          maxLevel: null,
          wagering: 25,
          gameRestrictions: {
            allowedProductTypes: ['slots'], // Initialize with only slots allowed
            providers: [],
            specificGames: [],
            excludedGames: []
          },
          gameStats: {
            slots: { minWager: 0, minWon: 0 },
            sports: { minWager: 0, minWon: 0 },
            crash: { minWager: 0, minWon: 0 },
            roll: { minWager: 0, minWon: 0 },
            blackjack: { minWager: 0, minWon: 0 },
            duels: { minWager: 0, minWon: 0 },
            mines: { minWager: 0, minWon: 0 },
            towers: { minWager: 0, minWon: 0 },
            unbox: { minWager: 0, minWon: 0 },
            battles: { minWager: 0, minWon: 0 },
            upgrader: { minWager: 0, minWon: 0 }
          },
          totalStats: {
            minWager: 0,
            minWon: 0,
            minNGR: 0
          }
        },
        claimLimit: {
          perUser: 1,
          total: 1000
        },
        competition: {
          minWager: 0,
          prizes: []
        },
        event: {
          location: '',
          maxParticipants: 0,
          schedule: []
        }
      }
    },
    createPromotion() {
      this.editingPromotion = null
      this.formData = this.getDefaultFormData()
      this.showForm = true
    },
    editPromotion(promotion) {
      this.editingPromotion = promotion;
      this.formData = { 
        ...promotion,
        validUntil: new Date(promotion.validUntil).toISOString().slice(0, 16)
      };
      this.termsText = promotion.terms.join('\n');
      this.showForm = true;
    },
    async fetchPromotions() {
      try {
        await this.adminGetPromotionsListSocket();
      } catch (error) {
        this.notificationShow({
          type: 'error',
          message: error.message || 'Failed to load promotions'
        });
      }
    },
    async savePromotion() {
      try {
        // Validate dates
        if (!this.formData.validUntil) {
          this.notificationShow({
            type: 'error',
            message: 'Valid until date is required'
          });
          return;
        }

        this.isSubmitting = true;
        if (this.editingPromotion) {
          await this.adminUpdatePromotionSocket({
            ...this.formData,
            _id: this.editingPromotion._id
          });
        } else {
          await this.adminCreatePromotionSocket(this.formData);
        }
        this.showForm = false;
        this.notificationShow({
          type: 'success',
          message: `Promotion ${this.editingPromotion ? 'updated' : 'created'} successfully`
        });
        // Refresh the list
        await this.fetchPromotions();
      } catch (error) {
        this.notificationShow({
          type: 'error',
          message: error.message || 'Failed to save promotion'
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    async deletePromotion(promotion) {
      if (!confirm('Are you sure you want to delete this promotion? This action cannot be undone.')) {
        return;
      }
      
      try {
        await this.adminDeletePromotionSocket(promotion._id);
        this.notificationShow({
          type: 'success',
          message: 'Promotion deleted successfully'
        });
        // Refresh the list
        await this.fetchPromotions();
      } catch (error) {
        this.notificationShow({
          type: 'error',
          message: error.message || 'Failed to delete promotion'
        });
      }
    },
    cancelForm() {
      this.showForm = false;
      this.editingPromotion = null;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
    },
    fillExampleTemplate() {
      const now = new Date();
      const validUntil = new Date();
      validUntil.setDate(now.getDate() + 30); // Set to 30 days from now

      this.formData = {
        title: 'Welcome Slots Bonus',
        type: 'DEPOSIT_BONUS',
        description: 'Get 100% bonus up to $2,000 on your first deposit! Play your favorite slots with double the funds.',
        fullDescription: `Welcome to our casino! Make your first deposit and receive a 100% match bonus up to $2,000.

This exclusive welcome offer doubles your playing power on our exciting collection of slot games. The minimum deposit is just $1, making it accessible for everyone to start their gaming journey with us.

How it works:
• Deposit $1 or more
• Receive 100% match bonus up to $2,000
• Example: Deposit $500, get $500 bonus = $1,000 to play with!`,
        image: 'https://thumbs.dreamstime.com/b/welcome-bonus-casino-banner-first-deposit-109993979.jpg',
        buttonText: 'Claim Welcome Bonus',
        validUntil: validUntil.toISOString().slice(0, 16),
        terms: [
          'Minimum deposit: $1',
          'Maximum bonus: $2,000',
          'Wagering requirement: 20x bonus amount',
          'Valid for slots games only',
          'Bonus expires in 30 days after claiming',
          'Only one welcome bonus per player',
          'General terms and conditions apply'
        ],
        active: true,
        order: 1,
        reward: {
          type: 'BONUS',
          amount: 100,
          currency: 'USD'
        },
        requirements: {
          minDeposit: 1,
          minLevel: 0,
          maxLevel: null,
          wagering: 20,
          gameRestrictions: {
            type: 'slot',
            providers: [
              'pragmatic',
              'spinomenal',
              'betsoft',
              'evolution',
              'hacksaw',
              'playngo'
            ],
            specificGames: [],
            excludedGames: []
          },
          gameStats: {
            slots: { minWager: 0, minWon: 0 },
            sports: { minWager: 0, minWon: 0 },
            crash: { minWager: 0, minWon: 0 },
            roll: { minWager: 0, minWon: 0 },
            blackjack: { minWager: 0, minWon: 0 },
            duels: { minWager: 0, minWon: 0 },
            mines: { minWager: 0, minWon: 0 },
            towers: { minWager: 0, minWon: 0 },
            unbox: { minWager: 0, minWon: 0 },
            battles: { minWager: 0, minWon: 0 },
            upgrader: { minWager: 0, minWon: 0 }
          },
          totalStats: {
            minWager: 0,
            minWon: 0,
            minNGR: 0
          }
        },
        claimLimit: {
          perUser: 1,
          total: 10000
        }
      };

      // Update the terms text area
      this.termsText = this.formData.terms.join('\n');

      this.notificationShow({
        type: 'success',
        message: 'Example slots promotion template loaded successfully'
      });
    },
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    addPrize() {
      this.formData.competition.prizes.push({
        position: '',
        amount: ''
      });
    },
    removePrize(index) {
      this.formData.competition.prizes.splice(index, 1);
    },
    addScheduleItem() {
      this.formData.event.schedule.push({
        time: '',
        description: ''
      });
    },
    removeScheduleItem(index) {
      this.formData.event.schedule.splice(index, 1);
    },
    toggleStep(step) {
      if (step <= this.currentStep) {
        this.currentStep = step;
      }
    },
    
    getNextStepTitle() {
      const nextStep = this.currentStep + 1;
      const stepTitles = {
        2: 'Media & Display',
        3: this.stepTitle,
        4: 'Requirements',
        5: 'Final Settings'
      };
      return stepTitles[nextStep] || 'Next';
    },
    addProvider() {
      if (this.newProvider.trim()) {
        this.formData.requirements.gameRestrictions.providers.push(this.newProvider.trim());
        this.newProvider = '';
      }
    },
    removeProvider(provider) {
      const index = this.formData.requirements.gameRestrictions.providers.indexOf(provider);
      if (index > -1) {
        this.formData.requirements.gameRestrictions.providers.splice(index, 1);
      }
    },
    addSpecificGame() {
      if (this.newSpecificGame.trim()) {
        this.formData.requirements.gameRestrictions.specificGames.push(this.newSpecificGame.trim());
        this.newSpecificGame = '';
      }
    },
    removeSpecificGame(game) {
      const index = this.formData.requirements.gameRestrictions.specificGames.findIndex(g => g.gameId === game.gameId)
      if (index > -1) {
        this.formData.requirements.gameRestrictions.specificGames.splice(index, 1)
      }
    },
    addExcludedGame() {
      if (this.newExcludedGame.trim()) {
        this.formData.requirements.gameRestrictions.excludedGames.push(this.newExcludedGame.trim());
        this.newExcludedGame = '';
      }
    },
    removeExcludedGame(game) {
      const index = this.formData.requirements.gameRestrictions.excludedGames.findIndex(g => g.gameId === game.gameId)
      if (index > -1) {
        this.formData.requirements.gameRestrictions.excludedGames.splice(index, 1)
      }
    },
    openGameSelector(mode) {
      this.gameSelectorMode = mode
      this.showGameSelector = true
    },
    closeGameSelector() {
      this.showGameSelector = false
    },
    getInitialSelectedGames() {
      return this.gameSelectorMode === 'specific' 
        ? this.formData.requirements.gameRestrictions.specificGames
        : this.formData.requirements.gameRestrictions.excludedGames
    },
    handleGamesSelected(games) {
      if (this.gameSelectorMode === 'specific') {
        this.formData.requirements.gameRestrictions.specificGames = games
      } else {
        this.formData.requirements.gameRestrictions.excludedGames = games
      }
    },
    toggleProductType(type) {
      if (!this.formData.requirements.gameRestrictions.allowedProductTypes.includes(type)) {
        this.formData.requirements.gameRestrictions.allowedProductTypes.push(type);
      } else {
        const index = this.formData.requirements.gameRestrictions.allowedProductTypes.indexOf(type);
        if (index > -1) {
          this.formData.requirements.gameRestrictions.allowedProductTypes.splice(index, 1);
        }
      }
    },
    formatProductType(type) {
      const formattedTypes = {
        DEPOSIT_BONUS: 'Deposit Bonus',
        BLACK_FRIDAY: 'Black Friday',
        EVENT: 'Event',
        NEWS: 'News'
      };
      return formattedTypes[type] || type;
    }
  },
  created() {
    this.fetchPromotions();
  }
}
</script>

<style scoped>
.admin-promotions-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-header {
  text-align: center;
  margin-bottom: 32px;
}

.admin-header h1 {
  font-size: 36px;
  font-weight: 800;
  margin: 0;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.admin-header p {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 8px;
}

.content-section {
  background: #1A1F37;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 24px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
}

.section-header {
  background: #1E2443;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 800;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.promotion-form {
  padding: 24px;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.form-section {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
}

.form-section h3 {
  margin: 0 0 16px 0;
  font-size: 18px;
  color: #B8C7FF;
}

.form-group {
  margin-bottom: 16px;
}

.form-row {
  display: flex;
  gap: 16px;
}

label {
  display: block;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

input[type="text"],
input[type="url"],
input[type="number"],
input[type="datetime-local"],
select,
textarea {
  width: 100%;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #2C5EFF;
  outline: none;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox input {
  margin-right: 8px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

button {
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-create,
.btn-save {
  background: linear-gradient(90deg, #2C5EFF 0%, #5F8AFF 100%);
  color: #fff;
  border: none;
}

.btn-create:hover,
.btn-save:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(44, 94, 255, 0.3);
}

.btn-cancel,
.btn-delete {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-cancel:hover,
.btn-delete:hover {
  background: rgba(255, 255, 255, 0.2);
}

.btn-template {
  background: rgba(44, 94, 255, 0.1);
  color: #2C5EFF;
  border: 1px solid #2C5EFF;
  padding: 8px 16px;
  border-radius: 6px;
}

.btn-template:hover {
  background: rgba(44, 94, 255, 0.2);
}

.list-filters {
  padding: 20px;
  display: flex;
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.search {
  flex: 1;
}

.filter-group {
  display: flex;
  gap: 12px;
}

.promotions-grid {
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.promotion-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.promotion-card:hover {
  transform: translateY(-4px);
}

.promotion-image {
  position: relative;
  aspect-ratio: 16/9;
}

.promotion-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.promotion-type {
  position: absolute;
  top: 12px;
  right: 12px;
  background: rgba(0, 0, 0, 0.6);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
}

.promotion-content {
  padding: 16px;
}

.promotion-content h3 {
  margin: 0 0 8px 0;
  font-size: 18px;
  color: #fff;
}

.promotion-content p {
  margin: 0 0 16px 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 1.5;
}

.promotion-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.status-badge.active {
  background: rgba(76, 175, 80, 0.2);
  color: #4CAF50;
}

.status-badge:not(.active) {
  background: rgba(158, 158, 158, 0.2);
  color: #9E9E9E;
}

.expiry {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.promotion-actions {
  display: flex;
  gap: 8px;
}

.promotion-actions button {
  flex: 1;
  font-size: 14px;
  padding: 8px 16px;
}

@media (max-width: 768px) {
  .admin-promotions-container {
    padding: 16px;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  .list-filters {
    flex-direction: column;
  }

  .filter-group {
    width: 100%;
  }

  .filter-group select {
    flex: 1;
  }

  .promotions-grid {
    grid-template-columns: 1fr;
    padding: 16px;
    gap: 16px;
  }
}

.header-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 16px;
}

.btn-back {
  position: absolute;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 8px 16px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.btn-back:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateX(-2px);
}

.back-icon {
  font-size: 18px;
  line-height: 1;
}

.step-indicator {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.form-step {
  margin-bottom: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.step-header {
  background: rgba(255, 255, 255, 0.05);
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.step-header:hover {
  background: rgba(255, 255, 255, 0.1);
}

.step-header h3 {
  margin: 0;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.step-toggle {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  transition: transform 0.3s ease;
}

.step-content {
  padding: 20px;
  background: rgba(255, 255, 255, 0.02);
  transition: all 0.3s ease;
}

.step-collapsed {
  padding: 0;
  height: 0;
  overflow: hidden;
}

.step-complete .step-header {
  background: rgba(44, 94, 255, 0.1);
}

.step-complete .step-header h3 {
  color: #B8C7FF;
}

.form-actions {
  position: sticky;
  bottom: 0;
  background: #1A1F37;
  padding: 16px 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 24px -24px -24px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  z-index: 10;
}

.type-selection {
  margin-bottom: 24px;
}

.type-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.type-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.type-card:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.type-card.active {
  border-color: #2C5EFF;
  background: rgba(44, 94, 255, 0.1);
}

.type-card h4 {
  margin: 0 0 8px 0;
  color: #fff;
}

.type-card p {
  margin: 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.prize-row,
.schedule-row {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.prize-row input,
.schedule-row input {
  flex: 1;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.tags-input {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 8px;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: rgba(44, 94, 255, 0.1);
  border: 1px solid rgba(44, 94, 255, 0.2);
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
}

.tag.excluded {
  background: rgba(255, 59, 48, 0.1);
  border-color: rgba(255, 59, 48, 0.2);
  color: #ff3b30;
}

.tag-remove {
  background: none;
  border: none;
  color: inherit;
  font-size: 16px;
  padding: 0;
  margin-left: 4px;
  cursor: pointer;
  opacity: 0.7;
}

.tag-remove:hover {
  opacity: 1;
}

.tags-input input {
  width: 100%;
  background: transparent;
  border: none;
  color: #fff;
  padding: 8px;
  font-size: 14px;
}

.tags-input input:focus {
  outline: none;
}

.tags-input input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.selected-games {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
  min-height: 44px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.game-tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px 4px 4px;
  background: rgba(44, 94, 255, 0.1);
  border: 1px solid rgba(44, 94, 255, 0.2);
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
}

.game-thumbnail {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
}

.game-name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-select-games {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(44, 94, 255, 0.1);
  border: 1px solid rgba(44, 94, 255, 0.2);
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-select-games:hover {
  background: rgba(44, 94, 255, 0.2);
  transform: translateY(-1px);
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.btn-template {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: linear-gradient(90deg, #2C5EFF 0%, #5F8AFF 100%);
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-template:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(44, 94, 255, 0.3);
}

.btn-template i {
  font-size: 16px;
}

.form-section h3 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 8px;
}

textarea {
  font-family: inherit;
  line-height: 1.5;
  resize: vertical;
  min-height: 100px;
}

input, select, textarea {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  width: 100%;
  transition: all 0.2s ease;
}

input:focus, select:focus, textarea:focus {
  border-color: #2C5EFF;
  box-shadow: 0 0 0 2px rgba(44, 94, 255, 0.2);
  outline: none;
}

input::placeholder, textarea::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.step-header h3 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.step-content {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.product-types-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  margin-bottom: 8px;
}

.product-type-toggle {
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease;
  user-select: none;
}

.product-type-toggle:not(.is-slots):hover {
  background: rgba(255, 255, 255, 0.1);
}

.product-type-toggle.active {
  background: rgba(255, 59, 48, 0.1);
  border-color: rgba(255, 59, 48, 0.2);
  color: #ff3b30;
}

.product-type-toggle.is-slots {
  opacity: 0.5;
  cursor: not-allowed;
  background: rgba(76, 175, 80, 0.1);
  border-color: rgba(76, 175, 80, 0.2);
  color: #4CAF50;
}

.help-text {
  display: block;
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}
</style> 