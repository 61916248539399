var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"towers-row",class:{
    'row-active':
      _vm.towersGame !== null &&
      _vm.towersGame.state !== 'completed' &&
      _vm.towersGame.revealed.length === _vm.row,
    'row-revealed':
      _vm.towersGame !== null && _vm.towersGame.revealed[_vm.row] !== undefined,
  }},_vm._l((_vm.towersGetTilesCount),function(tile){return _c('div',{key:tile,staticClass:"row-tile",class:{
      'tile-lose':
        _vm.towersGame !== null &&
        ((_vm.towersGame.revealed[_vm.row] !== undefined &&
          _vm.towersGame.revealed[_vm.row].tile === tile - 1 &&
          _vm.towersGame.revealed[_vm.row].row[tile - 1] === 'lose') ||
          (_vm.towersGame.state === 'completed' &&
            _vm.towersGame.revealed.length <= _vm.row &&
            _vm.towersGame.deck[_vm.row][tile - 1] === 'lose')),
      'tile-safe':
        _vm.towersGame !== null &&
        ((_vm.towersGame.revealed[_vm.row] !== undefined &&
          _vm.towersGame.revealed[_vm.row].tile === tile - 1 &&
          _vm.towersGame.revealed[_vm.row].row[tile - 1] === 'coin') ||
          (_vm.towersGame.state === 'completed' &&
            _vm.towersGame.revealed.length <= _vm.row &&
            _vm.towersGame.deck[_vm.row][tile - 1] === 'coin')),
    }},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
          _vm.towersGame !== null &&
          _vm.towersGame.revealed[_vm.row] !== undefined &&
          _vm.towersGame.revealed[_vm.row].tile === tile - 1 &&
          _vm.towersGame.revealed[_vm.row].row[tile - 1] === 'coin'
        )?_c('div',{staticClass:"tile-coin"},[_c('span',[_vm._v(_vm._s(_vm.towersFormatValue(_vm.towersGetRowCashout).split(".")[0]))]),_vm._v(" ."+_vm._s(_vm.towersFormatValue(_vm.towersGetRowCashout).split(".")[1])+" ")]):(
          _vm.towersGame !== null &&
          ((_vm.towersGame.revealed[_vm.row] !== undefined &&
            _vm.towersGame.revealed[_vm.row].tile === tile - 1 &&
            _vm.towersGame.revealed[_vm.row].row[tile - 1] === 'lose') ||
            (_vm.towersGame.state === 'completed' &&
              _vm.towersGame.revealed.length <= _vm.row &&
              _vm.towersGame.deck[_vm.row][tile - 1] === 'lose'))
        )?_c('div',{staticClass:"tile-lose"}):_c('button',{staticClass:"button-reveal",attrs:{"disabled":_vm.socketSendLoading !== null ||
          _vm.towersGame === null ||
          _vm.towersGame.state === 'completed' ||
          _vm.towersGame.revealed.length !== _vm.row},on:{"click":function($event){return _vm.towersRevealButton(tile - 1)}}},[_c('div',{staticClass:"button-inner"},[_c('div',{staticClass:"inner-value"},[_c('span',[_vm._v(_vm._s(_vm.towersFormatValue(_vm.towersGetRowCashout).split(".")[0]))]),_vm._v(" ."+_vm._s(_vm.towersFormatValue(_vm.towersGetRowCashout).split(".")[1])+" ")])])])])],1)}),0)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }