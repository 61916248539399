<template>
  <div class="currency-selector">
    <!-- Search box -->
    <div class="search-box">
      <i class="fas fa-search"></i>
      <input 
        type="text" 
        v-model="searchQuery" 
        placeholder="Search by name or symbol..."
        class="search-input"
      />
    </div>

    <!-- Featured Currencies Grid -->
    <div class="featured-currencies" v-if="!searchQuery">
      <h4 class="section-title">Featured Currencies</h4>
      <div class="featured-grid">
        <div 
          v-for="currency in featuredCurrencies" 
          :key="currency.code"
          class="currency-card featured"
          :class="{ active: currentSelection === currency.code }"
          @click="selectCurrency(currency)"
        >
          <div class="currency-content">
            <div class="network-badge">
              <img :src="getNetworkIcon(getNetworkCategory(currency.network))" :alt="currency.network" />
              <span>{{ formatNetworkName(getNetworkCategory(currency.network)) }}</span>
            </div>
            <div class="currency-main">
              <div class="currency-icon-wrapper">
                <img 
                  :src="currency.logo_url"
                  :alt="currency.name"
                  @error="handleImageError($event, currency)"
                />
              </div>
              <div class="currency-info">
                <span class="currency-name">{{ currency.name }}</span>
                <span class="currency-code">{{ currency.code.toUpperCase() }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- All Currencies List -->
    <div class="all-currencies">
      <h4 class="section-title" v-if="!searchQuery">All Currencies</h4>
      <div class="currencies-list">
        <div 
          v-for="currency in filteredCurrencies" 
          :key="currency.code"
          class="currency-card list-item"
          :class="{ active: currentSelection === currency.code }"
          @click="selectCurrency(currency)"
        >
          <div class="currency-content">
            <div class="currency-main">
              <div class="currency-icon-wrapper">
                <img 
                  :src="currency.logo_url"
                  :alt="currency.name"
                  @error="handleImageError($event, currency)"
                />
              </div>
              <div class="currency-info">
                <span class="currency-name">{{ currency.name }}</span>
                <span class="currency-code">{{ currency.code.toUpperCase() }}</span>
              </div>
            </div>
            <div class="network-badge">
              <img :src="getNetworkIcon(getNetworkCategory(currency.network))" :alt="currency.network" />
              <span>{{ formatNetworkName(getNetworkCategory(currency.network)) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { networkIcons } from '@/assets/icons/networks'
import { depositConfig } from '@/config/deposit'

function fuzzySearch(text, search) {
  text = text.toLowerCase();
  search = search.toLowerCase();
  
  let searchIndex = 0;
  for (let i = 0; i < text.length && searchIndex < search.length; i++) {
    if (text[i] === search[searchIndex]) {
      searchIndex++;
    }
  }
  return searchIndex === search.length;
}

export default {
  name: 'CurrencySelector',
  
  props: {
    currencies: {
      type: Array,
      required: true
    },
    selectedNetwork: {
      type: String,
      default: 'all'
    },
    selectedCurrency: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      searchQuery: '',
      currentSelection: null,
      networkCategories: depositConfig.networkCategories
    }
  },

  computed: {
    featuredCurrencies() {
      if (!this.currencies?.length) return [];
      
      const featured = depositConfig.featuredCoins;
      return this.currencies
        .filter(currency => featured.includes(currency.code.toUpperCase()))
        .slice(0, 6); // Limit to 6 featured currencies
    },

    filteredCurrencies() {
      let filtered = this.currencies.filter(
        currency => !this.featuredCurrencies.find(f => f.code === currency.code)
      );

      if (this.searchQuery) {
        filtered = [...this.currencies]; // Search in all currencies when searching
        const searchTerms = this.searchQuery.toLowerCase().split(' ');
        filtered = filtered.filter(currency => {
          const name = currency.name.toLowerCase();
          const code = currency.code.toLowerCase();
          const network = (currency.network || '').toLowerCase();

          return searchTerms.every(term => 
            fuzzySearch(name, term) || 
            fuzzySearch(code, term) || 
            fuzzySearch(network, term)
          );
        });
      }

      if (this.selectedNetwork !== 'all') {
        filtered = filtered.filter(currency =>
          this.getNetworkCategory(currency.network) === this.selectedNetwork
        );
      }

      return filtered;
    }
  },

  methods: {
    selectNetwork(network) {
      this.$emit('update:selectedNetwork', network);
    },

    selectCurrency(currency) {
      if (!currency?.code) return;
      this.currentSelection = currency.code;
      this.$emit('select-currency', currency);
    },

    getNetworkCategory(network) {
      if (!network) return network || 'OTHER';
      network = network.toLowerCase();
      
      for (const [category, data] of Object.entries(depositConfig.networkCategories)) {
        if (data.networks.includes(network)) {
          return category;
        }
      }
      return network;
    },

    formatNetworkName(network) {
      if (network === 'all') return 'All Networks';
      if (depositConfig.categories[network]) {
        return depositConfig.categories[network].name;
      }
      if (depositConfig.networkCategories[network]?.name) {
        return depositConfig.networkCategories[network].name;
      }
      return network.charAt(0).toUpperCase() + network.slice(1).toLowerCase();
    },

    getNetworkIcon(network) {
      return networkIcons[network] || networkIcons['OTHER'];
    },

    handleImageError(event, currency) {
      const target = event.target;
      target.src = `https://nowpayments.io/images/coins/${currency.code.toLowerCase()}.svg`;
    }
  }
}
</script>

<style scoped>
.currency-selector {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  padding: 16px;
}

.search-box {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.search-box i {
  color: rgba(255, 255, 255, 0.7);
  margin-right: 12px;
}

.search-input {
  width: 100%;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.featured-currencies {
  margin-bottom: 24px;
}

.section-title {
  color: rgba(255, 255, 255, 0.7);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  padding-left: 8px;
  letter-spacing: 0.2px;
}

.featured-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 16px;
}

.currency-card {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  transition: all 0.2s ease;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
}

.currency-card:hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(31, 42, 68, 0.4) 100%);
  transform: translateY(-1px);
}

.currency-card.active {
  background: linear-gradient(180deg, rgba(44, 94, 255, 0.2) 0%, rgba(31, 42, 68, 0.4) 100%);
  border-color: rgba(44, 94, 255, 0.3);
  box-shadow: 0px 4px 12px rgba(44, 94, 255, 0.25);
}

.currency-card.featured {
  padding: 16px;
}

.currency-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.list-item .currency-content {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 12px;
}

.currency-main {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  min-width: 0;
}

.currency-icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.list-item .currency-icon-wrapper {
  width: 32px;
  height: 32px;
}

.currency-icon-wrapper img {
  width: 28px;
  height: 28px;
  object-fit: contain;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
}

.list-item .currency-icon-wrapper img {
  width: 20px;
  height: 20px;
}

.currency-info {
  flex: 1;
  min-width: 0;
}

.currency-name {
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.2px;
}

.currency-code {
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  letter-spacing: 0.2px;
}

.network-badge {
  padding: 4px 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(31, 42, 68, 0.3) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 6px;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.9);
  display: inline-flex;
  align-items: center;
  gap: 6px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.network-badge img {
  width: 12px;
  height: 12px;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.2));
}

.currencies-list {
  flex: 1;
  overflow-y: auto;
  padding-right: 4px;
  min-height: 0;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
}

.currencies-list::-webkit-scrollbar {
  width: 6px;
}

.currencies-list::-webkit-scrollbar-track {
  background: transparent;
}

.currencies-list::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  border-radius: 3px;
}

/* Loading Indicator */
.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 32px;
  color: rgba(255, 255, 255, 0.7);
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(44, 94, 255, 0.1);
  border-left-color: #2C5EFF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .featured-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .currency-selector {
    padding: 12px;
  }

  .search-box {
    padding: 10px;
  }

  .featured-grid {
    gap: 10px;
  }

  .currency-card.featured {
    padding: 12px;
  }

  .currency-icon-wrapper {
    width: 36px;
    height: 36px;
  }

  .currency-icon-wrapper img {
    width: 24px;
    height: 24px;
  }

  .currency-name {
    font-size: 13px;
  }

  .currency-code {
    font-size: 11px;
  }

  .network-badge {
    font-size: 11px;
    padding: 3px 6px;
  }
}

@media (max-width: 480px) {
  .currency-selector {
    padding: 8px;
  }

  .search-box {
    padding: 8px;
  }

  .search-input {
    font-size: 13px;
  }

  .featured-grid {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .currency-card.featured {
    padding: 10px;
  }

  .list-item .currency-content {
    padding: 10px;
  }

  .currency-icon-wrapper {
    width: 32px;
    height: 32px;
  }

  .currency-icon-wrapper img {
    width: 20px;
    height: 20px;
  }

  .network-badge {
    font-size: 10px;
    padding: 2px 4px;
  }

  .network-badge img {
    width: 10px;
    height: 10px;
  }
}
</style> 