<template>
  <div class="sportsbook-page">
    <div v-if="error" class="error-message">
      {{ error }}
    </div>
    <div v-else class="sportsbook-container">
      <iframe
        v-if="sportsbookUrl"
        ref="sportsbookIframe"
        :src="sportsbookUrl"
        @load="onIframeLoaded"
        @error="onIframeError"
        class="sportsbook-iframe"
        id="sportsbook-iframe"
        allow="clipboard-write"
        scrolling="yes"
        frameborder="0"
      ></iframe>
      <div v-else class="loading-message">Loading sportsbook...</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import sportsbookService from "@/services/sportsbookFrontendService";

export default {
  name: "Sportsbook",
  data() {
    return {
      sportsbookUrl: "",
      defaultLanguage: "en",
      error: null,
      iframeLoaded: false,
      jwtToken: null,
      isBalanceUpdateInProgress: false,
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.auth.authUser,
      user: (state) => state.auth.user,
      authToken: (state) => state.auth.authToken,
      balance: (state) => state.sportsbook.balance
    }),
    userLanguage() {
      return this.defaultLanguage;
    },
  },
  methods: {
    ...mapActions(["modalsSetShow", "updateUserBalance"]),
    onIframeLoaded() {
      console.log("⚽ Sportsbook iframe loaded successfully");
      this.iframeLoaded = true;
      this.sendAuthState();
    },
    onIframeError(error) {
      console.error("⚽ Error loading iframe:", error);
      this.error = "Failed to load sportsbook. Please try again later.";
    },
    async initializeSportsbook() {
      try {
        console.log("⚽ Initializing sportsbook for user:", {
          userId: this.authUser?.user?._id,
          isAuthenticated: Boolean(this.authUser?.user),
          operatorId: process.env.VUE_APP_GR8_TECH_OPERATOR_ID,
          brand: process.env.VUE_APP_GR8_TECH_BRAND
        });

        const baseUrl = process.env.VUE_APP_SPORTSBOOK_IFRAME_URL;
        if (!baseUrl) {
          throw new Error("Sportsbook URL is not configured");
        }

        const baseUrlTrimmed = baseUrl.replace(/\/$/, '');
        
        if (this.authUser?.user?._id) {
          try {
            const currency = this.authUser.user.currency || 
                            process.env.VUE_APP_DEFAULT_CURRENCY || 
                            'USDT';

            console.log('⚽ Requesting JWT token with:', {
              userId: this.authUser.user._id,
              currency,
              operatorId: process.env.VUE_APP_GR8_TECH_OPERATOR_ID,
              brand: process.env.VUE_APP_GR8_TECH_BRAND
            });

            this.jwtToken = await sportsbookService.getJwtToken(
              this.authUser.user._id,
              currency
            );

            if (this.jwtToken) {
              // Log JWT payload for debugging
              try {
                const tokenParts = this.jwtToken.split('.');
                const decodedPayload = JSON.parse(atob(tokenParts[1]));
                console.log('⚽ JWT payload for iframe:', {
                  decodedPayload,
                  userId: this.authUser.user._id
                });
              } catch (e) {
                console.error('⚽ Error decoding JWT:', e);
              }

              this.sportsbookUrl = `${baseUrlTrimmed}/${this.userLanguage}/?jwt=${encodeURIComponent(this.jwtToken)}`;
              console.log("⚽ Created iframe URL with JWT");
            }
          } catch (error) {
            console.error('⚽ Failed to get JWT token:', {
              error,
              userId: this.authUser.user._id,
              operatorId: process.env.VUE_APP_GR8_TECH_OPERATOR_ID,
              brand: process.env.VUE_APP_GR8_TECH_BRAND
            });
            this.error = error.message || 'Failed to initialize sportsbook';
            this.sportsbookUrl = `${baseUrlTrimmed}/${this.userLanguage}/`;
          }
        } else {
          console.log("⚽ User not authenticated, using non-authorized URL");
          this.sportsbookUrl = `${baseUrlTrimmed}/${this.userLanguage}/`;
        }

        console.log("⚽ Final iframe URL:", this.sportsbookUrl);

        if (this.iframeLoaded && this.$refs.sportsbookIframe) {
          console.log("⚽ Reloading iframe with new URL");
          this.$refs.sportsbookIframe.src = this.sportsbookUrl;
        }

      } catch (error) {
        console.error("⚽ Failed to initialize sportsbook:", {
          error,
          env: {
            operatorId: process.env.VUE_APP_GR8_TECH_OPERATOR_ID,
            brand: process.env.VUE_APP_GR8_TECH_BRAND,
            iframeUrl: process.env.VUE_APP_SPORTSBOOK_IFRAME_URL
          }
        });
        this.error = error.message || "Failed to initialize sportsbook. Please try again later.";
      }
    },
    sendAuthState() {
      if (!this.$refs.sportsbookIframe?.contentWindow) {
        console.log("⚽ Iframe not ready for postMessage");
        return;
      }

      if (this.authUser?.user && this.jwtToken) {
        // For login, we just need to set the JWT in URL
        // The iframe handles login automatically
        console.log('⚽ User is logged in, JWT present in URL');
      } else {
        // For logout, we need to send the specific message
        this.handleLogout();
      }
    },
    postMessageToIframe(message) {
      if (this.$refs.sportsbookIframe?.contentWindow) {
        const targetOrigin = process.env.VUE_APP_SPORTSBOOK_IFRAME_URL || "*";
        this.$refs.sportsbookIframe.contentWindow.postMessage(message, targetOrigin);
        console.log("Sent message to iframe:", message);
      } else {
        console.log("Iframe not ready for postMessage");
      }
    },
    handleIframeMessage(event) {
      const { data } = event;
      console.log("⚽ Received message from iframe:", data);

      switch (data.type) {
        case "login.click":
          console.log("⚽ Login requested");
          this.modalsSetShow("Login");
          break;

        case "logout.click":
          console.log("⚽ Logout requested");
          this.handleLogout();
          break;

        case "auth.fail":
        case "auth.check.fail":
          console.error("⚽ Authentication failed:", data.message);
          this.handleLogout();
          break;

        case "user.balance":
          if (data.balance !== undefined) {
            console.log("⚽ Balance update received from iframe:", data.balance);
          }
          break;

        case "bet.placed":
          console.log("⚽ Bet placement initiated:", data);
          this.handleBetPlaced(data);
          break;

        case "bet.failed":
          console.error("⚽ Bet failed:", data.message);
          this.error = data.message || "Failed to place bet";
          break;

        default:
          console.log("⚽ Unhandled message type:", data.type);
      }
    },
    async handleBetPlaced(data) {
      if (!this.authUser?.user?._id) {
        console.error("⚽ Cannot place bet: User not authenticated");
        return;
      }

      console.log("⚽ Bet placement initiated:", {
        betData: data,
        currentUserId: this.authUser.user._id,
        transactionUserId: data.transactionId.split('__')[0].split('_')[2]
      });

      try {
        // Just pass through the data from iframe to backend
        const transactionData = {
          currency: "USDT",
          platform: "sport",
          id: data.transactionId,
          type: "withdrawal",
          initiatedAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
          context: {
            product: "sportsbook",
            reason: "bet",
            betId: data.betId
          },
          amountBreakdown: {
            cash: data.amount,
            bonus: 0,
            locked: 0,
            retract: 0
          }
        };

        console.log("⚽ Sending transaction request:", transactionData);

        const response = await sportsbookService.performTransaction(
          this.authUser.user._id, 
          transactionData
        );

        console.log("⚽ Transaction processed:", response);

        if (response.alreadyProcessed) {
          console.log("⚽ Transaction was already processed");
        } else {
          console.log("⚽ New transaction processed successfully");
          // Remove direct balance update here - we'll let the socket handle it
        }

      } catch (error) {
        console.error("⚽ Bet placement failed:", error);
        this.error = error.error?.message || "Failed to place bet";
      }
    },
    async verifyBalance() {
      if (!this.authUser?.user?._id) {
        console.log("⚽ Cannot verify balance: User not authenticated");
        return;
      }

      try {
        const response = await sportsbookService.getBalance(this.authUser.user._id);
        const balance = response.sport?.sportsbook?.USDT?.cash;
        
        if (balance !== undefined) {
          console.log("⚽ Current balance:", balance);
          this.updateUserBalance(parseFloat(balance));
        } else {
          console.error("⚽ Invalid balance format in response:", response);
        }
      } catch (error) {
        console.error("⚽ Balance verification failed:", error);
        // Handle specific error codes
        if (error.error?.code === 'decline.lowbalance') {
          this.error = "Insufficient funds";
        } else if (error.error?.code === 'decline.player.blocked') {
          this.error = "Player is blocked";
        } else {
          this.error = error.message;
        }
      }
    },
    handleLogout() {
      console.log('⚽ Handling logout');
      this.jwtToken = null;

      // Send logout message as per documentation
      if (this.$refs.sportsbookIframe?.contentWindow) {
        const message = {
          type: 'iframe',
          value: { logout: true }
        };

        console.log('⚽ Sending logout message to iframe:', message);
        
        const targetOrigin = process.env.VUE_APP_SPORTSBOOK_IFRAME_URL || "*";
        this.$refs.sportsbookIframe.contentWindow.postMessage(message, targetOrigin);
      } else {
        console.log('⚽ Iframe not ready for logout message');
      }
    }
  },
  async mounted() {
    console.log("Sportsbook component mounted");
    await this.initializeSportsbook();
    window.addEventListener("message", this.handleIframeMessage);

    // Modified socket balance update handler
    this.$socket.on('balance', (balance) => {
        console.log('⚽ Socket balance update received:', {
            newBalance: balance,
            currentBalance: this.authUser?.user?.balance
        });
        
        if (this.authUser?.user && !this.isBalanceUpdateInProgress) {
            this.isBalanceUpdateInProgress = true;
            this.$store.commit('auth_set_user_balance', balance);
            console.log('⚽ Balance updated in store:', {
                newBalance: balance,
                updatedBalance: this.authUser.user.balance
            });
            this.$nextTick(() => {
                this.isBalanceUpdateInProgress = false;
            });
        }
    });
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleIframeMessage);
    this.$socket.off('balance'); // Remove socket listener
  },
  watch: {
    'authUser.user': {
      handler(newUser, oldUser) {
        console.log('⚽ Auth user changed:', {
          hadUser: Boolean(oldUser),
          hasUser: Boolean(newUser)
        });
        
        // Only handle logout case here
        if (oldUser && !newUser) {
          this.handleLogout();
        }
        
        // Only initialize if we don't have a URL yet
        if (!this.sportsbookUrl) {
          this.initializeSportsbook();
        }
      },
      deep: true
    },
    authToken(newToken, oldToken) {
      console.log('⚽ Auth token changed:', {
        hadToken: Boolean(oldToken),
        hasToken: Boolean(newToken)
      });
      
      // Only handle logout case here
      if (oldToken && !newToken) {
        this.handleLogout();
      }
      
      // Only initialize if we don't have a URL yet
      if (!this.sportsbookUrl) {
        this.initializeSportsbook();
      }
    }
  },
};
</script>

<style scoped>
.sportsbook-page{
  height: 100%;
}

.sportsbook-container {
  width: 100%;
  height: 100%;
  position: sticky;
  background: var(--background-primary);
  z-index: 1;
}

.sportsbook-iframe {
  width: 100%;
  height: 100%; /* Takes up 90% of the viewport height */
  border: none;
  position: relative;
  background: transparent;
  z-index: 1;
  overflow-y: auto;
}

.loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  font-size: 18px;
  text-align: center;
}

</style>