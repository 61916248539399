import ethIcon from '@/assets/icons/networks/eth.svg'
import bscIcon from '@/assets/icons/networks/bsc.svg'
import trxIcon from '@/assets/icons/networks/trx.svg'
import solIcon from '@/assets/icons/networks/sol.svg'
import btcIcon from '@/assets/icons/networks/btc.svg'
import allIcon from '@/assets/icons/networks/all.svg'
import otherIcon from '@/assets/icons/networks/other.svg'

export const networkIcons = {
  'ETH': ethIcon,
  'BSC': bscIcon,
  'TRX': trxIcon,
  'SOL': solIcon,
  'BTC': btcIcon,
  'all': allIcon,
  'OTHER': otherIcon
} 