<template>
  <div class="modal-game-selector" v-if="show">
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{ selectionMode === 'specific' ? 'Select Eligible Games' : 'Select Excluded Games' }}</h2>
        <button class="close-button" @click="close">
          <font-awesome-icon icon="times" />
        </button>
      </div>

      <!-- Search and Filters -->
      <div class="search-filters">
        <div class="search-row">
          <div class="search-wrapper">
            <font-awesome-icon icon="search" class="search-icon" />
            <input 
              v-model="searchQuery" 
              type="text" 
              class="search-input"
              placeholder="Search games..."
              @input="handleFilters"
            >
            <button 
              v-if="searchQuery"
              @click="clearSearch"
              class="clear-search"
            >
              <font-awesome-icon icon="times" />
            </button>
          </div>

          <div class="provider-select-wrapper">
            <div class="custom-select" :class="{ active: isProviderDropdownOpen }">
              <div class="select-header" @click="toggleProviderDropdown">
                <span class="selected-value">{{ formatProviderName(selectedProvider) || 'All Providers' }}</span>
                <div class="select-controls">
                  <button 
                    v-if="selectedProvider"
                    class="reset-provider"
                    @click.stop="selectProvider('')"
                  >
                    <font-awesome-icon icon="times" />
                  </button>
                  <span class="select-icon">
                    <font-awesome-icon :icon="['fas', 'chevron-down']" />
                  </span>
                </div>
              </div>
              <div class="select-dropdown" v-if="isProviderDropdownOpen">
                <div 
                  class="select-option" 
                  :class="{ active: selectedProvider === '' }"
                  @click="selectProvider('')"
                >
                  All Providers
                </div>
                <div 
                  v-for="provider in availableProviders" 
                  :key="provider"
                  class="select-option"
                  :class="{ active: selectedProvider === provider }"
                  @click="selectProvider(provider)"
                >
                  {{ formatProviderName(provider) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Games Grid -->
      <div class="games-grid" ref="gamesGrid" @scroll="handleScroll">
        <div 
          v-for="game in filteredGames" 
          :key="game.gameId"
          class="game-card"
          :class="{ selected: isGameSelected(game) }"
          @click="toggleGameSelection(game)"
        >
          <div class="game-image-wrapper">
            <img 
              :src="game.imageUrl" 
              :alt="game.translationKey"
              class="game-image"
              loading="lazy"
            >
            <div class="game-overlay">
              <div class="selection-indicator">
                <font-awesome-icon :icon="isGameSelected(game) ? 'check-circle' : 'plus-circle'" />
              </div>
            </div>
          </div>
          <div class="game-info">
            <h3 class="game-title">{{ game.translationKey }}</h3>
            <p class="game-provider">{{ formatProviderName(game.gameProvider) }}</p>
          </div>
        </div>

        <!-- Loading State -->
        <div v-if="isLoading" class="loading-state">
          <div class="loading-spinner"></div>
          <p>Loading games...</p>
        </div>

        <!-- No Games Found State -->
        <div v-if="!isLoading && filteredGames.length === 0" class="no-games-state">
          <font-awesome-icon icon="dice-d20" class="no-games-icon" />
          <h3 class="no-games-title">No Games Found</h3>
          <p class="no-games-text">Try adjusting your filters or start fresh</p>
          <button @click="resetFilters" class="reload-button">
            <font-awesome-icon icon="sync" />
            Show All Games
          </button>
        </div>
      </div>

      <!-- Footer Actions -->
      <div class="modal-footer">
        <div class="selected-count">
          {{ selectedGames.length }} games selected
        </div>
        <div class="footer-actions">
          <button class="btn-secondary" @click="close">Cancel</button>
          <button class="btn-primary" @click="confirmSelection">Confirm Selection</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import { BONUS_GAME_TYPES, BONUS_ELIGIBLE_PROVIDERS } from '@/constants/bonus'

export default {
  name: 'ModalGameSelector',
  
  props: {
    show: {
      type: Boolean,
      default: false
    },
    selectionMode: {
      type: String,
      default: 'specific', // 'specific' or 'excluded'
      validator: value => ['specific', 'excluded'].includes(value)
    },
    initialSelectedGames: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      searchQuery: '',
      selectedProvider: '',
      isProviderDropdownOpen: false,
      selectedGames: [],
      isLoading: false,
      page: 1,
      debounceTimer: null
    }
  },

  computed: {
    ...mapState('eva', ['providers']),
    ...mapGetters('eva', [
      'displayedGames',
      'hasNextPage',
      'isLoading',
      'hasError'
    ]),

    availableProviders() {
      // Filter providers to only show eligible ones from backend constants
      return this.providers
        .filter(provider => BONUS_ELIGIBLE_PROVIDERS.includes(provider))
        .sort((a, b) => this.formatProviderName(a).localeCompare(this.formatProviderName(b)))
    },

    filteredGames() {
      return this.displayedGames.filter(game => {
        // Only show slots games
        if (game.productType !== 'slots') {
          return false
        }

        // Check if provider is eligible
        if (!BONUS_ELIGIBLE_PROVIDERS.includes(game.gameProvider)) {
          return false
        }

        return true
      })
    }
  },

  watch: {
    show(newVal) {
      if (newVal) {
        this.selectedGames = [...this.initialSelectedGames]
        this.initializeData()
      }
    }
  },

  methods: {
    ...mapActions('eva', [
      'fetchGames',
      'setFilters',
      'initializeProviders'
    ]),

    async initializeData() {
      this.isLoading = true
      try {
        // Initialize providers list
        await this.initializeProviders()

        // Set initial filters to only show slots
        await this.setFilters({
          filters: {
            size: 500,
            channel: 'desktop',
            isActive: true,
            productType: 'slots'
          },
          target: 'all',
          forceRefresh: true
        })

        // Ensure we have all games loaded
        await this.ensureGamesLoaded()
      } catch (error) {
        console.error('Failed to initialize games:', error)
      } finally {
        this.isLoading = false
      }
    },

    async ensureGamesLoaded() {
      // Set filters to load slots games
      await this.setFilters({
        filters: {
          isActive: true,
          channel: 'desktop',
          productType: 'slots'
        },
        target: 'all',
        loadAll: true
      })
      
      // Fetch all games if not already loaded
      if (!this.displayedGames?.length) {
        await this.fetchGames({ target: 'all' })
      }
    },

    handleFilters: debounce(async function() {
      this.isLoading = true
      try {
        const filters = {
          size: 500,
          channel: 'desktop',
          isActive: true,
          productType: 'slots'
        }

        if (this.searchQuery && this.searchQuery.length >= 3) {
          filters.translationKey = this.searchQuery
          filters.size = 100
        }

        if (this.selectedProvider) {
          filters.gameProvider = this.selectedProvider
        }

        // Reset games in store
        this.$store.commit('eva/RESET_GAMES')

        await this.setFilters({
          filters,
          target: 'all',
          forceRefresh: !this.searchQuery
        })

        // Fetch first page of games
        await this.fetchGames({ target: 'all' })
      } catch (error) {
        console.error('Failed to apply filters:', error)
      } finally {
        this.isLoading = false
      }
    }, 300),

    clearSearch() {
      this.searchQuery = ''
      this.handleFilters()
    },

    toggleProviderDropdown() {
      this.isProviderDropdownOpen = !this.isProviderDropdownOpen
    },

    async selectProvider(provider) {
      this.selectedProvider = provider
      this.isProviderDropdownOpen = false
      await this.handleFilters()
    },

    formatProviderName(provider) {
      if (!provider) return ''
      
      // Handle special cases
      const specialCases = {
        'pragmatic': 'Pragmatic Play',
        'evolution': 'Evolution Gaming',
        'netent': 'NetEnt',
        'playtech': 'Playtech',
        'microgaming': 'Microgaming',
        'playngo': "Play'n GO",
        'quickspin': 'Quickspin',
        'yggdrasil': 'Yggdrasil',
        'redtiger': 'Red Tiger',
        'pushgaming': 'Push Gaming',
        'nolimitcity': 'Nolimit City',
        'hacksaw': 'Hacksaw Gaming',
        'relax': 'Relax Gaming'
      }

      const lowercaseProvider = provider.toLowerCase()
      if (specialCases[lowercaseProvider]) {
        return specialCases[lowercaseProvider]
      }

      return provider
        .split(/[-_\s]/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
    },

    isGameSelected(game) {
      return this.selectedGames.some(g => g.gameId === game.gameId)
    },

    toggleGameSelection(game) {
      const index = this.selectedGames.findIndex(g => g.gameId === game.gameId)
      if (index === -1) {
        this.selectedGames.push(game)
      } else {
        this.selectedGames.splice(index, 1)
      }
    },

    async handleScroll(event) {
      const element = event.target
      const bottomSpace = element.scrollHeight - element.scrollTop - element.clientHeight

      if (bottomSpace < 200 && !this.isLoading && this.hasNextPage) {
        this.isLoading = true
        try {
          console.log('[ModalGameSelector] Loading more games...')
          await this.fetchGames({ target: 'all' })
        } catch (error) {
          console.error('[ModalGameSelector] Error loading more games:', error)
        } finally {
          this.isLoading = false
        }
      }
    },

    async resetFilters() {
      this.searchQuery = ''
      this.selectedProvider = ''
      this.$store.commit('eva/RESET_GAMES')
      await this.initializeData()
    },

    close() {
      this.$emit('close')
    },

    confirmSelection() {
      this.$emit('games-selected', this.selectedGames)
      this.close()
    }
  },

  created() {
    if (this.show) {
      this.selectedGames = [...this.initialSelectedGames]
      this.initializeData()
    }
  },

  beforeDestroy() {
    // Clean up
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer)
    }
  }
}
</script>

<style scoped>
.modal-game-selector {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.modal-content {
  width: 100%;
  max-width: 1000px;
  max-height: 90vh;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
  border-radius: 12px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.1) inset;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.modal-header {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(31, 42, 68, 0.5);
}

.modal-header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 800;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.close-button {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  transition: all 0.3s ease;
}

.close-button:hover {
  color: #fff;
  transform: scale(1.1);
}

.search-filters {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(31, 42, 68, 0.3);
}

.search-row {
  display: flex;
  gap: 16px;
}

.search-wrapper {
  flex: 1;
  position: relative;
}

.search-input {
  width: 100%;
  height: 40px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 0 40px;
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
}

.search-input:focus {
  background: rgba(44, 94, 255, 0.1);
  border-color: rgba(44, 94, 255, 0.3);
  outline: none;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
}

.clear-search {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  padding: 5px;
}

.provider-select-wrapper {
  width: 250px;
}

.custom-select {
  position: relative;
}

.select-header {
  height: 40px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
}

.select-header:hover {
  background: rgba(44, 94, 255, 0.1);
  border-color: rgba(44, 94, 255, 0.3);
}

.selected-value {
  color: #fff;
  font-size: 14px;
}

.select-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reset-provider {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  padding: 2px;
}

.select-icon {
  color: rgba(255, 255, 255, 0.5);
}

.select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background: #1A1F37;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.select-option {
  padding: 12px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.select-option:hover,
.select-option.active {
  background: rgba(44, 94, 255, 0.1);
}

.games-grid {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.game-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
}

.game-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  border-color: rgba(44, 94, 255, 0.2);
  background: rgba(255, 255, 255, 0.08);
}

.game-card.selected {
  border-color: #2C5EFF;
  box-shadow: 0 0 0 1px #2C5EFF;
}

.game-image-wrapper {
  position: relative;
  padding-top: 100%;
}

.game-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.game-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-card:hover .game-overlay,
.game-card.selected .game-overlay {
  opacity: 1;
}

.selection-indicator {
  font-size: 32px;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.game-info {
  padding: 12px;
}

.game-title {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.game-provider {
  margin: 4px 0 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.modal-footer {
  padding: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(31, 42, 68, 0.5);
}

.selected-count {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.footer-actions {
  display: flex;
  gap: 12px;
}

.btn-primary,
.btn-secondary {
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-primary {
  background: linear-gradient(90deg, #2C5EFF 0%, #5F8AFF 100%);
  border: none;
  color: #fff;
}

.btn-secondary {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}

.btn-primary:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(44, 94, 255, 0.3);
}

.btn-secondary:hover {
  background: rgba(255, 255, 255, 0.15);
}

.loading-state {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 40px;
  color: rgba(255, 255, 255, 0.7);
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-top-color: #2C5EFF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.no-games-state {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  text-align: center;
}

.no-games-icon {
  font-size: 48px;
  color: rgba(255, 255, 255, 0.2);
  margin-bottom: 16px;
}

.no-games-title {
  font-size: 24px;
  font-weight: 800;
  margin: 0 0 8px;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.no-games-text {
  color: rgba(255, 255, 255, 0.5);
  margin: 0 0 20px;
}

.reload-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: linear-gradient(90deg, #2C5EFF 0%, #5F8AFF 100%);
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.reload-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(44, 94, 255, 0.3);
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .modal-game-selector {
    padding: 10px;
  }

  .search-row {
    flex-direction: column;
  }

  .provider-select-wrapper {
    width: 100%;
  }

  .games-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 12px;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .games-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 8px;
  }

  .modal-header h2 {
    font-size: 20px;
  }
}
</style> 