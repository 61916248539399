<template>
    <div class="fair-duels">
        <div class="duels-title">DICE DUELS</div>
        <div class="duels-text">
            <p>Dice Duels use four elements to ensure fair play:</p>
            <p><span class="highlight">Game ID:</span> Your duel's unique identifier - like a match ticket.</p>
            <p><span class="highlight">Server Seed:</span> Our secret number that's revealed after the duel.</p>
            <p><span class="highlight">Public Seed:</span> A blockchain block ID that timestamps your game.</p>
            <p><span class="highlight">Roll Index:</span> Your position in the duel (Player 1 or 2).</p>
            <p>These combine to create verifiable rolls for both players.</p>
        </div>
        <div class="duels-code">
            <pre>
                <code>
const crypto = require('crypto');

const playerCount = '2';
const gameId = '6443aa99f4290f71b555f4bd';
const serverSeed = '4c60603de55e3b85fe67450aa124ec34509cb73b0f1fde15';
const publicSeed = '128b71e19b3c4bd9ec01eba9d6840bdda2a8d0972ac918c9d937c7f861879727';

const fairGetDuelsOutcome = () => {
    for(index = 0; index < playerCount; index++) {
        const combined = `${gameId}-${serverSeed}-${publicSeed}-${index}`;
        const hash = crypto.createHash('sha256').update(combined).digest('hex');
        const roll = parseInt(hash.substr(0, 8), 16) % 10000;

        console.log(`Player: ${index + 1} - Outcome: ${roll}`);
    }
}

fairGetDuelsOutcome();
                </code>
            </pre>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FairDuels'
    }
</script>

<style scoped>
    .fair-duels {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
        border-radius: 10px;
    }

    .duels-title {
        font-size: 24px;
        font-weight: 900;
        font-family: Montserrat, sans-serif;
        background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .duels-text {
        margin-top: 12px;
        width: 100%;
    }

    .duels-text p {
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
        margin-bottom: 8px;
        font-family: Montserrat, sans-serif;
    }

    .duels-text p .highlight {
        color: #2C5EFF;
        font-weight: 700;
    }

    .duels-code {
        width: 100%;
        margin-top: 25px;
        padding: 16px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
    }

    .duels-code pre {
        width: 100%;
        height: 225px;
        overflow: auto;
    }

    .duels-code pre code {
        font-family: 'Courier New', monospace;
        font-size: 13px;
        font-weight: 600;
        color: #bbbfd0;
    }

    /* Custom scrollbar styling */
    .duels-code pre::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    .duels-code pre::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
    }

    .duels-code pre::-webkit-scrollbar-thumb {
        background: #2C5EFF;
        border-radius: 2px;
    }

    @media only screen and (max-width: 768px) {
        .fair-duels {
            padding: 15px;
        }
        
        .duels-title {
            font-size: 20px;
        }
        
        .duels-code {
            padding: 12px;
        }
    }
</style>