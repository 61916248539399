<template>
    <div class="header">
        <div class="coin-container">
            <div class="coin-ellipse">
                <img src="@/assets/img/icons/coin.svg" alt="coin" />
            </div>
            <div class="bazed-coin">
                BAZED COIN
                <div class="stats">
                    <p class="text">$2.50</p>
                    <p class="gain">
                        <img src="@/assets/icons/green-arrows.svg" alt="coin" />
                        10%
                    </p>
                </div>
            </div>
        </div>
        <div class="coin-stats">
            <p class="text">24h Volume: <strong>$22.45K</strong></p>
            <p class="text">TVL: <strong>$22.45K</strong></p>
            <p class="text">Holders: <strong>26,601</strong></p>
        </div>
        <div class="actions">
            <button class="btn text">
                <img src="@/assets/icons/whitepaper.svg" alt="whitepaper" />
                Whitepaper
            </button>
            <button class="btn text">
                <img src="@/assets/icons/contract.svg" alt="contract" />
                Contract
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuyBackHeader',
}
</script>


<style scoped>
.header,
.stats,
.actions{
    display: flex;
    align-items: center;
}

.actions {
    flex-direction: row;
    margin-left: auto;
    gap: 18px;
}

.actions .btn:hover {
    background-color: #005EE9;
}

.actions .btn img {
    width: 16px;
    height: 16px;
}

.bazed-coin {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.btn {
    display: flex;
    width: 50%;
    height: 100%;
    padding: 13px 25px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    background: #1F2841;
    cursor: pointer;
}

.coin-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.coin-ellipse {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #080e1c;
    border: 2px solid transparent;
    background: linear-gradient(#080e1c, #080e1c) padding-box, linear-gradient(180deg, #349DFF, #005EE9) border-box;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2) inset, 0px 0px 4px rgba(45, 111, 255, 0.25);
}

.coin-ellipse img {
    width: 25px;
    height: 25px;
}

.coin-stats {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: 1rem;
    padding-top: 10px;
}

.gain {
    color: var(--primary-color, #38f27f);
    font-size: 14px;
    font-weight: 500;
}

.header {
    color: #ffffff;
}

.stats {
    gap: 1rem;
    flex-wrap: wrap;
    font-size: 14px;
    color: #ffffff;
}

.stats p {
    margin: 0;
}

.text {
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (max-width: 668px) {
    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .coin-info {
        align-items: flex-start;
        text-align: left;
        margin-bottom: 0;
    }

    .coin-stats {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 10px;
    }

    .actions {
        width: 100%;
        flex-direction: row;
        margin-left: 0px;
    }
}


</style>
