
/*-----------------24H Casino Revenue-----------------*/
export const casinoLabel = 1000;
export const casinoPercentage = 15;
export const casinoRevenue = [14678, 10980, 25679];
export const CasinoBurnt  = [5250, 3600, 7860];

/*-----------------24H Crypto Futures Revenue-----------------*/
export const cryptoLabel = 2000;
export const cryptoPercentage = 25;
export const cryptoRevenue = [14678, 10980, 25679];
export const cryptoBurnt  = [5250, 3600, 7860];

/*-----------------24H Sports Revenue-----------------*/
export const sportLabel = 3000;
export const sportPercentage = 35;
export const sportRevenue = [14678, 10980, 25679];
export const sportBurnt  = [5250, 3600, 7860];

/*-----------------Bazed Supply-----------------*/
export const burntPercentage = 10;
export const circulatingPercentage = (100-burntPercentage);

/*-----------------24H BAZED BURNT-----------------*/
export const bazedBurnt = [14678, 10980, 25679];