<template>
  <div class="crash">
    <div class="black-gradient" />
    <div class="clouds">
      <img
        src="../assets/img/crash/cloud.png"
        alt="Cloud"
        class="cloud-animation first-cloud"
      />
      <img
        src="../assets/img/crash/cloud.png"
        alt="Cloud"
        class="cloud-animation second-cloud"
      />
    </div>
    <div class="planets">
      <img
        v-for="(planet, index) in planets"
        :key="index"
        :src="planet.src"
        :style="{
          top: planet.top,
          left: planet.left,
          width: planet.size,
          height: planet.size,
        }"
        class="planet-animation"
        alt="Planet"
      />
    </div>
    <div class="crash-container">
      <transition name="fade" mode="out-in">
        <div
          v-if="socketCrash.connected === false"
          class="container-loading"
          key="loading"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            width="24"
            height="24"
            stroke-width="2"
          >
            <path d="M12 3a9 9 0 1 0 9 9"></path>
          </svg>
        </div>
        <div v-else class="container-data" key="data">
          <CrashControls />
          <div class="data-left">
            <div class="game-wrapper">
              <CrashHistory />
              <CrashGame />
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!--<Bets/>-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Bets from "@/components/bets/Bets";
import CrashGame from "@/components/crash/CrashGame";
import CrashHistory from "@/components/crash/CrashHistory";
import CrashControls from "@/components/crash/CrashControls";

export default {
  name: "Crash",
  metaInfo: {
    title: "Crash - Bazedbet",
  },
  components: {
    Bets,
    CrashGame,
    CrashHistory,
    CrashControls,
  },
  data() {
    return {
      planets: [],
      planetImages: [
        require("../assets/img/crash/planets/planet_1.png"),
        require("../assets/img/crash/planets/planet_2.png"),
        require("../assets/img/crash/planets/planet_3.png"),
        require("../assets/img/crash/planets/planet_4.png"),
        require("../assets/img/crash/planets/planet_5.png"),
      ],
      lastPlanetIndex: null,
    };
  },
  methods: {
    ...mapActions(["socketConnectCrash", "socketDisconnectCrash"]),
    generateRandomPlanet() {
      const size = `${Math.random() * 500 + 100}px`;
      const top = `${Math.random() * 50}vh`;
      const left = `100vw`;

      let newPlanetIndex;
      do {
        newPlanetIndex = Math.floor(Math.random() * this.planetImages.length);
      } while (newPlanetIndex === this.lastPlanetIndex);
      this.lastPlanetIndex = newPlanetIndex;

      const src = this.planetImages[newPlanetIndex];
      const planet = { src, top, left, size, speed: Math.random() * 0.5 + 0.2 };
      this.planets.push(planet);
    },
    movePlanets() {
      this.planets.forEach((planet, index) => {
        planet.left = parseFloat(planet.left) - planet.speed + "vw";
        if (parseFloat(planet.left) < -parseFloat(planet.size)) {
          this.planets.splice(index, 1);
        }
      });
      requestAnimationFrame(this.movePlanets);
    },
  },
  computed: {
    ...mapGetters(["socketCrash"]),
  },
  created() {
    this.socketConnectCrash();
    setInterval(this.generateRandomPlanet, 7000);
    this.movePlanets();
  },
  beforeRouteLeave(to, from, next) {
    this.socketDisconnectCrash();
    next();
  },
};
</script>

<style scoped>
.crash {
  width: 100%;
  height: 89.5vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px 10px;
  overflow: hidden;
  background: linear-gradient(180deg, #1a2d69 0%, #0a1127 100%);
}

.black-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0) 50%
  );
}

.clouds {
  position: absolute;
  bottom: -100px;
  height: 400px;
  opacity: 0.1;
  animation: moveClouds 30s linear infinite;
}

.clouds .first-cloud {
  transform: translateX(-2048px);
  z-index: 1;
}

.clouds .second-cloud {
  transform: translate(1024px, -450px);
  z-index: 1;
}

@keyframes moveClouds {
  0% {
    transform: translateX(2048px);
  }
  100% {
    transform: translateX(-2048px);
  }
}

.crash .crash-container {
  height: 100%;
  width: 90%;
}

.crash .container-loading {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.crash .container-loading svg {
  width: 86px;
  height: 86px;
  stroke: #fff;
  filter: drop-shadow(0 0 10px #ffffff61);
  animation: circle_loading_animation 1s linear infinite;
}

@keyframes circle_loading_animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.crash .container-data {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.crash .container-data.fade-enter-active {
  transition: opacity 0.5s;
}

.crash .container-data.fade-enter-from {
  opacity: 0;
}

.crash .data-left {
  width: calc(100% - 425px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  position: relative;
  z-index: 1;
}

.crash .data-left .game-wrapper {
  height: 100%;
  position: relative;
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  z-index: 2;
}

@media only screen and (max-width: 1140px) {
  .crash .crash-container {
    width: 100%;
  }
}

@media only screen and (max-width: 950px) {
  .crash {
    height: unset;
    padding: 15px 10px min(150px, 15vh) 10px;
  }

  .crash .data-left .game-wrapper {
    padding: 0;
    margin-bottom: 10px;
  }

  .crash .container-loading,
  .crash .container-data {
    flex-direction: column-reverse;
  }

  .crash .loading-element {
    width: 100% !important;
  }

  .crash .loading-element:nth-child(2) {
    height: 200px;
    margin-top: 15px;
  }

  .crash .data-left {
    width: 100%;
  }

  .black-gradient {
    display: none;
  }
}

.planets {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  opacity: 0.1;
}

.planet-animation {
  position: absolute;
}
</style>
