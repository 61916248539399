<template>
  <div class="bets">
    <div class="bets-header">
      <div class="bets-title">
        <div class="title-icon">
          <font-awesome-icon icon="fa-solid fa-coins" />
        </div>
        <h2>Bets</h2>
      </div>

      <div class="bets-nav">
        <button v-if="authUser.user !== null" 
                @click="betsSetTab('my')" 
                :class="['nav-button', { active: betsTab === 'my' }]">
          MY BETS
        </button>
        <button @click="betsSetTab('all')" 
                :class="['nav-button', { active: betsTab === 'all' }]">
          ALL BETS
        </button>
        <button @click="betsSetTab('sports')" 
                :class="['nav-button', { active: betsTab === 'sports' }]">
          SPORTS
        </button>
        <button @click="betsSetTab('slots')" 
                :class="['nav-button', { active: betsTab === 'slots' }]">
          SLOTS
        </button>
        <button @click="betsSetTab('whale')" 
                :class="['nav-button', { active: betsTab === 'whale' }]">
          HIGH ROLLERS
        </button>
        <button @click="betsSetTab('lucky')" 
                :class="['nav-button', { active: betsTab === 'lucky' }]">
          LUCKY BETS
        </button>
      </div>
    </div>

    <div class="bets-container">
      <div class="list-head">
        <div class="head-game">GAME</div>
        <div class="head-user">USER</div>
        <div class="head-time">TIME</div>
        <div class="head-wager">WAGER</div>
        <div class="head-multiplier">MULTIPLIER</div>
        <div class="head-payout">PAYOUT</div>
      </div>

      <div class="list-content">
        <transition name="fade" mode="out-in">
          <div v-if="generalBets.bets === null || generalBets.loading" 
               class="content-loading" key="loading">
            <LoadingAnimation/>
          </div>
          <div v-else-if="betsGetList.length > 0" 
               class="content-list" key="data">
            <BetsElement v-for="bet in betsGetList" 
                        :key="bet._id + betsTab" 
                        :bet="bet"/>
          </div>
          <div v-else class="content-empty" key="empty">
            No bets found
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import LoadingAnimation from '@/components/LoadingAnimation';
import BetsElement from '@/components/bets/BetsElement';

library.add(faCoins);

export default {
  name: 'Bets',
  components: {
    LoadingAnimation,
    BetsElement,
    FontAwesomeIcon
  },
  data() {
    return {
      betsTab: 'all',
      updateInterval: null
    };
  },
  methods: {
    ...mapActions([
      'generalGetBetsDataSocket',
      'sportsGetBetsDataSocket',
      'slotsGetBetsDataSocket',
      'socketConnectSports',
      'socketConnectSlots'
    ]),
    betsSetTab(tab) {
      this.betsTab = tab;
      this.updateBets();
    },
    updateBets() {
      if (this.betsTab === 'sports') {
        this.socketConnectSports();
        this.sportsGetBetsDataSocket({});
      } else if (this.betsTab === 'slots') {
        this.socketConnectSlots();
        this.slotsGetBetsDataSocket({});
      } else {
        this.generalGetBetsDataSocket({});
      }
    }
  },
  computed: {
    ...mapGetters([
      'authUser',
      'generalBets',
      'sportsBets',
      'slotsBets',
      'socketGeneral',
      'socketSports',
      'socketSlots'
    ]),
    betsGetList() {
      let bets = [];

      if (this.betsTab === 'sports' && this.sportsBets.bets) {
        bets = this.sportsBets.bets.slice(0, 12);
      } else if (this.betsTab === 'slots' && this.slotsBets.bets) {
        bets = this.slotsBets.bets.slice(0, 12);
      } else if (this.generalBets.bets !== null && this.generalBets.bets[this.betsTab] !== undefined) {
        bets = this.generalBets.bets[this.betsTab].slice(0, 12);
      }

      return bets;
    }
  },
  created() {
    // Initial load
    if (this.generalBets.bets === null && this.generalBets.loading === false) {
      this.updateBets();
    }

    // Set up auto-refresh every 30 seconds
    this.updateInterval = setInterval(() => {
      this.updateBets();
    }, 30000);

    // Listen for new bets on all relevant sockets
    this.socketGeneral.on('bet', () => this.updateBets());
    this.socketSports.on('bet', () => this.updateBets());
    this.socketSlots.on('bet', () => this.updateBets());
  },
  beforeDestroy() {
    // Clean up
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
    
    // Remove socket listeners
    if (this.socketGeneral) this.socketGeneral.off('bet');
    if (this.socketSports) this.socketSports.off('bet');
    if (this.socketSlots) this.socketSlots.off('bet');
  }
};
</script>

<style scoped>
.bets {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.bets-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.bets-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.title-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bets-title h2 {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: var(--highlight-white);
  margin: 0;
}

.bets-nav {
  display: flex;
  gap: 12px;
}

.nav-button {
  padding: 8px 16px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--highlight-white);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.3) inset;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background: linear-gradient(180deg, rgba(44, 94, 255, 0.2) 0%, rgba(44, 94, 255, 0.1) 100%);
}

.nav-button.active {
  background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
  box-shadow: 0 0 15px rgba(44, 94, 255, 0.3);
}

.bets-container {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px -1px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1px 0px rgba(255, 255, 255, 0.3) inset;
  border-radius: 10px;
  overflow: hidden;
}

.list-head {
  display: flex;
  align-items: center;
  padding: 0 35px;
  height: 52px;
  background: rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.head-game,
.head-user { width: 20%; }
.head-time { width: 20%; }
.head-wager,
.head-payout { width: 15%; }
.head-multiplier { width: 10%; }

.list-head > div {
  color: #8bacc8;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.list-content {
  max-height: 640px;
  overflow-y: auto;
}

.content-list {
  display: flex;
  flex-direction: column;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.list-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.list-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media only screen and (max-width: 950px) {
  .bets-header {
    flex-direction: column;
    gap: 16px;
  }

  .bets-nav {
    justify-content: center;
    flex-wrap: wrap;
  }

  .list-head {
    display: none;
  }

  .bets-title {
    justify-content: center;
    margin-bottom: 16px;
  }

  .title-icon {
    width: 28px;
    height: 28px;
  }

  .bets-title h2 {
    font-size: 20px;
  }
}
</style>
