<template>
  <div class="modal-fair">
    <div class="fair-header">
      <span class="gradient-blue">PROVABLY FAIR</span>
    </div>
    <div class="fair-info">
      Yo degens! We're all about that transparent gambling life here! 🎲 Before
      you ape in with your bets, we've already cooked up the results and locked
      them in with some serious crypto magic. We give you the hashed outcome
      upfront (think of it as a sealed envelope), then after the game we reveal
      all the juicy details - server seed, public seed, everything! You can
      verify it yourself that we're keeping it 100% legit. No cap, just pure
      provably fair gaming for the true degens! 🚀
    </div>
    <div class="fair-nav">
      <button
        v-on:click="modalSetGame('Blackjack')"
        v-bind:class="{ 'button-active': modalGame === 'Blackjack' }"
      >
        <div class="button-inner">
          <span>BLACKJACK</span>
        </div>
      </button>
      <button
        v-on:click="modalSetGame('Roll')"
        v-bind:class="{ 'button-active': modalGame === 'Roll' }"
      >
        <div class="button-inner">
          <span>ROLL</span>
        </div>
      </button>
      <button
        v-on:click="modalSetGame('Crash')"
        v-bind:class="{ 'button-active': modalGame === 'Crash' }"
      >
        <div class="button-inner">
          <span>CRASH</span>
        </div>
      </button>
      <button
        v-on:click="modalSetGame('Duels')"
        v-bind:class="{ 'button-active': modalGame === 'Duels' }"
      >
        <div class="button-inner">
          <span>DICE DUELS</span>
        </div>
      </button>
      <button
        v-on:click="modalSetGame('Mines')"
        v-bind:class="{ 'button-active': modalGame === 'Mines' }"
      >
        <div class="button-inner">
          <span>MINES</span>
        </div>
      </button>
      <button
        v-on:click="modalSetGame('Towers')"
        v-bind:class="{ 'button-active': modalGame === 'Towers' }"
      >
        <div class="button-inner">
          <span>TOWERS</span>
        </div>
      </button>
      <button
        v-on:click="modalSetGame('Battles')"
        v-bind:class="{ 'button-active': modalGame === 'Battles' }"
      >
        <div class="button-inner">
          <span>BATTLES</span>
        </div>
      </button>
      <button
        v-on:click="modalSetGame('Unbox')"
        v-bind:class="{ 'button-active': modalGame === 'Unbox' }"
      >
        <div class="button-inner">
          <span>UNBOX</span>
        </div>
      </button>
    </div>
    <div class="fair-content">
      <component v-bind:is="'Fair' + modalGame" />
    </div>
  </div>
</template>

<script>
import FairBlackjack from "@/components/fair/FairBlackjack";
import FairRoll from "@/components/fair/FairRoll";
import FairCrash from "@/components/fair/FairCrash";
import FairDuels from "@/components/fair/FairDuels";
import FairMines from "@/components/fair/FairMines";
import FairTowers from "@/components/fair/FairTowers";
import FairUnbox from "@/components/fair/FairUnbox";
import FairBattles from "@/components/fair/FairBattles";

export default {
  name: "ModalFair",
  components: {
    FairBlackjack,
    FairRoll,
    FairCrash,
    FairDuels,
    FairMines,
    FairTowers,
    FairUnbox,
    FairBattles,
  },
  data() {
    return {
      modalGame: "Blackjack",
    };
  },
  methods: {
    modalSetGame(game) {
      this.modalGame = game;
    },
  },
};
</script>

<style scoped>
.modal-fair {
  width: 860px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 30px 30px 30px;
  border-radius: 15px;
  background: var(--background-primary);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
}

.modal-fair .fair-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-fair .header-icon {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(
    255deg,
    rgba(44, 94, 255, 0.2) 0%,
    rgba(29, 67, 192, 0.2) 100%
  );
}

.modal-fair .header-icon img {
  width: 48px;
  height: 48px;
}

.modal-fair .fair-header span {
  margin-top: 20px;
  text-align: center;
  font-size: 32px;
  font-weight: 900;
  background: linear-gradient(90deg, #2c5eff 0%, #1d43c0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.modal-fair .fair-info {
  width: 100%;
  margin-top: 12px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #bbbfd0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.modal-fair .fair-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 30px;
  padding: 8px;
  background: var(--background-primary);
  border-radius: 10px;
}

.modal-fair .fair-nav button {
  height: 48px;
  padding: 12px 24px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    var(--background-primary) 100%
  );
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    0px -1px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px rgba(255, 255, 255, 0.3) inset;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.modal-fair .fair-nav button .button-inner {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #bbbfd0;
}

.modal-fair .fair-nav button.button-active {
  background: linear-gradient(90deg, #2c5eff 0%, #1d43c0 100%);
  box-shadow: 0 0 15px rgba(44, 94, 255, 0.3);
}

.modal-fair .fair-nav button.button-active .button-inner span {
  color: #ffffff;
  background: none;
  -webkit-text-fill-color: #ffffff;
}

.modal-fair .fair-content {
  width: 100%;
  margin-top: 30px;
}

@media only screen and (max-width: 955px) {
  .modal-fair {
    width: calc(100vw - 20px);
    padding: 85px 10px 48px 10px;
  }
}

@media only screen and (max-width: 825px) {
  .modal-fair .fair-nav button {
    width: calc(25% - 3px);
  }

  .modal-fair .fair-nav button:nth-child(4n) {
    margin-right: 0;
  }

  .modal-fair .fair-nav button .button-inner {
    padding: 0;
  }
}
</style>
