<template>
    <div class="fair-battles">
        <div class="battles-title">BATTLES</div>
        <div class="battles-text">
            <p>Battles outcomes are determined by five key components:</p>
            <p><span class="highlight">Game ID:</span> Your battle's unique identifier.</p>
            <p><span class="highlight">Server Seed:</span> Our secret code revealed after the battle.</p>
            <p><span class="highlight">Public Seed:</span> A blockchain timestamp proving when the battle started.</p>
            <p><span class="highlight">Round:</span> Which round of the battle is being played.</p>
            <p><span class="highlight">Player:</span> Your position in the battle sequence.</p>
            <p>This system ensures fair and verifiable outcomes for all players.</p>
        </div>
        <div class="battles-code">
            <pre>
                <code>
const crypto = require('crypto');

const roundCount = '2';
const playerCount = '2';
const gameId = '6443aa99f4290f71b555f4bd';
const serverSeed = '4c60603de55e3b85fe67450aa124ec34509cb73b0f1fde15';
const publicSeed = '128b71e19b3c4bd9ec01eba9d6840bdda2a8d0972ac918c9d937c7f861879727';

const fairGetBattlesOutcome = () => {
    for(round = 0; round < roundCount; round++) {
        for(player = 0; player < playerCount; player++) {
            const combined = `${gameId}-${serverSeed}-${publicSeed}-${round}-${player}`;
            const hash = crypto.createHash('sha256').update(combined).digest('hex');
            const outcome = parseInt(hash.substr(0, 8), 16) % 10000;

            console.log(`Round: ${round + 1} - Player: ${player + 1} - Outcome: ${outcome}`);
        }
    }
}

fairGetBattlesOutcome();
                </code>
            </pre>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FairBattles'
    }
</script>

<style scoped>
    .fair-battles {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
        border-radius: 10px;
    }

    .battles-title {
        font-size: 24px;
        font-weight: 900;
        font-family: Montserrat, sans-serif;
        background: linear-gradient(90deg, #2C5EFF 0%, #1D43C0 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .battles-text {
        margin-top: 12px;
        width: 100%;
    }

    .battles-text p {
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
        margin-bottom: 8px;
        font-family: Montserrat, sans-serif;
    }

    .battles-text p .highlight {
        color: #2C5EFF;
        font-weight: 700;
    }

    .battles-code {
        width: 100%;
        margin-top: 25px;
        padding: 16px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                   0px -1px 0px rgba(0, 0, 0, 0.25) inset,
                   0px 1px 0px rgba(255, 255, 255, 0.3) inset;
    }

    .battles-code pre {
        width: 100%;
        height: 225px;
        overflow: auto;
    }

    .battles-code pre code {
        font-family: 'Courier New', monospace;
        font-size: 13px;
        font-weight: 600;
        color: #bbbfd0;
    }

    /* Custom scrollbar styling */
    .battles-code pre::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    .battles-code pre::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
    }

    .battles-code pre::-webkit-scrollbar-thumb {
        background: #2C5EFF;
        border-radius: 2px;
    }

    @media only screen and (max-width: 768px) {
        .fair-battles {
            padding: 15px;
        }
        
        .battles-title {
            font-size: 20px;
        }
        
        .battles-code {
            padding: 12px;
        }
    }
</style>