<template>
  <div class="duels-game-fair">
    <div class="fair-hash" :title="duelsGame.fair.hash">
      <span>HASH</span>
      <div class="hash-content">
        {{ abbreviateHash(duelsGame.fair.hash) }}
        <div class="full-hash">{{ duelsGame.fair.hash }}</div>
      </div>
    </div>
    <div
      v-if="duelsGame.state === 'completed'"
      class="fair-server"
      :title="duelsGame.fair.seedServer"
    >
      <span>SERVER</span>
      <div class="hash-content">
        {{ abbreviateHash(duelsGame.fair.seedServer) }}
        <div class="full-hash">{{ duelsGame.fair.seedServer }}</div>
      </div>
    </div>
    <div
      v-if="duelsGame.state === 'completed'"
      class="fair-public"
      :title="duelsGame.fair.seedPublic"
    >
      <span>PUBLIC</span>
      <div class="hash-content">
        {{ abbreviateHash(duelsGame.fair.seedPublic) }}
        <div class="full-hash">{{ duelsGame.fair.seedPublic }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DuelsGameFair",
  props: ["duelsGame"],
  methods: {
    abbreviateHash(hash) {
      return hash ? hash.substring(0, 16) + "..." : "";
    },
  },
};
</script>

<style scoped>
.duels-game-fair {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2.5rem;
  padding: 1.5rem;
}

.duels-game-fair .fair-hash,
.duels-game-fair .fair-server,
.duels-game-fair .fair-public {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  position: relative;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.hash-content {
  position: relative;
  font-family: monospace;
  font-size: 0.875rem;
  color: var(--highlight-white);
}

.full-hash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px;
  background: var(--background-tertiary);
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  word-break: break-all;
  z-index: 2;
  box-shadow:
    0px 2px 0px 0px rgba(255, 255, 255, 0.2) inset,
    0px -2px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0 6px 30px rgba(0, 0, 0, 0.3);
}

.fair-hash:hover .full-hash,
.fair-server:hover .full-hash,
.fair-public:hover .full-hash {
  opacity: 1;
  visibility: visible;
  transform: translateY(60%);
}

.fair-hash span,
.fair-server span,
.fair-public span {
  display: block;
  font-weight: 700;
  color: var(--highlight-white);
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  opacity: 0.8;
}

.fair-hash:hover,
.fair-server:hover,
.fair-public:hover {
  background: rgba(255, 255, 255, 0.08);
}

@media (max-width: 1050px) {
  .duels-game-fair {
    display: none;
  }
}

@media (max-width: 768px) {
  .duels-game-fair {
    flex-direction: column;
    align-items: stretch;
  }

  .duels-game-fair .fair-hash,
  .duels-game-fair .fair-server,
  .duels-game-fair .fair-public {
    max-width: none;
  }
}
</style>
