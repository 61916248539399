<template>
    <div class="affiliates-code">
        <div class="code-grid">
            <CreateAffiliateCode />
            <PerformanceOverview v-if="codes.length > 0" />
            <GlobalCommissionRates />
            <AffiliateCodesList />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CreateAffiliateCode from './code/CreateAffiliateCode.vue';
import PerformanceOverview from './code/PerformanceOverview.vue';
import GlobalCommissionRates from './code/GlobalCommissionRates.vue';
import AffiliateCodesList from './code/AffiliateCodesList.vue';

export default {
    name: 'AffiliatesCode',
    components: {
        CreateAffiliateCode,
        PerformanceOverview,
        GlobalCommissionRates,
        AffiliateCodesList
    },
    computed: {
        ...mapGetters(['affiliatesData']),
        codes() {
            return this.affiliatesData?.data?.codes || [];
        }
    }
}
</script>

<style scoped>
.affiliates-code {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.code-grid {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media only screen and (max-width: 768px) {
    .code-grid {
        gap: 16px;
    }
}

@media only screen and (max-width: 480px) {
    .code-grid {
        gap: 12px;
    }
}
</style>
