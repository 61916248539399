<template>
  <div class="menu-container" :class="{ collapsed: isCollapsed }">
    <div class="menu-scroll-container">
      <a
        href="https://www.dextools.io/app/en/ether/pair-explorer/0x9eb51eb22813ee077e7ef4739a68d8e0b8e67cc6"
        target="_blank"
        rel="noopener noreferrer"
        class="bazedbet-container"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
        >
          <g filter="url(#filter0_di_1625_1348)">
            <rect
              x="5"
              y="5"
              width="40"
              height="40"
              rx="10"
              fill="#080E1C"
              shape-rendering="crispEdges"
            />
            <rect
              x="4.5"
              y="4.5"
              width="41"
              height="41"
              rx="10.5"
              stroke="url(#paint0_linear_1625_1348)"
              shape-rendering="crispEdges"
            />
            <g filter="url(#filter1_d_1625_1348)">
              <path
                d="M27.534 15.2057L17.2007 25.5965C16.7715 26.0277 17.0762 26.7627 17.6841 26.7642L22.2951 26.7769C22.7559 26.778 23.0843 27.2256 22.9467 27.6653L20.9391 34.1078C20.7266 34.7889 21.5681 35.3003 22.0747 34.7979L32.4476 24.5194C32.8788 24.0918 32.5815 23.3558 31.9742 23.3485L27.2235 23.2884C26.7627 23.2826 26.439 22.8323 26.5803 22.3937L28.6712 15.8984C28.8916 15.2136 28.0412 14.6953 27.534 15.2057Z"
                fill="url(#paint1_linear_1625_1348)"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_di_1625_1348"
              x="0"
              y="0"
              width="50"
              height="50"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.176471 0 0 0 0 0.435294 0 0 0 0 1 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1625_1348"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1625_1348"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="2"
                operator="erode"
                in="SourceAlpha"
                result="effect2_innerShadow_1625_1348"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2.5" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect2_innerShadow_1625_1348"
              />
            </filter>
            <filter
              id="filter1_d_1625_1348"
              x="13"
              y="11"
              width="23.6516"
              height="28"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0.274578 0 0 0 0 0.541667 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1625_1348"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1625_1348"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1625_1348"
              x1="25"
              y1="5"
              x2="25"
              y2="45"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#349DFF" />
              <stop offset="1" stop-color="#005EE9" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1625_1348"
              x1="24.8258"
              y1="15"
              x2="24.8258"
              y2="35"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#34B1FF" />
              <stop offset="1" stop-color="#005EE9" />
            </linearGradient>
          </defs>
        </svg>
        <div class="token-container">
          <span class="token-name">$BAZED</span>
          <div class="token-data">
            <span class="price">{{ bazedTokenData.price }}</span>
            <span
              class="percentage"
              :class="{
                negative: parseFloat(bazedTokenData.percentageChange) < 0,
              }"
            >
              {{ bazedTokenData.percentageChange }}%
            </span>
          </div>
        </div>
      </a>

      <!--
      <div class="top-items">
        <button
          :class="[
            casinoButtonClass,
            { expanded: selectedSection === 'casino' && !isCollapsed },
          ]"
          @click="navigateToGames"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="27"
            viewBox="0 0 28 27"
            fill="none"
          >
            <path
              d="M7.25 22.5C7.875 22.5 8.40625 22.2812 8.84375 21.8438C9.28125 21.4062 9.5 20.875 9.5 20.25C9.5 19.625 9.28125 19.0938 8.84375 18.6562C8.40625 18.2188 7.875 18 7.25 18C6.625 18 6.09375 18.2188 5.65625 18.6562C5.21875 19.0938 5 19.625 5 20.25C5 20.875 5.21875 21.4062 5.65625 21.8438C6.09375 22.2812 6.625 22.5 7.25 22.5ZM7.25 9C7.875 9 8.40625 8.78125 8.84375 8.34375C9.28125 7.90625 9.5 7.375 9.5 6.75C9.5 6.125 9.28125 5.59375 8.84375 5.15625C8.40625 4.71875 7.875 4.5 7.25 4.5C6.625 4.5 6.09375 4.71875 5.65625 5.15625C5.21875 5.59375 5 6.125 5 6.75C5 7.375 5.21875 7.90625 5.65625 8.34375C6.09375 8.78125 6.625 9 7.25 9ZM14 15.75C14.625 15.75 15.1563 15.5313 15.5938 15.0938C16.0313 14.6563 16.25 14.125 16.25 13.5C16.25 12.875 16.0313 12.3438 15.5938 11.9062C15.1563 11.4688 14.625 11.25 14 11.25C13.375 11.25 12.8438 11.4688 12.4062 11.9062C11.9688 12.3438 11.75 12.875 11.75 13.5C11.75 14.125 11.9688 14.6563 12.4062 15.0938C12.8438 15.5313 13.375 15.75 14 15.75ZM20.75 22.5C21.375 22.5 21.9062 22.2812 22.3438 21.8438C22.7812 21.4062 23 20.875 23 20.25C23 19.625 22.7812 19.0938 22.3438 18.6562C21.9062 18.2188 21.375 18 20.75 18C20.125 18 19.5938 18.2188 19.1562 18.6562C18.7188 19.0938 18.5 19.625 18.5 20.25C18.5 20.875 18.7188 21.4062 19.1562 21.8438C19.5938 22.2812 20.125 22.5 20.75 22.5ZM20.75 9C21.375 9 21.9062 8.78125 22.3438 8.34375C22.7812 7.90625 23 7.375 23 6.75C23 6.125 22.7812 5.59375 22.3438 5.15625C21.9062 4.71875 21.375 4.5 20.75 4.5C20.125 4.5 19.5938 4.71875 19.1562 5.15625C18.7188 5.59375 18.5 6.125 18.5 6.75C18.5 7.375 18.7188 7.90625 19.1562 8.34375C19.5938 8.78125 20.125 9 20.75 9ZM3.5 27C2.675 27 1.96875 26.7063 1.38125 26.1188C0.79375 25.5313 0.5 24.825 0.5 24V3C0.5 2.175 0.79375 1.46875 1.38125 0.88125C1.96875 0.29375 2.675 0 3.5 0H24.5C25.325 0 26.0313 0.29375 26.6188 0.88125C27.2063 1.46875 27.5 2.175 27.5 3V24C27.5 24.825 27.2063 25.5313 26.6188 26.1188C26.0313 26.7063 25.325 27 24.5 27H3.5Z"
              fill="#E8EAED"
            />
          </svg>
          <span v-if="selectedSection === 'casino' && !isCollapsed"
            >CASINO</span
          >
        </button>
        <button
          :class="[
            sportsButtonClass,
            'sports-button',
            { expanded: selectedSection === 'sports' && !isCollapsed },
          ]"
          @click="navigateTo('/sports', 'sports')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
          >
            <path
              d="M0 12.1093C0.113065 10.956 0.350503 9.86495 0.712312 8.83606C1.07412 7.80716 1.57161 6.84045 2.20477 5.93593C2.99623 6.75 3.65766 7.6941 4.18907 8.76822C4.72048 9.84234 5.06533 10.956 5.22362 12.1093H0ZM21.7764 12.1093C21.9347 10.956 22.2739 9.84799 22.794 8.78518C23.3141 7.72236 23.9812 6.78392 24.7952 5.96985C25.4284 6.85176 25.9259 7.81281 26.2877 8.85301C26.6495 9.89322 26.8869 10.9786 27 12.1093H21.7764ZM2.20477 21.0302C1.57161 20.1482 1.07412 19.1928 0.712312 18.1639C0.350503 17.135 0.113065 16.044 0 14.8907H5.22362C5.06533 16.044 4.72048 17.152 4.18907 18.2148C3.65766 19.2776 2.99623 20.2161 2.20477 21.0302ZM24.7952 21.0302C23.9812 20.2161 23.3141 19.2776 22.794 18.2148C22.2739 17.152 21.9347 16.044 21.7764 14.8907H27C26.8869 16.0214 26.6495 17.1068 26.2877 18.147C25.9259 19.1872 25.4284 20.1482 24.7952 21.0302ZM7.97111 12.1093C7.7902 10.4812 7.34925 8.97739 6.64824 7.59799C5.94724 6.21859 5.03141 4.97487 3.90075 3.86683C4.98618 2.78141 6.22425 1.9108 7.61495 1.25503C9.00565 0.599246 10.5038 0.180905 12.1093 0V12.1093H7.97111ZM14.8907 12.1093V0C16.4962 0.180905 17.9943 0.599246 19.3851 1.25503C20.7758 1.9108 22.0138 2.78141 23.0992 3.86683C21.946 4.95226 21.0245 6.19033 20.3348 7.58103C19.6451 8.97173 19.2098 10.4812 19.0289 12.1093H14.8907ZM12.1093 27C10.4812 26.8191 8.97739 26.3951 7.59799 25.728C6.21859 25.0609 4.98618 24.1847 3.90075 23.0992C5.05402 22.0138 5.9755 20.7814 6.6652 19.402C7.3549 18.0226 7.7902 16.5188 7.97111 14.8907H12.1093V27ZM14.8907 27V14.8907H19.0289C19.2098 16.5188 19.6451 18.0283 20.3348 19.419C21.0245 20.8097 21.946 22.0477 23.0992 23.1332C22.0138 24.2186 20.7758 25.0892 19.3851 25.745C17.9943 26.4008 16.4962 26.8191 14.8907 27Z"
              fill="#E8EAED"
            />
          </svg>
          <span v-if="selectedSection === 'sports' && !isCollapsed"
            >SPORTS</span
          >
        </button>
      </div>

      <div class="menu-separator"></div>
      -->
      <!-- Menu items -->
      <div class="buttons-container">
        <div v-for="item in filteredItems" :key="item.id">
          <hr v-if="item.id === 'hr'" class="custom-hr" />
          <button
            v-else
            @click="handleItemClick(item)"
            class="menu-button"
            :class="{
              selected: item.isGameCategory && activeCategory === item.type
            }"
          >
            <div class="button-data">
              <font-awesome-icon v-if="item.isGameCategory" :icon="item.icon" />
              <img
                v-else
                :src="item.icon"
                :alt="item.name"
              />
              <span v-if="!isCollapsed">{{ item.name }}</span>
            </div>
            <img
              v-if="!isCollapsed && item.route"
              class="arrow"
              src="@/assets/img/icons/right-arrow.svg"
            />
          </button>
        </div>
      </div>
    </div>
    <!-- Toggle button at the bottom -->
    <!-- <button @click="toggleCollapse" class="toggle-button">
      <img
        :src="require('@/assets/icons/right-arrow_12857695 1.svg')"
        :class="{ rotated: !isCollapsed }"
      />
    </button> -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions,mapGetters } from "vuex";
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faBorderAll, 
  faStar, 
  faDiceFive, 
  faCrown, 
  faTrophy, 
  faTv, 
  faBolt, 
  faBullseye 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { PRODUCT_TYPES } from '@/services/EVASlotsGameService';

// Add icons to library
library.add(
  faBorderAll, 
  faStar, 
  faDiceFive, 
  faCrown, 
  faTrophy, 
  faTv, 
  faBolt, 
  faBullseye
);

export default {
  name: "Menu",
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      searchTerm: "",
      items: [
        {
          id: "all-games",
          name: "All Games",
          icon: ['fas', 'border-all'],
          isGameCategory: true,
          type: "",
          gradient: 'linear-gradient(90deg, #2c5eff 0%, #1d43c0 100%)'
        },
        {
          id: "originals",
          name: "Originals",
          icon: ['fas', 'star'],
          isGameCategory: true,
          type: "originals",
          gradient: 'linear-gradient(90deg, #FFD700 0%, #FFA500 100%)'
        },
        {
          id: "slots",
          name: "Slots",
          icon: ['fas', 'dice-five'],
          isGameCategory: true,
          type: PRODUCT_TYPES.SLOTS,
          gradient: 'linear-gradient(90deg, #FF6B6B 0%, #EE5253 100%)'
        },
        {
          id: "live-casino",
          name: "Live Casino",
          icon: ['fas', 'crown'],
          isGameCategory: true,
          type: PRODUCT_TYPES.LIVE_CASINO,
          gradient: 'linear-gradient(90deg, #20BF6B 0%, #0FB855 100%)'
        },
        {
          id: "virtual-sports",
          name: "Virtual Sports",
          icon: ['fas', 'trophy'],
          isGameCategory: true,
          type: PRODUCT_TYPES.VIRTUAL_SPORTS,
          gradient: 'linear-gradient(90deg, #4B7BEC 0%, #3867D6 100%)'
        },
        {
          id: "tv-games",
          name: "TV Games",
          icon: ['fas', 'tv'],
          isGameCategory: true,
          type: PRODUCT_TYPES.TV_GAMES,
          gradient: 'linear-gradient(90deg, #A55EEA 0%, #8854D0 100%)'
        },
        {
          id: "instant-games",
          name: "Instant Games",
          icon: ['fas', 'bolt'],
          isGameCategory: true,
          type: PRODUCT_TYPES.INSTANT_GAMES,
          gradient: 'linear-gradient(90deg, #FA8231 0%, #E67E22 100%)'
        },
        {
          id: "bingo",
          name: "Bingo",
          icon: ['fas', 'bullseye'],
          isGameCategory: true,
          type: PRODUCT_TYPES.BINGO,
          gradient: 'linear-gradient(90deg, #26DE81 0%, #20C997 100%)'
        },
        { id: "hr", name: "hr" },
        {
          id: "leaderboard",
          name: "Analytics",
          route: "/leaderboard",
          icon: require("@/assets/img/sidebar/analytics-icon.svg"),
        },
        {
          id: "affiliates",
          name: "Affiliates",
          route: "/affiliates",
          icon: require("@/assets/img/sidebar/affiliates-icon.svg"),
        },
        {
          id: "rewards",
          name: "VIP",
          route: "/rewards",
          icon: require("@/assets/img/sidebar/vip-icon.svg"),
        },
        {
          id: "livesupport",
          name: "Live Support",
          route: "/support",
          icon: require("@/assets/img/sidebar/livesupport-icon.svg"),
        },
      ],
      originalGames: [
        { 
          gameId: 'mines',
          translationKey: 'Mines',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Mines.png')
        },
        { 
          gameId: 'crash',
          translationKey: 'Crash',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Crash.png')
        },
        { 
          gameId: 'blackjack',
          translationKey: 'Blackjack',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Blackjack.png')
        },
        { 
          gameId: 'towers',
          translationKey: 'Towers',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Tower.png')
        },
        { 
          gameId: 'duels',
          translationKey: 'Duels',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Dice_Duels.png')
        },
        { 
          gameId: 'coinflip',
          translationKey: 'Coinflip',
          gameProvider: 'BazedBet',
          imageUrl: require('@/assets/img/home/originalgames/Coin_Flip.png')
        }
      ],
      selectedSection: "casino",
      showCasinoSubmenu: false,
      bazedTokenData: {
        price: "0.00",
        percentageChange: "0.00",
      },
      tokenId: "bazed-games",
      currency: "usd",
    };
  },
  computed: {
    ...mapGetters('menu', ['isCollapsed']),
    ...mapState({
      authUser: (state) => state.auth.authUser,
      activeCategory: (state) => state.eva.activeCategory,
    }),
    isAuthenticated() {
      return !!this.authUser?.user;
    },
    filteredItems() {
      const term = this.searchTerm.toLowerCase();

      // Only filter by search term and hide sports from sidebar
      return this.items.filter((item) => {
        if (item.id === "hr") return true;
        if (item.id === "sports") return false; // Hide sports from sidebar
        return item.name.toLowerCase().includes(term);
      });
    },
    casinoButtonClass() {
      return {
        "casino-menu-item": true,
        selected: this.selectedSection === "casino",
      };
    },
    futuresButtonClass() {
      return {
        "secondary-menu-item": true,
        selected: this.selectedSection === "futures",
      };
    },
    sportsButtonClass() {
      return {
        "secondary-menu-item": true,
        selected: this.selectedSection === "sports",
      };
    },
  },
  methods: {
    ...mapActions('menu', ['updateCollapseState']),
    ...mapMutations("eva", ["SET_ACTIVE_CATEGORY", "SET_ALL_GAMES", "SET_TOTAL_ITEMS", "SET_DISPLAY_PAGE"]),
    ...mapActions("eva", ["setFilters"]),
    ...mapMutations(["modalsSetShow"]),
    navigateToGames() {
      this.selectedSection = "casino";
      this.updateCollapseState(true);
      this.$emit("sidebar-toggle", true);

      if (this.$route.path !== "/games") {
        this.$router.push("/games").catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });
      }
    },
    navigateTo(path, itemId) {
      if (itemId === "sports") {
        this.selectedSection = "sports";
        this.updateCollapseState(true);
        this.$emit("sidebar-toggle", true);
        this.$router.push("/sports");
      } else {
        this.$router.push(path);
      }
    },
    toggleCollapse() {
      const newState = !this.isCollapsed;
      this.updateCollapseState(newState);
      this.$emit("sidebar-toggle", newState);
    },
    navigateToOriginalGames() {
      if (
        this.$route.path !== "/games" ||
        this.activeCategory !== "Originals"
      ) {
        this.SET_ACTIVE_CATEGORY("Originals");
        if (this.$route.path !== "/games") {
          this.$router.push("/games").catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
        }
      }
    },
    showLiveSupport() {
      // Show Intercom messenger
      if (window.Intercom) {
        window.Intercom("show");
      }
    },
    getIconForItem(itemId) {
      switch (itemId) {
        case "leaderboard":
          return require("@/assets/img/sidebar/leaderboard-icon.svg");
        case "affiliates":
          return require("@/assets/img/sidebar/affiliates-icon.svg");
        case "rewards":
          return require("@/assets/img/sidebar/reward-icon.svg");
        case "profile":
          return require("@/assets/img/sidebar/profile-icon.svg");
        default:
          return require("@/assets/img/sidebar/" + itemId + "-icon.svg");
      }
    },
    navigateToFutures() {
      this.selectedSection = "futures";
      if (this.$route.path !== "/futures") {
        this.$router.push("/futures").catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });
      }
    },
    handleItemClick(item) {
      if (item.isGameCategory) {
        this.selectedSection = "casino";
        
        // First reset games and set active category
        this.$store.commit("eva/RESET_GAMES");
        this.SET_ACTIVE_CATEGORY(item.type);
        
        if (item.type === "originals") {
          // Handle originals category
          this.SET_ALL_GAMES(this.originalGames);
          this.SET_TOTAL_ITEMS(this.originalGames.length);
          this.SET_DISPLAY_PAGE(0);
        } else {
          // Handle other categories
          this.setFilters({
            productType: item.type || undefined,
            translationKey: undefined,
            gameProvider: undefined
          });
        }
        
        // Emit event to sync with EVASearchFilters
        this.$root.$emit('category-selected', item.type);

        if (this.$route.path !== "/games") {
          this.$router.push("/games");
        }
      } else if (item.id === "livesupport") {
        if (window.Intercom) {
          window.Intercom("show");
        }
      } else if (item.route) {
        this.navigateTo(item.route, item.id);
      }
    },
    async fetchBazedTokenData() {
      try {
        const response = await fetch(
          `https://api.coingecko.com/api/v3/simple/price?ids=${this.tokenId}&vs_currencies=${this.currency}&include_24hr_change=true`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (data[this.tokenId]) {
          this.bazedTokenData = {
            price: data[this.tokenId][this.currency].toFixed(4),
            percentageChange:
              data[this.tokenId][`${this.currency}_24h_change`]?.toFixed(2) ||
              "0.00",
          };
        }
      } catch (error) {
        console.error("Error fetching token data:", error);
      }
    },
  },
  created() {
    // event toggle
    this.$root.$on('toggle-sidebar', this.toggleCollapse)
  },
  mounted() {
    // Listen for category changes from EVASearchFilters
    this.$root.$on('eva-category-changed', (category) => {
      const matchingItem = this.items.find(item => item.type === category);
      if (matchingItem) {
        this.handleItemClick(matchingItem);
      }
    });

    // Ensure we're using the Vuex state
    this.$emit("sidebar-toggle", this.isCollapsed);

    // Hide Intercom messenger by default
    if (window.Intercom) {
      window.Intercom("update", { hide_default_launcher: true });
    }
    this.fetchBazedTokenData();

    // Update every 30 seconds
    setInterval(this.fetchBazedTokenData, 30000);
  },
  beforeDestroy() {
    // Clean up event listener
    this.$root.$off('toggle-sidebar', this.toggleCollapse)
  }
};
</script>

<style scoped>
.menu-container {
  display: flex;
  flex-direction: column;
  color: white;
  width: 300px;
  height: 100%;
  transition: all 0.3s ease;
  position: relative;
  border-right: 2px solid rgba(121, 121, 121, 0.19);
  background: var(--background-secondary);
  box-shadow: 0px 4px 8.9px 0px rgba(0, 0, 0, 0.13);
}

.menu-scroll-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.casino-menu-item {
  display: flex;
  gap: 12px;
  width: 48px;
  height: 48px;
  padding: 12px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0.625rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    var(--background-primary) 100%
  );
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  transition: all 0.3s ease;
}

.casino-menu-item:hover {
  filter: brightness(1.2);
}

.secondary-menu-item {
  display: flex;
  gap: 12px;
  height: 48px;
  width: 72px;
  padding: 12px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0.625rem;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    var(--background-primary) 100%
  );
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  transition: all 0.3s ease;
}

.secondary-menu-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--highlight-blue-gradient);
  opacity: 0;
  transition: all 0.3s ease;
}

.secondary-menu-item:hover::before {
  opacity: 1;
}

.secondary-menu-item svg {
  position: relative;
  z-index: 1;
}

.top-items {
  display: flex;
  gap: 6px;
  transition: all 0.3s ease;
  width: 100%;
}

/* Only apply these styles when collapsed */
.menu-container.collapsed .top-items {
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

/* Normal state styles */
.top-items button {
  display: flex;
  gap: 12px;
  height: 48px;
  padding: 12px;
  transition: all 0.3s ease;
}

.top-items button.expanded {
  width: 140px;
}

/* Collapsed state styles */
.menu-container.collapsed .top-items button {
  width: 48px !important;
  height: 48px;
  padding: 12px;
  justify-content: center;
}

.menu-container.collapsed .top-items button span {
  display: none;
}

/* Mobile styles remain unchanged */
@media (max-width: 768px) {
  .top-items {
    flex-direction: row;
    justify-content: center;
    gap: 8px;
  }

  .top-items button {
    flex: 0 0 48px;
    width: 48px !important;
    height: 48px;
  }

  .top-items button.expanded {
    width: auto !important;
    flex: 1;
  }
}

.menu-separator {
  margin: 0.5rem 0 0.25rem 0;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.08);
}

.menu-container.collapsed {
  width: 60px;
}

.menu-container.collapsed .menu-scroll-container {
  max-height: 93%;
  padding: 1rem 0.5rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.menu-scroll-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu-scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.toggle-button {
  position: fixed;
  bottom: 20px;
  align-self: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    var(--background-primary) 100%
  );
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
}

.toggle-button img {
  width: 15px;
  height: 15px;
  transition: transform 0.3s ease;
}

.toggle-button img.rotated {
  transform: rotate(180deg);
}

.buttons-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0.25rem;
  margin-bottom: 25px;
}

.collapsed .buttons-container {
  gap: 1.5rem;
  padding: 0;
  gap: 10px;
}

.buttons-container button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.buttons-container .button-data {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat, sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.buttons-container .button-data img {
  height: 1.5rem;
}

.collapsed .buttons-container .button-data {
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, var(--background-primary) 100%);
  box-shadow: 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.collapsed .buttons-container .button-data img,
.collapsed .buttons-container .button-data .svg-inline--fa {
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 0.7);
  filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.3));
}

.collapsed .menu-button {
  padding: 0;
  margin-bottom: 8px;
}

.collapsed .menu-button:hover .button-data {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, var(--background-primary) 100%);
}

.collapsed .menu-button.selected .button-data {
  background: linear-gradient(180deg, rgba(52, 157, 255, 0.1) 0%, rgba(0, 94, 233, 0.1) 100%);
  border: 1px solid rgba(52, 157, 255, 0.3);
}

.collapsed .menu-button.selected .button-data img,
.collapsed .menu-button.selected .button-data .svg-inline--fa {
  color: #349DFF;
  filter: drop-shadow(0px 0px 4px rgba(52, 157, 255, 0.5));
}

.bonus-container {
  width: 100%;
  display: flex;
  border-radius: 0.625rem;
  background: linear-gradient(
    90deg,
    rgba(var(--highlight-blue-rgb), 0.5) 0%,
    rgba(0, 0, 0, 0) 89.35%
  );
  padding: 0.8rem;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.left-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.left-content img {
  height: 2.5rem;
  transform: scaleX(-1);
  background-color: rgba(255, 255, 255, 0.08);
  padding: 0.3rem;
  border-radius: 0.4rem;
}

.left-content span {
  font-family: Montserrat, sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.right-content {
  display: flex;
  gap: 1rem;
}

.right-content img {
  opacity: 0.2;
}

.right-content div {
  display: flex;
  flex-direction: column;
}

.right-content div span:nth-child(1) {
  color: var(--highlight-aqua);
  font-family: Montserrat, sans-serif;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.right-content div span:nth-child(2) {
  color: #0075ff;
  font-family: Montserrat, sans-serif;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.collapsed .bonus-container {
  padding: 0.4rem;
  justify-content: center;
}

.collapsed .left-content img {
  height: 2rem;
}

/* Hide text elements when collapsed */
.collapsed .left-content span,
.collapsed .right-content,
.collapsed .category,
.collapsed .buttons-container span {
  display: none;
}

/* Adjust other styles to accommodate collapsed state */
.collapsed .buttons-container button {
  justify-content: center;
}

.collapsed .button-data {
  justify-content: center;
}

.collapsed .arrow {
  display: none;
}

.menu-container .category {
  font-family: Montserrat, sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1rem;
}

.custom-hr {
  margin: 12px 0;
  border: none;
  height: 1px;
  background: rgba(255, 255, 255, 0.08);
}

.buttons-container button .arrow {
  opacity: 0.4;
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0.75rem;
  gap: 0.75rem;
  border-radius: 0.625rem;
  border: 2px solid rgba(255, 255, 255, 0.08);
}

.search-box img {
  height: 1.75rem;
}

.search-box input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: rgba(255, 255, 255, 0.39);
}

.casino-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.casino-submenu {
  margin-left: 20px;
  width: 100%;
}

.casino-submenu .submenu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 0;
  text-align: left; /* Align content to the left */
}

.casino-submenu .submenu-item img {
  width: 20px;
  height: 20px;
  flex-shrink: 0; /* Prevent icon from shrinking */
}

.casino-submenu .submenu-item span {
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat, sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left; /* Ensure text is left-aligned */
}

.arrow.rotated {
  transform: rotate(90deg);
}

/* Ensure all menu items are left-aligned */
.buttons-container button,
.casino-menu-item {
  justify-content: flex-start;
}

.buttons-container button .arrow,
.casino-menu-item .arrow {
  margin-left: auto; /* Push arrow to the right */
}

/* Adjust main menu items to match submenu layout */
.buttons-container .button-data {
  width: 100%;
  justify-content: flex-start;
}

.original-games-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.original-games-submenu {
  margin-left: 20px;
  width: 100%;
}

.original-games-submenu .submenu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
}

.original-games-submenu .submenu-item:hover {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.original-games-submenu .submenu-item img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.arrow.rotated {
  transform: rotate(90deg);
}

@media (max-width: 1175px) {
  .menu-container {
    width: 60px;
    padding: 1rem 0.5rem;
  }

  .menu-container .search-box,
  .menu-container .bonus-container,
  .menu-container .category,
  .menu-container .buttons-container span,
  .menu-container .buttons-container .arrow {
    display: none;
  }

  .buttons-container {
    gap: 2rem;
    padding: 0;
  }

  .buttons-container button {
    justify-content: center;
  }

  .buttons-container .button-data {
    justify-content: center;
  }

  .buttons-container .button-data img {
    height: 1.75rem;
  }

  .toggle-button {
    position: relative;
    bottom: auto;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .menu-container {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: space-around;
    padding: 0.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--background-primary);
    z-index: 1000;
  }

  .menu-scroll-container {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .buttons-container {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    gap: 0;
  }

  .buttons-container button {
    flex: 1;
    max-width: 60px;
  }

  .buttons-container .button-data img {
    height: 1.5rem;
  }

  .toggle-button {
    display: none; /* Hide the toggle button on mobile */
  }
}

.casino-menu-item.selected {
  background: linear-gradient(90deg, #2c5eff 0%, #1d43c0 100%);
  box-shadow: 0 0 15px rgba(44, 94, 255, 0.3);
}

.secondary-menu-item.selected::before {
  opacity: 1;
}

.secondary-menu-item.selected {
  filter: brightness(1.2);
}

.secondary-menu-item.selected.futures-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #00c853 0%, #009688 100%);
  opacity: 1;
}

.secondary-menu-item.selected.sports-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #ff4081 0%, #c51162 100%);
  opacity: 1;
}

.secondary-menu-item.selected.futures-button {
  box-shadow: 0 0 15px rgba(0, 200, 83, 0.3);
}

.secondary-menu-item.selected.sports-button {
  box-shadow: 0 0 15px rgba(255, 64, 129, 0.3);
}

.secondary-menu-item svg {
  position: relative;
  z-index: 1;
}

.collapsed .top-items button {
  width: 48px !important;
  flex: 0 0 48px;
}

.collapsed .top-items button span {
  display: none !important;
}

/* Remove expanded styles when collapsed */
.collapsed .top-items button.expanded {
  width: 48px !important;
}

.collapsed .top-items button.expanded span {
  display: none !important;
}

.collapsed .top-items button {
  width: 48px !important;
  height: 48px;
  padding: 12px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  gap: 12px;
}

.collapsed .top-items button span {
  opacity: 0;
  width: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  transform: translateX(-20px);
  color: #ffffff;
}

/* Only show text when button is expanded */
.top-items button.expanded {
  width: 140px;
}

.top-items button.expanded span {
  opacity: 1;
  width: auto;
  transform: translateX(0);
  color: #ffffff;
}

/* Remove the non-collapsed menu styles since we only want text on selection */
.menu-container:not(.collapsed) .top-items button {
  width: 48px;
}

.menu-container:not(.collapsed) .top-items button span {
  opacity: 0;
  width: 0;
}

/* Keep expanded state even in non-collapsed menu */
.menu-container:not(.collapsed) .top-items button.expanded {
  width: 140px;
}

.menu-container:not(.collapsed) .top-items button.expanded span {
  opacity: 1;
  width: auto;
  transform: translateX(0);
  color: #ffffff;
}

/* SVG icon styles */
.top-items button svg {
  min-width: 24px;
  height: 24px;
  transition: all 0.3s ease;
}

/* Update specific button styles */
.casino-menu-item {
  width: 48px;
  transition: width 0.3s ease;
}

.casino-menu-item.expanded,
.menu-container:not(.collapsed) .casino-menu-item {
  width: 140px;
}

.secondary-menu-item {
  width: 48px;
  transition: width 0.3s ease;
}

.secondary-menu-item.expanded,
.menu-container:not(.collapsed) .secondary-menu-item {
  width: 140px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .top-items button {
    flex: 1;
    min-width: 48px;
    max-width: none;
    padding: 8px;
  }
}

.casino-submenu {
  margin-left: 20px;
  margin-top: 8px;
}

.casino-submenu .submenu-item {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 10px 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.casino-submenu .submenu-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.casino-submenu .submenu-item.selected {
  background: rgba(44, 94, 255, 0.1);
  border: 1px solid rgba(44, 94, 255, 0.3);
}

.casino-submenu .submenu-item img {
  width: 24px;
  height: 24px;
}

.casino-submenu .submenu-item span {
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.casino-submenu .submenu-item.selected span {
  color: #ffffff;
}

.arrow.rotated {
  transform: rotate(90deg);
}

.menu-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.menu-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.menu-button .button-data {
  display: flex;
  align-items: center;
  gap: 12px;
}

.menu-button .button-data img {
  width: 24px;
  height: 24px;
}

.menu-button .button-data span {
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.custom-hr {
  margin: 12px 0;
  border: none;
  height: 1px;
  background: rgba(255, 255, 255, 0.08);
}

.menu-button.selected {
  border-radius: 0.625rem;
  background: linear-gradient(
      0deg,
      rgba(225, 239, 255, 0) 0%,
      rgba(225, 239, 255, 0.06) 100%
    ),
    #191f2e;
  box-shadow:
    0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.menu-button.selected .button-data img {
  filter: brightness(0) saturate(100%) invert(40%) sepia(100%) saturate(2000%)
    hue-rotate(195deg) brightness(110%) contrast(110%)
    drop-shadow(0px 0px 4px rgba(52, 157, 255, 1));
}

.menu-button.selected .button-data span {
  color: #ffffff;
}

.bazedbet-container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 12px;
  background: linear-gradient(180deg, #1f2a44 0%, #181e2e 100%);
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
}

.bazedbet-container svg {
  scale: 1.2;
  display: block;
  flex-shrink: 0;
}

.token-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  border-radius: 0.75rem;
  padding: 8px 12px;
  border: 1px solid #349dff;
  background: #080e1c;
  box-shadow:
    0px 0px 8px 0px rgba(45, 111, 255, 0.5),
    0px 0px 5px 2px rgba(0, 0, 0, 0.2) inset;
}

.token-name {
  color: #e8eaed;
  font-family: Montserrat;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.token-data {
  display: flex;
  gap: 8px;
}

.token-data span {
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.token-data .price {
  color: #92969d;
  font-family: Montserrat;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.token-data .percentage {
  color: #00ffc2;
  font-family: Montserrat;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.token-data .percentage.negative {
  color: #ff4d4d;
}

/* Add collapsed state styles */
.collapsed .bazedbet-container {
  display: none;
}

.collapsed .token-container {
  display: none;
}

/* Update icon styles for Font Awesome */
.button-data .svg-inline--fa {
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 0.5);
}

.menu-button.selected .button-data .svg-inline--fa {
  color: #ffffff;
  filter: drop-shadow(0px 0px 4px rgba(52, 157, 255, 1));
}
</style>
