<template>
  <div class="crash-controls-container">
    <div class="crash-controls">
      <div class="bet-info-wrapper">
        <div class="bet-info">
          <div class="bet-info-item">
            <span>Total Bet:</span>
            <span class="bet-value">{{ crashAmount }}</span>
          </div>
          <div class="bet-info-item">
            <span>Win Chance:</span>
            <span class="bet-value">50%</span>
          </div>
        </div>
        <div class="controls-mode">
          <button
            v-on:click="crashSetMode('manual')"
            v-bind:class="{ 'button-active': crashMode === 'manual' }"
          >
            <div class="button-inner manual">
              <span>MANUAL</span>
            </div>
          </button>
          <button
            v-on:click="crashSetMode('auto')"
            v-bind:class="{ 'button-active': crashMode === 'auto' }"
          >
            <div class="button-inner auto">
              <span>AUTO</span>
            </div>
          </button>
        </div>
      </div>
      <div class="controls-input-row">
        <div class="controls-amount">
          <input
            v-model="crashAmount"
            v-on:input="crashValidateInput"
            v-on:change="crashFormatInput"
            type="text"
            placeholder="BET AMOUNT"
            v-bind:disabled="crashAutoRunning === true"
          />
        </div>
        <div class="controls-cashout">
          <input
            v-model="crashAutoCashout"
            type="text"
            placeholder="AUTO CASHOUT"
            v-bind:disabled="crashAutoRunning === true"
          />
        </div>
      </div>
      <div v-if="crashMode === 'manual'" class="controls-manual">
        <button
          v-if="
            authUser.user !== null &&
            crashGame !== null &&
            crashGame.state !== 'completed' &&
            crashBets.some(
              (element) =>
                element.user._id === authUser.user._id &&
                element.multiplier === undefined
            ) === true
          "
          v-on:click="crashBetCashout"
          class="button-cashout"
          v-bind:disabled="crashGame.state !== 'rolling'"
        >
          <div class="button-inner">
            {{ crashGame.state !== "rolling" ? "STARTING..." : "CASHOUT" }}
          </div>
        </button>
        <button v-else v-on:click="crashBetButton" class="button-bet">
          <div class="button-inner">PLACE BET</div>
        </button>
      </div>
      <div v-else class="controls-auto">
        <div class="auto-adjust">
          <div class="adjust-win">
            <input
              v-model="crashAutoPercentageWin"
              type="text"
              placeholder="% ON WIN"
              v-bind:disabled="crashAutoRunning === true"
            />
          </div>
          <div class="adjust-lose">
            <input
              v-model="crashAutoPercentageLoss"
              type="text"
              placeholder="% ON LOSS"
              v-bind:disabled="crashAutoRunning === true"
            />
          </div>
        </div>
        <div class="auto-stop">
          <div class="stop-profit">
            <input
              v-model="crashAutoStopProfit"
              type="text"
              placeholder="STOP PROFIT"
              v-bind:disabled="crashAutoRunning === true"
            />
            <img src="@/assets/img/icons/coin.svg" alt="icon" />
          </div>
          <div class="stop-lose">
            <input
              v-model="crashAutoStopLoss"
              type="text"
              placeholder="STOP LOSS"
              v-bind:disabled="crashAutoRunning === true"
            />
            <img src="@/assets/img/icons/coin.svg" alt="icon" />
          </div>
        </div>
        <div class="auto-count">
          <input
            v-model="crashAutoBetCount"
            type="text"
            placeholder="TOTAL BETS"
          />
        </div>
        <button
          v-if="crashAutoRunning === true"
          v-on:click="crashAutoStopButton"
          class="button-stop"
        >
          <div class="button-inner">STOP AUTO BETTING</div>
        </button>
        <button v-else v-on:click="crashAutoStartButton" class="button-auto">
          <div class="button-inner">START AUTO BETTING</div>
        </button>
      </div>
    </div>
    <div class="manual-bets">
      <div class="manual-bets-wrapper">
        <div class="bets-header">
          <div class="header-player">
            <span>{{ crashGetPlayerCount }}</span>
            PLAYERS
          </div>
          <div class="header-amount">
            <img src="@/assets/img/icons/coin.svg" alt="icon" />
            <div class="amount-value">
              <span>{{ crashFormatValue(crashGetBetsAmount) }}</span>
            </div>
          </div>
        </div>
        <div class="bets-content">
          <div class="content-list">
            <CrashBetElement
              v-for="bet of crashBets"
              v-bind:key="bet._id"
              v-bind:bet="bet"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import IconUsers from "@/components/icons/IconUsers";
import CrashBetElement from "@/components/crash/CrashBetElement";

export default {
  name: "CrashControls",
  components: {
    IconUsers,
    CrashBetElement,
  },
  data() {
    return {
      crashMode: "manual",
      crashAutoRunning: false,
      crashAutoInfinite: false,
      crashAmount: 10,
      crashAutoCashout: null,
      crashAutoPercentageWin: null,
      crashAutoPercentageLoss: null,
      crashAutoStopProfit: null,
      crashAutoStopLoss: null,
      crashAutoBetCount: null,
      crashAutoTotalBet: 0,
      crashAutoTotalWon: 0,
    };
  },
  methods: {
    ...mapActions([
      "notificationShow",
      "crashSendBetSocket",
      "crashSendCashoutSocket",
    ]),
    crashFormatValue(value) {
      return parseFloat(Math.floor(value / 10) / 100)
        .toFixed(2)
        .toString();
    },
    crashSetMode(mode) {
      if (mode === "manual") {
        this.crashAutoStopButton();
      }
      this.crashMode = mode;
    },
    crashValidateInput() {
      this.crashAmount = this.crashAmount
        .replace(",", ".")
        .replace(/[^\d.]/g, "");
      this.crashAmount =
        this.crashAmount.indexOf(".") >= 0
          ? this.crashAmount.substr(0, this.crashAmount.indexOf(".")) +
            "." +
            this.crashAmount
              .substr(this.crashAmount.indexOf(".") + 1, 2)
              .replace(".", "")
          : this.crashAmount;

      // Ensure the amount does not exceed 1000
      if (parseFloat(this.crashAmount) > 1000) {
        this.crashAmount = "1000.00";
      }
    },
    crashFormatInput() {
      this.crashAmount = Number(this.crashAmount).toFixed(2);
    },
    crashSetInput(value, action) {
      let amount = Math.floor(this[value] * 1000);

      if (action === "2x") {
        amount = Math.floor(amount * 2);
      } else if (action === "10x") {
        amount = Math.floor(amount * 10);
      } else if (action === "max") {
        amount =
          this.authUser.user.balance <= 1000000
            ? this.authUser.user.balance
            : 1000000;
      }

      this[value] = parseFloat(Math.floor(amount / 10) / 100).toFixed(2);
    },
    crashAutoStartButton() {
      const percentageWin = Math.floor(this.crashAutoPercentageWin);
      const percentageLoss = Math.floor(this.crashAutoPercentageLoss);

      if (
        isNaN(percentageWin) === true ||
        percentageWin < 0 ||
        percentageWin > 100
      ) {
        this.notificationShow({
          type: "error",
          message: "Your entered auto bet win percentage is invalid.",
        });
        return;
      }

      if (
        isNaN(percentageLoss) === true ||
        percentageLoss < 0 ||
        percentageLoss > 100
      ) {
        this.notificationShow({
          type: "error",
          message: "Your entered auto bet loss percentage is invalid.",
        });
        return;
      }

      if (isNaN(Math.floor(this.crashAutoStopProfit)) === true) {
        this.notificationShow({
          type: "error",
          message: "Your entered auto bet profit stop is invalid.",
        });
        return;
      }

      if (isNaN(Math.floor(this.crashAutoStopLoss)) === true) {
        this.notificationShow({
          type: "error",
          message: "Your entered auto bet loss stop is invalid.",
        });
        return;
      }

      if (isNaN(Math.floor(this.crashAutoBetCount)) === true) {
        this.notificationShow({
          type: "error",
          message: "Your entered auto bet count is invalid.",
        });
        return;
      }

      if (Math.floor(this.crashAutoBetCount) === 0) {
        this.crashAutoInfinite = true;
      }

      this.crashAutoRunning = true;
      if (this.crashGame.state === "created") {
        this.crashBetButton();
      }
    },
    crashAutoStopButton() {
      this.crashAutoTotalBet = 0;
      this.crashAutoTotalWon = 0;
      this.crashAutoInfinite = false;
      this.crashAutoRunning = false;
    },
    crashBetButton() {
      if (this.socketSendLoading !== null) {
        return;
      }

      if (this.authUser.user === null) {
        this.notificationShow({
          type: "error",
          message: "Please sign in to perform this action.",
        });
        this.crashAutoStopButton();
        return;
      }

      const amount = Math.floor(this.crashAmount * 1000);
      const autoCashout =
        this.crashAutoCashout === null || this.crashAutoCashout.trim() === ""
          ? 0
          : Math.round(this.crashAutoCashout * 100);

      if (amount === null || isNaN(amount) === true || amount <= 0) {
        this.notificationShow({
          type: "error",
          message: "Your entered bet amount is invalid.",
        });
        this.crashAutoStopButton();
        return;
      }

      if (
        autoCashout === null ||
        isNaN(autoCashout) === true ||
        ((autoCashout !== 0 || this.crashMode === "auto") && autoCashout <= 100)
      ) {
        this.notificationShow({
          type: "error",
          message: "Your entered bet auto cashout is invalid.",
        });
        this.crashAutoStopButton();
        return;
      }

      const data = { amount: amount, autoCashout: autoCashout };
      this.crashSendBetSocket(data);
    },
    crashBetCashout() {
      if (this.socketSendLoading !== null) {
        return;
      }

      if (this.authUser.user === null) {
        this.notificationShow({
          type: "error",
          message: "Please sign in to perform this action.",
        });
        return;
      }

      const data = {};
      this.crashSendCashoutSocket(data);
    },
  },
  computed: {
    ...mapGetters(["socketSendLoading", "authUser", "crashGame", "crashBets"]),
    crashGetPlayerCount() {
      let players = [];

      for (const bet of this.crashBets) {
        if (players.includes(bet.user._id) === false) {
          players.push(bet.user._id);
        }
      }

      return players.length;
    },
    crashGetBetsAmount() {
      let amount = 0;

      for (const bet of this.crashBets) {
        amount = Math.floor(amount + bet.amount);
      }

      return amount;
    },
  },
  watch: {
    crashGame: {
      handler(data, dataOld) {
        if (data.state === "created" && this.crashAutoRunning === true) {
          const profit = Math.floor(
            this.crashAutoTotalWon - this.crashAutoTotalBet
          );

          if (
            (this.crashAutoInfinite === true || this.crashAutoBetCount > 0) &&
            (Math.floor(this.crashAutoStopProfit) === 0 ||
              profit <= 0 ||
              profit < this.crashAutoStopProfit) &&
            (Math.floor(this.crashAutoStopLoss) === 0 ||
              profit >= 0 ||
              Math.abs(profit) < this.crashAutoStopLoss)
          ) {
            this.crashBetButton();
          } else {
            this.crashAutoStopButton();
          }
        }
      },
      deep: true,
    },
    crashBets: {
      deep: true,
      handler(data, dataOld) {
        const index = data.findIndex(
          (element) => element.user._id === this.authUser.user._id
        );
        if (index !== -1 && this.crashMode === "auto") {
          const bet = data[index];

          if (bet.payout !== undefined) {
            this.crashAutoTotalWon = Math.floor(
              this.crashAutoTotalWon + bet.payout
            );
          } else {
            if (Math.floor(this.crashAutoBetCount) > 0) {
              this.crashAutoBetCount = this.crashAutoBetCount - 1;
            }
            this.crashAutoTotalBet = Math.floor(
              this.crashAutoTotalBet + bet.amount
            );
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.crash-controls-container {
  height: 100%;
}

.crash-controls {
  width: 400px;
  border-radius: 30px;
  border: 5px solid rgba(255, 255, 255, 0.05);
  background: rgba(29, 33, 52, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  padding: 24px;
}

.bet-info-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 32px;
}

.bet-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bet-info-item {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
}

.bet-info-item .bet-value {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.crash-controls .controls-mode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.05);
  background: #0d1322;
}

.crash-controls .controls-mode button {
  height: 45px;
  position: relative;
  transition: all 0.3s ease;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.controls-mode button:hover {
  transition-duration: 0.1s;
}

.crash-controls .controls-mode button:active .button-inner {
  transition-duration: 0.2s;
  scale: 0.95;
}

.crash-controls .controls-mode button.button-active .button-inner {
  color: #ffffff;
  background-color: var(--highlight-blue);
}

.crash-controls .controls-mode button {
  position: relative;
  padding: 0;
  border: none;
  background: none;
}

.crash-controls .controls-mode button .button-inner {
  height: calc(100% - 2px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: all 0.1s ease;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  padding: 0 12px;
  border-radius: 10px;
}

.crash-controls .controls-input-row {
  display: block;
}

.crash-controls .controls-amount,
.crash-controls .controls-cashout {
  width: 100%;
  height: 51px;
  position: relative;
  margin-top: 20px;
}

.crash-controls .controls-amount input,
.crash-controls .controls-cashout input {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 1px;
  left: 1px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  z-index: 1;
  border-radius: 10px;
  background: #0d1322;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.crash-controls .controls-amount input::placeholder,
.crash-controls .controls-cashout input::placeholder {
  color: rgba(var(--highlight-blue-rgb), 0.5);
}

.crash-controls .amount-buttons,
.crash-controls .cashout-buttons {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  z-index: 2;
  gap: 4px;
}

.crash-controls .amount-buttons button,
.crash-controls .cashout-buttons button {
  width: 40px;
  height: 32px;
  transition: all 0.2s;
}

.cashout-buttons button:hover {
  transition-duration: 0.1s;
  opacity: 0.8;
}

.cashout-buttons button:active {
  transition-duration: 0.2s;
  scale: 0.95;
}

.crash-controls .amount-buttons button .button-inner,
.crash-controls .cashout-buttons button .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  color: var(--highlight-blue);
  border: 2px solid var(--highlight-blue);
  border-radius: 6px;
}

.crash-controls .controls-amount img {
  width: 19px;
  height: 19px;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(0, -50%);
}

.crash-controls .controls-manual {
  width: 100%;
  height: 100%;
  margin-top: 24px;
}

.crash-controls .controls-manual button.button-cashout,
.crash-controls .controls-manual button.button-bet {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  z-index: 1;
}

.button-bet:active .button-inner {
  transition-duration: 0.2s;
  scale: 0.95;
}

.crash-controls .controls-manual button.button-cashout:disabled {
  cursor: not-allowed;
}

.crash-controls .controls-manual button.button-cashout .button-inner,
.crash-controls .controls-manual button.button-bet .button-inner {
  width: 97%;
  height: 64px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  border-radius: 8px;
  font-weight: 500;
  color: #ffffff;
  background: #296eff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.1s ease;
}

.manual-bets {
  height: 50%;
  width: 100%;
  position: relative;
  margin-top: 18px;
  padding: 6px;
  z-index: 0;
}

.manual-bets .manual-bets-wrapper {
  height: 100%;
  border-radius: 12px;
  padding: 12px;
}

.bets-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: var(--highlight-blue);
}

.header-player {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  z-index: 0;
  color: #ffffff;
}

.header-player svg {
  width: 14px;
  height: 12px;
  margin-right: 8px;
  fill: var(--highlight-blue);
}

.header-player svg path {
  fill: #ac7aa5;
}

.header-player span {
  color: #ffffff;
  margin-right: 5px;
}

.header-amount {
  display: flex;
  align-items: center;
  z-index: 0;
}

.header-amount img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.amount-value {
  font-size: 12px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}

.amount-value span {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.bets-content {
  width: 100%;
  height: 50px;
  position: relative;
  margin-top: 10px;
  z-index: 0;
}

.content-list {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
}

.content-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.content-list::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.controls-auto {
  width: 100%;
  margin-top: 20px;
}

.crash-controls .auto-adjust,
.crash-controls .auto-stop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.crash-controls .auto-stop {
  margin-top: 20px;
}

.crash-controls .adjust-win,
.crash-controls .adjust-lose,
.crash-controls .stop-profit,
.crash-controls .stop-lose,
.crash-controls .auto-count {
  width: 100%;
  height: 51px;
  position: relative;
}

.crash-controls .auto-count {
  width: 100%;
  margin-top: 20px;
}

.crash-controls .adjust-win input,
.crash-controls .adjust-lose input,
.crash-controls .stop-profit input,
.crash-controls .stop-lose input,
.crash-controls .auto-count input {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 1px;
  left: 1px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background: #0d1322;
  border-radius: 10px;
  z-index: 1;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.crash-controls .stop-profit input,
.crash-controls .stop-lose input {
  padding-right: 35px;
}

.crash-controls .stop-profit img,
.crash-controls .stop-lose img {
  width: 19px;
  height: 19px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  z-index: 2;
}

.crash-controls .controls-auto button.button-auto,
.crash-controls .controls-auto button.button-stop {
  width: 100%;
  position: relative;
  margin-top: 26px;
}

.crash-controls .controls-auto button.button-auto .button-inner,
.crash-controls .controls-auto button.button-stop .button-inner {
  width: 97%;
  height: 64px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  border-radius: 8px;
  font-weight: 500;
  color: #ffffff;
  background: #296eff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.1s ease;
}

.crash-controls .controls-auto button.button-stop .button-inner {
  background: #de3b3b;
  color: #5a0000;
}

.crash-controls .adjust-win input::placeholder,
.crash-controls .adjust-lose input::placeholder,
.crash-controls .stop-profit input::placeholder,
.crash-controls .stop-lose input::placeholder,
.crash-controls .auto-count input::placeholder {
  color: rgba(var(--highlight-blue-rgb), 0.5);
}

@media only screen and (max-width: 950px) {
  .manual-bets {
    display: none;
  }
  
  .crash-controls {
    width: 100%;
    border: min(4px, 0.4vh) solid rgba(255, 255, 255, 0.05);
    padding: min(20px, 2vh);
    border-radius: min(25px, 3vh);
  }

  .bet-info-wrapper {
    gap: min(24px, 2.5vh);
  }

  .bet-info-item {
    font-size: min(11px, 1.3vh);
  }

  .bet-info-item .bet-value {
    font-size: min(13px, 1.5vh);
  }

  .crash-controls .controls-mode button {
    height: min(40px, 4.5vh);
  }

  .crash-controls .controls-mode button .button-inner {
    font-size: min(10px, 1.2vh);
    padding: 0 min(10px, 1.2vh);
  }

  .crash-controls .controls-input-row {
    display: flex;
    gap: min(16px, 1.8vh);
  }

  .crash-controls .controls-amount,
  .crash-controls .controls-cashout {
    width: 50%;
    height: min(45px, 5vh);
    margin-top: min(16px, 1.8vh);
  }

  .crash-controls .controls-amount input,
  .crash-controls .controls-cashout input,
  .crash-controls .adjust-win input,
  .crash-controls .adjust-lose input, 
  .crash-controls .stop-profit input,
  .crash-controls .stop-lose input,
  .crash-controls .auto-count input {
    font-size: min(13px, 1.5vh);
    padding: 0 min(12px, 1.4vh);
  }

  .crash-controls .controls-manual button.button-cashout .button-inner,
  .crash-controls .controls-manual button.button-bet .button-inner,
  .crash-controls .controls-auto button.button-auto .button-inner,
  .crash-controls .controls-auto button.button-stop .button-inner {
    height: min(56px, 6vh);
    font-size: min(18px, 2vh);
  }

  .crash-controls .adjust-win,
  .crash-controls .adjust-lose,
  .crash-controls .stop-profit,
  .crash-controls .stop-lose,
  .crash-controls .auto-count {
    height: min(45px, 5vh);
  }

  .crash-controls .auto-adjust,
  .crash-controls .auto-stop {
    gap: min(8px, 0.9vh);
  }

  .crash-controls .auto-stop {
    margin-top: min(16px, 1.8vh);
  }

  .crash-controls .auto-count {
    margin-top: min(16px, 1.8vh);
  }

  .crash-controls .controls-manual,
  .crash-controls .controls-auto button.button-auto,
  .crash-controls .controls-auto button.button-stop {
    margin-top: min(20px, 2.2vh);
  }
}

@media only screen and (max-width: 550px) {
  .crash-controls {
    padding: min(16px, 1.8vh);
    border-radius: min(20px, 2.5vh);
  }

  .bet-info-wrapper {
    gap: min(20px, 2.2vh);
  }

  .bet-info-item {
    font-size: min(10px, 1.2vh);
  }

  .bet-info-item .bet-value {
    font-size: min(12px, 1.4vh);
  }

  .crash-controls .controls-mode button {
    height: min(36px, 4vh);
  }

  .crash-controls .controls-amount,
  .crash-controls .controls-cashout {
    height: min(40px, 4.5vh);
    margin-top: min(14px, 1.6vh);
  }

  .crash-controls .controls-amount input,
  .crash-controls .controls-cashout input,
  .crash-controls .adjust-win input,
  .crash-controls .adjust-lose input, 
  .crash-controls .stop-profit input,
  .crash-controls .stop-lose input,
  .crash-controls .auto-count input {
    font-size: min(12px, 1.4vh);
    padding: 0 min(10px, 1.2vh);
  }

  .crash-controls .controls-manual button.button-cashout .button-inner,
  .crash-controls .controls-manual button.button-bet .button-inner,
  .crash-controls .controls-auto button.button-auto .button-inner,
  .crash-controls .controls-auto button.button-stop .button-inner {
    height: min(48px, 5.5vh);
    font-size: min(16px, 1.8vh);
  }

  .crash-controls .adjust-win,
  .crash-controls .adjust-lose,
  .crash-controls .stop-profit,
  .crash-controls .stop-lose,
  .crash-controls .auto-count {
    height: min(40px, 4.5vh);
  }

  .crash-controls .auto-adjust,
  .crash-controls .auto-stop {
    gap: min(6px, 0.7vh);
  }

  .crash-controls .auto-stop,
  .crash-controls .auto-count {
    margin-top: min(14px, 1.6vh);
  }

  .crash-controls .controls-manual,
  .crash-controls .controls-auto button.button-auto,
  .crash-controls .controls-auto button.button-stop {
    margin-top: min(16px, 1.8vh);
  }
}
</style>
