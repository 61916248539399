<template>
  <div class="navbar-cashier">
    <div class="cashier-balance" @click="modalsSetShow('Cashier')">
      <div class="balance-inner">
        <img src="@/assets/img/icons/coin.svg" alt="icon" />
        <div class="balance-wrapper">
          <div class="balance-display" :title="balanceTooltip">
            <AmountNumber v-bind:amount="totalBalance" />
            <div v-if="hasBonusBalance" class="bonus-indicator">
              <span class="bonus-plus">+</span>
            </div>
          </div>
          <div class="balance-tooltip">
            <div class="tooltip-content">
              <div class="tooltip-row">
                <span>Main Balance:</span>
                <span>${{ formatBalance(mainBalance) }}</span>
              </div>
              <div class="tooltip-row">
                <span>Bonus Balance:</span>
                <span>${{ formatBalance(bonusBalance) }}</span>
              </div>
            </div>
          </div>
        </div>
        <button class="button-add">+</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AmountNumber from "@/components/AmountNumber";
import IconCashier from "@/components/icons/IconCashier";

export default {
  name: "NavbarCashier",
  components: {
    AmountNumber,
    IconCashier,
  },
  methods: {
    ...mapActions(["modalsSetShow"]),
    formatBalance(balance) {
      return parseFloat(Math.floor(balance / 10) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    logBalances() {
      console.log('💰 Balance Details:', {
        main: {
          raw: this.mainBalance,
          formatted: this.formatBalance(this.mainBalance)
        },
        bonus: {
          raw: this.bonusBalance,
          formatted: this.formatBalance(this.bonusBalance)
        },
        total: {
          raw: this.totalBalance,
          formatted: this.formatBalance(this.totalBalance)
        },
        user: this.authUser.user
      });
    }
  },
  computed: {
    ...mapGetters(["authUser", "hasBonusBalance", "totalBalance"]),
    mainBalance() {
      return this.authUser.user?.balance || 0;
    },
    bonusBalance() {
      return this.authUser.user?.bonusBalance || 0;
    },
    balanceTooltip() {
      return `Main: $${this.formatBalance(this.mainBalance)}\nBonus: $${this.formatBalance(this.bonusBalance)}`;
    }
  },
  watch: {
    'authUser.user': {
      handler(newUser, oldUser) {
        console.log('💰 User Update:', {
          old: {
            balance: oldUser?.balance,
            bonusBalance: oldUser?.bonusBalance
          },
          new: {
            balance: newUser?.balance,
            bonusBalance: newUser?.bonusBalance
          }
        });
        this.logBalances();
      },
      deep: true
    }
  },
  mounted() {
    this.logBalances();
  }
};
</script>

<style scoped>
.navbar-cashier {
  display: flex;
  align-items: center;
}

.button-add {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 16px;
  background: var(--highlight-blue-gradient);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.34) inset;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.balance-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.balance-display {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 12px;
}

.bonus-indicator {
  position: absolute;
  top: -8px;
  right: 0;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(76, 175, 80, 0.1);
  border-radius: 50%;
}

.bonus-plus {
  color: #4CAF50;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
}

.navbar-cashier button.button-cashier {
  height: 50px;
  position: relative;
  margin-right: 15px;
  padding: 1px;
  transition: all 0.2s;
}

.button-cashier:hover {
  transition-duration: 0.2s;
  opacity: 0.8;
}

.button-cashier:active {
  transition-duration: 0.2s;
  scale: 0.9;
}

.navbar-cashier button.button-cashier .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.7rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 0.5rem;
  background: var(--highlight-yellow);
}

.navbar-cashier .cashier-balance {
  position: relative;
  padding: 1px;
  cursor: pointer;
}

.navbar-cashier .cashier-balance button.button-free {
  height: 21px;
  position: absolute;
  top: -11px;
  right: 0;
  z-index: 1;
}

.navbar-cashier .cashier-balance button.button-free .button-inner {
  display: inline-flex;
  padding: 0.3rem 0.5rem;
  align-items: center;
  gap: 0.5rem;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--highlight-blue);
  border-radius: 0.3rem;
}

.navbar-cashier .balance-inner {
  width: fit-content;
  height: 50px;
  display: flex;
  gap: 18px;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 0;
  border-radius: 0.625rem;
  background: var(--background-tertiary);
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.2) inset, 0px -2px 0px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
}

.navbar-cashier .balance-inner img {
  margin-left: 16px;
}

@media only screen and (max-width: 1175px) {
  .navbar-cashier button.button-cashier {
    height: 38px;
  }

  .navbar-cashier button.button-cashier .button-inner {
    padding: 0 13px;
  }

  .navbar-cashier button.button-cashier .button-inner svg {
    width: 14px;
  }

  .navbar-cashier button.button-cashier .button-inner svg {
    margin-right: 0;
  }

  .navbar-cashier button.button-cashier .button-inner span {
    display: none;
  }

  .navbar-cashier .cashier-balance {
    width: auto;
    height: 38px;
  }

  .navbar-cashier .cashier-balance button.button-free {
    height: 16px;
    top: -8px;
  }

  .navbar-cashier .cashier-balance button.button-free .button-inner {
    padding: 0 6px;
    font-size: 8px;
  }

  .navbar-cashier .balance-inner {
    padding: 0 12px 0 5px;
  }
}

.balance-tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: var(--background-tertiary);
  border-radius: 8px;
  padding: 12px;
  margin-top: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 100;
  min-width: 200px;
}

.balance-wrapper:hover .balance-tooltip {
  opacity: 1;
  visibility: visible;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tooltip-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
}

.tooltip-row span:first-child {
  color: #bbbfd0;
}

.balance-tooltip:before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--background-tertiary);
}
</style>
