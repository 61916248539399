// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/towers/towers-background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.towers[data-v-09c3b844] {\n  width: 100%;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 45px 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-size: cover;\n}\n.towers .towers-container[data-v-09c3b844] {\n  width: 100%;\n  display: flex;\n  flex-direction: column-reverse;\n  align-items: center;\n  justify-content: space-around;\n  padding: 20px 0;\n  border-radius: 25px;\n}\n.towers .container-game[data-v-09c3b844] {\n  width: min(100%, 550px);\n  display: flex;\n  flex-direction: column-reverse;\n  gap: 8px;\n}\n.container-game .static-row[data-v-09c3b844]{\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  padding: 0 min(2vw, 20px);\n}\n.container-game .static-row img[data-v-09c3b844]{\n  width: min(30vw, 150px);\n}\n@media only screen and (min-width: 1150px) {\n.towers .towers-container[data-v-09c3b844] {\n    flex-direction: row-reverse;\n}\n}\n@media only screen and (max-width: 950px) {\n}\n@media only screen and (max-width: 550px) {\n}\n", ""]);
// Exports
module.exports = exports;
