<template>
  <div class="rakeback-faq">
    <div class="faq-header">
      <span class="icon">❓</span>
      <h3>Frequently Asked Questions</h3>
    </div>
    
    <div class="faq-list">
      <div 
        v-for="(item, index) in faqItems" 
        :key="index"
        class="faq-item"
        :class="{ active: activeFaq === index }"
      >
        <div class="faq-question" @click="toggleFaq(index)">
          <span>{{ item.question }}</span>
          <span class="toggle-icon">{{ activeFaq === index ? '−' : '+' }}</span>
        </div>
        <transition name="fade">
          <div v-show="activeFaq === index" class="faq-answer">
            {{ item.answer }}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RakebackFAQ',
  
  data() {
    return {
      activeFaq: null,
      faqItems: [
        {
          question: "How does the tier system work?",
          answer: "Our tier system starts from Iron and progresses through Bronze, Silver, Gold, Platinum, Diamond, Master, and Legend. Each tier has five levels (I-V), and you progress based on your total wager amount. Higher tiers unlock better rakeback rates and exclusive VIP benefits."
        },
        {
          question: "How is rakeback distributed and collected?",
          answer: "Rakeback is distributed across different time periods: 40% Hourly, 30% Daily, 20% Weekly, and 10% Monthly. Each period has its own pool that you can claim separately. Make sure to collect regularly to maximize your rewards!"
        },
        {
          question: "How is the rakeback calculated and boosted?",
          answer: "Base rakeback starts at 1% for Iron, with each main tier adding 1% (Bronze 2%, Silver 3%, etc.). Within tiers, rates increase by 0.1% at I, 0.2% at II, 0.4% at III, 0.6% at IV, and 0.8% at V. Special boosts are available for specific games, providers, weekends, and events, which can multiply your rakeback earnings!"
        },
        {
          question: "What rewards and bonuses can I earn?",
          answer: "You can earn various rewards including weekly bonuses, reload boosts, and birthday rewards. Special game boosts are available for specific games, providers, or during weekends. Higher tiers get access to better boost multipliers and exclusive promotional offers."
        },
        {
          question: "What VIP benefits are available?",
          answer: "VIP benefits include Event Access (Silver+), Exclusive Events (Silver III+), Private Games (Gold III+), Custom Limits (Platinum III+), and Luxury Gifts (Diamond III+). You'll also get priority support, special boost rates, and access to unique VIP-only promotions and events."
        },
        {
          question: "How do boosts work?",
          answer: "Our boost system offers multipliers for your rakeback earnings. Boosts can be game-specific, provider-wide, or special event boosts like weekend multipliers. Different tiers may have access to different boost rates, and multiple boosts can stack for even bigger rewards!"
        },
        {
          question: "What happens if I miss a collection period?",
          answer: "Unclaimed rakeback expires after each collection period: hourly rewards expire after 24 hours, daily after 48 hours, weekly after 7 days, and monthly after 30 days. Set reminders to collect your rewards regularly to avoid missing out!"
        },
        {
          question: "Do I lose my tier progress if I stop playing?",
          answer: "Your tier is based on your total wagered amount, which doesn't decrease. However, to maintain access to certain VIP benefits and boost rates, regular activity may be required. Check the comparison table for minimum wager requirements per tier."
        },
        {
          question: "Are all games eligible for rakeback and boosts?",
          answer: "Most games are eligible for rakeback, but some games or providers might have different rates or be excluded from specific boosts. Watch for special promotions where certain games offer enhanced rakeback rates or exclusive boost multipliers!"
        },
        {
          question: "How do I track my progress and rewards?",
          answer: "Your current tier, progress, and available rewards are displayed in the Rewards dashboard. You can see your wagering progress, upcoming tier requirements, and all available claims. The comparison table shows all benefits you'll unlock as you progress."
        },
        {
          question: "What's the difference between regular and boosted periods?",
          answer: "Regular periods give you the base rakeback rate for your tier. Boosted periods (like weekends or special events) apply multipliers to your earnings. Game-specific and provider boosts can stack with these for maximum rewards!"
        },
        {
          question: "Can I get personalized VIP treatment?",
          answer: "Yes! Higher tier players (Platinum and above) receive personalized attention, custom gaming limits, and exclusive VIP host services. You'll get access to special events, custom promotions, and luxury gifts tailored to your preferences."
        }
      ]
    }
  },
  
  methods: {
    toggleFaq(index) {
      this.activeFaq = this.activeFaq === index ? null : index;
    }
  }
}
</script>

<style scoped>
.rakeback-faq {
  margin-top: 30px;
  background: #1A1F37;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
}

.faq-header {
  background: #1E2443;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  gap: 12px;
}

.faq-header .icon {
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(44, 94, 255, 0.1);
  border-radius: 10px;
}

.faq-header h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 800;
  background: linear-gradient(180deg, #FFFFFF 0%, #B8C7FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
}

.faq-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-item:hover {
  background: rgba(44, 94, 255, 0.1);
  transform: translateY(-1px);
  box-shadow: 0 6px 16px rgba(44, 94, 255, 0.1);
}

.faq-question {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  color: #FFFFFF;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
}

.faq-question:hover {
  background: rgba(44, 94, 255, 0.1);
}

.toggle-icon {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  transition: all 0.2s ease;
}

.faq-item.active .toggle-icon {
  color: #2C5EFF;
}

.faq-answer {
  padding: 0 20px 16px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
  max-height: 200px;
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  max-height: 0;
  opacity: 0;
  transform: translateY(-10px);
}
</style> 