const state = {
  balance: 0,
  // ... other user state
};

const mutations = {
  UPDATE_BALANCE(state, balance) {
    state.balance = balance;
  },
  // ... other mutations
};

const actions = {
  updateBalance({ commit }, balance) {
    commit('UPDATE_BALANCE', balance);
  },
  // ... other actions
}; 