<template>
  <div class="modal-coinflip-game">
    <div
      v-if="duelsGameData.loading !== true && duelsGameData.game !== null"
      class="game-content"
    >
      <DuelsGameHeader v-bind:duelsGame="duelsGame" />
      <!--<DuelsGameStats v-bind:duelsGame="duelsGame" />-->
      <CoinflipGameRoller v-bind:coinflipGame="duelsGame" />
      <DuelsGameWinner v-bind:duelsGame="duelsGame" />
      <DuelsGameFair v-bind:duelsGame="duelsGame" />
    </div>
    <DuelsGameUsers
      v-if="duelsGameData.loading !== true && duelsGameData.game !== null"
      v-bind:duelsGame="duelsGame"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DuelsGameHeader from "@/components/coinflip/DuelsGameHeader";
import DuelsGameStats from "@/components/coinflip/DuelsGameStats";
import CoinflipGameRoller from "@/components/coinflip/CoinflipGameRoller";
import DuelsGameWinner from "@/components/coinflip/DuelsGameWinner";
import DuelsGameFair from "@/components/coinflip/DuelsGameFair";
import DuelsGameUsers from "@/components/coinflip/DuelsGameUsers";

export default {
  name: "ModalDuelsGame",
  components: {
    DuelsGameHeader,
    DuelsGameStats,
    CoinflipGameRoller,
    DuelsGameWinner,
    DuelsGameFair,
    DuelsGameUsers,
  },
  data() {
    return {
      duelsGame: null,
    };
  },
  methods: {
    duelsStartGame(step) {
      let game = JSON.parse(JSON.stringify(this.duelsGameData.game));

      for (let i = 0; i < this.duelsGameData.game.bets.length; i++) {
        if (i > step) {
          delete game.bets[i].roll;
        }
      }

      if (step <= this.duelsGameData.game.bets.length - 1) {
        delete game.winner;

        const left =
          new Date(this.duelsGameData.game.updatedAt).getTime() +
          this.generalTimeDiff +
          (this.duelsFilterAnimation === "fast" ? 3000 : 5000) * (step + 1) -
          (new Date().getTime() + this.generalTimeDiff);
        setTimeout(() => {
          this.duelsStartGame(step + 1);
        }, left);
      }

      this.duelsGame = game;
    },
  },
  computed: {
    ...mapGetters(["generalTimeDiff", "duelsFilterAnimation", "duelsGameData"]),
  },
  watch: {
    duelsGameData: {
      deep: true,
      handler() {
        if (
          this.duelsGameData.loading !== true &&
          this.duelsGameData.game !== null
        ) {
          if (this.duelsGameData.game.state === "rolling") {
            this.duelsStartGame(0);
          } else {
            this.duelsGame = this.duelsGameData.game;
          }
        }
      },
    },
  },
  created() {
    if (
      this.duelsGameData.loading !== true &&
      this.duelsGameData.game !== null
    ) {
      if (this.duelsGameData.game.state === "rolling") {
        let step = Math.floor(
          (new Date().getTime() +
            this.generalTimeDiff -
            (new Date(this.duelsGameData.game.updatedAt).getTime() +
              this.generalTimeDiff)) /
            (this.duelsFilterAnimation === "fast" ? 3000 : 5000)
        );
        step = step <= 0 ? 0 : step;

        this.duelsStartGame(step);
      } else {
        this.duelsGame = this.duelsGameData.game;
      }
    }
  },
};
</script>

<style scoped>
.modal-coinflip-game {
  width: 1210px;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 12px;
  border-radius: 16px;
  background-image: url("../../assets/img/coinflip/coinflip-background.png");
  background-size: cover;
  background-position: center;
  box-shadow:
    0px 2px 0px 0px rgba(255, 255, 255, 0.2) inset,
    0px -2px 0px 0px rgba(0, 0, 0, 0.25) inset;
}

.modal-coinflip-game .game-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 13px 325px 13px 13px;
}

@media only screen and (max-width: 1230px) {
  .modal-coinflip-game {
    width: calc(100vw - 20px);
  }
}

@media only screen and (max-width: 1050px) {
  .modal-coinflip-game .game-content {
    width: 100%;
    padding: 8px 0 325px 0;
  }
}
</style>
