import axios from 'axios'

const API_BASE_URL = process.env.VUE_APP_BACKEND_URL;

export default {
  async getAvailableCurrencies() {
    try {
      console.log('Fetching available currencies');
      const response = await axios.get(`${API_BASE_URL}/api/nowpayments/full-currencies`);
      return response.data;
    } catch (error) {
      console.error('Get currencies error:', error);
      throw error;
    }
  },

  async getEstimatedPrice(amount, fromCurrency, toCurrency) {
    try {
      console.log('Getting estimate for:', {
        amount,
        fromCurrency,
        toCurrency
      });

      // First get the estimate from USD to crypto
      const response = await axios.get(`${API_BASE_URL}/api/nowpayments/estimate`, {
        params: {
          amount: parseFloat(amount).toFixed(6),
          currency_from: fromCurrency.toLowerCase(),
          currency_to: toCurrency.toLowerCase()
        }
      });

      if (!response.data || typeof response.data.estimated_amount === 'undefined') {
        throw new Error('Invalid estimate response');
      }

      return {
        currency_from: response.data.currency_from,
        currency_to: response.data.currency_to,
        estimated_amount: response.data.estimated_amount
      };
    } catch (error) {
      console.error('Get estimate error:', error);
      throw error;
    }
  },

  async createInvoice(paymentData) {
    try {
      if (!paymentData || !paymentData.price_amount || isNaN(paymentData.price_amount)) {
        throw new Error('Invalid payment data');
      }

      // Ensure minimum amount
      const minAmount = await this.getMinimumPaymentAmount(paymentData.pay_currency);
      if (paymentData.price_amount < minAmount.min_amount) {
        throw new Error(`Amount must be at least ${minAmount.min_amount} USD`);
      }

      // Format payload
      const payload = {
        price_amount: parseFloat(paymentData.price_amount),
        price_currency: 'usd',
        pay_currency: paymentData.pay_currency.toLowerCase(),
        currency_to: 'usdttrc20',  // Always convert to USDTTRC20
        order_id: paymentData.order_id || `order_${Date.now()}`,
        order_description: paymentData.order_description || `Deposit ${paymentData.price_amount} USD`,
        is_fixed_rate: false,
        is_fee_paid_by_user: false
      };

      console.log('Creating payment with payload:', payload);

      const response = await axios.post(`${API_BASE_URL}/api/nowpayments/payment`, payload);
      
      if (!response.data || !response.data.payment_id) {
        throw new Error('Invalid payment response');
      }

      return response.data;
    } catch (error) {
      console.error('Create payment error:', error);
      throw error;
    }
  },

  async getPaymentStatus(paymentId) {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/nowpayments/payment/${paymentId}`);
      return response.data;
    } catch (error) {
      console.error('Get payment status error:', error);
      throw error;
    }
  },

  async getMinimumPaymentAmount(currency_from) {
    try {
      console.log('Requesting minimum amount for:', currency_from);
      
      const response = await axios.get(`${API_BASE_URL}/api/nowpayments/min-amount`, {
        params: {
          currency_from: currency_from.toLowerCase(),
          currency_to: 'usdttrc20',
          fiat_equivalent: 'usd',
          is_fixed_rate: true,
          is_fee_paid_by_user: true
        }
      });

      console.log('Minimum amount response:', response.data);

      if (!response.data || typeof response.data.min_amount === 'undefined') {
        console.error('Invalid response format:', response.data);
        throw new Error('Invalid response format');
      }

      return {
        currency_from: response.data.currency_from,
        currency_to: response.data.currency_to,
        min_amount: response.data.min_amount,
        fiat_equivalent: response.data.fiat_equivalent
      };
    } catch (error) {
      console.error('Get minimum amount error:', {
        error: error.message,
        response: error.response?.data,
        status: error.response?.status,
        currency: currency_from
      });

      throw error;
    }
  },

  async getTransactionHistory(page = 1, limit = 10) {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/nowpayments/transactions`, {
        params: { page, limit }
      });
      return response.data;
    } catch (error) {
      console.error('Get transaction history error:', error);
      throw error;
    }
  },

  async getMinimumWithdrawalAmount(currency) {
    try {
      console.log('Getting minimum withdrawal amount for:', currency);
      
      const response = await axios.get(`${API_BASE_URL}/api/nowpayments/min-withdrawal-amount/${currency.toLowerCase()}`);
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to get minimum amount');
      }

      return {
        currency: response.data.currency,
        min_amount: response.data.min_amount
      };
    } catch (error) {
      console.error('Get minimum withdrawal amount error:', error);
      // Return default minimum amounts if API fails
      const defaultMinAmounts = {
        btc: 0.00002496,
        eth: 0.01,
        usdt: 10,
        trx: 100,
        default: 10
      };
      
      return {
        currency: currency.toLowerCase(),
        min_amount: defaultMinAmounts[currency.toLowerCase()] || defaultMinAmounts.default
      };
    }
  },

  async createWithdrawal(withdrawalData) {
    try {
      if (!withdrawalData || !withdrawalData.amount || isNaN(withdrawalData.amount)) {
        throw new Error('Invalid withdrawal data');
      }

      // Ensure minimum amount
      const minAmount = await this.getMinimumWithdrawalAmount(withdrawalData.currency);
      if (withdrawalData.amount < minAmount.min_amount) {
        throw new Error(`Amount must be at least ${minAmount.min_amount} USD`);
      }

      // Format payload
      const payload = {
        amount: parseFloat(withdrawalData.amount),
        currency_from: 'usdttrc20',
        currency_to: withdrawalData.currency.toLowerCase(),
        address: withdrawalData.address,
        is_fixed_rate: true,
        is_fee_paid_by_user: true
      };

      console.log('Creating withdrawal with payload:', payload);

      const response = await axios.post(`${API_BASE_URL}/api/nowpayments/withdraw`, payload);
      
      if (!response.data || !response.data.withdrawal_id) {
        throw new Error('Invalid withdrawal response');
      }

      return response.data;
    } catch (error) {
      console.error('Create withdrawal error:', error);
      throw error;
    }
  },

  async validateAddress(currency, address, extraId = null) {
    try {
      console.log('Validating address:', { currency, address });
      
      const response = await axios.post(`${API_BASE_URL}/api/nowpayments/validate-address`, {
        currency: currency.toLowerCase(),
        address,
        extra_id: extraId
      });

      // Check if response is OK
      if (response.data === 'OK' || response.data.valid) {
        return {
          valid: true,
          message: 'Address is valid'
        };
      }

      return {
        valid: false,
        message: response.data.message || 'Invalid address'
      };
    } catch (error) {
      console.error('Address validation error:', error);
      return {
        valid: false,
        message: error.response?.data?.message || 'Failed to validate address'
      };
    }
  },

  async createPayout(withdrawalData) {
    try {
      // Log the complete request data
      console.log('Creating payout - Complete Request:', {
        url: 'https://api.nowpayments.io/v1/payout', // Direct to NOWPayments API
        headers: {
          'Authorization': 'Bearer {{token}}', // This should come from backend
          'x-api-key': '{{api-key}}', // This should come from backend
          'Content-Type': 'application/json'
        },
        body: withdrawalData,
        timestamp: new Date().toISOString()
      });

      // Still use our backend as proxy for security
      const response = await axios.post(`${API_BASE_URL}/api/nowpayments/payout`, withdrawalData);
      
      console.log('Payout Response:', {
        status: response.status,
        data: response.data,
        timestamp: new Date().toISOString()
      });

      return response.data;
    } catch (error) {
      console.error('Create payout error:', error);
      throw error;
    }
  },

  async getPayoutStatus(payoutId) {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/nowpayments/payout/${payoutId}`);
      return response.data;
    } catch (error) {
      console.error('Get payout status error:', error);
      throw error;
    }
  },

  async getWithdrawalFeeEstimate(currency, amount) {
    try {
      console.log('Getting withdrawal fee estimate:', { 
        currency: currency.toUpperCase(), 
        amount: parseFloat(amount).toFixed(8) 
      });
      
      // Make request exactly like the example
      const response = await axios.get(`${API_BASE_URL}/api/nowpayments/payout/fee`, {
        params: {
          currency: currency.toUpperCase(), // Example: 'BTC', 'ETH', etc.
          amount: parseFloat(amount).toFixed(8) // The crypto amount, not USD
        }
      });

      return {
        currency: response.data.currency,
        fee: parseFloat(response.data.fee)
      };
    } catch (error) {
      console.error('Failed to get withdrawal fee estimate:', error);
      return {
        currency: currency.toUpperCase(),
        fee: parseFloat(amount) * 0.005,
        isEstimate: true
      };
    }
  },

  async verifyPayout(withdrawalId, verificationCode) {
    try {
        console.log('Verifying payout:', { withdrawalId });
        
        const response = await axios.post(
            `${API_BASE_URL}/api/nowpayments/payout/${withdrawalId}/verify`,
            { verification_code: verificationCode }
        );

        return {
            success: true,
            message: response.data.message
        };
    } catch (error) {
        console.error('Payout verification error:', error);
        throw error;
    }
  },

}