<template>
  <button
    v-on:click="rollInfoButton"
    class="roll-history-element"
    v-bind:class="{
      'element-high': rollGetOutcome >= 20,
      'element-mid': rollGetOutcome < 20 && rollGetOutcome >= 5,
      'element-low': rollGetOutcome < 5 && rollGetOutcome >= 2,
    }"
  >
    <div class="element-inner">
      <span>{{ rollGetOutcome }}x</span>
    </div>
  </button>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RollHistoryElement",
  props: ["game"],
  methods: {
    ...mapActions(["modalsSetData", "modalsSetShow"]),
    rollInfoButton() {
      this.modalsSetData({ game: this.game });
      this.modalsSetShow("FairGame");
    },
  },
  computed: {
    rollGetOutcome() {
      return parseFloat(this.game.outcome / 100).toFixed(2);
    },
  },
};
</script>

<style scoped>
button.roll-history-element {
  width: 70px;
  height: 35px;
  position: relative;
  flex-shrink: 0;
  margin-right: 4px;
  transition: all 0.2s;
  border-radius: 100px;
  border: 1px solid transparent;
  overflow: hidden;
}
.roll-history-element:hover {
  transition-duration: 0.2s;
  opacity: 0.7;
  transform: translateY(-2px);
}
.roll-history-element:active {
  transition-duration: 0.2s;
  transform: translateY(2px);
}

button.roll-history-element:last-of-type {
  margin-right: 0;
}

button.roll-history-element.element-high {
  border-color: #de4422;
}

button.roll-history-element.element-mid {
  border-color: var(--highlight-yellow);
}

button.roll-history-element.element-low {
  border-color: var(--green-gradient);
}

button.roll-history-element .element-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: #242937;
  border-radius: 6px;
}

button.roll-history-element .element-inner span {
  font-size: 14px;
  font-weight: 700;
  color: #47516a;
}

button.roll-history-element.element-high .element-inner span {
  background: #f84e4e;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

button.roll-history-element.element-mid .element-inner span {
  background: #fca311;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

button.roll-history-element.element-low .element-inner span {
  background: #00ff94;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
</style>
