<template>
    <div class="card">
        <div class="card-header">
            <p class="card-title">{{ title }}</p>
            <div class="info-logo">
                <img src="@/assets/icons/info.svg" alt="info" />
            </div>
        </div>
        <p class="card-data">${{ label }}</p>
        <div class="fire-logo">
            <img src="@/assets/icons/fire.svg" alt="fire" />
            <p class="card-percentage">{{ percentage }}%</p>
        </div>
        <div class="card-chart">
            <div class="chart-container">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BuybackRevenueCard",
    props: {
        title: {
            type: String,
            required: true,
        },
        label: {
            type: Number,
            required: true,
        },
        percentage: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style scoped>
.card {
    width: 100%;
    height: 356px;
    padding: 30px 30px 20px 30px;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(46, 99, 212, 0.00) 0%, rgba(46, 99, 212, 0.15) 100%), #10182B;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px -1px 0px 0px rgba(0, 0, 0, 0.73) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset;
    min-width: 330px;
}

.card-header {
    display: flex;
    justify-content: space-between;
}

.card-title,
.card-data,
.card-percentage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.card-title {
    color: #92969D;
    font-size: 16px;
}

.card-data {
    flex-shrink: 0;
    color: #FFF;
    font-size: 24px;
}

.card-percentage {
    color: var(--primary-color, #FF571B);
    font-size: 10px;
}

.card-chart {
    width: 100%;
    height: 212px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #92969D;
    background: #081122;
}

.chart-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.info-logo {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    cursor: pointer;
}

.fire-logo {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    width: 7px;
    height: 9.8px;
}
</style>