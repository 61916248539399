// Product Types
export const PRODUCT_TYPES = {
  SLOTS: 'slots',
  VIRTUAL_SPORTS: 'virtual-sports',
  TV_GAMES: 'tv-games',
  LIVE_CASINO: 'live-casino',
  INSTANT_GAMES: 'instant-games',
  BINGO: 'bingo'
};

// Channels
export const CHANNELS = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  OMNI: 'omni'
};

// Game Categories
export const CATEGORIES = {
  POPULAR: 'popular',
  NEW: 'new',
  SLOTS: 'slots',
  JACKPOT: 'jackpot',
  BONUS_BUY: 'bonus_buy',
  MEGAWAYS: 'megaways'
};

// Game Features
export const FEATURES = {
  FREE_SPINS: 'free_spins',
  BONUS_GAME: 'bonus_game',
  MULTIPLIER: 'multiplier',
  WILD: 'wild',
  SCATTER: 'scatter',
  GAMBLE: 'gamble'
};

// Volatility Levels
export const VOLATILITY = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high'
};

// Default Values
export const DEFAULTS = {
  LANGUAGE: 'en',
  CURRENCY: 'USD',
  PAGE_SIZE: 20,
  CHANNEL: CHANNELS.DESKTOP
};

export const EVA_PROVIDERS = [
  "1gamehub-caletagaming",
  "1gamehub-elbet-ig",
  "1gamehub-funta",
  "1gamehub-tpg",
  "1spin4win",
  "5mengames",
  "7mojos",
  "alea-felix-gaming",
  "alea-rival-gaming",
  "amigo",
  "andromeda",
  "atmosfera-lc",
  "belatragames",
  "beterlive-lc",
  "betgames",
  "betsoft",
  "bfgames",
  "champion",
  "ctgaming",
  "digitain-galaxsys",
  "dlv",
  "endorphina",
  "eva-lc-pragmatic",
  "evenbet",
  "f1x2",
  "fired-up-games",
  "fugaso",
  "gameart",
  "gamebeat",
  "gaming-corps-hub",
  "gamzix",
  "ggames",
  "habanero",
  "inbet",
  "infingame-7777gaming",
  "infingame-bet2tech",
  "infingame-eagaming",
  "infingame-enjoygaming",
  "infingame-matrixgames",
  "infingame-playbro",
  "jili",
  "kalamba-games",
  "kiron",
  "live-solutions-lc",
  "lucky-elephant",
  "mancala",
  "mplay",
  "mrslotty-kagaming",
  "mrslotty-mrslotty",
  "netgame",
  "nolimitcity",
  "onetouch",
  "onlyplay",
  "pateplay",
  "pgsoft",
  "pragmatic",
  "redrake",
  "scorum-aviatrix-ig",
  "smartsoft",
  "softgamings-asiagaming-live-casino",
  "softgamings-authenticgaming-live-casino",
  "softgamings-luckystreak-live-casino",
  "softgamings-xprogaming-live-casino",
  "softgamings-yggdrasil",
  "spadegaming",
  "spinomenal",
  "split-the-pot",
  "spribe",
  "superlotto",
  "swintt",
  "tada",
  "thunderkick",
  "thunderspin",
  "tomhorn",
  "tomhorn-alg-lc",
  "topspin",
  "turbogames",
  "uranus-vivogames-lc",
  "wdc-ig",
  "winfinity",
  "zillion"
];

export const PREFERRED_LIVE_PROVIDERS = [
  "eva-lc-pragmatic",
  "beterlive-lc",
  "infingame-enjoygaming",
  "softgamings-authenticgaming-live-casino",
  "softgamings-asiagaming-live-casino",
  "softgamings-luckystreak-live-casino",
  "softgamings-xprogaming-live-casino",
  "atmosfera-lc",
  "live-solutions-lc",
  "tomhorn-alg-lc",
  "uranus-vivogames-lc"
];

export const PREDEFINED_LIVE_GAMES = [
  {
    gameId: 'eva-lc-pragmatic-sweet-bonanza-candyland',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Sweet Bonanza Candyland'
  },
  {
    gameId: 'eva-lc-pragmatic-sweet-bonanza-candyland',
    provider: 'pragmatic',
    translationKey: 'Sweet Bonanza Candyland'
  },
  {
    gameId: 'eva-lc-pragmatic-vegas-ball-bonanza',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Vegas Ball Bonanza'
  },
  {
    gameId: 'eva-lc-pragmatic-vegas-ball-bonanza',
    provider: 'pragmatic',
    translationKey: 'Vegas Ball Bonanza'
  },
  {
    gameId: 'eva-lc-pragmatic-blackjack-24--azure',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Blackjack 24 - Azure'
  },
  {
    gameId: 'eva-lc-pragmatic-andar-bahar',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Andar Bahar'
  },
  {
    gameId: 'eva-lc-pragmatic-auto-mega-roulette',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Auto Mega Roulette'
  },
  {
    gameId: 'eva-lc-pragmatic-blackjack-x-1--azure',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Blackjack X 1 - Azure'
  },
  {
    gameId: 'eva-lc-pragmatic-boom-city',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Boom City'
  },
  {
    gameId: 'beterlive-lc-crash-live',
    provider: 'beterlive-lc',
    translationKey: 'Crash Live'
  },
  {
    gameId: 'eva-lc-pragmatic-dragon-tiger',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Dragon Tiger'
  },
  {
    gameId: 'beterlive-lc-gravity-sic-bo',
    provider: 'beterlive-lc',
    translationKey: 'Gravity Sic Bo'
  },
  {
    gameId: 'eva-lc-pragmatic-megawheel-801',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Mega Wheel'
  },
  {
    gameId: 'eva-lc-pragmatic-rouletteazure-227',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Roulette Azure'
  },
  {
    gameId: 'eva-lc-pragmatic-snakes-and-ladders',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Snakes and Ladders'
  },
  {
    gameId: 'eva-lc-pragmatic-speed-baccarat-17',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Speed Baccarat 17'
  },
  {
    gameId: 'eva-lc-pragmatic-super-trunfo',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Super Trunfo'
  },
  {
    gameId: 'eva-lc-pragmatic-treasure-island',
    provider: 'eva-lc-pragmatic',
    translationKey: 'Treasure Island'
  },
  {
    gameId: 'infingame-enjoygaming-x777-roulette-with-maria-lc',
    provider: 'infingame-enjoygaming',
    translationKey: 'x777 Roulette with Maria'
  }
];

export const PREFERRED_SLOTS_PROVIDERS = [
  "1spin4win",
  "pragmatic",
  "fugaso",
  "f1x2",
  "spinomenal",
  "betsoft",
  "nolimitcity",
  "gameart",
  "habanero",
  "pgsoft"
];

export const PREDEFINED_SLOTS_GAMES = [
  {
    gameId: 'pragmatic-sweet-bonanza-1000',
    provider: 'pragmatic',
    translationKey: 'Sweet Bonanza'
  },
  {
    gameId: 'pragmatic-big-bass-bonanza-megaways',
    provider: 'pragmatic',
    translationKey: 'Big Bass Bonanza Megaways'
  },
  {
    gameId: 'pragmatic-vswayswerewolf',
    provider: 'pragmatic',
    translationKey: 'Werewolf'
  },
  {
    gameId: '1spin4win-mega-lucky-pot',
    provider: '1spin4win',
    translationKey: 'Mega Lucky Pot'
  },
  {
    gameId: 'pragmatic-vs576treasures',
    provider: 'pragmatic',
    translationKey: 'Wild Wild Riches'
  },
  {
    gameId: 'f1x2-sweet-candy-cash-megaways-deluxe',
    provider: 'f1x2',
    translationKey: 'Sweet Candy Cash Megaways Deluxe'
  },
  {
    gameId: 'spinomenal-book-of-justice--athenas-glory',
    provider: 'spinomenal',
    translationKey: 'Book of Justice - Athenas Glory'
  },
  {
    gameId: 'f1x2-gods-of-asgard-megaways_2',
    provider: 'f1x2',
    translationKey: 'Gods of Asgard Megaways'
  },
  {
    gameId: 'spinomenal-zeus-unchained---hold--hit',
    provider: 'spinomenal',
    translationKey: 'Zeus Unchained - Hold & Hit'
  }
];

export const PREDEFINED_PROVIDER_OF_WEEK_GAMES = [
  {
    gameId: 'pragmatic-zeus-vs-hades--gods-of-war',
    provider: 'pragmatic',
    translationKey: 'Zeus vs Hades - Gods of War'
  },
  {
    gameId: 'pragmatic-gates-of-olympus-1000',
    provider: 'pragmatic',
    translationKey: 'Gates of Olympus'
  },
  {
    gameId: 'pragmatic-starlight-princess-1000',
    provider: 'pragmatic',
    translationKey: 'Starlight Princess'
  },
  {
    gameId: 'pragmatic-power-of-merlin-megaways',
    provider: 'pragmatic',
    translationKey: 'Power of Merlin Megaways'
  },
  {
    gameId: 'pragmatic-the-money-man-megaways',
    provider: 'pragmatic',
    translationKey: 'The Money Man Megaways'
  },
  {
    gameId: 'pragmatic-wild-beach-party',
    provider: 'pragmatic',
    translationKey: 'Wild Beach Party'
  },
  {
    gameId: 'pragmatic-vs25wolfgold',
    provider: 'pragmatic',
    translationKey: 'Wolf Gold'
  },
  {
    gameId: 'pragmatic-heart-of-cleopatra',
    provider: 'pragmatic',
    translationKey: 'Heart of Cleopatra'
  },
  {
    gameId: 'pragmatic-5-lions-megaways',
    provider: 'pragmatic',
    translationKey: '5 Lions Megaways'
  },
  {
    gameId: 'pragmatic-big-bass-vegas-double-down-deluxe',
    provider: 'pragmatic',
    translationKey: 'Big Bass Vegas Double Down Deluxe'
  },
  {
    gameId: 'pragmatic-vswaysbufking',
    provider: 'pragmatic',
    translationKey: 'Buffalo King'
  }
]; 