# Create new file
<template>
  <div class="game-view">
    <div v-if="error" class="error-container">
      <div class="error-message">
        {{ error }}
        <button @click="goBack" class="back-button">
          Back to Games
        </button>
      </div>
    </div>
    
    <template v-else>
      <div class="game-header">
        <button @click="goBack" class="back-button">
          <i class="fas fa-arrow-left"></i>
          Back to Games
        </button>
        <h1 class="game-title">{{ gameTitle }}</h1>
      </div>

      <div v-if="loading" class="loading">
        <div class="loader"></div>
        <p>Loading game...</p>
      </div>

      <div v-else-if="gameUrl" class="game-container">
        <iframe
          :src="gameUrl"
          class="game-frame"
          frameborder="0"
          allowfullscreen
          @load="handleGameLoad"
          ref="gameFrame"
        ></iframe>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'EVAGameView',

  props: {
    gameId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      error: null,
      loading: false,
      gameUrl: null,
      gameFrame: null,
      currentGame: null,
      sessionToken: null
    }
  },

  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
      currentUser: state => state.auth.user,
      storeGame: state => state.eva.currentGame
    }),

    gameTitle() {
      return this.currentGame?.name || this.storeGame?.name || 'Loading Game...'
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  },

  methods: {
    ...mapActions('eva', ['fetchGame', 'launchGame']),

    goBack() {
      this.$router.push('/games')
    },

    restoreGameSession() {
      const stored = sessionStorage.getItem('eva_current_game')
      if (stored) {
        try {
          const session = JSON.parse(stored)
          // Check if session is valid and matches current game
          if (session.gameId === this.gameId && 
              Date.now() - session.timestamp < 15 * 60 * 1000) {
            this.currentGame = {
              gameId: session.gameId,
              name: session.name,
              provider: session.provider
            }
            this.gameUrl = session.launchUrl
            this.sessionToken = session.sessionToken
            this.loading = false
            return true
          }
        } catch (e) {
          console.error('[EVAGameView] Failed to restore session:', e)
        }
        // Clean up invalid session
        this.cleanupGameSession()
      }
      return false
    },

    cleanupGameSession() {
      sessionStorage.removeItem('eva_current_game')
      if (this.sessionToken) {
        // Notify backend to cleanup session
        this.$store.dispatch('eva/endSession', this.sessionToken).catch(console.error)
      }
    },

    async initGame() {
      try {
        this.loading = true
        this.error = null

        // Try to load from session storage first
        if (this.restoreGameSession()) {
          return
        }

        // Fetch game details first
        await this.fetchGame(this.gameId)

        // Launch the game
        const result = await this.launchGame({
          gameId: this.gameId,
          playerId: this.currentUser?.id,
          options: {
            demo: !this.isAuthenticated,
            language: 'en',
            iframe: true,
            channel: this.isMobile ? 'mobile' : 'desktop',
            returnUrl: window.location.origin + '/games'
          }
        })

        this.gameUrl = result.url
        this.sessionToken = result.sessionToken
        this.currentGame = this.storeGame

      } catch (err) {
        console.error('[EVAGameView] Launch error:', err)
        this.error = err.message || 'Failed to launch game'
      } finally {
        this.loading = false
      }
    },

    handleGameLoad() {
      this.loading = false
      // Add message listener for game events
      window.addEventListener('message', this.handleGameMessage)
    },

    handleGameMessage(event) {
      // Verify origin matches game URL
      if (this.gameUrl && !event.origin.includes(new URL(this.gameUrl).origin)) {
        return
      }

      const { type, data } = event.data
      console.log('[EVAGameView] Game message:', { type, data })

      switch (type) {
        case 'game:loaded':
          this.loading = false
          break
        case 'game:error':
          this.error = data.message
          break
        case 'game:exit':
          this.goBack()
          break
      }
    }
  },

  async created() {
    await this.initGame()
  },

  beforeDestroy() {
    window.removeEventListener('message', this.handleGameMessage)
    this.cleanupGameSession()
  }
}
</script>

<style scoped>
.game-view {
  width: 100%;
  height: 100vh;
  background: #000;
  color: white;
  display: flex;
  flex-direction: column;
}

.game-header {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.1);
}

.game-title {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background: #9333ea;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

.back-button:hover {
  background: #7e22ce;
}

.game-container {
  flex: 1;
  position: relative;
}

.game-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.loading {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.loader {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: #9333ea;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.error-message {
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
</style> 