<template>
    <button v-on:click="modalsSetShow('Login')" class="login-btn">
        <div class="button-inner">
            Login
        </div>
    </button>

</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "LoginButton",
    methods: {
        ...mapActions(["modalsSetShow"]),
    },
};
</script>

<style scoped>
.button-inner {
    background: linear-gradient(180deg, #00FEFB 0%, #0067FE 100%);
    background-clip: text;
    font-family: Montserrat;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9rem;
    height: 50px;
    min-width: min(1vw, 1.5rem);
    background: linear-gradient(180deg,
            var(--background-tertiary) 0%,
            var(--background-primary) 100%);
    box-shadow:
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1px 0px 0px rgba(113, 106, 212, 0.342) inset;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: ease-in-out 0.15s;
}

.login-btn:hover {
    transform: translateY(-1px);
}

.login-btn:hover .button-inner {
    filter: brightness(1.2);
}

.login-btn:active {
    transition-duration: 0.2s;
    transform: translateY(2px);
}
</style>